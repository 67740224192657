import { useLocation, useHistory } from 'react-router-dom';
import { useCallback } from 'react';
import resolve from 'resolve-pathname';

const useToParentCallback = (upLevels: number) => {
  const history = useHistory();
  const location = useLocation();

  return useCallback(() => {
    const resolved = resolve(
      `${new Array(upLevels).fill('../').join('')}`,
      `${location.pathname}/`,
    );

    const withoutSlash = resolved.substring(0, resolved.length - 1);

    history.push(withoutSlash);
  }, [upLevels, location.pathname, history]);
};

export default useToParentCallback;
