import * as React from 'react';
import { err } from 'neverthrow';

import { CreationHandlerWithoutArgs, CreationResult } from '../types';

import { PdfPreview } from './PdfPreview';

export type PreviewDimensions = {
  width?: number | string;
  height?: number | string;
};

export function PreviewStep({
  onNext,
  onPreview,
  dimensions = {
    width: 600,
    height: 900,
  },
}: {
  onNext: () => void;
  onPreview: CreationHandlerWithoutArgs;
  dimensions?: PreviewDimensions;
}) {
  const [previewResult, setPreviewResult] = React.useState<CreationResult>();
  const [isLoading, setIsLoading] = React.useState(false);

  const loadPreview = React.useCallback(async () => {
    setIsLoading(true);
    try {
      const result = await onPreview();
      setPreviewResult(result);
    } catch (error) {
      setPreviewResult(
        err({
          reason: (error as Error).toString(),
        }),
      );
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    loadPreview();
  }, [loadPreview]);

  return (
    <PdfPreview
      isLoading={isLoading}
      fileSrc={previewResult?.isOk() ? previewResult.value.fileURL : undefined}
      error={previewResult?.isErr() ? previewResult.error.reason : undefined}
      dimensions={dimensions}
      onNext={onNext}
    />
  );
}
