import { DateTime } from 'luxon';

import { InvoiceCycle } from '../graphql-types';

export function determineInitialConsumptionEndDate(
  startDate: DateTime,
  invoiceCycle?: InvoiceCycle,
) {
  if (!startDate.isValid) {
    return null;
  }

  switch (invoiceCycle) {
    case InvoiceCycle.Monthly:
      return startDate.endOf('month');
    case InvoiceCycle.Quarterly:
      return DateTime.fromObject({
        year: startDate.year,
        month: Math.ceil(startDate.month / 3) * 3,
      }).endOf('month');
    case InvoiceCycle.HalfYearly:
      return DateTime.fromObject({
        year: startDate.year,
        month: Math.ceil(startDate.month / 6) * 6,
      }).endOf('month');
      break;
    case InvoiceCycle.Yearly:
    default:
      return startDate.endOf('year');
  }
}

export const areDatesOnSameDay = (first: Date, second: Date) =>
  first.getFullYear() === second.getFullYear() &&
  first.getMonth() === second.getMonth() &&
  first.getDate() === second.getDate();

export const generateYearsInPeriod = (startDate?: Date, endDate?: Date) => {
  const startYear = (startDate ?? new Date(2016, 0, 0)).getFullYear();
  const endYear = (endDate ?? new Date()).getFullYear();

  return Array.from(
    { length: endYear - startYear + 1 },
    (_, i) => endYear - i,
  ).reverse();
};

/*
 * Transforms a date string to the format 'dd.MM.yyyy'
 * @param str - Any valid date string that can be parsed by luxon
 */
export function formatDateToSimpleDateString(dateString?: string) {
  if (!dateString) return ``;

  const dateTime = DateTime.fromISO(dateString);

  if (!dateTime.isValid) {
    return '';
  }
  return `${dateTime.toFormat('dd')}.${dateTime.toFormat(
    'MM',
  )}.${dateTime.toFormat('yyyy')}`;
}

const DATE_INPUT_FORMAT = 'yyyy-MM-dd';
/**
 * Parses a value from a "date" input (for use in backend requests).
 * Sets time-zone to "UTC" and time to midnight.
 */
export function getDateTimeFromDateInput(date: string) {
  return DateTime.fromFormat(date, DATE_INPUT_FORMAT, { zone: 'utc' });
}
/**
 * Formats a date for use in a "date" input.
 * Loses all time and time-zone information.
 */
export function formatDateForInput(date: DateTime) {
  return date.toFormat(DATE_INPUT_FORMAT);
}
