import React from 'react';
import styled from 'styled-components';
import {
  FormikInput,
  Icons,
  FlexRow,
  Button,
} from '@ampeersenergy/ampeers-ui-components';

import {
  PlantAutarkySuggestion,
  suggestionIsClosingAccountingRun,
  suggestionHasError,
  AutarkySuggestionStandard,
  PlantAutarkySuggestionErrors,
  PlantAutarkyDeprecatedErrors,
} from '../types';
import { Relation } from '../../../../components';
import { formatDate, EMPTY_STR } from '../../../../helpers/formatStrings';
import { ToolTip } from '../../../../components/tooltip/tooltip';
import { assertNever } from '../../../../helpers/type.helpers';

const AutarkyValue = styled.div`
  flex: 1;
  align-self: center;
  font-size: 15px;
`;

const Item = styled(FlexRow)<{ isSelected: boolean }>`
  border: 1px solid
    ${(props) => (props.isSelected ? props.theme.primaryColor : 'whitesmoke')};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  margin-bottom: 15px;
  align-items: center;
`;

const List = styled.ul`
  list-style: none;
  padding-left: 0;
`;

const ListWrapper = styled.div`
  max-width: 800px;
`;

const Label = styled.label<{ isEditable: boolean }>`
  display: flex;
  padding: 10px 15px 10px 10px;
  margin-left: 10px;
  font-size: 15px;
  font-weight: bold;
  align-items: center;
  width: 150px;
  justify-content: flex-end;
  user-select: none;
  cursor: ${(props) => (props.isEditable ? 'pointer' : 'auto')};

  input {
    margin-left: 8px;
    position: relative;
    top: 3px;
    height: 16px;
    width: 16px;
  }
`;

const IconWrap = styled.div`
  display: flex;
  padding-left: 8px;
  align-items: center;
  justify-content: center;
`;

const LeftPart = styled.div`
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
`;

const Greyable = styled.span<{ isSelected: boolean }>`
  color: ${(props) => (props.isSelected ? '#000' : '#888888')};
`;

const Buttons = styled(FlexRow)`
  justify-content: flex-end;
`;

const formatError = (
  e: PlantAutarkySuggestionErrors['errorReason'] | PlantAutarkyDeprecatedErrors,
) => {
  switch (e) {
    case 'BUILDINGS_METERS_DOES_NOT_MATCH_MEASUREMENT_CONCEPT':
      return 'Autarkie konnte nicht berechnet werden, weil die Gebäudezähler nicht mit dem Messkonzept übereinstimmen';
    case 'BUILDINGS_METERS_MISSING_METER_READINGS':
      return 'Autarkie konnte nicht berechnet werden, weil es für diese Kundenanlage fehlerhafte Gebäudezähler Zählerstände gibt.';
    case 'NO_AUTARKY_CALCULATED_IN_THE_PAST':
      return 'Für diese Kundenlage gibt es noch keine historischen Berechnungen zum Autarkiegrad.';
    case 'PLANT_METER_READINGS_NOT_ERROR_FREE':
      return 'Autarkie konnte nicht berechnet werden, weil es für diese Kundenanlage fehlerhafte Zählerstände gibt.';
    case 'PLANT_MISSES_MSO_H_CONTRACT':
    case 'MSO_H_PLANT_METER_HAS_MORE_THAN_ONE_CONTRACT':
      return 'Autarkie konnte nicht berechnet werden, weil für die Kundenanlage kein Summenzähler Vertrag angelegt wurde.';
    /**
     * old errors which we handle here to be backwards
     * compatible
     */
    case 'PLANT_TYPE_FULL_SUPPLY':
      return 'Vollversorgung';
    case 'DIRECT_CONSUMPTION_AMOUNT_NOT_PLAUSIBLE':
      return 'Der Direktverbrauch in der Kundenanlage ist unplausibel';

    case 'INVALID_CALCULATED_AUTARKIES':
    case 'INVALID_AUTARKY_DATA_FOR_SESSION':
    case 'INVALID_PLANTS_WITH_AUTARKY':
      return 'Autarkie konnte nicht berechnet werden';

    default:
      assertNever(e);
  }
};

export function AutarkyList({
  suggestions,
  isEditable,
  selectAll,
}: {
  suggestions: (PlantAutarkySuggestion & {
    isSelected: boolean;
    value: string;
  })[];
  isEditable: boolean;
  selectAll: () => void;
}) {
  return (
    <ListWrapper>
      <ToolTip />

      {suggestions.length > 1 && isEditable && (
        <Buttons onClick={selectAll}>
          <Button secondary>Alle Abrechnen</Button>
        </Buttons>
      )}
      <List>
        {suggestions.map((row) => {
          let toolTipText = null;
          let toolTipType: 'info' | 'warning' = 'warning';

          if (suggestionHasError(row)) {
            if (row.errorReason === 'NO_AUTARKY_CALCULATED_IN_THE_PAST') {
              toolTipType = 'info';
            }
            if (
              !(
                row.errorReason !== 'NO_AUTARKY_CALCULATED_IN_THE_PAST' &&
                row.plantFullSupply
              )
            ) {
              toolTipText = formatError(row.errorReason);
            }
          } else if (suggestionIsClosingAccountingRun(row)) {
            toolTipText = `Die Autarkie gilt für den Zeitraum vom ${formatDate(
              row.validityStartAt,
            )} bis zum ${formatDate(
              row.validityEndAt,
            )} und wurde am ${formatDate(row.calculatedAt)} berechnet.`;
            toolTipType = 'info';
          } else if ((row as AutarkySuggestionStandard).warning !== undefined) {
            toolTipText =
              'Der Direktverbrauch in der Kundenanlage ist unplausibel';
          }

          return (
            <Item
              as="li"
              isSelected={row.isSelected}
              key={`${row.plantId}-${(row as any).autarky}`}
            >
              <LeftPart>
                <Relation
                  title={row.plantName}
                  icon={Icons.Plant}
                  color={row.isSelected ? '' : '#888888'}
                />
                {toolTipText !== null && isEditable && (
                  <IconWrap data-tip={toolTipText}>
                    {toolTipType === 'warning' && (
                      <Icons.Warning
                        size={20}
                        color={row.isSelected ? '#ff9800' : '#bdbdbd'}
                      />
                    )}
                    {toolTipType === 'info' && (
                      <Icons.Info
                        size={20}
                        color={row.isSelected ? '#888888' : '#bdbdbd'}
                      />
                    )}
                  </IconWrap>
                )}
              </LeftPart>
              <AutarkyValue>
                {isEditable && !row.plantFullSupply && (
                  <FormikInput
                    id={`${row.plantId}-autarky`}
                    name={`${row.plantId}`}
                    type="number"
                    max="100"
                    min="0"
                    label={null}
                    disabled={!row.isSelected}
                    appendix="%"
                  />
                )}
                {!isEditable &&
                  !row.plantFullSupply &&
                  `${
                    row.value !== undefined && row.value !== null
                      ? row.value
                      : EMPTY_STR
                  }%`}
                {row.plantFullSupply && (
                  <Greyable isSelected={row.isSelected}>Vollversorgt</Greyable>
                )}
              </AutarkyValue>
              <Label
                htmlFor={`${row.plantId}-selected`}
                isEditable={isEditable}
              >
                Abrechnen
                <FormikInput
                  type="checkbox"
                  disabled={!isEditable}
                  id={`${row.plantId}-selected`}
                  name={`${row.plantId}-selected`}
                />
              </Label>
            </Item>
          );
        })}
      </List>
    </ListWrapper>
  );
}
