import React, { useEffect } from 'react';
import { Icons, Button } from '@ampeersenergy/ampeers-ui-components';
import styled from 'styled-components';

import { EndMessageContainerProps } from '../../../../components/createFlow';

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;
`;

const BtnWrap = styled.div`
  margin-top: 10px;
`;

function FlowEndMessage({
  kind,
  onDone,
  showNextStep,
  onShown,
}: EndMessageContainerProps & {
  showNextStep: boolean;
  onShown: () => void;
}) {
  useEffect(onShown, [onShown]);

  return (
    <Center data-testid="create-flow-success">
      <div>
        <Icons.Checkmark size={60} color="#A8E015" />
      </div>
      {kind} erfolgreich hinzugefügt.
      {showNextStep && (
        <BtnWrap>
          <Button onClick={onDone} data-testid="create-flow-next-step">
            Weiter zum nächsten Vertragspartner
          </Button>
        </BtnWrap>
      )}
    </Center>
  );
}

export default FlowEndMessage;
