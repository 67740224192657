import React from 'react';
import styled from 'styled-components/macro';

import { Bold } from '../../../components';
import { useGraphqlForm } from '../../../components/graphql-form/hooks/useGraphqlForm';
import { Grid } from '../../../components/layout';
import { AddressForm } from '../create/AddressForm';

import { UpdateAdditionalPersonForm } from './AdditionalPersonForm';
import { PersonForm } from './editFormParts';

const Headline = styled(Bold)`
  padding: 10px 0 6px;
  font-size: 15px;
`;

type EditBillingShippingAddressFormProps = {
  fieldNamePrefix: string;
};

export function EditBillingShippingAddressForm({
  fieldNamePrefix,
}: EditBillingShippingAddressFormProps) {
  const { isEditing } = useGraphqlForm();

  return (
    <Grid $flow="column" css="grid-auto-columns: 1fr;">
      <div
        css={`
          display: grid;
          grid-auto-flow: row;
          gap: ${isEditing ? `0.5rem` : `0`};
        `}
      >
        {isAddressBilling(fieldNamePrefix) && (
          <Headline>Rechnungsadresse</Headline>
        )}
        <PersonForm fieldNamePrefix={fieldNamePrefix} />
        <AddressForm fieldNamePrefix={fieldNamePrefix} />
      </div>
      {isAddressBilling(fieldNamePrefix) && (
        <UpdateAdditionalPersonForm
          fieldNamePrefix={fieldNamePrefix}
          isEditing={isEditing}
        />
      )}
    </Grid>
  );
}

function isAddressBilling(fieldNamePrefix: string) {
  return fieldNamePrefix.includes('addressBilling');
}
