import gql from 'graphql-tag';

export default gql`
  query getAnnotations($types: [String!]!) {
    getAnnotations(types: $types) {
      type
      fields
      relation
    }
  }
`;
