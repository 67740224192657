import * as yup from 'yup';
import { isValidIBAN, isValidBIC } from 'ibantools';

import { yupUTCDate } from '../yupUTCDate';

import './yupSetup';

const isNotEmpty = (val: string | undefined | null): val is string =>
  val !== undefined && val !== null && val !== '';

export const yupIbanValidation = (required: boolean) => {
  if (required) {
    return yup
      .string()
      .transform((value) => (!value ? '' : value))
      .required()
      .test(
        'is-valid',
        (iban: string | undefined) => isNotEmpty(iban) && isValidIBAN(iban),
      );
  }
  return yup
    .string()
    .nullable()
    .test(
      'is-valid',
      (iban: string | undefined | null) =>
        !isNotEmpty(iban) || isValidIBAN(iban),
    );
};

export const yupBicValidation = (required: boolean) => {
  if (required) {
    return yup
      .string()
      .transform((value) => (!value ? '' : value))
      .isRequired(required)
      .test(
        'is-valid',
        (bic: string | undefined) => isNotEmpty(bic) && isValidBIC(bic),
      );
  }
  return yup
    .string()
    .nullable()
    .test(
      'is-valid',
      (bic: string | undefined | null) => !isNotEmpty(bic) || isValidBIC(bic),
    );
};

export const yupBankAccount = yup
  .object({
    name: yup
      .string()
      .transform((value) => value ?? '')
      .required(),
    iban: yupIbanValidation(true),
    bic: yupBicValidation(true),
    mandateReference: yup
      .string()
      .transform((value) => value ?? '')
      .required(),
    signedAt: yupUTCDate
      .transform((value) => value ?? '')
      .max(new Date(), `Das Datum darf nicht in der Zukunft liegen`)
      .required(),
  })
  .required();

export const yupBankAccountNullable = yup
  .object({
    name: yup.string().when('iban', (iban: string) => {
      if (iban) {
        return yup.string().required();
      }
      return yup.string().nullable();
    }),
    iban: yupIbanValidation(false),
    bic: yup.string().when('iban', (iban: string) => {
      if (iban) {
        return yupBicValidation(true);
      }
      return yupBicValidation(false);
    }),
    mandateReference: yup.string().nullable(),
    signedAt: yup.string().nullable(),
  })
  .nullable();
