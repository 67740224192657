import React, { useState } from 'react';
import { Button } from '@ampeersenergy/ampeers-ui-components';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import styled from 'styled-components';
import striptags from 'striptags';

import {
  ReadContractCommunicationsDocument,
  useCreateCommunicationMutation,
  useReadContractCommunicationsQuery,
} from '../../../../graphql-types';
import {
  formatDateTime,
  formatCommunication,
} from '../../../../helpers/formatStrings';
import {
  SubTitle,
  FormikInput,
  FormikTextarea,
  FormikSelect,
  FormikSubmit,
  PaddedShadowBox,
  FlexRow,
  Flex,
  Bold,
  ErrorMsg,
} from '../../../../components';

const SecondBox = styled(PaddedShadowBox)`
  margin-top: 10px;
  padding-top: 1px;
`;
const FlexStyled = styled(Flex)`
  margin-top: 10px;
`;

const inputValidationSchema = yup.object().shape({
  title: yup.string().required(),
  message: yup.string().required(),
  communicatedBy: yup.mixed().oneOf(['email', 'phone', 'note']),
});

const initialValues = {
  title: '',
  message: '',
  communicatedBy: 'email',
};

const Row = styled(FlexRow)`
  padding-top: 17px;
  padding-bottom: 17px;
  border-bottom: 1px solid #f3f3f3;
`;

const Small = styled.div`
  font-size: 14px;
`;

const ButtonWrap = styled.div`
  display: block;
  float: right;
`;

const SubTitleStyled = styled(SubTitle)`
  margin-top: 5px;
`;

const formatBy = (type: string) => {
  if (type === 'email') return 'E-Mail';
  if (type === 'note') return 'Notiz';
  return 'Telefon';
};

function CustomerCommunicationPage({
  contractId,
}: {
  contractId: string | undefined;
}) {
  const [isExpanded, setExpanded] = useState(false);
  const [mutationError, setMutationError] = useState<any>(null);
  const [createCommunicationMutation] = useCreateCommunicationMutation();
  const { data, error } = useReadContractCommunicationsQuery({
    variables: {
      contractId: contractId!,
    },
    skip: contractId === undefined,
  });

  const cancelCreateCustomerCommunication = (handleReset: any) => {
    handleReset();
    setExpanded(false);
  };

  return (
    <>
      <PaddedShadowBox>
        <SubTitleStyled>
          Kommunikation dokumentieren
          {!isExpanded && (
            <ButtonWrap>
              <Button onClick={() => setExpanded(true)}>Hinzufügen</Button>
            </ButtonWrap>
          )}
        </SubTitleStyled>

        {mutationError && <ErrorMsg error={mutationError} retryable={false} />}
        {error && <ErrorMsg error={error} retryable />}

        {isExpanded && (
          <Formik
            initialValues={initialValues}
            validationSchema={inputValidationSchema}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              setSubmitting(true);

              const stripped = {
                ...values,
                message: striptags(values.message),
              };

              try {
                await createCommunicationMutation({
                  variables: {
                    communication: stripped,
                    contractId: contractId!,
                  },
                  optimisticResponse: {
                    __typename: 'Mutation',
                    createCommunication: {
                      id: `${-parseInt(
                        Math.random().toString(8).substr(2, 9),
                        10,
                      )}`,
                      message: `<p>${stripped.message}</p>`,
                      date: new Date().toISOString(),
                      title: stripped.title,
                      communicatedBy: stripped.communicatedBy,
                      __typename: 'CustomerCommunication',
                    },
                  },
                  update: (
                    proxy: any,
                    { data: { createCommunication } }: any,
                  ) => {
                    const _data = proxy.readQuery({
                      query: ReadContractCommunicationsDocument,
                      variables: { contractId },
                    });
                    proxy.writeQuery({
                      query: ReadContractCommunicationsDocument,
                      variables: {
                        contractId,
                      },
                      data: {
                        readContract: {
                          ..._data.readContract,
                          communications: [
                            ..._data.readContract.communications,
                            createCommunication,
                          ],
                        },
                      },
                    });
                  },
                  refetchQueries: [
                    {
                      query: ReadContractCommunicationsDocument,
                      variables: {
                        contractId,
                      },
                    },
                  ],
                });
                resetForm();
                setExpanded(false);
                setMutationError(null);
              } catch (err) {
                setMutationError(err);
              }
              setSubmitting(false);
            }}
          >
            {({ handleReset }) => (
              <Form>
                <FormikSelect
                  name="communicatedBy"
                  id="communicatedBy"
                  label="Art"
                  required
                >
                  <option value="email">E-Mail</option>
                  <option value="phone">Telefon</option>
                  <option value="note">Notiz</option>
                </FormikSelect>
                <FormikInput
                  type="text"
                  name="title"
                  id="title"
                  label="Thema"
                  placeholder="Thema der Kommunikation"
                  required
                />
                <FormikTextarea
                  name="message"
                  id="message"
                  label="Inhalt"
                  placeholder="Inhalt der Kommunikation"
                  required
                />
                <FlexStyled>
                  <FormikSubmit>Speichern</FormikSubmit>
                  <Button
                    tertiary
                    style={{ marginLeft: '7.5px' }}
                    onClick={() =>
                      cancelCreateCustomerCommunication(handleReset)
                    }
                  >
                    Abbrechen
                  </Button>
                </FlexStyled>
              </Form>
            )}
          </Formik>
        )}
      </PaddedShadowBox>
      <SecondBox>
        <SubTitle>Verlauf</SubTitle>
        {data &&
          data?.readContract?.communications &&
          // eslint-disable-next-line no-unsafe-optional-chaining
          [...data?.readContract.communications]
            .sort(
              (a: any, b: any) =>
                new Date(b.date).getTime() - new Date(a.date).getTime(),
            )
            .map((c: any) => (
              <Row key={c.id}>
                <Flex>
                  <Bold>{c.title}</Bold>
                  <Small>{formatCommunication(c.message)}</Small>
                </Flex>
                <Small>
                  {formatDateTime(c.date)} via {formatBy(c.communicatedBy)}
                </Small>
              </Row>
            ))}
        {data?.readContract?.communications?.length === 0 &&
          'Keine Kommunikation'}
      </SecondBox>
    </>
  );
}

export default CustomerCommunicationPage;
