import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { FormikConfig, FormikValues } from 'formik';
import { ApolloError } from '@apollo/client';
import {
  AlertRetryable,
  Button,
  ButtonGroup as ButtonGroupComponent,
  Modal,
} from '@ampeersenergy/ampeers-ui-components';

import { ExportStep, StepState } from './export-step';
import { ProcessingStep } from './processing-step';
import { ResultStep } from './result-step';
import { ExportType } from './types';

const SubTitle = styled.div`
  color: #7d7d7d;
  margin-top: -10px;
  margin-bottom: 10px;
`;

const ButtonGroup = styled(ButtonGroupComponent)`
  justify-content: flex-end;
  margin-top: 10px;
`;

export interface ExportFlowProps {
  types: ExportType[];
  defaultType?: ExportType['id'];
  onClose(): void;
  onGenerate: (
    type: ExportType['id'],
    values: FormikValues,
  ) => Promise<StepState | undefined>;
  hasError?: boolean;
  errorMsg?: string;
  error?: ApolloError;
  isOpen: boolean;
  formProps: Omit<FormikConfig<FormikValues>, 'onSubmit' | 'validationSchema'>;
  title?: string;
}

export const ExportFlow: React.FC<ExportFlowProps> = ({
  types,
  defaultType,
  onClose,
  onGenerate,
  hasError,
  error,
  errorMsg,
  isOpen,
  formProps,
  title,
}) => {
  const [formValues, setFormValues] = useState({});
  const [step, setStep] = useState<StepState>('export');
  const [selectedType, setSelectedType] = useState<ExportType | undefined>(
    defaultType ? types.find((t) => t.id === defaultType) : undefined,
  );
  const [backgroundModalOpen, setBackgroundModalOpen] = useState(false);
  const [progressLoaderFinished, setProgressLoaderFinished] = useState(false);

  const _onGenerate = useCallback(
    (values) => {
      if (!selectedType) return Promise.resolve();

      setFormValues(values);
      setStep('processing');

      return onGenerate(selectedType.id, values).then(
        (nextState?: StepState) => {
          // special behavior for progress loader
          // when the generate step is done, we set the progress percentage to 100% and display it
          // to the user for 2 seconds before actually moving to the done step
          if (nextState === 'done') {
            // this forces the progress to 100%
            setProgressLoaderFinished(true);
            setTimeout(() => {
              // we reset the progress to 0% so that it displays correctly when the user opens the modal again
              setProgressLoaderFinished(false);
              // if the user already closed the modal, we don't move to the done step
              setStep((currentStep) =>
                currentStep === 'processing' ? nextState : currentStep,
              );
            }, 2000);
          } else {
            setStep(nextState ?? 'export');
          }
          if (backgroundModalOpen) {
            setBackgroundModalOpen(false);
          }
        },
      );
    },
    [selectedType, onGenerate, backgroundModalOpen, setBackgroundModalOpen],
  );

  const _onClose = useCallback(() => {
    if (step === 'processing') {
      setBackgroundModalOpen(true);
    } else {
      onClose?.();
      setStep('export');
    }
    setProgressLoaderFinished(false);
  }, [step, onClose, setStep, setProgressLoaderFinished]);

  const _onRetry = useCallback(() => {
    setProgressLoaderFinished(false);
    if (!selectedType) return;
    setStep('processing');
    onGenerate(selectedType.id, formValues).then((nextState?: StepState) => {
      setStep(nextState ?? 'export');
    });
  }, [
    formValues,
    onGenerate,
    setStep,
    selectedType,
    setProgressLoaderFinished,
  ]);

  const SuccessComponent = types.find(
    (t) => t.id === selectedType?.id,
  )?.SuccessComponent;

  return (
    <>
      <Modal
        contentLabel="Generate-Report-Modal"
        title={title ?? 'Bericht erstellen'}
        isOpen={isOpen}
        onRequestClose={_onClose}
        minWidth={824}
        maxWidth="lg"
        minHeight={300}
      >
        {selectedType && step !== 'export' && (
          <SubTitle>{selectedType.label} </SubTitle>
        )}
        {((hasError && step === 'done') || step === 'error') && (
          <AlertRetryable
            error={error}
            title="Bericht konnte nicht erstellt werden."
            message={errorMsg}
            onRetry={_onRetry}
          />
        )}
        {!hasError &&
          step === 'done' &&
          (SuccessComponent ? (
            <SuccessComponent />
          ) : (
            <ResultStep label={selectedType!.label} />
          ))}
        {step === 'processing' && (
          <ProcessingStep
            finished={progressLoaderFinished}
            expectedApiDurationInSeconds={
              selectedType?.expectedApiDurationInSeconds
            }
          />
        )}
        {step === 'export' && (
          <ExportStep
            types={types}
            selectedType={selectedType}
            setSelectedType={setSelectedType}
            onGenerate={_onGenerate}
            InnerComponent={selectedType?.InnerComponent}
            formProps={formProps}
          />
        )}
      </Modal>
      <Modal
        contentLabel="Background-Generate-Report-Modal"
        title="Berichterstellung wird im Hintergrund fortgeführt"
        isOpen={backgroundModalOpen}
        onRequestClose={() => setBackgroundModalOpen(false)}
        maxWidth="md"
      >
        Sobald Ihr Bericht fertig ist, kann dieser im Bereich Stromsteuerreport
        heruntergeladen werden. Möchten Sie dieses Fenster jetzt schließen und
        die Berichterstellung im Hintergrund laufen lassen?
        <ButtonGroup>
          <Button
            secondary
            onClick={() => {
              onClose?.();
              setStep('export');
              setBackgroundModalOpen(false);
            }}
          >
            Schließen und fortfahren
          </Button>
        </ButtonGroup>
      </Modal>
    </>
  );
};
