import React from 'react';

import { UnprocessedBooking } from '../../../../../graphql-types';
import {
  formatCurrency,
  formatDate,
} from '../../../../../helpers/formatStrings';
import {
  Amount,
  BookingDate,
  Empty,
  Entry,
  EntryInner,
  List,
  Name,
} from '../accountingMoves/accountingMoveList';

type VoucherBookingListProps = {
  entries?: UnprocessedBooking[];
};

export default function VoucherBookingList({
  entries = [],
}: VoucherBookingListProps) {
  return (
    <List>
      {entries.map((item) => (
        <VoucherItem
          item={item}
          key={`${item.name}-${item.amount}-${item.date}`}
        />
      ))}
      {entries.length === 0 && <Empty>Keine Einträge</Empty>}
    </List>
  );
}

function VoucherItem({ item }: { item: UnprocessedBooking }) {
  return (
    <Entry key={`${item.name}-${item.amount}-${item.date}`}>
      <EntryInner>
        <Name>{item.name}</Name>
        <Name>
          {item.journal === 'voucher_kb' ? 'Kunde wirbt Kunde' : 'Wechselbonus'}
        </Name>
        <BookingDate>{formatDate(item.date)}</BookingDate>
        <Amount>{formatCurrency(item.amount)}</Amount>
      </EntryInner>
    </Entry>
  );
}
