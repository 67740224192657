import React from 'react';
import { Route, Redirect } from 'react-router';

import { withSession } from '../services/session';

function AuthorizedRoute({ children, hasSession, ...rest }: any) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        hasSession ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default withSession(AuthorizedRoute);
