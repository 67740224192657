/* eslint-disable @typescript-eslint/default-param-last */
import { CreateReportSuccess } from '../graphql-types';

export type UiState = { reports: CreateReportSuccess[] };

export type SetReportAction = {
  type: 'set';
  payload: { report: CreateReportSuccess };
};

export type UnsetReportAction = {
  type: 'unset';
  payload: { reportId: string };
};

export const setReport = (report: CreateReportSuccess): SetReportAction => ({
  type: 'set',
  payload: { report },
});

export const unsetReport = (reportId: string): UnsetReportAction => ({
  type: 'unset',
  payload: { reportId },
});

export type UiAction = SetReportAction | UnsetReportAction;

export const initialState: UiState = { reports: [] };

export const uiReducer = (
  state: UiState = initialState,
  action: UiAction,
): UiState => {
  switch (action.type) {
    case 'set':
      return { reports: [...state.reports, action.payload.report] };
    case 'unset': {
      const index = state.reports.findIndex(
        ({ entityId }) => entityId === action.payload.reportId,
      );
      const reports = [...state.reports];
      if (index !== -1) {
        reports.splice(index, 1);
        return {
          reports,
        };
      }
      return state;
    }
    default:
      return state;
  }
};
