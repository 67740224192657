import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from '@ampeersenergy/ampeers-ui-components';

import { logErrorToClarity } from '../helpers/analyticsHelpers';

import * as Icons from './icons';
import { Warning } from './icons';
import { IconProps } from './baseIcon';

const Message = styled.div`
  position: relative;
  font-size: 14px;
  color: #ff0e0e;
  padding: 12px 15px 12px 15px;
  background: #ffe5e5;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 4px;
`;

const IconWrap = styled.div`
  margin-right: 0px;
  display: inline-block;
  position: relative;
  left: -5px;
`;

const InlineIconWrap = styled.div`
  display: inline-block;
  position: relative;
  left: -1px;
`;

const Title = styled.span`
  font-weight: 600;
  font-size: 18px;
  color: #ff0e0e;
  letter-spacing: 0.27px;
`;

const InlineTitle = styled.span`
  color: #ff0e0e;
`;

const Code = styled.pre`
  font-size: 12px;
`;

const DetailButton = styled.div`
  color: rgb(251, 49, 62);
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
`;

const Details = styled.div`
  margin-top: 5px;
`;

const InlineMessage = styled.div`
  font-size: 14px;
  color: #ff0e0e;
  background: #ffe5e5;
  padding: 3px 6px 3px 3px;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
`;

const ReportBug = styled(Button)`
  margin-top: 15px;
`;

const prepareMessages = ({
  icon,
  title,
  message,
  error,
}: Pick<ErrorMsgProps, 'title' | 'message' | 'icon' | 'error'>) => {
  let Icon = icon || Warning;
  let _title = title || 'Fehler';
  let _message = message || 'Etwas ist schiefgelaufen.';
  let details;
  let isDetailed = false;

  if (error) {
    if (error.networkError) {
      if (!navigator.onLine) {
        Icon = Icons.CloudWarning;
        _title = 'Keine Verbindung zum Internet';
        _message = 'Stelle eine Verbindung zum Internet her um fortzufahren.';
      } else {
        _message = 'Bei der Anfrage des Servers ist ein Fehler aufgetreten.';
      }
    } else if (typeof error === 'string') {
      _message = error;
    } else {
      _message = 'Bei der Anfrage des Servers ist ein Fehler aufgetreten.';
      details = error.message;
    }

    if (error.graphQLErrors) {
      error.graphQLErrors.map((e: any) => `${e.message}`);
    }

    if (error.exception) {
      details = error.exception;
    } else if (error.stack) {
      details = error.stack;
    }

    if (
      Object.keys(error).every((key) =>
        ['message', 'traceId', 'payload', 'context'].includes(key),
      )
    ) {
      const detailedError = {
        Fehler: error.message,
        TraceId: error.traceId,
        Kontext: error.context,
        Payload: error.payload,
      };
      isDetailed = true;
      details = JSON.stringify(detailedError, null, 2);
    }
  }

  return { Icon, title: _title, message: _message, details, isDetailed };
};

export interface ErrorMsgProps {
  title?: string;
  message?: string;
  icon?: React.ComponentType<IconProps>;
  error?: any;
  retryable?: boolean;
}

export default function ErrorMsg({
  error,
  retryable,
  ...props
}: ErrorMsgProps) {
  const [isExpanded, setExpanded] = useState(false);

  const { Icon, title, message, details, isDetailed } = prepareMessages({
    icon: props.icon,
    title: props.title,
    error,
    message: props.message,
  });

  logErrorToClarity(title, message);

  return (
    <Message {...props}>
      <IconWrap>
        <Icon size={25} color="#ff0e0e" />
      </IconWrap>
      <Title>{title}</Title>
      <div>{message}</div>
      {retryable !== false && (
        <DetailButton onClick={() => window.location.reload()}>
          Erneut versuchen
        </DetailButton>
      )}
      {details ? (
        <Details>
          <DetailButton onClick={() => setExpanded(!isExpanded)}>
            {isExpanded ? 'Weniger' : 'Mehr'}
          </DetailButton>
          {isExpanded && <Code>{error.stack ? error.stack : details}</Code>}
          {isDetailed && (
            <ReportBug
              onClick={() => {
                window.open(
                  `mailto:` +
                    `servicedesk@ampeersenergy.de` +
                    `?subject=` +
                    `LS bug report` +
                    `&body=${encodeURIComponent(details)}`,
                );
              }}
            >
              Report
            </ReportBug>
          )}
        </Details>
      ) : null}
    </Message>
  );
}

export function InlineErrorMessage({ error, ...props }: ErrorMsgProps) {
  const { Icon, message } = prepareMessages({
    icon: props.icon,
    title: props.title,
    error,
  });

  return (
    <InlineMessage title={props.title}>
      <InlineIconWrap>
        <Icon size={25} color="#ff0e0e" />
      </InlineIconWrap>
      <InlineTitle>{message}</InlineTitle>
    </InlineMessage>
  );
}
