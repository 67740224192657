import * as React from 'react';
import * as yup from 'yup';
import { DateTime } from 'luxon';
import { Form, useFormik, FormikContext } from 'formik';
import {
  FormikInput,
  WarningAlert,
} from '@ampeersenergy/ampeers-ui-components';

import setDownpaymentStart from '../../../queries/setDownpaymentStart';

import { InfoWrapped } from './style';

interface DownpaymentFormProps {
  isEditable?: boolean;
  startDate?: string;
}

export const DownpaymentForm: any = React.forwardRef<any, DownpaymentFormProps>(
  ({ isEditable = true, startDate }, ref) => {
    const [showHint, setShowHint] = React.useState(false);

    const validationSchema = React.useMemo(
      () =>
        yup.object().shape({
          startDate: yup.date().required(),
        }),
      [],
    );

    const initialFormValues = React.useMemo(() => {
      return {
        startDate: startDate ?? DateTime.now().toFormat('yyyy-MM-dd'),
      };
    }, [startDate]);

    const ctx = useFormik({
      initialValues: initialFormValues,
      validationSchema,
      validateOnMount: true,
      onSubmit: () => {},
    });

    React.useEffect(() => {
      const contextStartDate = ctx.values.startDate
        ? new Date(ctx.values.startDate)
        : null;
      if (
        !startDate &&
        contextStartDate &&
        contextStartDate < DateTime.local().startOf('day').toJSDate()
      ) {
        setShowHint(true);
      } else {
        setShowHint(false);
      }
    }, [ctx.values.startDate, startDate]);

    React.useImperativeHandle(ref, () => ({
      allowTransition(transitionName: string) {
        if (transitionName.startsWith('toSetDownpaymentStart')) {
          ctx.setTouched(
            {
              startDate: true,
            },
            true,
          );

          return ctx.isValid;
        }
        return true;
      },

      getTransitionConfig(transitionName: string) {
        if (transitionName.startsWith('toSetDownpaymentStart')) {
          return {
            mutation: setDownpaymentStart,
            variables: {
              transitionName,
              payload: {
                startDate: ctx.values.startDate,
              },
            },
          };
        }
      },
    }));

    return (
      <>
        <InfoWrapped>
          Bitte gib hier den Gültigkeitsbeginn der neuen Abschläge an.
        </InfoWrapped>
        <FormikContext.Provider value={ctx}>
          <Form>
            <FormikInput
              id="startDate"
              name="startDate"
              label="Datum"
              type="date"
              disabled={!isEditable}
            />
          </Form>
          {showHint && (
            <WarningAlert>
              Dein gewähltes Datum für den Gültigkeitsbeginn der neuen Abschläge
              liegt in der Vergangenheit. Möchtest Du trotzdem fortfahren?
            </WarningAlert>
          )}
        </FormikContext.Provider>
      </>
    );
  },
);
