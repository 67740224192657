import React, { useState } from 'react';
import styled from 'styled-components';
import { Box } from '@smooth-ui/core-sc';
import OutsideClickHandler from 'react-outside-click-handler';
import { Icons } from '@ampeersenergy/ampeers-ui-components';

import { CrmIcon } from '../icons';
import { useHasRole } from '../useHasRole';

import NavigationItem from './navigationItem';
import Logo from './logo';
import UserItem from './userItem';
import UserSubNav from './userSubNav';
import Search from './search';
import { handleHelpWidget } from './navigationHelpers';

const Wrapper = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: fixed;
  z-index: 2;
`;

function Navigation() {
  const [subNavVisible, setSubNavVisible] = useState(false);
  const [searchVisible, setSearchVisible] = useState(false);
  const { hasRole: crmEnabled } = useHasRole('feature_crm');
  const { hasRole: supplierChangeEnabled } = useHasRole(
    'feature_supplier_change',
  );

  return (
    <Wrapper>
      <Logo />
      <NavigationItem
        label="Suche"
        icon={Icons.Search}
        onClick={() => setSearchVisible(!searchVisible)}
      />
      <Search
        isVisible={searchVisible}
        setVisibility={(isVisible) => setSearchVisible(isVisible)}
      />
      <NavigationItem label="Dashboard" icon={Icons.Dashboard} href="/" exact />
      <NavigationItem label="Projekte" icon={Icons.Project} href="/project" />
      <NavigationItem
        label="Abrechnung"
        icon={Icons.Accounting}
        href="/accounting"
      />
      <NavigationItem label="Tarife" icon={Icons.Tariff} href="/tariff" />
      <NavigationItem label="Berichte" icon={Icons.Report} href="/reports" />
      {supplierChangeEnabled && (
        <NavigationItem
          label="Supplier"
          icon={Icons.SupplierChange}
          href="/supplier"
        />
      )}
      {crmEnabled && (
        <NavigationItem label="Akquise-Tool" icon={CrmIcon} href="/crm" />
      )}
      <Box flex="2" />
      <NavigationItem
        label="Hilfe"
        icon={Icons.Help}
        onClick={handleHelpWidget}
      />
      <NavigationItem
        label="Einstellungen"
        icon={Icons.Settings}
        href="/settings"
      />
      <OutsideClickHandler
        onOutsideClick={() => {
          setSubNavVisible(false);
        }}
      >
        <NavigationItem
          label="Nutzer"
          icon={UserItem}
          isActive={subNavVisible}
          onlyIconActive
          onClick={() => setSubNavVisible(!subNavVisible)}
        />
        {subNavVisible && <UserSubNav />}
      </OutsideClickHandler>
    </Wrapper>
  );
}

export default Navigation;
