import React from 'react';
import { ColumnDefinition, Table } from '@ampeersenergy/ampeers-ui-components';

import { UnprocessedBooking } from '../../../../../graphql-types';

const columns: ColumnDefinition<UnprocessedBooking>[] = [
  {
    Header: 'Account',
    accessor: 'account',
  },
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Ref',
    accessor: 'ref',
  },
  {
    Header: 'date',
    accessor: 'date',
    type: 'date',
  },
  {
    Header: 'amount',
    accessor: 'amount',
    type: 'currency',
  },
  {
    Header: 'journal',
    accessor: 'journal',
  },
  {
    Header: 'sollstellungDate',
    accessor: 'sollstellungDate',
    type: 'date',
  },
  {
    Header: 'matchingNumber',
    accessor: 'matchingNumber',
  },
];

export const Unprocessed: React.FC<{
  entries?: UnprocessedBooking[];
  isLoading: boolean;
}> = ({ entries, isLoading }) => {
  return <Table data={entries || []} columns={columns} isLoading={isLoading} />;
};
