import React from 'react';
import { ThemeProvider } from '@ampeersenergy/ampeers-ui-components';
import type { Theme } from '@ampeersenergy/ampeers-ui-components';

export type { Theme };

export interface Themeprops {
  theme: Theme;
}

const App: React.FC = ({ children }) => (
  <ThemeProvider>{children}</ThemeProvider>
);

export default App;
