import { useCallback } from 'react';
import { getTrace } from '@ampeersenergy/ampeers-node-logger';

import { _ae_env_ } from '../../helpers/env';
import { getSession } from '../../services/session';

const buildHeaders = () => {
  const { token, clientId, appKey, userId } = getSession();

  const headers: HeadersInit = new Headers();
  // headers.set('Content-Type', 'application/json');
  if (appKey) headers.set('x-ampeers-app-verify', appKey);
  if (token) headers.set('x-ampeers-user-token', token);
  if (clientId) headers.set('x-ampeers-client-id', clientId);
  if (userId) headers.set('x-ampeers-user-id', userId);
  headers.set('x-ampeers-trace-id', getTrace());

  return headers;
};

export default function useDocumentUpload(contractId: string) {
  if (!_ae_env_.REACT_APP_BACKEND_BASE_URI) {
    throw new Error('_ae_env_.REACT_APP_BACKEND_BASE_URI is required');
  }

  const uploadDocument = useCallback(
    async (file, documentType) => {
      const formData = new FormData();

      formData.append('document', file);
      formData.append('contractId', contractId);
      formData.append('documentType', documentType);

      const response = await fetch(
        `${_ae_env_.REACT_APP_BACKEND_BASE_URI}/documents/contract/upload`,
        {
          method: 'POST',
          body: formData,
          headers: buildHeaders(),
        },
      );

      const data = await response.json();

      if (response.status !== 200) {
        if (data.error) {
          throw new Error(data.error);
        }
        throw new Error('Upload fehlgeschlagen.');
      }

      return data;
    },
    [contractId],
  );

  return {
    uploadDocument,
  };
}
