import {
  AlertRetryable,
  Button,
  ColumnDefinition,
  Header,
  Icons,
  Main,
  Table,
  TableRows,
} from '@ampeersenergy/ampeers-ui-components';
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { Overlay } from '../../components';
import { DocTitle } from '../../components/docTitle';
import { UnitRatioRenderer } from '../../components/table/renderer';
import { UploadModal } from '../../components/importer/uploadModal';
import {
  Plant,
  ReadProjectsQueryDocument,
  useReadProjectsQueryQuery,
} from '../../graphql-types';
import { EMPTY_STR } from '../../helpers/formatStrings';
import CreatePlantFlow from '../plant/create/createPlantFlow';

const CreatePlantButton = styled(Button)`
  margin-left: 6px;
`;

const columns: ColumnDefinition<any>[] = [
  {
    Header: 'Projekt',
    accessor: 'name',
    minWidth: 100,
    style: { verticalAlign: 'top ' },
  },
  {
    Header: 'Kundenanlagen',
    accessor: 'plantName',
    style: { verticalAlign: 'top ' },
  },
  {
    Header: 'Straße',
    accessor: 'street',
    style: { verticalAlign: 'top ' },
  },
  {
    Header: 'Ort',
    accessor: 'city',
    style: { verticalAlign: 'top ' },
  },
  {
    Header: 'Ratio',
    accessor: 'ratio',
    filterable: false,
    Cell: UnitRatioRenderer,
    sortType: (rowA, rowB) => {
      return rowA.original.ratio[1] / (rowA.original.ratio[2] || 1) >
        rowB.original.ratio[1] / (rowB.original.ratio[2] || 1)
        ? 1
        : -1;
    },
    style: { verticalAlign: 'top ' },
    minWidth: 290,
  },
];

const projectPageTitle = 'Projekte';

const ProjectPage: React.FC = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);

  const { data, error, loading } = useReadProjectsQueryQuery({
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
  });

  const tableData: any[] = useMemo(() => {
    if (data?.readProjects) {
      return data.readProjects.map(({ plants, id, name }) => {
        let totalUnits = 0;
        let totalThirdSupplied = 0;
        let totalunitsMembers = 0;
        // let totalEmpty = 0;
        const addresses: { [index: string]: string[] } = {};

        (plants as Plant[]).forEach(({ kpis, mainAddress }: Plant) => {
          if (kpis) {
            const { units, /* unitsEmpty, */ unitsMembers } = kpis;
            totalUnits += units;
            totalunitsMembers += unitsMembers;
            // totalEmpty += unitsEmpty || 0;
            totalThirdSupplied += units - unitsMembers;
          }

          /**
           * we create a map with all streetNames as key
           * and the streetNumbers as values to filter the addresses
           * to those parts which differ
           */
          if (
            !mainAddress ||
            !mainAddress.streetName ||
            !mainAddress.streetNumber
          )
            return;

          if (
            !Object.prototype.hasOwnProperty.call(
              addresses,
              mainAddress.streetName,
            )
          ) {
            addresses[mainAddress.streetName] = [];
          }

          if (
            !addresses[mainAddress.streetName].includes(
              mainAddress.streetNumber,
            )
          ) {
            addresses[mainAddress.streetName].push(
              mainAddress.streetNumber.split(',').join(', '),
            );
          }
        });

        const streets = [];

        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const k in addresses) {
          streets.push(`${k} ${addresses[k].join(', ')}`);
        }

        let plantZipCode;
        let plantCity;

        if (plants.length !== 0 && plants[0].mainAddress) {
          plantZipCode = plants[0].mainAddress.zipCode;
          plantCity = plants[0].mainAddress.city;
        }

        let address = '';

        if (streets.length > 0) {
          address = `${address} ${streets.join(', ')}`;
        }

        let city = '';

        if (plantCity) {
          city = `${city} ${plantCity}`;
        }

        if (plantZipCode) {
          city = `${city} ${plantZipCode}`;
        }

        if (address === '') {
          address = EMPTY_STR;
        }

        const plantNames =
          plants.length === 0
            ? EMPTY_STR
            : plants.map(({ name: plantName }) => plantName).join(', ');

        return {
          id,
          name,
          plantName: plantNames,
          street: address,
          city,
          // tarriff: "",
          units: String(totalUnits),
          unitsThird: `${totalThirdSupplied} (${(
            (totalThirdSupplied / totalUnits) *
            100
          ).toFixed(0)}%)`,
          childPlantId: plants.length === 1 ? plants[0].id : null,
          childPlantName: plants.length === 1 ? plants[0].name : null,
          ratio: [totalUnits, totalunitsMembers, totalThirdSupplied],
        };
      });
    }

    return [];
  }, [data]);

  const rowLink = useCallback(({ row }: { row: TableRows<any> }) => {
    const entry = row.original;
    if (entry.childPlantId) {
      return `/project/${entry.id}/plant/${entry.childPlantId}`;
    }
    return `/project/${entry.id}`;
  }, []);

  if (error) {
    return <AlertRetryable error={error} />;
  }

  return (
    <>
      <DocTitle titleParts={[projectPageTitle]} />
      <Main>
        <Header
          Icon={Icons.Project}
          actions={() => (
            <>
              <Button secondary onClick={() => setUploadModalOpen(true)}>
                Import
              </Button>
              <CreatePlantButton
                data-testid="createProjectButton"
                onClick={() => setModalIsOpen(true)}
              >
                Kundenanlage anlegen
              </CreatePlantButton>
            </>
          )}
        >
          {projectPageTitle}
        </Header>
        <Table
          data={tableData}
          columns={columns}
          rowLink={rowLink}
          withAlternatingRows
          withFilter
          withPagination
          filterKind={projectPageTitle}
          isLoading={loading}
          withRouting
          compact
        />
      </Main>
      <Overlay
        title="Kundenanlage anlegen"
        onClose={() => setModalIsOpen(false)}
        isOpen={modalIsOpen}
      >
        <CreatePlantFlow
          onAbort={() => setModalIsOpen(false)}
          onSuccess={() => setModalIsOpen(false)}
        />
      </Overlay>
      {uploadModalOpen && <UploadModal setIsOpen={setUploadModalOpen} />}
    </>
  );
};

export const preloadQuery = ReadProjectsQueryDocument;

export default ProjectPage;
