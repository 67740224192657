import gql from 'graphql-tag';

export default gql`
  query readBalancingAreaAccounts {
    readBalancingAreaAccounts {
      id
      name
      eic
      transmissionSystemOperator {
        id
        name
        code
      }
    }
  }
`;
