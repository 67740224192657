import styled from 'styled-components';
import React from 'react';
import ContentLoader from 'react-content-loader';

import Label from './label';

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Flex = styled.div`
  flex: 1;
`;

export const Grid = styled.div<{ $flow?: 'column' | 'row' }>`
  display: grid;
  grid-gap: ${({ $flow }) => ($flow === 'row' ? '0.5rem' : '1rem')};
  grid-auto-flow: ${({ $flow }) => $flow || 'column'};
`;

export const Segment = styled.div<{ hasTitle: boolean }>`
  margin-bottom: ${(props) => (props.hasTitle ? '7px' : '0px')};
`;

export const Bold = styled.div`
  font-weight: 600;
`;

export const ShadowBox = styled.div`
  background: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  border-radius: 4px;
`;

export const Padded = styled.div`
  padding: 16px;
`;

export const PaddedShadowBox = styled(ShadowBox)`
  padding: 16px;
`;

const EntryContent = styled.div<{ growContent: boolean }>`
  flex: ${(props) => (props.growContent ? '1' : '0')};
  font-size: 15px;
  white-space: nowrap;
`;

const EntryContentUnit = styled.span`
  padding-left: 8px;
  flex: 1;
`;
const Prependix = styled.div`
  padding-right: 8px;
  display: inline-block;
`;

export function Entry({
  title,
  unit,
  children,
  isLoading,
  prependix,
  dataTestId,
  growContent = true,
}: {
  title: React.ReactNode;
  unit?: React.ReactNode;
  children: React.ReactNode;
  prependix?: React.ReactNode;
  isLoading?: boolean;
  dataTestId?: string;
  growContent?: boolean;
}) {
  const hasTitle = Boolean(title);
  return (
    <Segment hasTitle={hasTitle}>
      {isLoading ? (
        <ContentLoader speed={2} height={45} style={{ width: '100%' }}>
          <rect x="0" y="15" rx="1" ry="1" width="100%" height="6" />
          <rect x="0" y="35" rx="1" ry="1" width="50%" height="6" />
        </ContentLoader>
      ) : (
        <>
          {hasTitle && <Label>{title}</Label>}
          <EntryContent data-testid={dataTestId} growContent={growContent}>
            {prependix && <Prependix>{prependix}</Prependix>}
            {children}
            {unit && <EntryContentUnit>{unit}</EntryContentUnit>}
          </EntryContent>
        </>
      )}
    </Segment>
  );
}
