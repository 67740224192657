export function addLeadingZero(value: number | string): string {
  const numberValue = typeof value === 'string' ? +value : value;

  if (Number.isNaN(numberValue)) {
    throw new Error('Value is not a number');
  }
  return numberValue < 10 ? `0${numberValue}` : `${numberValue}`;
}

/**
 * Parses a string in european number notation to a float. Example: '1.234,56' -> 1234.56
 * @param numberString the number string to parse
 */
export function parseStringToFloat(numberString: string): number {
  return parseFloat(numberString.replace(/\./g, '').replace(',', '.'));
}

/**
 * Formats a number to a string in european number notation. Example: 1234.56 -> '1.234,56'
 * @param number the number to format
 */
export function formatNumberToEuropeanStyle(number: number): string {
  if (Number.isNaN(number)) {
    return '';
  }
  return number.toLocaleString('de-DE', { maximumFractionDigits: 2 });
}
