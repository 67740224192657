import { gql, useApolloClient, ApolloError } from '@apollo/client';
import { useState, useEffect } from 'react';

const queries = {
  plant: gql`
    query plantDetail($id: ID!) {
      readPlant(id: $id) {
        id
        projectId
        name
      }
    }
  `,
  contract: gql`
    query readContract($id: ID!) {
      readContract(contractId: $id) {
        id
        label

        customer {
          id
          label
          person {
            name
          }
        }
      }
    }
  `,
};

type SupportedTypes = keyof typeof queries;

interface ResolveByIdOptions {
  type: SupportedTypes;
  id: string;
  formVariables?: any;
}

export const useResolveById = ({ type, id }: ResolveByIdOptions) => {
  const client = useApolloClient();
  const [result, setResult] = useState<{
    loading: boolean;
    data?: any;
    error?: ApolloError;
  }>({
    loading: true,
  });

  if (!Object.prototype.hasOwnProperty.call(queries, type)) {
    throw new Error(`useResolveById: '${type}' is not supported`);
  }

  useEffect(() => {
    (async () => {
      try {
        const { data, error } = await client.query({
          query: queries[type],
          fetchPolicy: 'cache-first',
          variables: {
            id,
          },
        });

        if (error) {
          return setResult({ error, loading: false });
        }

        const resultKey = data ? Object.keys(data)[0] : null;
        const _result =
          data && resultKey && data[resultKey] !== undefined
            ? data[resultKey]
            : null;

        setResult({ data: _result, loading: false });
      } catch (err) {
        if (err instanceof ApolloError) {
          setResult({
            error: err,
            loading: false,
          });
        }
      }
    })();
  }, [client, id, type]);

  return result;
};
