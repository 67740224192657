import gql from 'graphql-tag';

export const readAccountingDetailsReport = gql`
  query readAccountingDetailsReport($opcName: String!) {
    readAccountingDetailsReport(opcName: $opcName) {
      contractLabel
      accountingReceivableAccount
      accountingNumber
      accountingPeriodStartDate
      accountingPeriodEndDate
      accountingGenerationDate
      accountingNetValue
      accountingTaxValue
      accountingGrossValue
      accountingDiff
      consumption
      reduction
      reductionStartDate
    }
  }
`;
