import styled, { withTheme } from 'styled-components';

export const Label = styled.div`
  font-weight: 600;
  font-size: 12px;
  color: #090909;
  letter-spacing: 1.29px;
  text-transform: uppercase;
  padding-left: 5px;
  padding-right: 10px;
`;

export const ItemList = styled.div`
  background: #f5f5f5;
  margin-left: 10px;
`;

export const Item = styled.div`
  border: 1px solid #cecece;
  border-radius: 4px;
  display: inline-flex;
  margin-right: 2px;
  align-items: center;
  padding: 0px 0px 0px 8px;
  background: #fff;
`;

export const ItemLabel = styled.span`
  font-weight: 600;
  font-size: 12px;
  color: #119142;
  letter-spacing: 1.29px;
  text-transform: uppercase;
  padding-top: 1px;
`;

export const ItemValue = styled.span`
  font-size: 14px;
  color: #404040;
  letter-spacing: 0;
  margin-left: 5px;
`;

export const TableStyles = withTheme(styled.div`
  .wrapper {
    background: #ffffff;
    // box-shadow: 1px 2px 4px 0 rgba(0,0,0,0.08);
    // border-radius: 4px;
    font-size: 14px;
    width: 100%;
    // border: 1px solid #efefef;
    overflow: hidden;

    ul {
      list-style: none;
      border-spacing: 0;
      width: 100%;

      li {
        a {
          display: block;
          color: #090909;
        }

        :last-child {
          td {
            border-bottom: 0;
          }
        }

        :nth-child(2n - 1) {
          background: #f7f7f7;
        }
      }

      li {
        margin: 0;
        padding: 0.5rem;
        // border-right: 1px solid #e8e8e8;

        :last-child {
          border-right: 0;
        }
      }
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0px;
    }

    li {
      // border-bottom: 1px solid #eaeaea;
      padding: 8px 15px;
      // cursor: pointer;

      &:last-child {
        border: none;
      }

      &.react-autosuggest__suggestion--highlighted {
        background: #dbffd4;
      }
    }
  }
`);

export const EmptyResult = styled.div`
  padding: 50px 0px;
  text-align: center;
  color: #616161;
  border-top: 1px solid #eaeaea;
`;
