import { FlexRow, Icons, Relation } from '@ampeersenergy/ampeers-ui-components';
import React from 'react';
import styled, { useTheme } from 'styled-components';

const ResultIcon = styled.div`
  margin-left: 10px;
`;

const ContractLabel = styled.span`
  color: ${(props) => props.theme.palette.textMuted};
  display: inline-block;
`;

export function ContractNameLabel({
  contractName,
  contractLabel,
}: {
  contractName?: string;
  contractLabel: string;
}) {
  const theme = useTheme();
  return (
    <FlexRow>
      {contractName && <Relation icon={Icons.Customer} title={contractName} />}
      <ResultIcon>
        <Icons.Contract size={20} color={theme.palette.textMuted} />
      </ResultIcon>
      <ContractLabel>{contractLabel}</ContractLabel>
    </FlexRow>
  );
}
