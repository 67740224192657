import React from 'react';
import { Provider } from 'react-redux';

import { boardStore } from './presentations/crm-board/crm-board.redux';
import CrmDataContainer from './containers/crm-data/crm-data-container';
import CrmBoard from './presentations/crm-board/crm-board';

function CrmPage() {
  return (
    <Provider store={boardStore}>
      <CrmDataContainer
        render={({ data }: any) => {
          // eslint-disable-next-line no-console
          console.log('Should Render', data);
          return <CrmBoard data={{ ...data }} />;
        }}
      />
    </Provider>
  );
}

export default CrmPage;
