import { Button } from '@ampeersenergy/ampeers-ui-components';
import { DocumentNode, useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import React from 'react';
import styled from 'styled-components';
import * as yup from 'yup';

import { ErrorMsg, FormikInput } from '../../../components';
import {
  SupplierChangeWorkflowType,
  useReadContractQuery,
  UpdateSupplierChangeLocalContractDocument,
  UpdateSupplierChangeCancellationContractDocument,
  UpdateSupplierChangeThirdPartyContractDocument,
  GetSupplierChangeWorkflowStateDocument,
} from '../../../graphql-types';

const ButtonStyled = styled(Button)`
  margin-top: 5px;
  margin-bottom: 5px;
`;

type UpdateContractAttributes = {
  label: string;
  message: string;
  mutation: DocumentNode;
};

function getUpdateContractFormLabelsByType(
  type?: SupplierChangeWorkflowType,
): UpdateContractAttributes {
  switch (type) {
    case SupplierChangeWorkflowType.Cancellation:
      return {
        label: 'Lieferende bestehender Stromvertrag',
        message: `Trage das erhaltende Lieferende ein, um den Vertrag entsprechend zu aktualisieren. Wir setzen dann den Lieferbeginn des neuen Vertrags auf den Folgetag.`,
        mutation: UpdateSupplierChangeCancellationContractDocument,
      };
    case SupplierChangeWorkflowType.SupplierChangeThirdParty:
      return {
        label: 'Lieferende',
        message: `Trage den erhaltenden Lieferbeginn ein, um das Lieferende des Vertrags entsprechend zu aktualisieren.`,
        mutation: UpdateSupplierChangeThirdPartyContractDocument,
      };
    case SupplierChangeWorkflowType.SupplierChangeLocalSupply:
    default:
      return {
        label: 'Lieferbeginn',
        message: `Trage den erhaltenden Lieferbeginn ein, um den Vertrag entsprechend zu aktualisieren.`,
        mutation: UpdateSupplierChangeLocalContractDocument,
      };
  }
}

export function UpdateContract({
  workflowId,
  contractId,
  customerLabel,
  deliveryDate,
  newMalo,
  reduction,
  reductionStartDate,
  type,
}: {
  workflowId: string;
  contractId: string;
  customerLabel?: string;
  deliveryDate: string | undefined;
  newMalo?: string;
  reduction?: string;
  reductionStartDate?: string;
  type?: SupplierChangeWorkflowType;
}) {
  const { label, message, mutation } =
    getUpdateContractFormLabelsByType(type) ?? {};

  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState<string[]>([]);
  const [genericErrors, setGenericErrors] = React.useState<string[]>([]);
  const { data: contractData } = useReadContractQuery({
    variables: { contractId },
  });

  React.useEffect(() => {
    if (deliveryDate) {
      setLoading(false);
    }
  }, [deliveryDate]);

  const contract = contractData?.readContract;

  const [updateContract] = useMutation(mutation);

  return (
    <>
      {errors.length > 0 && (
        <ErrorMsg
          retryable={false}
          error={`Folgende Informationen fehlen im Vertrag: ${errors.join(
            ', ',
          )}`}
        />
      )}
      {genericErrors.length > 0 && (
        <ErrorMsg
          retryable={false}
          error={`Fehler beim update der Vertragsdaten: ${genericErrors.join(
            ', ',
          )}`}
        />
      )}
      <Formik
        initialValues={{ deliveryDate, newMalo, reduction, reductionStartDate }}
        validationSchema={yup.object().shape({
          deliveryDate: yup.string().required(),
          reduction:
            type === SupplierChangeWorkflowType.SupplierChangeLocalSupply
              ? yup.number().required()
              : yup.number(),
          reductionStartDate:
            type === SupplierChangeWorkflowType.SupplierChangeLocalSupply
              ? yup.string().required()
              : yup.string(),
        })}
        onSubmit={async (values) => {
          const melo = contract?.contractMeter?.meters[0].melo;
          const malo = values.newMalo ?? contract?.contractMeter?.malo;
          const status = contract?.status;
          if (!status) {
            setErrors((err) => [...err, 'status']);
            return;
          }
          if (!type) {
            setGenericErrors((err) => [
              ...err,
              'WorkflowType konnte nicht geladen weren',
            ]);
            return;
          }

          try {
            const payload = createUpdateContractPayload(
              type,
              status,
              values.reduction,
              values.reductionStartDate,
              melo ?? undefined,
              malo ?? undefined,
              values.deliveryDate,
              customerLabel,
            );

            if (values.deliveryDate) {
              setLoading(true);
              await updateContract({
                variables: {
                  workflowId,
                  payload,
                },
                refetchQueries: [
                  {
                    query: GetSupplierChangeWorkflowStateDocument,
                    variables: { workflowId },
                  },
                ],
              });
            }
          } catch (e) {
            setGenericErrors((err) => [...err, String(e)]);
          }
        }}
      >
        {({ values }) => (
          <UpdateContractForm
            hasError={errors.length > 0}
            loading={loading}
            deliveryDate={deliveryDate}
            newMalo={newMalo}
            reduction={reduction}
            reductionStartDate={reductionStartDate}
            currentValues={values}
            label={label}
            message={message}
            type={type}
          />
        )}
      </Formik>
    </>
  );
}

function createUpdateContractPayload(
  type: SupplierChangeWorkflowType,
  status: string,
  reductionValue?: string,
  reductionStartDate?: string,
  melo?: string,
  malo?: string,
  deliveryDate?: string,
  customerLabel?: string,
) {
  // eslint-disable-next-line default-case
  switch (type) {
    case SupplierChangeWorkflowType.SupplierChangeLocalSupply:
      if (
        !customerLabel ||
        !deliveryDate ||
        !reductionValue ||
        !reductionStartDate
      ) {
        throw new Error('Missing attributes for contract update payload');
      }
      return {
        customerLabel,
        date: deliveryDate,
        status,
        reductionValue: Number.parseFloat(reductionValue),
        reductionStartDate,
      };
    case SupplierChangeWorkflowType.SupplierChangeThirdParty:
    case SupplierChangeWorkflowType.Cancellation:
      return {
        date: deliveryDate,
        status,
        melo,
        malo,
      };
  }
}

function UpdateContractForm({
  deliveryDate,
  newMalo,
  reduction,
  reductionStartDate,
  loading,
  hasError,
  currentValues,
  message,
  label,
  type,
}: {
  deliveryDate: string | undefined;
  newMalo?: string | undefined;
  reduction?: string;
  reductionStartDate?: string;
  loading: boolean;
  hasError: boolean;
  currentValues: {
    deliveryDate: string | undefined;
    newMalo: string | undefined;
    reduction: string | undefined;
    reductionStartDate: string | undefined;
  };
  message?: string;
  label?: string;
  type?: SupplierChangeWorkflowType;
}) {
  const buttonDisabled =
    loading ||
    hasError ||
    !currentValues.deliveryDate ||
    (type === SupplierChangeWorkflowType.SupplierChangeLocalSupply &&
      (!currentValues.reduction || !currentValues.reductionStartDate)) ||
    (type === SupplierChangeWorkflowType.SupplierChangeThirdParty &&
      !currentValues.newMalo);

  return (
    <Form>
      {message}
      <FormikInput
        id="deliveryDate"
        name="deliveryDate"
        label={label ?? 'Lieferbeginn'}
        type="Date"
        disabled={!!deliveryDate || loading}
      />
      {type === SupplierChangeWorkflowType.SupplierChangeLocalSupply ? (
        <>
          <FormikInput
            id="reduction"
            name="reduction"
            label="Abschlag"
            disabled={!!reduction || loading}
          />
          <FormikInput
            id="reductionStartDate"
            name="reductionStartDate"
            label="Abschlagsbeginn"
            type="Date"
            disabled={!!reductionStartDate || loading}
          />
        </>
      ) : null}
      {type === SupplierChangeWorkflowType.SupplierChangeThirdParty ? (
        <FormikInput
          id="newMalo"
          name="newMalo"
          label="Neue Malo"
          disabled={!!newMalo || loading}
        />
      ) : null}
      {!deliveryDate && (
        <div>
          <ButtonStyled
            isLoading={loading}
            disabled={buttonDisabled}
            type="submit"
          >
            Vertrag aktualisieren
          </ButtonStyled>
        </div>
      )}
    </Form>
  );
}
