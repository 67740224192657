import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';

import { Role } from '../graphql-types';

const query = gql`
  query readCurrentUserRoles {
    readCurrentUser {
      id
      roles {
        name
        meta
      }
    }
  }
`;

export const useHasRole = (roleName: string) => {
  const { loading, error, data, refetch } = useQuery(query);

  let hasRole = null;

  if (data) {
    hasRole =
      data.readCurrentUser.roles.findIndex(
        (role: Role) => role.name === roleName,
      ) !== -1;
  }

  return {
    loading,
    error,
    hasRole,
    refetch,
  };
};
