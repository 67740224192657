import React, { forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';
import { useFormikContext } from 'formik';
import { Label } from '@ampeersenergy/ampeers-ui-components';

import { FlexRow, Flex } from '../../../../components';
import { GraphqlFormField } from '../../../../components/graphql-form/render';

const StyledLabel = styled.label`
  margin-left: 5px;
  font-size: 14px;
`;

const Wrap = styled.div`
  min-width: 690px;
`;

const Margin = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
`;

interface PricesheetFormProps {
  setEnergyPriceMixed: (energyPriceMixed: boolean) => void;
  isEnergyPriceMixed: boolean;
}

const PricesheetForm = forwardRef(
  (
    { setEnergyPriceMixed, isEnergyPriceMixed }: PricesheetFormProps,
    ref?: any,
  ) => {
    const { getFieldProps, setFieldValue } = useFormikContext();

    useImperativeHandle(ref, () => ({
      getValue(path: string) {
        return getFieldProps(path).value;
      },
    }));

    return (
      <Wrap>
        <FlexRow>
          <Flex>
            <GraphqlFormField name="priceSheet.name" />
            <GraphqlFormField name="priceSheet.startDate" />
            <GraphqlFormField name="priceSheet.basicPrice" />
            <Label>Arbeitspreis</Label>
            <Flex>
              <input
                type="radio"
                id="energyPriceMixed"
                data-testid="energyPriceMixed"
                checked={isEnergyPriceMixed}
                onClick={() => {
                  setFieldValue('priceSheet.energyPriceLocal', '', false);
                  setFieldValue('priceSheet.energyPriceResidual', '', false);
                  setEnergyPriceMixed(true);
                }}
                readOnly
              />
              <StyledLabel htmlFor="energyPriceMixed">Gemischt</StyledLabel>
            </Flex>
            <Flex>
              <input
                type="radio"
                id="energyPriceLocalResidual"
                data-testid="energyPriceLocalResidual"
                checked={!isEnergyPriceMixed}
                onClick={() => {
                  setFieldValue('priceSheet.energyPrice', '', false);
                  setEnergyPriceMixed(false);
                }}
                readOnly
              />
              <StyledLabel htmlFor="energyPriceLocalResidual">
                Getrennt
              </StyledLabel>
            </Flex>
            {isEnergyPriceMixed ? (
              <FlexRow>
                <Flex>
                  <GraphqlFormField name="priceSheet.energyPrice" />
                </Flex>
              </FlexRow>
            ) : (
              <>
                <FlexRow>
                  <Flex>
                    <GraphqlFormField name="priceSheet.energyPriceLocal" />
                  </Flex>
                </FlexRow>
                <FlexRow>
                  <Flex>
                    <GraphqlFormField name="priceSheet.energyPriceResidual" />
                  </Flex>
                </FlexRow>
              </>
            )}
            <Margin>
              <GraphqlFormField
                name="priceSheet.ignorePriceGuarantee"
                label="Bestehende Preisgarantien ignorieren"
              />
              Dies kann ein Sonderkündigungsrecht für bestehende Verträge zur
              Folge haben.
            </Margin>
          </Flex>
        </FlexRow>
      </Wrap>
    );
  },
);

export default PricesheetForm;
