import React, { useState, useCallback, useMemo } from 'react';
import ContentLoader from 'react-content-loader';
import styled from 'styled-components';
import { get, omit } from 'lodash';
import { useFormikContext, FieldArray } from 'formik';
import { Button } from '@ampeersenergy/ampeers-ui-components';

import { EMPTY_STR } from '../../helpers/formatStrings';
import Label from '../label';
import RelationSelect from '../relationSelect';
import {
  FormikSelect,
  FormikRadio,
  FormikInput,
  InputGroup,
  FormElementStyle,
  FormikTextarea,
  FormikDropdown,
} from '../input';
import { FlexRow, Flex } from '../layout';
import { FormikAutoComplete } from '../autoComplete';
import SubTitle from '../subTitle';
import MeterSelect from '../form/relationSelects/meterSelect';

import { useGraphqlForm } from './hooks/useGraphqlForm';
import {
  useGraphqlFormField,
  GraphqlFormFieldIndentifier,
} from './hooks/useGraphqlFormField';
import { formatValueOfType } from './mapper';
import { StyledLink, ActionBtns, RadioButtonStyling } from './style';

function GraphqlFormMultiField(initialProps: GraphqlFormFieldIndentifier) {
  const [
    {
      values,
      labels,
      relation,
      type,
      asType,
      renderEmptyString,
      dataType,
      ...rest
    },
    { formVariables },
  ] = useGraphqlFormField(initialProps);
  const { values: formikValues } = useFormikContext();

  if ((values && !labels) || (!values && labels)) {
    throw new Error(
      'GraphqlForm: Annotation key `values` needs `labels` for mapping. Both are required',
    );
  }

  rest['data-testid'] = rest.id;

  if (relation) {
    if (relation === 'Meter') {
      return (
        // @ts-ignore
        <MeterSelect
          {...rest}
          type={relation}
          key={rest.id}
          formVariables={formVariables}
        />
      );
    }
    return (
      <RelationSelect
        {...rest}
        label={rest.label!}
        type={relation}
        key={rest.id}
        formVariables={formVariables}
      />
    );
  }
  if (values && labels && asType === 'Radio') {
    const selectLabels: string[] = [...labels];
    const selectValues: any[] = [...values];
    const selectedValue = get(formikValues, rest.name);
    const isSelected = selectValues.includes(selectedValue);
    return (
      <RadioButtonStyling compact>
        <FormikRadio {...rest} key={rest.id}>
          {selectValues.map((v: any, index: number) => (
            <div key={String(v)}>
              <input
                name={rest.name}
                type="radio"
                value={v}
                id={`${rest.name}-${String(v)}`}
                data-testid={`${rest.name}-${String(v)}`}
                defaultChecked={isSelected ? v === selectedValue : index === 0}
              />
              <label htmlFor={`${rest.name}-${String(v)}`}>
                {selectLabels[index]}
              </label>
            </div>
          ))}
        </FormikRadio>
      </RadioButtonStyling>
    );
  }
  if (values && labels && type === 'dropdown') {
    const selectLabels: string[] = [...labels];
    const selectValues: any[] = [...values];
    return (
      <FormikDropdown
        {...rest}
        key={rest.id}
        type={type}
        labels={selectLabels}
        values={selectValues}
      />
    );
  }

  if (values && labels) {
    const selectLabels: string[] = [...labels];
    const selectValues: any[] = [...values];

    return (
      <FormikSelect {...rest} key={rest.id}>
        <option disabled hidden value="">
          Bitte wählen
        </option>
        {selectValues.map((v: any, index: number) => (
          <option value={v} key={String(v)}>
            {selectLabels[index]}
          </option>
        ))}
      </FormikSelect>
    );
  }

  if (type === 'textarea' || asType === 'textarea') {
    return <FormikTextarea {...rest} key={rest.id} />;
  }

  return <FormikInput {...rest} key={rest.id} type={asType ?? type} />;
}

export const GraphqlFormInputGroup = styled(FlexRow)<{
  $spacingLarge?: boolean;
}>`
  > div {
    padding-left: ${(props) => (props.$spacingLarge ? '8px' : '3px')};
    padding-right: ${(props) => (props.$spacingLarge ? '8px' : '3px')};

    :first-child {
      padding-left: 0;
    }

    :last-child {
      padding-right: 0;
    }
  }

  .is-editing & > div {
    flex: 1;
  }
`;

export const Hint = styled.div`
  color: #ff9800;
  font-size: 12px;
  margin-top: 5px;
  margin-left: 6px;
`;

export function withGraphqlFormField<T>(
  WrappedComponent: React.ComponentType<T>,
) {
  // eslint-disable-next-line func-names
  return function (initialProps: any) {
    const [
      {
        value,
        values,
        labels,
        dataType,
        relation,
        renderEmptyString,
        linkTo,
        asType,
        formatValue,
        ...rest
      },
      { isEditing, isLoading },
    ] = useGraphqlFormField(initialProps);

    const key = rest.id;

    if (isLoading) {
      return (
        <ContentLoader
          id="content-loader-modal"
          key={key}
          speed={2}
          height={45}
          style={{ width: '100%' }}
        >
          <rect x="0" y="15" rx="1" ry="1" width="100%" height="6" />
          <rect x="0" y="35" rx="1" ry="1" width="50%" height="6" />
        </ContentLoader>
      );
    }

    if (!isEditing) {
      const groupClassnames = [];

      if (rest.appendix || rest.unit) {
        groupClassnames.push('with-appendix');
      }

      if (rest.prependix) {
        groupClassnames.push('with-prependix');
      }

      let selectedValue = value;

      if (values && labels) {
        const index = values.indexOf(value);

        if (index !== -1) {
          selectedValue = labels[index];
        }
      }

      const formattedValue = formatValue
        ? formatValue(selectedValue)
        : formatValueOfType(
            // @ts-ignore
            relation || asType || dataType,
            selectedValue,
          );

      if (renderEmptyString === false && formattedValue === EMPTY_STR) {
        return null;
      }

      return (
        <FormElementStyle key={key}>
          {rest.label && <Label>{rest.label}</Label>}
          <InputGroup className={groupClassnames.join(' ')}>
            {rest.prependix && (
              <div className="prependix">{rest.prependix}</div>
            )}
            {linkTo ? (
              <StyledLink to={linkTo}>{formattedValue}</StyledLink>
            ) : (
              formattedValue
            )}
            {rest.unit && formattedValue !== EMPTY_STR && (
              <div className="appendix">{rest.unit}</div>
            )}
            {rest.appendix && <div className="appendix">{rest.appendix}</div>}
          </InputGroup>
        </FormElementStyle>
      );
    }

    {
      const restInitialProps = omit(initialProps, 'formatValue');
      // @ts-ignore
      return <WrappedComponent {...(restInitialProps as T)} />;
    }
  };
}

export const GraphqlFormAutoComplete = withGraphqlFormField(FormikAutoComplete);
export const GraphqlFormSelect = withGraphqlFormField(FormikSelect);
export const GraphqlFormField = withGraphqlFormField(GraphqlFormMultiField);

const Sidebar = styled(FlexRow)`
  min-width: 200px;
  border-right: 1px solid #eaeaea;
  margin-right: 30px;
  padding: 20px 0px 20px 20px;
`;
const SidebarTitle = styled(SubTitle)`
  padding-left: 9px;
  margin-top: 0px;
`;

const MasterDetail = styled(FlexRow)``;

const Item = styled.div<{ selected: boolean }>`
  border-bottom: 1px solid #eeee;
  padding: 9px;
  border-right: 2px solid
    ${(props) => (props.selected ? props.theme.primaryColor : '#fff')};
  cursor: pointer;
  font-size: 14px;
`;

const Content = styled.div``;

interface GraphqlFormArrayProps {
  pathPrefixName: string;
  renderItem: (item: any, index: number, maxItems?: number) => React.ReactNode;
  renderForm: (fieldNamePrefix: string) => React.ReactNode;
  kind: string;
  icon?: React.ReactNode;
  showActionButtons?: boolean;
  maxItems?: number;
  sidebarTitle?: () => string;
  subTitle?: (selected: number) => string;
  isLoading?: boolean;
  hintLimitReached: string;
  setCurrentItem?: (index: number) => void;
}

export function GraphqlFormArray({
  pathPrefixName,
  renderItem,
  renderForm,
  kind,
  icon,
  maxItems,
  showActionButtons = true,
  sidebarTitle,
  subTitle,
  isLoading = false,
  hintLimitReached,
  setCurrentItem,
}: GraphqlFormArrayProps) {
  const { values, initialValues, validateForm, setTouched } =
    useFormikContext();
  const { isEditing } = useGraphqlForm();

  const [selected, setSelected] = useState(0);
  const items = useMemo(
    () => get(values, pathPrefixName) || [],
    [pathPrefixName, values],
  );

  const addAction = useCallback(
    async (arrayHelpers) => {
      const initial = get(initialValues, `${pathPrefixName}.[0]`);

      /**
       * set the current selected fields touched in order
       * to show the errors after validation
       */
      setTouched(
        Object.keys(initial).reduce(
          (acc, key) => ({
            ...acc,
            [`${pathPrefixName}.[${selected}].${key}`]: true,
          }),
          {},
        ),
      );

      const errors = await validateForm();

      if (Object.keys(errors).length === 0) {
        arrayHelpers.push(initial);
        setSelected(items?.length ?? 0);
        if (setCurrentItem) {
          setCurrentItem(items?.length ?? 0);
        }
      }
    },
    [
      initialValues,
      pathPrefixName,
      setTouched,
      validateForm,
      setCurrentItem,
      selected,
      items,
    ],
  );
  const maxItemsReached =
    maxItems === undefined ? false : !(items.length < maxItems);
  return (
    <FieldArray
      name={pathPrefixName}
      render={(arrayHelpers) => (
        <MasterDetail>
          <Sidebar>
            <Flex>
              {sidebarTitle && <SidebarTitle>{sidebarTitle()}</SidebarTitle>}
              {items?.map((item: any, index: number) => (
                <Item
                  onClick={() => setSelected(index)}
                  selected={index === selected}
                  key={index}
                >
                  {renderItem(item, index, maxItems)}
                </Item>
              ))}
            </Flex>
          </Sidebar>
          <Content>
            <SubTitle>
              {icon}
              {subTitle !== undefined ? subTitle(selected) : `${kind} anlegen`}
            </SubTitle>
            {isLoading ? null : (
              <>
                {items.length === 0 && (
                  <div>
                    <Button
                      onClick={() => {
                        addAction(arrayHelpers);
                      }}
                    >
                      Anlegen
                    </Button>
                  </div>
                )}
                {items && items[selected] && (
                  <>
                    {renderForm(`${pathPrefixName}.[${selected}].`)}

                    {isEditing && showActionButtons && (
                      <>
                        <ActionBtns>
                          <Button
                            secondary
                            onClick={() => {
                              arrayHelpers.remove(selected);
                              setSelected(selected - 1);
                            }}
                          >
                            Entfernen
                          </Button>
                          <Button
                            onClick={() => {
                              if (!maxItemsReached) {
                                addAction(arrayHelpers);
                              }
                            }}
                            disabled={maxItemsReached}
                          >
                            Weitere anlegen
                          </Button>
                        </ActionBtns>

                        {maxItemsReached && <Hint>{hintLimitReached}</Hint>}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </Content>
        </MasterDetail>
      )}
    />
  );
}
