import gql from 'graphql-tag';

export default gql`
  mutation createTariffsBatched($tariffs: [CreateTariffsBatchedInput!]!) {
    createTariffsBatched(tariffs: $tariffs) {
      __typename
      succeeded {
        nameInternal
        info
      }
      failed {
        nameInternal
        error
        message
      }
    }
  }
`;
