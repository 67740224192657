import React, {
  useState,
  useEffect,
  FormEvent,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import Autosuggest from 'react-autosuggest';
import styled from 'styled-components';
import { useField } from 'formik';
import ContentLoader from 'react-content-loader';

import { Input } from './input';
import ErrorMsg from './errorMsg';
import { Flex, FlexRow } from './layout';
import { TooltipInfo } from './TooltipInfo';

import { Icons, Label } from '.';

export const ItemValue = styled.div`
  padding: 8px 16px;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.gray[800]};
  letter-spacing: 0;
  margin-left: 5px;
  cursor: pointer;
`;

const Wrapper = styled.div`
  position: relative;

  .react-autosuggest__container {
    position: relative;
  }

  .react-autosuggest__suggestions-container {
    display: none;
  }

  .react-autosuggest__suggestions-container--open {
    display: block;
    background: ${({ theme }) => theme.palette.background};
    border: 1px solid ${({ theme }) => theme.palette.gray[300]};
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14);
    border-radius: 4px;
    z-index: 12;
    width: 100%;
    margin-top: -3px;
    position: absolute;
    max-height: 300px;
    overflow: scroll;
  }

  .react-autosuggest__section-container {
    border-top: 1px dashed ${({ theme }) => theme.palette.gray[400]};
  }

  .react-autosuggest__suggestions-list {
    position: relative;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0px;
  }

  li {
    border-bottom: 1px solid ${(props) => props.theme.palette.gray[100]};

    &:last-child {
      border: none;
    }

    &.react-autosuggest__suggestion--highlighted {
      background: ${(props) => props.theme.secondaryColor};
    }
  }

  .react-autosuggest__section-container {
    position: relative;
  }

  .react-autosuggest__section-title {
    color: ${(props) => props.theme.palette.text};
    background: #f1f1f1;
    padding: 8px 12px;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.15rem;
  }
`;

const SelectedWrapper = styled(FlexRow)`
  background: #f5f5f5;
  border-radius: 3.5px;
  color: #5050bf;
  border: 1px solid transparent;
  width: 100%;
  height: 41px;
`;

const SelectedWrapperMultiselect = styled(FlexRow)`
  border-radius: 3.5px;
  color: #5050bf;
  border: 1px solid #f5f5f5;
  width: 100%;
  height: 41px;
  margin-bottom: 1px;
`;

const SelectedClose = styled.div`
  padding: 0 10px;
  align-self: center;
  cursor: pointer;

  :hover svg {
    fill: ${(props) => props.theme.primaryColor};
  }
`;

const SelectedValue = styled(Flex)`
  padding: 6px 6px;
  margin: auto;
`;

interface SelectedSuggestionProps<T> {
  value: React.ReactNode;
  onClear(suggestion: T): void;
  suggestion: T;
}

function SelectedSuggestion<T>({
  value,
  onClear,
  suggestion,
}: SelectedSuggestionProps<T>) {
  return (
    <>
      <SelectedValue>{value}</SelectedValue>
      <SelectedClose
        onClick={() => onClear(suggestion)}
        data-testid="clear-suggestion"
      >
        <Icons.CloseIcon size={25} />
      </SelectedClose>
    </>
  );
}

/**
 * generate the suggestions, filter the already suggested items out
 */
function filterSuggestions<T>(
  inputValue: string,
  suggestions: T[],
  formatSuggestion: (suggestion: T) => string,
  userSelected: T[],
): Array<T> {
  if (inputValue.trim() === '' && userSelected.length === 0) {
    return suggestions;
  }

  const selectedFormatted = userSelected?.map((s) => formatSuggestion(s)) ?? [];

  return suggestions.filter((suggestion) => {
    const cur = formatSuggestion(suggestion);

    return (
      /**
       * filter out already selected
       */
      !selectedFormatted.includes(cur) &&
      /**
       * like search in current value
       */
      cur.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
    );
  });
}

interface FormikAutoCompleteProps<SuggestionT> {
  name: string;
  label: string;
  id: string;
  'data-testid'?: string;
  suggestions: SuggestionT[];
  selectedSuggestion?: SuggestionT | null;
  renderSuggestion?: (suggestion: SuggestionT) => React.ReactNode;
  formatSuggestion: (suggestion: SuggestionT) => string;
  getDataFromSuggestion: (suggestion: SuggestionT) => any;
  formatSelected?: (suggestion: SuggestionT) => React.ReactNode;
  loading: boolean;
  error?: any;
  appendix?: React.ReactNode;
  placeholder: string;
  multiselect?: boolean;
  disabled?: boolean;
  singleValuePrefill?: boolean;
  onChange?: (suggestion: SuggestionT) => void;
  onClear?: () => void;
}

export function FormikAutoComplete<SuggestionT extends { disabled?: boolean }>({
  name,
  label,
  id,
  suggestions,
  loading,
  error,
  renderSuggestion,
  formatSuggestion,
  getDataFromSuggestion,
  formatSelected,
  selectedSuggestion = null,
  multiselect,
  onChange,
  onClear,
  singleValuePrefill,
  ...rest
}: FormikAutoCompleteProps<SuggestionT>) {
  const [filtered, setFiltered] = useState<SuggestionT[]>([]);
  const [field, meta] = useField(name);
  const [userSelected, setUserSelected] = useState<SuggestionT[] | null>(null);
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef<HTMLInputElement>();
  const [selectedSuggestions, setSelectedSuggestions] = useState<SuggestionT[]>(
    [],
  );
  const firstUpdate = useRef(true);

  // update userSelected when suggestions or field changes
  useEffect(() => {
    if (!suggestions) {
      return;
    }

    const initialSuggestions = suggestions.filter((t) =>
      multiselect
        ? field.value.includes(getDataFromSuggestion(t))
        : field.value === getDataFromSuggestion(t),
    );

    if (initialSuggestions.length !== 0) {
      setSelectedSuggestions(initialSuggestions);
      setUserSelected(initialSuggestions);
    } else if (selectedSuggestion !== null) {
      setSelectedSuggestions([selectedSuggestion]);
      setUserSelected([selectedSuggestion]);
    }
  }, [
    suggestions,
    field.value,
    getDataFromSuggestion,
    multiselect,
    selectedSuggestion,
  ]);

  useEffect(() => {
    // When there is just one suggestion, prefill the value instead of showing a select with 1 value
    if (
      singleValuePrefill &&
      suggestions &&
      suggestions.length === 1 &&
      !field.value &&
      firstUpdate.current
    ) {
      firstUpdate.current = false;

      const suggestionData = getDataFromSuggestion(suggestions[0]);
      field.onChange({
        target: {
          name,
          id,
          value: multiselect
            ? [...field.value, suggestionData]
            : suggestionData,
        },
      });
    }
  }, [
    id,
    name,
    field,
    suggestions,
    getDataFromSuggestion,
    firstUpdate,
    multiselect,
    singleValuePrefill,
  ]);

  // clear selection from input component
  const clear = useCallback(() => {
    field.onChange({
      target: { name, id, value: multiselect ? [] : '' },
    });

    onClear?.();
    setInputValue('');
    setUserSelected(null);
    setSelectedSuggestions([]);
  }, [field, id, multiselect, name, onClear]);

  if (
    !field.value &&
    inputValue !== '' &&
    userSelected === null &&
    rest?.disabled
  ) {
    clear();
  }

  // clear selection from multiselect item
  const clearMultiselect = useCallback(
    (toRemove: SuggestionT) => {
      const nextSuggestions = selectedSuggestions.filter((s) => s !== toRemove);
      const nextValues = nextSuggestions.map((s) => getDataFromSuggestion(s));

      field.onChange({
        target: { name, id, value: nextValues },
      });
      setSelectedSuggestions(nextValues);
    },
    [field, getDataFromSuggestion, id, name, selectedSuggestions],
  );

  // input props passed to renderInput component
  const inputProps: any = useMemo(
    () => ({
      ...rest,
      value: inputValue,
      name,
      onChange: (_event: any, { newValue }: { newValue: string }) => {
        setInputValue(newValue);
        setUserSelected(null);
      },
      label,
      errorMsg: meta.error,
      touched: meta.touched,
      id,
      ref: inputRef,
    }),
    [id, inputValue, label, meta.error, meta.touched, name, rest],
  );

  // render input component
  const renderInput = useCallback(
    (props: any) => {
      const { tooltipText } = props;
      if (
        !multiselect &&
        userSelected &&
        userSelected.length &&
        formatSelected
      ) {
        return (
          <>
            {label !== null && (
              <Label>
                {label}
                {tooltipText && (
                  <TooltipInfo id={`${label}Tooltip`} text={tooltipText} />
                )}
              </Label>
            )}
            <SelectedWrapper>
              <SelectedSuggestion
                value={formatSelected(userSelected[0])}
                suggestion={userSelected[0]}
                onClear={clear}
              />
            </SelectedWrapper>
          </>
        );
      }
      return <Input {...props} />;
    },
    [multiselect, userSelected, formatSelected, label, clear],
  );

  // generate new suggestions
  const onSuggestionsFetchRequested = useCallback(
    ({ value }: { value: string }) => {
      setFiltered(
        filterSuggestions(
          value,
          suggestions,
          formatSuggestion,
          selectedSuggestions,
        ),
      );
    },
    [suggestions, formatSuggestion, selectedSuggestions],
  );

  // gets called when user selects one suggestion via keyboard or mouse
  const onSuggestionsClearRequested = useCallback(() => {
    if (!multiselect) {
      setFiltered([]);
    }
  }, [multiselect]);

  // when the user hovers one suggestion, format it nicely for the input
  const getSuggestionValue = useCallback(
    (suggestion: SuggestionT): string => {
      return formatSuggestion(suggestion);
    },
    [formatSuggestion],
  );

  // when the user selects a suggestion, change the field value
  const onSuggestionSelected = useCallback(
    (
      event: FormEvent<any>,
      { suggestion, method }: { suggestion: SuggestionT; method: string },
    ) => {
      if (suggestion.disabled) {
        return;
      }
      if (method === 'enter') {
        event.preventDefault();
      }

      const newSuggestion = getDataFromSuggestion(suggestion);

      field.onChange({
        target: {
          name,
          id,
          value: multiselect ? [...field.value, newSuggestion] : newSuggestion,
        },
      });
      setUserSelected(null);
      setInputValue('');
      const nextSelectedSuggestions = [...selectedSuggestions, suggestion];

      setSelectedSuggestions(nextSelectedSuggestions);

      setFiltered(
        filterSuggestions(
          '',
          suggestions,
          formatSuggestion,
          nextSelectedSuggestions,
        ),
      );
      // inputRef?.current?.blur();
      if (onChange) {
        onChange(suggestion);
      }
    },
    [
      field,
      formatSuggestion,
      getDataFromSuggestion,
      id,
      multiselect,
      name,
      selectedSuggestions,
      suggestions,
      onChange,
    ],
  );

  // format the suggestion in the dropdown list
  const _renderSuggestion = useCallback(
    (suggestion: SuggestionT): any => (
      <ItemValue>{formatSuggestion(suggestion)}</ItemValue>
    ),
    [formatSuggestion],
  );

  // determine if list of suggestions should be displayed
  const shouldRenderSuggestions = useCallback(
    () => multiselect || userSelected === null,
    [multiselect, userSelected],
  );

  if (loading) {
    return (
      <>
        <Label>{label}</Label>
        <ContentLoader
          key={`cl-${id}`}
          speed={2}
          height={40}
          style={{ width: '100%' }}
        >
          <rect x="0" y="0" rx="4" ry="4" width="100%" height="39" />
        </ContentLoader>
      </>
    );
  }

  if (error) {
    return <ErrorMsg message={error} />;
  }

  return (
    <Wrapper>
      <Autosuggest
        id={id}
        suggestions={filtered}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onSuggestionSelected={onSuggestionSelected}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion ?? _renderSuggestion}
        renderInputComponent={renderInput}
        inputProps={inputProps}
        highlightFirstSuggestion
        shouldRenderSuggestions={shouldRenderSuggestions}
      />
      {multiselect &&
        selectedSuggestions.length !== 0 &&
        selectedSuggestions?.map((s) => (
          <SelectedWrapperMultiselect key={getDataFromSuggestion(s)}>
            <SelectedSuggestion
              value={formatSelected?.(s) ?? s}
              suggestion={s}
              onClear={clearMultiselect}
            />
          </SelectedWrapperMultiselect>
        ))}
    </Wrapper>
  );
}
