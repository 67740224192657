import React from 'react';

export const WorkflowStepsContext = React.createContext<{
  disableSaveButton: boolean;
  handleDisableSaveButton: (disable: boolean) => void;
}>({
  disableSaveButton: false,
  handleDisableSaveButton: () => {},
});

export const useWorkflowStepsContext = () =>
  React.useContext(WorkflowStepsContext);

export default function WorkflowStepsProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [disableSaveButton, setDisableSaveButton] = React.useState(false);

  const handleDisableSaveButton = React.useCallback(
    (disable: boolean) => {
      setDisableSaveButton(disable);
    },
    [setDisableSaveButton],
  );

  const values = React.useMemo(
    () => ({
      disableSaveButton,
      handleDisableSaveButton,
    }),
    [disableSaveButton, handleDisableSaveButton],
  );

  return (
    <WorkflowStepsContext.Provider value={values}>
      {children}
    </WorkflowStepsContext.Provider>
  );
}
