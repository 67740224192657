import {
  LoginLayout,
  TwoFactorAuth,
} from '@ampeersenergy/ampeers-ui-components';
import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import ProductLogo from '../../assets/local-supply.png';
import { DocTitle } from '../../components/docTitle';
import { useCheck2FAuthMutation } from '../../graphql-types';

export function TwoFactorAuthPage() {
  const history = useHistory();
  const params = new URLSearchParams(useLocation().search);
  const token = params.get('token');
  const id = params.get('id');

  const onSuccess = useCallback(() => {
    history.replace({ pathname: '/' });
  }, [history]);

  return (
    <>
      <DocTitle titleParts={['2FA']} />
      <LoginLayout ProductLogo={ProductLogo}>
        <TwoFactorAuth
          id={id}
          token={token}
          onSuccess={onSuccess}
          useCheck2FAuthMutation={useCheck2FAuthMutation}
        />
      </LoginLayout>
    </>
  );
}
