import React from 'react';
import styled from 'styled-components';
import {
  Button,
  ModalProps,
  Modal,
  Bold,
  FlexRow,
  Icons,
} from '@ampeersenergy/ampeers-ui-components';

const Headline = styled(Bold)`
  padding-top: 20px;
  font-size: 15px;
`;
const Wrap = styled.div`
  width: 400px;
  font-size: 14px;
  p {
    padding-left: 12px;
    margin-top: 0px;
  }
`;

const Spacer = styled.div`
  width: 5px;
`;
const ActionBtns = styled.div`
  margin-top: 25px;
  min-width: 200px;
  display: inline-block;
`;

export interface ConfirmationDialogProps {
  text: string;
  onConfirm: () => void;
  onCancel: () => void;
}

function ConfirmationDialog({
  text,
  onConfirm,
  onCancel,
  ...rest
}: ConfirmationDialogProps & Omit<ModalProps, 'children' | 'onRequestClose'>) {
  return (
    <Modal {...rest} onRequestClose={onCancel}>
      <Wrap>
        <FlexRow>
          <Icons.Warning size={60} color="#A8E015" />
          <Headline>{text}</Headline>
        </FlexRow>
        <ActionBtns>
          <FlexRow>
            <Button onClick={onConfirm}>Ja</Button>
            <Spacer />
            <Button onClick={onCancel}>Nein</Button>
          </FlexRow>
        </ActionBtns>
      </Wrap>
    </Modal>
  );
}

export default ConfirmationDialog;
