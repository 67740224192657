import * as React from 'react';

import 'styled-components/macro';

import GraphQlForm from '../../../components/graphql-form';
import { ClientDetailsInput } from '../../../graphql-types';
import readClientDetails from '../../../queries/readClientDetails';

interface DocumentsSettingsFormWrapperProps {
  children: React.ReactNode;
  isLoading?: boolean;
  initialValues?: ClientDetailsInput;
  validation?: any;
  onBeforeSubmit?: (values: ClientDetailsInput) => Promise<ClientDetailsInput>;
}

export function DocumentsSettingsFormWrapper({
  children,
  initialValues,
  isLoading,
  validation,
  onBeforeSubmit,
}: DocumentsSettingsFormWrapperProps) {
  return (
    <GraphQlForm
      submitAllValues
      startInEdit
      hideCancel
      isLoading={isLoading}
      mutation="updateClientDetails"
      readDocumentFields={['success']}
      refetchQueries={[
        {
          query: readClientDetails,
        },
      ]}
      onBeforeSubmit={(props: ClientDetailsInput) => {
        const { numberOfFooters, contact } = props;
        const finalProps: ClientDetailsInput = {
          ...props,
          contact: {
            ...contact,
            footer3:
              numberOfFooters && numberOfFooters >= 3 ? contact?.footer3 : '',
            footer4:
              numberOfFooters && numberOfFooters >= 4 ? contact?.footer4 : '',
          },
        };
        if (onBeforeSubmit) {
          return onBeforeSubmit(finalProps);
        }
        return new Promise<ClientDetailsInput>((resolve) =>
          resolve(finalProps),
        );
      }}
      values={initialValues}
      validation={validation}
    >
      {children}
    </GraphQlForm>
  );
}
