import React from 'react';

import { CreateFlow, Overlay } from '../../../components';
import { DocTitle } from '../../../components/docTitle';
import { Plant } from '../../../graphql-types';

import EndMessageCreateContract from './endMessageCreateOverlay';
import CreateMeasurementContractFlow from './measurementContract';
import CreateTenantContract from './tenantContract/createTenantContract';

const createOverlayTitle = 'Vertrag anlegen';
const createTenantTitle = 'Mieter anlegen';

function OverlayTitleSnippet() {
  return <DocTitle titleParts={[createOverlayTitle]} />;
}

export default function CreateOverlay({
  plant,
  kind,
  isVisible,
  onClose,
  values,
  defaultValues,
  variables,
}: {
  kind: 'tenant' | 'generationPlant' | 'operator';
  plant: Pick<
    Plant,
    'id' | 'balancingAreaAccountId' | 'projectId' | 'addresses' | 'name'
  >;
  isVisible: boolean;
  onClose: () => void;
  values?: any;
  defaultValues?: any;
  variables?: any;
}) {
  const sharedProps = {
    onDone: onClose,
    onAbort: onClose,
    values,
    variables: {
      ...variables,
      plantId: plant.id,
      addresses:
        plant.addresses.map((a) => {
          const { id, ...rest } = a;
          return rest;
        }) ?? [],
    },
    formVariables: {
      balancingAreaAccountId: plant.balancingAreaAccountId,
      plantId: plant.id,
      projectId: plant.projectId,
      kind,
    },
    defaultValues,
  };

  if (!isVisible) {
    return null;
  }
  switch (kind) {
    case 'tenant':
      return (
        <>
          <DocTitle titleParts={[createTenantTitle]} />
          <Overlay isOpen onClose={onClose} title={createTenantTitle}>
            <CreateFlow
              editContainer={CreateTenantContract}
              kind="Mieter"
              {...sharedProps}
              endMessageContainer={EndMessageCreateContract}
            />
          </Overlay>
        </>
      );
    case 'generationPlant':
      return (
        <>
          <OverlayTitleSnippet />
          <Overlay isOpen onClose={onClose} title={createOverlayTitle}>
            <CreateFlow
              editContainer={CreateTenantContract}
              kind="Vertrag"
              {...sharedProps}
              endMessageContainer={EndMessageCreateContract}
            />
          </Overlay>
        </>
      );
    default:
      return (
        <>
          <OverlayTitleSnippet />
          <CreateMeasurementContractFlow {...sharedProps} />
        </>
      );
  }
}
