import { CheckboxSimple, SubTitle } from '@ampeersenergy/ampeers-ui-components';
import * as React from 'react';
import styled from 'styled-components';

import { Entry } from '../../../components';
import { useGraphqlForm } from '../../../components/graphql-form/hooks/useGraphqlForm';
import { GraphqlFormField } from '../../../components/graphql-form/render';

const FormWrapper = styled.div`
  margin-bottom: 40px;
`;

const FormFieldContainer = styled.div`
  display: flex;
  align-items: baseline;
`;

const CheckboxContainer = styled.div`
  font-weight: 600;
  color: ${({ theme }) => theme.palette.textMuted};
`;

export function ContractDraftsSettingsForm() {
  const { isEditing } = useGraphqlForm();

  return (
    <FormWrapper>
      <SubTitle>Vertragsentwurf</SubTitle>
      <p>
        Welche Dokumente sollen bei der Erstellung des Vertragsentwurfes
        zusätzlich mit erzeugt werden? Sollte der Versand per E-Mail erfolgen,
        werden alle erzeugten Dokumente angehängt. Fehlt dir ein Dokument in der
        Auswahl? Dann kontaktiere uns! Wir helfen dir gerne bei allen weiteren
        Konfigurationen.
      </p>
      {!isEditing ? (
        <>
          <Entry title="Stromliefervertrag">Ja</Entry>
        </>
      ) : (
        <CheckboxContainer>
          <CheckboxSimple
            label="Stromliefervertrag"
            id="contract"
            checked
            disabled
          />
        </CheckboxContainer>
      )}
      <FormFieldContainer>
        <GraphqlFormField
          name="contractDraftTemplates.pricesheet_letter"
          label="Preisblatt"
        />
      </FormFieldContainer>
      <FormFieldContainer>
        <GraphqlFormField
          name="contractDraftTemplates.withdrawal_letter"
          label="Widerrufsformular"
        />
      </FormFieldContainer>
      <FormFieldContainer>
        <GraphqlFormField
          name="contractDraftTemplates.poa_cancellation_letter"
          label="Vollmacht Kündigung"
        />
      </FormFieldContainer>
      <FormFieldContainer>
        <GraphqlFormField
          name="contractDraftTemplates.covering_letter"
          label="Anschreiben"
        />
      </FormFieldContainer>
    </FormWrapper>
  );
}
