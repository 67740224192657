import React from 'react';

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="250" viewBox="0 0 233 217">
      <defs>
        <linearGradient
          id="linearGradient-lr-_5rvt_1-1"
          x1="50%"
          x2="50%"
          y1="0%"
          y2="100%"
        >
          <stop offset="0%" stopColor="#7DD77B" />
          <stop offset="44.236%" stopColor="#64C685" />
          <stop offset="100%" stopColor="#45AF93" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g>
          <g transform="translate(112.671 45.548)">
            <g
              fill="#7B89D6"
              fillRule="nonzero"
              fontFamily="ProximaNova-Medium, Proxima Nova"
              fontWeight="400"
              transform="translate(6.348 2.397)"
            >
              <text fontSize="17.979">
                <tspan x="0" y="17">
                  Z
                </tspan>
              </text>
              <text fontSize="13.185">
                <tspan x="10.861" y="17.137">
                  1
                </tspan>
              </text>
            </g>
            <circle
              cx="13.784"
              cy="13.784"
              r="13.784"
              stroke="#7B89D6"
              strokeWidth="0.856"
            />
          </g>
          <g transform="translate(146.233 88.699)">
            <g
              fill="#7B89D6"
              fillRule="nonzero"
              fontFamily="ProximaNova-Medium, Proxima Nova"
              fontWeight="400"
              transform="translate(4.93 2.397)"
            >
              <text fontSize="17.979">
                <tspan x="0" y="17">
                  Z
                </tspan>
              </text>
              <text fontSize="13.185">
                <tspan x="10.863" y="17.137">
                  2
                </tspan>
              </text>
            </g>
            <circle
              cx="13.784"
              cy="13.784"
              r="13.784"
              stroke="#7B89D6"
              strokeWidth="0.856"
            />
          </g>
          <g transform="translate(64.726 104.28)">
            <g
              fill="#7B89D6"
              fillRule="nonzero"
              fontFamily="ProximaNova-Medium, Proxima Nova"
              fontWeight="400"
              transform="translate(5.03 2.397)"
            >
              <text fontSize="17.979">
                <tspan x="0" y="17">
                  Z
                </tspan>
              </text>
              <text fontSize="13.185">
                <tspan x="10.861" y="17.137">
                  4
                </tspan>
              </text>
            </g>
            <circle
              cx="13.784"
              cy="13.784"
              r="13.784"
              stroke="#7B89D6"
              strokeWidth="0.856"
            />
          </g>
          <g transform="translate(34.76 148.63)">
            <text
              fill="#7B89D6"
              fillRule="nonzero"
              fontFamily="ProximaNova-Medium, Proxima Nova"
              fontSize="17.979"
              fontWeight="400"
            >
              <tspan x="5.068" y="19.397">
                Z
              </tspan>
            </text>
            <text
              fill="#7B89D6"
              fillRule="nonzero"
              fontFamily="ProximaNova-Medium, Proxima Nova"
              fontSize="13.185"
              fontWeight="400"
            >
              <tspan x="15.932" y="19.534">
                3
              </tspan>
            </text>
            <circle
              cx="13.784"
              cy="13.784"
              r="13.784"
              stroke="#7B89D6"
              strokeWidth="0.856"
            />
          </g>
          <g transform="translate(152.227 46.747)">
            <g
              fill="#0270FF"
              fillRule="nonzero"
              fontFamily="ProximaNova-Medium, Proxima Nova"
              fontWeight="400"
              transform="translate(9.088)"
            >
              <text fontSize="17.979">
                <tspan x="0" y="17">
                  Z
                </tspan>
              </text>
              <text fontSize="13.185">
                <tspan x="10.863" y="17.137">
                  1B
                </tspan>
              </text>
            </g>
            <g transform="translate(0 2.397)">
              <path
                stroke="#0270FF"
                strokeLinecap="square"
                strokeWidth="1.199"
                d="M3.439 0L3.439 18.64"
              />
              <path
                fill="#0270FF"
                fillRule="nonzero"
                d="M3.43954735 18.953339L6.87909469 22.3994007 0 22.3994007z"
                transform="rotate(-180 3.44 20.676)"
              />
            </g>
          </g>
          <path
            stroke="#7BD67C"
            strokeLinecap="square"
            strokeWidth="1.199"
            d="M104.281 161.815L104.281 3.596"
          />
          <path
            stroke="#7BD67C"
            strokeLinecap="square"
            strokeWidth="1.199"
            d="M20.377 170.205L20.377 142.637"
          />
          <path
            stroke="#7BD67C"
            strokeLinecap="square"
            strokeWidth="1.199"
            d="M195.377 113.87L195.377 86.301"
          />
          <path
            stroke="#7BD67C"
            strokeLinecap="square"
            strokeWidth="1.199"
            d="M195.377 170.205L195.377 142.637"
          />
          <path
            stroke="#7BD67C"
            strokeLinecap="square"
            strokeWidth="1.199"
            d="M171.404 197.774L171.404 170.205"
          />
          <path
            stroke="#7BD67C"
            strokeLinecap="square"
            strokeWidth="1.199"
            d="M183.39 197.774L183.39 170.205"
          />
          <path
            stroke="#7BD67C"
            strokeLinecap="square"
            strokeWidth="1.199"
            d="M195.377 197.774L195.377 170.205"
          />
          <path
            stroke="#7BD67C"
            strokeLinecap="square"
            strokeWidth="1.199"
            d="M207.363 197.774L207.363 170.205"
          />
          <path
            stroke="#7BD67C"
            strokeLinecap="square"
            strokeWidth="1.199"
            d="M219.349 197.774L219.349 170.205"
          />
          <path
            stroke="#7BD67C"
            strokeLinecap="square"
            strokeWidth="1.199"
            d="M56.336 2.997L151.027 2.997"
          />
          <path
            stroke="#7BD67C"
            strokeLinecap="square"
            strokeWidth="1.199"
            d="M103.682 86.301L195.377 86.301"
          />
          <path
            stroke="#7BD67C"
            strokeLinecap="square"
            strokeWidth="1.199"
            d="M20.377 142.637L195.377 142.637"
          />
          <path
            stroke="#7BD67C"
            strokeLinecap="square"
            strokeWidth="1.199"
            d="M171.404 170.205L218.072 170.205"
          />
          <circle
            cx="104.281"
            cy="3.596"
            r="3.596"
            fill="#7BD67C"
            fillRule="nonzero"
          />
          <circle
            cx="104.281"
            cy="86.301"
            r="3.596"
            fill="#7BD67C"
            fillRule="nonzero"
          />
          <circle
            cx="104.281"
            cy="142.637"
            r="3.596"
            fill="#7BD67C"
            fillRule="nonzero"
          />
          <path
            stroke="#7B89D6"
            strokeDasharray="2.397260233147504,4.794520466295007"
            strokeLinecap="square"
            strokeWidth="1.199"
            d="M56.336 35.36L151.027 35.36"
          />
          <g
            fill="#12007F"
            fillRule="nonzero"
            fontFamily="ProximaNova-Medium, Proxima Nova"
            fontWeight="400"
            transform="translate(206.527 46.747)"
          >
            <text fontSize="17.979">
              <tspan x="0" y="17">
                Z
              </tspan>
            </text>
            <text fontSize="13.185">
              <tspan x="10.863" y="17.137">
                1L
              </tspan>
            </text>
          </g>
          <g transform="translate(26.682 106.678)">
            <g
              fill="#12007F"
              fillRule="nonzero"
              fontFamily="ProximaNova-Medium, Proxima Nova"
              fontWeight="400"
              transform="translate(8.635)"
            >
              <text fontSize="17.979">
                <tspan x="0" y="17">
                  Z
                </tspan>
              </text>
              <text fontSize="13.185">
                <tspan x="10.863" y="17.137">
                  4L
                </tspan>
              </text>
            </g>
            <g transform="rotate(-180 3.44 11.536)">
              <path
                stroke="#12007F"
                strokeLinecap="square"
                strokeWidth="1.199"
                d="M3.439 0L3.439 16.945"
              />
              <path
                fill="#12007F"
                fillRule="nonzero"
                d="M3.43954735 17.2311644L6.87909469 20.677226 0 20.677226z"
                transform="rotate(-180 3.44 18.954)"
              />
            </g>
          </g>
          <g transform="translate(66.236 151.027)">
            <g
              fill="#12007F"
              fillRule="nonzero"
              fontFamily="ProximaNova-Medium, Proxima Nova"
              fontWeight="400"
              transform="translate(8.675)"
            >
              <text fontSize="17.979">
                <tspan x="0" y="17">
                  Z
                </tspan>
              </text>
              <text fontSize="13.185">
                <tspan x="10.863" y="17.137">
                  3L
                </tspan>
              </text>
            </g>
            <g transform="rotate(-180 3.44 11.536)">
              <path
                stroke="#12007F"
                strokeLinecap="square"
                strokeWidth="1.199"
                d="M3.439 0L3.439 16.945"
              />
              <path
                fill="#12007F"
                fillRule="nonzero"
                d="M3.43954735 17.2311644L6.87909469 20.677226 0 20.677226z"
                transform="rotate(-180 3.44 18.954)"
              />
            </g>
          </g>
          <g transform="translate(109.387 91.096)">
            <g
              fill="#12007F"
              fillRule="nonzero"
              fontFamily="ProximaNova-Medium, Proxima Nova"
              fontWeight="400"
              transform="translate(8.536)"
            >
              <text fontSize="17.979">
                <tspan x="0" y="17">
                  Z
                </tspan>
              </text>
              <text fontSize="13.185">
                <tspan x="10.863" y="17.137">
                  2L
                </tspan>
              </text>
            </g>
            <g transform="rotate(-180 3.44 11.536)">
              <path
                stroke="#12007F"
                strokeLinecap="square"
                strokeWidth="1.199"
                d="M3.439 0L3.439 16.945"
              />
              <path
                fill="#12007F"
                fillRule="nonzero"
                d="M3.43954735 17.2311644L6.87909469 20.677226 0 20.677226z"
                transform="rotate(-180 3.44 18.954)"
              />
            </g>
          </g>
          <g transform="rotate(-180 101.727 34.91)">
            <path
              stroke="#12007F"
              strokeLinecap="square"
              strokeWidth="1.199"
              d="M3.439 0L3.439 16.945"
            />
            <path
              fill="#12007F"
              fillRule="nonzero"
              d="M3.43954735 17.2311644L6.87909469 20.677226 0 20.677226z"
              transform="rotate(-180 3.44 18.954)"
            />
          </g>
          <g
            fill="#7B89D6"
            fillRule="nonzero"
            fontFamily="ProximaNova-Medium, Proxima Nova"
            fontSize="10.788"
            fontWeight="400"
            transform="translate(111.776 17.98)"
          >
            <text>
              <tspan x="0" y="10">
                EIGENTUMSGRENZE
              </tspan>
            </text>
          </g>
          <g
            fill="#7BD67C"
            fillRule="nonzero"
            fontFamily="ProximaNova-Medium, Proxima Nova"
            fontSize="10.788"
            fontWeight="400"
            transform="translate(155.442 203.767)"
          >
            <text>
              <tspan x="0" y="10">
                VERBRAUCHER
              </tspan>
            </text>
          </g>
          <g fillRule="nonzero" transform="translate(0 164.212)">
            <circle
              cx="19.777"
              cy="19.777"
              r="19.777"
              fill="url(#linearGradient-lr-_5rvt_1-1)"
            />
            <text
              fill="#FFF"
              fontFamily="ProximaNova-Medium, Proxima Nova"
              fontSize="15.582"
              fontWeight="400"
              transform="translate(5.875 10.488)"
            >
              <tspan x="0" y="14">
                EA1
              </tspan>
            </text>
          </g>
          <g fillRule="nonzero" transform="translate(176.199 92.295)">
            <circle
              cx="19.777"
              cy="19.777"
              r="19.777"
              fill="url(#linearGradient-lr-_5rvt_1-1)"
            />
            <text
              fill="#FFF"
              fontFamily="ProximaNova-Medium, Proxima Nova"
              fontSize="15.582"
              fontWeight="400"
              transform="translate(5.397 10.488)"
            >
              <tspan x="0" y="14">
                EA2
              </tspan>
            </text>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default React.memo(Icon);
