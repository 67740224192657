import * as yup from 'yup';

yup.addMethod<yup.BaseSchema>(
  yup.string,
  'isRequired',
  function fn(required?: boolean) {
    if (!required) return this.nullable();
    return this.required();
  },
);

yup.addMethod<yup.BaseSchema>(
  yup.number,
  'isRequired',
  function fn(required?: boolean) {
    if (!required) return this.nullable();
    return this.required();
  },
);
