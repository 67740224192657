import * as React from 'react';
import styled from 'styled-components';
import {
  VerticalTabs,
  VerticalTabsContainer,
} from '@ampeersenergy/ampeers-ui-components';
import { err } from 'neverthrow';

import { useReadClientDetailsQuery } from '../../../graphql-types';
import { transformNullToUndefined } from '../../../helpers/utils';
import { translateDocumentType } from '../../../helpers/formatStrings';
import { CreationHandlerWithoutArgs, CreationResult } from '../types';

import { PdfPreview } from './PdfPreview';

export type PreviewDimensions = {
  width?: number | string;
  height?: number | string;
};

const PreviewContainer = styled.div`
  display: flex;
`;

const TitleWrap = styled.div`
  margin-bottom: 20px;
`;

const Title = styled.h3`
  margin: 0;
`;

const Subtitle = styled.p`
  margin: 0;
  font-size: 15px;
  color: ${(props) => props.theme.palette.textMuted};
`;

const SUPPLIER_CONTRACT_KEY = 'supplier_contract_letter';

export function MultiPreviewStep({
  onNext,
  onPreview,
  dimensions = {
    width: 600,
    height: 900,
  },
}: {
  onNext: () => void;
  onPreview: CreationHandlerWithoutArgs;
  dimensions?: PreviewDimensions;
}) {
  const isInit = React.useRef(true);
  const [previewResult, setPreviewResult] = React.useState<CreationResult>();
  const [isLoading, setIsLoading] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState(0);
  const [previewResults, setPreviewResults] = React.useState<{
    [key: string]: CreationResult;
  }>({});

  const { data: authClientDetailsData } = useReadClientDetailsQuery();

  const templatesFromAuth = React.useMemo(() => {
    if (
      authClientDetailsData?.readClientDetails &&
      Object.keys(authClientDetailsData?.readClientDetails).length > 0
    ) {
      const clientDetails = transformNullToUndefined(
        authClientDetailsData.readClientDetails,
      );
      return Object.entries(clientDetails?.contractDraftTemplates || {})
        .filter(([, templateValue]) => !!templateValue)
        .map(([templateName]) => templateName);
    }
  }, [authClientDetailsData]);
  const templates = React.useMemo(
    () => [SUPPLIER_CONTRACT_KEY, ...(templatesFromAuth || [])],
    [templatesFromAuth],
  );

  const handleTabClick = (tab: number) => {
    setActiveTab(tab);
    const templatePayloadType = templates && templates[tab];

    if (Object.keys(previewResults).includes(templatePayloadType)) {
      const previewUrl = previewResults[templatePayloadType];
      setPreviewResult(previewUrl);
    } else {
      loadPreview(templatePayloadType);
    }
  };

  const loadPreview = React.useCallback(
    async (template: string) => {
      setIsLoading(true);
      try {
        const result = await onPreview(template);
        setPreviewResult(result);
        setPreviewResults((acc) => ({
          ...acc,
          [template]: result,
        }));
      } catch (error) {
        setPreviewResult(
          err({
            reason: (error as Error).toString(),
          }),
        );
      }
      setIsLoading(false);
    },
    [onPreview],
  );

  React.useEffect(() => {
    if (isInit.current) {
      loadPreview(SUPPLIER_CONTRACT_KEY);
      isInit.current = false;
    }
  }, [loadPreview]);

  return (
    <>
      <TitleWrap>
        <Title>Vorschau</Title>
        <Subtitle>Die folgenden Dokumente werden erzeugt</Subtitle>
      </TitleWrap>
      <PreviewContainer>
        <VerticalTabsContainer>
          <VerticalTabs value={activeTab} onChange={handleTabClick}>
            {templates.map((template) => (
              <VerticalTabs.Tab
                label={translateDocumentType(template)}
                key={template}
              />
            ))}
          </VerticalTabs>
        </VerticalTabsContainer>
        <PdfPreview
          isLoading={isLoading}
          fileSrc={
            previewResult?.isOk() ? previewResult.value.fileURL : undefined
          }
          error={
            previewResult?.isErr() ? previewResult.error.reason : undefined
          }
          dimensions={dimensions}
          onNext={onNext}
        />
      </PreviewContainer>
    </>
  );
}
