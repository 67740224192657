import * as React from 'react';
import { UserSettingsForm } from '@ampeersenergy/ampeers-ui-components';

import {
  useReadCurrentUserQuery,
  useUpdatePasswordMutation,
} from '../../../graphql-types';
import { SettingsTitle } from '../style';

export function UserSettings() {
  const { data } = useReadCurrentUserQuery();
  const user = data?.readCurrentUser;

  return (
    <div>
      <SettingsTitle>Nutzer</SettingsTitle>
      <UserSettingsForm
        user={user}
        useUpdatePasswordMutation={useUpdatePasswordMutation}
      />
    </div>
  );
}
