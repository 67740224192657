import { Icons } from '@ampeersenergy/ampeers-ui-components';
import styled from 'styled-components/macro';

import GraphQlForm from '../../graphql-form';

export const GraphQlFormStyled = styled(GraphQlForm)`
  border-radius: 4px;
  .form-content {
  }

  .form-error {
    border-radius: 4px;
    border-left: 1px solid ${(props) => props.theme.palette.border};
    border-right: 1px solid ${(props) => props.theme.palette.border};
    border-bottom: 1px solid ${(props) => props.theme.palette.border};
  }

  .action-buttons {
    justify-content: flex-end;
    background-color: ${(props) => props.theme.palette.background};
    position: sticky;
    bottom: 0;
    padding: 16px 0;
    margin: 0;
    z-index: 10;

    &::before {
      content: '';
      top: -8px;
      left: 0;
      height: 8px;
      width: 100%;
      position: absolute;
      border-bottom: 1px solid ${(props) => props.theme.palette.border};
      border-radius: 4px;
    }
  }
`;

export const StickyHeader = styled.div`
  // padding left and right needs to be the same as for ContractBox
  padding: 0px 40px 0px 32px;
  background-color: ${(props) => props.theme.palette.background};
  position: sticky;
  top: 0px;
  z-index: 10;

  &::after {
    content: '';
    bottom: -8px;
    left: 0;
    height: 8px;
    width: 100%;
    position: absolute;
    border-top: 1px solid ${(props) => props.theme.palette.border};
    border-radius: 4px;
  }
`;

export const ContractBox = styled.div`
  padding: 32px 40px 32px 32px;
  border-radius: 4px;
  border-left: 1px solid ${(props) => props.theme.palette.border};
  border-right: 1px solid ${(props) => props.theme.palette.border};
  &:not(:last-of-type) {
    border-bottom: 1px solid ${(props) => props.theme.palette.border};
    border-top: 1px solid ${(props) => props.theme.palette.border};
    margin-top: -1px;
  }
`;

export const ValueGrid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr 2fr;
  align-items: center;
  gap: 2rem;
  padding: 8px 0;
  min-height: 60px;
  &:last-of-type {
    padding-bottom: 0;
  }
`;

export const ValueCell = styled.div`
  width: 100%;
  overflow: hidden;
  word-break: break-word;
  text-align: right;
`;

export const HeaderCell = styled(ValueCell)`
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.29px;
`;

export const Subtitle = styled.h4`
  color: ${(props) => props.theme.palette.textSubtle};
  margin: 0;
`;

export const ValueLabel = styled.span`
  font-size: 15px;
  display: block;
  margin: 0;
  text-align: right;
`;

export const ContractSelection = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: min-content minmax(min-content, 110px) auto;
  gap: 4px;
`;

export const WarningIconWrapper = styled.div`
  width: 18px;
  height: 18px;
  margin-top: -1px;
`;

export const WarningIcon = styled(Icons.Wrong)`
  position: relative;
  top: -6px;
  left: -6px;
`;

export const ResetButton = styled.button`
  background: transparent;
  outline: none;
  padding: 0;
  margin: 0;
  border: 0;
  cursor: pointer;

  > svg {
    fill: ${(props) => props.theme.palette.textMuted};
    transition: fill ease-in-out 200ms;
  }
  :hover {
    > svg {
      fill: ${(props) => props.theme.palette.warning.color};
    }
  }
`;
