import React from 'react';
import styled from 'styled-components';
import { ApolloClient, ApolloConsumer } from '@apollo/client';

import {
  useReadCurrentUserQuery,
  useLogoutUserMutation,
} from '../../graphql-types';
import { PaddedShadowBox, FlexRow, Link } from '..';
import { clearSession } from '../../services/session';
import aeLogo from '../../assets/aelogo.png';

const Wrapper = styled(PaddedShadowBox)`
  position: absolute;
  bottom: 2px;
  z-index: 1000;
  left: 76px;
  font-size: 14px;
  padding: 0px;
  width: 220px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.45);
  line-height: 19px;
`;

const Small = styled.div`
  color: #666;
`;

const Bold = styled.div`
  font-weight: 600;
  font-size: 15px;
`;

const LinkList = styled.ul`
  margin: 0;
  padding: 0;
  padding-top: 2px;

  li {
    list-style: none;
    border-top: 1px solid #efefef;
    padding: 10px 15px;

    &:first-child {
      border-top: none;
    }
    &:hover {
      // cursor: pointer;
      // background: #f9f9f9;
    }
  }
`;

const StyledImg = styled.img`
  border-radius: 4px;
  margin-right: 12px;
`;

const CenteredRow = styled(FlexRow)`
  align-items: center;
`;

async function handleLogout(
  mutation: () => Promise<any>,
  client: ApolloClient<any>,
) {
  try {
    await mutation();
  } finally {
    clearSession();
    client.clearStore();
  }
}

function UserSubNav() {
  const { data } = useReadCurrentUserQuery();
  const [logoutMutation] = useLogoutUserMutation();

  return (
    <Wrapper>
      <LinkList>
        <li>
          <CenteredRow>
            <StyledImg width="25" height="25" src={aeLogo} />
            <div>
              <Bold>{data?.readCurrentUser.company.name}</Bold>
              <Small>Workspace</Small>
            </div>
          </CenteredRow>
        </li>
        <li>
          <Bold>
            {data?.readCurrentUser.firstName} {data?.readCurrentUser.lastName}
          </Bold>
          <Small>{data?.readCurrentUser.email}</Small>
        </li>
        <li>
          <ApolloConsumer>
            {(client) => (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <Link
                onClick={() => handleLogout(logoutMutation, client)}
                tabIndex={0}
                role="button"
                onKeyDown={() => handleLogout(logoutMutation, client)}
              >
                Abmelden
              </Link>
            )}
          </ApolloConsumer>
        </li>
      </LinkList>
    </Wrapper>
  );
}

export default UserSubNav;
