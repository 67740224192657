import React from 'react';
import styled from 'styled-components';
import {
  Label,
  AlertInfo,
  FormikInput,
} from '@ampeersenergy/ampeers-ui-components';

import { FormWrapper } from './style';

const AlignedLabel = styled(Label)`
  align-self: flex-start;
`;

const InfoMsgWrapped = styled(AlertInfo)`
  max-width: 840px;
  width: 100%;
`;

export default function ClientPlantReportForm() {
  return (
    <>
      <AlignedLabel>Energetische Auswertung</AlignedLabel>
      <FormWrapper>
        <FormikInput
          type="date"
          id="accountingPeriodStartDate"
          name="accountingPeriodStartDate"
          label="Von"
        />
        <FormikInput
          type="date"
          id="accountingPeriodEndDate"
          name="accountingPeriodEndDate"
          label="Bis"
        />
      </FormWrapper>
      <InfoMsgWrapped title="Hinweis">
        Wähle hier einen Zeitraum, für den möglichst alle Messwerte in der
        Kundenanlage vorliegen. Wir empfehlen daher die letzte
        Abrechnungsperiode als Zeitraum. Fehlen Daten, lassen sich nicht alle
        KPIs berechnen.
      </InfoMsgWrapped>

      <AlignedLabel>Vertriebliche Auswertung</AlignedLabel>
      <FormWrapper>
        <FormikInput
          type="date"
          id="salesStartDate"
          name="salesStartDate"
          label="Von"
        />
        <FormikInput
          type="date"
          id="salesEndDate"
          name="salesEndDate"
          label="Bis"
        />
      </FormWrapper>
      <InfoMsgWrapped title="Hinweis">
        Wähle hier einen Zeitraum, für den Du die Veränderung in der
        Durchdringung auswerten möchtest
      </InfoMsgWrapped>
    </>
  );
}
