import * as React from 'react';
import {
  Expandable,
  Expandables,
  Icons,
} from '@ampeersenergy/ampeers-ui-components';
import { Link } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

import { IconWrap } from '../../oldWorkflow/validate/style';

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.primaryColor} !important;
  text-decoration: underline !important;
  font-weight: bold;
`;

const StyledErrorMessage = styled.div`
  display: flex;
  align-items: center;
`;

export default function OverviewStepNoContractsFound() {
  const theme = useTheme();
  return (
    <Expandables expandedIndex={0}>
      <Expandable
        title={
          <StyledErrorMessage>
            <IconWrap>
              <Icons.Warning size={30} color={theme.palette.warning.color} />
            </IconWrap>
            <span>Keine Verträge zur Abrechnung gefunden</span>
          </StyledErrorMessage>
        }
      >
        Für die ausgewählte Kundenanlage(n) bzw. den ausgewählten
        Abrechnungszeitraum konnten keine Verträge zur Abrechnung gefunden
        werden. Dies kann folgende Gründe haben:
        <ol>
          <li>
            Die Rechnungen wurden <strong>bereits erstellt</strong>. Du findest
            diese in der zugehörigen Vertragsakte.
          </li>
          <li>
            Die Rechnungen <strong>liegen bereits als Entwurf</strong> vor.
            Suche dazu in den bereits vorhandenen{' '}
            <StyledLink to="/accounting/runs">Rechnungsläufen</StyledLink>.
          </li>
        </ol>
      </Expandable>
    </Expandables>
  );
}
