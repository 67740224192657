import { combineReducers, createStore } from 'redux';

import { SessionState, sessionReducer } from './session';
import { uiReducer, UiState } from './ui';

const rootReducer = combineReducers({
  ui: uiReducer,
  session: sessionReducer,
});

const store = createStore(rootReducer);
export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = {
  ui: UiState;
  session: SessionState;
};
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
