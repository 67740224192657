/* eslint-disable no-console */
import React, { useCallback, useRef, useState } from 'react';
import * as yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import { DateTime } from 'luxon';

import { EditContainerProps } from '../../../../components/createFlow';
import {
  ContractType,
  CreateContractInput,
  InvoiceCycle,
  Meter,
  Metering,
  PlantDetailDocument,
  ReadMaloBoundDocument,
  ReadMeloBoundDocument,
  ReadPlantMetersDocument,
  ReadUnboundMetersByPlantDocument,
  useReadMeterQuery,
  useReadMeterReadingsQuery,
  useReadNextLabelQuery,
  useReadPlantMetersQuery,
} from '../../../../graphql-types';
import GraphqlMultiStepForm from '../../../../components/graphql-form/multistepForm';
import { ContractForm, CustomerForm } from '../sharedFormParts';
import { PaymentForm } from '../PaymentForm';
import MeterForm, { SourceMeloMaloEnum } from '../MeterForm';
import { yupUTCDate } from '../../../../helpers/yupUTCDate';
import { contractExists, customerExists } from '../labelChecks';
import yupMelo from '../../../../helpers/validators/melo';
import {
  yupBankAccount,
  yupBankAccountNullable,
} from '../../../../helpers/validators/bankAccount';
import { contractStatusValidator } from '../formParts/contractStatus/contractStatus';
import { yupInitialNullableMeterReading } from '../../../../helpers/validators/meterReading';
import { yupNumberAsString } from '../../../../helpers/yupNumberAsString';
import numeral from '../../../../helpers/numeral';
import AddressesForm from '../AddressesForm';
import {
  METER_READING_END_DATE,
  METER_READING_START_DATE,
} from '../../../../helpers/constants';
import { formatDate } from '../../../../helpers/formatStrings';
import { IbanExistsErrorClass } from '../../../../components/graphql-form/types';
import MultipleBankDetailsModal, {
  MeterCreatedErrorModal,
} from '../../../../components/MultipleBankDetailsModal';
import readContract from '../../../../queries/readContract';
import {
  formatNumberToEuropeanStyle,
  parseStringToFloat,
} from '../../../../helpers/numberHelper';

export default function CreateContractContainer({
  onSuccess,
  onAbort,
  variables,
  formVariables,
  defaultValues,
  values,
}: EditContainerProps) {
  const { data: customerLabel, loading: customerLabelLoading } =
    useReadNextLabelQuery({
      variables: {
        type: 'customer',
      },
      fetchPolicy: 'network-only',
    });
  const { data: contractLabel, loading: contractLabelLoading } =
    useReadNextLabelQuery({
      variables: {
        type: 'contract',
      },
      fetchPolicy: 'network-only',
    });
  const { data: plantMeters } = useReadPlantMetersQuery({
    variables: {
      plantId: formVariables.plantId,
    },
  });

  const history = useHistory();
  const location = useLocation();
  const client = useApolloClient();

  const maloState = useState<SourceMeloMaloEnum>(
    SourceMeloMaloEnum.Autogenerated,
  );
  const meloState = useState<SourceMeloMaloEnum>(
    SourceMeloMaloEnum.Autogenerated,
  );
  const meterState = useState(true);
  const selectedMeterIdState = useState<string | undefined>();
  const [isNewMeter, setIsNewMeter] = useState(false);
  const existingCustomerState = useState(
    Object.prototype.hasOwnProperty.call(
      variables,
      'existingCustomerInitialState',
    )
      ? variables.existingCustomerInitialState
      : true,
  );
  const customerLabelState = useState(false);
  const contractLabelState = useState(false);
  const fixedOpenEndDateState = useState(
    Object.prototype.hasOwnProperty.call(variables, 'fixedInitialState')
      ? variables.fixedInitialState
      : false,
  );
  const billingState = useState(true);
  const shippingState = useState(true);
  const selfPayerState = useState(true);
  const consumptionFirstOptionSelectionState = useState(true);
  const debtorLabelFirstOptionSelectionState = useState(true);
  const customerPersonState = useState(
    Object.prototype.hasOwnProperty.call(
      variables,
      'customerPersonInitialState',
    )
      ? variables.customerPersonInitialState
      : true,
  );
  const billingCustomerPersonState = useState(true);
  const billingCustomerAdditionalEntityFieldsState = useState(false);
  const billingCustomerAdditionalPersonState = useState(true);
  const shippingCustomerPersonState = useState(true);

  const [forceStep, setForceStep] = useState<number | null>(0);

  React.useEffect(() => {
    // To ensure that we can modify the variable "step" within the <GraphqlMultiStepForm />, we require the use of "forceStep".
    // However, setting "forceStep" causes stepIndex to be updated with each rendering.
    // Therefore, we need to reset "forceStep" to prevent it from affecting the normal functionality of stepIndex.
    if (forceStep) {
      setForceStep(null);
    }
  }, [forceStep]);

  const [ibanExistsError, setIbanExistsError] =
    React.useState<IbanExistsErrorClass | null>(null);

  const meterRef: React.Ref<any> = useRef();

  const selectedMeterId = selectedMeterIdState[0];

  const { data: dataMeter } = useReadMeterQuery({
    variables: { id: selectedMeterId! },
    skip: !selectedMeterId,
  });

  const metering =
    dataMeter?.readMeter?.metering?.toUpperCase() === Metering.Rlm
      ? Metering.Rlm
      : Metering.Slp;
  const { data: dataMeterReading } = useReadMeterReadingsQuery({
    variables: {
      meterId: selectedMeterId!,
      startDate: METER_READING_START_DATE,
      endDate: METER_READING_END_DATE,
      metering,
    },
    skip: !selectedMeterId || !dataMeter,
  });

  const readings = [...(dataMeterReading?.readMeterReadings || [])];
  const readingsSortedByDate = readings
    .sort((el1, el2) => new Date(el1.to).getTime() - new Date(el2.to).getTime())
    .reverse();

  const highestReading = readingsSortedByDate[0];

  const resetCustomerStates = useCallback(() => {
    customerLabelState[1](false);
    billingState[1](true);
    customerPersonState[1](true);
    billingCustomerPersonState[1](true);
    billingCustomerAdditionalEntityFieldsState[1](false);
    billingCustomerAdditionalPersonState[1](true);
  }, [
    customerLabelState,
    billingState,
    customerPersonState,
    billingCustomerPersonState,
    billingCustomerAdditionalEntityFieldsState,
    billingCustomerAdditionalPersonState,
  ]);

  const steps = [
    {
      title: 'Vertragspartner',
      content: (
        <CustomerForm
          fieldNamePrefix=""
          customerPersonState={customerPersonState}
          customerLabelState={customerLabelState}
          existingCustomerState={existingCustomerState}
          resetCustomerStates={resetCustomerStates}
        />
      ),
    },
    {
      title: 'Adressen',
      content: (
        <AddressesForm
          addresses={variables.addresses ?? []}
          fieldNamePrefix=""
          billingState={billingState}
          shippingState={shippingState}
          billingCustomerPersonState={billingCustomerPersonState}
          billingCustomerAdditionalEntityFieldsState={
            billingCustomerAdditionalEntityFieldsState
          }
          billingCustomerAdditionalPersonState={
            billingCustomerAdditionalPersonState
          }
          shippingCustomerPersonState={shippingCustomerPersonState}
          existingCustomerState={existingCustomerState}
          customerPersonState={customerPersonState}
        />
      ),
    },
    {
      title: 'Vertrag',
      content: (
        <ContractForm
          fieldNamePrefix=""
          fixedOpenEndDateState={fixedOpenEndDateState}
          debtorLabelFirstOptionSelectionState={
            debtorLabelFirstOptionSelectionState
          }
          contractLabelState={contractLabelState}
        />
      ),
    },
    {
      title: 'Abrechnung',
      content: (
        <PaymentForm
          fieldNamePrefix=""
          selfPayerState={selfPayerState}
          consumptionFirstOptionSelectionState={
            consumptionFirstOptionSelectionState
          }
        />
      ),
    },
    {
      title: 'Zähler',
      content: (
        <MeterForm
          fieldNamePrefix=""
          meloState={meloState}
          maloState={maloState}
          meterState={meterState}
          selectedMeterId={selectedMeterIdState}
          isNewMeterState={[isNewMeter, setIsNewMeter]}
          fixedOpenEndDateState={fixedOpenEndDateState}
          ref={meterRef}
        />
      ),
    },
  ];

  return (
    <GraphqlMultiStepForm
      mutation="createContract"
      steps={steps}
      variables={{ ...variables, meterType: '' }}
      formVariables={{
        ...formVariables,
        isOperator:
          formVariables.isOperator === undefined
            ? false
            : formVariables.isOperator,
        useCase: 'createContract',
        label: contractLabel?.readNextLabel,
        'customer.label': customerLabel?.readNextLabel,
        boundStartAt: variables.startDate,
        boundEndAt: variables.endDate,
      }}
      isLoading={customerLabelLoading || contractLabelLoading}
      onSuccess={(result, add) => {
        if (onSuccess) {
          onSuccess(result, add);
        }
        history.push(`${location.pathname}/contract/${result.id}`);
      }}
      onAbort={onAbort}
      onError={(error) => {
        if (error instanceof IbanExistsErrorClass) {
          setIbanExistsError(error);
        }
      }}
      forceStep={forceStep}
      defaultValues={defaultValues}
      labels={{
        submit: 'Vertrag anlegen',
      }}
      readDocument={readContract}
      values={values}
      refetchQueries={[
        {
          query: PlantDetailDocument,
          variables: {
            plantId: formVariables.plantId,
          },
        },
        {
          query: ReadPlantMetersDocument,
          variables: {
            plantId: formVariables.plantId,
          },
        },
      ]}
      formatBeforeSubmit={(contractInput: CreateContractInput) => {
        return {
          ...contractInput,
          label: contractInput.label.trim(),
          contractMeter: {
            ...contractInput.contractMeter,
            ...(numeral(
              contractInput.contractMeter.meterReading?.value,
            ).value() !== null &&
              numeral(
                contractInput.contractMeter.meterReading?.value,
              ).value() !== undefined && {
                meterReading: {
                  ...contractInput.contractMeter.meterReading,
                  value: numeral(
                    contractInput.contractMeter.meterReading?.value,
                  ).value(),
                },
              }),
          },
          customer: {
            ...contractInput.customer,
            label: contractInput.customer.label.trim(),
            bankAccount: {
              ...contractInput.customer.bankAccount,
              iban: contractInput.customer?.bankAccount?.iban?.replace(
                / /g,
                '',
              ),
              bic: contractInput.customer?.bankAccount?.bic?.replace(/ /g, ''),
            },
          },
          type: mapKindToContractType(formVariables.kind),
        };
      }}
      validation={{
        invoiceCycle: yup.string(),
        contractMeter: {
          meter: {
            id: yup
              .number()
              .test(
                'id',
                'Zähler im gewählten Zeitraum nicht verfügbar',
                (meterId: number | undefined) => {
                  if (!meterRef?.current) {
                    return true;
                  }

                  const boundStartAt = meterRef?.current.getValue('startDate');
                  const boundEndAt = meterRef?.current.getValue('endDate');
                  if (!meterId || !boundStartAt) return false;

                  return client
                    .query({
                      query: ReadUnboundMetersByPlantDocument,
                      variables: {
                        plantId: formVariables.plantId,
                        boundStartAt,
                        ...(boundEndAt && { boundEndAt }),
                      },
                      fetchPolicy: 'network-only',
                    })
                    .then((res) => {
                      if (res?.data?.readMeters) {
                        const meter = res?.data.readMeters.find(
                          (m: Meter) => m.id === meterId.toString(),
                        );
                        if (!meter) {
                          return false;
                        }
                      }
                      return true;
                    })
                    .catch((res) => {
                      console.log(
                        `Meter validation failed with the following message: ${res}`,
                      );
                      return false;
                    });
                },
              )
              .required(),
            new: {
              meterNumber: yup
                .string()
                .test(
                  'meterNumber',
                  'Es gibt bereits einen Zähler mit dieser Zählernummer',
                  (value: string | undefined) => {
                    if (!value) return true;
                    const plantMetersObject = plantMeters?.readPlantMeters;
                    const meters = [
                      ...(plantMetersObject?.matchedBuildingMeters
                        ? plantMetersObject?.matchedBuildingMeters
                        : []),
                      ...(plantMetersObject?.matchedTenantMeters
                        ? plantMetersObject?.matchedTenantMeters
                        : []),
                      ...(plantMetersObject?.unmatchedMeters
                        ? plantMetersObject?.unmatchedMeters
                        : []),
                    ];

                    return meters.every((m) => {
                      if (!m.meterNumber) return true;
                      const currentValueTrimmed = m.meterNumber.trimEnd();
                      const mValueTrimmed = value.trimEnd();

                      return currentValueTrimmed !== mValueTrimmed;
                    });
                  },
                ),
            },
          },
          ...(!(maloState[0] === SourceMeloMaloEnum.Autogenerated) && {
            malo: yup
              .string()
              .length(11)
              .required()
              .test(
                'malo',
                'Die Malo ist für den gewählten Zeitraum bereits einem anderen Vertrag zugeordnet.',
                function test(this: yup.TestContext, malo: string | undefined) {
                  const formData = this.options.context as
                    | CreateContractInput
                    | undefined;
                  if (!formData?.startDate || !malo) {
                    return true;
                  }
                  return client
                    .query({
                      query: ReadMaloBoundDocument,
                      variables: {
                        contractStartDate: formData.startDate,
                        ...(formData.endDate && {
                          contractEndDate: formData.endDate,
                        }),
                        malo,
                      },
                      fetchPolicy: 'network-only',
                    })
                    .then((res) => !res?.data.readMaloBound.bound)
                    .catch((res) => {
                      console.log(
                        `Malo validation failed with the following message: ${res}`,
                      );
                      return false;
                    });
                },
              ),
          }),
          ...(!(meloState[0] === SourceMeloMaloEnum.Autogenerated) && {
            melo: yupMelo.test(
              'melo',
              'Die Melo ist für den gewählten Zeitraum bereits einem anderen Vertrag zugeordnet.',
              function test(this: yup.TestContext, melo: string | undefined) {
                const formData = this.options.context as
                  | CreateContractInput
                  | undefined;
                if (!formData?.startDate || !melo) {
                  return true;
                }
                return client
                  .query({
                    query: ReadMeloBoundDocument,
                    variables: {
                      contractStartDate: formData.startDate,
                      ...(formData.endDate && {
                        contractEndDate: formData.endDate,
                      }),
                      melo,
                    },
                    fetchPolicy: 'network-only',
                  })
                  .then((res) => !res?.data.readMeloBound.bound)
                  .catch((res) => {
                    console.log(
                      `Melo validation failed with the following message: ${res}`,
                    );
                    return false;
                  });
              },
            ),
          }),
          ...(!meterState[0]
            ? {
                meterReading: yup.object({
                  date: yupUTCDate
                    .test(
                      'meterRadingDateValidation',
                      'Das Ablesedatum kann nicht vor dem Startdatum des Vertrags liegen.',
                      (meterReadingDate: Date | undefined) => {
                        if (
                          !meterRef?.current ||
                          !meterRef?.current.getValue('startDate') ||
                          !meterReadingDate
                        ) {
                          return true;
                        }
                        return (
                          new Date(meterReadingDate).getTime() -
                            new Date(
                              meterRef?.current.getValue('startDate'),
                            ).getTime() >=
                          0
                        );
                      },
                    )
                    .required(),
                  value: yupNumberAsString
                    .test(
                      'meterReadingValueValidation',
                      `Der Anfangszählerstand muss größer oder gleich dem letzten Zählerstand von ${formatNumberToEuropeanStyle(
                        highestReading?.value ?? 0,
                      )} kWh vom ${formatDate(highestReading?.to)} sein`,
                      (input) => {
                        if (
                          !selectedMeterId ||
                          (readings && readings.length === 0)
                        ) {
                          return true;
                        }
                        if (!input || !highestReading) return false;

                        return (
                          parseStringToFloat(input) >= highestReading?.value
                        );
                      },
                    )
                    .required(),
                  obis: yup.string().nullable(),
                  reason: yup.string().nullable(),
                  valueStatus: yup.string().required(),
                  ignoreWarning: yup.boolean().nullable(),
                }),
              }
            : { meterReading: yupInitialNullableMeterReading }),
        },
        signDate: yupUTCDate.required(),
        startDate: yupUTCDate.required(),
        endDate: yupUTCDate
          .when('startDate', (validityStartDate: any, schema: any) => {
            if (!validityStartDate) {
              return schema;
            }

            const startDateForValidation = DateTime.fromJSDate(
              new Date(validityStartDate),
            )
              .plus({ days: 1 })
              .startOf('day')
              .toJSDate();

            return schema.min(
              startDateForValidation,
              `Das Lieferende kann nicht vor dem Lieferbeginn liegen.`,
            );
          })
          .required(),
        customer: {
          existingCustomer: {
            id: yup.string().test('id', 'Pflichtfeld', (value: any) => {
              return !!(!existingCustomerState[0] || Boolean(value));
            }),
          },
          documentDeliveryMethod: yup.string().required(),
          person: {
            name: yup.string().required(),
          },
          label: yup
            .string()
            .required()
            .test(
              'valid-customerLabel',
              'Kunden-Nr existiert bereits',
              customerExists(client),
            ),
          bankAccount: yup.object().when('hasSepa', () => {
            if (!selfPayerState[0]) {
              return yupBankAccount;
            }
            return yupBankAccountNullable;
          }),
          addressBilling: {
            email: yup
              .string()
              .email()
              .test('email', 'Pflichtfeld', async function test(email) {
                if (
                  this.options.context?.customer.documentDeliveryMethod ===
                  'email'
                ) {
                  if (!email) return false;
                }
                return true;
              }),
          },
          payer: yup.object().nullable(),
        },
        label: yup
          .string()
          .required()
          .test(
            'valid-contractLabel',
            'Diese Vertragsnummer existiert bereits',
            contractExists(client),
          ),
        ...(!debtorLabelFirstOptionSelectionState[0] && {
          debtorLabel: yup.string().required(),
        }),
        status: contractStatusValidator,
        settlementDay: yup
          .string()
          .test(
            'settlementDayRequired',
            'Pflichtfeld',
            function testIt(settlementDay?: string) {
              const invoiceCycle = this.options.context?.invoiceCycle;
              if (invoiceCycle !== InvoiceCycle.Yearly) return this.schema;
              if (!settlementDay) {
                return false;
              }
              const settlementMonth = this.options.context?.settlementMonth;

              const checkDate = DateTime.fromObject({
                day: parseInt(settlementDay, 10),
                month: settlementMonth,
              });
              if (!checkDate.isValid) {
                this.createError({
                  message: 'Ungültiger Stichtag',
                });
              }
              return true;
            },
          ),
        settlementMonth: yup
          .string()
          .test(
            'settlementMonthRequired',
            'Pflichtfeld',
            function testIt(settlementMonth?: string) {
              const invoiceCycle = this.options.context?.invoiceCycle;
              if (invoiceCycle !== InvoiceCycle.Yearly) return this.schema;
              if (!settlementMonth) return false;

              if (!/^(1[0-2]|[1-9])$/.test(settlementMonth)) {
                this.createError({
                  message: 'Ungültiger Monat',
                });
              }
              return true;
            },
          ),
        downPayment: yup
          .number()
          .test(
            'downPaymentRequired',
            'Muss eine Zahl sein',
            function testIt(downpayment?: number) {
              const invoiceCycle = this.options.context?.invoiceCycle;
              if (invoiceCycle !== 'monthly' && Number.isNaN(downpayment)) {
                return false;
              }
              return this.schema;
            },
          )
          .nullable(),
        downPaymentStartDate: yupUTCDate.test(
          'downPaymentStartDate',
          'Das Datum darf nicht vor dem Lieferbeginn liegen',
          function testIt(downPaymentStartDate?: Date) {
            const invoiceCycle = this.options.context?.invoiceCycle;
            const startDate = this.options.context?.startDate;
            if (invoiceCycle === 'monthly' && !downPaymentStartDate) {
              return true;
            }
            if (!startDate || !downPaymentStartDate) {
              return this.schema;
            }
            return downPaymentStartDate >= new Date(startDate);
          },
        ),
        ...(consumptionFirstOptionSelectionState && {
          consumptions: {
            initial: {
              startDate: yupUTCDate
                .test(
                  'consumptionStartDate',
                  'Beginn der Gültigkeit darf nicht vor dem Lieferbeginn liegen',
                  function testIt(startDate?: Date) {
                    const startDateValue = this.options.context?.startDate;
                    if (!startDate || !startDateValue) {
                      return this.schema;
                    }
                    return startDate >= new Date(startDateValue);
                  },
                )
                .required(),
              endDate: yupUTCDate
                .when('startDate', (validityStartDate: string, schema: any) => {
                  if (!validityStartDate) {
                    return schema;
                  }
                  const minDate = DateTime.fromISO(validityStartDate)
                    .plus({ days: 1 })
                    .startOf('day')
                    .toJSDate();

                  return schema.min(
                    minDate,
                    `Ende der Gültigkeit darf nicht vor Beginn der Gültigkeit liegen`,
                  );
                })
                .required(),
              value: yup.number().min(0).required(),
            },
          },
        }),
      }}
    >
      {ibanExistsError && ibanExistsError.payers && (
        <>
          {ibanExistsError.hasNewMeterBeenAdded ? (
            <MeterCreatedErrorModal closeModal={() => setIsNewMeter(false)} />
          ) : (
            <MultipleBankDetailsModal
              closeModal={({ changeStep }) => {
                setIbanExistsError(null);
                if (changeStep) {
                  setForceStep(3); // Go to page where Iban is set
                }
              }}
              error={ibanExistsError}
            />
          )}
        </>
      )}
    </GraphqlMultiStepForm>
  );
}

// This component is used to both create "normal" tenant contracts (on the 'Mieter')
// tab in the UI, as well as building contracts like for example "Allgemeinstrom" in
// the 'Gebäude' tab.
// This function differentiates between the two so that the correct ContractType is set.
function mapKindToContractType(
  kind: 'tenant' | 'generationPlant' | 'operator',
) {
  switch (kind) {
    case 'generationPlant':
      return ContractType.Asset;
    case 'operator':
      return ContractType.MeasurementConcept;
    case 'tenant':
    default:
      return ContractType.Tenant;
  }
}
