/* eslint-disable no-param-reassign */
interface HexToRgbResult {
  r: number;
  g: number;
  b: number;
}

export function hexToRgb(hex: string): HexToRgbResult | null {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function rep(m, r, g, b) {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

export function hexToRgbaString(hex: string, alpha: number): string | null {
  if (typeof hex !== 'string') return 'red';
  const rgb = hexToRgb(hex);
  if (!rgb) return null;

  const { r, g, b } = rgb;

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}
