import React from 'react';
import styled from 'styled-components';

import { HistoricEntry, Bold } from '../../../../../components';
import { formatMeterReadingValue } from '../../../../../helpers/formatStrings';
import { useGraphqlForm } from '../../../../../components/graphql-form/hooks/useGraphqlForm';
import { GraphqlFormField } from '../../../../../components/graphql-form/render';
import { Consumption } from '../../../../../graphql-types';

const Headline = styled(Bold)`
  margin-top: 10px;
  font-size: 15px;
`;

export function ConsumptionAnnual({
  consumptionValues,
  loading,
}: {
  consumptionValues: Consumption[];
  loading: boolean;
}) {
  const { isEditing } = useGraphqlForm();

  const historicConsumption: Array<{
    value: number;
    date: {
      startDate: string;
      endDate?: string;
    };
  }> = consumptionValues
    ? consumptionValues
        .map((consumption: any) => ({
          date: {
            startDate: consumption.startDate,
            endDate: consumption.endDate,
          },
          value: consumption.value,
        }))
        .filter((consumption: any) => consumption.value !== null)
        .sort(
          (a: any, b: any) =>
            new Date(b.date.startDate).getTime() -
            new Date(a.date.startDate).getTime(),
        )
    : [];

  return (
    <>
      {!isEditing && (
        <HistoricEntry
          title="Angenommener Jahresverbrauch"
          values={historicConsumption}
          isLoading={loading}
          formatValue={formatMeterReadingValue}
        />
      )}
      {isEditing && (
        <>
          <Headline>Angenommener Jahresverbrauch</Headline>
          <GraphqlFormField name="value" label={null} />
          <GraphqlFormField prependix="vom" name="startDate" label={null} />
          <GraphqlFormField
            prependix={'bis\u00A0'}
            name="endDate"
            label={null}
          />
        </>
      )}
    </>
  );
}
