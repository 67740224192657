import {
  AlertRetryable,
  CookieBanner,
  modalSetAppElement,
  ToastProvider,
} from '@ampeersenergy/ampeers-ui-components';
import '@ampeersenergy/ampeers-ui-components/src/global-style.css';
import { datadogLogs } from '@datadog/browser-logs';
import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { withRouter } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { ApolloQueryResult, OperationVariables } from '@apollo/client';

import { Content, Sidebar, Wrapper } from './App.style';
import { NothingFound } from './components';
import AuthorizedRoute from './components/authorizedRoute';
import { DocTitle } from './components/docTitle';
import Navigation from './components/navigation';
import { TaskDetail } from './components/task-manager/TaskDetail';
import { TasksStatus } from './components/task-manager/TasksStatus';
import ThemeProvider from './components/themeProvider';
import { useHasRole } from './components/useHasRole';
import './helpers/yupErrorMsg';
import './queries/fragments';
import { withSession } from './services/session';
// pages
import AccountingPage from './pages/accounting/accounting.page';
import WorkflowDetailOld from './pages/accounting/oldWorkflow/index';
import CrmPage from './pages/crm';
import Dashboard from './pages/dashboard/dashboardPage';
import Login from './pages/login/loginPage';
import ResetPasswordPage from './pages/login/resetPasswordPage';
import { TwoFactorAuthPage } from './pages/login/twoFactorAuth.page';
import PlantDetailPage from './pages/plant';
import PlantsListPage from './pages/project/plantsListPage';
import ProjectListPage from './pages/project/projectsListPage';
import ReportsPage from './pages/report';
import Settings from './pages/settings/settingsPage';
import { SupplierDetailsWithRouter } from './pages/supplier/details';
import SupplierPage from './pages/supplier/index';
import TariffDetailPage from './pages/tariff/tariffDetailPage';
import TariffListPage from './pages/tariff/tariffListPage';
import { AccountingRunDetailsPage } from './pages/accounting/details';
import {
  getOptionalCookiesAccepted,
  LOCAL_STORAGE_IDENTIFIER,
} from './helpers/storageUtils';
import {
  loadMSClarityScript,
  loadSegmentScript,
} from './helpers/analyticsHelpers';
import TaskManagerProvider from './components/task-manager/TaskManagerProvider';

if (process.env.NODE_ENV !== 'test') {
  modalSetAppElement('#root');
}
if (process.env.NODE_ENV !== 'test' && process.env.NODE_ENV !== 'development') {
  datadogLogs.init({
    clientToken: 'pub2e223cc59bf971d57cf4b9a94581ad9f',
    site: 'datadoghq.eu',
    forwardErrorsToLogs: true,
    sampleRate: 100,
    service: `ae-ls-${process.env.NODE_ENV}-frontend`,
  });
}

const PageNotLoadedErrorWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PageNotLoadedError = ({
  refetch,
}: {
  refetch: (
    variables?: Partial<OperationVariables>,
  ) => Promise<ApolloQueryResult<any>>;
}) => {
  return (
    <PageNotLoadedErrorWrapper>
      <AlertRetryable
        message="Dieser Inhalt konnte nicht geladen werden."
        onRetry={() => refetch()}
      />
    </PageNotLoadedErrorWrapper>
  );
};

const App: React.FC = ({ hasSession, location: { pathname } }: any) => {
  const { hasRole: crmEnabled } = useHasRole('feature_crm');
  const { hasRole: accountingEnabled } = useHasRole('feature_accounting');
  const {
    hasRole: supplierChangeEnabled,
    error: supplierChangeEnabledError,
    refetch: supplierChangeEnabledRefetch,
  } = useHasRole('feature_supplier_change');

  const optionalCookiesAccepted = getOptionalCookiesAccepted();
  React.useEffect(() => {
    loadMSClarityScript(optionalCookiesAccepted);
    loadSegmentScript(optionalCookiesAccepted);
  }, [optionalCookiesAccepted]);

  return (
    <ThemeProvider>
      <HelmetProvider>
        <ToastProvider>
          <TaskManagerProvider>
            <DocTitle />
            <Wrapper>
              <CookieBanner
                cookiesIdentifier="cookies"
                localStorageIdentifier={LOCAL_STORAGE_IDENTIFIER}
                onClose={() => {
                  loadMSClarityScript(optionalCookiesAccepted);
                  loadSegmentScript(optionalCookiesAccepted);
                }}
              />
              {hasSession && !pathname.includes('login') && (
                <Sidebar>
                  <Navigation />
                </Sidebar>
              )}
              <Content>
                <Switch>
                  <Route path="/login" exact>
                    <Login />
                  </Route>
                  <Route path="/login/2fa" exact>
                    <TwoFactorAuthPage />
                  </Route>
                  <Route path="/reset-password" exact>
                    <ResetPasswordPage />
                  </Route>
                  <AuthorizedRoute path="/" exact>
                    <Dashboard />
                  </AuthorizedRoute>
                  <AuthorizedRoute
                    path={[
                      // Since labels or names can include slashes "/", we don't want to add them to the URL.
                      // But we also don't want to break existing URLs (for the time being).
                      // That's why we match the URLs with labels or names here, but mark them as deprecated.
                      // Note: In react-router v6 these partial dynamic segments are disallowed.
                      // See https://reactrouter.com/en/main/route/route#dynamic-segments
                      '/project/:projectId-:projectNameDeprecated/plant/:plantId-:plantNameDeprecated',
                      '/project/:projectId/plant/:plantId-:plantNameDeprecated',
                      '/project/:projectId-:projectNameDeprecated/plant/:plantId',
                      '/project/:projectId/plant/:plantId',
                    ]}
                  >
                    <PlantDetailPage />
                  </AuthorizedRoute>
                  <AuthorizedRoute
                    path={[
                      // Since labels or names can include slashes "/", we don't want to add them to the URL.
                      // But we also don't want to break existing URLs (for the time being).
                      // That's why we match the URLs with labels or names here, but mark them as deprecated.
                      // Note: In react-router v6 these partial dynamic segments are disallowed.
                      // See https://reactrouter.com/en/main/route/route#dynamic-segments
                      '/project/:projectId-:projectNameDeprecated',
                      '/project/:projectId',
                    ]}
                  >
                    <PlantsListPage />
                  </AuthorizedRoute>
                  <AuthorizedRoute path="/project">
                    <ProjectListPage />
                  </AuthorizedRoute>
                  <AuthorizedRoute
                    path={[
                      // Since labels or names can include slashes "/", we don't want to add them to the URL.
                      // But we also don't want to break existing URLs (for the time being).
                      // That's why we match the URLs with labels or names here, but mark them as deprecated.
                      // Note: In react-router v6 these partial dynamic segments are disallowed.
                      // See https://reactrouter.com/en/main/route/route#dynamic-segments
                      '/tariff/:tariffId-:tariffNameDeprecated',
                      '/tariff/:tariffId',
                    ]}
                  >
                    <TariffDetailPage />
                  </AuthorizedRoute>
                  <AuthorizedRoute path="/tariff">
                    <TariffListPage />
                  </AuthorizedRoute>
                  <AuthorizedRoute path="/accounting/workflows/:id-:name">
                    <WorkflowDetailOld />
                  </AuthorizedRoute>
                  <AuthorizedRoute path="/accounting/runs/:id">
                    <AccountingRunDetailsPage />
                  </AuthorizedRoute>
                  <AuthorizedRoute path="/accounting">
                    {accountingEnabled && <AccountingPage />}
                  </AuthorizedRoute>
                  <AuthorizedRoute path="/reports">
                    <ReportsPage />
                  </AuthorizedRoute>
                  <AuthorizedRoute path="/supplier/workflow/:id">
                    {supplierChangeEnabledError && (
                      <PageNotLoadedError
                        refetch={supplierChangeEnabledRefetch}
                      />
                    )}
                    {supplierChangeEnabled && <SupplierDetailsWithRouter />}
                  </AuthorizedRoute>
                  <AuthorizedRoute path="/supplier">
                    {supplierChangeEnabledError && (
                      <PageNotLoadedError
                        refetch={supplierChangeEnabledRefetch}
                      />
                    )}
                    {supplierChangeEnabled && <SupplierPage />}
                  </AuthorizedRoute>
                  <AuthorizedRoute path="/crm">
                    {crmEnabled && <CrmPage />}
                  </AuthorizedRoute>
                  <AuthorizedRoute path="/settings">
                    <Settings />
                  </AuthorizedRoute>
                  <AuthorizedRoute path="/tasks/:id">
                    <TaskDetail />
                  </AuthorizedRoute>
                  <AuthorizedRoute path="/tasks">
                    <TasksStatus />
                  </AuthorizedRoute>
                  <Route>
                    <NothingFound />
                  </Route>
                </Switch>
              </Content>
            </Wrapper>
          </TaskManagerProvider>
        </ToastProvider>
      </HelmetProvider>
    </ThemeProvider>
  );
};

export default withRouter(withSession(App));
