/* eslint-disable react/destructuring-assignment */
import {
  ColumnDefinition,
  FeatureAnnouncementModal,
  Header,
  Icons,
  Main,
  WorkflowStep,
} from '@ampeersenergy/ampeers-ui-components';
import * as React from 'react';
import styled from 'styled-components';

import { DocTitle } from '../../components/docTitle';
import { FullWorkflowFragment } from '../../graphql-types';
import LieferantenwechselAnnouncementImage from '../../assets/lfw_announcement.png';

import { disableSortAndFilter, SupplierTable } from './tables';

export const supplierTitle = 'Lieferantenwechsel';

const Content = styled.div`
  position: relative;
`;

export type SupplierBaseColumns = Pick<FullWorkflowFragment, 'alias'> & {
  id: number | string;
  status: string;
  statusText?: string | null;
  contractName: string;
  meterId: string;
  malo: string;
  plantName: string;
  marketPartnerLabel?: string | null;
};

const baseColumns: ColumnDefinition<SupplierBaseColumns>[] = [
  {
    Header: 'Vertrag',
    accessor: 'contractName',
    type: 'string',
    width: '25%',
    ...disableSortAndFilter,
  },
  {
    Header: 'Kundenanlage',
    accessor: 'plantName',
    type: 'string',
    width: '12%',
    ...disableSortAndFilter,
  },
  {
    Header: 'Zähler',
    accessor: 'meterId',
    type: 'string',
    width: '8%',
    ...disableSortAndFilter,
  },
  {
    Header: 'Malo',
    accessor: 'malo',
    type: 'string',
    width: '8%',
    ...disableSortAndFilter,
  },
  {
    Header: 'Status',
    accessor: 'status',
    width: '30%',
    ...disableSortAndFilter,
    Cell: (props) => {
      return (
        <WorkflowStep
          label={props.cell.row.original.statusText ?? ''}
          state={props.cell.value}
          onClick={() => null}
          isSelected={false}
          key={props.cell.row.original.id}
        />
      );
    },
  },
];

export const FeatureAnnouncement = () => (
  <FeatureAnnouncementModal
    name="feature-announcement-lieferantenwechsel"
    image={LieferantenwechselAnnouncementImage}
    moreLink="https://support.ls.ampeers.energy/support/solutions/articles/80001040459-lieferantenwechsel"
    heading="Lieferantenwechsel"
    paragraphs={[
      'Ein einfacher Weg um komplizierte Wechselprozesse fristgerecht abzubilden, zu steuern und den Überblick zu behalten.',
      'Der Lieferantenwechsel-Prozess startet automatisch, wenn ein Vertrag angelegt wird.',
    ]}
  />
);

const SupplierPage = () => {
  // TODO Enable filtering when SSR search and filter is implemented
  // const [filters, setFilters] = React.useState<Filter[]>([]);

  return (
    <>
      <DocTitle titleParts={[supplierTitle]} />
      <Main>
        <Header Icon={Icons.SupplierChange} hideBackground>
          {supplierTitle}
        </Header>
        {/* TODO Enable filter bar when we have server side search and filtering */}
        {/* <FilterBar
          filters={filters}
          columns={baseColumns.map(({ Header, accessor }) => ({
            Header,
            accessor,
          }))}
          setAllFilters={setFilters}
          kind={'Wechsel'}
        /> */}
        <Content>
          <SupplierTable filters={[]} baseColumns={baseColumns} />
        </Content>
      </Main>
      <FeatureAnnouncement />
    </>
  );
};

export default SupplierPage;
