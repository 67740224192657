import React from 'react';
import styled from 'styled-components';
import { Button } from '@ampeersenergy/ampeers-ui-components';

import { Bold, Icons, FlexRow } from '../../../../components';

const Headline = styled(Bold)`
  //   margin-top: 10px;
  padding-top: 20px;
  font-size: 15px;
`;
const Wrap = styled.div`
  width: 400px;
  font-size: 14px;
  p {
    padding-left: 12px;
    margin-top: 0px;
  }
`;

const Spacer = styled.div`
  width: 5px;
`;
const ActionBtns = styled.div`
  margin-top: 25px;
  min-width: 200px;
  display: inline-block;
`;

export interface ConfirmationDialogProps {
  onDone: () => void;
  onSuccess: () => void;
}

function ConfirmationDialog({ onDone, onSuccess }: ConfirmationDialogProps) {
  return (
    <Wrap>
      <FlexRow>
        <Icons.Warning size={60} color="#A8E015" />
        <Headline>Eintrag wirklich löschen?</Headline>
      </FlexRow>
      <ActionBtns>
        <FlexRow>
          <Button
            onClick={() => {
              onSuccess();
            }}
          >
            Ja
          </Button>
          <Spacer />
          <Button onClick={() => onDone()}>Nein</Button>
        </FlexRow>
      </ActionBtns>
    </Wrap>
  );
}

export default ConfirmationDialog;
