import React, { useMemo } from 'react';
import styled from 'styled-components';
import {
  FormikInput,
  FormikSelect as FormikSelectComponent,
} from '@ampeersenergy/ampeers-ui-components';
import { DateTime } from 'luxon';

import { generateYearsInPeriod } from '../../helpers/dateHelpers';
import { useReadOpsClientSettingsQuery } from '../../graphql-types';

import { FormWrapper } from './style';

const FormikSelect = styled(FormikSelectComponent)`
  margin: 0 !important;
`;

export const ETaxReportForm: React.FC = () => {
  const { data: opsClientSetting } = useReadOpsClientSettingsQuery();

  const startDate = useMemo(() => {
    const clientStartDate =
      opsClientSetting?.readOpsClientSettings?.clientStartDate;
    return clientStartDate && DateTime.fromISO(clientStartDate).toJSDate();
  }, [opsClientSetting]);

  const endDate = useMemo(
    () => DateTime.now().minus({ year: 1 }).toJSDate(),
    [],
  );

  return (
    <FormWrapper>
      <FormikSelect
        label="Für welches Jahr soll der Bericht erstellt werden?"
        id="year"
        name="year"
        data-testid="etax-report-year"
      >
        <option disabled hidden value="">
          Bitte wählen
        </option>
        {generateYearsInPeriod(startDate, endDate)
          .map((value) => (
            <option key={`year-${value}`} value={value}>
              {value}
            </option>
          ))
          .reverse()}
      </FormikSelect>
      <FormikInput
        label="Welcher Ersatzwert soll für fehlende Autarkien verwendet werden?"
        prependix="Ersatzwert Autarkie:"
        appendix="%"
        id="fallbackAutarky"
        type="number"
        max="100"
        min="0"
        step=".01"
        name="fallbackAutarky"
        data-testid="etax-report-default-autarky"
      />
    </FormWrapper>
  );
};
