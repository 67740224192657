/* eslint-disable react/destructuring-assignment */
import React, { useMemo, useCallback } from 'react';
import { useFormikContext } from 'formik';
import { get } from 'lodash';

import {
  useReadTariffsSimpleQuery,
  useReadPlantsSimpleQuery,
  useReadLoadProfilesQuery,
  useReadBalancingAreaAccountsQuery,
  useReadSuppliersQuery,
  useReadCustomersSimpleQuery,
  useReadProjectsQueryAutoCompleteQuery,
  useReadBalancingAreaGroupAccountsQuery,
  useReadMeteringPointOperatorsQuery,
  useReadUnboundMetersByPlantQuery,
  useReadWorkspacesQuery,
  useReadPowerSuppliersQuery,
} from '../graphql-types';
import { RelationFormattersMap } from '../helpers/formatStrings';

import { FormikAutoComplete } from './autoComplete';
import Relation from './relation';
import { ErrorBox } from './input';
import {
  getRelationIcon,
  asDefaultRelationIcon,
} from './relation/getRelationIcon';

interface BooleanState {
  value: boolean;
  setValue: (value: boolean) => void;
}

export interface RelationSelectProps {
  type: string;
  name: string;
  label: string;
  id: string;
  formVariables?: any;
  'data-testid'?: string;
  filter?: (items: any[]) => any[];
  sort?: (items: any[]) => any[];
  placeholder?: string;
  disabled?: boolean;
  resetFromOutsideState?: [BooleanState['value'], BooleanState['setValue']];
  multiselect?: boolean;
  onChange?: (suggestion: any) => void;
  onClear?: () => void;
}

type MapByModelNames = {
  [key: string /* in "Tariff" | "Plant" */]: any;
};

const queries: MapByModelNames = {
  Tariff: useReadTariffsSimpleQuery,
  Plant: useReadPlantsSimpleQuery,
  Meter: useReadUnboundMetersByPlantQuery,
  LoadProfile: useReadLoadProfilesQuery,
  ExistingCustomer: useReadCustomersSimpleQuery,
  BalancingAreaAccount: useReadBalancingAreaAccountsQuery,
  Supplier: useReadSuppliersQuery,
  Project: useReadProjectsQueryAutoCompleteQuery,
  BalancingAreaGroupAccount: useReadBalancingAreaGroupAccountsQuery,
  MeteringPointOperator: useReadMeteringPointOperatorsQuery,
  Workspace: useReadWorkspacesQuery,
  PowerSuppliers: useReadPowerSuppliersQuery,
};

function RelationSelect(props: RelationSelectProps) {
  const { type, formVariables, filter, sort, ...rest } = props;

  if (!Object.prototype.hasOwnProperty.call(queries, type)) {
    throw new Error(
      `RelationSelect Relation of type: '${type}' is not supported`,
    );
  }

  const queryOptions: { [key: string]: any } = {
    fetchPolicy: 'network-only',
    ...(props?.disabled !== undefined && { skip: props?.disabled }),
  };

  if (type === 'LoadProfile') {
    if (!formVariables?.balancingAreaAccountId && !props?.disabled) {
      throw new Error(
        'GraphqlForm: formVariables={balancingAreaAccountId} is required for Relation LoadProfile',
      );
    }

    queryOptions.variables = {
      balancingAreaAccountId: formVariables.balancingAreaAccountId,
    };
  }

  if (type === 'Meter') {
    if (!formVariables?.plantId && !props?.disabled) {
      throw new Error(
        'GraphqlForm: formVariables={plantId} is required for Relation Meter',
      );
    }

    queryOptions.variables = {
      plantId: formVariables.plantId,
      boundStartAt: formVariables.boundStartAt,
      boundEndAt: formVariables.boundEndAt,
    };
  }

  if (type === 'ExistingCustomer') {
    queryOptions.variables = {
      isOperator: formVariables.isOperator,
    };
  }

  if (type === 'PowerSuppliers') {
    queryOptions.variables = {
      type: formVariables.type,
    };
  }
  const { data, error, loading } = queries[type](queryOptions);

  const resultKey = data ? Object.keys(data)[0] : null;

  const result =
    data && resultKey && data[resultKey] !== undefined ? data[resultKey] : null;

  const filteredResult = useMemo(
    () => (filter && result ? filter(result) : result),
    [result, filter],
  );
  const filterSortedResult = useMemo(
    () => (sort && filteredResult ? sort([...filteredResult]) : filteredResult),
    [sort, filteredResult],
  );

  const { touched, errors, submitCount } = useFormikContext();

  const hasError =
    (get(touched, props.name, false) || submitCount > 0) &&
    get(errors, props.name, false);

  const formatter = RelationFormattersMap[type.toLowerCase()];
  const icon = getRelationIcon(type.toLowerCase());
  const iconWithStyle = asDefaultRelationIcon(icon);

  const formatSuggestion = useCallback(
    (suggestion: any) => formatter(suggestion),
    [formatter],
  );

  const getDataFromSuggestion = useCallback(
    (suggestion: any) => suggestion.id,
    [],
  );

  const formatSelected = useCallback(
    (suggestion: any) => <Relation icon={icon} title={formatter(suggestion)} />,
    [formatter, icon],
  );

  return (
    <>
      <FormikAutoComplete
        {...rest}
        suggestions={filterSortedResult}
        loading={loading}
        error={error ? `Fehler beim Laden der ${props.label} Auswahl` : null}
        formatSuggestion={formatSuggestion}
        getDataFromSuggestion={getDataFromSuggestion}
        formatSelected={formatSelected}
        appendix={iconWithStyle}
        placeholder={props?.placeholder ?? `${props.label} wählen`}
      />
      {hasError && (
        <ErrorBox
          {...(props['data-testid']
            ? { 'data-testid': `${props['data-testid']}-error` }
            : {})}
        >
          Pflichtfeld
        </ErrorBox>
      )}
    </>
  );
}

export default RelationSelect;
