interface SelectionSet {
  selections?: Selection[];
}

interface Selection {
  name: SelectionName;
  selectionSet?: SelectionSet;
}

interface SelectionName {
  kind: string;
  value: string;
}

export const buildMutationReturnTypeRecursive = (set: SelectionSet): string => {
  if (!set.selections) return '';
  return set.selections
    .map(
      (selection: Selection) =>
        `${selection.name.value} ${selection.selectionSet ? '{ ' : ''}${
          selection.selectionSet
            ? `${buildMutationReturnTypeRecursive(selection.selectionSet)}`
            : ''
        }${selection.selectionSet ? ' }' : ''}`,
    )
    .join(' ');
};
