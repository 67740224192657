import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Label } from '@ampeersenergy/ampeers-ui-components';
import { useFormikContext } from 'formik';
import { DateTime } from 'luxon';

import { FlexRow, Flex } from '../../../../components';
import { GraphqlFormField } from '../../../../components/graphql-form/render';

const StyledLabel = styled.label`
  margin-left: 5px;
  font-size: 14px;
`;

const Margin = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
`;
export type PriceSheetInput = {
  name?: string;
  startDate?: any;
  basicPrice?: number;
  energyPrice?: number;
  energyPriceLocal?: number;
  energyPriceResidual?: number;
  ignorePriceGuarantee?: boolean;
};
interface CreatePricesheetFormProps {
  setEnergyPriceMixed: (energyPriceMixed: boolean) => void;
  isEnergyPriceMixed: boolean;
  setNewPriceSheet: (pricesheet: PriceSheetInput) => void;
  newPriceSheet: PriceSheetInput;
}

export function CreatePricesheetForm({
  setEnergyPriceMixed,
  isEnergyPriceMixed,
  setNewPriceSheet,
  newPriceSheet,
}: CreatePricesheetFormProps) {
  const { setFieldValue, getFieldProps } = useFormikContext();
  const startDate = getFieldProps('startDate').value;
  const numberOfDaysUntilInbox = useMemo(
    () =>
      DateTime.fromISO(startDate)
        .endOf('day')
        .diff(DateTime.now().endOf('day'), 'days').days,
    [startDate],
  );

  const isStartDateSet = useMemo(
    () => DateTime.fromISO(startDate).isValid,
    [startDate],
  );
  const isStartDateMoreThan32DaysInTheFuture = numberOfDaysUntilInbox > 32;

  useEffect(() => {
    if (
      getFieldProps('startDate').value !== newPriceSheet.startDate ||
      getFieldProps('name').value !== newPriceSheet.name ||
      getFieldProps('basicPrice').value !== newPriceSheet.basicPrice ||
      getFieldProps('energyPrice').value !== newPriceSheet.energyPrice ||
      getFieldProps('ignorePriceGuarantee').value !==
        newPriceSheet.ignorePriceGuarantee
    ) {
      setNewPriceSheet({
        startDate: getFieldProps('startDate').value,
        name: getFieldProps('name').value,
        basicPrice: getFieldProps('basicPrice').value,
        energyPrice: getFieldProps('energyPrice').value,
        ignorePriceGuarantee: getFieldProps('ignorePriceGuarantee').value,
      });
    }
  }, [getFieldProps, setNewPriceSheet, newPriceSheet]);

  return (
    <>
      <FlexRow>
        <Flex>
          <GraphqlFormField name="name" />
        </Flex>
      </FlexRow>
      <FlexRow>
        <Flex>
          <GraphqlFormField name="basicPrice" />
        </Flex>
      </FlexRow>
      <Label>Arbeitspreis</Label>
      <Flex>
        <input
          type="radio"
          id="energyPriceMixed"
          data-testid="energyPriceMixed"
          checked={isEnergyPriceMixed}
          onClick={() => {
            setFieldValue('energyPriceLocal', '', false);
            setFieldValue('energyPriceResidual', '', false);
            setEnergyPriceMixed(true);
          }}
          readOnly
        />
        <StyledLabel htmlFor="energyPriceMixed">Gemischt</StyledLabel>
      </Flex>
      <Flex>
        <input
          type="radio"
          id="energyPriceLocalResidual"
          data-testid="energyPriceLocalResidual"
          checked={!isEnergyPriceMixed}
          onClick={() => {
            setFieldValue('energyPrice', '', false);
            setEnergyPriceMixed(false);
          }}
          readOnly
        />
        <StyledLabel htmlFor="energyPriceLocalResidual">Getrennt</StyledLabel>
      </Flex>
      {isEnergyPriceMixed ? (
        <FlexRow>
          <Flex>
            <GraphqlFormField name="energyPrice" />
          </Flex>
        </FlexRow>
      ) : (
        <>
          <FlexRow>
            <Flex>
              <GraphqlFormField name="energyPriceLocal" />
            </Flex>
          </FlexRow>
          <FlexRow>
            <Flex>
              <GraphqlFormField name="energyPriceResidual" />
            </Flex>
          </FlexRow>
        </>
      )}
      <FlexRow>
        <Flex>
          <GraphqlFormField
            name="startDate"
            {...(!isStartDateSet
              ? null
              : isStartDateMoreThan32DaysInTheFuture
              ? {
                  hint: `Die Ankündigungsfrist von 4 Wochen ist eingehalten, wenn das Preisänderungsschreibes innerhalb\n der nächsten ${
                    numberOfDaysUntilInbox - 28
                  } Tage bei den Kunden eintrifft.`,
                }
              : {
                  warning:
                    'Die Ankündigungsfrist von 4 Wochen kann nicht mehr eingehalten werden. ' +
                    'Bitte ändere den Gültigkeitsbeginn des neuen Preisblatts. ' +
                    ' Wenn Du dennoch fortfahren möchtest, klicke auf "Speichern.”',
                })}
          />
        </Flex>
      </FlexRow>
      <Margin>
        <GraphqlFormField
          name="ignorePriceGuarantee"
          label="Bestehende Preisgarantien ignorieren"
        />
        Dies kann ein Sonderkündigungsrecht für bestehende Verträge zur Folge
        haben.
      </Margin>
    </>
  );
}
