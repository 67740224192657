import * as React from 'react';
import {
  InfoAlert,
  Label,
  SubTitle,
} from '@ampeersenergy/ampeers-ui-components';
import styled from 'styled-components/macro';

import { GraphqlFormField } from '../../../components/graphql-form/render';
import { useGraphqlForm } from '../../../components/graphql-form/hooks/useGraphqlForm';
import { FormikSelect } from '../../../components';
import { TooltipInfo } from '../../../components/TooltipInfo';

import { MarkdownTextarea } from './MarkdownTextarea';

interface ContactDataFormProps {
  prefix?: string;
}

const List = styled.ul`
  margin: 0;
  padding: 0 0 0 1.8rem;
`;

const Code = styled.code`
  padding: 0.2em 0.4em;
  font-size: 85%;
  background: ${(props) => props.theme.palette.border};
  border-radius: 4px;
`;

export function ContactDataForm({ prefix }: ContactDataFormProps) {
  const { isEditing, getFieldProps } = useGraphqlForm();

  const [numberOfFooters, setNumberOfFooters] = React.useState<number>(2);

  React.useEffect(() => {
    const initialNumberOfFooters = getFieldProps(
      `${prefix}numberOfFooters`,
    )?.value;

    if (initialNumberOfFooters) {
      setNumberOfFooters(initialNumberOfFooters);
    }
  }, [getFieldProps, prefix]);

  return (
    <>
      <SubTitle>Kontaktdaten</SubTitle>
      <div>
        <Label>
          Mako E-Mailadresse
          <TooltipInfo
            id="mailTooltip"
            text="Die E-Mailadresse, die in der Kommunikation mit Marktpartnern
          verwendet wird, nur für das Modul Lieferantenwechsel relevant"
          />
        </Label>
        <GraphqlFormField name={`${prefix}email`} label={null} />
      </div>
      <GraphqlFormField name={`${prefix}contact.serviceEmail`} />
      <GraphqlFormField
        name={`${prefix}contact.serviceHotline`}
        label="Kundenservice Telefonnummer"
        placeholder="+49 123 456789"
      />
      <div>
        <Label>
          Servicezeiten
          <TooltipInfo
            id="contact.serviceInfo"
            text="Die Servicezeiten, bspw. 'Mo.-Fr.: 08:00 - 18:00 Uhr'"
          />
        </Label>
        <MarkdownTextarea
          name={`${prefix}contact.serviceHours`}
          rows={2}
          maxRows={3}
          label={null}
        />
      </div>
      <GraphqlFormField name={`${prefix}contact.headerAddress`} />
      <SubTitle css="margin-top: 24px;">
        Fußzeile
        <TooltipInfo
          id="footerInfo"
          text="Informationen für die Fußzeile der generierten Dokumente"
        />
      </SubTitle>
      <FormikSelect
        id="numberOfFooters"
        name={`${prefix}numberOfFooters`}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setNumberOfFooters(+e.target.value)
        }
        disabled={!isEditing}
      >
        <option id="2" value="2">
          2 Spalten
        </option>
        <option id="3" value="3">
          3 Spalten
        </option>
        <option id="4" value="4">
          4 Spalten
        </option>
      </FormikSelect>
      <MarkdownTextarea
        name={`${prefix}contact.footer1`}
        label="Fußzeile Spalte 1"
      />
      <MarkdownTextarea
        name={`${prefix}contact.footer2`}
        label="Fußzeile Spalte 2"
      />
      {numberOfFooters >= 3 && (
        <MarkdownTextarea
          name={`${prefix}contact.footer3`}
          label="Fußzeile Spalte 3"
        />
      )}
      {numberOfFooters >= 4 && (
        <MarkdownTextarea
          name={`${prefix}contact.footer4`}
          label="Fußzeile Spalte 4"
        />
      )}
      {isEditing ? (
        <InfoAlert>
          <p css="margin: 0;">Formatierungshilfen:</p>
          <List>
            <li>
              <Code>_</Code>: Kursiv
            </li>
            <li>
              <Code>**</Code>: Fett
            </li>
          </List>
        </InfoAlert>
      ) : null}
    </>
  );
}
