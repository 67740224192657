import React from 'react';
import { Icons, IconProps } from '@ampeersenergy/ampeers-ui-components';

const RelationIconMap: {
  [key: string]: React.ComponentType<IconProps> | null;
} = {
  tariff: Icons.Tariff,
  meter: Icons.Meter,
  plant: Icons.Project,
  loadprofile: () => null,
  existingcustomer: () => null,
  balancingareaaccount: () => null,
  balancingareagroupaccount: () => null,
  meteringpointoperator: () => null,
  supplieraccount: () => null,
  supplier: () => null,
  project: Icons.Project,
  workspace: () => null,
  customer: Icons.Customer,
  contract: Icons.Contract,
  powersuppliers: () => null,
};

export const getRelationIcon = (type: string): any =>
  RelationIconMap[type] ?? null;

export const asDefaultRelationIcon = (
  Icon: React.ComponentType<IconProps>,
  props: IconProps = {
    size: 20,
    color: '#119142',
  },
) => <Icon {...props} />;
