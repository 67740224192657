import React from 'react';
import styled from 'styled-components';

import { Icons } from '../../../../components';
import { EndMessageContainerProps } from '../../../../components/createFlow';

const Wrap = styled.div`
  font-size: 16px;
  p {
    padding-left: 12px;
    margin-top: 0px;
  }
`;

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;
`;

function EndMessageEditMetadata({ variables }: EndMessageContainerProps) {
  return (
    <Wrap>
      <Center data-testid="create-flow-success">
        <div>
          <Icons.Checkmark size={60} color="#A8E015" />
        </div>
        Metadaten erfolgreich
        {variables.performedActionState[0] === 'edited'
          ? ' geändert'
          : ' gelöscht'}
        .
      </Center>
    </Wrap>
  );
}

export default EndMessageEditMetadata;
