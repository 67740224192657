import * as yup from 'yup';

import { yupNumberAsString } from '../yupNumberAsString';

export const yupInitialNullableMeterReading = yup
  .object({
    date: yup.string().nullable(),
    value: yupNumberAsString.nullable(),
    obis: yup.string().nullable(),
    reason: yup.string().nullable(),
    valueStatus: yup.string().nullable(),
    ignoreWarning: yup.string().nullable(),
  })
  .nullable();

/**
 * This function finds valid minimum and maximum values
 * for the given date(newDate) and data consisting of an array of objects
 * with date, value properties. The newDate gets combined with the rest of data
 * with value set to string(newValue) that can be tracked, because
 * no other value is equal to "newValue". Sorting this new array
 * results object with newDate getting the correct place in the array.
 * Now knowing the index of this newDate in relation to other dates and values,
 * min and max values can be found with help of the neighboring(index ±1) values.
 * Minimum value gets determined the following way: if the lower value(index-1)
 * is nonexistent or equals 0 then minimum allowed value is 0, else that lower
 * neighboring value becomes lower limit.
 * Maximum value is found by checking whether upper neighboring value exists:
 * if not, then there is no upper limit and it gets set to null;
 * if the upper limit is 0, then there is already a meterReading value equal to 0
 * with a later date, which means value 0 cannot be exceeded for that newDate;
 * else(next neighboring value exists and not equal to 0) maximum limit gets set to
 * the value of the next object in the array.
 */
export const findMeterReadingMinMaxLimits = (
  data: { date: string; value: string }[],
  newDate: Date,
): { min: number; max: number | null } => {
  if (!newDate) {
    return { min: 0, max: null };
  }
  const temp = [...data, { date: newDate, value: 'newValue' }];
  temp.sort(
    (a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime(),
  );
  const index = temp.findIndex((v) => v.value === 'newValue');

  const min =
    typeof temp[index - 1] === 'undefined' ||
    parseFloat(temp[index - 1]?.value) === 0
      ? 0
      : parseFloat(temp[index - 1].value);
  const max =
    typeof temp[index + 1] === 'undefined'
      ? null
      : parseFloat(temp[index + 1].value) === 0
      ? 0
      : parseFloat(temp[index + 1].value);

  return { min, max };
};
