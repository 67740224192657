import styled, { withTheme } from 'styled-components';
import React from 'react';
import type { Theme } from '@ampeersenergy/ampeers-ui-components';

import { ReactComponent as Background } from '../assets/moduleBg.svg';

import { IconProps } from './baseIcon';
import { FlexRow, Flex } from './layout';

const Wrapper = styled.div`
  position: relative;
  margin-left: -20px;
  margin-right: -20px;
`;

const Headline = styled.h1`
  font-size: 26px;
  color: #000000;
  letter-spacing: 0.3px;
  font-weight: 100;
  margin: 0;
`;

const IconWrapper = styled.div`
  background: #fff;
  padding: 10px;
  border-radius: 4px;
  display: inline-block;
  margin-right: 10px;
`;

const Header = styled(FlexRow)`
  position: absolute;
  padding-top: 20px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;
`;

const Content = styled.div`
  position: absolute;
  width: 100%;
  top: 80px;
  padding-left: 20px;
  padding-right: 20px;
`;

const ActionRow = styled(FlexRow)`
  justify-content: flex-end;
`;

interface ModuleTitleProps {
  icon: React.ComponentType<IconProps>;
  title: string;
  background?: boolean;
  theme: Theme;
  actions?: any;
}

const ModuleTitle: React.FunctionComponent<ModuleTitleProps> = ({
  background,
  icon,
  theme,
  title,
  children,
  actions,
}) => {
  const Icon = icon;

  return (
    <Wrapper>
      <Header>
        <IconWrapper>
          <Icon size={25} color={theme.primaryColor} />
        </IconWrapper>
        <Headline>{title}</Headline>
        <Flex>
          <ActionRow>{actions && actions()}</ActionRow>
        </Flex>
      </Header>
      {background && <Background />}
      <Content>{children}</Content>
    </Wrapper>
  );
};

export default withTheme(ModuleTitle);
