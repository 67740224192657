import { defaultTheme, Icons } from '@ampeersenergy/ampeers-ui-components';

import { Status } from '../../graphql-types';
import { documentTypesKeyToI18n } from '../../helpers/formatStrings';

const jobNames = {
  contract_import: 'Verträge',
  contract_update_import: 'Aktualisierte Verträge',
  confirm_invoices: 'Rechnungen',
} as { [key: string]: string };

export function translateJobName(jobName?: string) {
  if (!jobName) return 'Dokumente';

  const label = documentTypesKeyToI18n[jobName];
  if (label) return label;

  return jobNames[jobName] || 'Dokumente';
}

export function getJobVerb(jobName?: string) {
  switch (jobName) {
    case 'contract_import':
      return 'importiert';
    case 'pricesheet_change_letter':
      return 'erstellt';
    case 'confirm_invoices':
      return 'freigegeben';
    default:
      return 'verarbeitet';
  }
}

export function getJobIcon(status?: Status, hasErrors = false) {
  switch (status) {
    case Status.Completed:
      return hasErrors ? Icons.Warning : Icons.Checkmark;
    case Status.Failed:
      return Icons.Warning;
    case Status.CompletedWithErrors:
      return Icons.Warning;
    default:
      return Icons.Progress;
  }
}

export function getIconColor(status?: Status, hasErrors = false) {
  switch (status) {
    case Status.Completed:
      return hasErrors
        ? defaultTheme.palette.warning.color
        : defaultTheme.palette.success.color;
    case Status.Failed:
      return defaultTheme.palette.error.color;
    case Status.CompletedWithErrors:
      return defaultTheme.palette.warning.color;
    default:
      return defaultTheme.palette.border;
  }
}
