import React, { useMemo } from 'react';
import {
  ColumnDefinition,
  Filter,
  SubTitle,
} from '@ampeersenergy/ampeers-ui-components';

import { SupplierChangeTable } from './table';

import type { SupplierBaseColumns } from './index';

export const disableSortAndFilter = {
  filterable: false,
  disableSortBy: true,
};

export const SupplierTable: React.FC<{
  filters: Filter[];
  baseColumns: ColumnDefinition<SupplierBaseColumns>[];
}> = ({ filters, baseColumns }) => {
  const changeColumns: any = useMemo(() => {
    return [
      ...baseColumns.slice(0, -1),
      {
        Header: 'Verteilnetzbetreiber',
        accessor: 'marketPartnerLabel',
        type: 'string',
        width: '17%',
        ...disableSortAndFilter,
      },
      ...baseColumns.slice(-1),
    ];
  }, [baseColumns]);

  const cancellationColumns: any = useMemo(() => {
    return [
      ...baseColumns.slice(0, -1),
      {
        Header: 'Altlieferant',
        accessor: 'marketPartnerLabel',
        type: 'string',
        width: '17%',
        ...disableSortAndFilter,
      },
      ...baseColumns.slice(-1),
    ];
  }, [baseColumns]);

  return (
    <>
      <SubTitle>Wechsel in Mieterstrom</SubTitle>
      <SupplierChangeTable columns={changeColumns} filters={filters} />
      <SubTitle>
        Wechsel in Drittbelieferung (beginne Lieferantenwechsel)
      </SubTitle>
      <SupplierChangeTable
        columns={changeColumns}
        workflowType="supplierChangeThirdParty"
        filters={filters}
      />
      <SubTitle>Vertragskündigung</SubTitle>
      <SupplierChangeTable
        columns={cancellationColumns}
        workflowType="cancellation"
        filters={filters}
      />
    </>
  );
};
