import styled from 'styled-components';

import { PaddedShadowBox } from './layout';

export const SuccessMsg = styled(PaddedShadowBox)`
  padding: 1px 16px 16px 16px;
  margin-bottom: 20px;
  h3 {
    margin-bottom: 2px;
    margin-top: 14px;
  }
  h3 svg {
    margin-right: 7px;
  }
`;
