/* eslint-disable no-continue */
/* eslint-disable no-await-in-loop */
/* eslint-disable guard-for-in, no-restricted-syntax, no-console */
import * as yup from 'yup';

import { yupUTCDate } from '../../helpers/yupUTCDate';

export const getYupTypeForType = (
  type: string,
): { schema: yup.BaseSchema<any>; yupType: string } | undefined => {
  switch (type) {
    case 'CountryScalar':
      return {
        schema: yup
          .string()
          .test(
            'Deutschland',
            'Muss 2 Zeichen lang sein',
            (value: string | undefined) => {
              if (
                value?.toLowerCase() === 'deutschland' ||
                value?.toLowerCase() === 'germany' ||
                value?.length === 2
              ) {
                return true;
              }
              return false;
            },
          ),
        yupType: 'string',
      };
    case 'String':
    case 'Enum':
      return {
        schema: yup.string(),
        yupType: 'string',
      };
    case 'Int':
    case 'Float':
    case 'ID':
      return {
        schema: yup.number(),
        yupType: 'number',
      };
    case 'Date':
    case 'DateTime':
      return {
        schema: yupUTCDate.transform(emptyStringToNull),
        yupType: 'date',
      };
    case 'Boolean':
      return {
        schema: yup.boolean(),
        yupType: 'boolean',
      };
    default:
      break;
  }
};

export const buildNestedYupSchema = (
  obj: any,
  validationDependencies?: { [key: string]: [string, string] },
) => {
  const schema: { [key: string]: any } = {};

  for (const key in obj) {
    if (yup.isSchema(obj[key])) {
      schema[key] = obj[key];
    } else if (Array.isArray(obj[key])) {
      schema[key] = yup.array().of(buildNestedYupSchema(obj[key][0]));
    } else {
      schema[key] = buildNestedYupSchema(obj[key]);
    }

    if (
      validationDependencies &&
      Object.keys(validationDependencies).includes(key)
    ) {
      return yup.object().shape(schema, [validationDependencies[key]]);
    }
  }
  return yup.object(schema);
};

// helper for yup transform function
function emptyStringToNull(value: any, originalValue: any) {
  if (typeof originalValue === 'string' && originalValue === '') {
    return null;
  }
  return value;
}

export const validateTree = async (
  obj: any,
  cb: (value: any, path: string) => Promise<{ [key: string]: any }>,
  path: string,
) => {
  const errors: { [key: string]: any } = {};

  for (const key in obj) {
    if (!Object.prototype.hasOwnProperty.call(obj, key)) continue;
    switch (toString.call(obj[key])) {
      case '[object Object]': {
        {
          const val = await validateTree(obj[key], cb, `${path}.${key}`);
          if (val !== undefined) {
            errors[key] = val;
          }
        }
        break;
      }
      case '[object Array]':
        {
          const val = await Promise.all(
            obj[key].map((e: any, index: number) =>
              cb(e, `${path}.${key}[${index}]`),
            ),
          );
          if (val !== undefined) {
            errors[key] = val;
          }
        }
        break;
      default:
        {
          const val = await cb(obj[key], `${path}.${key}`);
          if (val !== undefined) {
            errors[key] = val;
          }
        }
        break;
    }
  }

  return Object.keys(errors).length !== 0 ? errors : undefined;
};

export const buildYupValidationChain = (
  props: { [key: string]: any },
  isList: boolean,
  _validations?: { [key: string]: any },
  debug?: boolean,
) => {
  let chain: yup.BaseSchema<any>;
  const { type, ...validations } = _validations || {};
  const debugChain = ['yup'];

  if (typeof type !== 'undefined') {
    if (debug) {
      debugChain.push(`${type}()`);
    }

    chain = (yup as any)[type]?.();
  } else {
    const yupSchemaType = getYupTypeForType(props.dataType);

    if (!yupSchemaType?.schema) {
      console.log(props);
      throw new Error(
        `Couldn't translate type: '${props.dataType}' to yup type`,
      );
    }

    if (debug) {
      debugChain.push(`${yupSchemaType.schema}`);
    }

    chain = yupSchemaType.schema as any;
  }

  for (const e in validations) {
    if (debug) {
      debugChain.push(`${e}(${validations[e]})`);
    }
    if (e === 'matches') {
      chain = (chain as any).matches(new RegExp(validations[e]), {
        excludeEmptyString: !props.required,
      });
    } else {
      chain = (chain as any)[e](validations[e]);
    }
  }

  chain = (chain as any).nullable();

  if (debug) {
    debugChain.push(`nullable()`);
  }

  if (props.required || props['aria-required']) {
    chain = (chain as any).required();
    if (debug) {
      debugChain.push(`required()`);
    }
  }

  if (debug) {
    console.log(props.name, debugChain.join('.'));
  }

  if (isList) {
    return yup.array().of(chain).ensure();
  }

  return chain;
};
