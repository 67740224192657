import { getSession } from '../services/session';

import { _ae_env_ } from './env';

export function buildDocumentUrl(
  relativeURL: string,
  asAttachment = true,
  includeToken = true,
) {
  const session = getSession();

  const fileURL = new URL(relativeURL, _ae_env_.REACT_APP_BACKEND_BASE_URI);

  if (includeToken) {
    fileURL.searchParams.set('token', session.token!);
    fileURL.searchParams.set('client_id', session.clientId!);
  }
  fileURL.searchParams.set('attachment', String(asAttachment));

  return fileURL.toString();
}
