import * as React from 'react';
import { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { Button, SpinnerDark } from '@ampeersenergy/ampeers-ui-components';

import { useReadCheckClientSetupLazyQuery } from '../../../graphql-types';

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const RequestButton = styled(Button)`
  width: 160px;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
`;

const ResultWrapper = styled.div`
  background-color: ${(props) => props.color};
  display: flex;
  align-items: center;
  width: 60%;
  height: 100%;
  margin: 0 auto;
  padding: 10px;
`;

const SpinnerWrap = styled.div`
  display: flex;
  min-width: 160px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export default function TestSettings() {
  const theme = useTheme();
  const mapTypeToColor = {
    error: theme.palette.error.background,
    success: theme.palette.success.background,
    empty: theme.palette.info.background,
  };
  // const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState<{
    message: string;
    type: 'error' | 'success' | 'empty';
  } | null>(null);
  const [readClientSetup, { data, loading }] =
    useReadCheckClientSetupLazyQuery();

  React.useEffect(() => {
    if (data) {
      const isSuccess = data?.readCheckClientSetup?.success;

      if (isSuccess) {
        setResult({
          message: 'Successful Request',
          type: 'success',
        });
      } else {
        setResult({
          message: data?.readCheckClientSetup?.error || '',
          type: 'error',
        });
      }
    }
  }, [data]);

  const checkClientSetup = async () => {
    await readClientSetup();
  };

  return (
    <ButtonWrapper>
      <Wrapper>
        <RequestButton onClick={checkClientSetup}>
          Check Client Setup
        </RequestButton>

        <ResultWrapper color={mapTypeToColor[result?.type ?? 'empty']}>
          {!loading ? (
            <div>
              {result ? result.message.toString() : 'No request made yet'}
            </div>
          ) : (
            <SpinnerWrap>
              <SpinnerDark size={30} />
            </SpinnerWrap>
          )}
        </ResultWrapper>
      </Wrapper>
    </ButtonWrapper>
  );
}
