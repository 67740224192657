import { InfoAlert } from '@ampeersenergy/ampeers-ui-components';
import * as React from 'react';
import * as yup from 'yup';

import GraphqlMultiStepForm from '../../../components/graphql-form/multistepForm';
import {
  MkvTemplateData,
  MkvTemplateInput,
  ReadMkvTemplatesDocument,
} from '../../../graphql-types';
import { removeTypename } from '../../../helpers/utils';
import { contractStatusValidator } from '../../contract/create/formParts/contractStatus/contractStatus';
import { CustomerForm } from '../../contract/create/sharedFormParts';

import { AddressTemplateForm } from './AddressTemplateForm';
import { ContractTemplateForm } from './ContractTemplateForm';

interface MeterTemplatesProps {
  data?: MkvTemplateData | null;
  loading?: boolean;
  meterType:
    | 'sumMeterConsumption'
    | 'sumMeterFeedIn'
    | 'prodMeter'
    | 'cascadeMeter';
  onClose?: (success?: boolean) => void;
}

export function MeterTemplates({
  data,
  loading,
  meterType,
  onClose,
}: MeterTemplatesProps) {
  const defaultValues = React.useMemo(() => {
    if (data) {
      return removeTypename({
        ...data,
        // We need to set loadProfile to undefined if it is null otherwise it will cause issues with the select
        loadProfile: data.loadProfile ? data.loadProfile : undefined,
      });
    }
    return undefined;
  }, [data]);

  const [customerLabel, setCustomerLabel] = React.useState(false);
  const [customerPerson, setCustomerPerson] = React.useState(true);

  const resetCustomerStates = React.useCallback(() => {
    setCustomerLabel(false);
    setCustomerPerson(true);
  }, []);

  if (!data || Object.keys(data).length === 0) {
    return (
      <InfoAlert>
        Die Vorlage wird nach dem Anlegen des ersten Gebäudevertrags erstellt
        und kann anschließend hier bearbeitet werden.
      </InfoAlert>
    );
  }

  const steps = [
    {
      title: 'Vertragspartner',
      content: (
        <CustomerForm
          disableNewCustomer
          customerPersonState={[customerPerson, setCustomerPerson]}
          customerLabelState={[customerLabel, setCustomerLabel]}
          existingCustomerState={[true, () => {}]}
          resetCustomerStates={resetCustomerStates}
        />
      ),
    },
    {
      title: 'Adresse',
      content: <AddressTemplateForm isExistingCustomer />,
    },
    {
      title: 'Vertrag',
      content: <ContractTemplateForm />,
    },
  ];

  return (
    <GraphqlMultiStepForm
      mutation="updateMkvTemplate"
      startInEdit
      steps={steps}
      defaultValues={defaultValues}
      refetchQueries={[
        {
          query: ReadMkvTemplatesDocument,
        },
      ]}
      readDocumentFields={['__typename']}
      formVariables={{
        isOperator: ['prodMeter', 'sumMeterFeedIn'].includes(meterType),
      }}
      variables={{
        mkvType: meterType,
      }}
      formatBeforeSubmit={({
        customer,
        ...templateData
      }: MkvTemplateInput) => ({
        customer: {
          person: customer?.person,
          address: customer?.address,
          advertisementAccepted: customer?.advertisementAccepted,
          documentDeliveryMethod: customer?.documentDeliveryMethod,
          label: customer?.label,
          existingCustomer: customer?.existingCustomer,
          isOperator: ['prodMeter', 'sumMeterFeedIn'].includes(meterType),
        },
        ...templateData,
      })}
      isLoading={loading}
      labels={{
        submit: 'Änderungen speichern',
      }}
      onSuccess={() => onClose && onClose(true)}
      onAbort={() => onClose && onClose(false)}
      validation={{
        customer: {
          documentDeliveryMethod: yup.string().required(),
          existingCustomer: {
            id: yup.string().required(),
          },
        },
        loadProfile: yup.string().required(),
        status: contractStatusValidator,
      }}
    />
  );
}
