import React from 'react';
import styled from 'styled-components';

import ErrorMsg from './errorMsg';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  flex-direction: column;
`;

export default function NothingFound() {
  return (
    <Wrapper>
      <ErrorMsg message="Die angegebene Seite konnte nicht gefunden" />
    </Wrapper>
  );
}
