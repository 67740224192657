import {
  Bold,
  ButtonIcon,
  ColumnDefinition,
  Icons,
  Modal,
  SpinnerDark,
  Table,
  useDialog,
} from '@ampeersenergy/ampeers-ui-components';
import { DateTime } from 'luxon';
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components/macro';

import {
  Meter,
  MeterReading,
  Metering,
  ReadMeterReadingsDocument,
  ReadMeterReadingsQuery,
  useDeleteMeterReadingMutation,
} from '../../../../../graphql-types';
import {
  METER_READING_END_DATE,
  METER_READING_START_DATE,
} from '../../../../../helpers/constants';
import {
  EMPTY_STR,
  formatMeterReadingValue,
} from '../../../../../helpers/formatStrings';

const SpinnerDarkStyled = styled(SpinnerDark)`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const DeletionStatus = styled.div`
  max-width: 400px;
  min-width: 225px;
  padding-top: 0px;
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;
`;

const DeletionWarning = styled.div`
  > p {
    &:first-child {
      margin-top: 0px;
    }
    &:last-child {
      margin-bottom: 0px;
    }
  }
`;

type Readings = Omit<MeterReading, 'meterId'> & {
  date: string;
  delete: {
    deleteMutation?: () => Promise<void>;
  };
};

function formatMeterReason(reason?: string | null) {
  switch (reason) {
    case 'COS_VERTRAGSBEGINN':
      return 'Vertragsbeginn';
    case 'COS_VERTRAGSENDE':
      return 'Vertragsende';
    case 'COT':
      return 'Zwischenablesung';
    case 'COM':
      return 'Zählerwechsel';
    case 'PMR':
      return 'Turnusablesung';
    default:
      return EMPTY_STR;
  }
}

function formatMeterHint(hint?: string | null) {
  switch (hint) {
    case 'SMV':
      return 'Anfangsstand';
    case 'EMV':
      return 'Endstand';
    case 'MRV':
      return 'Zählerstand';
    default:
      return EMPTY_STR;
  }
}

function valueStatusMapping(s?: string | null) {
  if (!s) {
    return EMPTY_STR;
  }

  switch (s) {
    case '220':
      return 'wahrer Wert';
    case '67':
      return 'Ersatzwert';
    case '201':
      return 'Vorschlagswert';
    case 'Z18':
      return 'vorläufiger Wert';
    default:
      return EMPTY_STR;
  }
}

const consumptionColumns: ColumnDefinition<Readings>[] = [
  {
    Header: 'Ablesedatum',
    accessor: 'date',
    width: '19%',
    type: 'date',
    Cell: ({ value }) => DateTime.fromISO(value).toUTC().toFormat('dd.MM.yyyy'),
  },
  {
    Header: 'Zählerstand',
    accessor: 'value',
    width: '19%',
    Cell: ({ value }) => formatMeterReadingValue(value),
  },
  {
    Header: 'Ablesegrund',
    accessor: 'reason',
    width: '19%',
  },
  {
    Header: 'Erfassungshinweis',
    accessor: 'hint',
    width: '21%',
  },
  {
    Header: 'Wertstatus',
    accessor: 'valueStatus',
    width: '17%',
  },
  {
    Header: 'Löschen',
    accessor: 'delete',
    width: '5%',
    disableSortBy: true,
    Cell: ({ value: { deleteMutation } }) => (
      <ButtonIcon
        icon={Icons.Delete}
        disabled={!deleteMutation}
        onClick={deleteMutation}
        small
        secondary
        color="#E30045"
      />
    ),
  },
];

export function SlpReadingsTable({
  readings,
  loading,
  meter,
  startDate,
  endDate,
}: {
  readings: ReadMeterReadingsQuery['readMeterReadings'];
  loading: boolean;
  meter: Pick<Meter, 'melo' | 'metering' | 'meterType' | 'id'>;
  startDate?: string;
  endDate?: string;
}) {
  const { openConfirmation } = useDialog();
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

  const [
    deleteMeterReadingMutation,
    {
      data: deleteMeterReadingResponse,
      loading: isDeleteMRLoading,
      error: deleteMRMutationError,
    },
  ] = useDeleteMeterReadingMutation();

  const deleteMeterReading = useCallback(
    async (selectedMeterReading: {
      obisChannel: string;
      meloID: string;
      startDate: string;
      endDate: string;
      reason?: string;
    }) => {
      await deleteMeterReadingMutation({
        variables: selectedMeterReading,
        refetchQueries: [
          {
            query: ReadMeterReadingsDocument,
            variables: {
              meterId: meter!.id,
              startDate: startDate ?? METER_READING_START_DATE,
              endDate: endDate ?? METER_READING_END_DATE,
              metering:
                meter.metering === Metering.Slp ? Metering.Slp : Metering.Rlm,
              obisChannel: selectedMeterReading.obisChannel,
            },
          },
        ],
      });
      setDeleteModalIsOpen(true);
    },
    [deleteMeterReadingMutation, endDate, meter, startDate],
  );

  const formattedReadings = useMemo(() => {
    if (!readings) return [];

    return readings.map((reading) => {
      const content = reading.usedForBilling ? (
        <DeletionWarning>
          <p>
            <em>Vorsicht:</em> Bist Du dir sicher, dass der ausgewählte
            Zählerstand gelöscht werden soll? Dieser wurde bereits für die
            Erstellung einer Abrechnung genutzt.
          </p>
          <p>
            Ein abweichender Zählerstand kann Auswirkungen auf den Autarkiegrad
            der Kundenanlage haben und daher alle abgerechneten Verträge
            beeinflussen.
          </p>
          <p>Gelöschte Zählerstände können nicht wiederhergestellt werden.</p>
        </DeletionWarning>
      ) : (
        <DeletionWarning>
          <p>
            Bist Du sicher, dass Du diesen <Bold>Zählerstand</Bold> löschen
            möchtest?
          </p>
          <p>Gelöschte Zählerstände können nicht wiederhergestellt werden.</p>
        </DeletionWarning>
      );

      const deleteMutation = async () => {
        await openConfirmation({
          confirmButtonLabel: 'Löschen',
          dangerButton: true,
          content,
          title: 'Zählerstand löschen',
          maxWidth: reading.usedForBilling ? 'md' : 'sm',
          handleRequest: () =>
            deleteMeterReading({
              obisChannel: reading.obis,
              meloID: reading.melo,
              startDate: reading.from,
              endDate: reading.to,
              reason: reading.reason ?? undefined,
            }),
        });
      };

      // Prevents a reading from being deleted when the reason is COM (Zählerwechsel)
      const preventMeterReadingFromBeingDeleted = reading.reason === 'COM';

      return {
        ...reading,
        date: reading.from,
        reason: formatMeterReason(reading.reason),
        hint: formatMeterHint(reading.hint),
        valueStatus: valueStatusMapping(reading.valueStatus),
        delete: {
          ...(preventMeterReadingFromBeingDeleted ? null : { deleteMutation }),
        },
      };
    });
  }, [deleteMeterReading, openConfirmation, readings]);

  return (
    <div data-testid="meter-reading-table-wrapper">
      <Modal
        isOpen={deleteModalIsOpen}
        onRequestClose={() => {
          setDeleteModalIsOpen(false);
        }}
        contentLabel="Delete-MeterReading-Info-Modal"
        title="Zählerstand löschen"
      >
        <DeletionStatus>
          {isDeleteMRLoading ? (
            <SpinnerDarkStyled size={50} />
          ) : deleteMRMutationError ||
            !deleteMeterReadingResponse?.deleteMeterReading.success ? (
            <>
              <div>
                <Icons.Wrong size={60} color="#FF1F00" />
              </div>
              {`Wert kann nicht gelöscht werden, da dieser in einem bereits abgerechneten Zeitbereich liegt. Wende Dich gegebenenfalls an den Service Desk.`}{' '}
            </>
          ) : (
            <>
              <div>
                <Icons.Checkmark size={60} color="#A8E015" />
              </div>
              Wert wurde gelöscht
            </>
          )}
        </DeletionStatus>
      </Modal>
      <Table
        data={formattedReadings}
        isLoading={loading}
        columns={consumptionColumns}
        filterKind="Zählerstände"
        compact
        withAlternatingRows
        withPagination
      />
    </div>
  );
}
