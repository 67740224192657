import gql from 'graphql-tag';

export default gql`
  mutation createContractsBatched($contracts: [CreateContractsBatchedInput!]!) {
    createContractsBatched(contracts: $contracts) {
      __typename
      ... on ContractsBatchedSuccess {
        jobId
        jobName
        status
        progress
        total
      }
      ... on CreateContractsBatchedError {
        failedContracts {
          customerLabel
          contractLabel
          message
          error
        }
        message
      }
    }
  }
`;
