import { Link } from '@ampeersenergy/ampeers-ui-components';
import { DateTime } from 'luxon';
import React from 'react';
import styled from 'styled-components';

import { StepStatus, SupplierChangeWorkflowType } from '../../../graphql-types';

const Red = styled.span`
  color: ${(props) => props.theme.palette.error.color};
  font-weight: bold;
`;

function getDeadlineMessage(type?: SupplierChangeWorkflowType, deadline = '') {
  switch (type) {
    case SupplierChangeWorkflowType.Cancellation:
      return `Der alte Lieferant hat bis zum ${deadline} Zeit die Kündigung vorzunehmen.`;
    case SupplierChangeWorkflowType.SupplierChangeThirdParty:
      return `Der neue Lieferant hat bis zum ${deadline} Zeit die Anmeldung vorzunehmen.`;
    case SupplierChangeWorkflowType.SupplierChangeLocalSupply:
    default:
      return `Der Netzbetreiber hat bis zum ${deadline} Zeit die Abmeldung vorzunehmen.`;
  }
}

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.primaryColor};
`;

export default function WaitForAnswer({
  deadline,
  status,
  type,
  phoneNumber,
}: {
  deadline?: Date;
  status: StepStatus;
  type?: SupplierChangeWorkflowType;
  phoneNumber?: string;
}) {
  const deadlineString = deadline
    ? DateTime.fromJSDate(deadline).toFormat('dd. MMMM yyyy')
    : undefined;

  // in some cases an email address is stored in the field for the phone number 🤦🏻
  const phoneNumberLink = phoneNumber?.includes('@')
    ? `mailto:${phoneNumber}`
    : `tel:${phoneNumber}`;

  // TODO: error handling (should never be undefined though...)
  const message = getDeadlineMessage(type, deadlineString ?? '?');
  const statusText =
    status === StepStatus.Timeout ? (
      <div>
        <Red>Diese Frist ist abgelaufen.</Red>
        {phoneNumber ? (
          <p>
            Bei Rückfragen melde Dich beim Lieferanten:{' '}
            <StyledLink as="a" href={phoneNumberLink}>
              {phoneNumber}
            </StyledLink>
          </p>
        ) : null}
      </div>
    ) : (
      <>Wir erinnern Dich wenn die Frist abgelaufen ist.</>
    );

  return (
    <>
      {message}
      {` `}
      {status !== StepStatus.Succeeded ? statusText : null}
    </>
  );
}
