import React from 'react';
import styled from 'styled-components';
import { Button } from '@ampeersenergy/ampeers-ui-components';
import { FetchResult } from '@apollo/client';

import {
  CreateMeterReadingMutation,
  Metering,
  ReadMeterReadingsDocument,
  useCreateMeterReadingMutation,
} from '../../../../../../graphql-types';
import { Bold, ErrorMsg, FlexRow, Icons } from '../../../../../../components';

const Headline = styled(Bold)`
  //   margin-top: 10px;
  padding-top: 20px;
  font-size: 15px;
`;
const Wrap = styled.div`
  width: 400px;
  font-size: 14px;
  p {
    padding-left: 12px;
    margin-top: 0px;
  }
`;

const Spacer = styled.div`
  width: 5px;
`;
const ActionBtns = styled.div`
  margin-top: 25px;
  min-width: 200px;
  display: inline-block;
`;

export interface ConfirmationDialogProps {
  variables: {
    meterId: string;
    startDate: string;
    endDate: string;
    metering: Metering;
    selectedObis: string;
  };
  result?: CreateMeterReadingMutation;
  onDone: () => void;
  onSuccess?: (
    result: FetchResult<
      CreateMeterReadingMutation,
      Record<string, any>,
      Record<string, any>
    >,
    add: { values: {} },
  ) => void;
}

function ConfirmationDialog({
  variables,
  result,
  onDone,
  onSuccess,
}: ConfirmationDialogProps) {
  const [createMeterReading, { error: mutationError }] =
    useCreateMeterReadingMutation();

  return (
    <Wrap>
      <FlexRow>{mutationError && <ErrorMsg error={mutationError} />}</FlexRow>
      <FlexRow>
        <Icons.Warning size={60} color="#A8E015" />
        <Headline>
          Warnung: Der Wert wird in einen Zeitraum geschrieben, für den bereits
          ein Abrechnungslauf existiert. Möchten Sie trotzdem weitermachen?
        </Headline>
      </FlexRow>
      <ActionBtns>
        <FlexRow>
          <Button
            onClick={async () => {
              if (!result?.createMeterReading) return;

              const { obis, date, reason, value, valueStatus } =
                result?.createMeterReading ?? {};

              const response = await createMeterReading({
                variables: {
                  meterId: variables.meterId,
                  meterReading: {
                    obis,
                    date,
                    reason: reason!,
                    value,
                    valueStatus: valueStatus!,
                    ignoreWarning: true,
                  },
                },
                refetchQueries: [
                  {
                    query: ReadMeterReadingsDocument,
                    variables: {
                      meterId: String(variables.meterId),
                      startDate: variables.startDate,
                      endDate: variables.endDate,
                      metering:
                        variables.metering === Metering.Slp
                          ? Metering.Slp
                          : Metering.Rlm,
                      obisChannel: variables.selectedObis,
                    },
                  },
                ],
              });

              if (onSuccess && response && !mutationError) {
                onSuccess(response, { values: {} });
              }
            }}
          >
            Ja
          </Button>
          <Spacer />
          <Button onClick={() => onDone()}>Nein</Button>
        </FlexRow>
      </ActionBtns>
    </Wrap>
  );
}

export default ConfirmationDialog;
