/* eslint-disable react/destructuring-assignment */
import React, { useCallback, useEffect, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import {
  ColumnDefinition,
  Table,
  WorkflowStep,
} from '@ampeersenergy/ampeers-ui-components';
import styled from 'styled-components';

import { formatDate, formatSettlementDay } from '../../helpers/formatStrings';
import {
  useReadWorkflowsQuery,
  FullWorkflowFragment,
} from '../../graphql-types';
import { ErrorMsg } from '../../components';
import { useHasRole } from '../../components/useHasRole';
/**
 * adjustments for status column
 * */
const StyledTable = styled(Table)`
  tr td:last-child {
    padding: 0;

    > div {
      justify-content: center;
    }
  }
`;

type BaseColumns = Pick<
  FullWorkflowFragment,
  'alias' | 'createdAt' | 'doneAt'
> & {
  paymentPeriod: {
    startAt: string;
    endAt: string;
  };
  settlementDate: {
    day: string;
    month: string;
  };
  downPaymentStart: string;
  step: any;
  status: any;
  uuid: any;
  opc: any;
};

const baseColumns: ColumnDefinition<BaseColumns>[] = [
  {
    Header: 'Name',
    accessor: 'alias',
    type: 'string',
  },
  {
    Header: 'Start',
    accessor: 'createdAt',
    type: 'date',
  },
  {
    Header: 'Abschluss',
    accessor: 'doneAt',
    type: 'date',
  },
  {
    Header: 'Rechnungsperiode',
    accessor: 'paymentPeriod',
    Cell: (props) =>
      `${formatDate(props.value?.startAt)} - ${formatDate(props.value?.endAt)}`,
    sortType: (rowA, rowB, columnId, desc) => {
      if (desc) {
        return (
          Date.parse(rowA.values[columnId].endAt) -
          Date.parse(rowB.values[columnId].endAt)
        );
      }
      return (
        Date.parse(rowA.values[columnId].startAt) -
        Date.parse(rowB.values[columnId].startAt)
      );
    },
  },
  {
    Header: 'Abschlagsbeginn',
    accessor: 'downPaymentStart',
    type: 'date',
  },
  {
    Header: 'Stichtag',
    accessor: 'settlementDate',
    Cell: (props) => {
      return formatSettlementDay(props.value?.day, props.value?.month).replace(
        '/',
        '.',
      );
    },
    sortType: (rowA, rowB, columnId) =>
      Date.parse(
        `${rowA.values[columnId].month}/${
          rowA.values[columnId].day
        }/${new Date().getFullYear()}`,
      ) -
      Date.parse(
        `${rowB.values[columnId].month}/${
          rowB.values[columnId].day
        }/${new Date().getFullYear()}`,
      ),
  },
  {
    Header: 'Schritt',
    accessor: 'step',
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: (props) => (
      <WorkflowStep
        label=""
        state={props.cell.value}
        onClick={() => null}
        isSelected={false}
        key={1}
      />
    ),
  },
];

type OpsColumns = {
  uuid: string;
  opc: string;
};

const opsColumns: ColumnDefinition<OpsColumns>[] = [
  {
    Header: 'UUID',
    accessor: 'uuid',
    type: 'string',
  },
  {
    Header: 'OPC',
    accessor: 'opc',
    type: 'string',
  },
];

const POLL_INTERVAL = 15 * 1000;

const WorkflowTable: React.FC<{}> = () => {
  const match = useRouteMatch();

  const rowLink = useCallback(
    ({ row }: { row: any }) => {
      const entry = row.original as any;
      return `${match.url}/${entry.id}-${entry.alias}`;
    },
    [match.url],
  );

  const { data, loading, error, startPolling, stopPolling, refetch } =
    useReadWorkflowsQuery();

  useEffect(() => {
    refetch();
    startPolling(POLL_INTERVAL);

    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling, refetch]);

  const workflows = useMemo(() => {
    if (data?.readWorkflows) {
      return data.readWorkflows
        .slice(0)
        .sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
        )
        .map((w) => {
          const step = w.currentState.parentName || w.currentState.name;

          return {
            id: w.id,
            alias: w.alias,
            createdAt: w.createdAt,
            doneAt: w.doneAt,
            paymentPeriod: {
              startAt: w.currentState.context.payload?.paymentPeriodStartAt,
              endAt: w.currentState.context.payload?.paymentPeriodEndAt,
            },
            settlementDate: {
              day: w.currentState.context.payload?.settlementDay,
              month: w.currentState.context.payload?.settlementMonth,
            },
            downPaymentStart:
              w.currentState.context.downpaymentStart?.startDate ??
              w.currentState.context.payload?.downPaymentStartAt,
            step: w.meta[step]?.name ?? step,
            status: w.currentState.context[step].uiState,
            uuid: w.currentState.context.executionContext?.session,
            opc: w.currentState.context.createAccounting?.name,
          };
        });
    }

    return [];
  }, [data]);

  const { hasRole: hasOperationsRole } = useHasRole('ae-operations');

  const columns: any = useMemo(() => {
    if (hasOperationsRole) {
      // status column should always be the last
      return [
        ...baseColumns.slice(0, -1),
        ...opsColumns,
        ...baseColumns.slice(-1),
      ];
    }

    return baseColumns;
  }, [hasOperationsRole]);

  return (
    <>
      {error && <ErrorMsg error={error} />}
      <StyledTable
        columns={columns}
        data={workflows}
        filterKind="Abrechnungsläufe"
        isLoading={loading}
        rowLink={rowLink}
        compact
        withAlternatingRows
        withPagination
        withBoxShadow
        withFilter
      />
    </>
  );
};

export default WorkflowTable;
