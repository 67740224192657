/* eslint-disable @typescript-eslint/default-param-last */
import { Company } from '../graphql-types';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

export interface SessionState {
  appKey?: string;
  token?: string;
  clientId?: string;
  userId?: string;
  company?: Company;
}

// eslint-disable-next-line import/no-mutable-exports
export let sessionState: SessionState = {
  appKey: 'ap_local_supplier',
  token: undefined,
  clientId: undefined,
  userId: undefined,
  company: undefined,
};

const LOCAL_STORAGE_IDENTIFIER = 'ae.mieterstrom.auth';

if (localStorage) {
  const savedSessionString = localStorage.getItem(LOCAL_STORAGE_IDENTIFIER);

  if (savedSessionString) {
    const saved = JSON.parse(savedSessionString);
    sessionState = {
      ...sessionState,
      ...saved,
    };
  }
}

export const sessionReducer = (
  state = sessionState,
  { type, nextState }: { type: string; nextState?: SessionState },
): SessionState => {
  switch (type) {
    case LOGIN: {
      const merged = { ...state, ...nextState };
      if (localStorage) {
        localStorage.setItem(LOCAL_STORAGE_IDENTIFIER, JSON.stringify(merged));
      }
      return merged;
    }
    case LOGOUT: {
      if (localStorage) {
        localStorage.removeItem(LOCAL_STORAGE_IDENTIFIER);
      }
      return {
        ...state,
        ...{
          token: undefined,
          clientId: undefined,
          userId: undefined,
        },
      };
    }
    default:
      return state;
  }
};
