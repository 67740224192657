export interface KeyMap {
  [key: string]: string;
}

export interface ValueMap {
  [key: string]: (value: any) => any;
}

/**
 * maps the keys of an object given a keyMap
 * @param object
 * @param keyMap
 * @param filterOutKeys
 */
export const transformObject = (
  object: { [key: string]: string },
  keyMap: { [key: string]: string },
  valueMap: { [key: string]: (value: any) => any } = {},
  filterOutKeys = false,
) =>
  Object.keys(filterOutKeys ? keyMap : object).reduce(
    (newObject, key) => ({
      ...newObject,
      [keyMap[key]]: valueMap[key] ? valueMap[key](object[key]) : object[key],
    }),
    {},
  );
