import { Button } from '@ampeersenergy/ampeers-ui-components';
import { Form, Formik, useFormikContext } from 'formik';
import * as React from 'react';
import styled from 'styled-components';
import { DeepExtractType } from 'ts-deep-extract-types';

import { ErrorMsg } from '../../../components';
import RelationSelect from '../../../components/relationSelect';
import {
  CancellationLetterPayloadInput,
  GetSupplierChangeWorkflowStateDocument,
  ReadContractQuery,
  StepStatus,
  SupplierChangeWorkflowType,
  useGetSumMeterConsumptionQuery,
  usePlantDetailQuery,
  useReadContractQuery,
  useReadPowerSuppliersQuery,
  useSetCancellationSupplierMutation,
  useSetContractSupplierChangeOperatorMutation,
} from '../../../graphql-types';

const NetworkOperatorResultHeading = styled.div`
  margin-top: 15px;
  margin-bottom: 2px;
`;
const NetworkOperatorResultContainer = styled.div`
  font-weight: bold;
  color: ${(props) => props.theme.primaryColor};
`;

const ButtonStyled = styled(Button)`
  margin-top: 10px;
`;

type ContractDetails = DeepExtractType<ReadContractQuery, ['readContract']>;

const MeteringPointOperatorType = 'Messstellenbetreiber';
const GridOperatorType = 'Netzbetreiber';
const SupplierType = 'Lieferant';

interface FormikValues {
  gridOperatorId?: string;
  meteringPointOperatorId?: string;
  supplierId?: string;
}

export function SelectNetworkOperator({
  contractId,
  workflowId,
  state,
  meteringPointOperatorName,
  gridOperatorName,
  oldSupplierName,
  type,
}: {
  contractId: string;
  workflowId: string;
  state: StepStatus;
  meteringPointOperatorName?: string;
  gridOperatorName?: string;
  oldSupplierName?: string;
  type?: SupplierChangeWorkflowType;
}) {
  if (state === StepStatus.Succeeded) {
    if (type === SupplierChangeWorkflowType.Cancellation && oldSupplierName) {
      return (
        <>
          <NetworkOperatorResultHeading>
            {SupplierType}
          </NetworkOperatorResultHeading>
          <NetworkOperatorResultContainer>
            {oldSupplierName}
          </NetworkOperatorResultContainer>
        </>
      );
    }

    if (type === SupplierChangeWorkflowType.SupplierChangeThirdParty) {
      return (
        <>
          <NetworkOperatorResultHeading>
            {GridOperatorType}
          </NetworkOperatorResultHeading>
          <NetworkOperatorResultContainer>
            {gridOperatorName}
          </NetworkOperatorResultContainer>
        </>
      );
    }

    return (
      <>
        <NetworkOperatorResultHeading>
          {MeteringPointOperatorType}
        </NetworkOperatorResultHeading>
        <NetworkOperatorResultContainer>
          {meteringPointOperatorName}
        </NetworkOperatorResultContainer>
        <NetworkOperatorResultHeading>
          {GridOperatorType}
        </NetworkOperatorResultHeading>
        <NetworkOperatorResultContainer>
          {gridOperatorName}
        </NetworkOperatorResultContainer>
      </>
    );
  }

  if (type === SupplierChangeWorkflowType.Cancellation) {
    return (
      <SelectNetworkOperatorCancellationForm
        contractId={contractId}
        workflowId={workflowId}
        state={state}
      />
    );
  }

  if (type === SupplierChangeWorkflowType.SupplierChangeThirdParty) {
    return (
      <SelectNetworkOperatorThirdPartyForm
        contractId={contractId}
        workflowId={workflowId}
        state={state}
      />
    );
  }

  return (
    <SelectNetworkOperatorLocalSupplyForm
      contractId={contractId}
      workflowId={workflowId}
      state={state}
    />
  );
}

function useGetPlantDataByContractId(contractId: string) {
  const { data: contractData } = useReadContractQuery({
    variables: { contractId },
  });
  const { data: plantData } = usePlantDetailQuery({
    variables: { plantId: String(contractData?.readContract?.plantId) },
    skip: contractData?.readContract?.plantId === undefined,
  });

  return { contractData, plantData };
}

function SelectNetworkOperatorLocalSupplyForm({
  workflowId,
  contractId,
  state,
}: {
  workflowId: string;
  contractId: string;
  state: StepStatus;
}) {
  const [error, setError] = React.useState<string | null>(null);

  const { data: operatorsData } = useReadPowerSuppliersQuery({
    variables: { type: MeteringPointOperatorType },
  });
  const { data: suppliersData } = useReadPowerSuppliersQuery({
    variables: { type: GridOperatorType },
    // The BDEW Api sometimes can't deal with multiple requests in
    // quick succession. To avoid this, we wait until the first
    // request is done before sending the second one.
    skip: operatorsData === undefined,
  });

  // We need to get the malo of the "Summenzähler Bezug" of the building
  const { data: plantMalosResponse } = useGetSumMeterConsumptionQuery({
    variables: {
      contractId,
    },
  });

  const { plantData } = useGetPlantDataByContractId(contractId);
  const supplierName = plantData?.readPlant?.balancingAreaAccount.name;

  const [setContractSupplierChangeOperator] =
    useSetContractSupplierChangeOperatorMutation();

  const preSelectedSupplier = suppliersData?.readPowerSuppliers.find(
    (supplier) => supplier.CompanyName === supplierName,
  );

  const hasSucceeded = state === StepStatus.Succeeded;

  const relations = React.useMemo(
    () => [
      {
        label: MeteringPointOperatorType,
        name: 'meteringPointOperatorId',
      },
      {
        label: GridOperatorType,
        name: 'gridOperatorId',
        defaultValue: preSelectedSupplier?.id,
      },
    ],
    [preSelectedSupplier?.id],
  );

  if (
    plantMalosResponse &&
    plantMalosResponse.getSumMeterConsumption.__typename ===
      'SumMeterConsumptionError'
  ) {
    return (
      <ErrorMsg
        retryable={false}
        error={plantMalosResponse.getSumMeterConsumption.message}
      />
    );
  }

  return (
    <Formik<FormikValues>
      initialValues={{
        gridOperatorId: undefined,
        meteringPointOperatorId: undefined,
      }}
      onSubmit={async (values) => {
        setError(null);
        const operator = operatorsData?.readPowerSuppliers.find(
          (p) => p.BdewCode === values.meteringPointOperatorId,
        );
        const supplier = suppliersData?.readPowerSuppliers.find(
          (p) => p.BdewCode === values.gridOperatorId,
        );
        const sumMeterConsumptionMalo =
          plantMalosResponse?.getSumMeterConsumption.__typename ===
          'SumMeterConsumptionSuccess'
            ? plantMalosResponse.getSumMeterConsumption.malo
            : undefined;

        if (operator && supplier && sumMeterConsumptionMalo) {
          const maloSummenzaehlerBezug = sumMeterConsumptionMalo;

          try {
            await setContractSupplierChangeOperator({
              variables: {
                workflowId,
                meteringPointOperator: {
                  bdewCode: operator.BdewCode,
                  companyName: operator.CompanyName,
                  postCode: operator.PostCode,
                  city: operator.City,
                  street: operator.Street,
                  country: operator.Country,
                  email: operator.CodeContactEmail,
                  phone: operator.CodeContactEmail,
                },
                gridOperator: {
                  bdewCode: supplier.BdewCode,
                  companyName: supplier.CompanyName,
                  postCode: supplier.PostCode,
                  city: supplier.City,
                  street: supplier.Street,
                  country: supplier.Country,
                  email: supplier.CodeContactEmail,
                  phone: supplier.CodeContactEmail,
                },
                contractId,
                malo: maloSummenzaehlerBezug,
              },
              refetchQueries: [
                {
                  query: GetSupplierChangeWorkflowStateDocument,
                  variables: { workflowId },
                },
              ],
            });
          } catch (e) {
            if (e instanceof Error) {
              setError(e.message);
            } else {
              setError(String(e));
            }
          }
        } else {
          setError('Missing data needed to create document');
        }
      }}
    >
      {({ isSubmitting }) => (
        <RelationSelectForm
          error={error}
          hasSucceeded={hasSucceeded}
          isSubmitting={isSubmitting}
          powerSupplierRelations={relations}
        />
      )}
    </Formik>
  );
}

function SelectNetworkOperatorThirdPartyForm({
  workflowId,
  contractId,
  state,
}: {
  workflowId: string;
  contractId: string;
  state: StepStatus;
}) {
  const [error, setError] = React.useState<string | null>(null);

  const { data: suppliersData } = useReadPowerSuppliersQuery({
    variables: { type: GridOperatorType },
  });

  const { plantData } = useGetPlantDataByContractId(contractId);

  // We need to get the malo of the "Summenzähler Bezug" of the building
  const { data: plantMalosResponse } = useGetSumMeterConsumptionQuery({
    variables: {
      contractId,
    },
  });

  const [setContractSupplierChangeOperator] =
    useSetContractSupplierChangeOperatorMutation();

  const hasSucceeded = state === StepStatus.Succeeded;

  const relations = React.useMemo(
    () => [
      {
        label: GridOperatorType,
        name: 'gridOperatorId',
      },
    ],
    [],
  );

  if (
    plantMalosResponse &&
    plantMalosResponse.getSumMeterConsumption.__typename ===
      'SumMeterConsumptionError'
  ) {
    return (
      <ErrorMsg
        retryable={false}
        error={plantMalosResponse.getSumMeterConsumption.message}
      />
    );
  }

  return (
    <Formik<FormikValues>
      initialValues={{
        gridOperatorId: undefined,
      }}
      onSubmit={async (values) => {
        setError(null);
        const supplier = suppliersData?.readPowerSuppliers.find(
          (p) => p.BdewCode === values.gridOperatorId,
        );
        const meteringOperator = plantData?.readPlant?.meteringPointOperator;
        const sumMeterConsumptionMalo =
          plantMalosResponse?.getSumMeterConsumption.__typename ===
          'SumMeterConsumptionSuccess'
            ? plantMalosResponse.getSumMeterConsumption.malo
            : undefined;

        if (supplier && meteringOperator && sumMeterConsumptionMalo) {
          const maloSummenzaehlerBezug = sumMeterConsumptionMalo;
          try {
            await setContractSupplierChangeOperator({
              variables: {
                workflowId,
                meteringPointOperator: {
                  bdewCode: meteringOperator.code,
                  companyName: meteringOperator.name,
                },
                gridOperator: {
                  bdewCode: supplier.BdewCode,
                  companyName: supplier.CompanyName,
                  postCode: supplier.PostCode,
                  city: supplier.City,
                  street: supplier.Street,
                  country: supplier.Country,
                  email: supplier.CodeContactEmail,
                  phone: supplier.CodeContactEmail,
                },
                contractId,
                malo: maloSummenzaehlerBezug,
              },
              refetchQueries: [
                {
                  query: GetSupplierChangeWorkflowStateDocument,
                  variables: { workflowId },
                },
              ],
            });
          } catch (e) {
            if (e instanceof Error) {
              setError(e.message);
            } else {
              setError(String(e));
            }
          }
        } else {
          setError('Missing data needed to create document');
        }
      }}
    >
      {({ isSubmitting }) => (
        <RelationSelectForm
          error={error}
          hasSucceeded={hasSucceeded}
          isSubmitting={isSubmitting}
          powerSupplierRelations={relations}
        />
      )}
    </Formik>
  );
}

function SelectNetworkOperatorCancellationForm({
  workflowId,
  contractId,
  state,
}: {
  workflowId: string;
  contractId: string;
  state: StepStatus;
}) {
  const [error, setError] = React.useState<string | null>(null);

  const { data: suppliersData } = useReadPowerSuppliersQuery({
    variables: { type: SupplierType },
  });

  const { data: contractData } = useReadContractQuery({
    variables: { contractId },
  });

  const hasSucceeded = state === StepStatus.Succeeded;
  const [setCancellationSupplierMutation] =
    useSetCancellationSupplierMutation();

  const relations = React.useMemo(
    () => [
      {
        label: SupplierType,
        name: 'supplierId',
      },
    ],
    [],
  );

  return (
    <Formik<FormikValues>
      initialValues={{
        supplierId: undefined,
      }}
      onSubmit={async (values) => {
        setError(null);
        const supplier = suppliersData?.readPowerSuppliers.find(
          (p) => p.BdewCode === values.supplierId,
        );
        if (supplier && contractData?.readContract) {
          try {
            await setCancellationSupplierMutation({
              variables: {
                workflowId,
                supplier: {
                  bdewCode: supplier.BdewCode,
                  companyName: supplier.CompanyName,
                  postCode: supplier.PostCode,
                  city: supplier.City,
                  street: supplier.Street,
                  country: supplier.Country,
                  email: supplier.CodeContactEmail,
                  phone: supplier.CodeContactPhone,
                },
                letterPayload: createCancellationLetterPayload(
                  contractData.readContract,
                ),
              },
              refetchQueries: [
                {
                  query: GetSupplierChangeWorkflowStateDocument,
                  variables: { workflowId },
                },
              ],
            });
          } catch (e) {
            if (e instanceof Error) {
              setError(e.message);
            } else {
              setError(String(e));
            }
          }
        } else {
          setError('Missing data needed to create document');
        }
      }}
    >
      {({ isSubmitting }) => (
        <RelationSelectForm
          error={error}
          hasSucceeded={hasSucceeded}
          isSubmitting={isSubmitting}
          powerSupplierRelations={relations}
        />
      )}
    </Formik>
  );
}

type PowerSupplierRelations = {
  name: string;
  label: string;
  defaultValue?: string;
};

function RelationSelectForm({
  error,
  hasSucceeded,
  isSubmitting,
  powerSupplierRelations,
}: {
  error: string | null;
  hasSucceeded: boolean;
  isSubmitting: boolean;
  powerSupplierRelations: PowerSupplierRelations[];
}) {
  const { setFieldValue, values } = useFormikContext<FormikValues>();

  const emptyValues = powerSupplierRelations.every((relation) => {
    const key = relation.name as keyof typeof values;
    return values[key] === undefined;
  });

  const buttonDisabled = isSubmitting || emptyValues;

  React.useEffect(() => {
    powerSupplierRelations.forEach((relation) => {
      if (relation.defaultValue) {
        setFieldValue(relation.name, relation.defaultValue);
      }
    });
  }, [powerSupplierRelations, setFieldValue]);

  return (
    <Form>
      {error && <ErrorMsg retryable={false} error={error} />}
      {powerSupplierRelations.map((relation) => (
        <RelationSelect
          type="PowerSuppliers"
          key={relation.name}
          name={relation.name}
          id={relation.name}
          data-testid={relation.name}
          label={relation.label}
          placeholder={`${relation.label} auswählen`}
          formVariables={{ type: relation.label }}
        />
      ))}
      {!hasSucceeded && (
        <div>
          <ButtonStyled
            isLoading={isSubmitting}
            type="submit"
            disabled={buttonDisabled}
          >
            Auswählen
          </ButtonStyled>
        </div>
      )}
    </Form>
  );
}

function createCancellationLetterPayload(
  contract?: ContractDetails,
): CancellationLetterPayloadInput {
  if (!contract || !contract?.contractMeter) {
    throw new Error('Missing contract');
  }

  const {
    contractMeter: { meters },
    customer: { addressShipping, addressBilling, person },
  } = contract;

  if (!addressShipping || !meters[0]?.meterNumber) {
    throw new Error(
      'Missing information in contract meter that is needed to create document',
    );
  }

  if (!addressBilling) {
    throw new Error(
      'Missing customer address info that is needed to create document',
    );
  }

  return {
    billingAddress: {
      city: addressBilling.city!,
      streetName: addressBilling.streetName!,
      streetNumber: addressBilling.streetNumber!,
      zipCode: addressBilling.zipCode!,
    },
    shippingAddress: {
      city: addressShipping.city!,
      streetName: addressShipping.streetName!,
      streetNumber: addressShipping.streetNumber!,
      zipCode: addressShipping.zipCode!,
    },

    name: person?.name,
    meterNumber: meters[0].meterNumber,
  };
}
