export const handleHelpWidget = () => {
  if (
    document.getElementById('freshworks-frame-wrapper')?.style.visibility ===
    'visible'
  ) {
    (window as any).FreshworksWidget('hide');
  } else {
    (window as any).FreshworksWidget('open');
  }
};
