import * as React from 'react';
import styled from 'styled-components/macro';
import { Icons } from '@ampeersenergy/ampeers-ui-components';

const FilePreviewWrapper = styled.div<{
  $hover?: boolean;
  $disabled?: boolean;
}>`
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 40px auto;
  padding: 1rem 2rem;

  border: 1px solid ${(props) => (props.$hover ? '#979797' : '#C9C9C9')};
  padding: 10px;
  border-radius: 4px;
  position: relative;
  opacity: ${(props) => (props.$disabled ? 0.8 : 1)};
`;

const RemoveFileButton = styled.button<{ disabled?: boolean }>`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: -8px;
  right: -8px;
  border-radius: 9999px;
  color: white;
  background-color: #323331;
  padding: 0;
  margin: 0;
  border: 0;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

const FileDescription = styled.strong`
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const FileImagePreview = styled.img`
  width: 100%;
  height: auto;
  display: block;
`;

const FileType = styled.small`
  font-size: 14px;
  color: #7b7b7b;
  text-transform: uppercase;
`;

const mapFileTypes = {
  'application/pdf': 'PDF',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    'DOCX',
  'application/vnd.ms-powerpoint': 'PPT',
  'application/msword': 'DOC',
  'application/vnd.ms-excel': 'XLS',
  'text/csv': 'CSV',
  'text/plain': 'TXT',
  'image/jpeg': 'JPG',
  'image/png': 'PNG',
  'image/gif': 'GIF',
};

type FilePreviewProps = {
  file: File;
  disabled?: boolean;
  onRemove: () => void;
};

export default function FilePreview({
  file,
  disabled,
  onRemove,
}: FilePreviewProps) {
  let icon = <Icons.File size="30" color="primary" />;
  if (file.type.includes('image')) {
    const image = URL.createObjectURL(file);
    icon = <FileImagePreview src={image} />;
  }

  const { name } = file;
  const fileType =
    mapFileTypes[file.type as keyof typeof mapFileTypes] ?? 'unknown'; // file.type;

  return (
    <FilePreviewWrapper $disabled={disabled}>
      <RemoveFileButton
        onClick={onRemove}
        aria-label="Datei entfernen"
        disabled={disabled}
      >
        <Icons.Close size="20" color="#fff" />
      </RemoveFileButton>
      <div
        css={`
          display: flex;
          justify-content: center;
          align-items: center;
        `}
      >
        {icon}
      </div>
      <div
        css={`
          display: flex;
          flex-direction: column;
          max-width: 360px;
        `}
      >
        <FileDescription>{name}</FileDescription>
        <FileType>{fileType}</FileType>
      </div>
    </FilePreviewWrapper>
  );
}
