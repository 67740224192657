import gql from 'graphql-tag';

export default gql`
  query readClientDetails {
    readClientDetails {
      companyName
      address {
        streetName
        streetNumber
        zipCode
        city
      }
      email
      contact {
        serviceHotline
        serviceEmail
        serviceHours
        footer1
        footer2
        footer3
        footer4
      }
      workspaces {
        name
        companyName
        address {
          streetName
          streetNumber
          zipCode
          city
        }
        email
        contact {
          serviceHotline
          serviceEmail
          serviceHours
          footer1
          footer2
          footer3
          footer4
          headerAddress
        }
        banking {
          iban
          bic
        }
        numberOfFooters
      }
      banking {
        iban
        bic
      }
      contractDraftTemplates {
        pricesheet_letter
        withdrawal_letter
        poa_cancellation_letter
        covering_letter
      }
      numberOfFooters
    }
  }
`;
