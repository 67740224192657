import { useFormikContext } from 'formik';
import React, { useMemo } from 'react';
import get from 'lodash.get';

import Stepper from './stepper';

function WithErrorsStepper({
  fieldsStepMap,
  ...rest
}: {
  fieldsStepMap: { [key: string]: number };
} & Omit<React.ComponentProps<typeof Stepper>, 'stepsWithErrors'>) {
  const { status, touched, isSubmitting } = useFormikContext();
  const errorsInSteps = useMemo(() => {
    if (status && fieldsStepMap && !isSubmitting) {
      const stepsWithErrors = Object.entries(status)
        .filter((s) => !get(touched, s[0], false))
        .map((s) => fieldsStepMap[s[0]]);
      return stepsWithErrors;
    }
    return [];
  }, [status, touched, fieldsStepMap, isSubmitting]);
  return <Stepper {...rest} stepsWithErrors={errorsInSteps} />;
}
export default WithErrorsStepper;
