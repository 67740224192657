import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={300}
      viewBox="0 0 174 95"
    >
      <defs>
        <rect id="path-1" width="1236" height="711" x="0" y="0" rx="4" />
        <filter
          id="filter-2"
          width="101.1%"
          height="102%"
          x="-.6%"
          y="-.7%"
          filterUnits="objectBoundingBox"
        >
          <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="2"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0753660402 0"
          />
        </filter>
        <path id="path-3" d="M289 0H1440V1609H289z" />
        <filter
          id="filter-4"
          width="101.9%"
          height="101.2%"
          x="-1.4%"
          y="-.3%"
          filterUnits="objectBoundingBox"
        >
          <feOffset
            dx="-7"
            dy="5"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="2"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0753660402 0"
          />
        </filter>
        <linearGradient
          id="linearGradient-5"
          x1="50%"
          x2="50%"
          y1="0%"
          y2="100%"
        >
          <stop offset="0%" stopColor="#7DD77B" />
          <stop offset="44.236%" stopColor="#64C685" />
          <stop offset="100%" stopColor="#45AF93" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-348 -548)">
          <g fill="#F8F8FB" stroke="#979797">
            <path d="M0.5 0.5H1462.5V1608.5H0.5z" />
          </g>
          <g transform="translate(105 53)">
            <g transform="translate(0 244)">
              <g>
                <use fill="#000" filter="url(#filter-2)" xlinkHref="#path-1" />
                <use fill="#FFF" xlinkHref="#path-1" />
              </g>
            </g>
            <g transform="translate(186 244)">
              <path
                fill="#B5B5B5"
                fillRule="evenodd"
                d="M198 23l-3.5 4-3.5-4h7zm-3.5-7l3.5 4h-7l3.5-4z"
              />
              <path
                stroke="#E8E8E8"
                strokeLinecap="square"
                strokeWidth="1"
                d="M210.5 11L210.5 710.459"
              />
              <text
                fill="#090909"
                fontFamily="ProximaNova-Semibold, Proxima Nova"
                fontSize="12"
                fontWeight="500"
                letterSpacing="1.29"
              >
                <tspan x="15" y="26">
                  ADRESSE
                </tspan>
              </text>
            </g>
            <g transform="translate(397 244)">
              <path
                fill="#B5B5B5"
                fillRule="evenodd"
                d="M222 23l-3.5 4-3.5-4h7zm-3.5-7l3.5 4h-7l3.5-4z"
              />
              <path
                stroke="#E8E8E8"
                strokeLinecap="square"
                strokeWidth="1"
                d="M234.5 11L234.5 710.459"
              />
              <text
                fill="#090909"
                fontFamily="ProximaNova-Semibold, Proxima Nova"
                fontSize="12"
                fontWeight="500"
                letterSpacing="1.29"
              >
                <tspan x="15" y="26">
                  TARIFE
                </tspan>
              </text>
            </g>
            <g opacity="0.422" transform="translate(0 306)">
              <g fill="#EEE" transform="translate(0 140)">
                <path d="M0 0H1236V78H0z" />
              </g>
              <g fill="#DBFFD4" transform="translate(0 279)">
                <g>
                  <path d="M0 0H1236V81H0z" />
                </g>
              </g>
            </g>
            <g
              fill="#2A2A2A"
              fontFamily="Roboto-Regular, Roboto"
              fontSize="15"
              fontWeight="normal"
              letterSpacing="0.383"
              transform="translate(202 452)"
            >
              <text>
                <tspan x="0" y="14">
                  73728 Esslingen
                </tspan>
                <tspan x="0" y="40" fontSize="14" letterSpacing="0.357">
                  Stuttgarter Str. 1
                </tspan>
                <tspan x="0" y="64" fontSize="14" letterSpacing="0.357">
                  Stuttgarter Str. 2
                </tspan>
              </text>
            </g>
            <text
              fill="#2A2A2A"
              fontFamily="Roboto-Regular, Roboto"
              fontSize="15"
              fontWeight="normal"
              letterSpacing="0.383"
            >
              <tspan x="202" y="548">
                38106 Braunschweig
              </tspan>
              <tspan x="202" y="574" fontSize="14" letterSpacing="0.357">
                Methfessel Str. 3
              </tspan>
            </text>
            <text
              fill="#2A2A2A"
              fontFamily="Roboto-Regular, Roboto"
              fontSize="15"
              fontWeight="normal"
              letterSpacing="0.383"
            >
              <tspan x="412" y="466">
                T 2738
              </tspan>
              <tspan x="412" y="492">
                T 12
              </tspan>
            </text>
            <text
              fill="#2A2A2A"
              fontFamily="Roboto-Regular, Roboto"
              fontSize="15"
              fontWeight="normal"
              letterSpacing="0.383"
            >
              <tspan x="412" y="548">
                FL.12.9990
              </tspan>
            </text>
          </g>
          <path fill="#000" fillOpacity="0.087" d="M63 0H742V1634H63z" />
          <g>
            <use fill="#000" filter="url(#filter-4)" xlinkHref="#path-3" />
            <use fill="#FFF" xlinkHref="#path-3" />
          </g>
          <g transform="translate(348 548)">
            <g>
              <text
                fill="#7B89D6"
                fontFamily="ProximaNova-Semibold, Proxima Nova"
                fontSize="10.5"
                fontWeight="500"
                letterSpacing="0.159"
              >
                <tspan x="105.207" y="38">
                  Z
                </tspan>
                <tspan x="111.55" y="38" fontSize="7.7" letterSpacing="0.117">
                  1
                </tspan>
              </text>
              <g transform="translate(0 51.1)">
                <text
                  fill="#7B89D6"
                  fontFamily="ProximaNova-Semibold, Proxima Nova"
                  fontSize="10.5"
                  fontWeight="500"
                  letterSpacing="0.159"
                >
                  <tspan x="4.979" y="12.1">
                    Z
                  </tspan>
                  <tspan
                    x="11.323"
                    y="12.1"
                    fontSize="7.7"
                    letterSpacing="0.117"
                  >
                    2
                  </tspan>
                </text>
                <circle
                  cx="10.15"
                  cy="9.45"
                  r="9.45"
                  stroke="#7B89D6"
                  strokeWidth="0.5"
                />
              </g>
              <g transform="translate(124.6 27.3)">
                <text
                  fill="#0270FF"
                  fontFamily="ProximaNova-Semibold, Proxima Nova"
                  fontSize="10.5"
                  fontWeight="500"
                  letterSpacing="0.159"
                >
                  <tspan x="5.308" y="10">
                    Z
                  </tspan>
                  <tspan x="11.652" y="10" fontSize="7.7" letterSpacing="0.117">
                    1B
                  </tspan>
                </text>
                <g transform="translate(0 1.4)">
                  <path
                    stroke="#0270FF"
                    strokeLinecap="square"
                    strokeWidth="0.7"
                    d="M2.009 0L2.009 10.886"
                  />
                  <path
                    fill="#0270FF"
                    d="M2.00869565 11.06875L4.0173913 13.08125 0 13.08125z"
                    transform="rotate(-180 2.009 12.075)"
                  />
                </g>
              </g>
              <g transform="translate(44.8 52.5)">
                <text
                  fill="#0270FF"
                  fontFamily="ProximaNova-Semibold, Proxima Nova"
                  fontSize="10.5"
                  fontWeight="500"
                  letterSpacing="0.159"
                >
                  <tspan x="4.48" y="10">
                    Z
                  </tspan>
                  <tspan x="10.824" y="10" fontSize="7.7" letterSpacing="0.117">
                    2B
                  </tspan>
                </text>
                <g transform="translate(0 1.4)">
                  <path
                    stroke="#0270FF"
                    strokeLinecap="square"
                    strokeWidth="0.7"
                    d="M2.009 0L2.009 10.886"
                  />
                  <path
                    fill="#0270FF"
                    d="M2.00869565 11.06875L4.0173913 13.08125 0 13.08125z"
                    transform="rotate(-180 2.009 12.075)"
                  />
                </g>
              </g>
              <circle
                cx="109.55"
                cy="35.35"
                r="9.45"
                stroke="#7B89D6"
                strokeWidth="0.5"
              />
              <circle
                cx="69.3"
                cy="79.1"
                r="15.4"
                fill="url(#linearGradient-5)"
              />
              <text
                fill="#FFF"
                fontFamily="ProximaNova-Semibold, Proxima Nova"
                fontSize="14.7"
                fontWeight="500"
                letterSpacing="0.223"
              >
                <tspan x="60.444" y="84.525">
                  EA
                </tspan>
              </text>
              <path
                stroke="#7BD67C"
                strokeLinecap="square"
                strokeWidth="0.7"
                d="M96.6 50.4L96.6 2.1"
              />
              <path
                stroke="#7BD67C"
                strokeLinecap="square"
                strokeWidth="0.7"
                d="M68.6 66.5L68.6 50.4"
              />
              <path
                stroke="#7BD67C"
                strokeLinecap="square"
                strokeWidth="0.7"
                d="M123.9 66.5L123.9 50.4"
              />
              <path
                stroke="#7BD67C"
                strokeLinecap="square"
                strokeWidth="0.7"
                d="M109.9 82.6L109.9 66.5"
              />
              <path
                stroke="#7BD67C"
                strokeLinecap="square"
                strokeWidth="0.7"
                d="M116.9 82.6L116.9 66.5"
              />
              <path
                stroke="#7BD67C"
                strokeLinecap="square"
                strokeWidth="0.7"
                d="M123.9 82.6L123.9 66.5"
              />
              <path
                stroke="#7BD67C"
                strokeLinecap="square"
                strokeWidth="0.7"
                d="M130.9 82.6L130.9 66.5"
              />
              <path
                stroke="#7BD67C"
                strokeLinecap="square"
                strokeWidth="0.7"
                d="M137.9 82.6L137.9 66.5"
              />
              <path
                stroke="#7BD67C"
                strokeLinecap="square"
                strokeWidth="0.7"
                d="M68.6 1.75L123.9 1.75"
              />
              <path
                stroke="#7BD67C"
                strokeLinecap="square"
                strokeWidth="0.7"
                d="M68.6 50.4L123.9 50.4"
              />
              <path
                stroke="#7BD67C"
                strokeLinecap="square"
                strokeWidth="0.7"
                d="M109.9 66.5L137.154 66.5"
              />
              <circle cx="96.6" cy="2.1" r="2.1" fill="#7BD67C" />
              <circle cx="96.6" cy="50.4" r="2.1" fill="#7BD67C" />
              <path
                stroke="#7B89D6"
                strokeDasharray="1.4,2.8"
                strokeLinecap="square"
                strokeWidth="0.7"
                d="M68.6 20.65L123.9 20.65"
              />
              <text
                fill="#12007F"
                fontFamily="ProximaNova-Semibold, Proxima Nova"
                fontSize="10.5"
                fontWeight="500"
                letterSpacing="0.159"
              >
                <tspan x="156.312" y="37.3">
                  Z
                </tspan>
                <tspan
                  x="162.656"
                  y="37.3"
                  fontSize="7.7"
                  letterSpacing="0.117"
                >
                  1L
                </tspan>
              </text>
              <g transform="translate(21.7 51.1)">
                <text
                  fill="#12007F"
                  fontFamily="ProximaNova-Semibold, Proxima Nova"
                  fontSize="10.5"
                  fontWeight="500"
                  letterSpacing="0.159"
                >
                  <tspan x="5.167" y="10">
                    Z
                  </tspan>
                  <tspan x="11.511" y="10" fontSize="7.7" letterSpacing="0.117">
                    2L
                  </tspan>
                </text>
                <g transform="rotate(-180 2.1 6.737)">
                  <path
                    stroke="#12007F"
                    strokeLinecap="square"
                    strokeWidth="0.7"
                    d="M2.009 0L2.009 9.896"
                  />
                  <path
                    fill="#12007F"
                    d="M2.00869565 10.0625L4.0173913 12.075 0 12.075z"
                    transform="rotate(-180 2.009 11.069)"
                  />
                </g>
              </g>
              <g transform="rotate(-180 77.259 20.387)">
                <path
                  stroke="#12007F"
                  strokeLinecap="square"
                  strokeWidth="0.7"
                  d="M2.009 0L2.009 9.896"
                />
                <path
                  fill="#12007F"
                  d="M2.00869565 10.0625L4.0173913 12.075 0 12.075z"
                  transform="rotate(-180 2.009 11.069)"
                />
              </g>
              <text
                fill="#7B89D6"
                fontFamily="ProximaNova-Semibold, Proxima Nova"
                fontSize="6.3"
                fontWeight="500"
                letterSpacing="0.096"
              >
                <tspan x="100.977" y="16.5">
                  EIGENTUMSGRENZE
                </tspan>
              </text>
            </g>
            <text
              fill="#7BD67C"
              fontFamily="ProximaNova-Semibold, Proxima Nova"
              fontSize="6.3"
              fontWeight="500"
              letterSpacing="0.096"
            >
              <tspan x="102.678" y="92.1">
                VERBRAUCHER
              </tspan>
            </text>
          </g>
          <g
            fill="#6D6D6D"
            fontFamily="ProximaNova-Regular, Proxima Nova"
            fontSize="14"
            fontWeight="normal"
            transform="translate(340 483)"
          >
            <g>
              <text>
                <tspan x="31" y="16">
                  Eine Sammelschiene
                </tspan>
                <tspan x="31" y="35">
                  (Eine Erzeugungsanlage)
                </tspan>
              </text>
            </g>
          </g>
          <g
            fill="#6D6D6D"
            fontFamily="ProximaNova-Regular, Proxima Nova"
            fontSize="14"
            fontWeight="normal"
            transform="translate(340 483)"
          >
            <g>
              <text>
                <tspan x="31" y="16">
                  Eine Sammelschiene
                </tspan>
                <tspan x="31" y="35">
                  (Eine Erzeugungsanlage)
                </tspan>
              </text>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default React.memo(Icon);
