import React from 'react';
import styled from 'styled-components';

interface SpinnerProps {
  size: number;
  color?: string;
  style?: any;
}

const SpinnerBase = styled.div<SpinnerProps>`
  display: inline-block;
  position: relative;
  width: ${(props) => `${(props as any).size}px`};
  height: ${(props) => `${(props as any).size}px`};
  opacity: 1;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${(props) => `${(props as any).size - 4}px`};
    height: ${(props) => `${(props as any).size - 4}px`};
    border: 2px solid ${(props) => (props.color ? props.color : '#000')};
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${(props) => (props.color ? props.color : '#000')} transparent
      transparent transparent;
  }
  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export function Spinner(props: SpinnerProps) {
  return (
    <SpinnerBase {...props}>
      <div />
      <div />
      <div />
      <div />
    </SpinnerBase>
  );
}

export function SpinnerLight(props: SpinnerProps) {
  return <Spinner color="#fff" {...props} />;
}

export function SpinnerDark(props: SpinnerProps) {
  return <Spinner color="#000" {...props} />;
}
