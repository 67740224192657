import React from 'react';
import styled from 'styled-components';
import { Flex, FlexRow } from '@ampeersenergy/ampeers-ui-components';
import { CSSTransition } from 'react-transition-group';

const Wrapper = styled(Flex)`
  background: #f9f9f9;
  overflow: hidden;
  border-radius: 4px;
`;

const shared = `
  color: #fff;
  font-size: 12px;
  text-align: right;
  height: 18px;
  overflow: hidden;

  transition: transform 0.5s ease-out;
  transform-origin: left;

  .display-enter &,
  .display-appear & {
    transform: scaleX(0);
  }
  .display-enter-active &,
  .display-appear-active & {
    transform: scaleX(1);
  }

  span {
    margin: 0px 10px;
  }
`;

const SuccessBar = styled.div`
  ${shared}
  background: #7795F4;
`;

const FailureBar = styled.div`
  ${shared}
  background: #13238E;
`;

interface SummaryBarChartProps {
  total: number;
  stepTotal: number;
  stepSuccess: number;
  stepFailures: number;
  index: number;
}

export function SummaryBarChart({
  total,
  stepSuccess,
  stepFailures,
  stepTotal,
  index,
}: SummaryBarChartProps) {
  return (
    <CSSTransition in timeout={500} classNames="display" unmountOnExit appear>
      <Wrapper>
        <FlexRow>
          <SuccessBar
            style={{
              minWidth: 50,
              width: `${100 * (stepSuccess / total)}%`,
              transitionDelay: `${index * 0.1}s`,
            }}
          >
            <span>
              {`${stepSuccess} (${Math.round(
                100 * (stepSuccess / stepTotal),
              )}%)`}
            </span>
          </SuccessBar>
          <FailureBar
            style={{
              minWidth: 50,
              width: `${100 * (stepFailures / total)}%`,
              transitionDelay: `${index * 0.1 + 0.5}s`,
            }}
          >
            <span>
              {`${stepFailures} (${Math.round(
                100 * (stepFailures / stepTotal),
              )}%)`}
            </span>
          </FailureBar>
        </FlexRow>
      </Wrapper>
    </CSSTransition>
  );
}
