import React, { useState, useCallback, useEffect } from 'react';
import { Overlay } from '@ampeersenergy/ampeers-ui-components';
import { useMutation } from '@apollo/client';

import { CreateFlow } from '../../../../components';
import { EditContainerProps } from '../../../../components/createFlow';
import {
  Address,
  CreateMeasurementContractSessionDocument,
  LoadProfile,
  MkvTemplateData,
  useReadLoadProfilesQuery,
  useReadMkvTemplateQuery,
} from '../../../../graphql-types';
import { removeTypename } from '../../../../helpers/utils';

import { MaloMeloMeterForm } from './customMeloMaloForm';
import CreateMeasurementContract from './createMeasurementContract';
import FlowEndMessage from './flowEndMessage';
import DiscardChangesDialog from './discardChangesDialog';

export interface MeloMaloValueBag {
  maloFeedIn?: string;
  maloConsumption?: string;
  melo: string;
  malo?: string;
}

function prefillValuesFromTemplate(
  defaultValues: any,
  template?: MkvTemplateData | null,
  addresses?: Address[],
  loadProfiles?: LoadProfile[],
) {
  if (!template) {
    return defaultValues;
  }

  const loadProfileId =
    !!loadProfiles &&
    loadProfiles?.find((profile) => profile.name === template.loadProfile)?.id;
  return removeTypename({
    ...defaultValues,
    status: template.status,
    customer: {
      ...defaultValues?.customer,
      ...template.customer,
      ...(addresses && {
        addressShipping: {
          ...addresses[0],
          name: template.customer?.person?.name,
          namePrefix: template.customer?.person?.namePrefix,
        },
      }),
    },
    contractMeter: {
      ...defaultValues?.contractMeter,
    },
    ...(template.tariff && { tariff: template.tariff }),
    ...(loadProfileId && {
      loadProfile: {
        id: loadProfileId,
      },
    }),
  });
}

function getMkvMeterType(
  meterType: string,
  isSumMeter: boolean,
  secondContract: boolean,
) {
  if (isSumMeter) {
    return secondContract ? 'sumMeterFeedIn' : 'sumMeterConsumption';
  }
  switch (meterType.toLowerCase()) {
    case 'mso-e':
      return 'prodMeter';
    case 'mso-k':
      return 'cascadeMeter';
    default:
      return undefined;
  }
}

export default function CreateMeasurementContractFlow({
  onSuccess,
  formVariables,
  defaultValues,
  variables,
  values,
  onAbort,
}: EditContainerProps) {
  const {
    contractMeter: { meter },
  } = defaultValues;

  if (!meter.meterType || !meter.msoType) {
    throw new Error('Meter Object is required.');
  }

  const [formState, setFormState] = useState<
    'malo-melo' | 'first-contract' | 'second-contract' | 'done'
  >('malo-melo');

  const isSumMeter = meter.meterType === 'MSO-H';
  const isProdMeter = meter.meterType === 'MSO-E';
  const isCascadeMeter = meter.meterType === 'MSO-K';

  const isOperator = !!(formState === 'second-contract' || isProdMeter);
  const sumMeterContractLabelState = useState();

  const meterType = getMkvMeterType(
    meter.meterType,
    isSumMeter,
    formState === 'second-contract',
  );

  const { data: mkvTemplate } = useReadMkvTemplateQuery({
    variables: {
      template: meterType!,
    },
    skip: !meterType,
  });

  const { data: loadProfiles } = useReadLoadProfilesQuery({
    variables: {
      balancingAreaAccountId: formVariables.balancingAreaAccountId,
    },
    skip: !mkvTemplate?.readMkvTemplate?.loadProfile,
  });

  const [contractValues, setContractValues] = useState<null | MeloMaloValueBag>(
    null,
  );

  const [session, setSession] = useState<string | null>(null);
  const [getSession] = useMutation(CreateMeasurementContractSessionDocument);
  const [isWarningVisible, setWarningVisible] = useState(false);
  const [createResult, setCreateResult] = useState<string | null>(null);
  const [contractDates, setContractDates] = useState<null | {
    startDate: string;
    endDate: string;
  }>(null);
  const [warningEnabled, setWarningEnabled] = useState(false);

  useEffect(() => {
    const getSessionMutation = async () => {
      const result = await getSession();
      setSession(result.data.session);
    };

    if (session === null) {
      getSessionMutation();
    }
  }, [getSession, session]);

  const setValues = (value: MeloMaloValueBag) => {
    setContractValues(value);
    setFormState('first-contract');
  };

  const onContractCreateSuccess = useCallback(
    (result) => {
      if (formState === 'first-contract' && isSumMeter) {
        setCreateResult(result.odooContractId);
      }
    },
    [formState, isSumMeter],
  );

  const onContractCreateDone = useCallback(
    (result, { values: _values }) => {
      if (formState === 'first-contract' && isSumMeter) {
        setFormState('second-contract');
        setContractDates({
          startDate: _values.startDate,
          endDate: _values.endDate,
        });
      } else {
        setFormState('done');
        if (onSuccess) {
          onSuccess(null, { values: {} });
        }
      }
    },
    [formState, isSumMeter, onSuccess],
  );

  const onModalAbort = useCallback(() => {
    if (isSumMeter && warningEnabled) {
      setWarningVisible(true);
    } else if (onAbort) {
      onAbort();
    }
  }, [isSumMeter, warningEnabled, onAbort]);

  const defaultTitle = isCascadeMeter ? 'Kaskadenzähler' : 'Erzeugungszähler';

  const titles: { [key: string]: string } = {
    'malo-melo': `Vertrag ${isSumMeter ? 'Summenzähler' : defaultTitle}`,
    'ask-for-second-contract': `Vertrag ${
      isSumMeter ? 'Summenzähler' : defaultTitle
    }`,
    'first-contract': `Vertrag ${
      isSumMeter ? `Summenzähler Bezug` : defaultTitle
    }`,
    'second-contract': 'Vertrag Summenzähler Einspeisung',
  };

  let inner;

  if (formState === 'first-contract' || formState === 'second-contract') {
    const mergedDefaultValues = prefillValuesFromTemplate(
      {
        ...defaultValues,
        customer: {
          isOperator,
        },
        downPayment: 0,
        ...(isSumMeter &&
          formState === 'second-contract' && {
            electricityGenerationOrGasType: 'Sonstiges',
          }),
        contractMeter: {
          meter: {
            id: meter.id,
          },
          ...(isSumMeter && {
            malo:
              formState === 'first-contract'
                ? contractValues?.maloConsumption
                : contractValues?.maloFeedIn,
          }),
          ...(!isSumMeter && {
            malo: contractValues?.malo,
          }),
          melo: contractValues?.melo,
        },
        startDate: contractDates?.startDate,
        endDate: contractDates?.endDate,

        storeAsMkvTemplate: !mkvTemplate?.readMkvTemplate,
      },
      mkvTemplate?.readMkvTemplate,
      variables.addresses,
      loadProfiles?.readLoadProfiles,
    );

    inner = (
      <CreateFlow
        editContainer={CreateMeasurementContract}
        kind={titles[formState]}
        onSuccess={onContractCreateSuccess}
        onDone={onContractCreateDone}
        onAbort={onModalAbort}
        values={values}
        variables={{
          ...variables,
          session,
        }}
        formVariables={{
          ...formVariables,
          isSumMeter,
          isOperator,
          formState,
          sumMeterContractLabelState,
          isPrefilled: !!mkvTemplate?.readMkvTemplate,
          meterType,
        }}
        defaultValues={mergedDefaultValues}
        key={formState}
        endMessageContainer={(props) => (
          <FlowEndMessage
            {...props}
            showNextStep={isSumMeter && formState === 'first-contract'}
            /**
             * thats some hack to know when this component
             * is rendered in order to enable/disable the warning dialog
             */
            onShown={() => setWarningEnabled(formState === 'first-contract')}
          />
        )}
      />
    );
  } else if (formState === 'malo-melo') {
    inner = (
      <MaloMeloMeterForm
        meter={meter}
        setValues={setValues}
        isSumMeter={isSumMeter}
      />
    );
  }

  return (
    <>
      {isWarningVisible && (
        <DiscardChangesDialog
          contractIdToDiscard={createResult!}
          afterDiscard={() => {
            if (onAbort) {
              onAbort();
            }
          }}
          close={() => setWarningVisible(false)}
        />
      )}
      <Overlay isOpen onRequestClose={onModalAbort} title={titles[formState]}>
        {inner}
      </Overlay>
    </>
  );
}
