import React from 'react';

import { ReactPropType } from '../../types/util.types';

import './crm-board-column-header.scss';

export type CrmBoardColumnHeaderPropType = {
  title: string;
  cardCount: number;
};

function CrmBoardColumnHeader({
  cardCount,
  title,
}: ReactPropType<CrmBoardColumnHeaderPropType>) {
  return (
    <div className="column-header">
      <h2 className="title">{title}</h2>
      <span className="count">{cardCount}</span>
    </div>
  );
}

export default CrmBoardColumnHeader;
