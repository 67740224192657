/* eslint-disable prefer-destructuring */
import React from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import {
  Icons,
  Flex,
  FlexRow,
  SuccessConfetti,
} from '@ampeersenergy/ampeers-ui-components';

import { useHasRole } from '../../../components/useHasRole';
import { EndMessageContainerProps } from '../../../components/createFlow';

const Wrap = styled.div`
  font-size: 16px;
  p {
    padding-left: 12px;
    margin-top: 0px;
  }
`;

const Hint = styled.div`
  font-size: 16px;
  margin-top: 5px;
  margin-left: 6px;
  margin-bottom: 15px;
  text-align: center;
`;

const StyledLink = styled(Link)`
  font-size: 14px;
  margin-left: 5px;
  margin-top: 10px;
  text-decoration: none;

  span {
    margin-left: 5px;
    text-transform: uppercase;
  }
`;

function EndMessageCreateContract({
  variables,
  result,
  onDone,
  kind,
}: EndMessageContainerProps) {
  const canCreateWelcomeLetter = useHasRole('feature_welcome_letter');
  const canCreateContractConfirmationLetter = useHasRole(
    'feature_contract_confirmation_letter',
  );

  const location = useLocation();
  let basePath = location.pathname;
  switch (kind) {
    case 'Mieter':
      basePath = basePath.split('/tenant')[0];
      break;
    case 'Vertrag':
      basePath = basePath.split('/building')[0];
      break;
    default:
      break;
  }
  if (variables.createContractURL) {
    basePath = basePath.split('/crm/')[0] + variables.createContractURL;
  }
  const meterReadingPath = basePath.split('/contract/')[0];
  const meterId = result?.contractMeter?.meters[0].id;

  return (
    <SuccessConfetti data-testid="create-flow-success">
      <Wrap>
        <FlexRow>
          <Hint>
            Super! Der Vertrag wurde erfolgreich angelegt.
            <br /> Wenn Du möchtest, kannst Du direkt weitere Daten hinzufügen.
            Hier ein paar Vorschläge:
          </Hint>
        </FlexRow>
        <Flex>
          <StyledLink
            to={`${basePath}/tenant/contract/${result.id}/metadata`}
            onClick={(e) => {
              if (e.ctrlKey === e.metaKey) onDone();
            }}
          >
            <Icons.Metadata size={20} color="#119142" />
            <span>Metadaten</span>
          </StyledLink>
        </Flex>
        <Flex>
          <StyledLink to={`${meterReadingPath}/meter/${meterId}/meterReading`}>
            <Icons.Meter size={20} color="#119142" />
            <span>Zählerstände</span>
          </StyledLink>
        </Flex>
        <Flex>
          <StyledLink
            to={`${basePath}/tenant/contract/${result.id}/communication`}
            onClick={(e) => {
              if (e.ctrlKey === e.metaKey) onDone();
            }}
          >
            <Icons.Notes size={20} color="#119142" />
            <span>Notizen</span>
          </StyledLink>
        </Flex>
        {canCreateWelcomeLetter && (
          <Flex>
            <StyledLink
              to={`${basePath}/tenant/contract/${result.id}/masterdata?createDocument=welcome_letter`}
              onClick={(e) => {
                if (e.ctrlKey === e.metaKey) onDone();
              }}
            >
              <Icons.File size={20} color="#119142" />
              <span>Willkommensschreiben erstellen</span>
            </StyledLink>
          </Flex>
        )}
        {canCreateContractConfirmationLetter &&
          result.status?.endsWith('_ACTIVE') && (
            <Flex>
              <StyledLink
                to={`${basePath}/tenant/contract/${result.id}/masterdata?createDocument=contract_confirmation_letter`}
                onClick={(e) => {
                  if (e.ctrlKey === e.metaKey) onDone();
                }}
              >
                <Icons.File size={20} color="#119142" />
                <span>Lieferbestätigung erstellen</span>
              </StyledLink>
            </Flex>
          )}
      </Wrap>
    </SuccessConfetti>
  );
}

export default EndMessageCreateContract;
