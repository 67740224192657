import * as React from 'react';
import { SubTitle } from '@ampeersenergy/ampeers-ui-components';
import 'styled-components/macro';

import {
  GraphqlFormField,
  GraphqlFormInputGroup,
} from '../../../components/graphql-form/render';
import { Grid } from '../../../components/layout';
import { useGraphqlForm } from '../../../components/graphql-form/hooks/useGraphqlForm';
import { TooltipInfo } from '../../../components/TooltipInfo';

import { ContactDataForm } from './ContactDataForm';

interface AddressSettingsFormProps {
  formPrefix?: string;
}

export function AddressSettingsForm({ formPrefix }: AddressSettingsFormProps) {
  const { isEditing } = useGraphqlForm();

  const prefix = formPrefix ? `${formPrefix}.` : '';

  return (
    <>
      <SubTitle>
        Adresse
        <TooltipInfo
          id="addressTooltip"
          text="Die Firmenadresse, die auf den im Local Supply erstellten Dokumenten
          angezeigt wird"
        />
      </SubTitle>
      <GraphqlFormField name={`${prefix}companyName`} label={null} />
      {isEditing ? (
        <>
          <Grid css="grid-auto-columns: 3fr 1fr;">
            <GraphqlFormField
              name={`${prefix}address.streetName`}
              label={null}
            />
            <GraphqlFormField
              name={`${prefix}address.streetNumber`}
              label={null}
            />
          </Grid>
          <Grid css="grid-auto-columns: 1fr 2fr;">
            <GraphqlFormField name={`${prefix}address.zipCode`} label={null} />
            <GraphqlFormField name={`${prefix}address.city`} label={null} />
          </Grid>
        </>
      ) : (
        <>
          <GraphqlFormInputGroup>
            <GraphqlFormField
              name={`${prefix}address.streetName`}
              label={null}
            />
            <GraphqlFormField
              name={`${prefix}address.streetNumber`}
              label={null}
            />
          </GraphqlFormInputGroup>
          <GraphqlFormInputGroup>
            <GraphqlFormField name={`${prefix}address.zipCode`} label={null} />
            <GraphqlFormField name={`${prefix}address.city`} label={null} />
          </GraphqlFormInputGroup>
        </>
      )}

      <ContactDataForm prefix={prefix} />

      <SubTitle css="margin-top: 24px;">Bankdaten</SubTitle>
      <GraphqlFormField name={`${prefix}banking.iban`} />
      <GraphqlFormField name={`${prefix}banking.bic`} />
    </>
  );
}
