import React from 'react';
import { WorkflowStep } from '@ampeersenergy/ampeers-ui-components';

import { ReadWorkflowQuery } from '../../../graphql-types';

interface StepsSidebarProps {
  workflow?: ReadWorkflowQuery['readWorkflow'];
  userSelected?: string | null;
  setUserSelected: (
    s: string,
    type?: 'replace' | 'push' | 'replaceIn' | 'pushIn' | undefined,
  ) => void;
}

const hideInUIIf = (
  workflow: ReadWorkflowQuery['readWorkflow'],
  name: string,
) => {
  if (!workflow) return;

  const conditions = workflow.meta[name].hideInUIIf;

  if (!conditions) return false;
  if (conditions.always) return true;

  return Object.keys(conditions).some((key: string) => {
    if (Array.isArray(conditions[key])) {
      return conditions[key].some(
        (condition: string) =>
          condition === workflow.currentState?.context?.payload?.[key],
      );
    }
    // if the condition is of type boolean we just check if the value is present
    if (typeof conditions[key] === 'boolean') {
      return !!workflow.currentState?.context?.payload?.[key];
    }
    // otherwise we check if the value is equal to the condition
    return workflow.currentState?.context?.payload?.[key] === conditions[key];
  });
};

export const StepsSidebar: React.FC<StepsSidebarProps> = ({
  workflow,
  userSelected,
  setUserSelected,
}) => {
  if (!workflow) return null;

  return (
    <div>
      {workflow.stateNames
        .filter((name) => {
          return (
            workflow.meta[name] &&
            workflow.meta[name].hideInUI !== true &&
            !hideInUIIf(workflow, name)
          );
        })
        .map((name) => {
          let state = 'inactive';

          if (name in workflow.currentState.context) {
            state = workflow.currentState.context[name].uiState;
          } else if (
            name === workflow.currentState.name ||
            name === workflow.currentState.parentName
          ) {
            state = 'active';
          }

          return (
            <WorkflowStep
              label={workflow.meta[name]?.name ?? name}
              state={state as any}
              onClick={() => setUserSelected(name, 'replace')}
              isSelected={userSelected === name}
              key={workflow.meta[name]?.name ?? name}
            />
          );
        })}
    </div>
  );
};
