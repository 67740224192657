import * as React from 'react';
import {
  ButtonIcon,
  ErrorAlert,
  Icons,
  IFrameModal,
  Modal,
} from '@ampeersenergy/ampeers-ui-components';
import { Result } from 'neverthrow';

interface InvoiceDraftPreviewButtonProps {
  contractLabel: string;
  customerName: string;
  previewInvoice: (contractLabel: string) => Promise<Result<string, string>>;
}

export function InvoiceDraftPreviewButton({
  contractLabel,
  customerName,
  previewInvoice,
}: InvoiceDraftPreviewButtonProps) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [invoicePreviewUrl, setInvoicePreviewUrl] = React.useState<
    string | undefined
  >();
  const [documentError, setDocumentError] = React.useState<
    string | undefined
  >();

  const handleClick = async () => {
    setIsLoading(true);
    const previewResponse = await previewInvoice(contractLabel);

    if (previewResponse.isOk()) {
      setInvoicePreviewUrl(previewResponse.value);
    }
    if (previewResponse.isErr()) {
      setDocumentError(previewResponse.error);
    }
    setIsLoading(false);
  };

  const modalName = `Rechnungsvorschau für ${customerName}`;

  return (
    <>
      <ButtonIcon
        secondary
        icon={Icons.File}
        onClick={handleClick}
        disabled={isLoading}
      />
      {isLoading || invoicePreviewUrl ? (
        <IFrameModal
          src={`${invoicePreviewUrl}&delivery=inline`}
          contentLabel={modalName}
          closeModal={() => setInvoicePreviewUrl(undefined)}
          title={modalName}
          isLoading={isLoading}
        />
      ) : null}
      {documentError ? (
        <Modal
          contentLabel={modalName}
          title={modalName}
          onRequestClose={() => setDocumentError(undefined)}
          isOpen={!!documentError}
        >
          <ErrorAlert>{documentError}</ErrorAlert>
        </Modal>
      ) : null}
    </>
  );
}
