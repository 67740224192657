import React from 'react';

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="300" viewBox="0 0 257 136">
      <defs>
        <linearGradient
          id="linearGradient-v5hhe9sgyh-1"
          x1="50%"
          x2="50%"
          y1="0%"
          y2="100%"
        >
          <stop offset="0%" stopColor="#7DD77B" />
          <stop offset="44.236%" stopColor="#64C685" />
          <stop offset="100%" stopColor="#45AF93" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(1.006)">
          <text
            fill="#7BD67C"
            fillRule="nonzero"
            fontFamily="ProximaNova-Medium, Proxima Nova"
            fontSize="9.052"
            fontWeight="400"
          >
            <tspan x="146.52" y="131.707">
              VERBRAUCHER
            </tspan>
          </text>
          <text
            fill="#7B89D6"
            fillRule="nonzero"
            fontFamily="ProximaNova-Medium, Proxima Nova"
            fontSize="15.086"
            fontWeight="400"
          >
            <tspan x="150.154" y="54.23">
              Z
            </tspan>
          </text>
          <text
            fill="#7B89D6"
            fillRule="nonzero"
            fontFamily="ProximaNova-Medium, Proxima Nova"
            fontSize="11.063"
            fontWeight="400"
          >
            <tspan x="159.267" y="54.54">
              1
            </tspan>
          </text>
          <g transform="translate(0 73.42)">
            <g
              fill="#7B89D6"
              fillRule="nonzero"
              fontFamily="ProximaNova-Medium, Proxima Nova"
              fontWeight="400"
              transform="translate(6.148 3.017)"
            >
              <text fontSize="15.086">
                <tspan x="0" y="14">
                  Z
                </tspan>
              </text>
              <text fontSize="11.063">
                <tspan x="9.115" y="14.31">
                  N
                </tspan>
              </text>
            </g>
            <circle
              cx="13.578"
              cy="13.578"
              r="13.578"
              stroke="#7B89D6"
              strokeWidth="0.718"
            />
          </g>
          <g transform="translate(178.018 39.224)">
            <g
              fill="#0270FF"
              fillRule="nonzero"
              fontFamily="ProximaNova-Medium, Proxima Nova"
              fontWeight="400"
              transform="translate(7.626)"
            >
              <text fontSize="15.086">
                <tspan x="0" y="14">
                  Z
                </tspan>
              </text>
              <text fontSize="11.063">
                <tspan x="9.115" y="14.31">
                  1B
                </tspan>
              </text>
            </g>
            <g transform="translate(0 2.011)">
              <path
                stroke="#0270FF"
                strokeLinecap="square"
                strokeWidth="1.006"
                d="M2.886 0L2.886 15.641"
              />
              <path
                fill="#0270FF"
                fillRule="nonzero"
                d="M2.88605697 15.9033764L5.77211394 18.7948994 0 18.7948994z"
                transform="rotate(-180 2.886 17.35)"
              />
            </g>
          </g>
          <g transform="translate(63.363 75.431)">
            <g
              fill="#0270FF"
              fillRule="nonzero"
              fontFamily="ProximaNova-Medium, Proxima Nova"
              fontWeight="400"
              transform="translate(6.436)"
            >
              <text fontSize="15.086">
                <tspan x="0" y="14">
                  Z
                </tspan>
              </text>
              <text fontSize="11.063">
                <tspan x="9.115" y="14.31">
                  NB
                </tspan>
              </text>
            </g>
            <g transform="translate(0 2.011)">
              <path
                stroke="#0270FF"
                strokeLinecap="square"
                strokeWidth="1.006"
                d="M2.886 0L2.886 15.641"
              />
              <path
                fill="#0270FF"
                fillRule="nonzero"
                d="M2.88605697 15.9033764L5.77211394 18.7948994 0 18.7948994z"
                transform="rotate(-180 2.886 17.35)"
              />
            </g>
          </g>
          <circle
            cx="156.394"
            cy="50.79"
            r="13.578"
            stroke="#7B89D6"
            strokeWidth="0.718"
          />
          <circle
            cx="98.563"
            cy="113.649"
            r="22.126"
            fill="url(#linearGradient-v5hhe9sgyh-1)"
            fillRule="nonzero"
          />
          <text
            fill="#FFF"
            fillRule="nonzero"
            fontFamily="ProximaNova-Medium, Proxima Nova"
            fontSize="21.121"
            fontWeight="400"
          >
            <tspan x="85.839" y="120.329">
              EA
            </tspan>
          </text>
          <path
            stroke="#7BD67C"
            strokeLinecap="square"
            strokeWidth="1.006"
            d="M137.787 72.414L137.787 3.017"
          />
          <path
            stroke="#7BD67C"
            strokeLinecap="square"
            strokeWidth="1.006"
            d="M97.557 95.546L97.557 72.414"
          />
          <path
            stroke="#7BD67C"
            strokeLinecap="square"
            strokeWidth="1.006"
            d="M177.011 95.546L177.011 72.414"
          />
          <path
            stroke="#7BD67C"
            strokeLinecap="square"
            strokeWidth="1.006"
            d="M242.011 95.546L242.011 72.414"
          />
          <path
            stroke="#7BD67C"
            strokeLinecap="square"
            strokeWidth="1.006"
            d="M156.897 118.678L156.897 95.546"
          />
          <path
            stroke="#7BD67C"
            strokeLinecap="square"
            strokeWidth="1.006"
            d="M166.954 118.678L166.954 95.546"
          />
          <path
            stroke="#7BD67C"
            strokeLinecap="square"
            strokeWidth="1.006"
            d="M177.011 118.678L177.011 95.546"
          />
          <path
            stroke="#7BD67C"
            strokeLinecap="square"
            strokeWidth="1.006"
            d="M187.069 118.678L187.069 95.546"
          />
          <path
            stroke="#7BD67C"
            strokeLinecap="square"
            strokeWidth="1.006"
            d="M197.126 118.678L197.126 95.546"
          />
          <text
            fill="#7BD67C"
            fillRule="nonzero"
            fontFamily="ProximaNova-Medium, Proxima Nova"
            fontSize="9.052"
            fontWeight="400"
          >
            <tspan x="223.52" y="131.707">
              WÄRME
            </tspan>
          </text>
          <path
            stroke="#7BD67C"
            strokeLinecap="square"
            strokeWidth="1.006"
            d="M236.897 118.678L236.897 95.546"
          />
          <path
            stroke="#7BD67C"
            strokeLinecap="square"
            strokeWidth="1.006"
            d="M246.957 118.678L246.957 95.546"
          />
          <path
            stroke="#7BD67C"
            strokeLinecap="square"
            strokeWidth="1.006"
            d="M97.557 2.514L177.011 2.514"
          />
          <path
            stroke="#7BD67C"
            strokeLinecap="square"
            strokeWidth="1.006"
            d="M97.557 72.414L241.994 72.5"
          />
          <path
            stroke="#7BD67C"
            strokeLinecap="square"
            strokeWidth="1.006"
            d="M156.897 95.546L196.055 95.546"
          />
          <path
            stroke="#7BD67C"
            strokeLinecap="square"
            strokeWidth="1.006"
            d="M236.897 95.546L246.494 95.5"
          />
          <circle
            cx="137.787"
            cy="3.017"
            r="3.017"
            fill="#7BD67C"
            fillRule="nonzero"
          />
          <circle
            cx="137.787"
            cy="72.414"
            r="3.017"
            fill="#7BD67C"
            fillRule="nonzero"
          />
          <path
            stroke="#7B89D6"
            strokeDasharray="2.01149421861802,4.02298843723604"
            strokeLinecap="square"
            strokeWidth="1.006"
            d="M97.557 29.67L177.011 29.67"
          />
          <text
            fill="#12007F"
            fillRule="nonzero"
            fontFamily="ProximaNova-Medium, Proxima Nova"
            fontSize="15.086"
            fontWeight="400"
          >
            <tspan x="223.58" y="53.224">
              Z
            </tspan>
          </text>
          <text
            fill="#12007F"
            fillRule="nonzero"
            fontFamily="ProximaNova-Medium, Proxima Nova"
            fontSize="11.063"
            fontWeight="400"
          >
            <tspan x="232.695" y="53.534">
              1L
            </tspan>
          </text>
          <g transform="translate(30.434 73.42)">
            <g
              fill="#12007F"
              fillRule="nonzero"
              fontFamily="ProximaNova-Medium, Proxima Nova"
              fontWeight="400"
              transform="translate(7.162)"
            >
              <text fontSize="15.086">
                <tspan x="0" y="14">
                  Z
                </tspan>
              </text>
              <text fontSize="11.063">
                <tspan x="9.115" y="14.31">
                  NL
                </tspan>
              </text>
            </g>
            <g transform="rotate(-180 2.886 9.68)">
              <path
                stroke="#12007F"
                strokeLinecap="square"
                strokeWidth="1.006"
                d="M2.886 0L2.886 14.218"
              />
              <path
                fill="#12007F"
                fillRule="nonzero"
                d="M2.88605697 14.4583333L5.77211394 17.3498563 7.10542736e-15 17.3498563z"
                transform="rotate(-180 2.886 15.904)"
              />
            </g>
          </g>
          <g transform="rotate(-180 110.501 29.292)">
            <path
              stroke="#12007F"
              strokeLinecap="square"
              strokeWidth="1.006"
              d="M2.886 0L2.886 14.218"
            />
            <path
              fill="#12007F"
              fillRule="nonzero"
              d="M2.88605697 14.4583333L5.77211394 17.3498563 0 17.3498563z"
              transform="rotate(-180 2.886 15.904)"
            />
          </g>
          <text
            fill="#7B89D6"
            fillRule="nonzero"
            fontFamily="ProximaNova-Medium, Proxima Nova"
            fontSize="9.052"
            fontWeight="400"
          >
            <tspan x="144.076" y="23.086">
              EIGENTUMSGRENZE
            </tspan>
          </text>
        </g>
      </g>
    </svg>
  );
}

export default React.memo(Icon);
