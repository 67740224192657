import React from 'react';
import styled from 'styled-components';

import { Info } from './icons';
import { IconProps } from './baseIcon';

const Message = styled.div`
  font-size: 15px;
  padding: 12px 15px 12px 15px;
  border-radius: 4px;
  margin-top: 10px;
  margin-bottom: 5px;
  border-radius: 4px;
  color: #4d7488;
  background: #eef8fd;
`;

const IconWrap = styled.div`
  margin-right: 0px;
  display: inline-block;
  position: relative;
  left: -5px;
`;

const Title = styled.span`
  font-weight: 600;
  font-size: 17px;
  color: #4d7488;
  letter-spacing: 0.27px;
`;

interface InfoMsgProps {
  title: string;
  message?: string;
  icon?: React.ComponentType<IconProps>;
  children?: React.ReactNode;
}

export default function InfoMsg({
  icon,
  title,
  message,
  children,
}: InfoMsgProps) {
  const Icon = icon || Info;

  return (
    <Message>
      <IconWrap>
        <Icon size={20} color="#4d7488" />
      </IconWrap>
      <Title>{title}</Title>
      <div>{children || message}</div>
    </Message>
  );
}
