import React from 'react';
import styled from 'styled-components';
import ContentLoader from 'react-content-loader';

import { withComponentLabel } from './withComponentLabel';

interface KpiNumberProps {
  value: string | number;
  color?: 'orange' | 'red' | 'blue' | 'green';
  isLoading?: boolean;
}

const CubeOrange = styled.div`
  border-radius: 4px;
  display: inline-block;
  height: 35px;
  padding: 0px 10px 0px 10px;
  align-items: center;
  justify-content: center;
  display: flex;
  background: #d4ddff;
  color: #1848ff;
  font-weight: bold;
`;

const CubeRed = styled(CubeOrange)`
  background: #fde6e6;
  color: #ae1813;
`;

const CubeBlue = styled(CubeOrange)`
  background: #9ee9ec;
  color: #227a5c;
`;
const CubeGreen = styled(CubeOrange)`
  background: #bbef7e;
  color: #6ba727;
`;

const COLORS = {
  orange: CubeOrange,
  red: CubeRed,
  blue: CubeBlue,
  green: CubeGreen,
};

const KpiNumber: React.FC<KpiNumberProps> = ({
  value,
  isLoading,
  color = 'orange',
}) => {
  const Cube = COLORS[color];
  return isLoading ? (
    <svg height={70} width={100}>
      <ContentLoader speed={2} width={100} height={100}>
        <rect x="25" y="34" rx="0" ry="0" width="46" height="45" />
      </ContentLoader>
    </svg>
  ) : (
    <Cube>{value}</Cube>
  );
};

export const KpiNumberWithLabel = withComponentLabel(KpiNumber);
export default KpiNumber;
