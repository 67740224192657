import React from 'react';
import * as yup from 'yup';

import GraphqlForm from '../../components/graphql-form';
import { EditContainerProps } from '../../components/createFlow';
import {
  ReadTariffDocument,
  ReadTariffsDocument,
  ReadTariffsQuery,
} from '../../graphql-types';
import { yupUTCDate } from '../../helpers/yupUTCDate';

import EditTariffForm from './editTariffForm';

interface EditTariffContainerProps extends EditContainerProps {
  tariffId: string;
  dataTariff: ReadTariffsQuery['readTariffs'][number] | undefined;
  otherTariffNames: string[];
  isLoading?: boolean;
}

export default function EditTariffContainer({
  tariffId,
  dataTariff,
  otherTariffNames,
  onSuccess,
  onAbort,
  isLoading,
}: EditTariffContainerProps) {
  return (
    <GraphqlForm
      mutation="updateTariff"
      startInEdit={false}
      values={dataTariff}
      onSuccess={onSuccess}
      onAbort={onAbort}
      readDocument={ReadTariffDocument}
      variables={{
        tariffId: parseFloat(tariffId),
      }}
      refetchQueries={[
        {
          query: ReadTariffDocument,
          variables: {
            tariffId: [parseFloat(tariffId)],
          },
        },
        {
          query: ReadTariffsDocument,
        },
      ]}
      isLoading={isLoading}
      disableEdit={false}
      validation={{
        validityEndDate: yupUTCDate
          .required()
          .min(
            new Date(),
            `Ende der Gültigkeit darf nicht in der Vergangenheit liegen`,
          )
          .test(
            'validityEndDate',
            'Ende der Gültigkeit darf nicht vor neustem Preisblatt sein',
            (value: any) => {
              return (
                new Date(value).getTime() >
                new Date(dataTariff?.priceSheets[0].startDate).getTime()
              );
            },
          ),
        nameInternal: yup
          .mixed()
          .notOneOf(
            otherTariffNames,
            `Es gibt bereits einen Tarif mit diesem Namen`,
          )
          .required(),
        nameExternal: yup.string().required(),
      }}
    >
      <EditTariffForm isLoading={isLoading} dataTariff={dataTariff} />
    </GraphqlForm>
  );
}
