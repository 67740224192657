import { DateTime } from 'luxon';

interface DeductionCalculationProps {
  energyPrice: number;
  basicPrice: number;
  expectedConsumption: number;
  contractStartDate: string;
  reductionStartDate: string;
}
export const deductionCalculation = ({
  energyPrice,
  basicPrice,
  expectedConsumption,
  contractStartDate,
  reductionStartDate,
}: DeductionCalculationProps) => {
  const daysLeftInYearAfterContractStartDate = Math.round(
    DateTime.fromISO(contractStartDate)
      .endOf('year')
      .diff(DateTime.fromISO(contractStartDate), 'days').days,
  );
  const daysLeftInYearAfterReductionStartDate = Math.round(
    DateTime.fromISO(contractStartDate)
      .endOf('year')
      .diff(DateTime.fromISO(reductionStartDate).plus({ days: 1 }), 'days')
      .days,
  );
  return parseFloat(
    parseFloat(
      (
        (((((expectedConsumption * energyPrice) / 100 + 12 * basicPrice) /
          365) *
          daysLeftInYearAfterContractStartDate) /
          daysLeftInYearAfterReductionStartDate) *
        30
      ).toFixed(3),
    ).toFixed(2),
  );
};
