import {
  ColumnDefinition,
  PageTitleLayout,
  Table,
  TableRows,
} from '@ampeersenergy/ampeers-ui-components';
import React, { useCallback, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { ErrorMsg, Overlay } from '../../components';
import { DocTitle } from '../../components/docTitle';
import { UnitRatioRenderer } from '../../components/table/renderer';
import { useReadProjectQuery } from '../../graphql-types';
import CreatePlantFlow from '../plant/create/createPlantFlow';

type PlantsColumns = {
  id: string;
  name: string;
  street: string;
  city: string;
  ratio: number[];
};

const columns: ColumnDefinition<PlantsColumns>[] = [
  {
    Header: 'Kundenanlage',
    accessor: 'name',
  },
  {
    Header: 'Straße',
    accessor: 'street',
  },
  {
    Header: 'Ort',
    accessor: 'city',
  },
  {
    Header: 'Ratio', // UnitRatioHeaderRenderer,
    accessor: 'ratio',
    filterable: false,
    Cell: UnitRatioRenderer,
    sortType: (rowA, rowB) => {
      return rowA.original.ratio[1] / (rowA.original.ratio[2] || 1) >
        rowB.original.ratio[1] / (rowB.original.ratio[2] || 1)
        ? 1
        : -1;
    },
  },
];

function PlantsListPage() {
  const match = useRouteMatch<{ projectId: string }>();
  const { projectId } = match.params;
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const { data, error, loading } = useReadProjectQuery({
    variables: {
      projectId,
    },
  });

  const PageTitleSnippet = useCallback(() => {
    const titleParts = data?.readProject?.name
      ? [data?.readProject?.name, 'Projekt']
      : ['Projekt'];

    return <DocTitle titleParts={titleParts} />;
  }, [data?.readProject?.name]);

  const tableData = useMemo(() => {
    if (data?.readProject) {
      return data.readProject.plants.map((p: any) => {
        let tUnitsThirdSupplied = 0;
        if (p.kpis) {
          tUnitsThirdSupplied =
            (p.kpis?.units ?? 0) - (p.kpis?.unitsMembers ?? 0);
        }
        return {
          name: p.name,
          street: `${p.mainAddress?.streetName} ${(
            p.mainAddress?.streetNumber ?? ''
          )
            .split(',')
            .join(', ')}`,
          city: `${p.mainAddress?.zipCode} ${p.mainAddress?.city}`,
          units: p.kpis?.units,
          unitsThird: `${tUnitsThirdSupplied} (${(
            ((tUnitsThirdSupplied ?? 0) / (p.kpis?.units ?? 0)) *
            100
          ).toFixed(0)}%)`,
          ratio: [p.kpis?.units, p.kpis?.unitsMembers, tUnitsThirdSupplied],
          id: p.id,
        };
      });
    }

    return [];
  }, [data]);

  const rowLink = useCallback(
    ({ row }: { row: TableRows<PlantsColumns> }) => {
      const entry = row.original;
      return `${match.url}/plant/${entry.id}`;
    },
    [match.url],
  );

  if (error) {
    return (
      <>
        <PageTitleSnippet />
        <PageTitleLayout levelsUpToParent={1} title="">
          <ErrorMsg error={error} />
        </PageTitleLayout>
      </>
    );
  }

  return (
    <>
      <PageTitleSnippet />
      <PageTitleLayout
        levelsUpToParent={1}
        title={data?.readProject?.name || ''}
        isLoading={loading}
      >
        <Table
          data={tableData}
          columns={columns}
          rowLink={rowLink}
          filterKind="Kundenanlagen"
          isLoading={loading}
          withFilter
          withRouting
          withAlternatingRows
          compact
        />
      </PageTitleLayout>
      <Overlay
        title="Kundenanlage anlegen"
        onClose={() => setModalIsOpen(false)}
        isOpen={modalIsOpen}
      >
        <CreatePlantFlow
          onAbort={() => setModalIsOpen(false)}
          onSuccess={() => setModalIsOpen(false)}
        />
      </Overlay>
    </>
  );
}

export default PlantsListPage;
