import React from 'react';
import styled, { withTheme, ThemeProps } from 'styled-components';

import { File } from './icons';
import type { Theme } from './themeProvider';

const Wrapper = styled.div`
  border: 1px solid #979797;
  border-radius: 4px;
  display: inline-block;
  width: 35px;
  height: 35px;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;

  :hover {
    background: ${(props) => props.theme.primaryColor};
    border-color: ${(props) => props.theme.primaryColor};

    svg {
      fill: #fff;
    }
  }
`;

export const FileIcon = withTheme(
  ({ className, theme }: ThemeProps<Theme> & { className?: string }) => {
    return (
      <Wrapper className={className}>
        <File size={20} color={theme.primaryColor} />
      </Wrapper>
    );
  },
);
