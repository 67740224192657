import React from 'react';
import { Icons, Button } from '@ampeersenergy/ampeers-ui-components';
import { DateTime } from 'luxon';

import {
  AccountingType,
  InvoiceCycle,
  useReadContractsBatchQuery,
  WorkflowType,
} from '../../../graphql-types';
import { CreateAccountingWorkflowModal } from '../create/createAccountingWorkflow.modal';

import {
  ClosingAccountingInvoice,
  InvoicePeriod,
  PeriodEnum,
  StandardAccountingInvoice,
  YearlyAccountingInvoice,
} from './dashboardAccountingTypes';
import NumberOfInvoicesDisplay from './NumberOfInvoicesDisplay';
import { periodTexts } from './AccountingWorkflowFormInnerDashboard';
import { AccountingContractModal } from './AccountingContractModal';
import { ContinueAccountingContractModal } from './ContinueAccountingModal';

// Defines start dates of every quarter based on accountable months (March, Juni, September, December) for quarterly turnus type
const quarterlyDatesMap: {
  [key: string]: string;
} = {
  '03': '01-01',
  '06': '04-01',
  '09': '07-01',
  '12': '10-01',
};

// Defines start dates of every quarter based on accountable months (Juni, December) for halfyearly turnus type
const halfYearlyDatesMap: {
  [key: string]: string;
} = {
  '06': '01-01',
  '12': '07-01',
};

export function YearlyInvoicePeriodRow({
  invoiceCycleType,
  period,
  invoices,
}: {
  invoiceCycleType: InvoiceCycle;
  period: InvoicePeriod;
  invoices: YearlyAccountingInvoice;
}) {
  const [modalVisible, setModalVisible] = React.useState<boolean>(false);
  const [contractIdsToLoad, setContractIdsToLoad] = React.useState<
    Array<string>
  >([]);

  const { data: contractsToAccount, loading } = useReadContractsBatchQuery({
    variables: { ids: contractIdsToLoad },
    skip: contractIdsToLoad.length === 0,
  });

  const openDialog = React.useCallback(() => {
    setContractIdsToLoad(invoices.map((invoice) => `${invoice.id}`));
    setModalVisible(true);
  }, [invoices]);

  if (invoices.length === 0) {
    return null;
  }

  let finalInvoices = invoices;
  if (contractsToAccount?.readContractsBatch.length) {
    finalInvoices = invoices.map((invoice) => {
      const contract = contractsToAccount.readContractsBatch.find(
        (_contract) => Number.parseInt(_contract.id, 10) === invoice.id,
      );

      return {
        ...invoice,
        plantId: `${contract?.plantId!}`,
      };
    });
  }

  const { start: startSettlementDate } = period;
  const { year } = DateTime.fromISO(startSettlementDate);

  const yearlySettlement = finalInvoices[0].settlement; // The settlement date is the same for every yearly invoice
  const text = periodTexts[invoiceCycleType];

  const formattedMonth = DateTime.fromObject({
    month: +yearlySettlement.month,
  }).toFormat('MM');
  const formattedDay = DateTime.fromObject({
    month: +formattedMonth,
    day: +yearlySettlement.day,
  }).toFormat('dd');

  const endDateTime = DateTime.fromISO(
    `${year}-${formattedMonth}-${formattedDay}`,
    { zone: 'utc' },
  );
  const startDateTime = endDateTime.minus({ year: 1 }).plus({ day: 1 });

  const simplePlantMap = finalInvoices.map((invoice) => +invoice.plantId!);
  const uniquePlants = simplePlantMap.filter((element, index) => {
    return simplePlantMap.indexOf(element) === index;
  });

  return (
    <NumberOfInvoicesDisplay
      icon={Icons.AccountingRecuring}
      text={text}
      invoiceNumber={invoices.length}
      key={`${period.start}-${invoiceCycleType}`}
    >
      <>
        <Button isLoading={loading} secondary onClick={() => openDialog()}>
          Abrechnen
        </Button>

        {modalVisible && !loading && (
          <CreateAccountingWorkflowModal
            formVariables={{
              workflowType: WorkflowType.Multiple,
              paymentPeriodStartAt: startDateTime.toJSDate(),
              paymentPeriodEndAt: endDateTime.toJSDate(),
              invoiceCycle: invoiceCycleType,
              settlementDay: +yearlySettlement.day,
              settlementMonth: +yearlySettlement.month,
              accountingTypes: [AccountingType.StandardAccounting],
              plantsToAccount: uniquePlants,
            }}
            onClose={() => setModalVisible(false)}
            innerFormType="dashboard"
            invoicesIncluded={finalInvoices}
            title={text.charAt(0).toLocaleUpperCase() + text.slice(1)}
          />
        )}
      </>
    </NumberOfInvoicesDisplay>
  );
}

export function StandardInvoicePeriodRow({
  invoiceCycleType,
  period,
  invoices,
}: {
  invoiceCycleType: InvoiceCycle;
  period: InvoicePeriod;
  invoices: StandardAccountingInvoice;
}) {
  const [modalVisible, setModalVisible] = React.useState<boolean>(false);
  const [contractIdsToLoad, setContractIdsToLoad] = React.useState<
    Array<string>
  >([]);

  const { data: contractsToAccount, loading } = useReadContractsBatchQuery({
    variables: { ids: contractIdsToLoad },
    skip: contractIdsToLoad.length === 0,
  });

  const openDialog = React.useCallback(() => {
    setContractIdsToLoad(invoices.map((invoice) => `${invoice.id}`));
    setModalVisible(true);
  }, [invoices]);

  if (invoices.length === 0) {
    return null;
  }

  let finalInvoices = invoices;
  if (contractsToAccount?.readContractsBatch.length) {
    finalInvoices = invoices.map((invoice) => {
      const contract = contractsToAccount.readContractsBatch.find(
        (_contract) => Number.parseInt(_contract.id, 10) === invoice.id,
      );

      return {
        ...invoice,
        plantId: `${contract?.plantId!}`,
      };
    });
  }
  const { start: startSettlementDate, settlement } = period;
  const { year } = DateTime.fromISO(startSettlementDate);
  const { day, month: monthWithWrongFormat } = settlement;
  let startDate;

  const month =
    monthWithWrongFormat.length < 2
      ? `0${monthWithWrongFormat}`
      : monthWithWrongFormat;

  const endDate = `${year}-${month}-${day}`;
  switch (invoiceCycleType) {
    case InvoiceCycle.Monthly:
      startDate = `${year}-${month}-01`;
      break;
    case InvoiceCycle.Quarterly:
      startDate = `${year}-${quarterlyDatesMap[month]}`;
      break;
    case InvoiceCycle.HalfYearly:
      startDate = `${year}-${halfYearlyDatesMap[month]}`;
      break;
    default:
      startDate = '';
  }
  const text = periodTexts[invoiceCycleType];
  const startDateTime = DateTime.fromISO(startDate, { zone: 'utc' });
  const endDateTime = DateTime.fromISO(endDate, { zone: 'utc' });

  const simplePlantMap = finalInvoices.map((invoice) => +invoice.plantId!);
  const uniquePlants = simplePlantMap.filter((element, index) => {
    return simplePlantMap.indexOf(element) === index;
  });
  return (
    <NumberOfInvoicesDisplay
      icon={Icons.AccountingRecuring}
      text={text}
      invoiceNumber={invoices.length}
      key={`${period.start}-${invoiceCycleType}`}
    >
      <>
        <Button isLoading={loading} secondary onClick={() => openDialog()}>
          Abrechnen
        </Button>
        {modalVisible && !loading && (
          <CreateAccountingWorkflowModal
            formVariables={{
              workflowType: WorkflowType.Multiple,
              paymentPeriodStartAt: startDateTime.toJSDate(),
              paymentPeriodEndAt: endDateTime.toJSDate(),
              invoiceCycle: invoiceCycleType,
              settlementDay: +settlement.day,
              settlementMonth: +settlement.month,
              accountingTypes: [AccountingType.StandardAccounting],
              plantsToAccount: uniquePlants,
            }}
            onClose={() => setModalVisible(false)}
            innerFormType="dashboard"
            invoicesIncluded={finalInvoices}
            title={text.charAt(0).toLocaleUpperCase() + text.slice(1)}
          />
        )}
      </>
    </NumberOfInvoicesDisplay>
  );
}

export function ClosingInvoicePeriodRow({
  invoiceCycleType,
  invoices,
}: {
  invoiceCycleType: PeriodEnum;
  invoices: ClosingAccountingInvoice;
}) {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [contractIdsToLoad, setContractIdsToLoad] = React.useState<
    Array<string>
  >([]);

  const { data: contractsToAccount, loading } = useReadContractsBatchQuery({
    variables: { ids: contractIdsToLoad },
    skip: contractIdsToLoad.length === 0,
  });

  const openDialog = React.useCallback(() => {
    setContractIdsToLoad(invoices.map((invoice) => `${invoice.id}`));
    setModalOpen(true);
  }, [invoices]);

  if (invoices.length === 0) {
    return null;
  }

  let finalInvoices = invoices;
  if (contractsToAccount?.readContractsBatch.length) {
    finalInvoices = invoices.map((invoice) => {
      const contract = contractsToAccount.readContractsBatch.find(
        (_contract) => Number.parseInt(_contract.id, 10) === invoice.id,
      );

      return {
        ...invoice,
        plantId: `${contract?.plantId!}`,
        customerName: contract?.customer.person.name!,
        customerLabel: contract?.customer.label!,
      };
    });
  }

  const text = periodTexts[invoiceCycleType];

  return (
    <NumberOfInvoicesDisplay
      icon={Icons.AccountingFinal}
      text={text}
      invoiceNumber={finalInvoices.length}
    >
      <>
        <Button isLoading={loading} secondary onClick={() => openDialog()}>
          Abrechnen
        </Button>
        <AccountingContractModal
          invoices={finalInvoices}
          open={modalOpen && !loading}
          title="Schlussrechnungen erstellen"
          onClose={() => setModalOpen(false)}
        />
      </>
    </NumberOfInvoicesDisplay>
  );
}

export function UnfinishedPeriodRow({
  invoices,
}: {
  invoices: ClosingAccountingInvoice | StandardAccountingInvoice;
}) {
  const [modalVisible, setModalVisible] = React.useState(false);
  const openDialog = React.useCallback(() => {
    setModalVisible(true);
  }, []);

  // Get unique invoices by accFlowUUID and ensure accFlowUUID is not empty
  const uniqueInvoices = React.useMemo(() => {
    const uniqueFlowIds = new Set<string>();

    return invoices.reduce<Array<{ accFlowUUID: string }>>((acc, invoice) => {
      if (
        typeof invoice.accFlowUUID === 'string' &&
        invoice.accFlowUUID !== '' &&
        !uniqueFlowIds.has(invoice.accFlowUUID)
      ) {
        uniqueFlowIds.add(invoice.accFlowUUID);
        acc.push({ accFlowUUID: invoice.accFlowUUID });
      }
      return acc;
    }, []);
  }, [invoices]);

  return (
    <NumberOfInvoicesDisplay
      icon={Icons.ResumeAccounting}
      text="laufende Abrechnungen"
      invoiceNumber={uniqueInvoices.length}
    >
      <>
        <Button isLoading={false} secondary onClick={openDialog}>
          Fortsetzen
        </Button>
        <ContinueAccountingContractModal
          invoices={uniqueInvoices}
          title="Laufende Abrechnungen fortsetzen"
          open={modalVisible}
          onClose={() => setModalVisible(false)}
        />
      </>
    </NumberOfInvoicesDisplay>
  );
}
