import * as React from 'react';
import {
  Button,
  FlexRow,
  LinkButton,
} from '@ampeersenergy/ampeers-ui-components';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import {
  ChildWorkflowResult,
  GetSupplierChangeWorkflowStateDocument,
  useUpdateSupplierChangeCancellationWorkflowMutation,
} from '../../../graphql-types';

const FlexRowSpacing = styled(FlexRow)`
  > :not([hidden]) ~ :not([hidden]) {
    margin-left: 1rem;
  }
`;

interface ChildWorkflowProps {
  workflowId: string;
  contractId: string;
  result: ChildWorkflowResult;
}

export function ChildWorkflow({
  workflowId,
  contractId,
  result,
}: ChildWorkflowProps) {
  const [isLoading, setIsLoading] = React.useState(false);

  const [updateSupplierChangeCancellation] =
    useUpdateSupplierChangeCancellationWorkflowMutation();

  const handleClick = async (cancelled: boolean) => {
    setIsLoading(true);
    await updateSupplierChangeCancellation({
      variables: {
        workflowId,
        cancelled,
        contractId,
      },
      refetchQueries: [
        {
          query: GetSupplierChangeWorkflowStateDocument,
          variables: { workflowId },
        },
      ],
    });
    setIsLoading(false);
  };

  if (result.done && !result.workflowId) {
    return <p>Vertrag bereits gekündigt.</p>;
  }

  if (result.done && result.workflowId) {
    return <p>Vertragskündigung beim alten Lieferanten abgeschlossen.</p>;
  }

  if (result.workflowId) {
    return (
      <>
        <p>Vertragskündigung beim alten Lieferanten in Bearbeitung.</p>
        <LinkButton as={Link} to={`/supplier/workflow/${result.workflowId}`}>
          Zum Kündigungsprozess
        </LinkButton>
      </>
    );
  }

  return (
    <>
      <p>Wurde der Vertrag beim alten Lieferanten bereits gekündigt?</p>
      <FlexRowSpacing>
        <Button onClick={() => handleClick(true)} disabled={isLoading}>
          Ja
        </Button>
        <Button onClick={() => handleClick(false)} disabled={isLoading}>
          Nein
        </Button>
      </FlexRowSpacing>
    </>
  );
}
