import React from 'react';
import styled from 'styled-components';

interface GlowingDotProps {
  r1: number;
  g1: number;
  b1: number;
  r2: number;
  g2: number;
  b2: number;
}

const GlowingDotBase = styled.div<GlowingDotProps>`
  width: 2.5px;
  height: 2.5px;
  border-radius: 50px;
  background: rgba(255, 255, 255, 1);
  margin: 0 2.5px 0 7.5px;
  align-self: center;
  box-shadow: ${({
    r1,
    g1,
    b1,
    r2,
    g2,
    b2,
  }) => `inset 0px 0px 5px 3px rgba(${r1},${g1},${b1}.5),
    0px 0px 5px 3px rgba(${r2},${g2},${b2},1);`};
`;

export function GlowingGreenDot() {
  return <GlowingDotBase r1={0} g1={255} b1={182} r2={0} g2={255} b2={135} />;
}
export function GlowingRedDot() {
  return <GlowingDotBase r1={234} g1={80} b1={137} r2={234} g2={76} b2={137} />;
}
export function GlowingBlackDot() {
  return <GlowingDotBase r1={0} g1={0} b1={0} r2={50} g2={50} b2={50} />;
}
