import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useFormikContext } from 'formik';

import { FlexRow, Flex, Entry, Label } from '../../components';
import { GraphqlFormField } from '../../components/graphql-form/render';
import {
  formatString,
  formatDate,
  formatBoolean,
  formatTariffNoticePeriod,
  formatTariffKind,
  formatEmgEndDates,
  formatPeriod,
  formatPriceGuaranteeReference,
} from '../../helpers/formatStrings';
import { useGraphqlForm } from '../../components/graphql-form/hooks/useGraphqlForm';
import { EMG_END_DATE } from '../../helpers/emgEndDate';

const Hidden = styled.div`
  display: none;
`;

const Spacer = styled.div`
  width: 25px;
`;
const IndentedSection = styled.div`
  margin-top: 10px;
  padding-left: 10px;
`;

const StyledLabel = styled.label`
  margin-left: 5px;
  font-size: 14px;
`;
interface EditTariffFormProps {
  dataTariff: any;
  isLoading?: boolean;
}
export default function EditTariffForm({
  dataTariff,
  isLoading,
}: EditTariffFormProps) {
  const [isFixed, setFixed] = useState(
    dataTariff?.validityEndDate !== EMG_END_DATE.toISOString(),
  );
  const { getFieldProps, setFieldValue } = useFormikContext();
  const { isEditing } = useGraphqlForm();
  useEffect(() => {
    if (
      getFieldProps('validityEndDate').value !== EMG_END_DATE.toISOString() &&
      !isFixed
    ) {
      setFixed(true);
    } else if (
      getFieldProps('validityEndDate').value === EMG_END_DATE.toISOString() &&
      isFixed
    ) {
      setFixed(false);
    }
  }, [getFieldProps, setFixed, isFixed]);
  return (
    <FlexRow>
      <Flex>
        <GraphqlFormField name="nameExternal" />
        <GraphqlFormField name="nameInternal" />
        <Entry title="Beginn der Gültigkeit" isLoading={isLoading}>
          {formatString(formatDate(dataTariff?.validityStartDate))}
        </Entry>
        {isEditing ? (
          <>
            <Label>Ende der Gültigkeit</Label>
            <input
              type="radio"
              id="validityEndDate-open"
              data-testid="validityEndDate-open"
              checked={!isFixed}
              onClick={() => {
                setFieldValue(
                  'validityEndDate',
                  EMG_END_DATE.toISOString(),
                  false,
                );
                setFixed(false);
              }}
              readOnly
            />
            <StyledLabel htmlFor="validityEndDate-open">
              Offenes Ende
            </StyledLabel>
            <br />
            <input
              type="radio"
              id="validityEndDate-fixed"
              data-testid="validityEndDate-fixed"
              checked={isFixed}
              onClick={() => {
                const isPrevDateFixed =
                  dataTariff?.validityEndDate !== EMG_END_DATE.toISOString();
                const fixedDate = isPrevDateFixed
                  ? dataTariff?.validityEndDate
                  : '';
                setFieldValue('validityEndDate', fixedDate, true);
                setFixed(true);
              }}
              readOnly
            />
            <StyledLabel htmlFor="validityEndDate-fixed">
              Festes Ende
            </StyledLabel>
            <br />
            {isFixed && (
              <GraphqlFormField name="validityEndDate" label={false} />
            )}
            {!isFixed && (
              <Hidden>
                <GraphqlFormField name="validityEndDate" label={false} />
              </Hidden>
            )}
          </>
        ) : (
          <Entry title="Ende der Gültigkeit" isLoading={isLoading}>
            {formatEmgEndDates(dataTariff?.validityEndDate)}
          </Entry>
        )}
        <Entry title="Preisgarantie" isLoading={isLoading}>
          {`${formatPeriod(dataTariff?.priceGuarantee, 'month')} ${
            dataTariff?.priceGuarantee > 0
              ? ` ab ${formatPriceGuaranteeReference(dataTariff)}`
              : ''
          }`}
        </Entry>

        <Entry title="Umsatzsteuer" isLoading={isLoading}>
          {formatBoolean(dataTariff?.tax)}
        </Entry>
        <Entry title="Stromsteuer" isLoading={isLoading}>
          {formatBoolean(dataTariff?.powerTax)}
        </Entry>
      </Flex>
      <Spacer />
      <Flex>
        <Entry title="Tarifart" isLoading={isLoading}>
          {formatTariffKind(dataTariff)}
        </Entry>
        <Label>Vertragslaufzeit Initial</Label>
        <IndentedSection>
          <Entry title="Mindestlaufzeit" isLoading={isLoading}>
            {!isLoading
              ? `${formatPeriod(dataTariff?.minimumDuration, 'month')}`
              : null}
          </Entry>
          <Entry title="Kündigungsfrist" isLoading={isLoading}>
            {!isLoading
              ? formatTariffNoticePeriod(dataTariff?.noticePeriod)
              : null}
          </Entry>
        </IndentedSection>
        <Label>Vertragslaufzeit Verlängerung</Label>
        <IndentedSection>
          <Entry title="Mindestlaufzeit" isLoading={isLoading}>
            {!isLoading
              ? `${formatPeriod(dataTariff?.minimumDurationExtension, 'month')}`
              : null}
          </Entry>
          <Entry title="Kündigungsfrist" isLoading={isLoading}>
            {!isLoading
              ? formatTariffNoticePeriod(dataTariff?.noticePeriodExtension)
              : null}
          </Entry>
        </IndentedSection>
      </Flex>
    </FlexRow>
  );
}
