import {
  Modal,
  Button,
  SpinnerDark,
} from '@ampeersenergy/ampeers-ui-components';
import * as React from 'react';
import 'styled-components/macro'; // Needed to use css prop
import { useHistory } from 'react-router';

import { useGetAccountingRunWorkflowsByUuidsQuery } from '../../../graphql-types';

import {
  Wrapper,
  ContractRow,
  ContractRowContent,
  BaseAccountingModalProps,
} from './AccountingContractModal';

interface ContinueAccountingContractModalProps
  extends BaseAccountingModalProps {
  invoices: { accFlowUUID: string }[];
}

export function ContinueAccountingContractModal({
  invoices,
  open,
  title,
  onClose,
}: ContinueAccountingContractModalProps) {
  const history = useHistory();
  const uuids = invoices.map((invoice) => invoice.accFlowUUID);

  const rest = useGetAccountingRunWorkflowsByUuidsQuery({
    variables: {
      uuids,
    },
    skip: !open,
  });

  const { data, loading } = rest;

  const workflowRuns = data?.getAccountingRunWorkflowsByUuids.data ?? [];

  return (
    <Modal
      isOpen={open}
      title={title}
      onRequestClose={onClose}
      contentLabel="accounting-invoice-modal"
      minWidth={600}
    >
      <Wrapper>
        {loading ? (
          <ContractRow>
            <div
              css={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <SpinnerDark size={20} />
            </div>
          </ContractRow>
        ) : (
          workflowRuns.map((workflowRun) => {
            const isDisabled = !workflowRun.WorkflowId;

            return (
              <ContractRow key={`${workflowRun.WorkflowId}`}>
                <ContractRowContent>
                  {workflowRun.AccountingRunName}
                  <Button
                    secondary
                    isLoading={!isDisabled && loading}
                    onClick={() => {
                      history.push(
                        `/accounting/runs/${workflowRun.WorkflowId}`,
                      );
                    }}
                    disabled={isDisabled}
                  >
                    Fortsetzen
                  </Button>
                </ContractRowContent>
              </ContractRow>
            );
          })
        )}
      </Wrapper>
    </Modal>
  );
}
