import {
  AlertRetryable,
  Button,
  Icons,
  SpinnerDark,
  SubTitle,
} from '@ampeersenergy/ampeers-ui-components';
import { err, Result } from 'neverthrow';
import React, { useEffect, useState } from 'react';

import { DeliveryPreference } from '../../../graphql-types';
import { Center } from '../style';
import { CreationError } from '../types';

export function FinalStep({
  type,
  deliveryPreference,
  onClose,
  onCreate,
}: {
  type: string;
  deliveryPreference: DeliveryPreference;
  onClose: () => void;
  onCreate: () => Promise<Result<boolean, CreationError>>;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState<Result<boolean, CreationError> | null>(
    null,
  );

  useEffect(() => {
    const work = async () => {
      setIsLoading(true);

      try {
        const res = await onCreate();
        setResult(res);
      } catch (error) {
        setResult(
          err({
            reason: (error as Error).toString(),
          }),
        );
      }
      setIsLoading(false);
    };

    work();
  }, [onCreate]);

  return (
    <>
      {isLoading && (
        <Center>
          <SpinnerDark size={20} /> Die Dokumente werden im Hintergrund
          erstellt…
        </Center>
      )}
      {result?.isOk() && (
        <Center>
          <div>
            <Icons.Checkmark size={60} color="#A8E015" />
          </div>
          <SubTitle>Et voilà!</SubTitle>
          {type} wurde erzeugt
          {deliveryPreference !== DeliveryPreference.Browser
            ? ' und verschickt'
            : ''}
          .
          <Button secondary onClick={onClose}>
            Schließen
          </Button>
        </Center>
      )}
      {result?.isErr() && (
        <AlertRetryable
          title="Fehler beim Erstellen"
          message={result.error.reason}
        />
      )}
    </>
  );
}
