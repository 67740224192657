import {
  LoginLayout,
  ResetPassword,
} from '@ampeersenergy/ampeers-ui-components';
import React, { useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import ProductLogo from '../../assets/local-supply.png';
import { DocTitle } from '../../components/docTitle';
import {
  useCheckResetPasswordTokenMutation,
  useResetPasswordMutation,
} from '../../graphql-types';
import { extractErrorMessage } from '../../helpers/extractErrorMessage';

function ResetPasswordPage() {
  const history = useHistory();
  const params = new URLSearchParams(useLocation().search);
  const token = params.get('token');
  const email = params.get('email');

  const returnToLogin = useCallback(
    () => history.replace({ pathname: '/' }),
    [history],
  );

  const [
    resetPassword,
    {
      data: resetPasswordData,
      error: resetPasswordError,
      loading: resetPasswordLoading,
    },
  ] = useResetPasswordMutation();

  const [checkToken, { loading: checkTokenLoading, error: checkTokenError }] =
    useCheckResetPasswordTokenMutation();

  useEffect(() => {
    if (typeof token !== 'string') {
      return;
    }

    checkToken({
      variables: {
        token,
      },
    });
  }, [checkToken, token]);

  return (
    <>
      <DocTitle titleParts={['Passwort zurücksetzen']} />
      <LoginLayout ProductLogo={ProductLogo}>
        <ResetPassword
          token={token}
          email={email}
          resetPassword={({ password }: { password: string }) =>
            resetPassword({
              variables: { password, email: email ?? '', token: token ?? '' },
            }) as any
          }
          checkTokenLoading={checkTokenLoading}
          resetPasswordLoading={resetPasswordLoading}
          isSuccess={
            resetPasswordData?.resetPassword?.__typename ===
              'ResetPasswordSuccess' && resetPasswordData.resetPassword.success
          }
          errorMessage={
            (checkTokenError ?? resetPasswordError) &&
            extractErrorMessage(checkTokenError ?? resetPasswordError)
          }
          onSuccess={returnToLogin}
          onCancel={returnToLogin}
        />
      </LoginLayout>
    </>
  );
}

export default ResetPasswordPage;
