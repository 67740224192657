import React, { useCallback } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import {
  AlertRetryable,
  useDialog,
} from '@ampeersenergy/ampeers-ui-components';
import { FormikHelpers } from 'formik';

import { Entry, Flex, FlexRow, PaddedShadowBox } from '../../../../components';
import GraphqlForm from '../../../../components/graphql-form';
import { GraphqlFormField } from '../../../../components/graphql-form/render';
import {
  Metering,
  ReadMeterDocument,
  ReadMeterQuery,
  UpdateMeterInput,
  useReadMeterReadingsQuery,
  useReadPlantMetersQuery,
} from '../../../../graphql-types';
import { formatString } from '../../../../helpers/formatStrings';
import { isValidMeterNumber } from '../../../../helpers/validateStrings';
import yupMalo from '../../../../helpers/validators/malo';
import { formatValueOfType } from '../../../../components/graphql-form/mapper';
import {
  METER_READING_END_DATE,
  METER_READING_START_DATE,
} from '../../../../helpers/constants';
import yupMelo from '../../../../helpers/validators/melo';

const Spacer = styled.div`
  width: 25px;
`;

export function MeterInfo({
  meter,
  loading,
}: {
  meter: ReadMeterQuery['readMeter'];
  loading: boolean;
}) {
  const {
    data: dataReadPlantMeters,
    loading: loadingReadPlantMeters,
    refetch: refetchMetersQuery,
  } = useReadPlantMetersQuery({
    variables: {
      plantId: String(meter?.plantId!),
    },
    skip: loading,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const { data: readings, error } = useReadMeterReadingsQuery({
    variables: {
      meterId: meter?.id!,
      startDate: new Date(METER_READING_START_DATE).toISOString(),
      endDate: new Date(METER_READING_END_DATE).toISOString(),
      metering: meter?.metering === Metering.Slp ? Metering.Slp : Metering.Rlm,
    },
    skip: meter === undefined,
    fetchPolicy: 'cache-and-network',
  });

  const { openConfirmation } = useDialog();

  const joinMeters = [
    ...(dataReadPlantMeters?.readPlantMeters?.matchedBuildingMeters ?? []),
    ...(dataReadPlantMeters?.readPlantMeters?.matchedTenantMeters ?? []),
    ...(dataReadPlantMeters?.readPlantMeters?.unmatchedMeters ?? []),
  ];

  const contract = joinMeters.find((obj) => {
    return obj.id === meter?.id;
  });

  const inUseMeterNumbers =
    joinMeters
      .filter((m) => m.id !== meter?.id)
      .map(({ meterNumber }) => meterNumber) ?? [];

  const onBeforeSubmitMeter = useCallback(
    async (formValues: any, formikHelpers: FormikHelpers<any>) => {
      const converterFactorChanged =
        meter?.converterFactor !== undefined &&
        meter?.converterFactor !== null &&
        formValues?.converterFactor !== undefined &&
        `${meter.converterFactor}` !== `${formValues.converterFactor}`;

      const hasMeterReadings = !!readings?.readMeterReadings.length;

      if (hasMeterReadings && converterFactorChanged) {
        return openConfirmation({
          confirmButtonLabel: 'Speichern',
          content: (
            <>
              Vorsicht: Ein Ändern des Wandlerfaktors kann unter bestimmten
              Umständen Inkonsistenzen bei vergangenen Abrechnungen zur Folge
              haben:
              <br />
              <ul>
                <li>
                  Falls schon Abrechnungen in der Kundenanlage des Zählers
                  durchgeführt wurden, würde sich die Autarkie auf diesen
                  Rechnungen bei erneuter Abrechnung ändern.
                </li>
                <li>
                  Falls der Zähler einem Vertrag zugeordnet ist, dessen Tarif
                  einen geteilten Arbeitspreis enthält und bereits Rechnungen
                  gestellt wurden, würde sich der in Rechnung gestellte Betrag
                  bei erneuter Abrechnung ändern.
                </li>
              </ul>
              Wir empfehlen vergangene Abrechnungen und dazugehörende
              Zählerstände nach dem Ändern des Wandlerfaktors zu überprüfen.
            </>
          ),
          title: 'Wandlerfaktor geändert',
          catchOnCancel: true,
        })
          .then(() => formValues)
          .catch(() => {
            // when user cancels submit, reset the converterFactor in the form but keep every other change
            formikHelpers.setFieldValue(
              'converterFactor',
              meter?.converterFactor,
            );
            return null;
          });
      }

      return formValues;
    },
    [meter, readings, openConfirmation],
  );

  return (
    <PaddedShadowBox>
      {error && <AlertRetryable error={error} />}
      <GraphqlForm
        values={{
          ...meter,
          maloId: contract?.malo ?? '',
          melo: contract?.melo ?? '',
        }}
        mutation="updateMeter"
        isLoading={loading || loadingReadPlantMeters}
        disableEdit={false}
        onSuccess={() => {
          refetchMetersQuery();
        }}
        variables={{
          meterId: meter?.id,
          plantId: meter?.plantId,
        }}
        formatBeforeSubmit={(updateMeterValue: UpdateMeterInput) => {
          return {
            ...updateMeterValue,
            oldMalo: contract?.malo,
            oldMelo: meter?.melo,
          };
        }}
        refetchQueries={[
          {
            query: ReadMeterDocument,
            variables: {
              id: meter?.id,
            },
          },
        ]}
        validation={{
          meterNumber: yup
            .mixed()
            .notOneOf(
              [...inUseMeterNumbers],
              'Es gibt bereits einen Zähler mit dieser Zählernummer',
            )
            .test(
              'meterNumber name validity',
              'Ungültiges Format',
              isValidMeterNumber,
            )
            .required(),
          converterFactor: yup
            .number()
            .min(1)
            .integer('Muss eine natürliche Zahl sein.')
            .required(),
          maloId: yup.string().test(
            'malo-is-changed-validation',
            'Die Malo-Id ist nicht korrekt. Bitte überprüfe deine Eingabe.“',
            // eslint-disable-next-line func-names
            function (malo) {
              if (malo && contract?.malo && malo !== contract?.malo) {
                return yupMalo
                  .validate(malo)
                  .then(() => true)
                  .catch((validateError) => {
                    if (validateError instanceof yup.ValidationError) {
                      return this.createError({
                        path: validateError.path,
                        message: validateError.message,
                      });
                    }
                    return false;
                  });
              }

              return true;
            },
          ),
          melo: yup.string().test(
            'melo-is-changed-validation',
            'Die Melo-Id ist nicht korrekt. Bitte überprüfe deine Eingabe.“',
            // eslint-disable-next-line func-names
            async function (melo) {
              if (melo && contract?.melo && melo !== contract?.melo) {
                return yupMelo
                  .validate(melo)
                  .then(() => true)
                  .catch((validateError) => {
                    if (validateError instanceof yup.ValidationError) {
                      return this.createError({
                        path: validateError.path,
                        message: validateError.message,
                      });
                    }
                    return false;
                  });
              }

              return true;
            },
          ),
        }}
        onBeforeSubmit={onBeforeSubmitMeter}
      >
        <FlexRow>
          <Flex>
            <GraphqlFormField name="meterNumber" />
            <GraphqlFormField name="meterPlace" />
            {!loading && meter?.wasEverBound === true ? (
              <>
                <Entry title="Zählertyp" isLoading={loading}>
                  {formatString(meter?.meterType)}
                </Entry>
                <GraphqlFormField
                  name="converterFactor"
                  formatValue={(value: string) =>
                    formatValueOfType('Int', value)
                  }
                />
                <Entry title="Messart" isLoading={loading}>
                  {formatString(meter?.metering)}
                </Entry>
                <Spacer />
              </>
            ) : (
              <>
                <GraphqlFormField name="meterType" />
                <GraphqlFormField
                  name="converterFactor"
                  formatValue={(value: string) =>
                    formatValueOfType('Int', value)
                  }
                />
                <GraphqlFormField name="metering" />
              </>
            )}
          </Flex>
          <Spacer />
          <Flex>
            <GraphqlFormField
              label="Malo-Id"
              name="maloId"
              placeholder={
                contract?.malo
                  ? 'Malo-Id'
                  : 'Die Malo-Id kann nicht geändert werden, da der Zähler bisher keinem Vertrag zugeordnet war.'
              }
              disabled={!contract?.malo}
            />
            <GraphqlFormField
              label="Melo-Id"
              name="melo"
              placeholder={
                contract?.melo
                  ? 'Melo-Id'
                  : 'Die Melo-Id kann nicht geändert werden, da der Zähler bisher keinem Vertrag zugeordnet war.'
              }
              disabled={!contract?.melo}
            />
          </Flex>
        </FlexRow>
      </GraphqlForm>
    </PaddedShadowBox>
  );
}
