import gql from 'graphql-tag';

export default gql`
  mutation createMeterReadingsBatched(
    $meterReadings: [CreateMeterReadingsBatchedInput!]!
  ) {
    createMeterReadingsBatched(meterReadings: $meterReadings) {
      __typename
      ... on CreateMeterReadingsBatchedMixedResult {
        succeeded {
          id
          meterNumber
          value
          date
        }
        failed {
          id
          meterNumber
          error
          value
          date
        }
      }
      ... on DuplicateMeterReadingsError {
        message
        pointer
        retryable
        duplicateReadings {
          id
          meterNumber
          date
          value
        }
        multipleReadings {
          id
          meterNumber
          date
          value
        }
      }
    }
  }
`;
