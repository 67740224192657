import { useApolloClient } from '@apollo/client';
import { get, merge } from 'lodash';
import { useEffect, useState } from 'react';

import annotationQuery from '../queries/annotationQuery';
import introspectionQuery from '../queries/introspectionQuery';
import { buildSchema, findNestedInputTypes, getMutationType } from '../schema';

import { GraphqlFormContextType } from './useGraphqlForm';
import {
  GraphqlFormFieldIndentifier,
  GraphqlFormFieldInternalIdentifier,
  GraphqlFormFieldProps,
} from './useGraphqlFormField';

interface IntrospectionState {
  isLoading: boolean;
  initialValues?: { [key: string]: any };
  defaultValues?: { [key: string]: any };
  validationSchema?: { [key: string]: any };
  fieldProps?: Map<string, any>;
  fields?: string[];
  mutationSource?: string;
  schema?: any;
  getFieldProps: GraphqlFormContextType['getFieldProps'];
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const noop = (fieldName: string) => undefined;

export const useIntrospection = (mutationName: string, values?: any) => {
  const client = useApolloClient();
  const [result, setResult] = useState<IntrospectionState>({
    isLoading: true,
    getFieldProps: noop,
  });

  useEffect(() => {
    (async () => {
      const { data } = await client.query({ query: introspectionQuery });
      const schema = data.__schema;

      /**
       * find our mutation with mutationName
       */
      const mutation = getMutationType(schema, mutationName);

      if (!mutation) {
        throw new Error(`No Mutation named: '${mutationName}' found`);
      }

      /**
       * find all (nested) input objects for that mutation
       */
      const inputTypeNames = findNestedInputTypes(schema, mutation);

      if (inputTypeNames.size === 0) {
        throw new Error(`Mutation ${mutationName} expects no inputs`);
      }

      /**
       * load the annotation data for that input object
       */
      const { data: annotationData } = await client.query({
        query: annotationQuery,
        variables: {
          types: Array.from(inputTypeNames),
        },
      });

      /**
       * build our form schema with all the fields derived of
       * the servers graphql schema
       */
      const {
        validationSchema,
        fieldProps,
        initialValues,
        defaultValues,
        fields,
      } = buildSchema(
        mutation,
        schema,
        annotationData.getAnnotations ? annotationData.getAnnotations : [],
      );

      const mergedValues = merge({}, initialValues, defaultValues);

      const getFieldProps = (
        originalName: string,
      ):
        | (GraphqlFormFieldProps &
            GraphqlFormFieldIndentifier &
            GraphqlFormFieldInternalIdentifier)
        | undefined => {
        if (!fieldProps) {
          throw new Error();
        }

        /**
         * replace all array indices with 0
         */
        const fieldName = originalName.replace(/\[(.+?)\]/g, '[0]');

        if (!fieldProps.has(fieldName)) {
          throw new Error(
            `Trying to render unknown field: '${fieldName}'. Available fields are: ${Array.from(
              fieldProps.keys(),
            ).join(', ')}`,
          );
        }

        const { path, ...props } = fieldProps.get(fieldName);

        if (!props) {
          return undefined;
        }

        return {
          ...props,
          name: originalName,
          id: originalName,
          value: values
            ? get(values, props.relation ? path : fieldName)
            : get(mergedValues, originalName),
        };
      };

      setResult({
        isLoading: false,
        validationSchema,
        fieldProps,
        initialValues,
        defaultValues,
        fields,
        schema,
        getFieldProps,
      });
    })();
  }, [client, mutationName, values]);

  return result;
};
