import {
  ErrorBox,
  Input,
  Label,
  Select,
} from '@ampeersenergy/ampeers-ui-components';
import { useFormikContext } from 'formik';
import { DateTime } from 'luxon';
import React from 'react';

import {
  GraphqlFormInputGroup,
  GraphqlFormSelect,
} from '../../../components/graphql-form/render';
import { TooltipInfo } from '../../../components/TooltipInfo';

import { FormVariablesAccountingWorkflow } from './types';
import { getAccountableYears } from './helpers';

const defaultSettlementDay = 31;
const defaultSettlementMonth = 12;

export default function YearPeriodForm({
  firstYearAvailable,
  year,
  setYear,
}: {
  firstYearAvailable: number;
  year: number | null;
  setYear: (val: number) => void;
}) {
  const { setFieldValue, values, errors, touched } =
    useFormikContext<FormVariablesAccountingWorkflow>();
  const { settlementDay, settlementMonth, contractStartDate, workflowType } =
    values;

  const isCustomSettlementDate =
    (!!settlementDay && +settlementDay !== defaultSettlementDay) ||
    (!!settlementMonth && +settlementMonth !== defaultSettlementMonth);

  // TODO: Fix weird behavior with graphqlForms.
  // IMPORTANT: keep values on the dependencies, it has been observed that graphqlForm sets
  // default values (formikInitialValues) after this useEffect has been triggered.
  // So, to prevent that the values that we set here are resetted, we need to trigger the useEffect every time values is executed.
  React.useEffect(() => {
    if (year && settlementMonth && settlementDay) {
      const endDate = DateTime.fromObject({
        year,
        month: settlementMonth,
        day: settlementDay,
      });
      const startDate = endDate.plus({ day: 1 }).minus({ year: 1 });

      setFieldValue('paymentPeriodStartAt', startDate.toISODate());
      setFieldValue('paymentPeriodEndAt', endDate.toISODate());
    }
  }, [year, settlementMonth, settlementDay, setFieldValue, values]);

  const yearNotSelectedError =
    !year && (touched.paymentPeriodStartAt || touched.paymentPeriodEndAt)
      ? errors.paymentPeriodEndAt || errors.paymentPeriodStartAt
      : null;

  return (
    <div>
      {isCustomSettlementDate && year && (
        <Input
          id="startYear"
          disabled
          label="Beginn der Abrechnungsperiode"
          value={year ? year - 1 : ''}
        />
      )}
      <Select
        id="year"
        label={isCustomSettlementDate ? 'Ende der Abrechnungsperiode' : 'Jahr'}
        value={year?.toString() || ''}
        onChange={(e) => setYear(+e.target.value)}
        inputClassName={`${yearNotSelectedError && 'touched error'}`}
      >
        <option disabled hidden value="">
          Bitte wählen
        </option>
        {getAccountableYears({
          firstYearAvailable,
          settlementDay,
          settlementMonth,
          contractStartDate,
        })
          .map((value) => (
            <option key={`year-${value}`} value={value}>
              {value}
            </option>
          ))
          .reverse()}
      </Select>
      <ErrorBox>{yearNotSelectedError}</ErrorBox>
      <Label>
        Abrechnungsstichtag{' '}
        <TooltipInfo
          id="settlementDate"
          text="Nur Mieter- und Gebäudeverträge mit diesem Abrechnungsstichtag werden abgerechnet."
        />
      </Label>
      <GraphqlFormInputGroup>
        <GraphqlFormSelect
          name="settlementDay"
          hint="Tag"
          disabled={workflowType === 'single'}
        >
          <option disabled hidden value="">
            Bitte wählen
          </option>
          {new Array(31)
            .fill(null)
            .map((_, index) => (
              <option key={`day-${index + 1}`} value={index + 1}>
                {index + 1}
              </option>
            ))
            .reverse()}
        </GraphqlFormSelect>
        <GraphqlFormSelect
          name="settlementMonth"
          hint="Monat"
          disabled={workflowType === 'single'}
        >
          <option disabled hidden value="">
            Bitte wählen
          </option>
          {new Array(12)
            .fill(null)
            .map((_, index) => (
              <option key={`month-${index + 1}`} value={index + 1}>
                {index + 1}
              </option>
            ))
            .reverse()}
        </GraphqlFormSelect>
      </GraphqlFormInputGroup>
    </div>
  );
}
