import React, { useState } from 'react';
import { Formik } from 'formik';
import { Button } from '@ampeersenergy/ampeers-ui-components';

import { EditContainerProps } from '../../../../../components/createFlow';
import { ReadContractQuery } from '../../../../../graphql-types';
import { Flex, FlexRow } from '../../../../../components';
import { WrappedDropdown } from '../../../../../components/input';

import EditContractTariffsComponent from './edit';
import ReplaceTariff, { CurrentTariffType } from './replaceTariff';

enum TariffActions {
  ChangeExistingTariff = 'changeExistingTariff',
  NewTariff = 'newTariff',
}

interface MainPanelProps extends EditContainerProps {
  setDate: any;
  latestStartDate: string;
  meterReadingDate: string;
  contract: ReadContractQuery['readContract'];
  currentTariff?: CurrentTariffType;
}

function MainPanel({
  setDate,
  onSuccess,
  onAbort,
  latestStartDate,
  meterReadingDate,
  contract,
  currentTariff,
}: MainPanelProps) {
  const [action, setAction] = useState<TariffActions | undefined>();

  return (
    <Formik initialValues={{}} onSubmit={() => {}} onAbort={onAbort}>
      <>
        <FlexRow>
          <Flex>
            <WrappedDropdown
              id="tariffDropdownAction"
              name="tariffDropdownAction"
              type="dropdown"
              value={action}
              label="Aktion auswählen"
              labels={
                currentTariff
                  ? [ChangeTariffLabel, NewTariffLabel]
                  : [NewTariffLabel]
              }
              values={
                currentTariff
                  ? [
                      TariffActions.ChangeExistingTariff,
                      TariffActions.NewTariff,
                    ]
                  : [TariffActions.NewTariff]
              }
              onChange={(e) => setAction(e.target.value)}
            />
          </Flex>
          <Flex />
        </FlexRow>
        {action === TariffActions.NewTariff ? (
          <EditContractTariffsComponent
            setDate={setDate}
            currentTariff={currentTariff}
            contract={contract}
            meterReadingDate={meterReadingDate}
            latestStartDate={latestStartDate}
            onSuccess={onSuccess}
            onAbort={onAbort}
          />
        ) : null}
        {action === TariffActions.ChangeExistingTariff && currentTariff ? (
          <ReplaceTariff
            contract={contract}
            currentTariff={currentTariff}
            onSuccess={onSuccess}
            onAbort={onAbort}
          />
        ) : null}
        {!action && (
          <Button
            type="reset"
            secondary
            css="margin-top: 1rem;"
            onClick={onAbort}
          >
            Abbrechen
          </Button>
        )}
      </>
    </Formik>
  );
}

const NewTariffLabel = {
  description:
    'Damit kann dem Kunden ein neuer Tarif, z.B. mit neuen Konditionen o.ä., zugewiesen werden. In diesem Fall müssen Sie einen Gültigkeitsbeginn und aktuellen Zählerstand angeben.',
  title: 'Tarifwechsel',
};

const ChangeTariffLabel = {
  description:
    'Damit können Sie rückwirkend den Tarif austauschen. Dies ist zum Beispiel der Fall, wenn Sie versehentlich beim Anlegen einen falschen Tarif gewählt haben.',
  title: 'Tarifaustausch',
};

export default MainPanel;
