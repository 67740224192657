import React, { useState } from 'react';
import {
  Bold,
  Flex,
  FlexRow,
  ShadowBox,
} from '@ampeersenergy/ampeers-ui-components';
import styled from 'styled-components';

import { formatDate } from '../helpers/formatStrings';

import * as Icons from './icons';
import { Entry } from './layout';

const HistoricOverlay = styled(ShadowBox)<{ hasEndDate: boolean }>`
  position: absolute;
  top: 100%;
  margin-left: -5px;
  border-width: 5px;
  z-index: 2;
  width: ${(props) => (props.hasEndDate ? '290px' : '240px')};
  &:hover {
    color: #000;
  }

  ul {
    list-style-type: none;
    padding: 0px;
    margin: 0px 0px 0px 0px;
    font-size: 15px;
  }
  li.withValues {
    border-bottom: 1px solid #eaeaea;
    padding: 3px 0px 3px 10px;
  }
  li.withValues.last {
    border-bottom: none;
  }

  .withoutValues {
    text-align: center;
    width: 240px;
  }

  .value {
    max-width: 90px;
  }
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
  supported by Chrome, Edge, Opera and Firefox */
`;

const CurrentValueStyled = styled.div<{ hasEndDate: boolean }>`
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
  cursor: pointer;
  height: 22;
  &:hover {
    color: #000;

    svg {
      fill: ${(props) => props.theme.primaryColor};
    }
  }

  &.active {
    border-bottom: 2px solid ${(props) => props.theme.primaryColor};
    color: ${(props) => props.theme.primaryColor};
    font-weight: 600;
  }

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
  supported by Chrome, Edge, Opera and Firefox */
`;

const HistoricEntryWrap = styled.div`
  position: relative;
`;

const IconWrapper = styled.div`
  position: absolute;
  bottom: -1px;
  left: 0;
  padding: 1;
  border-radius: 4px;
  display: inline-block !important;
  cursor: pointer;
  width: 15x;
  height: 15px;
  // overflow: hidden;
  background: ${(props) => props.theme.palette.backgroundMuted};
  &:hover {
    svg {
      fill: ${(props) => props.theme.primaryColor};
    }
  }
`;

const AlignTextWithIcon = styled.div`
  display: inline-block !important;
  position: relative;
  margin-left: 10px;
`;

const Headline = styled(Bold)`
  margin-top: 10px;
  font-size: 15px;
`;

interface HistoricEntryProps<T> {
  values: Array<{
    value: T;
    date: {
      startDate: string;
      endDate?: string;
    };
  }>;
  title: string;
  isLoading: boolean;
  formatValue: (value: T) => string;
}
export default function HistoricEntry<T>(props: HistoricEntryProps<T>) {
  const { title, isLoading, values, formatValue } = props;
  const [historyVisible, setHistoryVisible] = useState(false);

  const currentValues = values[0] ?? {};
  let currentDateFormatted;
  let currentValue;
  let hasEndDate = false;
  if (values.length !== 0) {
    hasEndDate = currentValues.date.endDate !== undefined;
    currentDateFormatted = !hasEndDate
      ? `seit ${formatDate(currentValues.date.startDate)}`
      : `${formatDate(currentValues.date.startDate)} - ${formatDate(
          currentValues.date.endDate,
        )}`;
    currentValue = `${formatValue(
      currentValues.value,
    )} \u00A0\u00A0\u00A0 ${currentDateFormatted}`;
  } else {
    currentValue = '--';
  }

  const historicValues = values.slice(1);
  const historicRows =
    historicValues.length !== 0
      ? historicValues.map((row: any, index: number) => {
          const downPaymentStartDateFormatted = formatDate(row.date.startDate);
          return (
            <li
              className="withValues"
              key={`${index} - ${row.value} - ${row.date.startDate}`}
            >
              <FlexRow>
                <Flex className="value">{`${formatValue(row.value).replace(
                  /\s/,
                  '',
                )}`}</Flex>
                {row.date.endDate === undefined ? (
                  <Flex>{`seit ${downPaymentStartDateFormatted}`}</Flex>
                ) : (
                  <Flex>{`${formatDate(row.date.startDate)} - ${formatDate(
                    row.date.endDate,
                  )}`}</Flex>
                )}
              </FlexRow>
            </li>
          );
        })
      : [<li className="withoutValues">Kein Verlauf</li>];

  return (
    <HistoricEntryWrap>
      <Headline>{title}</Headline>
      {historyVisible && (
        <HistoricOverlay hasEndDate={hasEndDate}>
          <ul>{historicRows}</ul>
        </HistoricOverlay>
      )}
      {values.length !== 0 && (
        <CurrentValueStyled
          hasEndDate={hasEndDate}
          onClick={() => setHistoryVisible(!historyVisible)}
        >
          <Entry title={null} isLoading={isLoading}>
            {currentValue}
            {!historyVisible && !isLoading && (
              <AlignTextWithIcon>
                <IconWrapper>
                  <Icons.MiniChevronDown size={16} />
                </IconWrapper>
              </AlignTextWithIcon>
            )}
            {historyVisible && !isLoading && (
              <AlignTextWithIcon>
                <IconWrapper>
                  <Icons.MiniChevronUp size={16} />
                </IconWrapper>{' '}
              </AlignTextWithIcon>
            )}
          </Entry>
        </CurrentValueStyled>
      )}
      {values.length === 0 && (
        <Entry title={null} isLoading={isLoading}>
          {currentValue}
        </Entry>
      )}
    </HistoricEntryWrap>
  );
}
