/* eslint-disable no-console */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import styled from 'styled-components';

interface ErrorBoundaryProps {}

interface ErrorBoundaryState {
  hasError: boolean;
  error?: Error;
}

const ErrorMsg = styled.div`
  background: #fff;
  font-size: 13px;
  padding: 5px 20px;
  border-radius: 5px;
  color: #f95d23;
  border: 1px solid #e6754b;
`;

export default class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.log(error);
    console.log(errorInfo);
    // log error here
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <ErrorMsg>
          <h3>Ein Fehler ist aufgetretten.</h3>
          <pre>{this.state.error ? this.state.error.toString() : ''}</pre>
        </ErrorMsg>
      );
    }

    return this.props.children;
  }
}
