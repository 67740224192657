import { ErrorBox, Label, Select } from '@ampeersenergy/ampeers-ui-components';
import { useFormikContext } from 'formik';
import { DateTime } from 'luxon';
import React from 'react';
import styled from 'styled-components';

import { GraphqlFormSelect } from '../../../components/graphql-form/render';
import { InvoiceCycle } from '../../../graphql-types';

import {
  getSubstractPeriod,
  halfYearlyPeriods,
  quarterlyPeriods,
  yearsUntilContractStart,
} from './helpers';
import { FormVariablesAccountingWorkflow } from './types';

const StandardAccountingOptions = styled.div`
  display: flex;
  gap: 20px;
`;

const AccountingOption = styled.div`
  width: 100%;
`;

const MonthlyLabel = styled(Label)`
  margin-bottom: 5px;
`;

export default function MultiPeriodForm({
  firstYearAvailable,
  year,
  setYear,
}: {
  firstYearAvailable: number;
  year: number | null;
  setYear: (val: number) => void;
}) {
  const { setFieldValue, values, errors, touched } =
    useFormikContext<FormVariablesAccountingWorkflow>();
  const { invoiceCycle, settlementMonth } = values;

  React.useEffect(() => {
    if (settlementMonth) {
      const settlementMonthDatetime = DateTime.fromObject({
        month: settlementMonth,
      });
      setFieldValue('settlementDay', settlementMonthDatetime.daysInMonth);
    }
  }, [settlementMonth, setFieldValue]);

  // TODO: Fix weird behavior with graphqlForms.
  // IMPORTANT: keep values on the dependencies, it has been observed that graphqlForm sets
  // default values (formikInitialValues) after this useEffect has been triggered.
  // So, to prevent that the values that we set here are resetted, we need to trigger the useEffect every time values is executed.
  // Detected in YearPeriodForm, added here for prevention
  React.useEffect(() => {
    if (year && settlementMonth && invoiceCycle) {
      const endDate = DateTime.fromObject({
        year,
        month: settlementMonth,
      }).endOf('month');
      const substractPeriod = getSubstractPeriod(invoiceCycle);

      const dateSubstracted = endDate.minus(substractPeriod);

      const startDate = DateTime.fromObject({
        day: 1,
        year,
        month: dateSubstracted.plus({ month: 1 }).month,
      });

      setFieldValue('paymentPeriodStartAt', startDate.toISODate());
      setFieldValue('paymentPeriodEndAt', endDate.toISODate());
    }
  }, [year, invoiceCycle, settlementMonth, setFieldValue, values]);
  const yearNotSelectedError =
    !year && (touched.paymentPeriodStartAt || touched.paymentPeriodEndAt)
      ? errors.paymentPeriodEndAt || errors.paymentPeriodStartAt
      : null;

  return (
    <StandardAccountingOptions>
      <AccountingOption>
        <MonthlyLabel>Monat</MonthlyLabel>
        <GraphqlFormSelect name="settlementMonth" value="">
          <option disabled value="">
            Bitte wählen
          </option>
          {invoiceCycle === InvoiceCycle.Monthly &&
            new Array(12).fill(null).map((_, index) => (
              <option key={`period-${index + 1}`} value={index + 1}>
                {DateTime.fromObject({ month: index + 1 }).monthLong}
              </option>
            ))}
          {invoiceCycle === InvoiceCycle.Quarterly &&
            quarterlyPeriods.map((period) => {
              const [key, value] = Object.entries(period)[0];
              return (
                <option key={`period-${value}`} value={value}>
                  {key}
                </option>
              );
            })}
          {invoiceCycle === InvoiceCycle.HalfYearly &&
            halfYearlyPeriods.map((period) => {
              const [key, value] = Object.entries(period)[0];

              return (
                <option key={`period-${value}`} value={value}>
                  {key}
                </option>
              );
            })}
        </GraphqlFormSelect>
      </AccountingOption>
      <AccountingOption>
        <Select
          id="year"
          label="Jahr"
          value={year?.toString() || ''}
          onChange={(e) => setYear(+e.target.value)}
          inputClassName={`${yearNotSelectedError && 'touched error'}`}
        >
          <option disabled hidden value="">
            Bitte wählen
          </option>
          {yearsUntilContractStart(firstYearAvailable)
            .map((value) => (
              <option key={`year-${value}`} value={value}>
                {value}
              </option>
            ))
            .reverse()}
        </Select>
        <ErrorBox>{yearNotSelectedError}</ErrorBox>
      </AccountingOption>
    </StandardAccountingOptions>
  );
}
