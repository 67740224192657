import * as React from 'react';
import { SubTitle } from '@ampeersenergy/ampeers-ui-components';

import { ReadWorkspacesQuery } from '../../../graphql-types';

import { WorkspaceSelection } from './WorkspaceSelection';

export type Workspace = ReadWorkspacesQuery['readWorkspaces'][0];

interface WorkspaceSettingsFormProps {
  workspaces?: Workspace[];
}

export function WorkspaceSettingsForm({
  workspaces,
}: WorkspaceSettingsFormProps) {
  if (!workspaces || workspaces.length <= 1) {
    return null;
  }

  return (
    <>
      <p>
        Hier kannst Du einzelne Adress- und Kontaktinformationen spezifisch für
        jeden Vertragstypen überschreiben. Felder, die nicht ausgefüllt wurden,
        werden automatisch mit den Firmeninformationen angereichert.
      </p>
      {workspaces.map((workspace, i) => {
        return (
          <React.Fragment key={workspace.id}>
            <SubTitle>{workspace.name}</SubTitle>
            <WorkspaceSelection
              id={workspace.id}
              prefix={`workspaces.[${i}]`}
            />
          </React.Fragment>
        );
      })}
    </>
  );
}
