import * as React from 'react';
import styled from 'styled-components';
import {
  Flex,
  FlexRow,
  PaddedShadowBox,
} from '@ampeersenergy/ampeers-ui-components';
import ContentLoader from 'react-content-loader';

const AccountingKpiContent = styled(PaddedShadowBox)`
  padding: 24px 16px;
  width: 300px;
`;

const KpiAmount = styled.strong`
  display: inline-block;
  font-size: 20px;
`;

const IconCircle = styled.figure`
  background: ${(props) => props.theme.palette.info.background};
  border-radius: 9999px;
  align-self: flex-start;
  padding: 4px;
  margin: 0;
`;

interface AccountingKpiCardProps {
  icon: React.ReactElement;
  title: string;
  amount?: number;
  isLoading?: boolean;
}

const AccountingKpiTitle = styled.h4`
  font-size: 16px;
  margin: 0;
`;

const AccountingKpiDescription = styled.div`
  color: ${(props) => props.theme.palette.textMuted};
  font-size: 14px;
  margin: 0;
`;

export function AccountingKpiCard({
  children,
  icon,
  title,
  amount = 0,
  isLoading,
}: React.PropsWithChildren<AccountingKpiCardProps>) {
  const loader = (
    <ContentLoader speed={2} height="24px" width="30px">
      <rect x="0" y="0" width="100%" height="100%" />
    </ContentLoader>
  );

  return (
    <AccountingKpiContent>
      <FlexRow>
        <Flex>
          {isLoading ? loader : <KpiAmount>{amount}</KpiAmount>}

          <AccountingKpiTitle>{title}</AccountingKpiTitle>
          <AccountingKpiDescription>{children}</AccountingKpiDescription>
        </Flex>
        <IconCircle>{icon}</IconCircle>
      </FlexRow>
    </AccountingKpiContent>
  );
}
