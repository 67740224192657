import styled from 'styled-components';

export const Hint = styled.div`
  color: #a9a9a9;
  font-size: 12px;
  margin-top: 5px;
  margin-left: 6px;
  white-space: pre-line;
`;

export const HintWarning = styled(Hint)`
  color: #ff9800;
`;
