import React from 'react';
import { EditContainerProps } from '@ampeersenergy/ampeers-ui-components/dist/create-flow/create-flow';
import * as yup from 'yup';

import GraphqlForm from '../../../../../components/graphql-form';
import {
  PlantDetailDocument,
  PlantDetailExtendedQuery,
} from '../../../../../graphql-types';
import { removeTypename } from '../../../../../helpers/utils';

import MainPanel from './mainPanel';

interface EditMasterDataProps extends EditContainerProps {
  plant: PlantDetailExtendedQuery['readPlant'] | undefined;
  isLoading: boolean;
  setModalIsOpen: (value: boolean) => void;
}

export function EditMasterData({
  plant,
  setModalIsOpen,
  isLoading,
  onSuccess,
  variables,
}: EditMasterDataProps) {
  return (
    <GraphqlForm
      mutation="updatePlantMasterData"
      readDocumentFields={['id']}
      submitAllValues
      // TODO: Should be typed as UpdatePlantInput but this messes up value set to plant
      // @ts-ignore
      onBeforeSubmit={async (values: any) => {
        // Because we need to submit all values to get the address.id, we have to strip
        // all properties that are not included in UpdatePlantInput.
        const updatedValues = removeTypename(values);
        return {
          name: updatedValues?.name,
          ...(updatedValues?.kpis?.units && {
            kpis: {
              units: updatedValues?.kpis?.units,
            },
          }),
          ...(updatedValues?.addresses && {
            addresses: updatedValues?.addresses,
          }),
        };
      }}
      values={plant}
      variables={{
        plantId: parseFloat(variables.plantId),
      }}
      startInEdit={false}
      disableEdit={false}
      isLoading={isLoading}
      onSuccess={onSuccess}
      refetchQueries={[
        {
          query: PlantDetailDocument,
          variables: {
            plantId: parseFloat(variables.plantId),
          },
        },
      ]}
      validation={{
        name: yup
          .string()
          .test(
            'no-parentheses',
            'Die folgenden Zeichen sind nicht erlaubt: ( ) %',
            (value: string | undefined) => {
              if (value) {
                return !/[()%]/.test(value);
              }
              return true;
            },
          )
          .required(),
        addresses: [
          {
            streetName: yup.string().min(1).max(63).required(),
            streetNumber: yup.string().min(1).max(40).required(),
            zipCode: yup.string().min(1).max(7).required(),
            city: yup.string().min(1).max(63).required(),
          },
        ],
        kpis: {
          units: yup.number().min(1).integer().required(),
        },
      }}
    >
      <MainPanel
        plant={plant}
        isLoading={isLoading}
        setModalIsOpen={setModalIsOpen}
      />
    </GraphqlForm>
  );
}

export default EditMasterData;
