import * as React from 'react';
import { Relation } from '@ampeersenergy/ampeers-ui-components';
import ContentLoader from 'react-content-loader';

import { getRelationIcon } from '../../../../components/relation/getRelationIcon';
import { RelationFormattersMap } from '../../../../helpers/formatStrings';
import { useResolveById } from '../../../../components/relation/useResolveById';
import { InlineErrorMessage } from '../../../../components/errorMsg';

interface TypeIconProps {
  type: 'plant' | 'contract' | 'contractcomponent';
  sourceId: number;
  contractId?: number; // in case of contractcomponent
}

export function TypeIcon({ type, sourceId, contractId }: TypeIconProps) {
  const { data, loading, error } = useResolveById({
    type: type === 'contractcomponent' ? 'contract' : type,
    id: type === 'contractcomponent' ? String(contractId) : String(sourceId),
  });

  if (loading) {
    return (
      <ContentLoader id="content-loader-modal" speed={2} height={15}>
        <rect x="0" y="0" rx="1" ry="1" width="60px" height="12" />
      </ContentLoader>
    );
  }

  if (error) {
    return <InlineErrorMessage error={error} />;
  }

  const icon = getRelationIcon(
    type === 'contractcomponent' ? 'contract' : type,
  );

  const title =
    RelationFormattersMap[type === 'contractcomponent' ? 'contract' : type](
      data,
    );

  return <Relation icon={icon} title={title} />;
}
