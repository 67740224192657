import { get } from 'lodash';

import errorTranslations from './errorTranslation.json';

/**
 * Tries to retrieve the error translation object with the given key from errorTranslations.validation.
 * E.g. translate('invalidMeterReading.title') would get the title property from
 * errorTranslations.validation.invalidMeterReading.title.
 * If the key does not exist it returns the given fallback (which is the key by default)
 *
 * @param key The path of the property to get
 * @param fallback The fallback shown when the key does not exist.
 * @returns String
 */
export function translate(key: string, fallback: unknown = key) {
  return get(errorTranslations.validation, key, fallback);
}

/**
 * Same as `translate` but can handle array properties where the first item is the
 * singular translation and the second item the plural translation (if the property is an array).
 *
 * @param key The path of the property to get
 * @param plural If set to true and property is an array, return plural translation
 * @returns String
 */
export function translatePlural(key: string, plural: boolean) {
  const v = translate(key);

  if (Array.isArray(v)) {
    return v[plural ? 1 : 0];
  }
  return v;
}
