import gql from 'graphql-tag';

export const contractSimpleFragment = gql`
  fragment contractSimpleFragment on Contract {
    label
    customer {
      id
      label
      person {
        name
        namePrefix
        contactMobile
        contactPhone
        companyFlag
        co
      }
      address {
        streetName
        streetNumber
        zipCode
        city
        country
      }
      addressBilling {
        partnerId
        additionalPartnerId
        name
        namePrefix
        email
        additionalName
        additionalNamePrefix
        streetName
        streetNumber
        zipCode
        city
        country
        companyFlag
        co
      }
      addressShipping {
        name
        namePrefix
        streetName
        streetNumber
        zipCode
        city
        country
        companyFlag
        co
      }
      birthday
    }
  }
`;

// TODO combine with readContractFast
// Result is same as createContractFast. Previously a fragment was used, but this caused problems with the graphQlForms
export default gql`
  query readContract($contractId: ID!) {
    readContract(contractId: $contractId) {
      id
      plantId
      label
      status
      signDate
      startDate
      endDate
      contractType
      invoiceCycle

      cancelationReason
      cancelationDate
      earlyTerminationReason
      debtorLabel

      settlementDate
      settlementDay
      settlementMonth

      paymentDate

      downPayment
      downPaymentStartDate
      downPaymentHistory {
        downPayment
        downPaymentStartDate
      }

      contractMeter {
        malo
        meters {
          id
          contractId
          melo
          meterType
          meterNumber
          meterPlace
          validityStart
          validityEnd
          metering
        }
      }

      electricityGenerationOrGasType

      loadProfile {
        id
        name
      }

      tariffs {
        id
        tariffId
        nameExternal
        nameInternal
        validityStartDate
        validityEndDate
        assignedFrom
        assignedTo
      }

      customer {
        id
        label
        person {
          name
          namePrefix
          contactMobile
          contactPhone
          contactEmail
          companyFlag
          co
        }
        address {
          streetName
          streetNumber
          zipCode
          city
          country
        }

        addressBilling {
          partnerId
          additionalPartnerId
          name
          namePrefix
          email
          additionalName
          additionalNamePrefix
          companySecondaryFlag
          streetName
          streetNumber
          zipCode
          city
          country
          companyFlag
          co
        }

        addressShipping {
          namePrefix
          name
          streetName
          streetNumber
          zipCode
          city
          country
          companyFlag
          co
        }

        birthday
        advertisementAccepted
        documentDeliveryMethod

        bankAccount {
          name
          iban
          bic
          mandateReference
          signedAt
        }

        payer {
          accountHolderId
          name
          number
          iban
          bic
        }

        hasSepa
        isOperator
      }

      workspace {
        id
        name
      }

      documents {
        hash
        fileURL
        type
        contentType
        createdAt
        fileName
        userUpload
        parentGroup
      }
    }
  }
`;
