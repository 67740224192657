import React, { useCallback } from 'react';
import { Flex, FlexRow } from '@ampeersenergy/ampeers-ui-components';
import { DateTime } from 'luxon';

import { Entry } from '../../../../../components';
import { GraphqlFormField } from '../../../../../components/graphql-form/render';
import { Spacer } from '../../../../../components/graphql-form/style';
import { formatEmgEndDates } from '../../../../../helpers/formatStrings';
import {
  ReadContractDocument,
  ReadContractQuery,
  usePlantDetailQuery,
} from '../../../../../graphql-types';
import {
  filterTariffSuggestions,
  sortTariffsSuggestions,
} from '../../../../../helpers/utils';
import GraphQlForm from '../../../../../components/graphql-form';

import TariffChangeWarningMessage from './tariffChangeWarningMessage';

export type CurrentTariffType = {
  tariffId: string;
  startDate: string;
};
interface ReplaceContractTariffsComponentProps {
  contract: ReadContractQuery['readContract'];
  currentTariff: CurrentTariffType;
  onSuccess?: (result: any, additional: { values: any }) => void;
  onAbort?: () => void;
}

export default function ReplaceTariff({
  contract,
  currentTariff,
  onAbort,
  onSuccess,
}: ReplaceContractTariffsComponentProps) {
  const { id: contractId, plantId } = contract || {};
  const sortTariff = useCallback(sortTariffsSuggestions, []);

  const { data: plantData, loading: isPlantLoading } = usePlantDetailQuery({
    variables: {
      plantId: String(plantId),
    },
    skip: plantId === undefined,
  });

  return (
    <GraphQlForm
      mutation="replaceActiveContractTariff"
      variables={{
        contractId,
      }}
      defaultValues={{
        tariffAssignmentValidityStartDate: currentTariff?.startDate,
        tariff: {
          id: currentTariff.tariffId,
        },
      }}
      isLoading={isPlantLoading}
      startInEdit
      formVariables={{
        balancingAreaAccountId: plantData?.readPlant?.balancingAreaAccountId,
        plantId,
      }}
      onSuccess={onSuccess}
      onAbort={onAbort}
      refetchQueries={[
        {
          query: ReadContractDocument,
          variables: {
            contractId,
            contractLabel: contract?.label,
            customerId: contract?.customer?.id,
            customerLabel: contract?.customer?.label,
          },
        },
      ]}
    >
      <TariffChangeWarningMessage />
      <FlexRow>
        <Flex>
          <GraphqlFormField
            name="tariffAssignmentValidityStartDate"
            disabled
            tooltipText="Bei einem Tarifaustausch können Sie den Gültigkeitsbeginn nicht ändern. Der Tarifaustausch wirkt sich auf die gesamte Gültigkeit aus."
          />
        </Flex>
        <Spacer />
        <Flex>
          <Entry title="Ende Gültigkeit">
            {formatEmgEndDates(contract?.endDate)}
          </Entry>
        </Flex>
      </FlexRow>
      <FlexRow>
        <Flex>
          <GraphqlFormField
            name="tariff.id"
            data-testid="tariff.id"
            sort={sortTariff}
            placeholder="Tarif - Preisblatt"
            filter={filterTariffSuggestions(
              DateTime.fromISO(currentTariff.startDate),
            )}
          />
        </Flex>
      </FlexRow>
    </GraphQlForm>
  );
}
