import {
  StepStatus,
  AccountingRunWorkflowStepName,
  ActionOption,
} from '../../../graphql-types';

const stepNameLabels: Record<AccountingRunWorkflowStepName, string> = {
  [AccountingRunWorkflowStepName.OverviewStep]: 'Übersicht Rechnungslauf',
  [AccountingRunWorkflowStepName.AutarkyStep]: 'Berechnung Autarkie',
  [AccountingRunWorkflowStepName.ValidateContractConsumptionsStep]:
    'Berechnung Verbrauchswert',
  [AccountingRunWorkflowStepName.DownPaymentStartStep]:
    'Gültigkeitsbeginn neuer Abschlag',
  [AccountingRunWorkflowStepName.OpcStep]: 'Erstellung Abrechnung',
  [AccountingRunWorkflowStepName.FinalStep]: 'Freigabe Abrechnung',
};

export function getLabelForStepName(name?: string) {
  switch (name) {
    case AccountingRunWorkflowStepName.OverviewStep:
    case AccountingRunWorkflowStepName.AutarkyStep:
    case AccountingRunWorkflowStepName.ValidateContractConsumptionsStep:
    case AccountingRunWorkflowStepName.DownPaymentStartStep:
    case AccountingRunWorkflowStepName.OpcStep:
    case AccountingRunWorkflowStepName.FinalStep:
      return stepNameLabels[name];
    default:
      return name ?? '';
  }
}

type StepStatusProps =
  | 'success'
  | 'active'
  | 'inactive'
  | 'failed'
  | 'needs-attention'
  | 'loading';

export function mapStepStatus(
  status?: StepStatus,
  action?: ActionOption,
): StepStatusProps {
  if (
    status === StepStatus.Succeeded &&
    action &&
    [ActionOption.UserRetry, ActionOption.OperationsRetry].includes(action)
  ) {
    return 'needs-attention';
  }

  switch (status) {
    case StepStatus.Succeeded:
      return 'success';
    case StepStatus.Active:
      return 'active';
    case StepStatus.Timeout:
    case StepStatus.Failed:
      return 'failed';
    case StepStatus.WaitingForExternal:
    case StepStatus.WaitingForUser:
      return 'needs-attention';
    case StepStatus.Inactive:
    default:
      return 'inactive';
  }
}

export function translateActivityErrorTitle(
  title?: string | null,
  isOperations?: boolean,
) {
  if (isOperations) return title ?? 'Fehler';

  switch (title) {
    case 'createAccountingHandler':
      return '';
    case 'validateContractMetersHandler':
      return 'Prüfen der Verträge';
    case 'getPlantAutarkySuggestionHandler':
      return 'Berechnung der Autarkie';
    case 'validateContractConsumptionsHandler':
      return 'Prüfen der Verbrauchswerte';
    case 'buildOpc':
      return 'Zusammenstellen der Abrechnungswerte';
    case 'createOpc':
      return 'Erstellung der Abrechnungswerte';
    case 'setOpcDate':
      return 'Setzen des Abschlagdatums';
    case 'startOpc':
      return 'Starten der Abrechnung';
    case 'lockOpc':
      return 'Abschließen der Abrechnung';
    case 'computeOpc':
      return 'Berechnung';
    default:
      return 'Fehler';
  }
}

export function translateErrorMessage(message: string) {
  switch (message) {
    case 'activity StartToClose timeout':
      return (
        `Leider ist eine Zeitüberschreitung bei der Ausführung aufgetreten. ` +
        `Bitte setze Dich mit unserem Helpdesk in Verbindung, damit Sie diesen Schritt noch mal ausführen können.`
      );
    case 'NOTHING_TO_ACCOUNT':
      return 'Im gewählten Zeitraum wurden keine Abrechenbare Verträge gefunden';
    case 'OPC_CUSTOMER_DATA_CREATION_FAILED':
      return 'Die finale Erstellung der Abrechnung(en) war nicht erfolgreich. Bitte wende Dich an unser Helpdesk für weitere Unterstützung.';
    default:
      return message;
  }
}
