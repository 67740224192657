import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={350}
      viewBox="0 0 204 94"
    >
      <defs>
        <rect id="path-1" width="1236" height="711" x="0" y="0" rx="4" />
        <filter
          id="filter-2"
          width="101.1%"
          height="102%"
          x="-.6%"
          y="-.7%"
          filterUnits="objectBoundingBox"
        >
          <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="2"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0753660402 0"
          />
        </filter>
        <path id="path-3" d="M289 0H1440V1609H289z" />
        <filter
          id="filter-4"
          width="101.9%"
          height="101.2%"
          x="-1.4%"
          y="-.3%"
          filterUnits="objectBoundingBox"
        >
          <feOffset
            dx="-7"
            dy="5"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="2"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0753660402 0"
          />
        </filter>
        <linearGradient
          id="linearGradient-5"
          x1="50%"
          x2="50%"
          y1="0%"
          y2="100%"
        >
          <stop offset="0%" stopColor="#7DD77B" />
          <stop offset="44.236%" stopColor="#64C685" />
          <stop offset="100%" stopColor="#45AF93" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-872 -554)">
          <g fill="#F8F8FB" stroke="#979797">
            <path d="M0.5 0.5H1462.5V1608.5H0.5z" />
          </g>
          <g transform="translate(105 53)">
            <g transform="translate(0 244)">
              <g>
                <use fill="#000" filter="url(#filter-2)" xlinkHref="#path-1" />
                <use fill="#FFF" xlinkHref="#path-1" />
              </g>
            </g>
            <g transform="translate(631 244)">
              <path
                fill="#B5B5B5"
                fillRule="evenodd"
                d="M190 23l-3.5 4-3.5-4h7zm-3.5-7l3.5 4h-7l3.5-4z"
              />
              <path
                stroke="#E8E8E8"
                strokeLinecap="square"
                strokeWidth="1"
                d="M202.5 11L202.5 710.459"
              />
              <text
                fill="#090909"
                fontFamily="ProximaNova-Semibold, Proxima Nova"
                fontSize="12"
                fontWeight="500"
                letterSpacing="1.29"
              >
                <tspan x="15" y="26">
                  PROJEKTMANAGER
                </tspan>
              </text>
            </g>
            <g transform="translate(833 244)">
              <path
                fill="#B5B5B5"
                fillRule="evenodd"
                d="M181 23l-3.5 4-3.5-4h7zm-3.5-7l3.5 4h-7l3.5-4z"
              />
              <path
                stroke="#E8E8E8"
                strokeLinecap="square"
                strokeWidth="1"
                d="M193.5 11L193.5 710.459"
              />
              <text
                fill="#090909"
                fontFamily="ProximaNova-Semibold, Proxima Nova"
                fontSize="12"
                fontWeight="500"
                letterSpacing="1.29"
              >
                <tspan x="15" y="26">
                  SUMMENZÄHLER
                </tspan>
              </text>
            </g>
            <g opacity="0.422" transform="translate(0 306)">
              <g fill="#EEE" transform="translate(0 140)">
                <path d="M0 0H1236V78H0z" />
              </g>
              <g fill="#DBFFD4" transform="translate(0 279)">
                <g>
                  <path d="M0 0H1236V81H0z" />
                </g>
              </g>
            </g>
            <text
              fill="#2A2A2A"
              fontFamily="Roboto-Regular, Roboto"
              fontSize="15"
              fontWeight="normal"
              letterSpacing="0.383"
            >
              <tspan x="842" y="601">
                SLP
              </tspan>
            </text>
            <text
              fill="#2A2A2A"
              fontFamily="Roboto-Regular, Roboto"
              fontSize="15"
              fontWeight="normal"
              letterSpacing="0.383"
            >
              <tspan x="842" y="544">
                SLP
              </tspan>
            </text>
          </g>
          <g>
            <use fill="#000" filter="url(#filter-4)" xlinkHref="#path-3" />
            <use fill="#FFF" xlinkHref="#path-3" />
          </g>
          <g
            fill="#6D6D6D"
            fontFamily="ProximaNova-Regular, Proxima Nova"
            fontSize="14"
            fontWeight="normal"
            transform="translate(593 483)"
          >
            <text>
              <tspan x="31" y="16">
                Kaskadenschaltung
              </tspan>
              <tspan x="31" y="35">
                (Zwei Erzeugungsanlagen)
              </tspan>
            </text>
          </g>
          <g
            fill="#6D6D6D"
            fontFamily="ProximaNova-Regular, Proxima Nova"
            fontSize="14"
            fontWeight="normal"
            transform="translate(869 483)"
          >
            <text>
              <tspan x="31" y="16">
                Eine Sammelschiene
              </tspan>
              <tspan x="31" y="35">
                (Zwei Erzeugungsanlagen – RLM)
              </tspan>
            </text>
          </g>
          <g opacity="0.303" transform="translate(872 554)">
            <text
              fill="#7B89D6"
              fontFamily="ProximaNova-Semibold, Proxima Nova"
              fontSize="10.5"
              fontWeight="500"
              letterSpacing="0.159"
            >
              <tspan x="135.307" y="38">
                Z
              </tspan>
              <tspan x="141.65" y="38" fontSize="7.7" letterSpacing="0.117">
                1
              </tspan>
            </text>
            <g transform="translate(79.1 51.8)">
              <text
                fill="#7B89D6"
                fontFamily="ProximaNova-Semibold, Proxima Nova"
                fontSize="10.5"
                fontWeight="500"
                letterSpacing="0.159"
              >
                <tspan x="5.06" y="12.1">
                  Z
                </tspan>
                <tspan x="11.404" y="12.1" fontSize="7.7" letterSpacing="0.117">
                  3
                </tspan>
              </text>
              <circle
                cx="10.15"
                cy="9.45"
                r="9.45"
                stroke="#7B89D6"
                strokeWidth="0.5"
              />
            </g>
            <g transform="translate(18.9 51.8)">
              <text
                fill="#7B89D6"
                fontFamily="ProximaNova-Semibold, Proxima Nova"
                fontSize="10.5"
                fontWeight="500"
                letterSpacing="0.159"
              >
                <tspan x="4.979" y="12.1">
                  Z
                </tspan>
                <tspan x="11.323" y="12.1" fontSize="7.7" letterSpacing="0.117">
                  2
                </tspan>
              </text>
              <circle
                cx="10.15"
                cy="9.45"
                r="9.45"
                stroke="#7B89D6"
                strokeWidth="0.5"
              />
            </g>
            <g transform="translate(154.7 27.3)">
              <text
                fill="#0270FF"
                fontFamily="ProximaNova-Semibold, Proxima Nova"
                fontSize="10.5"
                fontWeight="500"
                letterSpacing="0.159"
              >
                <tspan x="5.308" y="10">
                  Z
                </tspan>
                <tspan x="11.652" y="10" fontSize="7.7" letterSpacing="0.117">
                  1B
                </tspan>
              </text>
              <g transform="translate(0 1.4)">
                <path
                  stroke="#0270FF"
                  strokeLinecap="square"
                  strokeWidth="0.7"
                  d="M2.009 0L2.009 10.886"
                />
                <path
                  fill="#0270FF"
                  d="M2.00869565 11.06875L4.0173913 13.08125 0 13.08125z"
                  transform="rotate(-180 2.009 12.075)"
                />
              </g>
            </g>
            <circle
              cx="139.65"
              cy="35.35"
              r="9.45"
              stroke="#7B89D6"
              strokeWidth="0.5"
            />
            <text
              fill="#FFF"
              fontFamily="ProximaNova-Semibold, Proxima Nova"
              fontSize="14.7"
              fontWeight="500"
              letterSpacing="0.223"
            >
              <tspan x="60.444" y="84.525">
                EA
              </tspan>
            </text>
            <path
              stroke="#7BD67C"
              strokeLinecap="square"
              strokeWidth="0.7"
              d="M126.7 50.4L126.7 2.1"
            />
            <path
              stroke="#7BD67C"
              strokeLinecap="square"
              strokeWidth="0.7"
              d="M70.7 66.5L70.7 50.4"
            />
            <path
              stroke="#7BD67C"
              strokeLinecap="square"
              strokeWidth="0.7"
              d="M11.9 66.5L11.9 50.4"
            />
            <path
              stroke="#7BD67C"
              strokeLinecap="square"
              strokeWidth="0.7"
              d="M154 66.5L154 50.4"
            />
            <path
              stroke="#7BD67C"
              strokeLinecap="square"
              strokeWidth="0.7"
              d="M140 82.6L140 66.5"
            />
            <path
              stroke="#7BD67C"
              strokeLinecap="square"
              strokeWidth="0.7"
              d="M147 82.6L147 66.5"
            />
            <path
              stroke="#7BD67C"
              strokeLinecap="square"
              strokeWidth="0.7"
              d="M154 82.6L154 66.5"
            />
            <path
              stroke="#7BD67C"
              strokeLinecap="square"
              strokeWidth="0.7"
              d="M161 82.6L161 66.5"
            />
            <path
              stroke="#7BD67C"
              strokeLinecap="square"
              strokeWidth="0.7"
              d="M168 82.6L168 66.5"
            />
            <path
              stroke="#7BD67C"
              strokeLinecap="square"
              strokeWidth="0.7"
              d="M98.7 1.75L154 1.75"
            />
            <path
              stroke="#7BD67C"
              strokeLinecap="square"
              strokeWidth="0.7"
              d="M11.9 50.4L154 50.4"
            />
            <path
              stroke="#7BD67C"
              strokeLinecap="square"
              strokeWidth="0.7"
              d="M140 66.5L167.254 66.5"
            />
            <circle cx="126.7" cy="2.1" r="2.1" fill="#7BD67C" />
            <circle cx="126.7" cy="50.4" r="2.1" fill="#7BD67C" />
            <path
              stroke="#7B89D6"
              strokeDasharray="1.4,2.8"
              strokeLinecap="square"
              strokeWidth="0.7"
              d="M98.7 20.65L154 20.65"
            />
            <text
              fill="#12007F"
              fontFamily="ProximaNova-Semibold, Proxima Nova"
              fontSize="10.5"
              fontWeight="500"
              letterSpacing="0.159"
            >
              <tspan x="186.412" y="37.3">
                Z
              </tspan>
              <tspan x="192.756" y="37.3" fontSize="7.7" letterSpacing="0.117">
                1L
              </tspan>
            </text>
            <g transform="translate(100.1 54.6)">
              <text
                fill="#12007F"
                fontFamily="ProximaNova-Semibold, Proxima Nova"
                fontSize="10.5"
                fontWeight="500"
                letterSpacing="0.159"
              >
                <tspan x="5.248" y="10">
                  Z
                </tspan>
                <tspan x="11.592" y="10" fontSize="7.7" letterSpacing="0.117">
                  3L
                </tspan>
              </text>
              <g transform="rotate(-180 2.1 6.737)">
                <path
                  stroke="#12007F"
                  strokeLinecap="square"
                  strokeWidth="0.7"
                  d="M2.009 0L2.009 9.896"
                />
                <path
                  fill="#12007F"
                  d="M2.00869565 10.0625L4.0173913 12.075 0 12.075z"
                  transform="rotate(-180 2.009 11.069)"
                />
              </g>
            </g>
            <g transform="translate(39.9 54.6)">
              <text
                fill="#12007F"
                fontFamily="ProximaNova-Semibold, Proxima Nova"
                fontSize="10.5"
                fontWeight="500"
                letterSpacing="0.159"
              >
                <tspan x="5.167" y="10">
                  Z
                </tspan>
                <tspan x="11.511" y="10" fontSize="7.7" letterSpacing="0.117">
                  2L
                </tspan>
              </text>
              <g transform="rotate(-180 2.1 6.737)">
                <path
                  stroke="#12007F"
                  strokeLinecap="square"
                  strokeWidth="0.7"
                  d="M2.009 0L2.009 9.896"
                />
                <path
                  fill="#12007F"
                  d="M2.00869565 10.0625L4.0173913 12.075 0 12.075z"
                  transform="rotate(-180 2.009 11.069)"
                />
              </g>
            </g>
            <g transform="rotate(-180 92.309 20.387)">
              <path
                stroke="#12007F"
                strokeLinecap="square"
                strokeWidth="0.7"
                d="M2.009 0L2.009 9.896"
              />
              <path
                fill="#12007F"
                d="M2.00869565 10.0625L4.0173913 12.075 0 12.075z"
                transform="rotate(-180 2.009 11.069)"
              />
            </g>
            <text
              fill="#7B89D6"
              fontFamily="ProximaNova-Semibold, Proxima Nova"
              fontSize="6.3"
              fontWeight="500"
              letterSpacing="0.096"
            >
              <tspan x="131.077" y="16.5">
                EIGENTUMSGRENZE
              </tspan>
            </text>
            <g transform="translate(59.5 63)">
              <circle
                cx="11.55"
                cy="11.55"
                r="11.55"
                fill="url(#linearGradient-5)"
              />
              <text
                fill="#FFF"
                fontFamily="ProximaNova-Semibold, Proxima Nova"
                fontSize="9.1"
                fontWeight="500"
                letterSpacing="0.138"
              >
                <tspan x="2.452" y="14.125">
                  EA2
                </tspan>
              </text>
            </g>
            <g transform="translate(0 63)">
              <circle
                cx="11.55"
                cy="11.55"
                r="11.55"
                fill="url(#linearGradient-5)"
              />
              <text
                fill="#FFF"
                fontFamily="ProximaNova-Semibold, Proxima Nova"
                fontSize="9.1"
                fontWeight="500"
                letterSpacing="0.138"
              >
                <tspan x="3.431" y="14.125">
                  EA1
                </tspan>
              </text>
            </g>
            <text
              fill="#7BD67C"
              fontFamily="ProximaNova-Semibold, Proxima Nova"
              fontSize="6.3"
              fontWeight="500"
              letterSpacing="0.096"
            >
              <tspan x="129.978" y="92.8">
                VERBRAUCHER
              </tspan>
            </text>
          </g>
          <g
            fill="#6D6D6D"
            fontFamily="ProximaNova-Regular, Proxima Nova"
            fontSize="14"
            fontWeight="normal"
            transform="translate(593 483)"
          >
            <text>
              <tspan x="31" y="16">
                Kaskadenschaltung
              </tspan>
              <tspan x="31" y="35">
                (Zwei Erzeugungsanlagen)
              </tspan>
            </text>
          </g>
          <g
            fill="#6D6D6D"
            fontFamily="ProximaNova-Regular, Proxima Nova"
            fontSize="14"
            fontWeight="normal"
            transform="translate(869 483)"
          >
            <text>
              <tspan x="31" y="16">
                Eine Sammelschiene
              </tspan>
              <tspan x="31" y="35">
                (Zwei Erzeugungsanlagen – RLM)
              </tspan>
            </text>
          </g>
          <g transform="translate(872 554)">
            <text
              fill="#7B89D6"
              fontFamily="ProximaNova-Semibold, Proxima Nova"
              fontSize="10.5"
              fontWeight="500"
              letterSpacing="0.159"
            >
              <tspan x="135.307" y="38">
                Z
              </tspan>
              <tspan x="141.65" y="38" fontSize="7.7" letterSpacing="0.117">
                1
              </tspan>
            </text>
            <g transform="translate(79.1 51.8)">
              <text
                fill="#7B89D6"
                fontFamily="ProximaNova-Semibold, Proxima Nova"
                fontSize="10.5"
                fontWeight="500"
                letterSpacing="0.159"
              >
                <tspan x="5.06" y="12.1">
                  Z
                </tspan>
                <tspan x="11.404" y="12.1" fontSize="7.7" letterSpacing="0.117">
                  3
                </tspan>
              </text>
              <circle
                cx="10.15"
                cy="9.45"
                r="9.45"
                stroke="#7B89D6"
                strokeWidth="0.5"
              />
            </g>
            <g transform="translate(18.9 51.8)">
              <text
                fill="#7B89D6"
                fontFamily="ProximaNova-Semibold, Proxima Nova"
                fontSize="10.5"
                fontWeight="500"
                letterSpacing="0.159"
              >
                <tspan x="4.979" y="12.1">
                  Z
                </tspan>
                <tspan x="11.323" y="12.1" fontSize="7.7" letterSpacing="0.117">
                  2
                </tspan>
              </text>
              <circle
                cx="10.15"
                cy="9.45"
                r="9.45"
                stroke="#7B89D6"
                strokeWidth="0.5"
              />
            </g>
            <g transform="translate(154.7 27.3)">
              <text
                fill="#0270FF"
                fontFamily="ProximaNova-Semibold, Proxima Nova"
                fontSize="10.5"
                fontWeight="500"
                letterSpacing="0.159"
              >
                <tspan x="5.308" y="10">
                  Z
                </tspan>
                <tspan x="11.652" y="10" fontSize="7.7" letterSpacing="0.117">
                  1B
                </tspan>
              </text>
              <g transform="translate(0 1.4)">
                <path
                  stroke="#0270FF"
                  strokeLinecap="square"
                  strokeWidth="0.7"
                  d="M2.009 0L2.009 10.886"
                />
                <path
                  fill="#0270FF"
                  d="M2.00869565 11.06875L4.0173913 13.08125 0 13.08125z"
                  transform="rotate(-180 2.009 12.075)"
                />
              </g>
            </g>
            <circle
              cx="139.65"
              cy="35.35"
              r="9.45"
              stroke="#7B89D6"
              strokeWidth="0.5"
            />
            <text
              fill="#FFF"
              fontFamily="ProximaNova-Semibold, Proxima Nova"
              fontSize="14.7"
              fontWeight="500"
              letterSpacing="0.223"
            >
              <tspan x="60.444" y="84.525">
                EA
              </tspan>
            </text>
            <path
              stroke="#7BD67C"
              strokeLinecap="square"
              strokeWidth="0.7"
              d="M126.7 50.4L126.7 2.1"
            />
            <path
              stroke="#7BD67C"
              strokeLinecap="square"
              strokeWidth="0.7"
              d="M70.7 66.5L70.7 50.4"
            />
            <path
              stroke="#7BD67C"
              strokeLinecap="square"
              strokeWidth="0.7"
              d="M11.9 66.5L11.9 50.4"
            />
            <path
              stroke="#7BD67C"
              strokeLinecap="square"
              strokeWidth="0.7"
              d="M154 66.5L154 50.4"
            />
            <path
              stroke="#7BD67C"
              strokeLinecap="square"
              strokeWidth="0.7"
              d="M140 82.6L140 66.5"
            />
            <path
              stroke="#7BD67C"
              strokeLinecap="square"
              strokeWidth="0.7"
              d="M147 82.6L147 66.5"
            />
            <path
              stroke="#7BD67C"
              strokeLinecap="square"
              strokeWidth="0.7"
              d="M154 82.6L154 66.5"
            />
            <path
              stroke="#7BD67C"
              strokeLinecap="square"
              strokeWidth="0.7"
              d="M161 82.6L161 66.5"
            />
            <path
              stroke="#7BD67C"
              strokeLinecap="square"
              strokeWidth="0.7"
              d="M168 82.6L168 66.5"
            />
            <path
              stroke="#7BD67C"
              strokeLinecap="square"
              strokeWidth="0.7"
              d="M98.7 1.75L154 1.75"
            />
            <path
              stroke="#7BD67C"
              strokeLinecap="square"
              strokeWidth="0.7"
              d="M11.9 50.4L154 50.4"
            />
            <path
              stroke="#7BD67C"
              strokeLinecap="square"
              strokeWidth="0.7"
              d="M140 66.5L167.254 66.5"
            />
            <circle cx="126.7" cy="2.1" r="2.1" fill="#7BD67C" />
            <circle cx="126.7" cy="50.4" r="2.1" fill="#7BD67C" />
            <path
              stroke="#7B89D6"
              strokeDasharray="1.4,2.8"
              strokeLinecap="square"
              strokeWidth="0.7"
              d="M98.7 20.65L154 20.65"
            />
            <text
              fill="#12007F"
              fontFamily="ProximaNova-Semibold, Proxima Nova"
              fontSize="10.5"
              fontWeight="500"
              letterSpacing="0.159"
            >
              <tspan x="186.412" y="37.3">
                Z
              </tspan>
              <tspan x="192.756" y="37.3" fontSize="7.7" letterSpacing="0.117">
                1L
              </tspan>
            </text>
            <g transform="translate(100.1 54.6)">
              <text
                fill="#12007F"
                fontFamily="ProximaNova-Semibold, Proxima Nova"
                fontSize="10.5"
                fontWeight="500"
                letterSpacing="0.159"
              >
                <tspan x="5.248" y="10">
                  Z
                </tspan>
                <tspan x="11.592" y="10" fontSize="7.7" letterSpacing="0.117">
                  3L
                </tspan>
              </text>
              <g transform="rotate(-180 2.1 6.737)">
                <path
                  stroke="#12007F"
                  strokeLinecap="square"
                  strokeWidth="0.7"
                  d="M2.009 0L2.009 9.896"
                />
                <path
                  fill="#12007F"
                  d="M2.00869565 10.0625L4.0173913 12.075 0 12.075z"
                  transform="rotate(-180 2.009 11.069)"
                />
              </g>
            </g>
            <g transform="translate(39.9 54.6)">
              <text
                fill="#12007F"
                fontFamily="ProximaNova-Semibold, Proxima Nova"
                fontSize="10.5"
                fontWeight="500"
                letterSpacing="0.159"
              >
                <tspan x="5.167" y="10">
                  Z
                </tspan>
                <tspan x="11.511" y="10" fontSize="7.7" letterSpacing="0.117">
                  2L
                </tspan>
              </text>
              <g transform="rotate(-180 2.1 6.737)">
                <path
                  stroke="#12007F"
                  strokeLinecap="square"
                  strokeWidth="0.7"
                  d="M2.009 0L2.009 9.896"
                />
                <path
                  fill="#12007F"
                  d="M2.00869565 10.0625L4.0173913 12.075 0 12.075z"
                  transform="rotate(-180 2.009 11.069)"
                />
              </g>
            </g>
            <g transform="rotate(-180 92.309 20.387)">
              <path
                stroke="#12007F"
                strokeLinecap="square"
                strokeWidth="0.7"
                d="M2.009 0L2.009 9.896"
              />
              <path
                fill="#12007F"
                d="M2.00869565 10.0625L4.0173913 12.075 0 12.075z"
                transform="rotate(-180 2.009 11.069)"
              />
            </g>
            <text
              fill="#7B89D6"
              fontFamily="ProximaNova-Semibold, Proxima Nova"
              fontSize="6.3"
              fontWeight="500"
              letterSpacing="0.096"
            >
              <tspan x="131.077" y="16.5">
                EIGENTUMSGRENZE
              </tspan>
            </text>
            <g transform="translate(59.5 63)">
              <circle
                cx="11.55"
                cy="11.55"
                r="11.55"
                fill="url(#linearGradient-5)"
              />
              <text
                fill="#FFF"
                fontFamily="ProximaNova-Semibold, Proxima Nova"
                fontSize="9.1"
                fontWeight="500"
                letterSpacing="0.138"
              >
                <tspan x="2.452" y="14.125">
                  EA2
                </tspan>
              </text>
            </g>
            <g transform="translate(0 63)">
              <circle
                cx="11.55"
                cy="11.55"
                r="11.55"
                fill="url(#linearGradient-5)"
              />
              <text
                fill="#FFF"
                fontFamily="ProximaNova-Semibold, Proxima Nova"
                fontSize="9.1"
                fontWeight="500"
                letterSpacing="0.138"
              >
                <tspan x="3.431" y="14.125">
                  EA1
                </tspan>
              </text>
            </g>
            <text
              fill="#7BD67C"
              fontFamily="ProximaNova-Semibold, Proxima Nova"
              fontSize="6.3"
              fontWeight="500"
              letterSpacing="0.096"
            >
              <tspan x="129.978" y="92.8">
                VERBRAUCHER
              </tspan>
            </text>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default React.memo(Icon);
