import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import styled from 'styled-components';
import { FormikErrors, useFormikContext } from 'formik';
import { Relation, FieldSet } from '@ampeersenergy/ampeers-ui-components';

import {
  GraphqlFormAutoComplete,
  GraphqlFormField,
  GraphqlFormInputGroup,
} from '../../../../components/graphql-form/render';
import { FlexRow, Flex, Label } from '../../../../components';
import { formatPeriod } from '../../../../helpers/formatStrings';
import { ErrorBox } from '../../../../components/input';

const GraphqlFormInputGroupStyled = styled(GraphqlFormInputGroup)`
  > div > div > div {
    margin-top: 3px;
    margin-bottom: 3px;
  }
  > div {
    min-width: 200px;
  }
`;
const Wrap = styled.div`
  min-width: 690px;
`;

const GraphqlFormFieldRadioStyled = styled(GraphqlFormField)`
  div > label {
    margin-left: 5px;
  }
  label {
    margin-left: 0px;
  }
  input {
    margin: 0px;
  }
`;

const GraphqlFormCheckboxGroup = styled(FlexRow)`
  div {
    padding-right: 20px;
  }
`;

const ErrorBoxStyled = styled(ErrorBox)`
  overflow-wrap: break-word;
  width: 210px;
`;

const isTestEnv = process.env.NODE_ENV === 'test';

const DetailsForm = forwardRef((_props, ref?: any) => {
  const { getFieldProps, setFieldValue, initialValues, errors } =
    useFormikContext();
  const [isNoticePeriodExtension, setNoticePeriodExtension] = useState(true);

  useImperativeHandle(ref, () => ({
    getValue(path: string) {
      return getFieldProps(path).value;
    },
  }));

  useEffect(() => {
    if (
      getFieldProps('noticePeriodExtension.count').value === 0 &&
      isNoticePeriodExtension
    ) {
      setFieldValue('noticePeriodExtension.period', 'months', true);
      setFieldValue('noticePeriodExtension.to', 'vl', true);
      setNoticePeriodExtension(false);
    } else if (
      getFieldProps('noticePeriodExtension.count').value !== 0 &&
      !isNoticePeriodExtension
    ) {
      setNoticePeriodExtension(true);
    }
  }, [getFieldProps, setFieldValue, isNoticePeriodExtension, initialValues]);

  const noticePeriodError =
    (errors as FormikErrors<{ [key: string]: string }>)['noticePeriod.count'] ||
    '';
  const noticePeriodExtensionError =
    (errors as FormikErrors<{ [key: string]: string }>)[
      'noticePeriodExtension.count'
    ] || '';

  return (
    <Wrap>
      <FieldSet legend="Tarifdetails">
        <GraphqlFormCheckboxGroup>
          <Flex>
            <GraphqlFormField name="kind" />
          </Flex>
          <Flex>
            <GraphqlFormField name="tax" />
            <GraphqlFormField name="powerTax" />
          </Flex>
        </GraphqlFormCheckboxGroup>
      </FieldSet>
      <FieldSet legend="Preisgarantie">
        <GraphqlFormFieldRadioStyled
          name="priceGuaranteeReference"
          label="Bezugszeitpunkt"
        />
        <GraphqlFormAutoComplete
          name="priceGuarantee"
          placeholder="Laufzeit"
          loading={false}
          label="Laufzeit"
          id="priceGuarantee"
          data-testid="priceGuarantee"
          suggestions={new Array(100).fill(null).map((_, index) => ({
            priceGuarantee: index,
          }))}
          getDataFromSuggestion={(suggestion: any) => suggestion.priceGuarantee}
          formatSelected={(suggestion: any) => (
            <Relation
              icon={() => null}
              title={formatPeriod(suggestion.priceGuarantee, 'month')}
            />
          )}
          formatSuggestion={(suggestion: any) =>
            formatPeriod(suggestion.priceGuarantee, 'month')
          }
        />
      </FieldSet>
      <FieldSet legend="Vertragslaufzeit Initial">
        <GraphqlFormAutoComplete
          name="minimumDuration"
          placeholder="Mindestlaufzeit"
          loading={false}
          label="Mindestlaufzeit"
          id="minimumDuration"
          data-testid="minimumDuration"
          suggestions={new Array(100).fill(null).map((_, index) => ({
            minimumDuration: index,
          }))}
          getDataFromSuggestion={(suggestion: any) =>
            suggestion.minimumDuration
          }
          formatSelected={(suggestion: any) => (
            <Relation
              icon={() => null}
              title={formatPeriod(suggestion.minimumDuration, 'month')}
            />
          )}
          formatSuggestion={(suggestion: any) =>
            formatPeriod(suggestion.minimumDuration, 'month')
          }
        />
        <Label>Kündigungsfrist</Label>
        <GraphqlFormInputGroupStyled>
          <div>
            <GraphqlFormAutoComplete
              name="noticePeriod.count"
              placeholder="Kündigungsfrist"
              loading={false}
              // label={"Kündigungsfrist"}
              label={null}
              id="noticePeriod.count"
              data-testid="noticePeriod.count"
              suggestions={new Array(99).fill(null).map((_, index) => ({
                'noticePeriod.count': index + 1,
              }))}
              getDataFromSuggestion={(suggestion: any) =>
                suggestion['noticePeriod.count']
              }
              formatSelected={(suggestion: any) => (
                <Relation
                  icon={() => null}
                  title={formatPeriod(suggestion['noticePeriod.count'])}
                />
              )}
              formatSuggestion={(suggestion: any) =>
                formatPeriod(suggestion['noticePeriod.count'])
              }
            />
            {noticePeriodError && (
              <ErrorBoxStyled
                {...(isTestEnv
                  ? { 'data-testid': 'noticePeriod.count-error' }
                  : {})}
              >
                {noticePeriodError}
              </ErrorBoxStyled>
            )}
          </div>
          <GraphqlFormField name="noticePeriod.period" label={false} />
          <GraphqlFormField name="noticePeriod.to" label={false} />
        </GraphqlFormInputGroupStyled>
      </FieldSet>
      <FieldSet legend="Vertragslaufzeit Verlängerung">
        <GraphqlFormAutoComplete
          name="minimumDurationExtension"
          placeholder="Mindestlaufzeit"
          loading={false}
          label="Mindestlaufzeit"
          id="minimumDurationExtension"
          data-testid="minimumDurationExtension"
          suggestions={new Array(100).fill(null).map((_, index) => ({
            minimumDurationExtension: index,
          }))}
          getDataFromSuggestion={(suggestion: any) =>
            suggestion.minimumDurationExtension
          }
          formatSelected={(suggestion: any) => (
            <Relation
              icon={() => null}
              title={formatPeriod(suggestion.minimumDurationExtension, 'month')}
            />
          )}
          formatSuggestion={(suggestion: any) =>
            formatPeriod(suggestion.minimumDurationExtension, 'month')
          }
        />
        <Label>Kündigungsfrist</Label>
        <GraphqlFormInputGroupStyled>
          <div>
            <GraphqlFormAutoComplete
              name="noticePeriodExtension.count"
              placeholder="Kündigungsfrist"
              loading={false}
              label={null}
              id="noticePeriodExtension.count"
              data-testid="noticePeriodExtension.count"
              suggestions={new Array(100).fill(null).map((_, index) => ({
                'noticePeriodExtension.count': index,
              }))}
              getDataFromSuggestion={(suggestion: any) =>
                suggestion['noticePeriodExtension.count']
              }
              formatSelected={(suggestion: any) => (
                <Relation
                  icon={() => null}
                  title={formatPeriod(
                    suggestion['noticePeriodExtension.count'],
                  )}
                />
              )}
              formatSuggestion={(suggestion: any) =>
                formatPeriod(suggestion['noticePeriodExtension.count'])
              }
            />
            {noticePeriodExtensionError && (
              <ErrorBoxStyled
                {...(isTestEnv
                  ? { 'data-testid': 'noticePeriodExtension.count-error' }
                  : {})}
              >
                {noticePeriodExtensionError}
              </ErrorBoxStyled>
            )}
          </div>
          <GraphqlFormField
            name="noticePeriodExtension.period"
            label={false}
            disabled={!isNoticePeriodExtension}
          />
          <GraphqlFormField
            name="noticePeriodExtension.to"
            label={false}
            disabled={!isNoticePeriodExtension}
          />
        </GraphqlFormInputGroupStyled>
      </FieldSet>
    </Wrap>
  );
});

export default DetailsForm;
