import * as React from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { Form, useFormikContext } from 'formik';
import {
  Icons,
  Button,
  FormikInput,
  Select,
  LinkButton,
  Input,
} from '@ampeersenergy/ampeers-ui-components';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';

import {
  AccountingRunAutarkySuggestion,
  ActionOption,
  ReadPlantsAutarkiesQuery,
} from '../../../../graphql-types';
import { useWorkflowStepsContext } from '../WorkflowStepsProvider';
import { TooltipInfo } from '../../../../components/TooltipInfo';
import { isSameTimePeriod } from '../../../../helpers/utils';

const Item = styled.li<{ isSelected?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 65px;
  padding: 12px 0;
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.palette.border};
  }
`;

const List = styled.ul`
  list-style: none;
  padding-left: 0;
`;

const PlantText = styled.span`
  font-weight: initial;
`;

const AutarkyValue = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: normal;

  .previous-autarky {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export interface PlantAutarkyFormValues {
  suggestions: PlantSuggestionValues[];
}

export interface PlantSuggestionValues extends AccountingRunAutarkySuggestion {
  value: string | number;
  isSelected?: boolean;
}

export type AutarkyFormVariables = {
  suggestions: PlantSuggestionValues[];
};
interface PlantListProps {
  setErrorMessage: (errorMessage: string) => void;
  isEditable?: boolean;
  isSuccess?: boolean;
  stepAction?: ActionOption;
  plantAutarkies: ReadPlantsAutarkiesQuery['readPlantsAutarkies'];
  paymentPeriodStartAt?: string;
  paymentPeriodEndAt?: string;
  loadingPlants?: boolean;
}

export function PlantList({
  setErrorMessage,
  isEditable,
  isSuccess,
  stepAction,
  plantAutarkies,
  paymentPeriodStartAt,
  paymentPeriodEndAt,
  loadingPlants,
}: PlantListProps) {
  const { values } = useFormikContext<AutarkyFormVariables>();
  const { handleDisableSaveButton } = useWorkflowStepsContext();
  const plantsWithProblem = values.suggestions?.filter(
    (suggestion) =>
      suggestion.isSelected !== false &&
      (suggestion.autarky === null || suggestion.autarky === undefined) &&
      (suggestion.value === null ||
        suggestion.value === undefined ||
        suggestion.value === ''),
  );
  const plantsIncluded = values.suggestions?.filter(
    (suggestion) => suggestion.isSelected,
  );

  React.useEffect(() => {
    if (plantsWithProblem.length > 0) {
      handleDisableSaveButton(true);
      const plantIds = plantsWithProblem
        ?.filter((p) =>
          p.errorReason?.includes('DIRECT_CONSUMPTION_AMOUNT_NOT_PLAUSIBLE'),
        )
        .map((p) => p.plantName);
      if (plantIds.length > 0) {
        setErrorMessage(
          `Für die folgenden Kundenanlage(n) wurde ein negativer Autarkiewert berechnet: ${plantIds.join(
            ', ',
          )}. Bitte behebe das Problem für jede Kundenanlage oder speichere einen selbstgewählten Ersatzwert um fortfahren zu können.`,
        );
      } else {
        setErrorMessage(
          'Bitte behebe das Problem für jede Kundenanlage, um fortfahren zu können.',
        );
      }
    } else if (plantsIncluded?.length === 0) {
      handleDisableSaveButton(true);
      setErrorMessage(
        'Mindestens eine Kundenanlage muss ausgewählt sein, um fortfahren zu können.',
      );
    } else {
      handleDisableSaveButton(false);
      setErrorMessage('');
    }

    return () => {
      handleDisableSaveButton(false);
      setErrorMessage('');
    };
  }, [
    plantsWithProblem,
    plantsIncluded,
    handleDisableSaveButton,
    setErrorMessage,
  ]);

  return (
    <Form>
      <List>
        {values.suggestions.map((suggestion, index) => {
          const previousAutarkyValuesForPlant = plantAutarkies.find(
            (plant) => plant.id === `${suggestion.plantId}`,
          );

          const previousAutarkyValueForPeriod =
            previousAutarkyValuesForPlant?.autarkies
              ?.filter((autarky) => {
                if (!paymentPeriodStartAt || !paymentPeriodEndAt) {
                  return false;
                }

                const samePeriod = isSameTimePeriod(
                  paymentPeriodStartAt,
                  paymentPeriodEndAt,
                  autarky.startDate,
                  autarky.endDate,
                );

                return (
                  samePeriod &&
                  DateTime.fromISO(paymentPeriodStartAt) >
                    DateTime.fromISO(autarky.startDate)
                );
              })
              .sort((v1, v2) =>
                // get the most recent value by sorting the autarkies by start date
                DateTime.fromISO(v1.startDate) < DateTime.fromISO(v2.startDate)
                  ? 1
                  : -1,
              )[0];

          if (isSuccess) {
            if (
              suggestion.autarky !== undefined &&
              suggestion.autarky !== null
            ) {
              return (
                <Item key={`${suggestion.plantId}-${index}`}>
                  <PlantText>
                    {suggestion.plantName ?? 'Kundenanlage'}
                  </PlantText>
                  <PlantListRowValid
                    index={index}
                    suggestion={suggestion}
                    previousValue={
                      previousAutarkyValueForPeriod?.userSuppliedValue ??
                      previousAutarkyValueForPeriod?.calculatedValue
                    }
                    loadingPlants={loadingPlants}
                  />
                </Item>
              );
            }
            return null;
          }

          if (suggestion.autarky === undefined || suggestion.autarky === null) {
            return (
              <Item key={`${suggestion.plantId}-${index}`}>
                <PlantText>{suggestion.plantName ?? 'Kundenanlage'}</PlantText>
                <PlantListRowWarning
                  suggestion={suggestion}
                  index={index}
                  isEditable={isEditable}
                  stepAction={stepAction}
                  previousValue={
                    previousAutarkyValueForPeriod?.userSuppliedValue ??
                    previousAutarkyValueForPeriod?.calculatedValue
                  }
                  loadingPlants={loadingPlants}
                />
              </Item>
            );
          }
          return null;
        })}
      </List>
    </Form>
  );
}

const AutarkyWithPreviousValue = ({
  index,
  plantId,
  previousValue,
  loadingPlants,
  children,
}: {
  index: number;
  plantId: number;
  previousValue?: number | null;
  loadingPlants?: boolean;
  children: React.ReactNode;
}) => {
  const previousAutarkyContent = React.useMemo(() => {
    if (loadingPlants) {
      return null;
    }

    if (previousValue === undefined || previousValue === null) {
      return (
        <>
          nicht verfügbar
          <TooltipInfo
            id={`no-autarky-${plantId}`}
            text="Es konnte keine Autarkie gefunden werden."
          />
        </>
      );
    }

    if (previousValue >= 0 && previousValue <= 100) {
      return <b>&nbsp;{previousValue} %</b>;
    }

    return (
      <>
        nicht verfügbar
        <TooltipInfo
          id={`invalid-autarky-${plantId}`}
          text="Die letzte gefundene Autarkie liegt außerhalb der zugelassenen Grenzen von 0% bis 100% und wird daher nicht angezeigt."
        />
      </>
    );
  }, [previousValue, plantId, loadingPlants]);

  return (
    <AutarkyValue>
      {loadingPlants ? null : (
        <span
          data-testid={`suggestions[${index}].previousAutarkyValue`}
          className="previous-autarky"
        >
          Vergleichswert letzte Abrechnung: {previousAutarkyContent}
        </span>
      )}
      {children}
    </AutarkyValue>
  );
};

const PlantListRowValid = ({
  suggestion,
  index,
  previousValue,
  loadingPlants,
}: {
  suggestion: PlantSuggestionValues;
  index: number;
  previousValue?: number | null;
  loadingPlants?: boolean;
}) => {
  return (
    <AutarkyWithPreviousValue
      index={index}
      plantId={suggestion.plantId}
      previousValue={previousValue}
      loadingPlants={loadingPlants}
    >
      <Input
        id={`${suggestion.plantId}-autarky`}
        type="number"
        max="100"
        min="0"
        disabled
        value={suggestion.autarky?.toString()}
        prependix="Autarkie:"
        appendix="%"
      />
    </AutarkyWithPreviousValue>
  );
};

const PlantListRowWarning = ({
  suggestion,
  index,
  isEditable,
  stepAction,
  previousValue,
  loadingPlants,
}: {
  suggestion: PlantSuggestionValues;
  index: number;
  isEditable?: boolean;
  stepAction?: ActionOption;
  previousValue?: number | null;
  loadingPlants?: boolean;
}) => {
  const theme = useTheme();
  const { setFieldValue } = useFormikContext();
  const [showAlternative, setShowAlternative] = React.useState<
    null | 'substitute_value' | 'exclude_plant'
  >(null);
  const { projectId, plantId, meterId } = suggestion;
  let meterUrl = '';
  if (projectId && plantId) {
    if (meterId) {
      meterUrl = `/project/${suggestion?.projectId}/plant/${suggestion?.plantId}/meter/${suggestion.meterId}/meterReading`;
    } else {
      meterUrl = `/project/${suggestion?.projectId}/plant/${suggestion?.plantId}/meter`;
    }
  }

  const handleShowAlternative = (value: string | null) => {
    switch (value) {
      case 'substitute_value':
      case 'exclude_plant':
        setShowAlternative(value);
        break;
      default: {
        setFieldValue(`suggestions[${index}].value`, '');
        setShowAlternative(null);
      }
    }
  };

  React.useEffect(() => {
    if (showAlternative === 'exclude_plant') {
      setFieldValue(`suggestions[${index}].isSelected`, false);
    } else {
      setFieldValue(`suggestions[${index}].isSelected`, true);
    }
  }, [showAlternative, index, setFieldValue]);

  if (!isEditable) {
    return (
      <div css="display: flex; align-items: center; gap: 10px;">
        <input type="checkbox" disabled defaultChecked />
        Kundenanlage ausschließen
      </div>
    );
  }

  // If the button "Schritt Wiederholen" is not shown, we just show a Input for the new Autarky Value
  if (stepAction === ActionOption.Proceed) {
    return (
      <AutarkyWithPreviousValue
        index={index}
        plantId={suggestion.plantId}
        previousValue={previousValue}
        loadingPlants={loadingPlants}
      >
        <FormikInput
          id={`${suggestion.plantId}-autarky`}
          name={`suggestions[${index}].value`}
          data-testid={`suggestions[${index}].value`}
          type="number"
          max="100"
          min="0"
          label={null}
          disabled={!suggestion.isSelected}
          appendix="%"
          prependix="Ersatzwert: "
        />
      </AutarkyWithPreviousValue>
    );
  }

  if (showAlternative) {
    return (
      <div css="display: flex; align-items: center;">
        {showAlternative === 'substitute_value' &&
          isEditable &&
          !suggestion.plantFullSupply && (
            <AutarkyWithPreviousValue
              index={index}
              plantId={suggestion.plantId}
              previousValue={previousValue}
              loadingPlants={loadingPlants}
            >
              <FormikInput
                id={`${suggestion.plantId}-autarky`}
                name={`suggestions[${index}].value`}
                data-testid={`suggestions[${index}].value`}
                type="number"
                max="100"
                min="0"
                label={null}
                disabled={!suggestion.isSelected}
                appendix="%"
                prependix="Ersatzwert: "
              />
            </AutarkyWithPreviousValue>
          )}
        {showAlternative === 'exclude_plant' && (
          <div css="display: flex; align-items: center; gap: 10px;">
            <input type="checkbox" disabled defaultChecked />
            Kundenanlage ausschließen
          </div>
        )}

        <Button
          secondary
          onClick={() => handleShowAlternative(null)}
          css="margin-left: 10px; padding-left: 8px; padding-right: 8px;"
        >
          <Icons.Close size={30} />
        </Button>
      </div>
    );
  }

  return (
    <div css="display: flex; align-items: center;">
      {meterUrl && (
        <>
          <LinkButton tertiary as={Link} to={meterUrl} target="_blank">
            <Icons.Pencil size={30} color={theme.primaryColor} />
            Zählerstände bearbeiten
          </LinkButton>
          <span css="text-decoration: underline; margin-right: 20px;">
            oder
          </span>
        </>
      )}
      <Select
        id="exclude-plant"
        disabled={!isEditable}
        name={`PlantListRowWarning-select-${suggestion.plantId}`}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleShowAlternative(e.target.value)
        }
      >
        <option value="0" hidden>
          Alternative wählen
        </option>
        <option value="substitute_value">Ersatzwert eintragen</option>
        <option value="exclude_plant">Kundenanlage ausschließen</option>
      </Select>
    </div>
  );
};
