/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/destructuring-assignment */
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import * as yup from 'yup';
import {
  AlertInfo,
  AlertRetryable,
  Button,
  ButtonIcon,
  ColumnDefinition,
  Icons,
  Table,
} from '@ampeersenergy/ampeers-ui-components';

import {
  PlantDetailExtendedQuery,
  ReadPlantReportsDocument,
  Report,
  UnknownError,
  useCreatePlantReportMutation,
  useReadPlantReportsQuery,
  useRemoveDocumentsMutation,
} from '../../graphql-types';
import { yupUTCDate } from '../../helpers/yupUTCDate';
import { FileIcon, ModalLink } from '../../components';
import {
  ExportFlow,
  ExportFlowProps,
} from '../../components/export-flow/export-flow';
import ClientPlantReportForm from '../report/ClientPlantReportForm';
import {
  getDateTimeFromDateInput,
  formatDateForInput,
} from '../../helpers/dateHelpers';

const Spacer = styled.div`
  margin-top: 40px;
`;

type ReportExportProps = {
  data: PlantDetailExtendedQuery;
  open: boolean;
  onClose: () => void;
};

function ReportExport({ data, open, onClose }: ReportExportProps) {
  const [createPlantReport, { data: createReportData, error }] =
    useCreatePlantReportMutation();

  const createFailed =
    createReportData?.createPlantReport?.__typename === 'UnknownError';

  const hasError = !!(error || createFailed);
  const exportAlreadyExists =
    createReportData?.createPlantReport?.__typename === 'RateLimitReached';

  const generatePlantReport: ExportFlowProps['onGenerate'] = useCallback(
    (
      _,
      {
        salesStartDate,
        salesEndDate,
        accountingPeriodStartDate,
        accountingPeriodEndDate,
      },
    ) => {
      return createPlantReport({
        variables: {
          id: data.readPlant!.id,
          salesStartDate: getDateTimeFromDateInput(salesStartDate),
          salesEndDate: getDateTimeFromDateInput(salesEndDate),
          accountingPeriodStartDate: getDateTimeFromDateInput(
            accountingPeriodStartDate,
          ),
          accountingPeriodEndDate: getDateTimeFromDateInput(
            accountingPeriodEndDate,
          ),
        },
        refetchQueries: [
          {
            variables: { plantId: data?.readPlant?.id },
            query: ReadPlantReportsDocument,
          },
        ],
      })
        .then(({ data: _data }) => {
          if (
            _data?.createPlantReport?.__typename === 'CreatePlantReportSuccess'
          ) {
            return 'done';
          }
          if (_data?.createPlantReport?.__typename === 'RateLimitReached') {
            return 'done';
          }
          onClose();
          return 'export';
        })
        .catch(() => 'done');
    },
    [data, createPlantReport, onClose],
  );

  return (
    <ExportFlow
      types={[
        {
          label: 'Kundenanlagenbericht',
          id: 'plantReport',
          SuccessComponent: exportAlreadyExists
            ? () => (
                <AlertInfo title="Hinweis">
                  Der letzte Export für diese Kundenanlage liegt noch keine 24
                  Stunden zurück.
                  <br />
                  Du musst noch warten bis der nächste Export generiert werden
                  kann.
                </AlertInfo>
              )
            : undefined,
          InnerComponent: () => <ClientPlantReportForm />,
          validationSchema: yup.object().shape({
            salesStartDate: yupUTCDate
              .required()
              .test(
                'salesEndDate',
                'Startdatum darf nicht nach dem Enddatum liegen.',
                (value: Date | undefined, context) => {
                  return (
                    !value ||
                    !context.parent.salesEndDate ||
                    value.getTime() <=
                      new Date(context.parent.salesEndDate).getTime()
                  );
                },
              ),
            salesEndDate: yupUTCDate
              .required()
              .test(
                'salesStartDate',
                'Enddatum darf nicht vor dem Startdatum liegen.',
                (value: Date | undefined, context) => {
                  return (
                    !value ||
                    !context.parent.salesStartDate ||
                    value.getTime() >=
                      new Date(context.parent.salesStartDate).getTime()
                  );
                },
              ),
            accountingPeriodStartDate: yupUTCDate
              .required()
              .test(
                'accountingPeriodEndDate',
                'Startdatum darf nicht nach dem Enddatum liegen.',
                (value: Date | undefined, context) => {
                  return (
                    !value ||
                    !context.parent.accountingPeriodEndDate ||
                    value.getTime() <=
                      new Date(context.parent.accountingPeriodEndDate).getTime()
                  );
                },
              ),
            accountingPeriodEndDate: yupUTCDate
              .required()
              .test(
                'accountingPeriodStartDate',
                'Enddatum darf nicht vor dem Startdatum liegen.',
                (value: Date | undefined, context) => {
                  return (
                    !value ||
                    !context.parent.accountingPeriodStartDate ||
                    value.getTime() >=
                      new Date(
                        context.parent.accountingPeriodStartDate,
                      ).getTime()
                  );
                },
              ),
          }),
        },
      ]}
      onClose={onClose}
      onGenerate={generatePlantReport}
      error={error}
      errorMsg={createFailed ? (error as UnknownError)?.message : undefined}
      hasError={hasError}
      isOpen={open}
      formProps={{
        initialValues: {
          salesStartDate: formatDateForInput(DateTime.now().startOf('month')),
          salesEndDate: formatDateForInput(DateTime.now().endOf('month')),
          accountingPeriodStartDate: formatDateForInput(
            DateTime.now().startOf('year'),
          ),
          accountingPeriodEndDate: formatDateForInput(
            DateTime.now().endOf('year'),
          ),
        },
      }}
      defaultType="plantReport"
    />
  );
}

type ReportsPageProps = {
  data?: PlantDetailExtendedQuery;
  isLoading: boolean;
};

function ReportsPage({ data, isLoading }: ReportsPageProps) {
  const [removeDocument] = useRemoveDocumentsMutation();
  const [documentBeingRemovedId, setDocumentBeingRemovedId] = useState<
    string | null
  >(null);

  const {
    data: plantReports,
    error: reportsError,
    loading: loadingReport,
  } = useReadPlantReportsQuery({
    variables: { plantId: data?.readPlant?.id },
    skip: isLoading,
  });

  const [isOpen, setIsOpen] = useState(false);

  const columns: ColumnDefinition<Report & { loading?: boolean }>[] = useMemo(
    () => [
      {
        Header: 'Berichtname',
        accessor: 'name',
        type: 'string',
      },
      {
        Header: 'Erstellungsdatum',
        accessor: 'createdAt',
        type: 'date',
      },
      {
        Header: 'Datei',
        accessor: 'url',
        disableSortBy: true,
        Cell: (props) => (
          <ModalLink
            url={props.cell.value}
            modalTitle="Kundenanlagenbericht"
            modalAccessibilityContentLabel="Kundenanlagenbericht"
            modalIncludeTokeninURL
            filename={props.row.original.name}
          >
            <FileIcon />
          </ModalLink>
        ),
      },
      {
        Header: 'Löschen',
        accessor: 'id',
        width: 150,
        disableSortBy: true,
        Cell: ({ row, value }) => (
          <ButtonIcon
            icon={Icons.Delete}
            color="#E30045"
            small
            secondary
            isLoading={documentBeingRemovedId === value}
            disabled={documentBeingRemovedId === value || row.original.loading}
            onClick={() => {
              if (value) {
                setDocumentBeingRemovedId(value);
                removeDocument({
                  variables: { hash: value },
                  refetchQueries: [
                    {
                      query: ReadPlantReportsDocument,
                      variables: { plantId: data?.readPlant?.id },
                    },
                  ],
                }).finally(() => setDocumentBeingRemovedId(null));
              }
            }}
          />
        ),
      },
    ],
    [data?.readPlant?.id, documentBeingRemovedId, removeDocument],
  );

  const tableData = React.useMemo(
    () =>
      plantReports?.readPlantReports.filter((r) => r.type === 'plantreport') ??
      [],
    [plantReports],
  );

  return (
    <>
      <Spacer />
      {reportsError && <AlertRetryable error={reportsError} />}
      <Table
        columns={columns}
        compact
        withAlternatingRows
        withBoxShadow
        filterKind="Plant Reports"
        isLoading={isLoading || loadingReport}
        data={tableData}
        renderTableActions={() => (
          <Button onClick={() => setIsOpen(true)} disabled={isLoading}>
            Erstellen
          </Button>
        )}
        initialState={{
          sortBy: [
            {
              id: 'createdAt',
              desc: true,
            },
          ],
        }}
      />
      {data && (
        <ReportExport
          data={data}
          open={isOpen}
          onClose={() => setIsOpen(false)}
        />
      )}
    </>
  );
}

export default ReportsPage;
