import * as React from 'react';
import styled from 'styled-components/macro';
import { Icons } from '@ampeersenergy/ampeers-ui-components';
import { motion } from 'framer-motion';

const FileIcon = styled(Icons.File)`
  position: absolute;

  top: var(--file-icon-top);
  left: var(--file-icon-left);
`;

function MovingDocuments() {
  return (
    <motion.div
      css={`
        position: relative;
        width: 100px;
        height: 100px;
      `}
      initial={
        {
          transform: 'scale(1)',
          '--file-icon-3-rotate': '4deg',
          '--file-icon-2-rotate': '-2deg',
          '--file-icon-1-scale': 0.9,
        } as any
      }
      animate={
        {
          transform: 'scale(1.1)',
          '--file-icon-3-rotate': '16deg',
          '--file-icon-2-rotate': '-15deg',
          '--file-icon-1-scale': 1,
        } as any
      }
      transition={{ duration: 0.4 }}
    >
      <FileIcon
        size="50"
        color="#F5A623"
        style={
          {
            '--file-icon-top': '15px',
            '--file-icon-left': '65px',
            transform: 'rotate(var(--file-icon-3-rotate))',
          } as React.CSSProperties
        }
      />
      <FileIcon
        size="60"
        color="#B8E986"
        style={
          {
            '--file-icon-top': '-5px',
            '--file-icon-left': '-20px',
            '--file-icon-rotate': '-15deg',
            transform: 'rotate(var(--file-icon-2-rotate))',
          } as React.CSSProperties
        }
      />
      <FileIcon
        size="90"
        color="primary"
        style={{
          transform: 'scale(var(--file-icon-1-scale))',
        }}
      />
    </motion.div>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 990;

  background-color: rgba(255, 255, 255, 0.8);
`;

const Text = styled.span`
  font-size: 18px;
  line-height: 1.5;
  text-align: center;
`;

type WrapperOverlayProps = {
  name?: string;
};

export default function WrapperOverlay({ name }: WrapperOverlayProps) {
  const label = name ? (
    <>
      Dokument zu <br />
      {name} hinzufügen
    </>
  ) : (
    <>Dokument hinzufügen</>
  );

  return (
    <Wrapper>
      <MovingDocuments />
      <Text>{label}</Text>
    </Wrapper>
  );
}
