import React from 'react';
import { Button, FlexRow, Modal } from '@ampeersenergy/ampeers-ui-components';
import styled, { useTheme } from 'styled-components';
import { useFormikContext } from 'formik';

import { CreateContractInput } from '../graphql-types';

import { IbanExistsErrorClass } from './graphql-form/types';

const Buttons = styled(FlexRow)`
  margin-top: 30px;

  display: flex;
  justify-content: space-between;
`;

export default function MultipleBankDetailsModal({
  closeModal,
  error,
}: {
  closeModal: ({ changeStep }: { changeStep?: boolean }) => void;
  error: IbanExistsErrorClass;
}) {
  const { submitForm, setFieldValue, values } =
    useFormikContext<CreateContractInput>();
  const { payers, accountHolderId, accountNumber } = error;
  const theme = useTheme();
  const handleSubmit = () => {
    closeModal({ changeStep: false });
    submitForm();
  };

  const handleCloseModal = () => {
    closeModal({ changeStep: true });
    setFieldValue('customer.payer', null);
  };

  React.useEffect(() => {
    const { iban, bic } = values.customer.bankAccount || {};

    setFieldValue('customer.payer.accountHolderId', accountHolderId);
    setFieldValue('customer.payer.name', payers[0].payerName);
    setFieldValue('customer.payer.number', accountNumber);
    setFieldValue('customer.payer.iban', iban);
    setFieldValue('customer.payer.bic', bic);
  }, [
    setFieldValue,
    accountHolderId,
    values.customer.bankAccount,
    accountNumber,
    payers,
  ]);

  return (
    <Modal
      isOpen
      contentLabel="multiple-bank-details-modal"
      title="Bankverbindung bereits verwendet"
      onRequestClose={handleCloseModal}
      maxWidth="sm"
    >
      {payers.length === 1 ? (
        <>
          Die Bankverbindung wird bereits vom Vertragspartner
          <br />
          {payers[0].payerName} ({payers[0].contractNumber}) verwendet.
          <br />
        </>
      ) : (
        <>
          Die Bankverbindung wird bereits von folgenden Vertragspartnern
          verwendet:
          <br />
          <ol>
            {payers.map((payer) => (
              <li key={payer.contractNumber}>
                {payer.payerName} ({payer.contractNumber})
              </li>
            ))}
          </ol>
        </>
      )}
      <br />
      Bist Du sicher, dass die gleiche Bankverbindung für diesen Vertrag
      verwendet werden soll?
      <Buttons>
        <Button onClick={handleSubmit} secondary>
          Übernehmen
        </Button>
        <Button
          onClick={handleCloseModal}
          customTheme={{
            primaryColor: theme.palette.error.color,
            secondaryColor: theme.palette.error.background,
          }}
        >
          Andere Verbindung
        </Button>
      </Buttons>
    </Modal>
  );
}

const ButtonWrapper = styled(FlexRow)`
  margin-top: 30px;

  display: flex;
  justify-content: flex-end;
`;

// This component is here because the error happens because of the Iban Exists error
export function MeterCreatedErrorModal({
  closeModal,
}: {
  closeModal: () => void;
}) {
  const [isOpen, setIsOpen] = React.useState(true);
  const { values } = useFormikContext<CreateContractInput>();
  const { meterNumber } = values.contractMeter.meter.new || {};

  const handleCloseModal = () => {
    setIsOpen(false);
    closeModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      contentLabel="error-meter-created-modal"
      title="Zähler erfolgreich angelegt"
      onRequestClose={handleCloseModal}
      maxWidth="sm"
    >
      <p>
        Der Zähler <strong> {meterNumber || ''} </strong> wurde erfolgreich
        angelegt. Bitte wähle diesen unter den bestehenden Zählern in der
        Dropdownliste aus.
      </p>
      <ButtonWrapper>
        <Button onClick={handleCloseModal}>Okay</Button>
      </ButtonWrapper>
    </Modal>
  );
}
