import gql from 'graphql-tag';

export default gql`
  fragment fullAddress on Address {
    streetName
    streetNumber
    zipCode
    city
    country
  }
`;
