import * as yup from 'yup';

import './yupSetup';

export const yupAddress = (
  required?: boolean,
  extended?: Record<string, yup.AnySchema>,
) => {
  return yup.object().shape({
    streetName: yup.string().min(1).max(63).isRequired(required),
    streetNumber: yup.string().min(1).max(40).isRequired(required),
    zipCode: yup.string().min(1).max(7).isRequired(required),
    city: yup.string().min(1).max(63).isRequired(required),
    ...extended,
  });
};
