/**
 * validate as false multiple consecutive spaces
 * or a space at the beginning to avoid having meterNumbers
 * like " ", " a";
 */
export const isValidMeterNumber = (value: string | number | undefined) => {
  if (typeof value === 'number' || value === undefined) return true;
  if (/\s\s+|^\s/.test(value) || !value) {
    return false;
  }
  return true;
};
