/* eslint-disable react/destructuring-assignment */
import React from 'react';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import ReactTooltip from 'react-tooltip';

import { Bold } from '../layout';
import { Legend, LegendItem } from '../legend';

const W = styled.div`
  height: 21px;
  padding-top: 5px;

  .animate {
    transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transform-origin: left;
  }

  .display-enter .animate,
  .display-appear .animate {
    transform: scaleX(0);
  }
  .display-enter-active .animate,
  .display-appear-active .animate {
    transform: scaleX(1);
  }
`;

const Bar = styled.div`
  height: 10px;
  background: #d8d8d8;
  border-radius: 4px;
  overflow: hidden;
  font-size: 11px;
  position: relative;
`;

const Members = styled.div`
  background: #1848ff;
  height: 10px;
  position: absolute;
`;

const Third = styled.div`
  background: #14dce8;
  height: 10px;
  position: absolute;
  transition-delay: 0.5s !important;
`;

const Empty = styled.div`
  background: #d8d8d8;
  height: 10px;
  position: absolute;
  transition-delay: 0.5s !important;
`;

const SpacedBold = styled(Bold)`
  margin-left: 5px;
`;

export function UnitRatioRenderer(props: any) {
  const [total, member, /*  empty, */ third] = props.row.original.ratio;
  return (
    <>
      <W data-for={`ratio-tip-${props.row.original.id}`} data-tip>
        <CSSTransition
          in
          timeout={1000}
          classNames="display"
          unmountOnExit
          appear
        >
          <Bar>
            {member === 0 && third === 0 ? (
              <Empty style={{ width: '100%' }} className="animate" />
            ) : (
              <>
                <Members
                  style={{ width: `${(member / total) * 100}%` }}
                  className="animate"
                />
                <Third
                  style={{
                    width: `${(third / total) * 100}%`,
                    left: `${(member / total) * 100}%`,
                  }}
                  className="animate delay"
                />
              </>
            )}
          </Bar>
        </CSSTransition>
      </W>
      <ReactTooltip
        id={`ratio-tip-${props.row.original.id}`}
        place="bottom"
        type="light"
        effect="solid"
        className="customTooltip"
      >
        <Legend>
          <LegendItem color="#1848ff">
            Teilnehmer <SpacedBold>{member}</SpacedBold>
          </LegendItem>
          <LegendItem color="#14dce8">
            Dritte <SpacedBold>{third}</SpacedBold>
          </LegendItem>
          {/* <LegendItem color="#d8d8d8">
            Leer <SpacedBold>{empty}</SpacedBold>
          </LegendItem> */}
        </Legend>
      </ReactTooltip>
    </>
  );
}

export function UnitRatioHeaderRenderer() {
  return (
    <>
      Ratio
      <Legend>
        <LegendItem color="#1848ff">Teilnehmer</LegendItem>
        <LegendItem color="#14dce8">Dritte</LegendItem>
        <LegendItem color="#d8d8d8">Leer</LegendItem>
      </Legend>
    </>
  );
}
