import { DeepExtractType } from 'ts-deep-extract-types';

import {
  InvoiceCycle,
  ReadAccountingOverviewQuery,
} from '../../../graphql-types';

export type InvoicePeriod = DeepExtractType<
  ReadAccountingOverviewQuery,
  ['readAccountingOverview', 'periods']
>[0];

export type ClosingAccountingInvoice = DeepExtractType<
  InvoicePeriod,
  ['closingAccounting']
>;
export type AccountingInvoiceGroup = DeepExtractType<
  InvoicePeriod,
  ['standardAccounting']
>;

export type StandardAccountingInvoice = DeepExtractType<
  AccountingInvoiceGroup,
  ['monthlySettlement']
>;

export type YearlyAccountingInvoice = DeepExtractType<
  AccountingInvoiceGroup,
  ['yearlySettlement']
>;

export enum PeriodEnum {
  Final = 'final',
}

export const Periods = {
  monthly: InvoiceCycle.Monthly,
  quarterly: InvoiceCycle.Quarterly,
  halfyearly: InvoiceCycle.HalfYearly,
  yearly: InvoiceCycle.Yearly,
  final: PeriodEnum.Final,
};
