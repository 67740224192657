import React from 'react';

import { ContainerRenderFunction } from '../../types/util.types';
import { CRM_DATA_MOCK } from '../../mock';
import { CrmBoardType } from '../../presentations/crm-board/crm-board.types';

export type CrmDataContainerPropType = {
  render: ContainerRenderFunction<CrmBoardType>;
};

function CrmDataContainer(
  { render }: any /* ReactPropTypeWithChildren<CrmDataContainerPropType> */,
) {
  return (
    <div>
      {render({
        data: CRM_DATA_MOCK,
        loading: false,
      })}
    </div>
  );
}

export default CrmDataContainer;
