import React from 'react';

import { ReadContractQuery } from '../../../../graphql-types';

import MeterComponent from './meterComponent';

function MeterPage({
  contract,
  isLoading,
}: {
  contract?: ReadContractQuery['readContract'];
  isLoading: boolean;
}) {
  return <MeterComponent contract={contract} isLoading={isLoading} />;
}

export default MeterPage;
