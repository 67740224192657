import {
  AlertRetryable,
  DialogProvider,
  FlexRow,
  PaddedShadowBox,
  PageTitleLayout,
  WorkflowStep,
} from '@ampeersenergy/ampeers-ui-components';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import { DocTitle } from '../../../components/docTitle';
import {
  AccountingRunWorkflowStepName,
  StepStatus,
  useGetAccountingRunWorkflowStateQuery,
} from '../../../graphql-types';

import { DetailsActions } from './DetailsActions';
import { StepsDetails, Wrapper as StepDetailWrapper } from './StepsDetails';
import { StepsSidebar } from './StepsSidebar';

const Steps = styled(PaddedShadowBox)`
  padding: 10px;
  margin-right: 5px;
  width: 300px;
`;

const StyledPageTitleLayout = styled(PageTitleLayout)`
  padding: 10px 0;
`;

const POLL_INTERVAL = 1500;

interface WorkflowDetailsWrapperProps {
  name?: string;
  actions?: React.ReactNode;
}

function WorkflowDetailsPageWrapper({
  name,
  actions,
  children,
}: React.PropsWithChildren<WorkflowDetailsWrapperProps>) {
  return (
    <>
      <DocTitle titleParts={[name ?? '', 'Abrechnung']} />
      <StyledPageTitleLayout title={name ?? '...'} actions={actions} />
      <FlexRow>{children}</FlexRow>
    </>
  );
}

export function AccountingRunDetailsPage() {
  const { id } = useParams<{ id: string }>();
  const [selectedStep, setSelectedStep] = React.useState<
    AccountingRunWorkflowStepName | undefined
  >();
  const [jumpToActive, setJumpToActive] = React.useState(false);

  const { data, loading, error, refetch, startPolling, stopPolling } =
    useGetAccountingRunWorkflowStateQuery({
      variables: {
        workflowId: id,
      },
      skip: !id,
    });

  const workflow = React.useMemo(() => {
    const workflowState = data?.getAccountingRunWorkflowState;
    if (!workflowState) return undefined;

    return {
      ...workflowState,
      steps: [...workflowState.steps].sort((a, b) => a.index - b.index),
    };
  }, [data]);

  React.useEffect(() => {
    refetch();
  }, [refetch]);

  React.useEffect(() => {
    if (!workflow) return;

    const hasActiveStep = workflow.steps.some(
      (s) => s.status === StepStatus.Active,
    );

    if (hasActiveStep) {
      startPolling(POLL_INTERVAL);
      return;
    }

    setJumpToActive(false);
    stopPolling();

    return () => {
      stopPolling();
    };
  }, [selectedStep, startPolling, stopPolling, workflow]);

  React.useEffect(() => {
    if (!selectedStep || jumpToActive) {
      setSelectedStep(workflow?.steps.find((step) => step.active)?.name);
    }
  }, [selectedStep, workflow?.steps, jumpToActive]);

  const transistionToNextStep = React.useCallback(async () => {
    await refetch();
    startPolling(POLL_INTERVAL);
    setJumpToActive(true);
  }, [refetch, startPolling]);

  const currentStep = workflow?.steps.find(
    (step) => step.name === selectedStep,
  );

  if (error) {
    if (error.message === 'Accounting run workflow not found') {
      return (
        <AlertRetryable
          message="Der angeforderte Abrechnungslauf konnte nicht gefunden werden"
          retryable={false}
        />
      );
    }
    return <AlertRetryable error={error} />;
  }

  if (loading) {
    return (
      <WorkflowDetailsPageWrapper name="Lädt...">
        <Steps>
          {new Array(5).fill(null).map((_, index: number) => (
            <WorkflowStep
              label=""
              state="loading"
              onClick={() => null}
              isSelected={false}
              key={index}
            />
          ))}
        </Steps>
        <StepDetailWrapper />
      </WorkflowDetailsPageWrapper>
    );
  }

  if (!workflow) {
    return (
      <AlertRetryable
        message="Der angeforderte Abrechnungslauf konnte nicht gefunden werden"
        retryable={false}
      />
    );
  }

  return (
    <DialogProvider>
      <WorkflowDetailsPageWrapper
        name={workflow.name}
        actions={<DetailsActions workflow={workflow} workflowId={id} />}
      >
        <Steps>
          <StepsSidebar
            steps={workflow.steps}
            selectedStep={selectedStep}
            setActive={setSelectedStep}
          />
        </Steps>
        <StepsDetails
          workflowId={id}
          step={currentStep}
          workflow={workflow}
          onStepAction={transistionToNextStep}
        />
      </WorkflowDetailsPageWrapper>
    </DialogProvider>
  );
}
