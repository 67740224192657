import styled from 'styled-components';
import { ButtonGroup } from '@ampeersenergy/ampeers-ui-components';
import { Link } from 'react-router-dom';

export const ActionBtns = styled(ButtonGroup)`
  margin-top: 10px;
`;

export const Spacer = styled.div`
  width: 15px;
`;

export const StyledLink = styled(Link)`
  &:hover {
    text-decoration: underline ${(props) => props.theme.primaryColor};
  }
`;

export const RadioButtonStyling = styled.div.attrs<{ compact?: boolean }>(
  (props) => ({
    className: `${props.compact ? 'compact' : ''}`,
  }),
)<{ compact?: boolean }>`
  input:focus {
    box-shadow: none;
  }
  input {
    width: auto;
  }
  label {
    display: inline;
    margin-left: 25px;
  }
  input,
  label {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .input-group {
    border: none;
    background: none;
    border-radius: 0;
  }

  &.compact {
    div > div > label {
      margin-left: 5px;
      font-size: 14px;
    }
    input,
    label {
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
`;
