import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useFormikContext } from 'formik';
import { DateTime } from 'luxon';

import { Flex, FlexRow } from '../../../../../../components';
import { GraphqlFormField } from '../../../../../../components/graphql-form/render';
import { useGraphqlForm } from '../../../../../../components/graphql-form/hooks/useGraphqlForm';
import { useGetExtrapolatedMeterReadingQuery } from '../../../../../../graphql-types';
import { formatMeterReadingValue } from '../../../../../../helpers/formatStrings';
import InputSuggestion from '../../../../../../components/inputSuggestion';

type AddMeterReadingFormProps = {
  fieldNamePrefix: string;
  meterId: string;
  meterType: string;
  existingReadings?: {
    date: string;
    value: string;
    reason: string;
    obis: string;
  }[];
};

function isDateAfterLastReadingDate(
  date?: string,
  existingReadings?: AddMeterReadingFormProps['existingReadings'],
) {
  if (!existingReadings || !date) return false;

  const lastMeterReading = existingReadings[existingReadings.length - 1];

  if (!lastMeterReading) return false;

  return DateTime.fromISO(date) >= DateTime.fromISO(lastMeterReading.date);
}

const MeterReadingTooltip = styled.div`
  font-weight: normal;
  letter-spacing: 0;

  > ul {
    margin: 0;
    padding: 0 12px;
  }
`;

export function AddMeterReadingForm({
  fieldNamePrefix,
  meterId,
  meterType,
  existingReadings,
}: AddMeterReadingFormProps) {
  const { formVariables } = useGraphqlForm();
  const { values, setFieldValue } = useFormikContext<{ date: string }>();

  const [disableInputs, setDisableInputs] = useState(false);
  const [displayExtrapolatedValue, setDisplayExtrapolatedValue] =
    useState(false);

  const {
    data: extrapolatedMeterReading,
    loading: loadingExtrapolatedMeterReading,
  } = useGetExtrapolatedMeterReadingQuery({
    variables: {
      meterId,
      date: values.date
        ? DateTime.fromISO(values.date).plus({ day: 1 }).toISO()
        : undefined,
    },
    skip:
      !['MSO-W', 'MSO-L', 'MSO-A', 'MSO-P'].includes(meterType) ||
      !meterId ||
      !values?.date ||
      !isDateAfterLastReadingDate(values?.date, existingReadings),
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (!displayExtrapolatedValue && !loadingExtrapolatedMeterReading) {
      setDisplayExtrapolatedValue(true);
    }
    // user changes something in the form -> query is executed again ->
    // loading state changes to true -> query finishes -> loading state changes to false ->
    // UI displays the loaded extrapolated value, if available
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingExtrapolatedMeterReading]);

  const extrapolatedMeterReadingValue = useMemo(() => {
    if (
      extrapolatedMeterReading?.getExtrapolatedMeterReading?.__typename ===
      'ExtrapolatedReading'
    ) {
      return extrapolatedMeterReading.getExtrapolatedMeterReading.value;
    }
  }, [extrapolatedMeterReading]);

  const applyExtrapolatedValue = useCallback(() => {
    try {
      if (window.clarity) {
        window.clarity?.('meter', 'use_extrapolated_meter_reading');
      }
    } catch (error) {
      console.error(error);
    }
    if (
      extrapolatedMeterReading?.getExtrapolatedMeterReading?.__typename ===
      'ExtrapolatedReading'
    ) {
      const { value } = extrapolatedMeterReading.getExtrapolatedMeterReading;
      if (value) {
        setFieldValue('value', formatMeterReadingValue(value, false));
        setFieldValue('reason', 'PMR'); // Turnusablesung
        setFieldValue('valueStatus', '67'); // Ersatzwert
        setDisplayExtrapolatedValue(false);
        setDisableInputs(true);
      }
    }
  }, [extrapolatedMeterReading, setFieldValue, setDisplayExtrapolatedValue]);

  return (
    <>
      <FlexRow>
        <Flex>
          <GraphqlFormField
            name={`${fieldNamePrefix}date`}
            onChange={() => {
              setFieldValue(`${fieldNamePrefix}value`, undefined);
              setFieldValue(`${fieldNamePrefix}reason`, undefined);
              setFieldValue(`${fieldNamePrefix}valueStatus`, undefined);
              setDisableInputs(false);
            }}
          />
        </Flex>
      </FlexRow>
      <FlexRow>
        <Flex>
          <GraphqlFormField
            name={`${fieldNamePrefix}obis`}
            disabled={!formVariables?.showObisDropdown}
          />
        </Flex>
      </FlexRow>
      <FlexRow>
        <Flex>
          <GraphqlFormField
            name={`${fieldNamePrefix}value`}
            asType="string"
            disabled={disableInputs}
            tooltipText={
              ['MSO-W', 'MSO-L', 'MSO-A', 'MSO-P'].includes(meterType) ? (
                <MeterReadingTooltip>
                  <b>Zukünftige Zählerstände schätzen</b>
                  <br />
                  Du kannst Zählerstände für Turnusablesungen schätzen lassen.
                  Passe hierfür das Ablesedatum an.
                  <br />
                  <br />
                  Bitte beachte:
                  <ul>
                    <li>Nur verfügbar für MSO-W / A / P / L.</li>
                    <li>
                      Für die Schätzung sind zwei echte Zählerstande nötig.
                    </li>
                    <li>
                      Das letzte Ablesedatum darf nicht älter als 90 Tage sein.
                    </li>
                  </ul>
                </MeterReadingTooltip>
              ) : null
            }
          />
          {extrapolatedMeterReadingValue && displayExtrapolatedValue ? (
            <InputSuggestion
              message="Geschätzter Zählerstand:"
              value={formatMeterReadingValue(extrapolatedMeterReadingValue)}
              onAccept={applyExtrapolatedValue}
            />
          ) : null}
        </Flex>
      </FlexRow>
      <FlexRow>
        <Flex>
          <GraphqlFormField
            name={`${fieldNamePrefix}reason`}
            disabled={disableInputs}
          />
        </Flex>
      </FlexRow>
      <FlexRow>
        <Flex>
          <GraphqlFormField
            name={`${fieldNamePrefix}valueStatus`}
            disabled={disableInputs}
          />
        </Flex>
      </FlexRow>
    </>
  );
}

export default AddMeterReadingForm;
