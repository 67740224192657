import gql from 'graphql-tag';

export default gql`
  mutation createMetersBatched($meters: [CreateMetersBatchedInput!]!) {
    createMetersBatched(meters: $meters) {
      __typename
      succeeded {
        meterNumber
        plantName
      }
      failed {
        error
        message
        meterNumber
        plantName
      }
    }
  }
`;
