/* eslint-disable react/destructuring-assignment */
import { useApolloClient } from '@apollo/client';
import {
  DialogProvider,
  LinkedTab,
  LinkedTabs,
} from '@ampeersenergy/ampeers-ui-components';
import React, { useMemo } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Route, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import { Flex, FlexRow, PageTitle, SubTitle } from '../../../components';
import { DocTitle } from '../../../components/docTitle';
import UploadDropzone from '../../../components/document-upload/UploadDropzone';
import ErrorBoundary from '../../../components/errorBoundary';
import ErrorMsg from '../../../components/errorMsg';
import { NestedPage } from '../../../components/nestedPage';
import {
  ContractExcerptLabelFragment,
  useReadContractFastQuery,
  useReadContractQuery,
} from '../../../graphql-types';
import { LOADING_STR } from '../../../helpers/formatStrings';
import { MeterDetailPage } from '../../plant/meter';
import TariffDetailPage from '../../tariff/tariffDetailPage';
import { contractExcerptLabelFragment } from '../../../queries/fragments/contractExcerptLabel';

import AccountingPage from './accounting';
import ContractPage from './contract';
import CustomerPage from './customer';
import CustomerCommunicationPage from './customerCommunication';
import { ContractSidebar } from './documents/contractSidebar';
import Metadata from './metadata';
import MeterPage from './meter';

const TabContainer = styled(Flex)`
  min-width: 650px;
`;

const contractIndexPageTitle = 'Vertrag';

function ContractIndexPage(props: RouteComponentProps<any>) {
  const { contractId } = props.match.params;

  const {
    data,
    error,
    loading: contractLoading,
    refetch,
  } = useReadContract(contractId);

  const loading = contractLoading && !data;

  const pageTitle = loading
    ? LOADING_STR
    : data?.readContract?.customer.person.name ?? '';

  let inner;

  if (contractId === null) {
    inner = <ErrorMsg message="Id des Vertrages fehlt." />;
  } else if (error) {
    inner = <ErrorMsg error={error} />;
  } else if (data && !loading && data.readContract === null) {
    inner = <ErrorMsg message="Kein Vertrag gefunden." />;
  } else {
    inner = (
      <UploadDropzone
        accept="application/pdf"
        maxSize={25000000}
        contract={data?.readContract}
      >
        <FlexRow>
          <TabContainer>
            <LinkedTabs basePath={props.match.url}>
              <LinkedTab title="Stammdaten" path="/masterdata">
                <CustomerPage
                  contract={data?.readContract}
                  isLoading={loading}
                />
              </LinkedTab>
              <LinkedTab title="Vertrag" path="/contract">
                <ContractPage
                  contract={data?.readContract}
                  isLoading={loading}
                />
              </LinkedTab>
              <LinkedTab title="Abrechnung" path="/accounting">
                <AccountingPage
                  contract={data?.readContract}
                  isLoading={loading}
                />
              </LinkedTab>
              <LinkedTab title="Messpunkt" path="/measurement">
                <MeterPage contract={data?.readContract} isLoading={loading} />
              </LinkedTab>
              <LinkedTab title="Kommunikation" path="/communication">
                <CustomerCommunicationPage
                  contractId={data?.readContract?.id}
                />
              </LinkedTab>
              <LinkedTab title="Metadaten" path="/metadata">
                <Metadata contract={data?.readContract} isLoading={loading} />
              </LinkedTab>
            </LinkedTabs>
          </TabContainer>
          <ErrorBoundary>
            <DialogProvider>
              <ContractSidebar
                documents={data?.readContract?.documents ?? []}
                contractStatus={data?.readContract?.status}
                customerEmail={
                  data?.readContract?.customer.addressBilling.email
                }
                contractId={
                  data?.readContract?.id
                    ? data?.readContract?.id.toString()
                    : undefined
                }
                documentDeliveryMethod={
                  data?.readContract?.customer.documentDeliveryMethod!
                }
                refetch={refetch}
              />
            </DialogProvider>
          </ErrorBoundary>
        </FlexRow>
      </UploadDropzone>
    );
  }
  return (
    <>
      <DocTitle
        titleParts={
          data?.readContract
            ? [pageTitle, contractIndexPageTitle]
            : [contractIndexPageTitle]
        }
      />
      <NestedPage levelsUpToParent={3}>
        <PageTitle>{pageTitle}</PageTitle>
        {inner}
        <Route
          path={[
            // Since labels or names can include slashes "/", we don't want to add them to the URL.
            // But we also don't want to break existing URLs (for the time being).
            // That's why we match the URLs with labels or names here, but mark them as deprecated.
            // Note: In react-router v6 these partial dynamic segments are disallowed.
            // See https://reactrouter.com/en/main/route/route#dynamic-segments
            `${props.match.path}/contract/tariff/:tariffId-:tariffInternalNameDeprecated`,
            `${props.match.path}/contract/tariff/:tariffId`,
          ]}
        >
          <ContractTariffPage />
        </Route>
        <Route path={`${props.match.path}/measurement/meter/:meterId`}>
          <ContractMeterPage />
        </Route>
      </NestedPage>
    </>
  );
}

/**
 * Tries to use `readContractFast` by utilizing the local cache to get `contractLabel`.
 * Falls back to using `readContract` otherwise.
 */
function useReadContract(contractId: string) {
  const client = useApolloClient();
  const contractLabelFragment = useMemo(
    () =>
      // Purposely don't watch the cache (via `useFragment` for example)
      // because we don't want to run the query again when the `contractLabel` is loaded into the cache.
      client.readFragment<ContractExcerptLabelFragment>({
        id: `ContractExcerpt:${contractId}`,
        fragment: contractExcerptLabelFragment,
      }),
    [client, contractId],
  );
  const contractLabel = contractLabelFragment?.label;

  const fastRead = useReadContractFastQuery({
    variables: {
      contractId,
      contractLabel: contractLabel!,
    },
    skip: contractLabel === undefined,
    fetchPolicy: 'cache-and-network',
  });

  const normalRead = useReadContractQuery({
    variables: {
      contractId,
    },
    skip: contractLabel !== undefined,
    fetchPolicy: 'cache-and-network',
  });

  return contractLabel !== undefined ? fastRead : normalRead;
}

function ContractTariffPage() {
  return (
    <NestedPage
      levelsUpToParent={3}
      css={`
        z-index: 10;
      `}
    >
      <TariffDetailPage showPageTitleLayout />
    </NestedPage>
  );
}

function ContractMeterPage() {
  const { meterId } = useParams<{ meterId: string }>();
  return (
    <NestedPage
      levelsUpToParent={3}
      css={`
        z-index: 10;
      `}
    >
      <SubTitle>{meterId}</SubTitle>
      <MeterDetailPage />
    </NestedPage>
  );
}

export default withRouter(ContractIndexPage);
