export interface Workflow {}

export enum ContractType {
  tenant = 'tenant',
  building = 'building',
}

export enum MeterError {
  missingMeterReading = 'missingMeterReading',
  invalidMeterReading = 'invalidMeterReading',
  NON_INTERPOLATABLE_ACCOBJ_TYPE = 'NON_INTERPOLATABLE_ACCOBJ_TYPE',
  NO_METERREADING_FOR_END_OF_ACC_PERIOD = 'NO_METERREADING_FOR_END_OF_ACC_PERIOD',
  BEGINREADING_GREATER_THAN_END_READING = 'BEGINREADING_GREATER_THAN_END_READING',
  CONCESSIONLEVY_NOT_FOUND = 'CONCESSIONLEVY_NOT_FOUND',
  CONTRACT_HAS_MORE_THAN_1_ACCOUNTING_IN_CURRENT_PERIOD = 'CONTRACT_HAS_MORE_THAN_1_ACCOUNTING_IN_CURRENT_PERIOD',
  NETWORKCHARGES_NOT_FOUND = 'NETWORKCHARGES_NOT_FOUND',
  CONTRACT_MERGED_EVENTS_INVALID = 'CONTRACT_MERGED_EVENTS_INVALID',
  CONTRACT_NOT_FOUND_OR_INVALID = 'CONTRACT_NOT_FOUND_OR_INVALID',
  CC_IS_NOT_IN_TARIFF_INTERVAL = 'CC_IS_NOT_IN_TARIFF_INTERVAL',
  TOO_FEW_RLM_READINGS_FOUND = 'TOO_FEW_RLM_READINGS_FOUND',
  TOO_MANY_RLM_READINGS_FOUND = 'TOO_MANY_RLM_READINGS_FOUND',
}

export interface ContractExcerpt {
  name: string;
  id: number;
  customerLabel: string;
  contractLabel: string;
  type: ContractType;
  plantId: number;
  projectId: number;
}

export interface ValidateContractResponse {
  additionalErrors: ValidationError[];
  contracts: ValidatedContractMeter[];
}

export interface ValidationError {
  type: 'plant' | 'contract' | 'contractcomponent';
  sourceId: number;
  contractId?: number; // in case of contractcomponent
  errorString: string;
}

export interface ValidatedContractMeter extends ContractExcerpt {
  meters: {
    meterId: number;
    meterNumber: string;
    errors: {
      reason: MeterError;
      date: Date;
    }[];
  }[];
}

export interface CalculatedContractConsumption extends ContractExcerpt {
  errors: string[];
}

interface AutarkySuggestion {
  plantId: number;
  plantName: string;
  plantFullSupply: boolean;
}

export interface AutarkySuggestionStandard extends AutarkySuggestion {
  autarky: number;
  unroundedAutarky: number;
  warning?: 'DIRECT_CONSUMPTION_AMOUNT_NOT_PLAUSIBLE';
}

export const suggestionHasError = (
  s: PlantAutarkySuggestion,
): s is AutarkySuggestionStandardError | AutarkySuggestionClosingError => {
  return (
    (s as AutarkySuggestionStandardError | AutarkySuggestionClosingError)
      .errorReason !== undefined
  );
};

export const suggestionIsClosingAccountingRun = (
  s: PlantAutarkySuggestion,
): s is AutarkySuggestionClosing => {
  return (s as AutarkySuggestionClosing).calculatedAt !== undefined;
};

export interface AutarkySuggestionStandardError extends AutarkySuggestion {
  errorReason:
    | 'PLANT_MISSES_MSO_H_CONTRACT'
    | 'PLANT_METER_READINGS_NOT_ERROR_FREE'
    | 'BUILDINGS_METERS_DOES_NOT_MATCH_MEASUREMENT_CONCEPT'
    | 'BUILDINGS_METERS_MISSING_METER_READINGS';
}

export interface AutarkySuggestionClosing extends AutarkySuggestion {
  autarky: number;
  calculatedAt: string;
  validityStartAt: string;
  validityEndAt: string;
}

export interface AutarkySuggestionClosingError extends AutarkySuggestion {
  errorReason: 'NO_AUTARKY_CALCULATED_IN_THE_PAST';
}

export type PlantAutarkySuggestionErrors =
  | AutarkySuggestionStandardError
  | AutarkySuggestionClosingError;

export type PlantAutarkySuggestion =
  | AutarkySuggestionStandard
  | PlantAutarkySuggestionErrors
  | AutarkySuggestionClosing;

export type PlantAutarkyDeprecatedErrors =
  | 'PLANT_TYPE_FULL_SUPPLY'
  | 'DIRECT_CONSUMPTION_AMOUNT_NOT_PLAUSIBLE'
  | 'MSO_H_PLANT_METER_HAS_MORE_THAN_ONE_CONTRACT'
  | 'INVALID_CALCULATED_AUTARKIES'
  | 'INVALID_AUTARKY_DATA_FOR_SESSION'
  | 'INVALID_PLANTS_WITH_AUTARKY';

export interface SetPlantAutarky {
  plantId: number;
  autarky: number;
}

export enum AccountingType {
  standardAccounting = 'standardAccounting',
  closingAccountingMove = 'closingAccountingMove',
  closingAccountingThird = 'closingAccountingThird',
  betweenAccounting = 'betweenAccounting',
  correctionAccounting = 'correctionAccounting',
}

export interface CreateAccountingPayload {
  settlementDate: string;
  accountingPeriod: string[];
  contractToAccount: number[];
  reductionTax: boolean;
  accountingType: AccountingType;
  plantsToAccount?: number[];
}

export interface OPCResponse {
  passed: {
    id: number;
  }[];
  errored: {
    id: number;
    error: string;
  }[];
  opc: {
    underlyingData: any;
    customerData: any;
    tariff: any;
    accountingType: string;
  };
}

export interface Invoice {
  amount: number;
  date: string;
  name: string;
  ref: string;
  type?: string;
}
