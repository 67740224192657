/* eslint-disable react/no-unstable-nested-components */
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  AlertRetryable,
  Button,
  ButtonIcon,
  ColumnDefinition,
  FlexRow,
  Header,
  Headline as HeadlineComponent,
  Icons,
  Main,
  Segment,
  Table as TableComponent,
} from '@ampeersenergy/ampeers-ui-components';

import {
  ReadReportsDocument,
  useReadReportsQuery,
  useRemoveDocumentsMutation,
} from '../../graphql-types';
import { Spinner as SpinnerComponent } from '../../components';
import { NestedPage } from '../../components/nestedPage';
import { formatDate, formatString } from '../../helpers/formatStrings';
import DownloadLink from '../../components/downloadLink';
import { DocTitle } from '../../components/docTitle';
import { useHasRole } from '../../components/useHasRole';

import {
  GenerateReportModal,
  readReportsQueryTypes,
} from './generateReportModal';

const FadedText = styled.span`
  opacity: 0.3;
`;

const Spinner = styled(SpinnerComponent)`
  margin-left: 6px;
`;

const Table = styled(TableComponent)`
  .wrapper {
    width: unset;
    margin-bottom: 30px;
  }
`;

const Headline = styled(HeadlineComponent)`
  margin-bottom: 20px;
`;

const reportPageTitle = 'Berichte';

function PageTitleSnippet() {
  return <DocTitle titleParts={[reportPageTitle]} />;
}

function ReportsPage() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    data,
    error,
    loading,
    refetch: refetchReadReports,
  } = useReadReportsQuery({
    variables: {
      types: readReportsQueryTypes,
    },
  });
  const [removeDocument] = useRemoveDocumentsMutation();
  const [documentBeingRemovedId, setDocumentBeingRemovedId] = useState<
    string | null
  >(null);
  const { hasRole: hasOperationsRole } = useHasRole('ae-operations');
  const { hasRole: hasETaxReportRole } = useHasRole('feature_etax_report');

  const columns: ColumnDefinition<any>[] = useMemo(
    () => [
      {
        Header: 'Berichtname',
        accessor: 'name',
        Cell: ({ value, row }) =>
          row.original.loading ? (
            <FlexRow>
              <FadedText>{value}</FadedText>
              <Spinner size={20} />
            </FlexRow>
          ) : (
            formatString(value)
          ),
      },
      {
        Header: 'Erstellungsdatum',
        accessor: 'createdAt',
        width: 100,
        Cell: ({ value, row }) =>
          row.original.loading ? (
            <FadedText>{value}</FadedText>
          ) : (
            formatDate(value)
          ),
      },
      {
        Header: 'Datei',
        accessor: 'url',
        width: 150,
        disableSortBy: true,
        Cell: ({ cell, row }) => (
          <DownloadLink url={cell.value} disabled={row.original.loading} />
        ),
      },
    ],
    [],
  );

  const extendedColumns: ColumnDefinition<any>[] = useMemo(
    () => [
      ...columns,
      {
        Header: 'Löschen',
        accessor: 'id',
        width: 150,
        disableSortBy: true,
        Cell: ({ row, value }: { row: any; value: string }) => (
          <ButtonIcon
            icon={Icons.Delete}
            color="#E30045"
            small
            secondary
            isLoading={documentBeingRemovedId === value || loading}
            disabled={
              documentBeingRemovedId === value ||
              loading ||
              row.original.loading
            }
            onClick={() => {
              if (value) {
                setDocumentBeingRemovedId(value);
                removeDocument({
                  variables: { hash: value },
                  refetchQueries: [
                    {
                      query: ReadReportsDocument,
                      variables: {
                        types: [
                          'customer_contracts',
                          'client_report',
                          'etax_report',
                        ],
                      },
                    },
                  ],
                })
                  .then(() => {
                    refetchReadReports();
                  })
                  .finally(() => setDocumentBeingRemovedId(null));
              }
            }}
          />
        ),
      },
    ],
    [
      columns,
      documentBeingRemovedId,
      loading,
      removeDocument,
      refetchReadReports,
    ],
  );

  const customerContractReports = useMemo(
    () => [
      ...(data?.readReports
        ? // eslint-disable-next-line no-unsafe-optional-chaining
          data?.readReports
            .map((c) => ({
              ...c,
              asAttachment: true,
            }))
            .filter((c) => c.type === 'customer_contracts')
        : []),
    ],
    [data],
  );

  const clientReportData = useMemo(
    () =>
      data?.readReports
        .map((c) => ({
          ...c,
          asAttachment: true,
        }))
        ?.filter((c) => c.type === 'client_report') || [],
    [data],
  );

  const eTaxReport = useMemo(
    () => data?.readReports.filter((c) => c.type === 'etax_report') || [],
    [data],
  );

  const importReports = useMemo(
    () => data?.readReports.filter((c) => c.type === 'import_report') || [],
    [data],
  );

  const initialState = {
    sortBy: [
      {
        id: 'createdAt',
        desc: true,
      },
    ],
  };

  if (error) {
    return (
      <>
        <PageTitleSnippet />
        <NestedPage levelsUpToParent={2}>
          <AlertRetryable message={String(error)} />
        </NestedPage>
      </>
    );
  }

  return (
    <>
      <PageTitleSnippet />
      <Main>
        <Header
          Icon={Icons.Report}
          actions={() => (
            <Button onClick={() => setIsModalOpen(true)}>
              Bericht Erstellen
            </Button>
          )}
        >
          {reportPageTitle}
        </Header>

        <Table
          header={
            <Segment style={{ marginBottom: 0 }}>
              <Headline title>Kundenbasisbericht</Headline>
            </Segment>
          }
          compact
          columns={extendedColumns}
          filterKind="Kundenbasisberichte"
          isLoading={loading}
          withBoxShadow
          withAlternatingRows
          data={customerContractReports}
          initialState={initialState}
        />

        <Table
          header={
            <Segment style={{ marginBottom: 0 }}>
              <Headline title>Energiebericht</Headline>
            </Segment>
          }
          compact
          columns={extendedColumns}
          data={clientReportData}
          filterKind="Energieberichte"
          isLoading={loading}
          withBoxShadow
          withAlternatingRows
          initialState={initialState}
        />

        {hasETaxReportRole ? (
          <Table
            header={
              <Segment style={{ marginBottom: 0 }}>
                <Headline title>Stromsteuerbericht</Headline>
              </Segment>
            }
            compact
            columns={columns}
            filterKind="Stromsteuerberichte"
            isLoading={loading}
            withBoxShadow
            withAlternatingRows
            data={eTaxReport}
            initialState={initialState}
          />
        ) : null}

        {hasOperationsRole ? (
          <Table
            header={
              <Segment style={{ marginBottom: 0 }}>
                <Headline title>Importberichte</Headline>
              </Segment>
            }
            compact
            columns={columns}
            filterKind="Importberichte"
            isLoading={loading}
            withBoxShadow
            withAlternatingRows
            data={importReports}
            initialState={initialState}
          />
        ) : null}

        <GenerateReportModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
      </Main>
    </>
  );
}

export default ReportsPage;
