import React from 'react';
import { Helmet } from 'react-helmet-async';

const DOC_TITLE_SEPARATOR = ' • ';
const DOC_TITLE_GENERIC = 'AE Local Supply';

export function DocTitle({ titleParts = [] }: { titleParts?: string[] }) {
  const title = [...titleParts, DOC_TITLE_GENERIC]
    .join(DOC_TITLE_SEPARATOR)
    .trim();
  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
}
