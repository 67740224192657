import {
  Selectable,
  SelectableOption,
} from '@ampeersenergy/ampeers-ui-components';
import { useFormikContext } from 'formik';
import * as React from 'react';

import { useGraphqlForm } from '../../../components/graphql-form/hooks/useGraphqlForm';

import { AddressSettingsForm } from './AddressSettingsForm';

export interface WorkspaceSelectionProps {
  id: string;
  prefix: string;
}

export function WorkspaceSelection({ id, prefix }: WorkspaceSelectionProps) {
  const { getFieldProps, setFieldValue } = useFormikContext();
  const { isEditing } = useGraphqlForm();

  const [selected, setSelected] = React.useState<string>(`${id}.skip`);

  React.useEffect(() => {
    const name = getFieldProps(`${prefix}.name`).value;
    if (name) {
      setSelected('override');
    } else {
      setSelected('skip');
    }
  }, [prefix, getFieldProps]);

  const handleChange = (val: any) => {
    if (val === 'skip') {
      setFieldValue(`${prefix}.name`, undefined);
    } else {
      setFieldValue(`${prefix}.name`, id);
    }
  };

  return (
    <Selectable value={selected} onChange={handleChange}>
      <SelectableOption value="skip" disabled={!isEditing}>
        Bestehende Daten übernehmen
      </SelectableOption>
      <SelectableOption
        value="override"
        content={<AddressSettingsForm formPrefix={prefix} />}
        disabled={!isEditing}
      >
        Daten überschreiben
      </SelectableOption>
    </Selectable>
  );
}
