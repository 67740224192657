import React, { useCallback } from 'react';
import styled from 'styled-components';
import { AlertInfo } from '@ampeersenergy/ampeers-ui-components';
import * as yup from 'yup';
import { DateTime } from 'luxon';

import {
  ReadReportsDocument,
  UnknownError,
  useCreateClientReportMutation,
  useCreateETaxReportMutation,
  useCreateReportMutation,
} from '../../graphql-types';
import {
  ExportFlow,
  ExportFlowProps,
} from '../../components/export-flow/export-flow';
import { yupUTCDate } from '../../helpers/yupUTCDate';
import { useHasRole } from '../../components/useHasRole';
import { StepState } from '../../components/export-flow/export-step';
import {
  getDateTimeFromDateInput,
  formatDateForInput,
} from '../../helpers/dateHelpers';

import ClientPlantReportForm from './ClientPlantReportForm';
import { ETaxReportForm } from './ETaxReportForm';

const InfoMsgWrapped = styled(AlertInfo)`
  width: 100%;
`;

export const readReportsQueryTypes = [
  'eeg',
  'customer_contracts',
  'client_report',
  'import_report',
  'etax_report',
];

const ExportAlreadyExistsAlert: React.FC = () => (
  <AlertInfo title="Hinweis">
    Der letzte Export für dein Unternehmen liegt noch keine 24 Stunden zurück.
    <br />
    Du musst noch warten bis der nächste Export generiert werden kann.
  </AlertInfo>
);

export interface GenerateReportModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const GenerateReportModal: React.FC<GenerateReportModalProps> = ({
  isOpen,
  setIsOpen,
}) => {
  const [createReport, { data, error }] = useCreateReportMutation();
  const [
    createClientReport,
    { data: clientReportData, error: clientReportError },
  ] = useCreateClientReportMutation();
  const [createETaxReport, { data: eTaxReportData, error: eTaxReportError }] =
    useCreateETaxReportMutation();

  const { hasRole: hasETaxReportRole } = useHasRole('feature_etax_report');

  const createFailed =
    data?.createReport?.__typename === 'UnknownError' ||
    data?.createReport?.__typename === 'WrongCredentialsError' ||
    clientReportData?.createClientReport?.__typename === 'UnknownError' ||
    eTaxReportData?.createETaxReport.__typename === 'UnknownError';
  const hasError = !!(
    error ||
    clientReportError ||
    eTaxReportError ||
    createFailed
  );
  const exportAlreadyExists =
    data?.createReport?.__typename === 'RateLimitReached' ||
    clientReportData?.createClientReport?.__typename === 'RateLimitReached' ||
    eTaxReportData?.createETaxReport.__typename === 'RateLimitReached';

  const generateClientReport: ExportFlowProps['onGenerate'] = useCallback(
    (type, input) => {
      const refetchQueries = [
        {
          query: ReadReportsDocument,
          variables: {
            types: readReportsQueryTypes,
          },
        },
      ];
      if (type === 'customerContracts') {
        return createReport({
          refetchQueries,
        }).then(({ data: reportData }) => {
          if (reportData?.createReport?.__typename === 'CreateReportSuccess') {
            setIsOpen(false);
            return 'export';
          }
          return 'done';
        });
      }
      if (type === 'clientReport') {
        return createClientReport({
          variables: {
            salesStartDate: getDateTimeFromDateInput(input.salesStartDate),
            salesEndDate: getDateTimeFromDateInput(input.salesEndDate),
            accountingPeriodStartDate: getDateTimeFromDateInput(
              input.accountingPeriodStartDate,
            ),
            accountingPeriodEndDate: getDateTimeFromDateInput(
              input.accountingPeriodEndDate,
            ),
          },
          refetchQueries,
        })
          .then(({ data: reportData }: any) => {
            if (
              reportData?.createClientReport?.__typename ===
              'CreateClientReportSuccess'
            ) {
              return 'done';
            }
            if (
              reportData?.createClientReport?.__typename === 'RateLimitReached'
            ) {
              return 'done';
            }
            setIsOpen(false);
            return 'export';
          })
          .catch(() => 'done');
      }
      if (type === 'eTaxReport') {
        return createETaxReport({
          variables: {
            year: +input.year,
            fallbackAutarky: +input.fallbackAutarky,
          },
          refetchQueries,
        })
          .then(() => 'done' as StepState)
          .catch(() => 'done' as StepState);
      }

      throw Error('Unknown export type');
    },
    [createReport, setIsOpen, createClientReport, createETaxReport],
  );

  return (
    <ExportFlow
      types={[
        {
          label: 'Kundenbasisbericht',
          id: 'customerContracts',
          SuccessComponent: exportAlreadyExists
            ? ExportAlreadyExistsAlert
            : undefined,
          InnerComponent: () => (
            <InfoMsgWrapped title="Hinweis">
              Die Erstellung kann einige Minuten dauern. <br />
              Der fertige Bericht erscheint anschließend in der Übersicht.
              <br />
              <br />
              Können für einzelne Verträge keine Datensätze erzeugt werden, wrid
              dies am Ende des Berichtes angezeigt. <br />
              Wende Dich damit einfach an Deinen Helpdesk.
            </InfoMsgWrapped>
          ),
        },
        {
          label: 'Energiebericht',
          id: 'clientReport',
          SuccessComponent: exportAlreadyExists
            ? ExportAlreadyExistsAlert
            : undefined,
          InnerComponent: () => <ClientPlantReportForm />,
          validationSchema: yup.object().shape({
            salesStartDate: yupUTCDate
              .required()
              .test(
                'salesEndDate',
                'Startdatum darf nicht nach dem Enddatum liegen.',
                (value: Date | undefined, context) => {
                  return (
                    !value ||
                    !context.parent.salesEndDate ||
                    value.getTime() <=
                      new Date(context.parent.salesEndDate).getTime()
                  );
                },
              ),
            salesEndDate: yupUTCDate
              .required()
              .test(
                'salesStartDate',
                'Enddatum darf nicht vor dem Startdatum liegen.',
                (value: Date | undefined, context) => {
                  return (
                    !value ||
                    !context.parent.salesStartDate ||
                    value.getTime() >=
                      new Date(context.parent.salesStartDate).getTime()
                  );
                },
              ),
            accountingPeriodStartDate: yupUTCDate
              .required()
              .test(
                'accountingPeriodEndDate',
                'Startdatum darf nicht nach dem Enddatum liegen.',
                (value: Date | undefined, context) => {
                  return (
                    !value ||
                    !context.parent.accountingPeriodEndDate ||
                    value.getTime() <=
                      new Date(context.parent.accountingPeriodEndDate).getTime()
                  );
                },
              ),
            accountingPeriodEndDate: yupUTCDate
              .required()
              .test(
                'accountingPeriodStartDate',
                'Enddatum darf nicht vor dem Startdatum liegen.',
                (value: Date | undefined, context) => {
                  return (
                    !value ||
                    !context.parent.accountingPeriodStartDate ||
                    value.getTime() >=
                      new Date(
                        context.parent.accountingPeriodStartDate,
                      ).getTime()
                  );
                },
              ),
          }),
        },
        ...(hasETaxReportRole
          ? [
              {
                label: 'Stromsteuerbericht',
                id: 'eTaxReport',
                SuccessComponent: exportAlreadyExists
                  ? ExportAlreadyExistsAlert
                  : undefined,
                InnerComponent: () => (
                  <>
                    <InfoMsgWrapped title="Hinweis">
                      <p>
                        Der LOCAL SUPPLY hilft Ihnen, einen Stromsteuerbericht
                        zu erstellen. Nach der Erstellung ist es wichtig, dass
                        Sie den Bericht auf seine Richtigkeit prüfen und selbst
                        an das Hauptzollamt weiterleiten, um Ihren steuerlichen
                        Pflichten nachzukommen.{' '}
                      </p>
                      <p>
                        Wenn Sie Strom (z.B. zur Stromerzeugung)
                        steuerbegünstigt oder steuerfrei beziehen oder leisten,
                        müssen Sie die Nummer dieser Erlaubnis oder ein
                        zugehöriges Geschäftszeichen im Feld „Erlaubnisnummer“
                        eigenständig ergänzen. Dies dient als Nachweis Ihrer
                        Berechtigung gegenüber den Steuerbehörden und ermöglicht
                        Ihnen, die steuerlichen Vorteile in Anspruch zu nehmen.
                      </p>
                    </InfoMsgWrapped>
                    <ETaxReportForm />
                  </>
                ),
                validationSchema: yup.object().shape({
                  year: yup.number().required(),
                  fallbackAutarky: yup.number().required(),
                }),
                expectedApiDurationInSeconds: 40,
              },
            ]
          : []),
      ]}
      onClose={() => setIsOpen(false)}
      onGenerate={generateClientReport}
      error={error}
      errorMsg={
        createFailed
          ? (
              (data?.createReport as UnknownError) ??
              (clientReportData?.createClientReport as UnknownError)
            )?.message
          : undefined
      }
      hasError={hasError}
      isOpen={isOpen}
      defaultType="customerContracts"
      formProps={{
        initialValues: {
          salesStartDate: formatDateForInput(DateTime.now().startOf('month')),
          salesEndDate: formatDateForInput(DateTime.now().endOf('month')),
          accountingPeriodStartDate: formatDateForInput(
            DateTime.now().minus({ year: 1 }).startOf('year'),
          ),
          accountingPeriodEndDate: formatDateForInput(
            DateTime.now().minus({ year: 1 }).endOf('year'),
          ),
        },
      }}
    />
  );
};
