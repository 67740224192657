/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** String that gets processed/converted */
  CountryScalar: any;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
  /**
   * A signed eight-byte integer. The upper big integer values are greater than the
   * max value for a JavaScript number. Therefore all big integers will be output as
   * strings and not numbers.
   */
  BigInt: any;
  /**
   * A point in time as described by the [ISO
   * 8601](https://en.wikipedia.org/wiki/ISO_8601) standard. May or may not include a timezone.
   */
  Datetime: any;
  /** A location in a connection that can be used for resuming pagination. */
  Cursor: any;
  /** A floating point number that requires more precision than IEEE 754 binary 64 */
  BigFloat: any;
};


export type AcceptInvoiceDraftResponse = {
  __typename?: 'AcceptInvoiceDraftResponse';
  jobId: Scalars['String'];
  jobName: Scalars['String'];
};

export type AccountingDetailsReport = {
  __typename?: 'AccountingDetailsReport';
  contractLabel: Scalars['String'];
  accountingReceivableAccount?: Maybe<Scalars['String']>;
  accountingNumber: Scalars['String'];
  accountingPeriodStartDate: Scalars['String'];
  accountingPeriodEndDate: Scalars['String'];
  accountingGenerationDate: Scalars['String'];
  accountingNetValue: Scalars['Float'];
  accountingTaxValue: Scalars['Float'];
  accountingGrossValue: Scalars['Float'];
  accountingDiff: Scalars['Float'];
  consumption: Scalars['Float'];
  reduction: Scalars['Float'];
  reductionStartDate: Scalars['String'];
};

export type AccountingKpis = {
  __typename?: 'AccountingKpis';
  totalInvoicesMandate: Scalars['Int'];
  cancelledInvoicesMandate: Scalars['Int'];
  confirmedInvoicesMandate: Scalars['Int'];
};

export type AccountingMeteringPointExcerpt = {
  __typename?: 'AccountingMeteringPointExcerpt';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type AccountingObject = {
  accountingObjectEnd: Scalars['String'];
  accountingObjectStart: Scalars['String'];
  basicPriceValue: Scalars['Float'];
  consumptionRate: Scalars['Float'];
  days: Scalars['Int'];
  eegPrice?: Maybe<Scalars['Float']>;
  energyPriceValueBasic?: Maybe<Scalars['Float']>;
  energyPriceValueLocal?: Maybe<Scalars['Float']>;
  energyPriceValueResidual?: Maybe<Scalars['Float']>;
  maLo: Scalars['String'];
  meLo: Scalars['String'];
  meterConverterFactor: Scalars['Float'];
  meterNumber: Scalars['String'];
  meterReadingDifference: Scalars['Float'];
  meterReadingInfo: MeterReadingInfo;
  meterReadingNew: Scalars['Float'];
  meterReadingOld: Scalars['Float'];
  ownConsumptionRate: Scalars['Float'];
  priceSheetId: Scalars['Int'];
  restConsumptionRate: Scalars['Float'];
  tariffId: Scalars['Int'];
};

export type AccountingOverview = {
  __typename?: 'AccountingOverview';
  periods: Array<AccountingPeriodGroup>;
  totalPendingInvoices: Scalars['Int'];
};

export type AccountingPeriodGroup = {
  __typename?: 'AccountingPeriodGroup';
  start: Scalars['String'];
  end: Scalars['String'];
  settlement: SettlementDate;
  standardAccounting: StandardAccountingExcerpt;
  closingAccounting: Array<ClosingAccountingContractExcerpt>;
  unfinishedStandardAccounting?: Maybe<StandardAccountingExcerpt>;
  unfinishedClosingAccounting?: Maybe<Array<ClosingAccountingContractExcerpt>>;
};

export type AccountingPlantExcerpt = {
  __typename?: 'AccountingPlantExcerpt';
  id: Scalars['String'];
  name: Scalars['String'];
  zipCode?: Maybe<Scalars['String']>;
  meteringPointOperator: AccountingMeteringPointExcerpt;
};

export type AccountingRunActionResultSuccess = {
  __typename?: 'AccountingRunActionResultSuccess';
  success: Scalars['Boolean'];
};

export type AccountingRunAutarkySuggestion = {
  __typename?: 'AccountingRunAutarkySuggestion';
  autarky?: Maybe<Scalars['Float']>;
  calculatedAt?: Maybe<Scalars['String']>;
  errorReason?: Maybe<Scalars['String']>;
  meterId?: Maybe<Scalars['Int']>;
  plantFullSupply: Scalars['Boolean'];
  plantId: Scalars['Int'];
  plantName?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['Int']>;
  unroundedAutarky?: Maybe<Scalars['Float']>;
  validityEndAt?: Maybe<Scalars['String']>;
  validityStartAt?: Maybe<Scalars['String']>;
  warning?: Maybe<Scalars['String']>;
};

export type AccountingRunContractConsumptionContract = {
  __typename?: 'AccountingRunContractConsumptionContract';
  contractLabel: Scalars['String'];
  customerLabel: Scalars['String'];
  errors?: Maybe<Array<Scalars['String']>>;
  id: Scalars['Int'];
  name: Scalars['String'];
  plantId: Scalars['Int'];
  projectId: Scalars['Int'];
  type?: Maybe<Scalars['String']>;
};

export type AccountingRunContractMeter = {
  __typename?: 'AccountingRunContractMeter';
  errors: Array<AccountingRunContractMeterError>;
  meterId: Scalars['Int'];
  meterNumber: Scalars['String'];
};

export type AccountingRunContractMeterError = {
  __typename?: 'AccountingRunContractMeterError';
  date?: Maybe<Scalars['DateTime']>;
  reason: Scalars['String'];
};

export enum AccountingRunCurrentState {
  Complete = 'COMPLETE',
  Error = 'ERROR',
  NeedsAction = 'NEEDS_ACTION'
}

export type AccountingRunMeta = {
  __typename?: 'AccountingRunMeta';
  accountingType: AccountingType;
  invoiceCycle?: Maybe<InvoiceCycle>;
  paymentPeriodEndAt: Scalars['DateTime'];
  paymentPeriodStartAt: Scalars['DateTime'];
  settlementDay: Scalars['Int'];
  settlementMonth: Scalars['Int'];
};

export type AccountingRunSearchAttributes = {
  AccountingRunContractLabels?: Maybe<Array<Scalars['String']>>;
  AccountingRunCustomerLabels?: Maybe<Array<Scalars['String']>>;
  AccountingRunDownpaymentStartDate?: Maybe<Scalars['DateTime']>;
  AccountingRunEndTime?: Maybe<Scalars['DateTime']>;
  AccountingRunName?: Maybe<Scalars['String']>;
  AccountingRunOpcName?: Maybe<Scalars['String']>;
  AccountingRunPeriodEnd?: Maybe<Scalars['DateTime']>;
  AccountingRunPeriodStart?: Maybe<Scalars['DateTime']>;
  AccountingRunSessionId?: Maybe<Scalars['String']>;
  AccountingRunSettlementDay?: Maybe<Scalars['String']>;
  AccountingRunStartTime?: Maybe<Scalars['DateTime']>;
  AccountingRunState?: Maybe<AccountingRunCurrentState>;
  AccountingRunStep?: Maybe<Scalars['String']>;
};

export type AccountingRunSearchSorting = {
  direction: SortDirection;
  sortBy: AccountingRunSortByOptions;
};

export enum AccountingRunSortByOptions {
  AccountingRunContractLabels = 'AccountingRunContractLabels',
  AccountingRunCustomerLabels = 'AccountingRunCustomerLabels',
  AccountingRunDownpaymentStartDate = 'AccountingRunDownpaymentStartDate',
  AccountingRunEndTime = 'AccountingRunEndTime',
  AccountingRunName = 'AccountingRunName',
  AccountingRunOpcName = 'AccountingRunOpcName',
  AccountingRunPeriodEnd = 'AccountingRunPeriodEnd',
  AccountingRunPeriodStart = 'AccountingRunPeriodStart',
  AccountingRunSessionId = 'AccountingRunSessionId',
  AccountingRunSettlementDay = 'AccountingRunSettlementDay',
  AccountingRunStartTime = 'AccountingRunStartTime',
  AccountingRunState = 'AccountingRunState',
  AccountingRunStep = 'AccountingRunStep'
}

export type AccountingRunUserInputAutarky = {
  __typename?: 'AccountingRunUserInputAutarky';
  autarky: Scalars['Float'];
  plantId: Scalars['Int'];
};

export type AccountingRunValidatedConsumption = {
  __typename?: 'AccountingRunValidatedConsumption';
  ajv: Scalars['Float'];
  contractLabel: Scalars['String'];
  customerLabel: Scalars['String'];
  error: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  newConsumption: Scalars['Float'];
  plantId: Scalars['Int'];
  projectId: Scalars['Int'];
  relativeAjv: Scalars['Float'];
  relativeVariance?: Maybe<Scalars['Float']>;
};

export type AccountingRunValidatedContractMeter = {
  __typename?: 'AccountingRunValidatedContractMeter';
  contractLabel: Scalars['String'];
  customerLabel: Scalars['String'];
  id: Scalars['Int'];
  meters?: Maybe<Array<AccountingRunContractMeter>>;
  name: Scalars['String'];
  plantId: Scalars['Int'];
  projectId: Scalars['Int'];
  type?: Maybe<Scalars['String']>;
};

export type AccountingRunValidationError = {
  __typename?: 'AccountingRunValidationError';
  contractId?: Maybe<Scalars['Int']>;
  errorString: Scalars['String'];
  sourceId: Scalars['Int'];
  type: Scalars['String'];
};

export type AccountingRunWorkflowsData = {
  __typename?: 'AccountingRunWorkflowsData';
  AccountingRunContractLabels?: Maybe<Array<Scalars['String']>>;
  AccountingRunCustomerLabels?: Maybe<Array<Scalars['String']>>;
  AccountingRunDownpaymentStartDate?: Maybe<Scalars['DateTime']>;
  AccountingRunEndTime?: Maybe<Scalars['DateTime']>;
  AccountingRunName: Scalars['String'];
  AccountingRunOpcName?: Maybe<Scalars['String']>;
  AccountingRunPeriodEnd: Scalars['DateTime'];
  AccountingRunPeriodStart: Scalars['DateTime'];
  AccountingRunSessionId?: Maybe<Scalars['String']>;
  AccountingRunSettlementDay: Scalars['String'];
  AccountingRunStartTime: Scalars['DateTime'];
  AccountingRunState: AccountingRunCurrentState;
  AccountingRunStep: Scalars['String'];
  AccountingRunStepState: StepStatus;
  WorkflowId: Scalars['String'];
};

export type AccountingRunWorkflowState = {
  __typename?: 'AccountingRunWorkflowState';
  done: Scalars['Boolean'];
  doneAt?: Maybe<Scalars['DateTime']>;
  meta: AccountingRunMeta;
  name: Scalars['String'];
  startedAt: Scalars['DateTime'];
  steps: Array<AccountingRunWorkflowStep>;
  type: AccountingType;
};

export type AccountingRunWorkflowStep = {
  __typename?: 'AccountingRunWorkflowStep';
  action: ActionOption;
  active: Scalars['Boolean'];
  error?: Maybe<AccountingRunWorkflowStepError>;
  index: Scalars['Int'];
  name: AccountingRunWorkflowStepName;
  result?: Maybe<AccountingRunWorkflowStepResult>;
  status: StepStatus;
};

export type AccountingRunWorkflowStepError = {
  __typename?: 'AccountingRunWorkflowStepError';
  activityType?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export enum AccountingRunWorkflowStepName {
  AutarkyStep = 'AutarkyStep',
  DownPaymentStartStep = 'DownPaymentStartStep',
  FinalStep = 'FinalStep',
  OpcStep = 'OpcStep',
  OverviewStep = 'OverviewStep',
  ValidateContractConsumptionsStep = 'ValidateContractConsumptionsStep'
}

export type AccountingRunWorkflowStepResult = AutarkyStepResult | DownpaymentStartStepResult | OpcStepResult | OverviewStepResult | ValidateContractConsumptionsStepResult;

export enum AccountingStatus {
  Pending = 'PENDING',
  Confirmed = 'CONFIRMED',
  Reversal = 'REVERSAL',
  Draft = 'DRAFT'
}

export type AccountingStatusForContract = {
  __typename?: 'AccountingStatusForContract';
  contractId: Scalars['ID'];
  status: AccountingStatus;
  accFlowUUID: Scalars['String'];
};

export enum AccountingType {
  BetweenAccounting = 'betweenAccounting',
  ClosingAccountingMove = 'closingAccountingMove',
  ClosingAccountingThird = 'closingAccountingThird',
  CorrectionAccounting = 'correctionAccounting',
  StandardAccounting = 'standardAccounting'
}

export type AccountingWorkflowPayload = {
  accountingType: AccountingType;
  contractToAccount?: Maybe<Scalars['Int']>;
  invoiceCycle?: Maybe<InvoiceCycle>;
  name: Scalars['String'];
  paymentPeriodEndAt: Scalars['Date'];
  paymentPeriodStartAt: Scalars['Date'];
  plantsToAccount?: Maybe<Array<Scalars['Int']>>;
  quarterlyDeduction?: Maybe<Scalars['Boolean']>;
  settlementDay: Scalars['Int'];
  settlementMonth: Scalars['Int'];
  workflowType: WorkflowType;
};

export type AccountMove = {
  __typename?: 'AccountMove';
  type: InvoiceType;
  state: InvoiceState;
  amount: Scalars['Float'];
  bookedAt: Scalars['DateTime'];
  name: Scalars['String'];
  opcName?: Maybe<Scalars['String']>;
  sepa?: Maybe<Scalars['Boolean']>;
  sepaReturn?: Maybe<Scalars['Boolean']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  partialAmount?: Maybe<Scalars['Float']>;
  partialPaidAt?: Maybe<Scalars['DateTime']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  canceledName?: Maybe<Scalars['String']>;
  bookings?: Maybe<Array<Booking>>;
};

export type AccountMoves = {
  __typename?: 'AccountMoves';
  processed: Array<AccountMove>;
  unprocessed: Array<UnprocessedBooking>;
  vouchers: Array<UnprocessedBooking>;
};

export enum AccountType {
  Credit = 'CREDIT',
  Debit = 'DEBIT',
  Voucher = 'VOUCHER'
}

export enum ActionOption {
  None = 'None',
  OperationsRetry = 'OperationsRetry',
  Proceed = 'Proceed',
  ProceedAndRetry = 'ProceedAndRetry',
  UserRetry = 'UserRetry'
}

export type Address = {
  __typename?: 'Address';
  id?: Maybe<Scalars['ID']>;
  streetName?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['CountryScalar']>;
};

export type AddressInfo = {
  city: Scalars['String'];
  streetName: Scalars['String'];
  streetNumber: Scalars['String'];
  zipCode: Scalars['String'];
};

export type AdhocSettlement = {
  __typename?: 'AdhocSettlement';
  settlementDate: Scalars['Date'];
  meterReading?: Maybe<Scalars['Float']>;
  contractId?: Maybe<Scalars['Int']>;
  fileURL?: Maybe<Scalars['String']>;
};

export type AdhocSettlementInput = {
  settlementDate?: Maybe<Scalars['Date']>;
  meterReadingValue?: Maybe<Scalars['Float']>;
  sapSaldo?: Maybe<Scalars['Float']>;
};

export type AnnotatedType = {
  __typename?: 'AnnotatedType';
  type: Scalars['String'];
  fields: Scalars['JSONObject'];
  relation?: Maybe<Scalars['String']>;
};

export type Autarky = {
  __typename?: 'Autarky';
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  calculatedValue?: Maybe<Scalars['Float']>;
  userSuppliedValue?: Maybe<Scalars['Float']>;
};

export type AutarkyStepResult = {
  __typename?: 'AutarkyStepResult';
  plants?: Maybe<Array<Maybe<AccountingRunAutarkySuggestion>>>;
  userInput?: Maybe<Array<AccountingRunUserInputAutarky>>;
  workflowType: WorkflowType;
};

export type BalancingAreaAccount = {
  __typename?: 'BalancingAreaAccount';
  /** Bilanzierungsgebiet */
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  eic?: Maybe<Scalars['String']>;
  distributionNetworkOperator: DistributionNetworkOperator;
  transmissionSystemOperator: TransmissionSystemOperator;
};

export type BalancingAreaGroupAccount = {
  __typename?: 'BalancingAreaGroupAccount';
  /** Bilanzkreis */
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  eic?: Maybe<Scalars['String']>;
  suppliers?: Maybe<Array<Supplier>>;
  transmissionSystemOperatorId: Scalars['Int'];
};

export type BankAccount = {
  __typename?: 'BankAccount';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  bic?: Maybe<Scalars['String']>;
  mandateReference?: Maybe<Scalars['String']>;
  signedAt?: Maybe<Scalars['DateTime']>;
  isValid?: Maybe<Scalars['Boolean']>;
};

export type BdewCode = {
  __typename?: 'BdewCode';
  id: Scalars['String'];
  BdewCode: Scalars['String'];
  BdewCodeType?: Maybe<Scalars['String']>;
  BdewCodeFunction?: Maybe<Scalars['String']>;
  CompanyUID?: Maybe<Scalars['Float']>;
  CompanyName: Scalars['String'];
  PostCode: Scalars['String'];
  City: Scalars['String'];
  Street: Scalars['String'];
  Country: Scalars['String'];
  CodeContact?: Maybe<Scalars['String']>;
  CodeContactPhone?: Maybe<Scalars['String']>;
  CodeContactEmail: Scalars['String'];
};



export type Booking = {
  __typename?: 'Booking';
  type: BookingType;
  amount: Scalars['Float'];
  date: Scalars['DateTime'];
  account?: Maybe<AccountType>;
};

export type BookingReport = {
  __typename?: 'BookingReport';
  id: Scalars['ID'];
  name: Scalars['String'];
  url: Scalars['String'];
  createdAt: Scalars['DateTime'];
  status?: Maybe<Scalars['String']>;
};

export enum BookingType {
  VoucherWb = 'VOUCHER_WB',
  VoucherKb = 'VOUCHER_KB',
  DownPayment = 'DOWN_PAYMENT',
  Settlement = 'SETTLEMENT',
  SettlementRemaining = 'SETTLEMENT_REMAINING',
  Reversal = 'REVERSAL'
}

export type BoundMeter = {
  __typename?: 'BoundMeter';
  id: Scalars['ID'];
  plantId: Scalars['Int'];
  meterNumber: Scalars['String'];
  meterPlace?: Maybe<Scalars['String']>;
  meterType: Scalars['String'];
  msoType?: Maybe<Scalars['String']>;
  converterFactor?: Maybe<Scalars['Float']>;
  metering?: Maybe<Scalars['String']>;
  wasEverBound?: Maybe<Scalars['Boolean']>;
  isBoundInInterval?: Maybe<Scalars['Boolean']>;
  binds?: Maybe<Array<Maybe<MeterBind>>>;
};

export type CancelInvoiceResponse = {
  __typename?: 'CancelInvoiceResponse';
  contractNumber: Scalars['String'];
  bookingName: Scalars['String'];
  opcName: Scalars['String'];
  reversalName: Scalars['String'];
};

export type CancellationLetterPayloadInput = {
  billingAddress: AddressInfo;
  contractLabelOldSupplier?: Maybe<Scalars['String']>;
  meterNumber: Scalars['String'];
  name: Scalars['String'];
  shippingAddress: AddressInfo;
};

/** A CRM card */
export type Card = Node & {
  __typename?: 'Card';
  ajv?: Maybe<Scalars['Int']>;
  city?: Maybe<Scalars['String']>;
  co?: Maybe<Scalars['String']>;
  companyname?: Maybe<Scalars['String']>;
  contractenddate?: Maybe<Scalars['Datetime']>;
  contractstartdate?: Maybe<Scalars['Datetime']>;
  country?: Maybe<Scalars['String']>;
  createdat?: Maybe<Scalars['Datetime']>;
  creator?: Maybe<Scalars['BigInt']>;
  email?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  /** Identifies a card */
  id: Scalars['BigInt'];
  isperson?: Maybe<Scalars['Boolean']>;
  labels?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Reads a single `LaneCardRel` that is related to this `Card`. */
  laneCardRelByIdCard?: Maybe<LaneCardRel>;
  /**
   * Reads and enables pagination through a set of `LaneCardRel`.
   * @deprecated Please use laneCardRelByIdCard instead
   */
  laneCardRelsByIdCard: LaneCardRelsConnection;
  lastname?: Maybe<Scalars['String']>;
  leadorigin?: Maybe<Scalars['String']>;
  meterid?: Maybe<Scalars['BigInt']>;
  name?: Maybe<Scalars['String']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  plantid?: Maybe<Scalars['BigInt']>;
  prefix?: Maybe<Scalars['String']>;
  reduction?: Maybe<Scalars['BigFloat']>;
  reductionstartdate?: Maybe<Scalars['Datetime']>;
  shippingaddresscity?: Maybe<Scalars['String']>;
  shippingaddressstreet?: Maybe<Scalars['String']>;
  shippingaddressstreetnr?: Maybe<Scalars['String']>;
  shippingaddresszipcode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  streetnr?: Maybe<Scalars['String']>;
  tariffid?: Maybe<Scalars['BigInt']>;
  updatedat?: Maybe<Scalars['Datetime']>;
  workspaceid?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};


/** A CRM card */
export type CardLaneCardRelsByIdCardArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<LaneCardRelCondition>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<LaneCardRelsOrderBy>>;
};

/** A condition to be used against `Card` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type CardCondition = {
  /** Checks for equality with the object’s `ajv` field. */
  ajv?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `city` field. */
  city?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `co` field. */
  co?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `companyname` field. */
  companyname?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `contractenddate` field. */
  contractenddate?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `contractstartdate` field. */
  contractstartdate?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `country` field. */
  country?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdat` field. */
  createdat?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `creator` field. */
  creator?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `email` field. */
  email?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `firstname` field. */
  firstname?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `isperson` field. */
  isperson?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `labels` field. */
  labels?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `lastname` field. */
  lastname?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `leadorigin` field. */
  leadorigin?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `meterid` field. */
  meterid?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `notes` field. */
  notes?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `phone` field. */
  phone?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `plantid` field. */
  plantid?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `prefix` field. */
  prefix?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `reduction` field. */
  reduction?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `reductionstartdate` field. */
  reductionstartdate?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `shippingaddresscity` field. */
  shippingaddresscity?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `shippingaddressstreet` field. */
  shippingaddressstreet?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `shippingaddressstreetnr` field. */
  shippingaddressstreetnr?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `shippingaddresszipcode` field. */
  shippingaddresszipcode?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `state` field. */
  state?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `street` field. */
  street?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `streetnr` field. */
  streetnr?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `tariffid` field. */
  tariffid?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `updatedat` field. */
  updatedat?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `workspaceid` field. */
  workspaceid?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `zipcode` field. */
  zipcode?: Maybe<Scalars['String']>;
};

/** An input for mutations affecting `Card` */
export type CardInput = {
  ajv?: Maybe<Scalars['Int']>;
  city?: Maybe<Scalars['String']>;
  co?: Maybe<Scalars['String']>;
  companyname?: Maybe<Scalars['String']>;
  contractenddate?: Maybe<Scalars['Datetime']>;
  contractstartdate?: Maybe<Scalars['Datetime']>;
  country?: Maybe<Scalars['String']>;
  createdat?: Maybe<Scalars['Datetime']>;
  creator?: Maybe<Scalars['BigInt']>;
  email?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  isperson?: Maybe<Scalars['Boolean']>;
  labels?: Maybe<Array<Maybe<Scalars['String']>>>;
  lastname?: Maybe<Scalars['String']>;
  leadorigin?: Maybe<Scalars['String']>;
  meterid?: Maybe<Scalars['BigInt']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  plantid?: Maybe<Scalars['BigInt']>;
  prefix?: Maybe<Scalars['String']>;
  reduction?: Maybe<Scalars['BigFloat']>;
  reductionstartdate?: Maybe<Scalars['Datetime']>;
  shippingaddresscity?: Maybe<Scalars['String']>;
  shippingaddressstreet?: Maybe<Scalars['String']>;
  shippingaddressstreetnr?: Maybe<Scalars['String']>;
  shippingaddresszipcode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  streetnr?: Maybe<Scalars['String']>;
  tariffid?: Maybe<Scalars['BigInt']>;
  updatedat?: Maybe<Scalars['Datetime']>;
  workspaceid?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

/** Represents an update to a `Card`. Fields that are set will be updated. */
export type CardPatch = {
  ajv?: Maybe<Scalars['Int']>;
  city?: Maybe<Scalars['String']>;
  co?: Maybe<Scalars['String']>;
  companyname?: Maybe<Scalars['String']>;
  contractenddate?: Maybe<Scalars['Datetime']>;
  contractstartdate?: Maybe<Scalars['Datetime']>;
  country?: Maybe<Scalars['String']>;
  createdat?: Maybe<Scalars['Datetime']>;
  creator?: Maybe<Scalars['BigInt']>;
  email?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  /** Identifies a card */
  id?: Maybe<Scalars['BigInt']>;
  isperson?: Maybe<Scalars['Boolean']>;
  labels?: Maybe<Array<Maybe<Scalars['String']>>>;
  lastname?: Maybe<Scalars['String']>;
  leadorigin?: Maybe<Scalars['String']>;
  meterid?: Maybe<Scalars['BigInt']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  plantid?: Maybe<Scalars['BigInt']>;
  prefix?: Maybe<Scalars['String']>;
  reduction?: Maybe<Scalars['BigFloat']>;
  reductionstartdate?: Maybe<Scalars['Datetime']>;
  shippingaddresscity?: Maybe<Scalars['String']>;
  shippingaddressstreet?: Maybe<Scalars['String']>;
  shippingaddressstreetnr?: Maybe<Scalars['String']>;
  shippingaddresszipcode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  streetnr?: Maybe<Scalars['String']>;
  tariffid?: Maybe<Scalars['BigInt']>;
  updatedat?: Maybe<Scalars['Datetime']>;
  workspaceid?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

/** A connection to a list of `Card` values. */
export type CardsConnection = {
  __typename?: 'CardsConnection';
  /** A list of edges which contains the `Card` and cursor to aid in pagination. */
  edges: Array<CardsEdge>;
  /** A list of `Card` objects. */
  nodes: Array<Card>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Card` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Card` edge in the connection. */
export type CardsEdge = {
  __typename?: 'CardsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Card` at the end of the edge. */
  node: Card;
};

/** Methods to use when ordering `Card`. */
export enum CardsOrderBy {
  AjvAsc = 'AJV_ASC',
  AjvDesc = 'AJV_DESC',
  CityAsc = 'CITY_ASC',
  CityDesc = 'CITY_DESC',
  CompanynameAsc = 'COMPANYNAME_ASC',
  CompanynameDesc = 'COMPANYNAME_DESC',
  ContractenddateAsc = 'CONTRACTENDDATE_ASC',
  ContractenddateDesc = 'CONTRACTENDDATE_DESC',
  ContractstartdateAsc = 'CONTRACTSTARTDATE_ASC',
  ContractstartdateDesc = 'CONTRACTSTARTDATE_DESC',
  CountryAsc = 'COUNTRY_ASC',
  CountryDesc = 'COUNTRY_DESC',
  CoAsc = 'CO_ASC',
  CoDesc = 'CO_DESC',
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC',
  CreatorAsc = 'CREATOR_ASC',
  CreatorDesc = 'CREATOR_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  FirstnameAsc = 'FIRSTNAME_ASC',
  FirstnameDesc = 'FIRSTNAME_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IspersonAsc = 'ISPERSON_ASC',
  IspersonDesc = 'ISPERSON_DESC',
  LabelsAsc = 'LABELS_ASC',
  LabelsDesc = 'LABELS_DESC',
  LastnameAsc = 'LASTNAME_ASC',
  LastnameDesc = 'LASTNAME_DESC',
  LeadoriginAsc = 'LEADORIGIN_ASC',
  LeadoriginDesc = 'LEADORIGIN_DESC',
  MeteridAsc = 'METERID_ASC',
  MeteridDesc = 'METERID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  NotesAsc = 'NOTES_ASC',
  NotesDesc = 'NOTES_DESC',
  PhoneAsc = 'PHONE_ASC',
  PhoneDesc = 'PHONE_DESC',
  PlantidAsc = 'PLANTID_ASC',
  PlantidDesc = 'PLANTID_DESC',
  PrefixAsc = 'PREFIX_ASC',
  PrefixDesc = 'PREFIX_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ReductionstartdateAsc = 'REDUCTIONSTARTDATE_ASC',
  ReductionstartdateDesc = 'REDUCTIONSTARTDATE_DESC',
  ReductionAsc = 'REDUCTION_ASC',
  ReductionDesc = 'REDUCTION_DESC',
  ShippingaddresscityAsc = 'SHIPPINGADDRESSCITY_ASC',
  ShippingaddresscityDesc = 'SHIPPINGADDRESSCITY_DESC',
  ShippingaddressstreetnrAsc = 'SHIPPINGADDRESSSTREETNR_ASC',
  ShippingaddressstreetnrDesc = 'SHIPPINGADDRESSSTREETNR_DESC',
  ShippingaddressstreetAsc = 'SHIPPINGADDRESSSTREET_ASC',
  ShippingaddressstreetDesc = 'SHIPPINGADDRESSSTREET_DESC',
  ShippingaddresszipcodeAsc = 'SHIPPINGADDRESSZIPCODE_ASC',
  ShippingaddresszipcodeDesc = 'SHIPPINGADDRESSZIPCODE_DESC',
  StateAsc = 'STATE_ASC',
  StateDesc = 'STATE_DESC',
  StreetnrAsc = 'STREETNR_ASC',
  StreetnrDesc = 'STREETNR_DESC',
  StreetAsc = 'STREET_ASC',
  StreetDesc = 'STREET_DESC',
  TariffidAsc = 'TARIFFID_ASC',
  TariffidDesc = 'TARIFFID_DESC',
  UpdatedatAsc = 'UPDATEDAT_ASC',
  UpdatedatDesc = 'UPDATEDAT_DESC',
  WorkspaceidAsc = 'WORKSPACEID_ASC',
  WorkspaceidDesc = 'WORKSPACEID_DESC',
  ZipcodeAsc = 'ZIPCODE_ASC',
  ZipcodeDesc = 'ZIPCODE_DESC'
}

export type Check2FAuthReponse = User | TwoFactorSessionInvalid | TwoFactorSessionExpired | ServiceNotReachableError | UnknownError;

export type CheckDeletePriceSheetResult = {
  __typename?: 'CheckDeletePriceSheetResult';
  success: Scalars['Boolean'];
};

export type CheckResetPasswordTokenResponse = CheckResetPasswordTokenSuccess | WrongPasswordResetFlow | NoPasswordResetSessionFound | ServiceNotReachableError | UnknownError;

export type CheckResetPasswordTokenSuccess = {
  __typename?: 'CheckResetPasswordTokenSuccess';
  success: Scalars['Boolean'];
  message: Scalars['String'];
};

export type ChildWorkflowResult = {
  __typename?: 'ChildWorkflowResult';
  done?: Maybe<Scalars['Boolean']>;
  workflowId?: Maybe<Scalars['String']>;
};

export type ClientDetails = {
  __typename?: 'ClientDetails';
  companyName?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  banking?: Maybe<ClientDetailsBanking>;
  contact?: Maybe<ClientDetailsContact>;
  email?: Maybe<Scalars['String']>;
  contractDraftTemplates?: Maybe<ContractDraftTemplates>;
  workspaces?: Maybe<Array<ClientWorkspaceDetails>>;
  numberOfFooters?: Maybe<Scalars['Int']>;
};

export type ClientDetailsBanking = {
  __typename?: 'ClientDetailsBanking';
  iban?: Maybe<Scalars['String']>;
  bic?: Maybe<Scalars['String']>;
};

export type ClientDetailsBankingInput = {
  iban?: Maybe<Scalars['String']>;
  bic?: Maybe<Scalars['String']>;
};

export type ClientDetailsContact = {
  __typename?: 'ClientDetailsContact';
  serviceHotline?: Maybe<Scalars['String']>;
  serviceEmail?: Maybe<Scalars['String']>;
  serviceHours?: Maybe<Scalars['String']>;
  footer1?: Maybe<Scalars['String']>;
  footer2?: Maybe<Scalars['String']>;
  footer3?: Maybe<Scalars['String']>;
  footer4?: Maybe<Scalars['String']>;
  headerAddress?: Maybe<Scalars['String']>;
};

export type ClientDetailsContactInput = {
  serviceHotline?: Maybe<Scalars['String']>;
  serviceEmail?: Maybe<Scalars['String']>;
  serviceHours?: Maybe<Scalars['String']>;
  footer1?: Maybe<Scalars['String']>;
  footer2?: Maybe<Scalars['String']>;
  footer3?: Maybe<Scalars['String']>;
  footer4?: Maybe<Scalars['String']>;
  headerAddress?: Maybe<Scalars['String']>;
};

export type ClientDetailsInput = {
  companyName?: Maybe<Scalars['String']>;
  address?: Maybe<ClientWorkspaceAddressInput>;
  contact?: Maybe<ClientDetailsContactInput>;
  banking?: Maybe<ClientDetailsBankingInput>;
  email?: Maybe<Scalars['String']>;
  contractDraftTemplates?: Maybe<UpdateContractDraftTemplates>;
  workspaces?: Maybe<Array<ClientWorkspaceDetailsInput>>;
  numberOfFooters?: Maybe<Scalars['Int']>;
};

export type ClientSmtpConfig = {
  __typename?: 'ClientSmtpConfig';
  active: Scalars['Boolean'];
  host?: Maybe<Scalars['String']>;
  port?: Maybe<Scalars['Int']>;
  mailFrom?: Maybe<Scalars['String']>;
  secure?: Maybe<Scalars['Boolean']>;
  tls?: Maybe<Scalars['Boolean']>;
  auth?: Maybe<SmtpAuthConfig>;
};

export type ClientSmtpConfigInput = {
  active: Scalars['Boolean'];
  host?: Maybe<Scalars['String']>;
  port?: Maybe<Scalars['Int']>;
  mailFrom?: Maybe<Scalars['String']>;
  secure?: Maybe<Scalars['Boolean']>;
  tls?: Maybe<Scalars['Boolean']>;
  auth?: Maybe<SmtpAuthConfigInput>;
};

export type ClientWorkspaceAddressInput = {
  streetName?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
};

export type ClientWorkspaceDetails = {
  __typename?: 'ClientWorkspaceDetails';
  name?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  banking?: Maybe<ClientDetailsBanking>;
  contact?: Maybe<ClientDetailsContact>;
  email?: Maybe<Scalars['String']>;
  numberOfFooters?: Maybe<Scalars['Int']>;
};

export type ClientWorkspaceDetailsInput = {
  name?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  address?: Maybe<ClientWorkspaceAddressInput>;
  contact?: Maybe<ClientDetailsContactInput>;
  banking?: Maybe<ClientDetailsBankingInput>;
  email?: Maybe<Scalars['String']>;
  numberOfFooters?: Maybe<Scalars['Int']>;
};

export type ClosingAccountingContractExcerpt = {
  __typename?: 'ClosingAccountingContractExcerpt';
  id: Scalars['Int'];
  label: Scalars['String'];
  customerLabel?: Maybe<Scalars['String']>;
  plantId?: Maybe<Scalars['ID']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  settlement: SettlementDate;
  customerName?: Maybe<Scalars['String']>;
  invoiceCycle?: Maybe<Scalars['String']>;
  accFlowUUID?: Maybe<Scalars['String']>;
};

export type CommonPriceComponent = {
  commonPriceEnd: Scalars['String'];
  commonPriceStart: Scalars['String'];
  deferrableLoad: Scalars['Float'];
  electricityTax: Scalars['Float'];
  feeCHtPo: Scalars['Float'];
  feeReEnLa: Scalars['Float'];
  offshore: Scalars['Float'];
  strNEV: Scalars['Float'];
  vatRate: Scalars['Float'];
};

export type Company = {
  __typename?: 'Company';
  id: Scalars['ID'];
  name: Scalars['String'];
  logo?: Maybe<Scalars['String']>;
  primaryColor: Scalars['String'];
  secondaryColor: Scalars['String'];
};

export type ConcessionLevyComponent = {
  concessionLevy: Scalars['Float'];
  concessionLevyEnd: Scalars['String'];
  concessionLevyStart: Scalars['String'];
};

export type Consumption = {
  __typename?: 'Consumption';
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  value: Scalars['Float'];
};

export type Consumptions = {
  __typename?: 'Consumptions';
  initial: Consumption;
  usage?: Maybe<Consumption>;
};

export type ConsumptionsForContract = {
  __typename?: 'ConsumptionsForContract';
  ajvs: Array<Consumption>;
  consumptions: Array<Consumption>;
};

export type Contract = {
  __typename?: 'Contract';
  id: Scalars['ID'];
  label: Scalars['String'];
  plantId: Scalars['Int'];
  startDate: Scalars['DateTime'];
  endDate?: Maybe<Scalars['DateTime']>;
  signDate?: Maybe<Scalars['DateTime']>;
  contractType?: Maybe<Scalars['String']>;
  debtorLabel?: Maybe<Scalars['String']>;
  cancelationDate?: Maybe<Scalars['DateTime']>;
  cancelationReason?: Maybe<Scalars['String']>;
  earlyTerminationReason?: Maybe<Scalars['String']>;
  communications?: Maybe<Array<CustomerCommunication>>;
  status?: Maybe<Scalars['String']>;
  invoiceCycle?: Maybe<InvoiceCycle>;
  customer: Customer;
  tariffs?: Maybe<Array<ContractTariff>>;
  loadProfile?: Maybe<LoadProfile>;
  meteringPointOperator?: Maybe<MeteringPointOperator>;
  contractMeter?: Maybe<ContractMeter>;
  settlementDate?: Maybe<Scalars['String']>;
  settlementDay?: Maybe<Scalars['String']>;
  settlementMonth?: Maybe<Scalars['String']>;
  electricityGenerationOrGasType?: Maybe<Scalars['String']>;
  paymentDate?: Maybe<Scalars['Int']>;
  downPayment?: Maybe<Scalars['Float']>;
  downPaymentStartDate?: Maybe<Scalars['DateTime']>;
  downPaymentHistory: Array<DownPaymentHistory>;
  additionalValues?: Maybe<Array<Metadata>>;
  workspace: Workspace;
  documents?: Maybe<Array<Document>>;
  phtref?: Maybe<PhtRef>;
};

export type ContractAccountingHistory = {
  __typename?: 'ContractAccountingHistory';
  lastAccountingEnd?: Maybe<Scalars['DateTime']>;
  nextAccountingStart?: Maybe<Scalars['DateTime']>;
};

export type ContractAddress = {
  streetName: Scalars['String'];
  streetNumber: Scalars['String'];
  zipCode: Scalars['String'];
  city: Scalars['String'];
};

export type ContractClientSetup = {
  __typename?: 'ContractClientSetup';
  success: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
};

export type ContractComponent = {
  __typename?: 'ContractComponent';
  /** Vertragsbestandteil */
  id: Scalars['ID'];
  startDate: Scalars['String'];
  endDate?: Maybe<Scalars['String']>;
  loadProfile?: Maybe<LoadProfile>;
  balancingAreaAccount: BalancingAreaAccount;
  supplierAccount: Supplier;
  settlementDate: Scalars['String'];
  settlementDay: Scalars['String'];
  settlementMonth: Scalars['String'];
  downPayment?: Maybe<Scalars['Float']>;
  downPaymentStartDate?: Maybe<Scalars['DateTime']>;
  downPaymentHistory: Array<DownPaymentHistory>;
  tariff?: Maybe<Tariff>;
  electricityGenerationOrGasType?: Maybe<Scalars['String']>;
  consumptions?: Maybe<Consumptions>;
};

export type ContractComponentTariff = {
  id: Scalars['Int'];
  kind: Scalars['Int'];
  minimumDuration?: Maybe<Scalars['Int']>;
  minimumDurationExtension?: Maybe<Scalars['Int']>;
  nameExternal: Scalars['String'];
  nameInternal: Scalars['String'];
  noticePeriod: Scalars['String'];
  noticePeriodExtension?: Maybe<Scalars['String']>;
  powerTax: Scalars['Boolean'];
  priceGuarantee: Scalars['String'];
  priceGuaranteeReference: Scalars['String'];
  priceSheets: Array<TariffPriceSheet>;
  tax: Scalars['Boolean'];
  validityEndDate: Scalars['DateTime'];
  validityStartDate: Scalars['DateTime'];
};

export type ContractDocumentData = {
  name: Scalars['String'];
  currentAddress: ContractAddress;
  date: Scalars['DateTime'];
  shippingAddress: ContractAddress;
  cardId: Scalars['String'];
  mail?: Maybe<Scalars['String']>;
  aJV?: Maybe<Scalars['Float']>;
  meterNumber?: Maybe<Scalars['String']>;
  reduction?: Maybe<Scalars['Float']>;
  startOfDelivery?: Maybe<Scalars['DateTime']>;
  tariffId: Scalars['String'];
  plantId: Scalars['String'];
  workspaceId?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
};

export type ContractDocumentPreview = {
  __typename?: 'ContractDocumentPreview';
  fileURL: Scalars['String'];
};

export type ContractDraftTemplates = {
  __typename?: 'ContractDraftTemplates';
  pricesheet_letter?: Maybe<Scalars['Boolean']>;
  withdrawal_letter?: Maybe<Scalars['Boolean']>;
  poa_cancellation_letter?: Maybe<Scalars['Boolean']>;
  covering_letter?: Maybe<Scalars['Boolean']>;
};

export type ContractExcerpt = {
  __typename?: 'ContractExcerpt';
  id: Scalars['ID'];
  label: Scalars['String'];
  debtorLabel?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  contractType?: Maybe<Scalars['String']>;
  meterNumber?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  customerLabel: Scalars['String'];
  isOperator: Scalars['Boolean'];
  meterType: Scalars['String'];
  electricityGenerationOrGasType?: Maybe<Scalars['String']>;
  contractStatus?: Maybe<Scalars['String']>;
};

export type ContractExists = {
  __typename?: 'ContractExists';
  exists: Scalars['Boolean'];
  contractId?: Maybe<Scalars['ID']>;
};

export type ContractForMeter = {
  __typename?: 'ContractForMeter';
  id: Scalars['ID'];
  label: Scalars['String'];
  customerNames: Array<Scalars['String']>;
  meters: Array<MeterInfo>;
};

export type ContractInfo = {
  deliveryStartDate: Scalars['String'];
  malo: Scalars['String'];
  melo: Scalars['String'];
  meterNumber: Scalars['String'];
  name: Scalars['String'];
  street: Scalars['String'];
  streetNumber: Scalars['String'];
  town: Scalars['String'];
  zip: Scalars['String'];
};

export type ContractLight = {
  __typename?: 'ContractLight';
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
};

export type ContractMeter = {
  __typename?: 'ContractMeter';
  malo?: Maybe<Scalars['String']>;
  meters: Array<Meter>;
};

export type ContractMeterExcerpt = {
  __typename?: 'ContractMeterExcerpt';
  id: Scalars['ID'];
  meterNumber: Scalars['String'];
  meterPlace?: Maybe<Scalars['String']>;
  meterType: Scalars['String'];
  msoType: Scalars['String'];
  contractLabel?: Maybe<Scalars['String']>;
  customerLabel?: Maybe<Scalars['String']>;
  melo?: Maybe<Scalars['String']>;
  malo?: Maybe<Scalars['String']>;
  isOperator: Scalars['Boolean'];
  wasEverBound?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type ContractsBatchedSuccess = {
  __typename?: 'ContractsBatchedSuccess';
  jobId: Scalars['String'];
  jobName: Scalars['String'];
  status?: Maybe<Status>;
  progress?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type ContractTariff = {
  __typename?: 'ContractTariff';
  id: Scalars['String'];
  tariffId: Scalars['String'];
  nameExternal: Scalars['String'];
  nameInternal: Scalars['String'];
  tax: Scalars['Boolean'];
  powerTax: Scalars['Boolean'];
  priceGuaranteeReference: Scalars['String'];
  priceGuarantee: Scalars['Int'];
  minimumDuration?: Maybe<Scalars['Int']>;
  minimumDurationExtension?: Maybe<Scalars['Int']>;
  noticePeriod: NoticePeriod;
  noticePeriodExtension?: Maybe<NoticePeriod>;
  validityStartDate: Scalars['DateTime'];
  validityEndDate: Scalars['DateTime'];
  priceSheets: Array<PriceSheet>;
  kind: Scalars['String'];
  assignedFrom?: Maybe<Scalars['DateTime']>;
  assignedTo?: Maybe<Scalars['DateTime']>;
};

export type ContractTerminationInput = {
  cancelationReason?: Maybe<Scalars['String']>;
  cancelationDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  earlyTerminationReason?: Maybe<Scalars['String']>;
};

export enum ContractType {
  /** Relevant for accounting flow. Assets are of type MSO-{A,L,G,P}. */
  Asset = 'Asset',
  /** Not relevant for accounting flow. Assets are of type MSO-{H,E,K}. */
  MeasurementConcept = 'MeasurementConcept',
  /** Relevant for accounting flow. Assets are of type MSO-W. */
  Tenant = 'Tenant'
}

export type ContractUpdateResult = {
  __typename?: 'ContractUpdateResult';
  contractDate?: Maybe<Scalars['DateTime']>;
  deadline: Scalars['DateTime'];
  newMalo?: Maybe<Scalars['String']>;
  parentWorkflowId?: Maybe<Scalars['String']>;
  reductionStartDate?: Maybe<Scalars['String']>;
  reductionValue?: Maybe<Scalars['Float']>;
};

export type ContractVoucherBatchedResponse = {
  __typename?: 'ContractVoucherBatchedResponse';
  contractId: Scalars['String'];
  date: Scalars['String'];
  value: Scalars['Float'];
  type: Scalars['String'];
};

export type ContractVoucherData = {
  date: Scalars['DateTime'];
  value: Scalars['Float'];
  bonus: Scalars['String'];
  outOfPeriod: Scalars['Boolean'];
};

export type ContractVoucherResponse = {
  __typename?: 'ContractVoucherResponse';
  contractId: Scalars['String'];
  date: Scalars['String'];
  value: Scalars['Float'];
  journalId: Scalars['String'];
  productId: Scalars['String'];
  reverseAmount?: Maybe<Scalars['Boolean']>;
  paid?: Maybe<Scalars['Boolean']>;
};


export type CreateAccountingRunResult = {
  __typename?: 'CreateAccountingRunResult';
  id: Scalars['String'];
};

export type CreateAddressInput = {
  streetName: Scalars['String'];
  streetNumber: Scalars['String'];
  zipCode: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['CountryScalar'];
};

export type CreateAnnualConsumptionInput = {
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  value: Scalars['Float'];
};

export type CreateBankAccountInput = {
  name: Scalars['String'];
  iban: Scalars['String'];
  bic: Scalars['String'];
  mandateReference?: Maybe<Scalars['String']>;
  signedAt?: Maybe<Scalars['Date']>;
};

export type CreateBookingsBatchedError = {
  __typename?: 'CreateBookingsBatchedError';
  failed: Array<CreateBookingsBatchedValidatonError>;
  info?: Maybe<Array<InfoContractVoucherBatched>>;
};

export type CreateBookingsBatchedInput = {
  contractLabel: Scalars['String'];
  bookingValue: Scalars['String'];
  bookingDate: Scalars['String'];
  bookingTypeLabel: Scalars['String'];
};

export type CreateBookingsBatchedResponse = CreateBookingsBatchedSuccess | CreateBookingsBatchedError;

export type CreateBookingsBatchedSuccess = {
  __typename?: 'CreateBookingsBatchedSuccess';
  succeeded: Array<ContractVoucherBatchedResponse>;
  info?: Maybe<Array<InfoContractVoucherBatched>>;
};

export type CreateBookingsBatchedValidatonError = {
  __typename?: 'CreateBookingsBatchedValidatonError';
  contractLabel: Scalars['String'];
  message: Scalars['String'];
  error: Scalars['String'];
};

/** All input for the create `Card` mutation. */
export type CreateCardInput = {
  /** The `Card` to be created by this mutation. */
  card: CardInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** The output of our create `Card` mutation. */
export type CreateCardPayload = {
  __typename?: 'CreateCardPayload';
  /** The `Card` that was created by this mutation. */
  card?: Maybe<Card>;
  /** An edge for our `Card`. May be used by Relay 1. */
  cardEdge?: Maybe<CardsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `Card` mutation. */
export type CreateCardPayloadCardEdgeArgs = {
  orderBy?: Maybe<Array<CardsOrderBy>>;
};

export type CreateClientReportResponse = CreateClientReportSuccess | RateLimitReached | UnknownError;

export type CreateClientReportSuccess = {
  __typename?: 'CreateClientReportSuccess';
  success: Scalars['Boolean'];
};

export type CreateConsumptionsInput = {
  initial: CreateAnnualConsumptionInput;
};

export type CreateContractImportResponse = CreateContractsImportSuccess | ScheduleRequestsError | UnknownError;

export type CreateContractInput = {
  label: Scalars['String'];
  debtorLabel?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate?: Maybe<Scalars['DateTime']>;
  signDate: Scalars['DateTime'];
  contractMeter: CreateContractMeterInput;
  customer: CreateCustomerInput;
  paymentDate?: Maybe<Scalars['Int']>;
  status: Scalars['String'];
  workspace: SetWorkspaceInput;
  documentIdToMove?: Maybe<Scalars['String']>;
  contractType?: Maybe<Scalars['String']>;
  invoiceCycle?: Maybe<InvoiceCycle>;
  settlementDay?: Maybe<Scalars['String']>;
  settlementMonth?: Maybe<Scalars['String']>;
  downPayment?: Maybe<Scalars['Float']>;
  downPaymentStartDate?: Maybe<Scalars['DateTime']>;
  electricityGenerationOrGasType?: Maybe<Scalars['String']>;
  tariff: SetTariffInput;
  loadProfile: SetLoadProfileInput;
  consumptions?: Maybe<CreateConsumptionsInput>;
  storeAsMkvTemplate?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ContractType>;
};

export type CreateContractMeterInput = {
  meter: NewOrExistingMeterInput;
  meterReading?: Maybe<CreateMeterReadingInput>;
  malo?: Maybe<Scalars['String']>;
  consumptionPointId?: Maybe<Scalars['String']>;
  melo?: Maybe<Scalars['String']>;
  measuringPointId?: Maybe<Scalars['String']>;
};

export type CreateContractResponse = Contract | ValidationErrors | IbanExistsError;

export type CreateContractsBatchedError = {
  __typename?: 'CreateContractsBatchedError';
  failedContracts: Array<CreateContractsValidationError>;
  message: Scalars['String'];
};

export type CreateContractsBatchedInput = {
  VP_NR?: Maybe<Scalars['String']>;
  VP_TYP?: Maybe<Scalars['String']>;
  VP_ANREDE_1?: Maybe<Scalars['String']>;
  VP_NAME_1?: Maybe<Scalars['String']>;
  VP_DOB_1?: Maybe<Scalars['String']>;
  VP_ANREDE_2?: Maybe<Scalars['String']>;
  VP_NAME_2?: Maybe<Scalars['String']>;
  VP_DOB_2?: Maybe<Scalars['String']>;
  VP_COMPANY?: Maybe<Scalars['String']>;
  VP_COMPANY_CO?: Maybe<Scalars['String']>;
  VP_EMAIL?: Maybe<Scalars['String']>;
  VP_TELEFON?: Maybe<Scalars['String']>;
  VP_MOBIL?: Maybe<Scalars['String']>;
  VP_WERBUNG?: Maybe<Scalars['String']>;
  VP_KOMMUNIKATION?: Maybe<Scalars['String']>;
  VP_STR?: Maybe<Scalars['String']>;
  VP_HSNR?: Maybe<Scalars['String']>;
  VP_PLZ?: Maybe<Scalars['String']>;
  VP_ORT?: Maybe<Scalars['String']>;
  VP_LK?: Maybe<Scalars['String']>;
  RE_TYP?: Maybe<Scalars['String']>;
  RE_ANREDE_1?: Maybe<Scalars['String']>;
  RE_NAME_1?: Maybe<Scalars['String']>;
  RE_ANREDE_2?: Maybe<Scalars['String']>;
  RE_NAME_2?: Maybe<Scalars['String']>;
  RE_COMPANY?: Maybe<Scalars['String']>;
  RE_COMPANY_CO?: Maybe<Scalars['String']>;
  RE_STR?: Maybe<Scalars['String']>;
  RE_HSNR?: Maybe<Scalars['String']>;
  RE_PLZ?: Maybe<Scalars['String']>;
  RE_ORT?: Maybe<Scalars['String']>;
  RE_LK?: Maybe<Scalars['String']>;
  LF_TYP?: Maybe<Scalars['String']>;
  LF_ANREDE_1?: Maybe<Scalars['String']>;
  LF_NAME_1?: Maybe<Scalars['String']>;
  LF_ANREDE_2?: Maybe<Scalars['String']>;
  LF_NAME_2?: Maybe<Scalars['String']>;
  LF_COMPANY?: Maybe<Scalars['String']>;
  LF_COMPANY_CO?: Maybe<Scalars['String']>;
  LF_STR?: Maybe<Scalars['String']>;
  LF_HSNR?: Maybe<Scalars['String']>;
  LF_PLZ?: Maybe<Scalars['String']>;
  LF_ORT?: Maybe<Scalars['String']>;
  LF_LK?: Maybe<Scalars['String']>;
  V_VNR?: Maybe<Scalars['String']>;
  V_DEBI?: Maybe<Scalars['String']>;
  V_UNTERSCHRIFT?: Maybe<Scalars['String']>;
  V_LIEFERBEGINN?: Maybe<Scalars['String']>;
  V_LIEFERENDE?: Maybe<Scalars['String']>;
  V_STATUS?: Maybe<Scalars['String']>;
  V_WORKSPACE?: Maybe<Scalars['String']>;
  V_TARIF?: Maybe<Scalars['String']>;
  V_PROFIL?: Maybe<Scalars['String']>;
  AB_SEPA?: Maybe<Scalars['String']>;
  AB_KONTOINHABER?: Maybe<Scalars['String']>;
  AB_IBAN?: Maybe<Scalars['String']>;
  AB_BIC?: Maybe<Scalars['String']>;
  AB_REF?: Maybe<Scalars['String']>;
  AB_UNTERSCHRIFT?: Maybe<Scalars['String']>;
  AB_ABSCHLAG?: Maybe<Scalars['String']>;
  AB_BEGINN?: Maybe<Scalars['String']>;
  AB_AJV?: Maybe<Scalars['String']>;
  AB_AJV_BEGINN?: Maybe<Scalars['String']>;
  AB_AJV_ENDE?: Maybe<Scalars['String']>;
  AB_STICHTAG?: Maybe<Scalars['String']>;
  AB_TERMIN?: Maybe<Scalars['String']>;
  AB_TURNUS?: Maybe<Scalars['String']>;
  Z_NR?: Maybe<Scalars['String']>;
  Z_MALO?: Maybe<Scalars['String']>;
  Z_MELO?: Maybe<Scalars['String']>;
};

export type CreateContractsBatchedResponse = ContractsBatchedSuccess | CreateContractsBatchedError;

export type CreateContractsImportSuccess = {
  __typename?: 'CreateContractsImportSuccess';
  id: Scalars['ID'];
  customerLabel: Scalars['String'];
  contractLabel: Scalars['String'];
};

export type CreateContractsValidationError = {
  __typename?: 'CreateContractsValidationError';
  customerLabel: Scalars['String'];
  contractLabel: Scalars['String'];
  message: Scalars['String'];
  error: Scalars['String'];
};

/** All input for the create `Crminstance` mutation. */
export type CreateCrminstanceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Crminstance` to be created by this mutation. */
  crminstance: CrminstanceInput;
};

/** All input for the create `CrminstanceLaneRel` mutation. */
export type CreateCrminstanceLaneRelInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CrminstanceLaneRel` to be created by this mutation. */
  crminstanceLaneRel: CrminstanceLaneRelInput;
};

/** The output of our create `CrminstanceLaneRel` mutation. */
export type CreateCrminstanceLaneRelPayload = {
  __typename?: 'CreateCrminstanceLaneRelPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Crminstance` that is related to this `CrminstanceLaneRel`. */
  crminstanceByIdCrminstance?: Maybe<Crminstance>;
  /** The `CrminstanceLaneRel` that was created by this mutation. */
  crminstanceLaneRel?: Maybe<CrminstanceLaneRel>;
  /** An edge for our `CrminstanceLaneRel`. May be used by Relay 1. */
  crminstanceLaneRelEdge?: Maybe<CrminstanceLaneRelsEdge>;
  /** Reads a single `Lane` that is related to this `CrminstanceLaneRel`. */
  laneByIdLanes?: Maybe<Lane>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `CrminstanceLaneRel` mutation. */
export type CreateCrminstanceLaneRelPayloadCrminstanceLaneRelEdgeArgs = {
  orderBy?: Maybe<Array<CrminstanceLaneRelsOrderBy>>;
};

/** The output of our create `Crminstance` mutation. */
export type CreateCrminstancePayload = {
  __typename?: 'CreateCrminstancePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Crminstance` that was created by this mutation. */
  crminstance?: Maybe<Crminstance>;
  /** An edge for our `Crminstance`. May be used by Relay 1. */
  crminstanceEdge?: Maybe<CrminstancesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `Crminstance` mutation. */
export type CreateCrminstancePayloadCrminstanceEdgeArgs = {
  orderBy?: Maybe<Array<CrminstancesOrderBy>>;
};

export type CreateCustomerInput = {
  person: CreatePersonInput;
  existingCustomer?: Maybe<UpdateExistingCustomer>;
  address: CreateAddressInput;
  addressBilling?: Maybe<CreateOdooAddressInput>;
  addressShipping?: Maybe<CreateOdooAddressInput>;
  birthday?: Maybe<Scalars['DateTime']>;
  hasSepa?: Maybe<Scalars['Boolean']>;
  bankAccount?: Maybe<CreateBankAccountInput>;
  payer?: Maybe<CreatePayerInput>;
  documentDeliveryMethod: Scalars['String'];
  advertisementAccepted: Scalars['Boolean'];
  label: Scalars['String'];
  isOperator: Scalars['Boolean'];
};

export type CreateETaxReportResponse = CreateETaxReportSuccess | RateLimitReached | UnknownError;

export type CreateETaxReportSuccess = {
  __typename?: 'CreateETaxReportSuccess';
  success: Scalars['Boolean'];
};

/** All input for the create `LaneCardRel` mutation. */
export type CreateLaneCardRelInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LaneCardRel` to be created by this mutation. */
  laneCardRel: LaneCardRelInput;
};

/** The output of our create `LaneCardRel` mutation. */
export type CreateLaneCardRelPayload = {
  __typename?: 'CreateLaneCardRelPayload';
  /** Reads a single `Card` that is related to this `LaneCardRel`. */
  cardByIdCard?: Maybe<Card>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Lane` that is related to this `LaneCardRel`. */
  laneByIdLanes?: Maybe<Lane>;
  /** The `LaneCardRel` that was created by this mutation. */
  laneCardRel?: Maybe<LaneCardRel>;
  /** An edge for our `LaneCardRel`. May be used by Relay 1. */
  laneCardRelEdge?: Maybe<LaneCardRelsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `LaneCardRel` mutation. */
export type CreateLaneCardRelPayloadLaneCardRelEdgeArgs = {
  orderBy?: Maybe<Array<LaneCardRelsOrderBy>>;
};

/** All input for the create `Lane` mutation. */
export type CreateLaneInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Lane` to be created by this mutation. */
  lane: LaneInput;
};

/** The output of our create `Lane` mutation. */
export type CreateLanePayload = {
  __typename?: 'CreateLanePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Lane` that was created by this mutation. */
  lane?: Maybe<Lane>;
  /** An edge for our `Lane`. May be used by Relay 1. */
  laneEdge?: Maybe<LanesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `Lane` mutation. */
export type CreateLanePayloadLaneEdgeArgs = {
  orderBy?: Maybe<Array<LanesOrderBy>>;
};

export type CreateMeasurementContractResult = {
  __typename?: 'CreateMeasurementContractResult';
  success: Scalars['Boolean'];
  odooContractId?: Maybe<Scalars['String']>;
  feedInContract?: Maybe<Contract>;
  consumptionContract?: Maybe<Contract>;
};

export type CreateMeasurementContractResultResponse = CreateMeasurementContractResult | ValidationErrors;

export type CreateMeterInput = {
  meterNumber: Scalars['String'];
  meterPlace: Scalars['String'];
  meterType: Scalars['String'];
  msoType?: Maybe<Scalars['String']>;
  converterFactor: Scalars['Float'];
  metering: Scalars['String'];
  isInSecondaryCascade?: Maybe<Scalars['Boolean']>;
};

export type CreateMeterReadingInput = {
  date: Scalars['DateTime'];
  value: Scalars['Float'];
  obis: Scalars['String'];
  reason: Scalars['String'];
  valueStatus: Scalars['String'];
  ignoreWarning?: Maybe<Scalars['Boolean']>;
};

export type CreateMeterReadingsBatchedError = {
  __typename?: 'CreateMeterReadingsBatchedError';
  id?: Maybe<Scalars['ID']>;
  error?: Maybe<Scalars['String']>;
  meterNumber?: Maybe<Scalars['ID']>;
  date?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
  fileName?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
};

export type CreateMeterReadingsBatchedInput = {
  meterNumber: Scalars['ID'];
  date?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Float']>;
  obis?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  hint?: Maybe<Scalars['String']>;
  valueStatus?: Maybe<Scalars['String']>;
};

export type CreateMeterReadingsBatchedMixedResult = {
  __typename?: 'CreateMeterReadingsBatchedMixedResult';
  succeeded: Array<Maybe<CreateMeterReadingsBatchedSuccess>>;
  failed: Array<Maybe<CreateMeterReadingsBatchedError>>;
};

export type CreateMeterReadingsBatchedResult = CreateMeterReadingsBatchedMixedResult | DuplicateMeterReadingsError;

export type CreateMeterReadingsBatchedSuccess = {
  __typename?: 'CreateMeterReadingsBatchedSuccess';
  id: Scalars['ID'];
  meterNumber: Scalars['ID'];
  date?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
  fileName?: Maybe<Scalars['String']>;
};

export type CreateMeterResponse = Meter | ValidationErrors;

export type CreateMetersBatchedError = {
  __typename?: 'CreateMetersBatchedError';
  meterNumber: Scalars['String'];
  plantName: Scalars['String'];
  message: Scalars['String'];
  error: Scalars['String'];
};

export type CreateMetersBatchedInput = {
  plantName?: Maybe<Scalars['String']>;
  meterNumber?: Maybe<Scalars['String']>;
  meterPlace?: Maybe<Scalars['String']>;
  meterType?: Maybe<Scalars['String']>;
  msoType?: Maybe<Scalars['String']>;
  converterFactor?: Maybe<Scalars['String']>;
  metering?: Maybe<Scalars['String']>;
};

export type CreateMetersBatchedResponse = {
  __typename?: 'CreateMetersBatchedResponse';
  succeeded: Array<CreateMetersBatchedSuccess>;
  failed: Array<CreateMetersBatchedError>;
};

export type CreateMetersBatchedSuccess = {
  __typename?: 'CreateMetersBatchedSuccess';
  meterNumber: Scalars['String'];
  plantName: Scalars['String'];
};

export type CreateOdooAddressInput = {
  name?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  additionalName?: Maybe<Scalars['String']>;
  additionalNamePrefix?: Maybe<Scalars['String']>;
  companySecondaryFlag?: Maybe<Scalars['Boolean']>;
  streetName?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['CountryScalar']>;
  companyFlag?: Maybe<Scalars['Boolean']>;
  co?: Maybe<Scalars['String']>;
};

export type CreatePayerInput = {
  accountHolderId: Scalars['ID'];
  name: Scalars['String'];
  number: Scalars['String'];
  iban: Scalars['String'];
  bic: Scalars['String'];
};

export type CreatePersonInput = {
  namePrefix?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  contactPhone?: Maybe<Scalars['String']>;
  contactMobile?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  companyFlag?: Maybe<Scalars['Boolean']>;
  co?: Maybe<Scalars['String']>;
};

export type CreatePlantAdditionalAddress = {
  address: CreateAddressInput;
};

export type CreatePlantFlowInput = {
  existingProject?: Maybe<UpdateProjectInput>;
  newProject?: Maybe<CreateProjectInput>;
  name: Scalars['String'];
  units: Scalars['Int'];
  measurementConcept: Scalars['String'];
  meteringPointOperator: UpdateMeteringPointOperator;
  balancingAreaAccount: UpdateBalancingAreaAccount;
  balancingAreaGroupAccount: UpdateBalancingAreaGroupAccount;
  supplierAccount: UpdateSupplier;
  addresses: Array<CreateAddressInput>;
  meters: Array<CreateMeterInput>;
};

export type CreatePlantReportResponse = CreatePlantReportSuccess | RateLimitReached | UnknownError;

export type CreatePlantReportSuccess = {
  __typename?: 'CreatePlantReportSuccess';
  success: Scalars['Boolean'];
};

export type CreatePlantResponse = Plant | ValidationErrors;

export type CreateProjectInput = {
  name: Scalars['String'];
};

export type CreateReportResponse = CreateReportSuccess | RateLimitReached | WrongCredentialsError | UnknownError;

export type CreateReportSuccess = {
  __typename?: 'CreateReportSuccess';
  entityId: Scalars['String'];
  documentName: Scalars['String'];
  createdAt: Scalars['String'];
};

export type CreateScheduleRequestsResponse = {
  __typename?: 'CreateScheduleRequestsResponse';
  jobId: Scalars['String'];
  jobName: Scalars['String'];
};

export type CreateTariffsBatchedError = {
  __typename?: 'CreateTariffsBatchedError';
  error: Scalars['String'];
  nameInternal: Scalars['String'];
  message: Scalars['String'];
};

export type CreateTariffsBatchedInput = {
  nameExternal?: Maybe<Scalars['String']>;
  nameInternal?: Maybe<Scalars['String']>;
  validityStartDate?: Maybe<Scalars['String']>;
  validityEndDate?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  tax?: Maybe<Scalars['String']>;
  powerTax?: Maybe<Scalars['String']>;
  priceGuarantee?: Maybe<Scalars['String']>;
  priceGuaranteeReference?: Maybe<Scalars['String']>;
  minimumDuration?: Maybe<Scalars['String']>;
  minimumDurationExtension?: Maybe<Scalars['String']>;
  noticePeriod?: Maybe<Scalars['String']>;
  noticePeriodReference?: Maybe<Scalars['String']>;
  noticePeriodExtension?: Maybe<Scalars['String']>;
  noticePeriodExtensionReference?: Maybe<Scalars['String']>;
  priceSheetName?: Maybe<Scalars['String']>;
  priceSheetStartDate?: Maybe<Scalars['String']>;
  priceSheetBasicPrice?: Maybe<Scalars['String']>;
  priceSheetEnergyPrice?: Maybe<Scalars['String']>;
  priceSheetEnergyPriceLocal?: Maybe<Scalars['String']>;
  priceSheetEnergyPriceResidual?: Maybe<Scalars['String']>;
  priceSheetIgnorePriceGuarantee?: Maybe<Scalars['String']>;
};

export type CreateTariffsBatchedResponse = {
  __typename?: 'CreateTariffsBatchedResponse';
  succeeded: Array<CreateTariffsBatchedSuccess>;
  failed: Array<CreateTariffsBatchedError>;
};

export type CreateTariffsBatchedSuccess = {
  __typename?: 'CreateTariffsBatchedSuccess';
  nameInternal: Scalars['String'];
  info?: Maybe<Scalars['String']>;
};

export type CreateUpdateMetadataInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type Crminstance = Node & {
  __typename?: 'Crminstance';
  client: Scalars['BigInt'];
  createdat?: Maybe<Scalars['Datetime']>;
  /** Reads and enables pagination through a set of `CrminstanceLaneRel`. */
  crminstanceLaneRelsByIdCrminstance: CrminstanceLaneRelsConnection;
  id: Scalars['BigInt'];
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  updatedat?: Maybe<Scalars['Datetime']>;
};


export type CrminstanceCrminstanceLaneRelsByIdCrminstanceArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<CrminstanceLaneRelCondition>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<CrminstanceLaneRelsOrderBy>>;
};

/**
 * A condition to be used against `Crminstance` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type CrminstanceCondition = {
  /** Checks for equality with the object’s `client` field. */
  client?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `createdat` field. */
  createdat?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedat` field. */
  updatedat?: Maybe<Scalars['Datetime']>;
};

/** An input for mutations affecting `Crminstance` */
export type CrminstanceInput = {
  client: Scalars['BigInt'];
  createdat?: Maybe<Scalars['Datetime']>;
  id: Scalars['BigInt'];
  name: Scalars['String'];
  updatedat?: Maybe<Scalars['Datetime']>;
};

export type CrminstanceLaneRel = {
  __typename?: 'CrminstanceLaneRel';
  createdat?: Maybe<Scalars['Datetime']>;
  /** Reads a single `Crminstance` that is related to this `CrminstanceLaneRel`. */
  crminstanceByIdCrminstance?: Maybe<Crminstance>;
  idCrminstance?: Maybe<Scalars['BigInt']>;
  idLanes?: Maybe<Scalars['BigInt']>;
  /** Reads a single `Lane` that is related to this `CrminstanceLaneRel`. */
  laneByIdLanes?: Maybe<Lane>;
  updatedat?: Maybe<Scalars['Datetime']>;
};

/**
 * A condition to be used against `CrminstanceLaneRel` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type CrminstanceLaneRelCondition = {
  /** Checks for equality with the object’s `createdat` field. */
  createdat?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `idCrminstance` field. */
  idCrminstance?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `idLanes` field. */
  idLanes?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `updatedat` field. */
  updatedat?: Maybe<Scalars['Datetime']>;
};

/** An input for mutations affecting `CrminstanceLaneRel` */
export type CrminstanceLaneRelInput = {
  createdat?: Maybe<Scalars['Datetime']>;
  idCrminstance?: Maybe<Scalars['BigInt']>;
  idLanes?: Maybe<Scalars['BigInt']>;
  updatedat?: Maybe<Scalars['Datetime']>;
};

/** A connection to a list of `CrminstanceLaneRel` values. */
export type CrminstanceLaneRelsConnection = {
  __typename?: 'CrminstanceLaneRelsConnection';
  /** A list of edges which contains the `CrminstanceLaneRel` and cursor to aid in pagination. */
  edges: Array<CrminstanceLaneRelsEdge>;
  /** A list of `CrminstanceLaneRel` objects. */
  nodes: Array<CrminstanceLaneRel>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CrminstanceLaneRel` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `CrminstanceLaneRel` edge in the connection. */
export type CrminstanceLaneRelsEdge = {
  __typename?: 'CrminstanceLaneRelsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CrminstanceLaneRel` at the end of the edge. */
  node: CrminstanceLaneRel;
};

/** Methods to use when ordering `CrminstanceLaneRel`. */
export enum CrminstanceLaneRelsOrderBy {
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC',
  IdCrminstanceAsc = 'ID_CRMINSTANCE_ASC',
  IdCrminstanceDesc = 'ID_CRMINSTANCE_DESC',
  IdLanesAsc = 'ID_LANES_ASC',
  IdLanesDesc = 'ID_LANES_DESC',
  Natural = 'NATURAL',
  UpdatedatAsc = 'UPDATEDAT_ASC',
  UpdatedatDesc = 'UPDATEDAT_DESC'
}

/** Represents an update to a `Crminstance`. Fields that are set will be updated. */
export type CrminstancePatch = {
  client?: Maybe<Scalars['BigInt']>;
  createdat?: Maybe<Scalars['Datetime']>;
  id?: Maybe<Scalars['BigInt']>;
  name?: Maybe<Scalars['String']>;
  updatedat?: Maybe<Scalars['Datetime']>;
};

/** A connection to a list of `Crminstance` values. */
export type CrminstancesConnection = {
  __typename?: 'CrminstancesConnection';
  /** A list of edges which contains the `Crminstance` and cursor to aid in pagination. */
  edges: Array<CrminstancesEdge>;
  /** A list of `Crminstance` objects. */
  nodes: Array<Crminstance>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Crminstance` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Crminstance` edge in the connection. */
export type CrminstancesEdge = {
  __typename?: 'CrminstancesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Crminstance` at the end of the edge. */
  node: Crminstance;
};

/** Methods to use when ordering `Crminstance`. */
export enum CrminstancesOrderBy {
  ClientAsc = 'CLIENT_ASC',
  ClientDesc = 'CLIENT_DESC',
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedatAsc = 'UPDATEDAT_ASC',
  UpdatedatDesc = 'UPDATEDAT_DESC'
}


export type Customer = {
  __typename?: 'Customer';
  id: Scalars['ID'];
  person: Person;
  address: Address;
  addressBilling: OdooAddress;
  addressShipping: OdooAddress;
  birthday?: Maybe<Scalars['DateTime']>;
  advertisementAccepted: Scalars['Boolean'];
  bankAccount?: Maybe<BankAccount>;
  hasSepa?: Maybe<Scalars['Boolean']>;
  paymentDate: Scalars['String'];
  reduction?: Maybe<Scalars['Float']>;
  documentDeliveryMethod: Scalars['String'];
  invoicesOverDue?: Maybe<Scalars['Boolean']>;
  isOperator: Scalars['Boolean'];
  label: Scalars['String'];
  payer?: Maybe<Payer>;
};

export type CustomerCommunication = {
  __typename?: 'CustomerCommunication';
  id: Scalars['ID'];
  userId: Scalars['Int'];
  communicatedBy: Scalars['String'];
  title: Scalars['String'];
  message: Scalars['String'];
  date: Scalars['String'];
  attachmentId?: Maybe<Scalars['Int']>;
};

export type CustomerCommunicationInput = {
  title: Scalars['String'];
  message: Scalars['String'];
  communicatedBy: Scalars['String'];
};

export type CustomerData = {
  accountingNumber?: Maybe<Scalars['String']>;
  accountingObjects: Array<AccountingObject>;
  accountingPeriodEnd: Scalars['String'];
  accountingPeriodStart: Scalars['String'];
  autarky: Scalars['Float'];
  contractNumber: Scalars['String'];
  customerInstallationId: Scalars['Int'];
  grossDisagioNew: Scalars['Float'];
  id: Scalars['String'];
  newConsumption: NewConsumption;
  nextCancelationDate: Scalars['String'];
  producer: Scalars['String'];
  reversalInvoiceNumber?: Maybe<Scalars['String']>;
  shippingCity: Scalars['String'];
  shippingStreet: Scalars['String'];
  shippingStreetNumber: Scalars['String'];
  shippingZip: Scalars['String'];
  state: Scalars['String'];
  totalOwnConsumptionRate: Scalars['Float'];
  totalRestConsumptionRate: Scalars['Float'];
  transactionNumber: Scalars['Int'];
};

export type CustomerExists = {
  __typename?: 'CustomerExists';
  exists: Scalars['Boolean'];
  customerId?: Maybe<Scalars['ID']>;
};

export type CustomerLight = {
  __typename?: 'CustomerLight';
  id: Scalars['ID'];
  person: Person;
  address: Address;
  isOperator: Scalars['Boolean'];
  label: Scalars['String'];
};




export type DebitPositions = {
  contractId: Scalars['ID'];
  date: Scalars['Date'];
  journalId: Scalars['String'];
  reverseAmount: Scalars['Boolean'];
  value: Scalars['Float'];
};

export type DeleteAccountingDraftConsumptionValuesError = {
  __typename?: 'DeleteAccountingDraftConsumptionValuesError';
  contractId: Scalars['String'];
  error: DeleteAccountingDraftConsumptionValuesErrorResult;
};

export type DeleteAccountingDraftConsumptionValuesErrorResult = {
  __typename?: 'DeleteAccountingDraftConsumptionValuesErrorResult';
  title: Scalars['String'];
};

export type DeleteAccountingDraftConsumptionValuesResponse = DeleteAccountingDraftConsumptionValuesSuccess | DeleteAccountingDraftConsumptionValuesError;

export type DeleteAccountingDraftConsumptionValuesSuccess = {
  __typename?: 'DeleteAccountingDraftConsumptionValuesSuccess';
  contractId: Scalars['String'];
  deletionResult: Array<DeleteAccountingDraftConsumptionValuesSuccessResult>;
};

export type DeleteAccountingDraftConsumptionValuesSuccessResult = {
  __typename?: 'DeleteAccountingDraftConsumptionValuesSuccessResult';
  success: Scalars['Boolean'];
};

/** All input for the `deleteCardByNodeId` mutation. */
export type DeleteCardByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Card` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteCardCustom` mutation. */
export type DeleteCardCustomInput = {
  cardId: Scalars['BigInt'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** The output of our `deleteCardCustom` mutation. */
export type DeleteCardCustomPayload = {
  __typename?: 'DeleteCardCustomPayload';
  boolean?: Maybe<Scalars['Boolean']>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteCard` mutation. */
export type DeleteCardInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Identifies a card */
  id: Scalars['BigInt'];
};

/** The output of our delete `Card` mutation. */
export type DeleteCardPayload = {
  __typename?: 'DeleteCardPayload';
  /** The `Card` that was deleted by this mutation. */
  card?: Maybe<Card>;
  /** An edge for our `Card`. May be used by Relay 1. */
  cardEdge?: Maybe<CardsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedCardNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `Card` mutation. */
export type DeleteCardPayloadCardEdgeArgs = {
  orderBy?: Maybe<Array<CardsOrderBy>>;
};

/** All input for the `deleteCrminstanceByNodeId` mutation. */
export type DeleteCrminstanceByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Crminstance` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteCrminstance` mutation. */
export type DeleteCrminstanceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['BigInt'];
};

/** The output of our delete `Crminstance` mutation. */
export type DeleteCrminstancePayload = {
  __typename?: 'DeleteCrminstancePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Crminstance` that was deleted by this mutation. */
  crminstance?: Maybe<Crminstance>;
  /** An edge for our `Crminstance`. May be used by Relay 1. */
  crminstanceEdge?: Maybe<CrminstancesEdge>;
  deletedCrminstanceNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `Crminstance` mutation. */
export type DeleteCrminstancePayloadCrminstanceEdgeArgs = {
  orderBy?: Maybe<Array<CrminstancesOrderBy>>;
};

/** All input for the `deleteLaneByNodeId` mutation. */
export type DeleteLaneByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Lane` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteLaneCardRelByNodeId` mutation. */
export type DeleteLaneCardRelByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `LaneCardRel` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteLaneCardRel` mutation. */
export type DeleteLaneCardRelInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  idCard: Scalars['BigInt'];
};

/** The output of our delete `LaneCardRel` mutation. */
export type DeleteLaneCardRelPayload = {
  __typename?: 'DeleteLaneCardRelPayload';
  /** Reads a single `Card` that is related to this `LaneCardRel`. */
  cardByIdCard?: Maybe<Card>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedLaneCardRelNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `Lane` that is related to this `LaneCardRel`. */
  laneByIdLanes?: Maybe<Lane>;
  /** The `LaneCardRel` that was deleted by this mutation. */
  laneCardRel?: Maybe<LaneCardRel>;
  /** An edge for our `LaneCardRel`. May be used by Relay 1. */
  laneCardRelEdge?: Maybe<LaneCardRelsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `LaneCardRel` mutation. */
export type DeleteLaneCardRelPayloadLaneCardRelEdgeArgs = {
  orderBy?: Maybe<Array<LaneCardRelsOrderBy>>;
};

/** All input for the `deleteLane` mutation. */
export type DeleteLaneInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['BigInt'];
};

/** The output of our delete `Lane` mutation. */
export type DeleteLanePayload = {
  __typename?: 'DeleteLanePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedLaneNodeId?: Maybe<Scalars['ID']>;
  /** The `Lane` that was deleted by this mutation. */
  lane?: Maybe<Lane>;
  /** An edge for our `Lane`. May be used by Relay 1. */
  laneEdge?: Maybe<LanesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `Lane` mutation. */
export type DeleteLanePayloadLaneEdgeArgs = {
  orderBy?: Maybe<Array<LanesOrderBy>>;
};

export type DeleteOdooContractResponse = OdooContractPartnerAssignedToOtherContractError | OdooContractNotFoundError | UnknownError | SuccessResponse;

export type DeleteOldAccountingWorkflowResponse = UnknownError | WorkflowResponse;

export type DeletePriceSheetSuccessResult = {
  __typename?: 'DeletePriceSheetSuccessResult';
  success: Scalars['Boolean'];
};

export enum DeliveryPreference {
  ContractEmail = 'CONTRACT_EMAIL',
  UserEmail = 'USER_EMAIL',
  Browser = 'BROWSER'
}

export type DistributionNetworkOperator = {
  __typename?: 'DistributionNetworkOperator';
  /** Verteilnetzbetreiber */
  id: Scalars['ID'];
  name: Scalars['String'];
  code: Scalars['String'];
};

export type Document = {
  __typename?: 'Document';
  contentType: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  fileURL: Scalars['String'];
  type: Scalars['String'];
  fileName?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  userUpload?: Maybe<Scalars['Boolean']>;
  parentGroup?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
};

export type DocumentErrorResponse = {
  __typename?: 'DocumentErrorResponse';
  message: Scalars['String'];
};

export type DocumentInfo = {
  __typename?: 'DocumentInfo';
  contractId: Scalars['String'];
  url: Scalars['String'];
  hash: Scalars['String'];
};

export type DocumentResponse = {
  __typename?: 'DocumentResponse';
  hash?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

export type DocumentResult = {
  __typename?: 'DocumentResult';
  document?: Maybe<SupplierChangeDocument>;
};

export type DocumentsRemovedResponse = {
  __typename?: 'DocumentsRemovedResponse';
  success: Scalars['Boolean'];
  message: Scalars['String'];
  deletedDocumentIds: Array<Maybe<Scalars['String']>>;
};

export type DocumentStorageOptions = {
  groupId: Scalars['String'];
  entityId: Scalars['String'];
  entityType: Scalars['String'];
};

export type DocumentType = {
  __typename?: 'DocumentType';
  name: Scalars['String'];
  label: Scalars['String'];
  group?: Maybe<Scalars['String']>;
};

export type DownPaymentHistory = {
  __typename?: 'DownPaymentHistory';
  downPayment?: Maybe<Scalars['Float']>;
  downPaymentStartDate?: Maybe<Scalars['DateTime']>;
};

export type DownpaymentStartStepResult = {
  __typename?: 'DownpaymentStartStepResult';
  downpaymentStart?: Maybe<Scalars['DateTime']>;
};

export type DuplicateMeterReadingsError = Error & {
  __typename?: 'DuplicateMeterReadingsError';
  message: Scalars['String'];
  pointer?: Maybe<Array<Scalars['String']>>;
  retryable?: Maybe<Scalars['Boolean']>;
  duplicateReadings: Array<Maybe<CreateMeterReadingsBatchedError>>;
  multipleReadings: Array<Maybe<CreateMeterReadingsBatchedError>>;
};

export type EmailNotFound = {
  __typename?: 'EmailNotFound';
  message: Scalars['String'];
};

export type EmgTestResult = {
  __typename?: 'EmgTestResult';
  session: Scalars['String'];
};

export type Error = {
  /** error summary */
  message: Scalars['String'];
  /** a JSON Pointer [RFC6901] pointing to the error causing entity */
  pointer?: Maybe<Array<Scalars['String']>>;
  /** is the request with the same payload retryable */
  retryable?: Maybe<Scalars['Boolean']>;
};

export type ExtrapolatedReading = {
  __typename?: 'ExtrapolatedReading';
  value?: Maybe<Scalars['Float']>;
  valueStatus?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
};

export type ExtrapolateMeterReadingResult = ExtrapolatedReading | ValidationError;

export type GenerateContractDocumentInput = {
  contractId: Scalars['String'];
  type: Scalars['String'];
  deliveryPreference: DeliveryPreference;
  contractDocumentData?: Maybe<ContractDocumentData>;
  pricesheetChangeDocumentData?: Maybe<PricesheetChangeDocumentData>;
};

export type GenerateContractDocumentPreviewResponse = ContractDocumentPreview | MissingAttributesInContractDocumentError | UnknownError;

export type GenerateContractDocumentResponse = DocumentErrorResponse | DocumentResponse | SuccessResponse | MultipleFilesSuccessResponse | MissingAttributesInContractDocumentError | UnknownError;

export type GenerateDocumentError = {
  __typename?: 'GenerateDocumentError';
  contractId: Scalars['String'];
  reason: Scalars['String'];
};

export type GenerateDocumentsData = DocumentInfo | GenerateDocumentError;

export type GenerateInvoiceDocumentInput = {
  bccReceiver?: Maybe<Scalars['String']>;
  contract: InvoiceContract;
  invoiceCycle: InvoiceCycle;
  invoiceDraftId: Scalars['ID'];
  opc: OpcPayload;
  workflowUuid: Scalars['String'];
};

export type GenerateInvoiceResponse = DocumentErrorResponse | InvoiceSuccessResponse;

export type GenerateMultipleDocumentsResult = {
  __typename?: 'GenerateMultipleDocumentsResult';
  jobId: Scalars['String'];
  jobName: Scalars['String'];
  status: Status;
  progress: Scalars['Int'];
  total: Scalars['Int'];
  documents?: Maybe<Array<GenerateDocumentsData>>;
};

export type GetAccountingRunWorkflowsResponse = {
  __typename?: 'GetAccountingRunWorkflowsResponse';
  data: Array<AccountingRunWorkflowsData>;
  nextPageToken?: Maybe<Scalars['String']>;
  totalCount: Scalars['Int'];
};

export type GetScheduleRequestsResponse = {
  __typename?: 'GetScheduleRequestsResponse';
  id: Scalars['String'];
  status: Scalars['String'];
  workflowName: Scalars['String'];
};

export type GetSupplierChangeResponse = {
  __typename?: 'GetSupplierChangeResponse';
  data: Array<SupplierChangeData>;
  nextPageToken?: Maybe<Scalars['String']>;
  totalCount: Scalars['Int'];
};

export type IbanExistsError = {
  __typename?: 'IbanExistsError';
  message: Scalars['String'];
  accountHolderId: Scalars['String'];
  accountNumber: Scalars['String'];
  accountHolder: Scalars['String'];
  payers: Array<OdooBankAccountPayer>;
  hasNewMeterBeenAdded?: Maybe<Scalars['Boolean']>;
};

export type ImportDebitPositionsPayload = {
  positions: Array<DebitPositions>;
};

export type InfoContractVoucherBatched = {
  __typename?: 'InfoContractVoucherBatched';
  contractLabel: Scalars['String'];
  bookingDate: Scalars['DateTime'];
  contractEndDate?: Maybe<Scalars['DateTime']>;
  contractStartDate?: Maybe<Scalars['DateTime']>;
};

export type InfoProductBookingsType = {
  __typename?: 'InfoProductBookingsType';
  name: Scalars['String'];
  product: Scalars['String'];
  journal: Scalars['String'];
  typ: Scalars['String'];
};

export type InfoProductBookingsTypeInput = {
  name: Scalars['String'];
  product: Scalars['String'];
  journal: Scalars['String'];
  typ: Scalars['String'];
};

export type InstallationPriceComponent = {
  concessionLevyComponent: Array<ConcessionLevyComponent>;
  customerInstallationId: Scalars['Int'];
  measuringStationOperatorDetails: MeasuringStationOperatorDetails;
  networkCharges: Array<NetworkCharges>;
  networkOperatorCode: Scalars['String'];
};

export type InvoiceAddress = {
  city?: Maybe<Scalars['String']>;
  co?: Maybe<Scalars['String']>;
  companyFlag?: Maybe<Scalars['Boolean']>;
  country?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  streetName?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type InvoiceBankAccount = {
  bic?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  isValid?: Maybe<Scalars['Boolean']>;
  mandateReference?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  signedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceContract = {
  addressPlant: InvoiceAddress;
  contractMeter: InvoiceContractMeter;
  customer: InvoiceCustomer;
  debtorLabel?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  label: Scalars['String'];
  metadata?: Maybe<Scalars['JSON']>;
  plantId: Scalars['Int'];
  workspace: InvoiceWorkspace;
};

export type InvoiceContractComponent = {
  tariff?: Maybe<ContractComponentTariff>;
};

export type InvoiceContractMeter = {
  contractComponents: Array<InvoiceContractComponent>;
  id: Scalars['Int'];
  meters: Array<InvoiceMeter>;
};

export type InvoiceCustomer = {
  addressBilling: InvoiceOdooAddress;
  addressShipping: InvoiceAddress;
  bankAccount?: Maybe<InvoiceBankAccount>;
  documentDeliveryMethod?: Maybe<Scalars['String']>;
  hasSepa?: Maybe<Scalars['Boolean']>;
  label: Scalars['String'];
  person: InvoicePerson;
};

export enum InvoiceCycle {
  HalfYearly = 'HalfYearly',
  Monthly = 'Monthly',
  Quarterly = 'Quarterly',
  Yearly = 'Yearly'
}

export type InvoiceDraft = {
  __typename?: 'InvoiceDraft';
  id: Scalars['ID'];
  confirmed: Scalars['Boolean'];
  customerName: Scalars['String'];
  amount: Scalars['Float'];
  dueDate: Scalars['DateTime'];
  contractId: Scalars['String'];
  invoiceId: Scalars['String'];
  invoiceDocumentUrl?: Maybe<Scalars['String']>;
  documentDeliveryMethod?: Maybe<Scalars['String']>;
};

export type InvoiceDraftExcerpt = {
  __typename?: 'InvoiceDraftExcerpt';
  customerName: Scalars['String'];
  customerLabel: Scalars['String'];
  contractLabel: Scalars['String'];
  contractId?: Maybe<Scalars['String']>;
  sessionUUID?: Maybe<Scalars['String']>;
  workflow?: Maybe<Workflow>;
};

export type InvoiceDraftTuple = {
  __typename?: 'InvoiceDraftTuple';
  contractLabel: Scalars['String'];
  invoiceDraftId: Scalars['String'];
};

export type InvoiceDraftTupleInput = {
  contractLabel: Scalars['String'];
  invoiceDraftId: Scalars['String'];
};

export type InvoiceMeter = {
  meterNumber: Scalars['String'];
};

export type InvoiceOdooAddress = {
  additionalName?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  co?: Maybe<Scalars['String']>;
  companyFlag?: Maybe<Scalars['Boolean']>;
  country?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  streetName?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type InvoicePerson = {
  co?: Maybe<Scalars['String']>;
  companyFlag?: Maybe<Scalars['Boolean']>;
  contactEmail?: Maybe<Scalars['String']>;
  contactMobile?: Maybe<Scalars['String']>;
  contactPhone?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  namePrefix?: Maybe<Scalars['String']>;
};

export type InvoiceScheduledRequestCompleteResult = {
  data: Scalars['JSON'];
  id: Scalars['String'];
  jobId?: Maybe<Scalars['String']>;
};

export enum InvoiceState {
  Open = 'OPEN',
  Paid = 'PAID',
  UnderPaid = 'UNDER_PAID',
  OverPaid = 'OVER_PAID',
  Canceled = 'CANCELED'
}

export type InvoiceSuccessResponse = {
  __typename?: 'InvoiceSuccessResponse';
  contractLabel: Scalars['String'];
  hash: Scalars['String'];
  invoiceId: Scalars['String'];
  url: Scalars['String'];
};

export enum InvoiceType {
  DownPayment = 'DOWN_PAYMENT',
  FinalSettlement = 'FINAL_SETTLEMENT'
}

export type InvoiceWorkspace = {
  code: Scalars['String'];
  name: Scalars['String'];
};

export type JobNotFound = {
  __typename?: 'JobNotFound';
  message: Scalars['String'];
};


export type JsonBox = {
  __typename?: 'JSONBox';
  id?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['JSON']>;
};


export type Lane = Node & {
  __typename?: 'Lane';
  createdat?: Maybe<Scalars['Datetime']>;
  /** Reads and enables pagination through a set of `CrminstanceLaneRel`. */
  crminstanceLaneRelsByIdLanes: CrminstanceLaneRelsConnection;
  id: Scalars['BigInt'];
  /** Reads and enables pagination through a set of `LaneCardRel`. */
  laneCardRelsByIdLanes: LaneCardRelsConnection;
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  updatedat?: Maybe<Scalars['Datetime']>;
};


export type LaneCrminstanceLaneRelsByIdLanesArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<CrminstanceLaneRelCondition>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<CrminstanceLaneRelsOrderBy>>;
};


export type LaneLaneCardRelsByIdLanesArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<LaneCardRelCondition>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<LaneCardRelsOrderBy>>;
};

export type LaneCardRel = Node & {
  __typename?: 'LaneCardRel';
  /** Reads a single `Card` that is related to this `LaneCardRel`. */
  cardByIdCard?: Maybe<Card>;
  createdat?: Maybe<Scalars['Datetime']>;
  idCard: Scalars['BigInt'];
  idLanes?: Maybe<Scalars['BigInt']>;
  /** Reads a single `Lane` that is related to this `LaneCardRel`. */
  laneByIdLanes?: Maybe<Lane>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  position: Scalars['BigInt'];
  updatedat?: Maybe<Scalars['Datetime']>;
};

/**
 * A condition to be used against `LaneCardRel` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type LaneCardRelCondition = {
  /** Checks for equality with the object’s `createdat` field. */
  createdat?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `idCard` field. */
  idCard?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `idLanes` field. */
  idLanes?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `position` field. */
  position?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `updatedat` field. */
  updatedat?: Maybe<Scalars['Datetime']>;
};

/** An input for mutations affecting `LaneCardRel` */
export type LaneCardRelInput = {
  createdat?: Maybe<Scalars['Datetime']>;
  idCard: Scalars['BigInt'];
  idLanes?: Maybe<Scalars['BigInt']>;
  position: Scalars['BigInt'];
  updatedat?: Maybe<Scalars['Datetime']>;
};

/** Represents an update to a `LaneCardRel`. Fields that are set will be updated. */
export type LaneCardRelPatch = {
  createdat?: Maybe<Scalars['Datetime']>;
  idCard?: Maybe<Scalars['BigInt']>;
  idLanes?: Maybe<Scalars['BigInt']>;
  position?: Maybe<Scalars['BigInt']>;
  updatedat?: Maybe<Scalars['Datetime']>;
};

/** A connection to a list of `LaneCardRel` values. */
export type LaneCardRelsConnection = {
  __typename?: 'LaneCardRelsConnection';
  /** A list of edges which contains the `LaneCardRel` and cursor to aid in pagination. */
  edges: Array<LaneCardRelsEdge>;
  /** A list of `LaneCardRel` objects. */
  nodes: Array<LaneCardRel>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LaneCardRel` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `LaneCardRel` edge in the connection. */
export type LaneCardRelsEdge = {
  __typename?: 'LaneCardRelsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LaneCardRel` at the end of the edge. */
  node: LaneCardRel;
};

/** Methods to use when ordering `LaneCardRel`. */
export enum LaneCardRelsOrderBy {
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC',
  IdCardAsc = 'ID_CARD_ASC',
  IdCardDesc = 'ID_CARD_DESC',
  IdLanesAsc = 'ID_LANES_ASC',
  IdLanesDesc = 'ID_LANES_DESC',
  Natural = 'NATURAL',
  PositionAsc = 'POSITION_ASC',
  PositionDesc = 'POSITION_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedatAsc = 'UPDATEDAT_ASC',
  UpdatedatDesc = 'UPDATEDAT_DESC'
}

/** A condition to be used against `Lane` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type LaneCondition = {
  /** Checks for equality with the object’s `createdat` field. */
  createdat?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedat` field. */
  updatedat?: Maybe<Scalars['Datetime']>;
};

/** An input for mutations affecting `Lane` */
export type LaneInput = {
  createdat?: Maybe<Scalars['Datetime']>;
  id: Scalars['BigInt'];
  name: Scalars['String'];
  updatedat?: Maybe<Scalars['Datetime']>;
};

/** Represents an update to a `Lane`. Fields that are set will be updated. */
export type LanePatch = {
  createdat?: Maybe<Scalars['Datetime']>;
  id?: Maybe<Scalars['BigInt']>;
  name?: Maybe<Scalars['String']>;
  updatedat?: Maybe<Scalars['Datetime']>;
};

/** A connection to a list of `Lane` values. */
export type LanesConnection = {
  __typename?: 'LanesConnection';
  /** A list of edges which contains the `Lane` and cursor to aid in pagination. */
  edges: Array<LanesEdge>;
  /** A list of `Lane` objects. */
  nodes: Array<Lane>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Lane` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Lane` edge in the connection. */
export type LanesEdge = {
  __typename?: 'LanesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Lane` at the end of the edge. */
  node: Lane;
};

/** Methods to use when ordering `Lane`. */
export enum LanesOrderBy {
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedatAsc = 'UPDATEDAT_ASC',
  UpdatedatDesc = 'UPDATEDAT_DESC'
}

export type LoadProfile = {
  __typename?: 'LoadProfile';
  /** LastProfil */
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type LoginResponse = User | TwoFactorRequired | TwoFactorEmailFailed | WrongCredentialsError | ServiceNotReachableError | UnknownError;

export type MeasuringStationOperatorDetails = {
  city: Scalars['String'];
  code: Scalars['String'];
  mail?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  number: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  street: Scalars['String'];
  zip: Scalars['String'];
};

export type MeloMaloBoundResponse = {
  __typename?: 'MeloMaloBoundResponse';
  bound: Scalars['Boolean'];
};

export type Metadata = {
  __typename?: 'Metadata';
  key: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type Meter = {
  __typename?: 'Meter';
  id: Scalars['ID'];
  plantId?: Maybe<Scalars['Int']>;
  meterNumber: Scalars['String'];
  meterPlace?: Maybe<Scalars['String']>;
  meterType: Scalars['String'];
  msoType?: Maybe<Scalars['String']>;
  converterFactor?: Maybe<Scalars['Float']>;
  metering?: Maybe<Scalars['String']>;
  wasEverBound?: Maybe<Scalars['Boolean']>;
  validityStart?: Maybe<Scalars['String']>;
  validityEnd?: Maybe<Scalars['String']>;
  contractId?: Maybe<Scalars['Int']>;
  contract?: Maybe<Contract>;
  inUse?: Maybe<Scalars['Boolean']>;
  melo?: Maybe<Scalars['String']>;
  isBound?: Maybe<Scalars['Boolean']>;
  contractLabel?: Maybe<Scalars['String']>;
  contractEnd?: Maybe<Scalars['String']>;
};

export type MeterBind = {
  __typename?: 'MeterBind';
  validityStart?: Maybe<Scalars['String']>;
  validityEnd?: Maybe<Scalars['String']>;
  melo?: Maybe<Scalars['String']>;
  malo?: Maybe<Scalars['String']>;
  contractLabel?: Maybe<Scalars['String']>;
  contractEnd?: Maybe<Scalars['String']>;
  measuringPointId?: Maybe<Scalars['String']>;
  consumptionPointId?: Maybe<Scalars['String']>;
};

export type MeterInfo = {
  __typename?: 'MeterInfo';
  id: Scalars['ID'];
  validityStart?: Maybe<Scalars['String']>;
  validityEnd?: Maybe<Scalars['String']>;
};

export enum Metering {
  Slp = 'SLP',
  Rlm = 'RLM'
}

export type MeteringPointOperator = {
  __typename?: 'MeteringPointOperator';
  /** Messstellenbetreiber */
  id: Scalars['ID'];
  number: Scalars['String'];
  code: Scalars['String'];
  name: Scalars['String'];
};

export type MeterReading = {
  __typename?: 'MeterReading';
  id?: Maybe<Scalars['Int']>;
  meterId: Scalars['Int'];
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
  obis: Scalars['String'];
  value: Scalars['Float'];
  reason?: Maybe<Scalars['String']>;
  hint?: Maybe<Scalars['String']>;
  valueStatus?: Maybe<Scalars['String']>;
  warning?: Maybe<Scalars['String']>;
  ignoreWarning?: Maybe<Scalars['Boolean']>;
  date?: Maybe<Scalars['DateTime']>;
  usedForBilling?: Maybe<Scalars['Boolean']>;
  melo: Scalars['String'];
};

export type MeterReadingInfo = {
  beginHint: Scalars['String'];
  beginReason: Scalars['String'];
  endHint: Scalars['String'];
  endReason: Scalars['String'];
};

export enum MeterType {
  MsoW = 'MSO_W',
  MsoA = 'MSO_A',
  MsoE = 'MSO_E',
  MsoK = 'MSO_K',
  MsoL = 'MSO_L',
  MsoP = 'MSO_P',
  MsoH = 'MSO_H'
}

export type MissingAttributesInContractDocumentError = {
  __typename?: 'MissingAttributesInContractDocumentError';
  attributes: Array<Scalars['String']>;
};

export type MkvTemplateCustomer = {
  __typename?: 'MkvTemplateCustomer';
  person?: Maybe<Person>;
  address?: Maybe<Address>;
  birthday?: Maybe<Scalars['DateTime']>;
  advertisementAccepted?: Maybe<Scalars['Boolean']>;
  documentDeliveryMethod?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  existingCustomer?: Maybe<ObjectWithId>;
  isOperator?: Maybe<Scalars['Boolean']>;
};

export type MkvTemplateCustomerInput = {
  person?: Maybe<CreatePersonInput>;
  existingCustomer?: Maybe<UpdateExistingCustomer>;
  address?: Maybe<UpdateAddressInput>;
  birthday?: Maybe<Scalars['DateTime']>;
  hasSepa?: Maybe<Scalars['Boolean']>;
  documentDeliveryMethod?: Maybe<Scalars['String']>;
  advertisementAccepted?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  isOperator?: Maybe<Scalars['Boolean']>;
};

export type MkvTemplateData = {
  __typename?: 'MkvTemplateData';
  customer?: Maybe<MkvTemplateCustomer>;
  contractType?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  tariff?: Maybe<ObjectWithId>;
  loadProfile?: Maybe<Scalars['String']>;
};

export type MkvTemplateInput = {
  customer?: Maybe<MkvTemplateCustomerInput>;
  contractType?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  tariff?: Maybe<UpdateTariff>;
  loadProfile?: Maybe<Scalars['String']>;
};

export type MkvTemplates = {
  __typename?: 'MkvTemplates';
  sumMeterConsumption?: Maybe<MkvTemplateData>;
  sumMeterFeedIn?: Maybe<MkvTemplateData>;
  prodMeter?: Maybe<MkvTemplateData>;
  cascadeMeter?: Maybe<MkvTemplateData>;
};

export enum MsoType {
  Ha = 'HA',
  E1 = 'E1',
  E2 = 'E2',
  K = 'K',
  W = 'W'
}

export type MultipleFilesSuccessResponse = {
  __typename?: 'MultipleFilesSuccessResponse';
  success?: Maybe<Scalars['Boolean']>;
  results: Array<PdfResult>;
};

export type MultipleScheduleRequestsResults = JobNotFound | MultipleScheduleRequestsSuccess | ScheduleRequestsError;

export type MultipleScheduleRequestsSuccess = {
  __typename?: 'MultipleScheduleRequestsSuccess';
  results: Array<ScheduleRequest>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type Mutation = {
  __typename?: 'Mutation';
  createAccountingRunWorkflow: CreateAccountingRunResult;
  createOldAccountingWorkflow?: Maybe<Workflow>;
  createScheduleRequests: CreateScheduleRequestsResponse;
  deleteAccountingRunWorkflow: AccountingRunActionResultSuccess;
  deleteOldAccountingWorkflow?: Maybe<DeleteOldAccountingWorkflowResponse>;
  emgTest?: Maybe<EmgTestResult>;
  finishWorkflow: TransitionResponse;
  generateInvoiceDocument: GenerateInvoiceResponse;
  generateInvoiceDocumentPreview: GenerateContractDocumentResponse;
  importDebitPositions?: Maybe<Workflow>;
  invoiceScheduledRequestComplete: WorkflowSuccessResponse;
  restartAccountingRunWorkflow: AccountingRunActionResultSuccess;
  restartWorkflow: SupplierChangeWorkflowState;
  sanitizeWorkflowForContract?: Maybe<SanitizeWorkflowResponse>;
  scheduleGenerateInvoiceDocuments: CreateScheduleRequestsResponse;
  setAccountingRunDownpaymentStart: TransitionResponse;
  setAccountingRunPlantAutarky: TransitionResponse;
  setCancellationSupplier: SupplierChangeWorkflowState;
  setDownpaymentStart?: Maybe<Workflow>;
  setPlantAutarky?: Maybe<Workflow>;
  setSupplierChangeOperator: SupplierChangeWorkflowState;
  startSupplierChangeTimer: SupplierChangeWorkflowState;
  startSupplierChangeWorkflow: SupplierChangeWorkflowState;
  transitionAccountingRunWorkflow: TransitionResponse;
  transitionWorkflow?: Maybe<Workflow>;
  updateSupplierChangeCancellation: SupplierChangeWorkflowState;
  updateSupplierChangeCancellationContract: SupplierChangeWorkflowState;
  updateSupplierChangeLocalContract: SupplierChangeWorkflowState;
  updateSupplierChangeThirdPartyContract: SupplierChangeWorkflowState;
  zipDocumentsRequests: ZipDocumentsResult;
  loginUser: LoginResponse;
  logoutUser: Scalars['Boolean'];
  createPasswordReset: Scalars['Boolean'];
  check2FAuth?: Maybe<Check2FAuthReponse>;
  sendPasswordResetEmail: SendPasswordResetEmailResponse;
  checkResetPasswordToken: CheckResetPasswordTokenResponse;
  resetPassword?: Maybe<ResetPasswordResponse>;
  updatePassword?: Maybe<UpdatePasswordResponse>;
  createTariff: Tariff;
  updateTariff: Tariff;
  createTariffsBatched: CreateTariffsBatchedResponse;
  createPriceSheet: PriceSheetSuccessResult;
  deletePriceSheet?: Maybe<DeletePriceSheetSuccessResult>;
  updatePriceSheet: PriceSheetSuccessResult;
  createMeter: CreateMeterResponse;
  updateMeter: Meter;
  createMetersBatched: CreateMetersBatchedResponse;
  createMeterReading: MeterReading;
  createMeterReadingsBatched?: Maybe<CreateMeterReadingsBatchedResult>;
  deleteMeterReading: SuccessResponse;
  createPlantAdditionalAddress: Plant;
  createReport: CreateReportResponse;
  createPlantReport: CreatePlantReportResponse;
  createClientReport: CreateClientReportResponse;
  createETaxReport: CreateETaxReportResponse;
  createContract: CreateContractResponse;
  createContractImport: CreateContractImportResponse;
  createContractsBatched: CreateContractsBatchedResponse;
  updateContract: UpdateContractResponse;
  prepareUpdateContractsBatched: PrepareUpdateContractsBatchedResponse;
  updateContractsBatched: ContractsBatchedSuccess;
  updateContractImport: UpdateContractImportResponse;
  createContractsImportReport: SuccessResponse;
  createMeasurementContractSession: Scalars['String'];
  createMeasurementContract: CreateMeasurementContractResultResponse;
  deleteOdooContract: DeleteOdooContractResponse;
  updateAccounting: Contract;
  updateContractStatus: Contract;
  createMetadata: Metadata;
  updateMetadata: Metadata;
  deleteMetadata: Array<Metadata>;
  updateContractComponentTariff: Contract;
  replaceActiveContractTariff: Contract;
  updateContractMeterChange: Contract;
  updateAnnualConsumption: Consumption;
  terminateContract: TerminateContractResult;
  createAdHocSettlement: AdhocSettlement;
  acceptInvoiceDraft?: Maybe<AcceptInvoiceDraftResponse>;
  retryInvoiceDocumentCreation?: Maybe<AcceptInvoiceDraftResponse>;
  rejectInvoiceDraft: RejectInvoiceDraftResponse;
  deleteAccountingDraftConsumptionValues: Array<DeleteAccountingDraftConsumptionValuesResponse>;
  mergeInvoiceDrafts?: Maybe<Scalars['Boolean']>;
  createCommunication?: Maybe<CustomerCommunication>;
  generateContractDocument: GenerateContractDocumentResponse;
  generateMultipleContractDocuments: GenerateMultipleDocumentsResult;
  generateContractDocumentPreview: GenerateContractDocumentPreviewResponse;
  generateContractInvoiceDocumentPreview: GenerateContractDocumentPreviewResponse;
  sendTestMail: SendMailResponse;
  relocateDocuments: Array<Document>;
  removeDocuments: RemoveResponse;
  removeContractDocuments: RemoveContractDocumentsResponse;
  createVoucherBooking: ContractVoucherResponse;
  createBookingsBatched: CreateBookingsBatchedResponse;
  cancelInvoice: CancelInvoiceResponse;
  generateReversalInvoice: SuccessResponse;
  updateMkvTemplate: UpdateMkvTemplateResponse;
  updateClientSmtpConfig: UpdateAuthResponse;
  updateClientDetails: UpdateAuthResponse;
  updateOpsClientSettings: UpdateAuthResponse;
  setContractSupplierChangeOperator: SupplierChangeWorkflowState;
  updateSupplierChangeCancellationWorkflow: SupplierChangeWorkflowState;
  updatePlantMasterData: Plant;
  createPlant: CreatePlantResponse;
  /** Creates a single `Card`. */
  createCard?: Maybe<CreateCardPayload>;
  /** Creates a single `Crminstance`. */
  createCrminstance?: Maybe<CreateCrminstancePayload>;
  /** Creates a single `CrminstanceLaneRel`. */
  createCrminstanceLaneRel?: Maybe<CreateCrminstanceLaneRelPayload>;
  /** Creates a single `Lane`. */
  createLane?: Maybe<CreateLanePayload>;
  /** Creates a single `LaneCardRel`. */
  createLaneCardRel?: Maybe<CreateLaneCardRelPayload>;
  /** Deletes a single `Card` using a unique key. */
  deleteCard?: Maybe<DeleteCardPayload>;
  /** Deletes a single `Card` using its globally unique id. */
  deleteCardByNodeId?: Maybe<DeleteCardPayload>;
  deleteCardCustom?: Maybe<DeleteCardCustomPayload>;
  /** Deletes a single `Crminstance` using a unique key. */
  deleteCrminstance?: Maybe<DeleteCrminstancePayload>;
  /** Deletes a single `Crminstance` using its globally unique id. */
  deleteCrminstanceByNodeId?: Maybe<DeleteCrminstancePayload>;
  /** Deletes a single `Lane` using a unique key. */
  deleteLane?: Maybe<DeleteLanePayload>;
  /** Deletes a single `Lane` using its globally unique id. */
  deleteLaneByNodeId?: Maybe<DeleteLanePayload>;
  /** Deletes a single `LaneCardRel` using a unique key. */
  deleteLaneCardRel?: Maybe<DeleteLaneCardRelPayload>;
  /** Deletes a single `LaneCardRel` using its globally unique id. */
  deleteLaneCardRelByNodeId?: Maybe<DeleteLaneCardRelPayload>;
  /** Updates a single `Card` using a unique key and a patch. */
  updateCard?: Maybe<UpdateCardPayload>;
  /** Updates a single `Card` using its globally unique id and a patch. */
  updateCardByNodeId?: Maybe<UpdateCardPayload>;
  /** Updates a single `Crminstance` using a unique key and a patch. */
  updateCrminstance?: Maybe<UpdateCrminstancePayload>;
  /** Updates a single `Crminstance` using its globally unique id and a patch. */
  updateCrminstanceByNodeId?: Maybe<UpdateCrminstancePayload>;
  /** Updates a single `Lane` using a unique key and a patch. */
  updateLane?: Maybe<UpdateLanePayload>;
  /** Updates a single `Lane` using its globally unique id and a patch. */
  updateLaneByNodeId?: Maybe<UpdateLanePayload>;
  /** Updates a single `LaneCardRel` using a unique key and a patch. */
  updateLaneCardRel?: Maybe<UpdateLaneCardRelPayload>;
  /** Updates a single `LaneCardRel` using its globally unique id and a patch. */
  updateLaneCardRelByNodeId?: Maybe<UpdateLaneCardRelPayload>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAccountingRunWorkflowArgs = {
  payload: AccountingWorkflowPayload;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateOldAccountingWorkflowArgs = {
  payload: AccountingWorkflowPayload;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateScheduleRequestsArgs = {
  payload: ScheduleRequestsPayload;
  requestBatchSize?: Maybe<Scalars['Int']>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAccountingRunWorkflowArgs = {
  workflowId: Scalars['String'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteOldAccountingWorkflowArgs = {
  uuid?: Maybe<Scalars['String']>;
  workflowId: Scalars['ID'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationEmgTestArgs = {
  contractId?: Maybe<Scalars['String']>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationFinishWorkflowArgs = {
  workflowId: Scalars['String'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationGenerateInvoiceDocumentArgs = {
  accountingType: Scalars['String'];
  bccReceiver?: Maybe<Scalars['String']>;
  contract: InvoiceContract;
  invoiceCycle: InvoiceCycle;
  invoiceDraftId: Scalars['ID'];
  opc: OpcPayload;
  opcName: Scalars['String'];
  workflowUuid: Scalars['String'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationGenerateInvoiceDocumentPreviewArgs = {
  contract: InvoiceContract;
  invoiceCycle: InvoiceCycle;
  opc: OpcPayload;
  opcName: Scalars['String'];
  workflowUuid: Scalars['String'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationImportDebitPositionsArgs = {
  payload: ImportDebitPositionsPayload;
  workflowId: Scalars['ID'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationInvoiceScheduledRequestCompleteArgs = {
  additionalInput: Scalars['JSON'];
  name: Scalars['String'];
  results: Array<InvoiceScheduledRequestCompleteResult>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRestartAccountingRunWorkflowArgs = {
  workflowId: Scalars['String'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRestartWorkflowArgs = {
  workflowId: Scalars['String'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSanitizeWorkflowForContractArgs = {
  contractLabel: Scalars['String'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationScheduleGenerateInvoiceDocumentsArgs = {
  jobLabel: Scalars['String'];
  opcName: Scalars['String'];
  payload: Array<GenerateInvoiceDocumentInput>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetAccountingRunDownpaymentStartArgs = {
  downpaymentStartDate: Scalars['Date'];
  workflowId: Scalars['String'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetAccountingRunPlantAutarkyArgs = {
  payload: SetPlantAutarkyPayload;
  workflowId: Scalars['String'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetCancellationSupplierArgs = {
  letterPayload: CancellationLetterPayloadInput;
  supplier: NetworkOperatorInput;
  workflowId: Scalars['String'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetDownpaymentStartArgs = {
  payload: SetDownpaymentStartPayload;
  transitionName: Scalars['String'];
  workflowId: Scalars['ID'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetPlantAutarkyArgs = {
  payload: SetPlantAutarkyPayload;
  transitionName: Scalars['String'];
  workflowId: Scalars['ID'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetSupplierChangeOperatorArgs = {
  contractInfo: ContractInfo;
  gridOperator: NetworkOperatorInput;
  meteringPointOperator: NetworkOperatorInput;
  plantInfo: PlantInfo;
  workflowId: Scalars['String'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationStartSupplierChangeTimerArgs = {
  workflowId: Scalars['String'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationStartSupplierChangeWorkflowArgs = {
  payload: StartSupplierChangeWorkflowInput;
  type: SupplierChangeWorkflowType;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationTransitionAccountingRunWorkflowArgs = {
  action: TransitionAction;
  workflowId: Scalars['String'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationTransitionWorkflowArgs = {
  transitionName: Scalars['String'];
  workflowId: Scalars['ID'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSupplierChangeCancellationArgs = {
  cancelled: Scalars['Boolean'];
  payload?: Maybe<UpdateCancellationWorkflowInput>;
  workflowId: Scalars['String'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSupplierChangeCancellationContractArgs = {
  payload: UpdateSupplierChangeContractInput;
  workflowId: Scalars['String'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSupplierChangeLocalContractArgs = {
  payload: UpdateSupplierChangeLocalContractInput;
  workflowId: Scalars['String'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSupplierChangeThirdPartyContractArgs = {
  payload: UpdateSupplierChangeContractInput;
  workflowId: Scalars['String'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationZipDocumentsRequestsArgs = {
  hashes: Array<Scalars['String']>;
  receiver: Scalars['String'];
  zipName: Scalars['String'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationLoginUserArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  twoFactorAuthCallbackURL: Scalars['String'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreatePasswordResetArgs = {
  email: Scalars['String'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCheck2FAuthArgs = {
  id: Scalars['String'];
  token: Scalars['String'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSendPasswordResetEmailArgs = {
  email: Scalars['String'];
  resetPasswordCallbackUrl: Scalars['String'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCheckResetPasswordTokenArgs = {
  token: Scalars['String'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationResetPasswordArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  token: Scalars['String'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePasswordArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  currentPassword: Scalars['String'];
  updatePasswordCallbackUrl: Scalars['String'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTariffArgs = {
  tariff: TariffCreate;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTariffArgs = {
  tariffId: Scalars['ID'];
  tariff: TariffEdit;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTariffsBatchedArgs = {
  tariffs: Array<CreateTariffsBatchedInput>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreatePriceSheetArgs = {
  tariffId: Scalars['ID'];
  priceSheet: PriceSheetCreate;
  includeContracts: Scalars['Boolean'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePriceSheetArgs = {
  id: Scalars['ID'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePriceSheetArgs = {
  tariffId: Scalars['ID'];
  priceSheetId: Scalars['ID'];
  priceSheet: PriceSheetEdit;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateMeterArgs = {
  plantId: Scalars['ID'];
  meter: CreateMeterInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMeterArgs = {
  meterId: Scalars['ID'];
  plantId: Scalars['ID'];
  meter: UpdateMeterInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateMetersBatchedArgs = {
  meters: Array<CreateMetersBatchedInput>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateMeterReadingArgs = {
  meterId: Scalars['ID'];
  meterReading: CreateMeterReadingInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateMeterReadingsBatchedArgs = {
  meterReadings: Array<CreateMeterReadingsBatchedInput>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMeterReadingArgs = {
  obisChannel: Scalars['String'];
  meloID: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  reason?: Maybe<Scalars['String']>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreatePlantAdditionalAddressArgs = {
  plantId: Scalars['ID'];
  address: CreatePlantAdditionalAddress;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreatePlantReportArgs = {
  id: Scalars['ID'];
  salesStartDate: Scalars['DateTime'];
  salesEndDate: Scalars['DateTime'];
  accountingPeriodStartDate: Scalars['DateTime'];
  accountingPeriodEndDate: Scalars['DateTime'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateClientReportArgs = {
  salesStartDate: Scalars['DateTime'];
  salesEndDate: Scalars['DateTime'];
  accountingPeriodStartDate: Scalars['DateTime'];
  accountingPeriodEndDate: Scalars['DateTime'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateETaxReportArgs = {
  year: Scalars['Int'];
  fallbackAutarky: Scalars['Float'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateContractArgs = {
  plantId: Scalars['ID'];
  contract: CreateContractInput;
  meterType: Scalars['String'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateContractImportArgs = {
  plantId: Scalars['ID'];
  contract: CreateContractInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateContractsBatchedArgs = {
  contracts: Array<CreateContractsBatchedInput>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateContractArgs = {
  contractId: Scalars['ID'];
  contractLabel: Scalars['ID'];
  customerId: Scalars['ID'];
  customerLabel: Scalars['ID'];
  contract: UpdateContractInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationPrepareUpdateContractsBatchedArgs = {
  contracts: Array<PrepareUpdateContractsBatchedInput>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateContractsBatchedArgs = {
  payload: UpdateContractsBatchedPayload;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateContractImportArgs = {
  contractId: Scalars['ID'];
  contractLabel: Scalars['String'];
  customerLabel: Scalars['String'];
  contract: UpdateContractInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateContractsImportReportArgs = {
  results: Array<UpdateContractsBatchedResult>;
  name?: Maybe<Scalars['String']>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateMeasurementContractArgs = {
  plantId: Scalars['ID'];
  session: Scalars['String'];
  contract: CreateContractInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteOdooContractArgs = {
  id: Scalars['ID'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAccountingArgs = {
  contractId: Scalars['ID'];
  contractLabel: Scalars['ID'];
  customerId: Scalars['ID'];
  customerLabel: Scalars['ID'];
  reductions: UpdateReductionsInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateContractStatusArgs = {
  contractId: Scalars['ID'];
  contract: UpdateContractStatusInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateMetadataArgs = {
  contractLabel: Scalars['ID'];
  customerLabel: Scalars['ID'];
  metadata: CreateUpdateMetadataInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMetadataArgs = {
  contractLabel: Scalars['ID'];
  customerLabel: Scalars['ID'];
  metadata: CreateUpdateMetadataInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMetadataArgs = {
  contractLabel: Scalars['ID'];
  customerLabel: Scalars['ID'];
  metadata: CreateUpdateMetadataInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateContractComponentTariffArgs = {
  contractId: Scalars['ID'];
  contractComponentTariff: UpdateContractComponentInput;
  meterId: Scalars['ID'];
  meterReadingDate: Scalars['DateTime'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationReplaceActiveContractTariffArgs = {
  contractId: Scalars['ID'];
  contractComponentTariff: ReplaceActiveContractTariffInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateContractMeterChangeArgs = {
  contractId: Scalars['ID'];
  oldMeterId: Scalars['ID'];
  melo: Scalars['String'];
  meterChange: UpdateContractMeterChangeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAnnualConsumptionArgs = {
  contractId: Scalars['ID'];
  consumption: UpdateAnnualConsumptionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationTerminateContractArgs = {
  contractId: Scalars['ID'];
  contractLabel: Scalars['ID'];
  currentDate: Scalars['Date'];
  termination: ContractTerminationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAdHocSettlementArgs = {
  contractId: Scalars['ID'];
  adHocSettlement: AdhocSettlementInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAcceptInvoiceDraftArgs = {
  invoiceDraftsTuple: Array<InvoiceDraftTupleInput>;
  uuid: Scalars['ID'];
  accountingType: Scalars['String'];
  opcName: Scalars['String'];
  invoiceCycle: InvoiceCycle;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRetryInvoiceDocumentCreationArgs = {
  invoiceDraftsTuple: Array<InvoiceDraftTupleInput>;
  uuid: Scalars['ID'];
  accountingType: Scalars['String'];
  opcName: Scalars['String'];
  invoiceCycle: InvoiceCycle;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRejectInvoiceDraftArgs = {
  invoiceDraftsTuple: Array<InvoiceDraftTupleInput>;
  uuid: Scalars['ID'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAccountingDraftConsumptionValuesArgs = {
  contractLabels: Array<Scalars['String']>;
  validityStart: Scalars['String'];
  validityEnd: Scalars['String'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationMergeInvoiceDraftsArgs = {
  invoiceDraftIds: Array<Scalars['ID']>;
  opcName: Scalars['String'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCommunicationArgs = {
  contractId: Scalars['ID'];
  communication: CustomerCommunicationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationGenerateContractDocumentArgs = {
  contractId: Scalars['String'];
  type: Scalars['String'];
  deliveryPreference: DeliveryPreference;
  contractDocumentData?: Maybe<ContractDocumentData>;
  pricesheetChangeDocumentData?: Maybe<PricesheetChangeDocumentData>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationGenerateMultipleContractDocumentsArgs = {
  payloads: Array<GenerateContractDocumentInput>;
  deliveryPreference: DeliveryPreference;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationGenerateContractDocumentPreviewArgs = {
  contractId: Scalars['String'];
  type: Scalars['String'];
  contractDocumentData?: Maybe<ContractDocumentData>;
  pricesheetChangeDocumentData?: Maybe<PricesheetChangeDocumentData>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationGenerateContractInvoiceDocumentPreviewArgs = {
  contractLabel: Scalars['String'];
  opcName: Scalars['String'];
  workflowUuid: Scalars['String'];
  invoiceCycle: InvoiceCycle;
  invoiceId?: Maybe<Scalars['String']>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRelocateDocumentsArgs = {
  from: DocumentStorageOptions;
  to: DocumentStorageOptions;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRemoveDocumentsArgs = {
  hash: Scalars['String'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRemoveContractDocumentsArgs = {
  contractId: Scalars['String'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateVoucherBookingArgs = {
  contractLabel: Scalars['ID'];
  contractStart: Scalars['DateTime'];
  voucher: ContractVoucherData;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateBookingsBatchedArgs = {
  bookings: Array<CreateBookingsBatchedInput>;
  fileName?: Maybe<Scalars['String']>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCancelInvoiceArgs = {
  contractNumber: Scalars['String'];
  contractId: Scalars['String'];
  bookingName: Scalars['String'];
  opcName: Scalars['String'];
  isPaid: Scalars['Boolean'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationGenerateReversalInvoiceArgs = {
  contractNumber: Scalars['String'];
  contractId: Scalars['String'];
  opcName: Scalars['String'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMkvTemplateArgs = {
  mkvType: Scalars['String'];
  template: MkvTemplateInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateClientSmtpConfigArgs = {
  smtpConfig: ClientSmtpConfigInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateClientDetailsArgs = {
  clientDetails: ClientDetailsInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateOpsClientSettingsArgs = {
  opsClientSettings: OpsClientSettingsInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetContractSupplierChangeOperatorArgs = {
  meteringPointOperator: SupplierNetworkOperatorInput;
  gridOperator: SupplierNetworkOperatorInput;
  contractId: Scalars['String'];
  malo: Scalars['String'];
  workflowId: Scalars['String'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSupplierChangeCancellationWorkflowArgs = {
  cancelled: Scalars['Boolean'];
  workflowId: Scalars['String'];
  contractId: Scalars['String'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePlantMasterDataArgs = {
  plantId: Scalars['ID'];
  masterData: UpdatePlantInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreatePlantArgs = {
  plant: CreatePlantFlowInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCardArgs = {
  input: CreateCardInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCrminstanceArgs = {
  input: CreateCrminstanceInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCrminstanceLaneRelArgs = {
  input: CreateCrminstanceLaneRelInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateLaneArgs = {
  input: CreateLaneInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateLaneCardRelArgs = {
  input: CreateLaneCardRelInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCardArgs = {
  input: DeleteCardInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCardByNodeIdArgs = {
  input: DeleteCardByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCardCustomArgs = {
  input: DeleteCardCustomInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCrminstanceArgs = {
  input: DeleteCrminstanceInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCrminstanceByNodeIdArgs = {
  input: DeleteCrminstanceByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLaneArgs = {
  input: DeleteLaneInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLaneByNodeIdArgs = {
  input: DeleteLaneByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLaneCardRelArgs = {
  input: DeleteLaneCardRelInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLaneCardRelByNodeIdArgs = {
  input: DeleteLaneCardRelByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCardArgs = {
  input: UpdateCardInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCardByNodeIdArgs = {
  input: UpdateCardByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCrminstanceArgs = {
  input: UpdateCrminstanceInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCrminstanceByNodeIdArgs = {
  input: UpdateCrminstanceByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLaneArgs = {
  input: UpdateLaneInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLaneByNodeIdArgs = {
  input: UpdateLaneByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLaneCardRelArgs = {
  input: UpdateLaneCardRelInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLaneCardRelByNodeIdArgs = {
  input: UpdateLaneCardRelByNodeIdInput;
};

export type NetworkCharges = {
  chargeBill: Scalars['Float'];
  chargeMeterop: Scalars['Float'];
  chargeMeterread: Scalars['Float'];
  energyPrice: Scalars['Float'];
  fixedPrice: Scalars['Float'];
  networkChargesEnd: Scalars['String'];
  networkChargesStart: Scalars['String'];
};

export type NetworkOperator = {
  __typename?: 'NetworkOperator';
  bdewCode: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  companyName: Scalars['String'];
  country?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
};

export type NetworkOperatorInput = {
  bdewCode: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  companyName: Scalars['String'];
  country?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
};

export type NetworkOperatorResult = {
  __typename?: 'NetworkOperatorResult';
  gridOperator?: Maybe<NetworkOperator>;
  meteringPointOperator?: Maybe<NetworkOperator>;
  oldSupplier?: Maybe<NetworkOperator>;
};

export type NewConsumption = {
  consumptionDayRate: Scalars['Float'];
  totalConsumptionRate: Scalars['Float'];
  totalDays: Scalars['Float'];
  totalYearConsumptionRate: Scalars['Float'];
};

export type NewOrExistingMeterInput = {
  id?: Maybe<Scalars['ID']>;
  new?: Maybe<CreateMeterInput>;
};

export type NewsPost = {
  __typename?: 'NewsPost';
  id: Scalars['ID'];
  type: Scalars['String'];
  title: Scalars['String'];
  createdAt: Scalars['String'];
  forumId?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
};

export enum NewsPostType {
  ProductNews = 'ProductNews',
  MarketNews = 'MarketNews'
}

/** An object with a globally unique `ID`. */
export type Node = {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
};

export type NoPasswordResetSessionFound = {
  __typename?: 'NoPasswordResetSessionFound';
  message: Scalars['String'];
};

export type NoticePeriod = {
  __typename?: 'NoticePeriod';
  to: Scalars['String'];
  period: Scalars['String'];
  count: Scalars['Int'];
};

export type NoticePeriodInput = {
  to: Scalars['String'];
  period: Scalars['String'];
  count: Scalars['Int'];
};

export type ObjectWithId = {
  __typename?: 'ObjectWithId';
  id: Scalars['ID'];
};

export type OdooAddress = {
  __typename?: 'OdooAddress';
  partnerId?: Maybe<Scalars['ID']>;
  additionalPartnerId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  additionalName?: Maybe<Scalars['String']>;
  additionalNamePrefix?: Maybe<Scalars['String']>;
  companySecondaryFlag?: Maybe<Scalars['Boolean']>;
  streetName?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['CountryScalar']>;
  companyFlag?: Maybe<Scalars['Boolean']>;
  co?: Maybe<Scalars['String']>;
};

export type OdooBankAccountPayer = {
  __typename?: 'OdooBankAccountPayer';
  contractNumber?: Maybe<Scalars['String']>;
  payerName?: Maybe<Scalars['String']>;
};

export type OdooContract = {
  __typename?: 'OdooContract';
  id: Scalars['ID'];
  label: Scalars['String'];
  documentDeliveryMethod: Scalars['String'];
};

export type OdooContractNotFoundError = Error & {
  __typename?: 'OdooContractNotFoundError';
  message: Scalars['String'];
  pointer?: Maybe<Array<Scalars['String']>>;
  retryable?: Maybe<Scalars['Boolean']>;
};

export type OdooContractPartnerAssignedToOtherContractError = Error & {
  __typename?: 'OdooContractPartnerAssignedToOtherContractError';
  message: Scalars['String'];
  pointer?: Maybe<Array<Scalars['String']>>;
  retryable?: Maybe<Scalars['Boolean']>;
};

export enum OnCompleteAction {
  SendResult = 'SEND_RESULT',
  ZipDocuments = 'ZIP_DOCUMENTS'
}

export type OnCompleteInput = {
  action: OnCompleteAction;
  variables: OnCompleteVariables;
};

export type OnCompleteVariables = {
  additionalInput?: Maybe<Scalars['JSON']>;
  clientId?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  receiver?: Maybe<Scalars['String']>;
  service?: Maybe<Service>;
  zipName?: Maybe<Scalars['String']>;
};

export type OpcPayload = {
  accountingType: Scalars['String'];
  customerData: Array<CustomerData>;
  underlyingData: UnderlyingData;
};

export type OpcStepResult = {
  __typename?: 'OpcStepResult';
  errored?: Maybe<Array<OpcStepResultInfo>>;
  name: Scalars['String'];
  passed?: Maybe<Array<OpcStepResultInfo>>;
};

export type OpcStepResultInfo = {
  __typename?: 'OpcStepResultInfo';
  error?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

export type OperationError = {
  __typename?: 'OperationError';
  id?: Maybe<Scalars['String']>;
  reason: Scalars['String'];
};

export type OperationInput = {
  id: Scalars['String'];
  variables: Scalars['JSON'];
};

export type OperationResult = {
  __typename?: 'OperationResult';
  data: OperationResultContent;
  id: Scalars['String'];
  jobId?: Maybe<Scalars['String']>;
};

export type OperationResultContent = JsonBox | OperationError;

export type OpsClientSettings = {
  __typename?: 'OpsClientSettings';
  debtorLabel?: Maybe<Scalars['Boolean']>;
  clientStartDate?: Maybe<Scalars['DateTime']>;
  bccReceiver?: Maybe<Scalars['String']>;
  bookingsInfo?: Maybe<Array<InfoProductBookingsType>>;
};

export type OpsClientSettingsInput = {
  debtorLabel?: Maybe<Scalars['Boolean']>;
  clientStartDate?: Maybe<Scalars['DateTime']>;
  bccReceiver?: Maybe<Scalars['String']>;
  bookingsInfo?: Maybe<Array<InfoProductBookingsTypeInput>>;
};

export type OverviewStepResult = {
  __typename?: 'OverviewStepResult';
  additionalErrors: Array<Maybe<AccountingRunValidationError>>;
  consumptionValuesWarnings?: Maybe<Array<AccountingRunValidatedConsumption>>;
  hasSearchableLabels: Scalars['Boolean'];
  invalidContracts: Array<AccountingRunValidatedContractMeter>;
  session: Scalars['String'];
  validContracts: Array<AccountingRunValidatedContractMeter>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['Cursor']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['Cursor']>;
};

export type Payer = {
  __typename?: 'Payer';
  accountHolderId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  bic?: Maybe<Scalars['String']>;
};

export type PdfResult = {
  __typename?: 'PdfResult';
  url: Scalars['String'];
  hash?: Maybe<Scalars['String']>;
};

export type Person = {
  __typename?: 'Person';
  namePrefix?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  contactPhone?: Maybe<Scalars['String']>;
  contactMobile?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  companyFlag?: Maybe<Scalars['Boolean']>;
  co?: Maybe<Scalars['String']>;
};

export type PhtRef = {
  __typename?: 'PhtRef';
  id: Scalars['String'];
  table: Scalars['String'];
};

export type Plant = {
  __typename?: 'Plant';
  id: Scalars['ID'];
  projectId?: Maybe<Scalars['ID']>;
  project?: Maybe<ProjectSimple>;
  name: Scalars['String'];
  mainAddress: Address;
  addresses: Array<Address>;
  kpis: PlantKpi;
  measurementConcept: Scalars['String'];
  meteringPointOperatorId: Scalars['String'];
  meteringPointOperator: MeteringPointOperator;
  balancingAreaAccountId: Scalars['String'];
  balancingAreaAccount: BalancingAreaAccount;
  balancingAreaGroupId: Scalars['String'];
  balancingAreaGroupAccount?: Maybe<BalancingAreaGroupAccount>;
  supplierAccountId?: Maybe<Scalars['String']>;
  supplierAccount: Supplier;
  transmissionSystemOperatorId: Scalars['String'];
  transmissionSystemOperator: TransmissionSystemOperator;
  tenantContracts: Array<ContractExcerpt>;
  buildingContracts: Array<ContractExcerpt>;
  measurementConceptContractsInSetup: Array<ContractMeterExcerpt>;
  autarkies?: Maybe<Array<Autarky>>;
  reports: Array<Report>;
  isInSetup?: Maybe<Scalars['Boolean']>;
};

export type PlantAutarkies = {
  autarky: Scalars['Float'];
  plantId: Scalars['Int'];
};

export type PlantExists = {
  __typename?: 'PlantExists';
  exists: Scalars['Boolean'];
  plantId?: Maybe<Scalars['ID']>;
};

export type PlantInfo = {
  label: Scalars['String'];
  malo: Scalars['String'];
  street: Scalars['String'];
  streetNumber: Scalars['String'];
  town: Scalars['String'];
  zip: Scalars['String'];
};

export type PlantKpi = {
  __typename?: 'PlantKPI';
  units: Scalars['Int'];
  unitsMembers: Scalars['Int'];
};

export type PlantMeters = {
  __typename?: 'PlantMeters';
  matchedTenantMeters: Array<ContractMeterExcerpt>;
  unmatchedMeters: Array<ContractMeterExcerpt>;
  matchedBuildingMeters: Array<ContractMeterExcerpt>;
};

export type PlantSimple = {
  __typename?: 'PlantSimple';
  id: Scalars['ID'];
  name: Scalars['String'];
  projectId?: Maybe<Scalars['ID']>;
  project?: Maybe<ProjectSimple>;
  mainAddress: Address;
  addresses: Array<Address>;
  kpis: PlantKpi;
  autarkies?: Maybe<Array<Autarky>>;
  meteringPointOperatorId?: Maybe<Scalars['String']>;
  meteringPointOperator?: Maybe<MeteringPointOperator>;
};

export type PrepareUpdateContractsBatchedInput = {
  V_VNR?: Maybe<Scalars['String']>;
  VP_TYP?: Maybe<Scalars['String']>;
  VP_ANREDE?: Maybe<Scalars['String']>;
  VP_NAME?: Maybe<Scalars['String']>;
  VP_COMPANY?: Maybe<Scalars['String']>;
  VP_COMPANY_CO?: Maybe<Scalars['String']>;
  VP_EMAIL?: Maybe<Scalars['String']>;
  VP_TELEFON?: Maybe<Scalars['String']>;
  VP_MOBIL?: Maybe<Scalars['String']>;
  VP_WERBUNG?: Maybe<Scalars['String']>;
  VP_KOMMUNIKATION?: Maybe<Scalars['String']>;
  VP_STR?: Maybe<Scalars['String']>;
  VP_HSNR?: Maybe<Scalars['String']>;
  VP_PLZ?: Maybe<Scalars['String']>;
  VP_ORT?: Maybe<Scalars['String']>;
  VP_LK?: Maybe<Scalars['String']>;
  RE_TYP?: Maybe<Scalars['String']>;
  RE_ANREDE_1?: Maybe<Scalars['String']>;
  RE_NAME_1?: Maybe<Scalars['String']>;
  RE_ANREDE_2?: Maybe<Scalars['String']>;
  RE_NAME_2?: Maybe<Scalars['String']>;
  RE_COMPANY?: Maybe<Scalars['String']>;
  RE_COMPANY_CO?: Maybe<Scalars['String']>;
  RE_STR?: Maybe<Scalars['String']>;
  RE_HSNR?: Maybe<Scalars['String']>;
  RE_PLZ?: Maybe<Scalars['String']>;
  RE_ORT?: Maybe<Scalars['String']>;
  RE_LK?: Maybe<Scalars['String']>;
  LF_TYP?: Maybe<Scalars['String']>;
  LF_ANREDE?: Maybe<Scalars['String']>;
  LF_NAME?: Maybe<Scalars['String']>;
  LF_COMPANY?: Maybe<Scalars['String']>;
  LF_COMPANY_CO?: Maybe<Scalars['String']>;
  LF_STR?: Maybe<Scalars['String']>;
  LF_HSNR?: Maybe<Scalars['String']>;
  LF_PLZ?: Maybe<Scalars['String']>;
  LF_ORT?: Maybe<Scalars['String']>;
  LF_LK?: Maybe<Scalars['String']>;
  V_LIEFERBEGINN?: Maybe<Scalars['String']>;
  V_STATUS?: Maybe<Scalars['String']>;
  AB_SEPA?: Maybe<Scalars['String']>;
  AB_KONTOINHABER?: Maybe<Scalars['String']>;
  AB_IBAN?: Maybe<Scalars['String']>;
  AB_BIC?: Maybe<Scalars['String']>;
  AB_REF?: Maybe<Scalars['String']>;
  AB_UNTERSCHRIFT?: Maybe<Scalars['String']>;
  AB_ABSCHLAG?: Maybe<Scalars['String']>;
  AB_BEGINN?: Maybe<Scalars['String']>;
};

export type PrepareUpdateContractsBatchedResponse = {
  __typename?: 'PrepareUpdateContractsBatchedResponse';
  valid: Array<UpdateContractsBatchedValidationSuccess>;
  failed: Array<UpdateContractsBatchedValidationError>;
};

export type PriceSheet = {
  __typename?: 'PriceSheet';
  id: Scalars['ID'];
  name: Scalars['String'];
  endDate?: Maybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
  basicPrice: Scalars['Float'];
  energyPrice?: Maybe<Scalars['Float']>;
  energyPriceLocal?: Maybe<Scalars['Float']>;
  energyPriceResidual?: Maybe<Scalars['Float']>;
  ignorePriceGuarantee: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type PricesheetChangeDocumentData = {
  meterNumber: Scalars['String'];
  customerLabel: Scalars['String'];
  contractLabel: Scalars['String'];
  tariffName: Scalars['String'];
  pricesheetName: Scalars['String'];
  pricesheetStartDate: Scalars['DateTime'];
  salutation: Scalars['String'];
  priceGuaranteeEndDate: Scalars['DateTime'];
  name: Scalars['String'];
  date: Scalars['DateTime'];
  tariffNewPricesheetDetails: TariffPricesheetDetails;
  tariffOldPricesheetDetails: TariffPricesheetDetails;
  billingAddress: CreateOdooAddressInput;
  workspace?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  ignorePriceGuarantee?: Maybe<Scalars['Boolean']>;
};

export type PriceSheetCreate = {
  name: Scalars['String'];
  basicPrice: Scalars['Float'];
  energyPrice?: Maybe<Scalars['Float']>;
  energyPriceLocal?: Maybe<Scalars['Float']>;
  energyPriceResidual?: Maybe<Scalars['Float']>;
  startDate: Scalars['DateTime'];
  endDate?: Maybe<Scalars['DateTime']>;
  ignorePriceGuarantee: Scalars['Boolean'];
};

export type PriceSheetEdit = {
  name?: Maybe<Scalars['String']>;
};

export type PriceSheetSuccessResult = {
  __typename?: 'PriceSheetSuccessResult';
  tariff: Tariff;
  contracts?: Maybe<Array<Contract>>;
};

export type Project = {
  __typename?: 'Project';
  id: Scalars['ID'];
  name: Scalars['String'];
  plants: Array<PlantSimple>;
};

export type ProjectSimple = {
  __typename?: 'ProjectSimple';
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** The root query type which gives access points into the data universe. */
export type Query = Node & {
  __typename?: 'Query';
  getAccountingRunWorkflowState: AccountingRunWorkflowState;
  getAccountingRunWorkflows: GetAccountingRunWorkflowsResponse;
  getAccountingRunWorkflowsByUuids: GetAccountingRunWorkflowsResponse;
  getAllScheduleRequestsResults?: Maybe<MultipleScheduleRequestsResults>;
  getMultipleScheduleRequestsResults?: Maybe<MultipleScheduleRequestsResults>;
  getScheduleRequestsWorkflow: ScheduleRequestResult;
  getSupplierChangeWorkflowState: SupplierChangeWorkflowState;
  getSupplierChangeWorkflows: GetSupplierChangeResponse;
  readAccountingWorkflowsByUuids: Array<Workflow>;
  readWorkflow?: Maybe<Workflow>;
  readWorkflows: Array<Workflow>;
  readContractAccountingHistory: ContractAccountingHistory;
  readAccountingDetailsReport: Array<AccountingDetailsReport>;
  readAccountingOverview: AccountingOverview;
  readAccountingKpis: AccountingKpis;
  readCurrentUser: User;
  readTariffs: Array<Tariff>;
  readTariff: Tariff;
  checkDeletePriceSheet?: Maybe<CheckDeletePriceSheetResult>;
  readMeters: Array<BoundMeter>;
  readMeter?: Maybe<Meter>;
  /** @deprecated Use `readMeterReadings` Query instead. */
  readMeterReading: Array<MeterReading>;
  readMeterReadings: Array<MeterReading>;
  getExtrapolatedMeterReading?: Maybe<ExtrapolateMeterReadingResult>;
  readPlantMeters: PlantMeters;
  readDocuments: Array<Document>;
  getGenerateMultipleDocumentsResult: GenerateMultipleDocumentsResult;
  getDocumentTypes: Array<DocumentType>;
  readContract?: Maybe<Contract>;
  readContractFast?: Maybe<Contract>;
  readContractsForMeter: Array<ContractForMeter>;
  readContractByCustomer?: Maybe<Contract>;
  readContracts: Array<OdooContract>;
  readContractsBatch: Array<Contract>;
  readContractsAll: Array<Contract>;
  readContractsExport: Scalars['String'];
  readContractExists: ContractExists;
  readContractCancelationDate?: Maybe<Scalars['String']>;
  readNewPosts?: Maybe<Array<Maybe<NewsPost>>>;
  readNewsComment?: Maybe<NewsPost>;
  readConsumptions: ConsumptionsForContract;
  readMetadata: Array<Metadata>;
  readMetadataKeys: Array<Metadata>;
  readCustomers: Array<CustomerLight>;
  readCustomerExists: CustomerExists;
  readMeloBound: MeloMaloBoundResponse;
  readMaloBound: MeloMaloBoundResponse;
  readReports: Array<Report>;
  readPlantReports: Array<Report>;
  readBookingsReports: Array<BookingReport>;
  readSuppliers: Array<Supplier>;
  readBalancingAreaAccounts: Array<BalancingAreaAccount>;
  readBalancingAreaAccount?: Maybe<BalancingAreaAccount>;
  readBalancingAreaGroupAccounts: Array<BalancingAreaGroupAccount>;
  readBalancingAreaGroupAccount: BalancingAreaGroupAccount;
  readMeteringPointOperators: Array<MeteringPointOperator>;
  readLoadProfiles: Array<LoadProfile>;
  readInvoiceDrafts: Array<InvoiceDraft>;
  readInvoiceDraftExcerpts: Array<InvoiceDraftExcerpt>;
  accountingsInTimespan: Array<Maybe<AccountingStatusForContract>>;
  readNextLabel: Scalars['String'];
  getAnnotations: Array<Maybe<AnnotatedType>>;
  readContractExcerpts: Array<ContractExcerpt>;
  search: Array<SearchResult>;
  readWorkspaces: Array<Workspace>;
  readDeliveryMethods: Array<Customer>;
  readAccountMoves?: Maybe<AccountMoves>;
  getSumMeterConsumption: SumMeterConsumptionResponse;
  getBicForIban?: Maybe<Scalars['String']>;
  readPowerSuppliers: Array<BdewCode>;
  readMkvTemplates?: Maybe<MkvTemplates>;
  readMkvTemplate?: Maybe<MkvTemplateData>;
  readClientSmtpConfig?: Maybe<ClientSmtpConfig>;
  readClientDetails?: Maybe<ClientDetails>;
  readOpsClientSettings?: Maybe<OpsClientSettings>;
  readCheckClientSetup?: Maybe<ContractClientSetup>;
  readProjects: Array<Project>;
  readProject?: Maybe<Project>;
  readPlantsAutarkies: Array<PlantSimple>;
  readPlants: Array<Plant>;
  readPlant?: Maybe<Plant>;
  readPlantExists: PlantExists;
  card?: Maybe<Card>;
  /** Reads a single `Card` using its globally unique `ID`. */
  cardByNodeId?: Maybe<Card>;
  /** Reads and enables pagination through a set of `Card`. */
  cards?: Maybe<CardsConnection>;
  crminstance?: Maybe<Crminstance>;
  /** Reads a single `Crminstance` using its globally unique `ID`. */
  crminstanceByNodeId?: Maybe<Crminstance>;
  /** Reads and enables pagination through a set of `CrminstanceLaneRel`. */
  crminstanceLaneRels?: Maybe<CrminstanceLaneRelsConnection>;
  /** Reads and enables pagination through a set of `Crminstance`. */
  crminstances?: Maybe<CrminstancesConnection>;
  lane?: Maybe<Lane>;
  /** Reads a single `Lane` using its globally unique `ID`. */
  laneByNodeId?: Maybe<Lane>;
  laneCardRel?: Maybe<LaneCardRel>;
  /** Reads a single `LaneCardRel` using its globally unique `ID`. */
  laneCardRelByNodeId?: Maybe<LaneCardRel>;
  /** Reads and enables pagination through a set of `LaneCardRel`. */
  laneCardRels?: Maybe<LaneCardRelsConnection>;
  /** Reads and enables pagination through a set of `Lane`. */
  lanes?: Maybe<LanesConnection>;
  /** Fetches an object given its globally unique `ID`. */
  node?: Maybe<Node>;
  /** The root query type must be a `Node` to work well with Relay 1 mutations. This just resolves to `query`. */
  nodeId: Scalars['ID'];
  /**
   * Exposes the root query type nested one level down. This is helpful for Relay 1
   * which can only query top level fields if they are in a particular form.
   */
  query: Query;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetAccountingRunWorkflowStateArgs = {
  workflowId: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGetAccountingRunWorkflowsArgs = {
  nextPageToken?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
  searchAttributes?: Maybe<AccountingRunSearchAttributes>;
  sorting?: Maybe<AccountingRunSearchSorting>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetAccountingRunWorkflowsByUuidsArgs = {
  uuids: Array<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetMultipleScheduleRequestsResultsArgs = {
  jobIds: Array<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetScheduleRequestsWorkflowArgs = {
  workflowId: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGetSupplierChangeWorkflowStateArgs = {
  workflowId: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGetSupplierChangeWorkflowsArgs = {
  nextPageToken?: Maybe<Scalars['String']>;
  workflowType: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryReadAccountingWorkflowsByUuidsArgs = {
  uuids: Array<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryReadWorkflowArgs = {
  workflowId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryReadContractAccountingHistoryArgs = {
  contractId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryReadAccountingDetailsReportArgs = {
  opcName: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryReadTariffsArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryReadTariffArgs = {
  id: Array<Scalars['ID']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCheckDeletePriceSheetArgs = {
  id: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryReadMetersArgs = {
  plantId?: Maybe<Scalars['ID']>;
  boundStartAt?: Maybe<Scalars['String']>;
  boundEndAt?: Maybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryReadMeterArgs = {
  id: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryReadMeterReadingArgs = {
  meterId: Scalars['ID'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryReadMeterReadingsArgs = {
  meterId: Scalars['ID'];
  metering: Metering;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGetExtrapolatedMeterReadingArgs = {
  meterId: Scalars['ID'];
  date: Scalars['DateTime'];
};


/** The root query type which gives access points into the data universe. */
export type QueryReadPlantMetersArgs = {
  id: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryReadDocumentsArgs = {
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetGenerateMultipleDocumentsResultArgs = {
  jobId: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryReadContractArgs = {
  contractId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryReadContractFastArgs = {
  contractId: Scalars['ID'];
  contractLabel: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryReadContractsForMeterArgs = {
  meterId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryReadContractByCustomerArgs = {
  customerId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryReadContractsArgs = {
  contractLabels: Array<Scalars['ID']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryReadContractsBatchArgs = {
  ids: Array<Scalars['ID']>;
  byLabel?: Maybe<Scalars['Boolean']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryReadContractExistsArgs = {
  contractLabel: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryReadContractCancelationDateArgs = {
  contractId: Scalars['ID'];
  tariffId: Scalars['ID'];
  noticeDate?: Maybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryReadNewPostsArgs = {
  types: Array<NewsPostType>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryReadNewsCommentArgs = {
  topicId: Scalars['ID'];
  type: NewsPostType;
};


/** The root query type which gives access points into the data universe. */
export type QueryReadConsumptionsArgs = {
  contractID: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryReadMetadataArgs = {
  contractLabel: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryReadMetadataKeysArgs = {
  contractLabel: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryReadCustomersArgs = {
  isOperator?: Maybe<Scalars['Boolean']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryReadCustomerExistsArgs = {
  customerLabel: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryReadMeloBoundArgs = {
  contractStartDate: Scalars['String'];
  contractEndDate?: Maybe<Scalars['String']>;
  melo: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryReadMaloBoundArgs = {
  contractStartDate: Scalars['String'];
  contractEndDate?: Maybe<Scalars['String']>;
  malo: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryReadReportsArgs = {
  types?: Maybe<Array<Scalars['String']>>;
  plantId?: Maybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryReadPlantReportsArgs = {
  plantId?: Maybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryReadBalancingAreaAccountArgs = {
  balancingAreaAccountId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryReadBalancingAreaGroupAccountArgs = {
  balancingAreaGroupAccountId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryReadLoadProfilesArgs = {
  balancingAreaAccountId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryReadInvoiceDraftsArgs = {
  confirmed?: Maybe<Scalars['Boolean']>;
  filterByOPCName?: Maybe<Scalars['String']>;
  showDocuments?: Maybe<Scalars['Boolean']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAccountingsInTimespanArgs = {
  plantIds?: Maybe<Array<Scalars['ID']>>;
  contractIds?: Maybe<Array<Scalars['ID']>>;
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
};


/** The root query type which gives access points into the data universe. */
export type QueryReadNextLabelArgs = {
  type: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGetAnnotationsArgs = {
  types: Array<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryReadContractExcerptsArgs = {
  ids: Array<Scalars['ID']>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySearchArgs = {
  needle: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryReadAccountMovesArgs = {
  contractLabel: Scalars['ID'];
  startAt: Scalars['DateTime'];
  endAt: Scalars['DateTime'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGetSumMeterConsumptionArgs = {
  contractId?: Maybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetBicForIbanArgs = {
  iban: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryReadPowerSuppliersArgs = {
  type: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryReadMkvTemplateArgs = {
  template?: Maybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryReadProjectsArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryReadProjectArgs = {
  id: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryReadPlantsAutarkiesArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryReadPlantArgs = {
  id: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryReadPlantExistsArgs = {
  plantName: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCardArgs = {
  id: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCardByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCardsArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<CardCondition>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<CardsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCrminstanceArgs = {
  id: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCrminstanceByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCrminstanceLaneRelsArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<CrminstanceLaneRelCondition>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<CrminstanceLaneRelsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCrminstancesArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<CrminstanceCondition>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<CrminstancesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryLaneArgs = {
  id: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLaneByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLaneCardRelArgs = {
  idCard: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLaneCardRelByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLaneCardRelsArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<LaneCardRelCondition>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<LaneCardRelsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryLanesArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<LaneCondition>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<LanesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryNodeArgs = {
  nodeId: Scalars['ID'];
};

export type RateLimitReached = {
  __typename?: 'RateLimitReached';
  message: Scalars['String'];
};

export type RejectInvoiceDraftResponse = {
  __typename?: 'RejectInvoiceDraftResponse';
  succeeded: Array<Maybe<InvoiceDraftTuple>>;
  failed: Array<Maybe<InvoiceDraftTuple>>;
};

export type RemoveContractDocumentsResponse = DocumentsRemovedResponse | UnknownError;

export type RemoveResponse = {
  __typename?: 'RemoveResponse';
  success: Scalars['Boolean'];
};

export type ReplaceActiveContractTariffInput = {
  tariffAssignmentValidityStartDate?: Maybe<Scalars['DateTime']>;
  tariff?: Maybe<UpdateTariff>;
};

export type Report = {
  __typename?: 'Report';
  id: Scalars['ID'];
  plantId?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  name: Scalars['String'];
  url: Scalars['String'];
  createdAt: Scalars['DateTime'];
  entityId?: Maybe<Scalars['String']>;
};

export type ResetPasswordResponse = ResetPasswordSuccess | WrongPasswordResetFlow | NoPasswordResetSessionFound | ServiceNotReachableError | UnknownError;

export type ResetPasswordSuccess = {
  __typename?: 'ResetPasswordSuccess';
  success: Scalars['Boolean'];
  message: Scalars['String'];
};

export type Role = {
  __typename?: 'Role';
  name: Scalars['String'];
  meta?: Maybe<Scalars['JSON']>;
};

export type SanitizeWorkflowResponse = UnknownError | WorkflowResponse;

export type ScheduleRequest = {
  __typename?: 'ScheduleRequest';
  finishedOn?: Maybe<Scalars['String']>;
  jobId: Scalars['String'];
  jobLabel?: Maybe<Scalars['String']>;
  jobName: Scalars['String'];
  progress: Scalars['Int'];
  result: Array<OperationResult>;
  startedOn?: Maybe<Scalars['String']>;
  status: Status;
  total: Scalars['Int'];
};

export type ScheduleRequestResult = JobNotFound | ScheduleRequestSuccess | ScheduleRequestsError;

export type ScheduleRequestsError = {
  __typename?: 'ScheduleRequestsError';
  message: Scalars['String'];
};

export type ScheduleRequestsPayload = {
  jobLabel?: Maybe<Scalars['String']>;
  jobName: Scalars['String'];
  onComplete?: Maybe<OnCompleteInput>;
  operationInputs: Array<OperationInput>;
  operationName: Scalars['String'];
  query: Scalars['String'];
  service: Service;
};

export type ScheduleRequestSuccess = {
  __typename?: 'ScheduleRequestSuccess';
  result: ScheduleRequest;
};

export type SearchIdentifiers = {
  __typename?: 'SearchIdentifiers';
  sourceId: Scalars['Int'];
  projectId?: Maybe<Scalars['Int']>;
  plantId?: Maybe<Scalars['Int']>;
  contractId?: Maybe<Scalars['Int']>;
};

export type SearchResult = {
  __typename?: 'SearchResult';
  source: Scalars['String'];
  designation: Scalars['String'];
  identifiers: SearchIdentifiers;
  object: Scalars['JSONObject'];
};

export type SendMailResponse = SuccessResponse | UnknownError;

export type SendPasswordResetEmailResponse = SendPasswordResetEmailSuccess | WrongPasswordResetFlow | ServiceNotReachableError | UnknownError;

export type SendPasswordResetEmailSuccess = {
  __typename?: 'SendPasswordResetEmailSuccess';
  email: Scalars['String'];
};

export enum Service {
  DocumentManager = 'DOCUMENT_MANAGER',
  LocalSupplier = 'LOCAL_SUPPLIER',
  Mailhub = 'MAILHUB',
  WorkflowManager = 'WORKFLOW_MANAGER'
}

export type ServiceNotReachableError = {
  __typename?: 'ServiceNotReachableError';
  message: Scalars['String'];
};

export type SetDownpaymentStartPayload = {
  startDate: Scalars['Date'];
};

export type SetLoadProfileInput = {
  id: Scalars['ID'];
};

export type SetPlantAutarkyPayload = {
  autarkies: Array<PlantAutarkies>;
};

export type SetTariffInput = {
  id: Scalars['ID'];
};

export type SettlementDate = {
  __typename?: 'SettlementDate';
  day: Scalars['String'];
  month: Scalars['String'];
};

export type SetWorkspaceInput = {
  id: Scalars['String'];
};

export type ShippingAddress = {
  __typename?: 'ShippingAddress';
  namePrefix?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  streetName?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['CountryScalar']>;
  companyFlag?: Maybe<Scalars['Boolean']>;
};

export type SmtpAuthConfig = {
  __typename?: 'SmtpAuthConfig';
  user?: Maybe<Scalars['String']>;
  pass?: Maybe<Scalars['String']>;
};

export type SmtpAuthConfigInput = {
  user?: Maybe<Scalars['String']>;
  pass?: Maybe<Scalars['String']>;
};

export enum SortDirection {
  Asc = 'Asc',
  Desc = 'Desc'
}

export type StandardAccountingContractExcerpt = {
  __typename?: 'StandardAccountingContractExcerpt';
  id: Scalars['Int'];
  plantId?: Maybe<Scalars['ID']>;
  label: Scalars['String'];
  customerLabel?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  settlement: SettlementDate;
  accFlowUUID?: Maybe<Scalars['String']>;
};

export type StandardAccountingExcerpt = {
  __typename?: 'StandardAccountingExcerpt';
  monthlySettlement: Array<StandardAccountingContractExcerpt>;
  quarterlySettlement: Array<StandardAccountingContractExcerpt>;
  halfYearlySettlement: Array<StandardAccountingContractExcerpt>;
  yearlySettlement: Array<StandardAccountingContractExcerpt>;
};

export type StartSupplierChangeWorkflowInput = {
  contractId: Scalars['String'];
  contractLabel: Scalars['String'];
  contractName: Scalars['String'];
  malo: Scalars['String'];
  meter: Scalars['String'];
  plant: Scalars['String'];
  workflowId: Scalars['String'];
};

export type State = {
  __typename?: 'State';
  context: Scalars['JSON'];
  name: Scalars['String'];
  parentName?: Maybe<Scalars['String']>;
  transitions: Array<Scalars['String']>;
};

export enum Status {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  CompletedWithErrors = 'COMPLETED_WITH_ERRORS',
  Delayed = 'DELAYED',
  Failed = 'FAILED',
  Unknown = 'UNKNOWN',
  Waiting = 'WAITING',
  WaitingChildren = 'WAITING_CHILDREN',
  InProgress = 'IN_PROGRESS'
}

export enum StepStatus {
  Active = 'ACTIVE',
  Failed = 'FAILED',
  Inactive = 'INACTIVE',
  Succeeded = 'SUCCEEDED',
  Timeout = 'TIMEOUT',
  WaitingForExternal = 'WAITING_FOR_EXTERNAL',
  WaitingForUser = 'WAITING_FOR_USER'
}

export type Subscription = {
  __typename?: 'Subscription';
  workflowChange?: Maybe<WorkflowChange>;
};

export type SuccessResponse = {
  __typename?: 'SuccessResponse';
  success?: Maybe<Scalars['Boolean']>;
  fileURL?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
};

export type SumMeterConsumptionError = {
  __typename?: 'SumMeterConsumptionError';
  message: Scalars['String'];
};

export type SumMeterConsumptionResponse = SumMeterConsumptionSuccess | SumMeterConsumptionError;

export type SumMeterConsumptionSuccess = {
  __typename?: 'SumMeterConsumptionSuccess';
  malo: Scalars['String'];
};

export type Supplier = {
  __typename?: 'Supplier';
  /** Lieferant */
  id: Scalars['ID'];
  name: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  eic?: Maybe<Scalars['String']>;
};

export type SupplierChangeData = {
  __typename?: 'SupplierChangeData';
  ContractId: Scalars['String'];
  ContractLabel: Scalars['String'];
  ContractName: Scalars['String'];
  Malo: Scalars['String'];
  Meter: Scalars['String'];
  NetworkOperator?: Maybe<Scalars['String']>;
  Plant: Scalars['String'];
  RunId: Scalars['String'];
  StatusText?: Maybe<Scalars['String']>;
  WorkflowId: Scalars['String'];
  WorkflowStatus: Scalars['String'];
};

export type SupplierChangeDocument = {
  __typename?: 'SupplierChangeDocument';
  url: Scalars['String'];
};

export type SupplierChangeWorkflowResult = ChildWorkflowResult | ContractUpdateResult | DocumentResult | NetworkOperatorResult;

export type SupplierChangeWorkflowState = {
  __typename?: 'SupplierChangeWorkflowState';
  contractId: Scalars['String'];
  done: Scalars['Boolean'];
  startedAt: Scalars['DateTime'];
  steps: Array<SupplierChangeWorkflowStep>;
  type: SupplierChangeWorkflowType;
};

export type SupplierChangeWorkflowStep = {
  __typename?: 'SupplierChangeWorkflowStep';
  index: Scalars['Int'];
  result: SupplierChangeWorkflowResult;
  status: StepStatus;
};

export enum SupplierChangeWorkflowType {
  Cancellation = 'CANCELLATION',
  SupplierChangeLocalSupply = 'SUPPLIER_CHANGE_LOCAL_SUPPLY',
  SupplierChangeThirdParty = 'SUPPLIER_CHANGE_THIRD_PARTY'
}

export type SupplierNetworkOperatorInput = {
  bdewCode: Scalars['String'];
  companyName: Scalars['String'];
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type Tariff = {
  __typename?: 'Tariff';
  id: Scalars['String'];
  nameExternal: Scalars['String'];
  nameInternal: Scalars['String'];
  tax: Scalars['Boolean'];
  powerTax: Scalars['Boolean'];
  priceGuaranteeReference: Scalars['String'];
  priceGuarantee: Scalars['Int'];
  minimumDuration?: Maybe<Scalars['Int']>;
  minimumDurationExtension?: Maybe<Scalars['Int']>;
  noticePeriod: NoticePeriod;
  noticePeriodExtension?: Maybe<NoticePeriod>;
  validityStartDate: Scalars['DateTime'];
  validityEndDate: Scalars['DateTime'];
  priceSheets: Array<PriceSheet>;
  kind: Scalars['String'];
  assignedFrom?: Maybe<Scalars['DateTime']>;
  assignedTo?: Maybe<Scalars['DateTime']>;
};

export type TariffCreate = {
  nameExternal: Scalars['String'];
  nameInternal: Scalars['String'];
  kind: Scalars['String'];
  validityStartDate: Scalars['DateTime'];
  tax: Scalars['Boolean'];
  powerTax: Scalars['Boolean'];
  priceGuaranteeReference: Scalars['String'];
  noticePeriod: NoticePeriodInput;
  priceGuarantee?: Maybe<Scalars['Int']>;
  validityEndDate?: Maybe<Scalars['DateTime']>;
  priceSheet: PriceSheetCreate;
  minimumDuration: Scalars['Int'];
  minimumDurationExtension: Scalars['Int'];
  noticePeriodExtension: NoticePeriodInput;
};

export type TariffEdit = {
  nameExternal?: Maybe<Scalars['String']>;
  nameInternal?: Maybe<Scalars['String']>;
  validityEndDate?: Maybe<Scalars['DateTime']>;
};

export type TariffPriceSheet = {
  basicPrice: Scalars['Float'];
  considerPriceGurantee?: Maybe<Scalars['Boolean']>;
  endDate?: Maybe<Scalars['DateTime']>;
  energyPrice?: Maybe<Scalars['Float']>;
  energyPriceLocal?: Maybe<Scalars['Float']>;
  energyPriceResidual?: Maybe<Scalars['Float']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  startDate: Scalars['DateTime'];
};

export type TariffPricesheetDetails = {
  basicPrice: Scalars['Float'];
  energyPrice: Scalars['Float'];
};

export type TerminateContractResult = {
  __typename?: 'TerminateContractResult';
  contract: Contract;
  workflowId?: Maybe<Scalars['String']>;
};

export enum TransitionAction {
  Proceed = 'Proceed',
  Retry = 'Retry'
}

export type TransitionResponse = {
  __typename?: 'TransitionResponse';
  success: Scalars['Boolean'];
};

export type TransmissionSystemOperator = {
  __typename?: 'TransmissionSystemOperator';
  /** Übertragungsnetzbetreiber (Stammdaten) */
  id: Scalars['ID'];
  name: Scalars['String'];
  code: Scalars['String'];
  eic: Scalars['String'];
};

export type TwoFactorEmailFailed = {
  __typename?: 'TwoFactorEmailFailed';
  message: Scalars['String'];
};

export type TwoFactorRequired = {
  __typename?: 'TwoFactorRequired';
  email: Scalars['String'];
};

export type TwoFactorSessionExpired = {
  __typename?: 'TwoFactorSessionExpired';
  message: Scalars['String'];
};

export type TwoFactorSessionInvalid = {
  __typename?: 'TwoFactorSessionInvalid';
  message: Scalars['String'];
};

export type UnderlyingData = {
  commonPriceComponent: Array<CommonPriceComponent>;
  installationPriceComponent: Array<InstallationPriceComponent>;
};

export type UnknownError = Error & {
  __typename?: 'UnknownError';
  message: Scalars['String'];
  pointer?: Maybe<Array<Scalars['String']>>;
  retryable?: Maybe<Scalars['Boolean']>;
};

export type UnprocessedBooking = {
  __typename?: 'UnprocessedBooking';
  account: AccountType;
  name: Scalars['String'];
  ref: Scalars['String'];
  date: Scalars['String'];
  amount: Scalars['Float'];
  journal: Scalars['String'];
  sollstellungDate?: Maybe<Scalars['String']>;
  matchingNumber?: Maybe<Scalars['String']>;
};

export type UpdateAddressInput = {
  id?: Maybe<Scalars['ID']>;
  streetName?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['CountryScalar']>;
};

export type UpdateAnnualConsumptionInput = {
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  value: Scalars['Float'];
};

export type UpdateAuthResponse = SuccessResponse | ValidationErrors;

export type UpdateBalancingAreaAccount = {
  id: Scalars['ID'];
};

export type UpdateBalancingAreaGroupAccount = {
  id: Scalars['ID'];
};

export type UpdateBankAccountInput = {
  name?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  bic?: Maybe<Scalars['String']>;
  mandateReference?: Maybe<Scalars['String']>;
  signedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateCancellationWorkflowInput = {
  clientId: Scalars['String'];
  workspace: Scalars['String'];
};

/** All input for the `updateCardByNodeId` mutation. */
export type UpdateCardByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Card` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Card` being updated. */
  patch: CardPatch;
};

/** All input for the `updateCard` mutation. */
export type UpdateCardInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Identifies a card */
  id: Scalars['BigInt'];
  /** An object where the defined keys will be set on the `Card` being updated. */
  patch: CardPatch;
};

/** The output of our update `Card` mutation. */
export type UpdateCardPayload = {
  __typename?: 'UpdateCardPayload';
  /** The `Card` that was updated by this mutation. */
  card?: Maybe<Card>;
  /** An edge for our `Card`. May be used by Relay 1. */
  cardEdge?: Maybe<CardsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `Card` mutation. */
export type UpdateCardPayloadCardEdgeArgs = {
  orderBy?: Maybe<Array<CardsOrderBy>>;
};

export type UpdateContractComponentInput = {
  tariffAssignmentValidityStartDate?: Maybe<Scalars['DateTime']>;
  tariff?: Maybe<UpdateTariff>;
  meterReading?: Maybe<UpdateMeterReadingInput>;
};

export type UpdateContractDraftTemplates = {
  pricesheet_letter?: Maybe<Scalars['Boolean']>;
  withdrawal_letter?: Maybe<Scalars['Boolean']>;
  poa_cancellation_letter?: Maybe<Scalars['Boolean']>;
  covering_letter?: Maybe<Scalars['Boolean']>;
};

export type UpdateContractImportResponse = UpdateContractsImportSuccess | ScheduleRequestsError | UnknownError;

export type UpdateContractInput = {
  contractMeter?: Maybe<UpdateContractMeterInput>;
  downPayment?: Maybe<Scalars['Float']>;
  downPaymentStartDate?: Maybe<Scalars['DateTime']>;
  customer?: Maybe<UpdateCustomerInput>;
  paymentDate?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  workspace?: Maybe<SetWorkspaceInput>;
};

export type UpdateContractMeterChangeInput = {
  meter: UpdateMeterInput;
  meterReading: UpdateMeterReadingInput;
  oldMeterReading: UpdateMeterReadingInput;
  validityStartDate?: Maybe<Scalars['DateTime']>;
  validityEndDate?: Maybe<Scalars['DateTime']>;
};

export type UpdateContractMeterChangeResult = {
  __typename?: 'UpdateContractMeterChangeResult';
  success: Scalars['Boolean'];
};

export type UpdateContractMeterInput = {
  contractComponent?: Maybe<UpdateContractComponentInput>;
  meter?: Maybe<UpdateMeterInput>;
};

export type UpdateContractResponse = Contract | IbanExistsError | ValidationErrors;

export type UpdateContractsBatchedBankAccount = {
  __typename?: 'UpdateContractsBatchedBankAccount';
  name?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  bic?: Maybe<Scalars['String']>;
  mandateReference?: Maybe<Scalars['String']>;
  signedAt?: Maybe<Scalars['DateTime']>;
  isValid?: Maybe<Scalars['Boolean']>;
};

export type UpdateContractsBatchedCustomer = {
  __typename?: 'UpdateContractsBatchedCustomer';
  person?: Maybe<UpdateContractsBatchedPerson>;
  address?: Maybe<Address>;
  addressBilling?: Maybe<OdooAddress>;
  addressShipping?: Maybe<UpdateContractsBatchedShippingAddress>;
  bankAccount?: Maybe<UpdateContractsBatchedBankAccount>;
  hasSepa?: Maybe<Scalars['Boolean']>;
  documentDeliveryMethod?: Maybe<Scalars['String']>;
  advertisementAccepted?: Maybe<Scalars['Boolean']>;
};

export type UpdateContractsBatchedInput = {
  contractId: Scalars['ID'];
  contractLabel: Scalars['String'];
  customerLabel: Scalars['String'];
  contract: UpdateContractInput;
};

export type UpdateContractsBatchedPayload = {
  contracts: Array<UpdateContractsBatchedInput>;
};

export type UpdateContractsBatchedPerson = {
  __typename?: 'UpdateContractsBatchedPerson';
  namePrefix?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  contactPhone?: Maybe<Scalars['String']>;
  contactMobile?: Maybe<Scalars['String']>;
  companyFlag?: Maybe<Scalars['Boolean']>;
  co?: Maybe<Scalars['String']>;
};

export type UpdateContractsBatchedResult = {
  id: Scalars['String'];
  jobId?: Maybe<Scalars['String']>;
  data: Scalars['JSON'];
};

export type UpdateContractsBatchedShippingAddress = {
  __typename?: 'UpdateContractsBatchedShippingAddress';
  namePrefix?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  streetName?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['CountryScalar']>;
  companyFlag?: Maybe<Scalars['Boolean']>;
  co?: Maybe<Scalars['String']>;
};

export type UpdateContractsBatchedValidationError = {
  __typename?: 'UpdateContractsBatchedValidationError';
  contractLabel: Scalars['String'];
  message: Scalars['String'];
  error: Scalars['String'];
};

export type UpdateContractsBatchedValidationSuccess = {
  __typename?: 'UpdateContractsBatchedValidationSuccess';
  contractId: Scalars['ID'];
  contractLabel: Scalars['String'];
  customerLabel: Scalars['String'];
  contract: UpdateContractsContractData;
};

export type UpdateContractsContractData = {
  __typename?: 'UpdateContractsContractData';
  customer?: Maybe<UpdateContractsBatchedCustomer>;
  status?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  downPayment?: Maybe<Scalars['Float']>;
  downPaymentStartDate?: Maybe<Scalars['DateTime']>;
};

export type UpdateContractsImportSuccess = {
  __typename?: 'UpdateContractsImportSuccess';
  id: Scalars['ID'];
  customerLabel: Scalars['String'];
  contractLabel: Scalars['String'];
};

export type UpdateContractStatusInput = {
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  workspace?: Maybe<SetWorkspaceInput>;
};

/** All input for the `updateCrminstanceByNodeId` mutation. */
export type UpdateCrminstanceByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Crminstance` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Crminstance` being updated. */
  patch: CrminstancePatch;
};

/** All input for the `updateCrminstance` mutation. */
export type UpdateCrminstanceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['BigInt'];
  /** An object where the defined keys will be set on the `Crminstance` being updated. */
  patch: CrminstancePatch;
};

/** The output of our update `Crminstance` mutation. */
export type UpdateCrminstancePayload = {
  __typename?: 'UpdateCrminstancePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Crminstance` that was updated by this mutation. */
  crminstance?: Maybe<Crminstance>;
  /** An edge for our `Crminstance`. May be used by Relay 1. */
  crminstanceEdge?: Maybe<CrminstancesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `Crminstance` mutation. */
export type UpdateCrminstancePayloadCrminstanceEdgeArgs = {
  orderBy?: Maybe<Array<CrminstancesOrderBy>>;
};

export type UpdateCustomerInput = {
  person?: Maybe<UpdatePersonInput>;
  address?: Maybe<UpdateAddressInput>;
  addressBilling?: Maybe<UpdateOdooAddressInput>;
  addressShipping?: Maybe<UpdateOdooAddressInput>;
  birthday?: Maybe<Scalars['DateTime']>;
  bankAccount?: Maybe<UpdateBankAccountInput>;
  payer?: Maybe<UpdatePayerInput>;
  hasSepa?: Maybe<Scalars['Boolean']>;
  documentDeliveryMethod?: Maybe<Scalars['String']>;
  advertisementAccepted?: Maybe<Scalars['Boolean']>;
};

export type UpdateExistingCustomer = {
  id?: Maybe<Scalars['Int']>;
};

/** All input for the `updateLaneByNodeId` mutation. */
export type UpdateLaneByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Lane` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Lane` being updated. */
  patch: LanePatch;
};

/** All input for the `updateLaneCardRelByNodeId` mutation. */
export type UpdateLaneCardRelByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `LaneCardRel` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `LaneCardRel` being updated. */
  patch: LaneCardRelPatch;
};

/** All input for the `updateLaneCardRel` mutation. */
export type UpdateLaneCardRelInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  idCard: Scalars['BigInt'];
  /** An object where the defined keys will be set on the `LaneCardRel` being updated. */
  patch: LaneCardRelPatch;
};

/** The output of our update `LaneCardRel` mutation. */
export type UpdateLaneCardRelPayload = {
  __typename?: 'UpdateLaneCardRelPayload';
  /** Reads a single `Card` that is related to this `LaneCardRel`. */
  cardByIdCard?: Maybe<Card>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Lane` that is related to this `LaneCardRel`. */
  laneByIdLanes?: Maybe<Lane>;
  /** The `LaneCardRel` that was updated by this mutation. */
  laneCardRel?: Maybe<LaneCardRel>;
  /** An edge for our `LaneCardRel`. May be used by Relay 1. */
  laneCardRelEdge?: Maybe<LaneCardRelsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `LaneCardRel` mutation. */
export type UpdateLaneCardRelPayloadLaneCardRelEdgeArgs = {
  orderBy?: Maybe<Array<LaneCardRelsOrderBy>>;
};

/** All input for the `updateLane` mutation. */
export type UpdateLaneInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['BigInt'];
  /** An object where the defined keys will be set on the `Lane` being updated. */
  patch: LanePatch;
};

/** The output of our update `Lane` mutation. */
export type UpdateLanePayload = {
  __typename?: 'UpdateLanePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Lane` that was updated by this mutation. */
  lane?: Maybe<Lane>;
  /** An edge for our `Lane`. May be used by Relay 1. */
  laneEdge?: Maybe<LanesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `Lane` mutation. */
export type UpdateLanePayloadLaneEdgeArgs = {
  orderBy?: Maybe<Array<LanesOrderBy>>;
};

export type UpdateLoadProfile = {
  id?: Maybe<Scalars['Int']>;
};

export type UpdateMeteringPointOperator = {
  id: Scalars['ID'];
};

export type UpdateMeterInput = {
  id?: Maybe<Scalars['Int']>;
  meterNumber?: Maybe<Scalars['String']>;
  meterPlace?: Maybe<Scalars['String']>;
  meterType?: Maybe<Scalars['String']>;
  converterFactor?: Maybe<Scalars['Float']>;
  oldMelo?: Maybe<Scalars['String']>;
  melo?: Maybe<Scalars['String']>;
  oldMalo?: Maybe<Scalars['String']>;
  maloId?: Maybe<Scalars['String']>;
  metering?: Maybe<Scalars['String']>;
};

export type UpdateMeterReadingInput = {
  value?: Maybe<Scalars['Float']>;
  valueStatus?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
};

export type UpdateMkvTemplateResponse = MkvTemplateData | ValidationErrors;

export type UpdateOdooAddressInput = {
  partnerId?: Maybe<Scalars['ID']>;
  additionalPartnerId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  additionalName?: Maybe<Scalars['String']>;
  additionalNamePrefix?: Maybe<Scalars['String']>;
  companySecondaryFlag?: Maybe<Scalars['Boolean']>;
  streetName?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['CountryScalar']>;
  companyFlag?: Maybe<Scalars['Boolean']>;
  co?: Maybe<Scalars['String']>;
};

export type UpdatePasswordEmailFailed = {
  __typename?: 'UpdatePasswordEmailFailed';
  message: Scalars['String'];
};

export type UpdatePasswordResponse = UpdatePasswordSuccess | WrongCredentialsError | EmailNotFound | UpdatePasswordEmailFailed | ServiceNotReachableError | UnknownError;

export type UpdatePasswordSuccess = {
  __typename?: 'UpdatePasswordSuccess';
  success: Scalars['Boolean'];
  message: Scalars['String'];
};

export type UpdatePayerInput = {
  accountHolderId: Scalars['ID'];
  name: Scalars['String'];
  number: Scalars['String'];
  iban: Scalars['String'];
  bic: Scalars['String'];
};

export type UpdatePersonInput = {
  namePrefix?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  contactPhone?: Maybe<Scalars['String']>;
  contactMobile?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  companyFlag?: Maybe<Scalars['Boolean']>;
  co?: Maybe<Scalars['String']>;
};

export type UpdatePlantInput = {
  name?: Maybe<Scalars['String']>;
  kpis?: Maybe<UpdatePlantKpi>;
  addresses?: Maybe<Array<UpdateAddressInput>>;
};

export type UpdatePlantKpi = {
  units?: Maybe<Scalars['Int']>;
};

export type UpdateProjectInput = {
  id: Scalars['Int'];
};

export type UpdateReductionsInput = {
  downPayment?: Maybe<Scalars['Float']>;
  downPaymentStartDate?: Maybe<Scalars['DateTime']>;
};

export type UpdateSupplier = {
  id: Scalars['ID'];
};

export type UpdateSupplierChangeContractInput = {
  date: Scalars['String'];
  malo?: Maybe<Scalars['String']>;
  melo?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

export type UpdateSupplierChangeLocalContractInput = {
  customerLabel: Scalars['String'];
  date: Scalars['String'];
  reductionStartDate: Scalars['String'];
  reductionValue: Scalars['Float'];
  status: Scalars['String'];
};

export type UpdateTariff = {
  id?: Maybe<Scalars['Int']>;
};

export type UploadDocumentInfo = {
  __typename?: 'UploadDocumentInfo';
  hash: Scalars['String'];
  token: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  name: Scalars['String'];
  email: Scalars['String'];
  company: Company;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  roles: Array<Role>;
};

export type ValidateContractConsumptionsStepResult = {
  __typename?: 'ValidateContractConsumptionsStepResult';
  contracts?: Maybe<Array<AccountingRunContractConsumptionContract>>;
};

export type ValidationError = {
  __typename?: 'ValidationError';
  message: Scalars['String'];
  pointer: Scalars['String'];
  retryable?: Maybe<Scalars['Boolean']>;
};

export type ValidationErrors = {
  __typename?: 'ValidationErrors';
  errors: Array<ValidationError>;
};

export type Workflow = {
  __typename?: 'Workflow';
  alias?: Maybe<Scalars['String']>;
  clientId: Scalars['String'];
  companyId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  currentState: State;
  done: Scalars['Boolean'];
  doneAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  meta: Scalars['JSON'];
  stateNames: Array<Scalars['String']>;
  type: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type WorkflowChange = {
  __typename?: 'WorkflowChange';
  workflowId?: Maybe<Scalars['String']>;
};

export type WorkflowResponse = {
  __typename?: 'WorkflowResponse';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type WorkflowSuccessResponse = {
  __typename?: 'WorkflowSuccessResponse';
  success: Scalars['Boolean'];
};

export enum WorkflowType {
  Multiple = 'multiple',
  Single = 'single'
}

export type Workspace = {
  __typename?: 'Workspace';
  id: Scalars['ID'];
  name: Scalars['String'];
  code: Scalars['String'];
};

export type WrongCredentialsError = {
  __typename?: 'WrongCredentialsError';
  message: Scalars['String'];
};

export type WrongPasswordResetFlow = {
  __typename?: 'WrongPasswordResetFlow';
  message: Scalars['String'];
};

export type ZipDocumentsRequestSuccess = {
  __typename?: 'zipDocumentsRequestSuccess';
  results: Array<UploadDocumentInfo>;
};

export type ZipDocumentsResult = ScheduleRequestsError | ZipDocumentsRequestSuccess;

export type OverviewStepContractsFragment = (
  { __typename: 'AccountingRunValidatedContractMeter' }
  & Pick<AccountingRunValidatedContractMeter, 'name' | 'id' | 'customerLabel' | 'contractLabel' | 'type' | 'plantId' | 'projectId'>
  & { meters?: Maybe<Array<(
    { __typename?: 'AccountingRunContractMeter' }
    & Pick<AccountingRunContractMeter, 'meterId' | 'meterNumber'>
    & { errors: Array<(
      { __typename?: 'AccountingRunContractMeterError' }
      & Pick<AccountingRunContractMeterError, 'reason' | 'date'>
    )> }
  )>> }
);

export type ValidatedConsumptionContractsFragment = (
  { __typename: 'AccountingRunValidatedConsumption' }
  & Pick<AccountingRunValidatedConsumption, 'name' | 'id' | 'customerLabel' | 'contractLabel' | 'plantId' | 'projectId' | 'ajv' | 'relativeAjv' | 'newConsumption' | 'relativeVariance' | 'error'>
);

export type GetAccountingRunWorkflowStateQueryVariables = Exact<{
  workflowId: Scalars['String'];
}>;


export type GetAccountingRunWorkflowStateQuery = (
  { __typename?: 'Query' }
  & { getAccountingRunWorkflowState: (
    { __typename?: 'AccountingRunWorkflowState' }
    & Pick<AccountingRunWorkflowState, 'done' | 'name' | 'type' | 'startedAt' | 'doneAt'>
    & { meta: (
      { __typename?: 'AccountingRunMeta' }
      & Pick<AccountingRunMeta, 'accountingType' | 'paymentPeriodEndAt' | 'paymentPeriodStartAt' | 'settlementDay' | 'settlementMonth' | 'invoiceCycle'>
    ), steps: Array<(
      { __typename?: 'AccountingRunWorkflowStep' }
      & Pick<AccountingRunWorkflowStep, 'index' | 'name' | 'action' | 'status' | 'active'>
      & { error?: Maybe<(
        { __typename: 'AccountingRunWorkflowStepError' }
        & Pick<AccountingRunWorkflowStepError, 'activityType' | 'message'>
      )>, result?: Maybe<(
        { __typename: 'AutarkyStepResult' }
        & Pick<AutarkyStepResult, 'workflowType'>
        & { plants?: Maybe<Array<Maybe<(
          { __typename: 'AccountingRunAutarkySuggestion' }
          & Pick<AccountingRunAutarkySuggestion, 'plantId' | 'plantName' | 'projectId' | 'plantFullSupply' | 'autarky' | 'errorReason' | 'calculatedAt' | 'validityStartAt' | 'validityEndAt' | 'unroundedAutarky' | 'warning' | 'meterId'>
        )>>>, userInput?: Maybe<Array<(
          { __typename: 'AccountingRunUserInputAutarky' }
          & Pick<AccountingRunUserInputAutarky, 'autarky' | 'plantId'>
        )>> }
      ) | (
        { __typename: 'DownpaymentStartStepResult' }
        & Pick<DownpaymentStartStepResult, 'downpaymentStart'>
      ) | (
        { __typename: 'OpcStepResult' }
        & Pick<OpcStepResult, 'name'>
        & { passed?: Maybe<Array<(
          { __typename?: 'OpcStepResultInfo' }
          & Pick<OpcStepResultInfo, 'id'>
        )>>, errored?: Maybe<Array<(
          { __typename?: 'OpcStepResultInfo' }
          & Pick<OpcStepResultInfo, 'id' | 'error'>
        )>> }
      ) | (
        { __typename: 'OverviewStepResult' }
        & Pick<OverviewStepResult, 'session' | 'hasSearchableLabels'>
        & { validContracts: Array<(
          { __typename?: 'AccountingRunValidatedContractMeter' }
          & OverviewStepContractsFragment
        )>, invalidContracts: Array<(
          { __typename?: 'AccountingRunValidatedContractMeter' }
          & OverviewStepContractsFragment
        )>, consumptionValuesWarnings?: Maybe<Array<(
          { __typename?: 'AccountingRunValidatedConsumption' }
          & ValidatedConsumptionContractsFragment
        )>>, additionalErrors: Array<Maybe<(
          { __typename: 'AccountingRunValidationError' }
          & Pick<AccountingRunValidationError, 'type' | 'sourceId' | 'contractId' | 'errorString'>
        )>> }
      ) | (
        { __typename: 'ValidateContractConsumptionsStepResult' }
        & { contracts?: Maybe<Array<(
          { __typename: 'AccountingRunContractConsumptionContract' }
          & Pick<AccountingRunContractConsumptionContract, 'name' | 'id' | 'customerLabel' | 'contractLabel' | 'type' | 'plantId' | 'projectId' | 'errors'>
        )>> }
      )> }
    )> }
  ) }
);

export type GetAccountingRunWorkflowsQueryVariables = Exact<{
  nextPageToken?: Maybe<Scalars['String']>;
  searchAttributes?: Maybe<AccountingRunSearchAttributes>;
  sorting?: Maybe<AccountingRunSearchSorting>;
  pageSize?: Maybe<Scalars['Int']>;
}>;


export type GetAccountingRunWorkflowsQuery = (
  { __typename?: 'Query' }
  & { getAccountingRunWorkflows: (
    { __typename?: 'GetAccountingRunWorkflowsResponse' }
    & Pick<GetAccountingRunWorkflowsResponse, 'totalCount' | 'nextPageToken'>
    & { data: Array<(
      { __typename?: 'AccountingRunWorkflowsData' }
      & Pick<AccountingRunWorkflowsData, 'WorkflowId' | 'AccountingRunName' | 'AccountingRunStartTime' | 'AccountingRunPeriodStart' | 'AccountingRunPeriodEnd' | 'AccountingRunSettlementDay' | 'AccountingRunStep' | 'AccountingRunStepState' | 'AccountingRunState' | 'AccountingRunSessionId' | 'AccountingRunDownpaymentStartDate' | 'AccountingRunOpcName' | 'AccountingRunEndTime'>
    )> }
  ) }
);

export type GetAccountingRunWorkflowsByUuidsQueryVariables = Exact<{
  uuids: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetAccountingRunWorkflowsByUuidsQuery = (
  { __typename?: 'Query' }
  & { getAccountingRunWorkflowsByUuids: (
    { __typename?: 'GetAccountingRunWorkflowsResponse' }
    & Pick<GetAccountingRunWorkflowsResponse, 'totalCount'>
    & { data: Array<(
      { __typename?: 'AccountingRunWorkflowsData' }
      & Pick<AccountingRunWorkflowsData, 'WorkflowId' | 'AccountingRunName' | 'AccountingRunStartTime' | 'AccountingRunPeriodStart' | 'AccountingRunPeriodEnd' | 'AccountingRunSettlementDay' | 'AccountingRunStep' | 'AccountingRunStepState' | 'AccountingRunState' | 'AccountingRunSessionId' | 'AccountingRunDownpaymentStartDate' | 'AccountingRunOpcName' | 'AccountingRunEndTime'>
    )> }
  ) }
);

export type TransitionAccountingRunWorkflowMutationVariables = Exact<{
  workflowId: Scalars['String'];
  action: TransitionAction;
}>;


export type TransitionAccountingRunWorkflowMutation = (
  { __typename?: 'Mutation' }
  & { transitionAccountingRunWorkflow: (
    { __typename?: 'TransitionResponse' }
    & Pick<TransitionResponse, 'success'>
  ) }
);

export type SetAccountingRunPlantAutarkyMutationVariables = Exact<{
  workflowId: Scalars['String'];
  payload: SetPlantAutarkyPayload;
}>;


export type SetAccountingRunPlantAutarkyMutation = (
  { __typename?: 'Mutation' }
  & { setAccountingRunPlantAutarky: (
    { __typename?: 'TransitionResponse' }
    & Pick<TransitionResponse, 'success'>
  ) }
);

export type SetAccountingRunDownpaymentStartMutationVariables = Exact<{
  workflowId: Scalars['String'];
  downpaymentStartDate: Scalars['Date'];
}>;


export type SetAccountingRunDownpaymentStartMutation = (
  { __typename?: 'Mutation' }
  & { setAccountingRunDownpaymentStart: (
    { __typename?: 'TransitionResponse' }
    & Pick<TransitionResponse, 'success'>
  ) }
);

export type DeleteAccountingRunWorkflowMutationVariables = Exact<{
  workflowId: Scalars['String'];
}>;


export type DeleteAccountingRunWorkflowMutation = (
  { __typename?: 'Mutation' }
  & { deleteAccountingRunWorkflow: (
    { __typename?: 'AccountingRunActionResultSuccess' }
    & Pick<AccountingRunActionResultSuccess, 'success'>
  ) }
);

export type RestartAccountingRunWorkflowMutationVariables = Exact<{
  workflowId: Scalars['String'];
}>;


export type RestartAccountingRunWorkflowMutation = (
  { __typename?: 'Mutation' }
  & { restartAccountingRunWorkflow: (
    { __typename?: 'AccountingRunActionResultSuccess' }
    & Pick<AccountingRunActionResultSuccess, 'success'>
  ) }
);

export type AcceptInvoiceDraftMutationVariables = Exact<{
  invoiceDraftsTuple: Array<InvoiceDraftTupleInput> | InvoiceDraftTupleInput;
  uuid: Scalars['ID'];
  accountingType: Scalars['String'];
  accountingName: Scalars['String'];
  invoiceCycle: InvoiceCycle;
}>;


export type AcceptInvoiceDraftMutation = (
  { __typename?: 'Mutation' }
  & { acceptInvoiceDraft?: Maybe<(
    { __typename?: 'AcceptInvoiceDraftResponse' }
    & Pick<AcceptInvoiceDraftResponse, 'jobId' | 'jobName'>
  )> }
);

export type AccountingsInTimespanQueryVariables = Exact<{
  plantIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  contractIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
}>;


export type AccountingsInTimespanQuery = (
  { __typename?: 'Query' }
  & { accountingsInTimespan: Array<Maybe<(
    { __typename?: 'AccountingStatusForContract' }
    & Pick<AccountingStatusForContract, 'contractId' | 'status' | 'accFlowUUID'>
  )>> }
);

export type AssignCardToLaneMutationVariables = Exact<{
  input: CreateLaneCardRelInput;
}>;


export type AssignCardToLaneMutation = (
  { __typename?: 'Mutation' }
  & { createLaneCardRel?: Maybe<(
    { __typename?: 'CreateLaneCardRelPayload' }
    & Pick<CreateLaneCardRelPayload, 'clientMutationId'>
  )> }
);

export type CancelInvoiceMutationVariables = Exact<{
  contractNumber: Scalars['String'];
  contractId: Scalars['String'];
  bookingName: Scalars['String'];
  opcName: Scalars['String'];
  isPaid: Scalars['Boolean'];
}>;


export type CancelInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { cancelInvoice: (
    { __typename: 'CancelInvoiceResponse' }
    & Pick<CancelInvoiceResponse, 'contractNumber' | 'bookingName' | 'opcName' | 'reversalName'>
  ) }
);

export type Check2FAuthMutationVariables = Exact<{
  id: Scalars['String'];
  token: Scalars['String'];
}>;


export type Check2FAuthMutation = (
  { __typename?: 'Mutation' }
  & { check2FAuth?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'secondaryColor' | 'primaryColor' | 'logo'>
    ) }
  ) | (
    { __typename: 'TwoFactorSessionInvalid' }
    & Pick<TwoFactorSessionInvalid, 'message'>
  ) | (
    { __typename: 'TwoFactorSessionExpired' }
    & Pick<TwoFactorSessionExpired, 'message'>
  ) | (
    { __typename: 'ServiceNotReachableError' }
    & Pick<ServiceNotReachableError, 'message'>
  ) | (
    { __typename: 'UnknownError' }
    & Pick<UnknownError, 'message'>
  )> }
);

export type CheckDeletePriceSheetQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CheckDeletePriceSheetQuery = (
  { __typename?: 'Query' }
  & { checkDeletePriceSheet?: Maybe<(
    { __typename?: 'CheckDeletePriceSheetResult' }
    & Pick<CheckDeletePriceSheetResult, 'success'>
  )> }
);

export type CheckResetPasswordTokenMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type CheckResetPasswordTokenMutation = (
  { __typename?: 'Mutation' }
  & { checkResetPasswordToken: (
    { __typename: 'CheckResetPasswordTokenSuccess' }
    & Pick<CheckResetPasswordTokenSuccess, 'success' | 'message'>
  ) | (
    { __typename: 'WrongPasswordResetFlow' }
    & Pick<WrongPasswordResetFlow, 'message'>
  ) | (
    { __typename: 'NoPasswordResetSessionFound' }
    & Pick<NoPasswordResetSessionFound, 'message'>
  ) | { __typename: 'ServiceNotReachableError' } | (
    { __typename: 'UnknownError' }
    & Pick<UnknownError, 'message'>
  ) }
);

export type CreateCardMutationVariables = Exact<{
  input: CreateCardInput;
}>;


export type CreateCardMutation = (
  { __typename?: 'Mutation' }
  & { createCard?: Maybe<(
    { __typename?: 'CreateCardPayload' }
    & { card?: Maybe<(
      { __typename?: 'Card' }
      & Pick<Card, 'id' | 'ajv' | 'reductionstartdate' | 'reduction' | 'city' | 'country' | 'createdat' | 'creator' | 'email' | 'isperson' | 'prefix' | 'firstname' | 'lastname' | 'contractstartdate' | 'contractenddate' | 'companyname' | 'co' | 'phone' | 'state' | 'street' | 'streetnr' | 'plantid' | 'workspaceid' | 'tariffid' | 'meterid' | 'notes' | 'shippingaddressstreet' | 'shippingaddressstreetnr' | 'shippingaddresszipcode' | 'shippingaddresscity' | 'leadorigin'>
    )> }
  )> }
);

export type CreateAccountingRunWorkflowMutationVariables = Exact<{
  payload: AccountingWorkflowPayload;
}>;


export type CreateAccountingRunWorkflowMutation = (
  { __typename?: 'Mutation' }
  & { createAccountingRunWorkflow: (
    { __typename?: 'CreateAccountingRunResult' }
    & Pick<CreateAccountingRunResult, 'id'>
  ) }
);

export type CreateBookingsBatchedMutationVariables = Exact<{
  bookings: Array<CreateBookingsBatchedInput> | CreateBookingsBatchedInput;
  fileName?: Maybe<Scalars['String']>;
}>;


export type CreateBookingsBatchedMutation = (
  { __typename?: 'Mutation' }
  & { createBookingsBatched: (
    { __typename: 'CreateBookingsBatchedSuccess' }
    & { succeeded: Array<(
      { __typename?: 'ContractVoucherBatchedResponse' }
      & Pick<ContractVoucherBatchedResponse, 'contractId' | 'date' | 'value' | 'type'>
    )>, info?: Maybe<Array<(
      { __typename?: 'InfoContractVoucherBatched' }
      & Pick<InfoContractVoucherBatched, 'contractLabel' | 'bookingDate' | 'contractEndDate' | 'contractStartDate'>
    )>> }
  ) | (
    { __typename: 'CreateBookingsBatchedError' }
    & { failed: Array<(
      { __typename?: 'CreateBookingsBatchedValidatonError' }
      & Pick<CreateBookingsBatchedValidatonError, 'error' | 'message' | 'contractLabel'>
    )>, info?: Maybe<Array<(
      { __typename?: 'InfoContractVoucherBatched' }
      & Pick<InfoContractVoucherBatched, 'contractLabel' | 'bookingDate' | 'contractEndDate' | 'contractStartDate'>
    )>> }
  ) }
);

export type CreateClientReportMutationVariables = Exact<{
  salesStartDate: Scalars['DateTime'];
  salesEndDate: Scalars['DateTime'];
  accountingPeriodStartDate: Scalars['DateTime'];
  accountingPeriodEndDate: Scalars['DateTime'];
}>;


export type CreateClientReportMutation = (
  { __typename?: 'Mutation' }
  & { createClientReport: (
    { __typename?: 'CreateClientReportSuccess' }
    & Pick<CreateClientReportSuccess, 'success'>
  ) | (
    { __typename?: 'RateLimitReached' }
    & Pick<RateLimitReached, 'message'>
  ) | (
    { __typename?: 'UnknownError' }
    & Pick<UnknownError, 'message'>
  ) }
);

export type CreateCommunicationMutationVariables = Exact<{
  contractId: Scalars['ID'];
  communication: CustomerCommunicationInput;
}>;


export type CreateCommunicationMutation = (
  { __typename?: 'Mutation' }
  & { createCommunication?: Maybe<(
    { __typename?: 'CustomerCommunication' }
    & Pick<CustomerCommunication, 'id' | 'message' | 'date' | 'title' | 'communicatedBy'>
  )> }
);

export type CreateContractsBatchedMutationVariables = Exact<{
  contracts: Array<CreateContractsBatchedInput> | CreateContractsBatchedInput;
}>;


export type CreateContractsBatchedMutation = (
  { __typename?: 'Mutation' }
  & { createContractsBatched: (
    { __typename: 'ContractsBatchedSuccess' }
    & Pick<ContractsBatchedSuccess, 'jobId' | 'jobName' | 'status' | 'progress' | 'total'>
  ) | (
    { __typename: 'CreateContractsBatchedError' }
    & Pick<CreateContractsBatchedError, 'message'>
    & { failedContracts: Array<(
      { __typename?: 'CreateContractsValidationError' }
      & Pick<CreateContractsValidationError, 'customerLabel' | 'contractLabel' | 'message' | 'error'>
    )> }
  ) }
);

export type CreateETaxReportMutationVariables = Exact<{
  year: Scalars['Int'];
  fallbackAutarky: Scalars['Float'];
}>;


export type CreateETaxReportMutation = (
  { __typename?: 'Mutation' }
  & { createETaxReport: (
    { __typename: 'CreateETaxReportSuccess' }
    & Pick<CreateETaxReportSuccess, 'success'>
  ) | (
    { __typename: 'RateLimitReached' }
    & Pick<RateLimitReached, 'message'>
  ) | (
    { __typename: 'UnknownError' }
    & Pick<UnknownError, 'message'>
  ) }
);

export type CreateMeasurementContractSessionMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateMeasurementContractSessionMutation = (
  { __typename?: 'Mutation' }
  & { session: Mutation['createMeasurementContractSession'] }
);

export type CreateMeterReadingMutationVariables = Exact<{
  meterId: Scalars['ID'];
  meterReading: CreateMeterReadingInput;
}>;


export type CreateMeterReadingMutation = (
  { __typename?: 'Mutation' }
  & { createMeterReading: (
    { __typename?: 'MeterReading' }
    & Pick<MeterReading, 'date' | 'value' | 'reason' | 'hint' | 'valueStatus' | 'obis'>
  ) }
);

export type CreateMeterReadingsBatchedMutationVariables = Exact<{
  meterReadings: Array<CreateMeterReadingsBatchedInput> | CreateMeterReadingsBatchedInput;
}>;


export type CreateMeterReadingsBatchedMutation = (
  { __typename?: 'Mutation' }
  & { createMeterReadingsBatched?: Maybe<(
    { __typename: 'CreateMeterReadingsBatchedMixedResult' }
    & { succeeded: Array<Maybe<(
      { __typename?: 'CreateMeterReadingsBatchedSuccess' }
      & Pick<CreateMeterReadingsBatchedSuccess, 'id' | 'meterNumber' | 'value' | 'date'>
    )>>, failed: Array<Maybe<(
      { __typename?: 'CreateMeterReadingsBatchedError' }
      & Pick<CreateMeterReadingsBatchedError, 'id' | 'meterNumber' | 'error' | 'value' | 'date'>
    )>> }
  ) | (
    { __typename: 'DuplicateMeterReadingsError' }
    & Pick<DuplicateMeterReadingsError, 'message' | 'pointer' | 'retryable'>
    & { duplicateReadings: Array<Maybe<(
      { __typename?: 'CreateMeterReadingsBatchedError' }
      & Pick<CreateMeterReadingsBatchedError, 'id' | 'meterNumber' | 'date' | 'value'>
    )>>, multipleReadings: Array<Maybe<(
      { __typename?: 'CreateMeterReadingsBatchedError' }
      & Pick<CreateMeterReadingsBatchedError, 'id' | 'meterNumber' | 'date' | 'value'>
    )>> }
  )> }
);

export type CreateMetersBatchedMutationVariables = Exact<{
  meters: Array<CreateMetersBatchedInput> | CreateMetersBatchedInput;
}>;


export type CreateMetersBatchedMutation = (
  { __typename?: 'Mutation' }
  & { createMetersBatched: (
    { __typename: 'CreateMetersBatchedResponse' }
    & { succeeded: Array<(
      { __typename?: 'CreateMetersBatchedSuccess' }
      & Pick<CreateMetersBatchedSuccess, 'meterNumber' | 'plantName'>
    )>, failed: Array<(
      { __typename?: 'CreateMetersBatchedError' }
      & Pick<CreateMetersBatchedError, 'error' | 'message' | 'meterNumber' | 'plantName'>
    )> }
  ) }
);

export type CreatePlantReportMutationVariables = Exact<{
  id: Scalars['ID'];
  salesStartDate: Scalars['DateTime'];
  salesEndDate: Scalars['DateTime'];
  accountingPeriodStartDate: Scalars['DateTime'];
  accountingPeriodEndDate: Scalars['DateTime'];
}>;


export type CreatePlantReportMutation = (
  { __typename?: 'Mutation' }
  & { createPlantReport: (
    { __typename?: 'CreatePlantReportSuccess' }
    & Pick<CreatePlantReportSuccess, 'success'>
  ) | (
    { __typename?: 'RateLimitReached' }
    & Pick<RateLimitReached, 'message'>
  ) | (
    { __typename?: 'UnknownError' }
    & Pick<UnknownError, 'message'>
  ) }
);

export type CreateReportMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateReportMutation = (
  { __typename?: 'Mutation' }
  & { createReport: (
    { __typename?: 'CreateReportSuccess' }
    & Pick<CreateReportSuccess, 'entityId' | 'documentName' | 'createdAt'>
  ) | (
    { __typename?: 'RateLimitReached' }
    & Pick<RateLimitReached, 'message'>
  ) | (
    { __typename?: 'WrongCredentialsError' }
    & Pick<WrongCredentialsError, 'message'>
  ) | (
    { __typename?: 'UnknownError' }
    & Pick<UnknownError, 'message'>
  ) }
);

export type CreateTariffsBatchedMutationVariables = Exact<{
  tariffs: Array<CreateTariffsBatchedInput> | CreateTariffsBatchedInput;
}>;


export type CreateTariffsBatchedMutation = (
  { __typename?: 'Mutation' }
  & { createTariffsBatched: (
    { __typename: 'CreateTariffsBatchedResponse' }
    & { succeeded: Array<(
      { __typename?: 'CreateTariffsBatchedSuccess' }
      & Pick<CreateTariffsBatchedSuccess, 'nameInternal' | 'info'>
    )>, failed: Array<(
      { __typename?: 'CreateTariffsBatchedError' }
      & Pick<CreateTariffsBatchedError, 'nameInternal' | 'error' | 'message'>
    )> }
  ) }
);

export type DeleteAccountingDraftConsumptionValuesMutationVariables = Exact<{
  contractLabels: Array<Scalars['String']> | Scalars['String'];
  validityStart: Scalars['String'];
  validityEnd: Scalars['String'];
}>;


export type DeleteAccountingDraftConsumptionValuesMutation = (
  { __typename?: 'Mutation' }
  & { deleteAccountingDraftConsumptionValues: Array<(
    { __typename: 'DeleteAccountingDraftConsumptionValuesSuccess' }
    & Pick<DeleteAccountingDraftConsumptionValuesSuccess, 'contractId'>
    & { deletionResult: Array<(
      { __typename?: 'DeleteAccountingDraftConsumptionValuesSuccessResult' }
      & Pick<DeleteAccountingDraftConsumptionValuesSuccessResult, 'success'>
    )> }
  ) | (
    { __typename: 'DeleteAccountingDraftConsumptionValuesError' }
    & Pick<DeleteAccountingDraftConsumptionValuesError, 'contractId'>
    & { error: (
      { __typename?: 'DeleteAccountingDraftConsumptionValuesErrorResult' }
      & Pick<DeleteAccountingDraftConsumptionValuesErrorResult, 'title'>
    ) }
  )> }
);

export type DeleteOldAccountingWorkflowMutationVariables = Exact<{
  workflowId: Scalars['ID'];
  uuid?: Maybe<Scalars['String']>;
}>;


export type DeleteOldAccountingWorkflowMutation = (
  { __typename?: 'Mutation' }
  & { deleteOldAccountingWorkflow?: Maybe<(
    { __typename?: 'UnknownError' }
    & Pick<UnknownError, 'message'>
  ) | (
    { __typename?: 'WorkflowResponse' }
    & Pick<WorkflowResponse, 'success' | 'message'>
  )> }
);

export type DeleteCrmCardMutationVariables = Exact<{
  cardId: Scalars['BigInt'];
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type DeleteCrmCardMutation = (
  { __typename?: 'Mutation' }
  & { deleteCardCustom?: Maybe<(
    { __typename?: 'DeleteCardCustomPayload' }
    & Pick<DeleteCardCustomPayload, 'boolean'>
  )> }
);

export type RemoveDocumentsMutationVariables = Exact<{
  hash: Scalars['String'];
}>;


export type RemoveDocumentsMutation = (
  { __typename?: 'Mutation' }
  & { removeDocuments: (
    { __typename: 'RemoveResponse' }
    & Pick<RemoveResponse, 'success'>
  ) }
);

export type DeleteMeterReadingMutationVariables = Exact<{
  obisChannel: Scalars['String'];
  meloID: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  reason?: Maybe<Scalars['String']>;
}>;


export type DeleteMeterReadingMutation = (
  { __typename?: 'Mutation' }
  & { deleteMeterReading: (
    { __typename?: 'SuccessResponse' }
    & Pick<SuccessResponse, 'success'>
  ) }
);

export type DeleteOdooContractMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteOdooContractMutation = (
  { __typename?: 'Mutation' }
  & { deleteOdooContract: (
    { __typename?: 'OdooContractPartnerAssignedToOtherContractError' }
    & Pick<OdooContractPartnerAssignedToOtherContractError, 'message'>
  ) | (
    { __typename?: 'OdooContractNotFoundError' }
    & Pick<OdooContractNotFoundError, 'message'>
  ) | (
    { __typename?: 'UnknownError' }
    & Pick<UnknownError, 'message'>
  ) | (
    { __typename?: 'SuccessResponse' }
    & Pick<SuccessResponse, 'success'>
  ) }
);

export type DeletePriceSheetMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeletePriceSheetMutation = (
  { __typename?: 'Mutation' }
  & { deletePriceSheet?: Maybe<(
    { __typename?: 'DeletePriceSheetSuccessResult' }
    & Pick<DeletePriceSheetSuccessResult, 'success'>
  )> }
);

export type ContractExcerptLabelFragment = (
  { __typename?: 'ContractExcerpt' }
  & Pick<ContractExcerpt, 'label'>
);

export type ContractMeterExcerptFragment = (
  { __typename?: 'ContractMeterExcerpt' }
  & Pick<ContractMeterExcerpt, 'id' | 'meterNumber' | 'meterPlace' | 'meterType' | 'msoType' | 'contractLabel' | 'customerLabel' | 'melo' | 'malo' | 'isOperator' | 'wasEverBound' | 'name'>
);

export type FullAddressFragment = (
  { __typename?: 'Address' }
  & Pick<Address, 'streetName' | 'streetNumber' | 'zipCode' | 'city' | 'country'>
);

export type FullPersonFragment = (
  { __typename?: 'Person' }
  & Pick<Person, 'name'>
);

export type FullTariffFragment = (
  { __typename?: 'Tariff' }
  & Pick<Tariff, 'nameInternal' | 'nameExternal' | 'tax' | 'priceGuarantee' | 'validityEndDate' | 'validityStartDate'>
  & { noticePeriod: (
    { __typename?: 'NoticePeriod' }
    & Pick<NoticePeriod, 'to' | 'period' | 'count'>
  ), noticePeriodExtension?: Maybe<(
    { __typename?: 'NoticePeriod' }
    & Pick<NoticePeriod, 'to' | 'period' | 'count'>
  )>, priceSheets: Array<(
    { __typename?: 'PriceSheet' }
    & Pick<PriceSheet, 'basicPrice' | 'energyPrice' | 'energyPriceLocal' | 'energyPriceResidual' | 'startDate'>
  )> }
);

export type FullWorkflowFragment = (
  { __typename?: 'Workflow' }
  & Pick<Workflow, 'id' | 'alias' | 'type' | 'stateNames' | 'done' | 'meta' | 'createdAt' | 'doneAt' | 'updatedAt'>
  & { currentState: (
    { __typename?: 'State' }
    & Pick<State, 'name' | 'parentName' | 'context' | 'transitions'>
  ) }
);

export type MeterInfoFragment = (
  { __typename?: 'Meter' }
  & Pick<Meter, 'id' | 'meterNumber' | 'meterPlace' | 'meterType' | 'melo' | 'inUse' | 'msoType'>
);

export type TariffNameInternalFragment = (
  { __typename?: 'Tariff' }
  & Pick<Tariff, 'nameInternal'>
);

export type GenerateContractDocumentMutationVariables = Exact<{
  contractId: Scalars['String'];
  type: Scalars['String'];
  deliveryPreference: DeliveryPreference;
  contractDocumentData?: Maybe<ContractDocumentData>;
  pricesheetChangeDocumentData?: Maybe<PricesheetChangeDocumentData>;
}>;


export type GenerateContractDocumentMutation = (
  { __typename?: 'Mutation' }
  & { generateContractDocument: { __typename?: 'DocumentErrorResponse' } | { __typename?: 'DocumentResponse' } | (
    { __typename?: 'SuccessResponse' }
    & Pick<SuccessResponse, 'success' | 'fileURL' | 'hash'>
  ) | (
    { __typename?: 'MultipleFilesSuccessResponse' }
    & Pick<MultipleFilesSuccessResponse, 'success'>
    & { results: Array<(
      { __typename?: 'PdfResult' }
      & Pick<PdfResult, 'url' | 'hash'>
    )> }
  ) | { __typename?: 'MissingAttributesInContractDocumentError' } | (
    { __typename?: 'UnknownError' }
    & Pick<UnknownError, 'message'>
  ) }
);

export type GenerateContractDocumentPreviewMutationVariables = Exact<{
  contractId: Scalars['String'];
  type: Scalars['String'];
  contractDocumentData?: Maybe<ContractDocumentData>;
  pricesheetChangeDocumentData?: Maybe<PricesheetChangeDocumentData>;
}>;


export type GenerateContractDocumentPreviewMutation = (
  { __typename?: 'Mutation' }
  & { generateContractDocumentPreview: (
    { __typename?: 'ContractDocumentPreview' }
    & Pick<ContractDocumentPreview, 'fileURL'>
  ) | (
    { __typename?: 'MissingAttributesInContractDocumentError' }
    & Pick<MissingAttributesInContractDocumentError, 'attributes'>
  ) | (
    { __typename?: 'UnknownError' }
    & Pick<UnknownError, 'message'>
  ) }
);

export type GenerateContractInvoiceDocumentPreviewMutationVariables = Exact<{
  contractLabel: Scalars['String'];
  opcName: Scalars['String'];
  workflowUuid: Scalars['String'];
  invoiceCycle: InvoiceCycle;
  invoiceId?: Maybe<Scalars['String']>;
}>;


export type GenerateContractInvoiceDocumentPreviewMutation = (
  { __typename?: 'Mutation' }
  & { generateContractInvoiceDocumentPreview: (
    { __typename?: 'ContractDocumentPreview' }
    & Pick<ContractDocumentPreview, 'fileURL'>
  ) | { __typename?: 'MissingAttributesInContractDocumentError' } | (
    { __typename?: 'UnknownError' }
    & Pick<UnknownError, 'message'>
  ) }
);

export type GenerateMultipleContractDocumentsMutationVariables = Exact<{
  payloads: Array<GenerateContractDocumentInput> | GenerateContractDocumentInput;
  deliveryPreference: DeliveryPreference;
}>;


export type GenerateMultipleContractDocumentsMutation = (
  { __typename?: 'Mutation' }
  & { generateMultipleContractDocuments: (
    { __typename?: 'GenerateMultipleDocumentsResult' }
    & Pick<GenerateMultipleDocumentsResult, 'jobId' | 'status' | 'progress'>
    & { documents?: Maybe<Array<(
      { __typename?: 'DocumentInfo' }
      & Pick<DocumentInfo, 'contractId' | 'url' | 'hash'>
    ) | (
      { __typename?: 'GenerateDocumentError' }
      & Pick<GenerateDocumentError, 'contractId' | 'reason'>
    )>> }
  ) }
);

export type GetInitialBoardQueryVariables = Exact<{
  clientId: Scalars['BigInt'];
}>;


export type GetInitialBoardQuery = (
  { __typename?: 'Query' }
  & { crminstances?: Maybe<(
    { __typename?: 'CrminstancesConnection' }
    & { nodes: Array<(
      { __typename?: 'Crminstance' }
      & Pick<Crminstance, 'id'>
      & { crminstanceLaneRelsByIdCrminstance: (
        { __typename?: 'CrminstanceLaneRelsConnection' }
        & { nodes: Array<(
          { __typename?: 'CrminstanceLaneRel' }
          & { laneByIdLanes?: Maybe<(
            { __typename?: 'Lane' }
            & Pick<Lane, 'name' | 'updatedat' | 'id'>
            & { laneCardRelsByIdLanes: (
              { __typename?: 'LaneCardRelsConnection' }
              & { nodes: Array<(
                { __typename?: 'LaneCardRel' }
                & { cardByIdCard?: Maybe<(
                  { __typename?: 'Card' }
                  & Pick<Card, 'isperson' | 'prefix' | 'name' | 'companyname' | 'co' | 'contractstartdate' | 'contractenddate' | 'street' | 'streetnr' | 'createdat' | 'updatedat' | 'ajv' | 'reductionstartdate' | 'reduction' | 'city' | 'id' | 'zipcode' | 'phone' | 'email' | 'state' | 'notes' | 'plantid' | 'workspaceid' | 'tariffid' | 'meterid' | 'shippingaddressstreet' | 'shippingaddressstreetnr' | 'shippingaddresszipcode' | 'shippingaddresscity' | 'leadorigin'>
                )> }
              )> }
            ) }
          )> }
        )> }
      ) }
    )> }
  )> }
);

export type GetBicForIbanQueryVariables = Exact<{
  iban: Scalars['String'];
}>;


export type GetBicForIbanQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getBicForIban'>
);

export type GetDocumentTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDocumentTypesQuery = (
  { __typename?: 'Query' }
  & { getDocumentTypes: Array<(
    { __typename?: 'DocumentType' }
    & Pick<DocumentType, 'name' | 'label' | 'group'>
  )> }
);

export type GetExtrapolatedMeterReadingQueryVariables = Exact<{
  meterId: Scalars['ID'];
  date: Scalars['DateTime'];
}>;


export type GetExtrapolatedMeterReadingQuery = (
  { __typename?: 'Query' }
  & { getExtrapolatedMeterReading?: Maybe<(
    { __typename?: 'ExtrapolatedReading' }
    & Pick<ExtrapolatedReading, 'value'>
  ) | (
    { __typename?: 'ValidationError' }
    & Pick<ValidationError, 'message'>
  )> }
);

export type GetSumMeterConsumptionQueryVariables = Exact<{
  contractId: Scalars['String'];
}>;


export type GetSumMeterConsumptionQuery = (
  { __typename?: 'Query' }
  & { getSumMeterConsumption: (
    { __typename?: 'SumMeterConsumptionSuccess' }
    & Pick<SumMeterConsumptionSuccess, 'malo'>
  ) | (
    { __typename?: 'SumMeterConsumptionError' }
    & Pick<SumMeterConsumptionError, 'message'>
  ) }
);

export type NetworkOperatorDataFragment = (
  { __typename?: 'NetworkOperator' }
  & Pick<NetworkOperator, 'bdewCode' | 'companyName' | 'postCode' | 'city' | 'street' | 'country' | 'email' | 'phone'>
);

export type GetSupplierChangeWorkflowStateQueryVariables = Exact<{
  workflowId: Scalars['String'];
}>;


export type GetSupplierChangeWorkflowStateQuery = (
  { __typename?: 'Query' }
  & { getSupplierChangeWorkflowState: (
    { __typename?: 'SupplierChangeWorkflowState' }
    & Pick<SupplierChangeWorkflowState, 'done' | 'type' | 'contractId' | 'startedAt'>
    & { steps: Array<(
      { __typename?: 'SupplierChangeWorkflowStep' }
      & Pick<SupplierChangeWorkflowStep, 'index' | 'status'>
      & { result: (
        { __typename: 'ChildWorkflowResult' }
        & Pick<ChildWorkflowResult, 'workflowId' | 'done'>
      ) | (
        { __typename: 'ContractUpdateResult' }
        & Pick<ContractUpdateResult, 'deadline' | 'contractDate' | 'newMalo' | 'parentWorkflowId' | 'reductionValue' | 'reductionStartDate'>
      ) | (
        { __typename: 'DocumentResult' }
        & { document?: Maybe<(
          { __typename?: 'SupplierChangeDocument' }
          & Pick<SupplierChangeDocument, 'url'>
        )> }
      ) | (
        { __typename: 'NetworkOperatorResult' }
        & { meteringPointOperator?: Maybe<(
          { __typename?: 'NetworkOperator' }
          & NetworkOperatorDataFragment
        )>, gridOperator?: Maybe<(
          { __typename?: 'NetworkOperator' }
          & NetworkOperatorDataFragment
        )>, oldSupplier?: Maybe<(
          { __typename?: 'NetworkOperator' }
          & NetworkOperatorDataFragment
        )> }
      ) }
    )> }
  ) }
);

export type GetSupplierChangeWorkflowsQueryVariables = Exact<{
  workflowType: Scalars['String'];
  nextPageToken?: Maybe<Scalars['String']>;
}>;


export type GetSupplierChangeWorkflowsQuery = (
  { __typename?: 'Query' }
  & { getSupplierChangeWorkflows: (
    { __typename?: 'GetSupplierChangeResponse' }
    & Pick<GetSupplierChangeResponse, 'nextPageToken' | 'totalCount'>
    & { data: Array<(
      { __typename?: 'SupplierChangeData' }
      & Pick<SupplierChangeData, 'WorkflowId' | 'RunId' | 'ContractId' | 'ContractName' | 'ContractLabel' | 'Plant' | 'Meter' | 'Malo' | 'NetworkOperator' | 'WorkflowStatus' | 'StatusText'>
    )> }
  ) }
);

export type ImportDebitPositionsMutationVariables = Exact<{
  workflowId: Scalars['ID'];
  payload: ImportDebitPositionsPayload;
}>;


export type ImportDebitPositionsMutation = (
  { __typename?: 'Mutation' }
  & { importDebitPositions?: Maybe<(
    { __typename?: 'Workflow' }
    & Pick<Workflow, 'id' | 'alias' | 'type' | 'stateNames' | 'done' | 'meta' | 'createdAt' | 'doneAt' | 'updatedAt'>
    & { currentState: (
      { __typename?: 'State' }
      & Pick<State, 'name' | 'parentName' | 'context' | 'transitions'>
    ) }
  )> }
);

export type ListPlantsQueryVariables = Exact<{
  projectId: Scalars['ID'];
}>;


export type ListPlantsQuery = (
  { __typename?: 'Query' }
  & { readProject?: Maybe<(
    { __typename?: 'Project' }
    & { plants: Array<(
      { __typename?: 'PlantSimple' }
      & Pick<PlantSimple, 'id'>
    )> }
  )> }
);

export type LoginUserMutationMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  twoFactorAuthCallbackURL: Scalars['String'];
}>;


export type LoginUserMutationMutation = (
  { __typename?: 'Mutation' }
  & { loginUser: (
    { __typename: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'secondaryColor' | 'primaryColor' | 'logo'>
    ) }
  ) | (
    { __typename: 'TwoFactorRequired' }
    & Pick<TwoFactorRequired, 'email'>
  ) | (
    { __typename: 'TwoFactorEmailFailed' }
    & Pick<TwoFactorEmailFailed, 'message'>
  ) | (
    { __typename: 'WrongCredentialsError' }
    & Pick<WrongCredentialsError, 'message'>
  ) | (
    { __typename: 'ServiceNotReachableError' }
    & Pick<ServiceNotReachableError, 'message'>
  ) | (
    { __typename: 'UnknownError' }
    & Pick<UnknownError, 'message'>
  ) }
);

export type LogoutUserMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logoutUser'>
);

export type MergeInvoiceDraftsMutationVariables = Exact<{
  invoiceDraftIds: Array<Scalars['ID']> | Scalars['ID'];
  opcName: Scalars['String'];
}>;


export type MergeInvoiceDraftsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'mergeInvoiceDrafts'>
);

export type DeleteMetadataMutationVariables = Exact<{
  contractLabel: Scalars['ID'];
  customerLabel: Scalars['ID'];
  metadata: CreateUpdateMetadataInput;
}>;


export type DeleteMetadataMutation = (
  { __typename?: 'Mutation' }
  & { deleteMetadata: Array<(
    { __typename?: 'Metadata' }
    & Pick<Metadata, 'key' | 'value'>
  )> }
);

export type OnWorkflowChangeSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnWorkflowChangeSubscription = (
  { __typename?: 'Subscription' }
  & { workflowChange?: Maybe<(
    { __typename?: 'WorkflowChange' }
    & Pick<WorkflowChange, 'workflowId'>
  )> }
);

export type PrepareUpdateContractsBatchedMutationVariables = Exact<{
  contracts: Array<PrepareUpdateContractsBatchedInput> | PrepareUpdateContractsBatchedInput;
}>;


export type PrepareUpdateContractsBatchedMutation = (
  { __typename?: 'Mutation' }
  & { prepareUpdateContractsBatched: (
    { __typename: 'PrepareUpdateContractsBatchedResponse' }
    & { valid: Array<(
      { __typename?: 'UpdateContractsBatchedValidationSuccess' }
      & Pick<UpdateContractsBatchedValidationSuccess, 'contractId' | 'contractLabel' | 'customerLabel'>
      & { contract: (
        { __typename?: 'UpdateContractsContractData' }
        & Pick<UpdateContractsContractData, 'status' | 'startDate' | 'downPayment' | 'downPaymentStartDate'>
        & { customer?: Maybe<(
          { __typename?: 'UpdateContractsBatchedCustomer' }
          & Pick<UpdateContractsBatchedCustomer, 'hasSepa' | 'documentDeliveryMethod' | 'advertisementAccepted'>
          & { person?: Maybe<(
            { __typename?: 'UpdateContractsBatchedPerson' }
            & Pick<UpdateContractsBatchedPerson, 'namePrefix' | 'name' | 'contactPhone' | 'contactMobile' | 'companyFlag' | 'co'>
          )>, address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'streetName' | 'streetNumber' | 'zipCode' | 'city' | 'country'>
          )>, addressBilling?: Maybe<(
            { __typename?: 'OdooAddress' }
            & Pick<OdooAddress, 'partnerId' | 'additionalPartnerId' | 'namePrefix' | 'name' | 'additionalName' | 'additionalNamePrefix' | 'streetName' | 'streetNumber' | 'zipCode' | 'city' | 'country' | 'email' | 'companyFlag' | 'co'>
          )>, addressShipping?: Maybe<(
            { __typename?: 'UpdateContractsBatchedShippingAddress' }
            & Pick<UpdateContractsBatchedShippingAddress, 'namePrefix' | 'name' | 'streetName' | 'streetNumber' | 'zipCode' | 'city' | 'country' | 'companyFlag' | 'co'>
          )>, bankAccount?: Maybe<(
            { __typename?: 'UpdateContractsBatchedBankAccount' }
            & Pick<UpdateContractsBatchedBankAccount, 'name' | 'iban' | 'bic' | 'mandateReference' | 'signedAt'>
          )> }
        )> }
      ) }
    )>, failed: Array<(
      { __typename?: 'UpdateContractsBatchedValidationError' }
      & Pick<UpdateContractsBatchedValidationError, 'contractLabel' | 'message' | 'error'>
    )> }
  ) }
);

export type ReadAccountingDetailsReportQueryVariables = Exact<{
  opcName: Scalars['String'];
}>;


export type ReadAccountingDetailsReportQuery = (
  { __typename?: 'Query' }
  & { readAccountingDetailsReport: Array<(
    { __typename?: 'AccountingDetailsReport' }
    & Pick<AccountingDetailsReport, 'contractLabel' | 'accountingReceivableAccount' | 'accountingNumber' | 'accountingPeriodStartDate' | 'accountingPeriodEndDate' | 'accountingGenerationDate' | 'accountingNetValue' | 'accountingTaxValue' | 'accountingGrossValue' | 'accountingDiff' | 'consumption' | 'reduction' | 'reductionStartDate'>
  )> }
);

export type ReadAccountingKpisQueryVariables = Exact<{ [key: string]: never; }>;


export type ReadAccountingKpisQuery = (
  { __typename?: 'Query' }
  & { readAccountingKpis: (
    { __typename?: 'AccountingKpis' }
    & Pick<AccountingKpis, 'totalInvoicesMandate' | 'cancelledInvoicesMandate'>
  ) }
);

export type SettlementDateFieldsFragment = (
  { __typename?: 'SettlementDate' }
  & Pick<SettlementDate, 'day' | 'month'>
);

export type StandardAccountingFieldsFragment = (
  { __typename?: 'StandardAccountingContractExcerpt' }
  & Pick<StandardAccountingContractExcerpt, 'id' | 'plantId' | 'label' | 'customerLabel' | 'startDate' | 'endDate' | 'accFlowUUID'>
  & { settlement: (
    { __typename?: 'SettlementDate' }
    & SettlementDateFieldsFragment
  ) }
);

export type ClosingAccountingFieldsFragment = (
  { __typename?: 'ClosingAccountingContractExcerpt' }
  & Pick<ClosingAccountingContractExcerpt, 'id' | 'label' | 'customerLabel' | 'plantId' | 'startDate' | 'endDate' | 'customerName' | 'invoiceCycle' | 'accFlowUUID'>
  & { settlement: (
    { __typename?: 'SettlementDate' }
    & SettlementDateFieldsFragment
  ) }
);

export type ReadAccountingOverviewQueryVariables = Exact<{ [key: string]: never; }>;


export type ReadAccountingOverviewQuery = (
  { __typename?: 'Query' }
  & { readAccountingOverview: (
    { __typename?: 'AccountingOverview' }
    & Pick<AccountingOverview, 'totalPendingInvoices'>
    & { periods: Array<(
      { __typename?: 'AccountingPeriodGroup' }
      & Pick<AccountingPeriodGroup, 'start' | 'end'>
      & { settlement: (
        { __typename?: 'SettlementDate' }
        & SettlementDateFieldsFragment
      ), standardAccounting: (
        { __typename?: 'StandardAccountingExcerpt' }
        & { monthlySettlement: Array<(
          { __typename?: 'StandardAccountingContractExcerpt' }
          & StandardAccountingFieldsFragment
        )>, quarterlySettlement: Array<(
          { __typename?: 'StandardAccountingContractExcerpt' }
          & StandardAccountingFieldsFragment
        )>, halfYearlySettlement: Array<(
          { __typename?: 'StandardAccountingContractExcerpt' }
          & StandardAccountingFieldsFragment
        )>, yearlySettlement: Array<(
          { __typename?: 'StandardAccountingContractExcerpt' }
          & StandardAccountingFieldsFragment
        )> }
      ), closingAccounting: Array<(
        { __typename?: 'ClosingAccountingContractExcerpt' }
        & ClosingAccountingFieldsFragment
      )>, unfinishedStandardAccounting?: Maybe<(
        { __typename?: 'StandardAccountingExcerpt' }
        & { monthlySettlement: Array<(
          { __typename?: 'StandardAccountingContractExcerpt' }
          & StandardAccountingFieldsFragment
        )>, quarterlySettlement: Array<(
          { __typename?: 'StandardAccountingContractExcerpt' }
          & StandardAccountingFieldsFragment
        )>, halfYearlySettlement: Array<(
          { __typename?: 'StandardAccountingContractExcerpt' }
          & StandardAccountingFieldsFragment
        )>, yearlySettlement: Array<(
          { __typename?: 'StandardAccountingContractExcerpt' }
          & StandardAccountingFieldsFragment
        )> }
      )>, unfinishedClosingAccounting?: Maybe<Array<(
        { __typename?: 'ClosingAccountingContractExcerpt' }
        & ClosingAccountingFieldsFragment
      )>> }
    )> }
  ) }
);

export type ReadBalancingAreaAccountsQueryVariables = Exact<{ [key: string]: never; }>;


export type ReadBalancingAreaAccountsQuery = (
  { __typename?: 'Query' }
  & { readBalancingAreaAccounts: Array<(
    { __typename?: 'BalancingAreaAccount' }
    & Pick<BalancingAreaAccount, 'id' | 'name' | 'eic'>
    & { transmissionSystemOperator: (
      { __typename?: 'TransmissionSystemOperator' }
      & Pick<TransmissionSystemOperator, 'id' | 'name' | 'code'>
    ) }
  )> }
);

export type ReadBalancingAreaGroupAccountsQueryVariables = Exact<{ [key: string]: never; }>;


export type ReadBalancingAreaGroupAccountsQuery = (
  { __typename?: 'Query' }
  & { readBalancingAreaGroupAccounts: Array<(
    { __typename?: 'BalancingAreaGroupAccount' }
    & Pick<BalancingAreaGroupAccount, 'id' | 'name' | 'eic' | 'transmissionSystemOperatorId'>
    & { suppliers?: Maybe<Array<(
      { __typename?: 'Supplier' }
      & Pick<Supplier, 'id'>
    )>> }
  )> }
);

export type ReadBookingsReportsQueryVariables = Exact<{ [key: string]: never; }>;


export type ReadBookingsReportsQuery = (
  { __typename?: 'Query' }
  & { readBookingsReports: Array<(
    { __typename?: 'BookingReport' }
    & Pick<BookingReport, 'id' | 'name' | 'url' | 'createdAt' | 'status'>
  )> }
);

export type ReadCheckClientSetupQueryVariables = Exact<{ [key: string]: never; }>;


export type ReadCheckClientSetupQuery = (
  { __typename?: 'Query' }
  & { readCheckClientSetup?: Maybe<(
    { __typename?: 'ContractClientSetup' }
    & Pick<ContractClientSetup, 'success' | 'error'>
  )> }
);

export type ReadClientDetailsQueryVariables = Exact<{ [key: string]: never; }>;


export type ReadClientDetailsQuery = (
  { __typename?: 'Query' }
  & { readClientDetails?: Maybe<(
    { __typename?: 'ClientDetails' }
    & Pick<ClientDetails, 'companyName' | 'email' | 'numberOfFooters'>
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'streetName' | 'streetNumber' | 'zipCode' | 'city'>
    )>, contact?: Maybe<(
      { __typename?: 'ClientDetailsContact' }
      & Pick<ClientDetailsContact, 'serviceHotline' | 'serviceEmail' | 'serviceHours' | 'footer1' | 'footer2' | 'footer3' | 'footer4'>
    )>, workspaces?: Maybe<Array<(
      { __typename?: 'ClientWorkspaceDetails' }
      & Pick<ClientWorkspaceDetails, 'name' | 'companyName' | 'email' | 'numberOfFooters'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'streetName' | 'streetNumber' | 'zipCode' | 'city'>
      )>, contact?: Maybe<(
        { __typename?: 'ClientDetailsContact' }
        & Pick<ClientDetailsContact, 'serviceHotline' | 'serviceEmail' | 'serviceHours' | 'footer1' | 'footer2' | 'footer3' | 'footer4' | 'headerAddress'>
      )>, banking?: Maybe<(
        { __typename?: 'ClientDetailsBanking' }
        & Pick<ClientDetailsBanking, 'iban' | 'bic'>
      )> }
    )>>, banking?: Maybe<(
      { __typename?: 'ClientDetailsBanking' }
      & Pick<ClientDetailsBanking, 'iban' | 'bic'>
    )>, contractDraftTemplates?: Maybe<(
      { __typename?: 'ContractDraftTemplates' }
      & Pick<ContractDraftTemplates, 'pricesheet_letter' | 'withdrawal_letter' | 'poa_cancellation_letter' | 'covering_letter'>
    )> }
  )> }
);

export type ReadClientSmtpConfigQueryVariables = Exact<{ [key: string]: never; }>;


export type ReadClientSmtpConfigQuery = (
  { __typename?: 'Query' }
  & { readClientSmtpConfig?: Maybe<(
    { __typename?: 'ClientSmtpConfig' }
    & Pick<ClientSmtpConfig, 'active' | 'host' | 'port' | 'mailFrom' | 'secure' | 'tls'>
    & { auth?: Maybe<(
      { __typename?: 'SmtpAuthConfig' }
      & Pick<SmtpAuthConfig, 'user'>
    )> }
  )> }
);

export type ReadConsumptionsQueryVariables = Exact<{
  contractID: Scalars['ID'];
}>;


export type ReadConsumptionsQuery = (
  { __typename?: 'Query' }
  & { readConsumptions: (
    { __typename?: 'ConsumptionsForContract' }
    & { ajvs: Array<(
      { __typename?: 'Consumption' }
      & Pick<Consumption, 'startDate' | 'endDate' | 'value'>
    )>, consumptions: Array<(
      { __typename?: 'Consumption' }
      & Pick<Consumption, 'startDate' | 'endDate' | 'value'>
    )> }
  ) }
);

export type ContractSimpleFragmentFragment = (
  { __typename?: 'Contract' }
  & Pick<Contract, 'label'>
  & { customer: (
    { __typename?: 'Customer' }
    & Pick<Customer, 'id' | 'label' | 'birthday'>
    & { person: (
      { __typename?: 'Person' }
      & Pick<Person, 'name' | 'namePrefix' | 'contactMobile' | 'contactPhone' | 'companyFlag' | 'co'>
    ), address: (
      { __typename?: 'Address' }
      & Pick<Address, 'streetName' | 'streetNumber' | 'zipCode' | 'city' | 'country'>
    ), addressBilling: (
      { __typename?: 'OdooAddress' }
      & Pick<OdooAddress, 'partnerId' | 'additionalPartnerId' | 'name' | 'namePrefix' | 'email' | 'additionalName' | 'additionalNamePrefix' | 'streetName' | 'streetNumber' | 'zipCode' | 'city' | 'country' | 'companyFlag' | 'co'>
    ), addressShipping: (
      { __typename?: 'OdooAddress' }
      & Pick<OdooAddress, 'name' | 'namePrefix' | 'streetName' | 'streetNumber' | 'zipCode' | 'city' | 'country' | 'companyFlag' | 'co'>
    ) }
  ) }
);

export type ReadContractQueryVariables = Exact<{
  contractId: Scalars['ID'];
}>;


export type ReadContractQuery = (
  { __typename?: 'Query' }
  & { readContract?: Maybe<(
    { __typename?: 'Contract' }
    & Pick<Contract, 'id' | 'plantId' | 'label' | 'status' | 'signDate' | 'startDate' | 'endDate' | 'contractType' | 'invoiceCycle' | 'cancelationReason' | 'cancelationDate' | 'earlyTerminationReason' | 'debtorLabel' | 'settlementDate' | 'settlementDay' | 'settlementMonth' | 'paymentDate' | 'downPayment' | 'downPaymentStartDate' | 'electricityGenerationOrGasType'>
    & { downPaymentHistory: Array<(
      { __typename?: 'DownPaymentHistory' }
      & Pick<DownPaymentHistory, 'downPayment' | 'downPaymentStartDate'>
    )>, contractMeter?: Maybe<(
      { __typename?: 'ContractMeter' }
      & Pick<ContractMeter, 'malo'>
      & { meters: Array<(
        { __typename?: 'Meter' }
        & Pick<Meter, 'id' | 'contractId' | 'melo' | 'meterType' | 'meterNumber' | 'meterPlace' | 'validityStart' | 'validityEnd' | 'metering'>
      )> }
    )>, loadProfile?: Maybe<(
      { __typename?: 'LoadProfile' }
      & Pick<LoadProfile, 'id' | 'name'>
    )>, tariffs?: Maybe<Array<(
      { __typename?: 'ContractTariff' }
      & Pick<ContractTariff, 'id' | 'tariffId' | 'nameExternal' | 'nameInternal' | 'validityStartDate' | 'validityEndDate' | 'assignedFrom' | 'assignedTo'>
    )>>, customer: (
      { __typename?: 'Customer' }
      & Pick<Customer, 'id' | 'label' | 'birthday' | 'advertisementAccepted' | 'documentDeliveryMethod' | 'hasSepa' | 'isOperator'>
      & { person: (
        { __typename?: 'Person' }
        & Pick<Person, 'name' | 'namePrefix' | 'contactMobile' | 'contactPhone' | 'contactEmail' | 'companyFlag' | 'co'>
      ), address: (
        { __typename?: 'Address' }
        & Pick<Address, 'streetName' | 'streetNumber' | 'zipCode' | 'city' | 'country'>
      ), addressBilling: (
        { __typename?: 'OdooAddress' }
        & Pick<OdooAddress, 'partnerId' | 'additionalPartnerId' | 'name' | 'namePrefix' | 'email' | 'additionalName' | 'additionalNamePrefix' | 'companySecondaryFlag' | 'streetName' | 'streetNumber' | 'zipCode' | 'city' | 'country' | 'companyFlag' | 'co'>
      ), addressShipping: (
        { __typename?: 'OdooAddress' }
        & Pick<OdooAddress, 'namePrefix' | 'name' | 'streetName' | 'streetNumber' | 'zipCode' | 'city' | 'country' | 'companyFlag' | 'co'>
      ), bankAccount?: Maybe<(
        { __typename?: 'BankAccount' }
        & Pick<BankAccount, 'name' | 'iban' | 'bic' | 'mandateReference' | 'signedAt'>
      )>, payer?: Maybe<(
        { __typename?: 'Payer' }
        & Pick<Payer, 'accountHolderId' | 'name' | 'number' | 'iban' | 'bic'>
      )> }
    ), workspace: (
      { __typename?: 'Workspace' }
      & Pick<Workspace, 'id' | 'name'>
    ), documents?: Maybe<Array<(
      { __typename?: 'Document' }
      & Pick<Document, 'hash' | 'fileURL' | 'type' | 'contentType' | 'createdAt' | 'fileName' | 'userUpload' | 'parentGroup'>
    )>> }
  )> }
);

export type ReadContractAccountingQueryVariables = Exact<{
  contractId: Scalars['ID'];
  contractLabel: Scalars['ID'];
  startAt: Scalars['DateTime'];
  endAt: Scalars['DateTime'];
}>;


export type ReadContractAccountingQuery = (
  { __typename?: 'Query' }
  & { readContract?: Maybe<(
    { __typename?: 'Contract' }
    & Pick<Contract, 'id'>
    & { customer: (
      { __typename?: 'Customer' }
      & Pick<Customer, 'id' | 'hasSepa' | 'isOperator'>
      & { bankAccount?: Maybe<(
        { __typename?: 'BankAccount' }
        & Pick<BankAccount, 'name' | 'iban' | 'bic' | 'mandateReference' | 'signedAt'>
      )> }
    ) }
  )>, readAccountMoves?: Maybe<(
    { __typename?: 'AccountMoves' }
    & { processed: Array<(
      { __typename?: 'AccountMove' }
      & Pick<AccountMove, 'type' | 'state' | 'amount' | 'bookedAt' | 'name' | 'opcName' | 'sepa' | 'sepaReturn' | 'partialAmount' | 'partialPaidAt' | 'canceledAt' | 'canceledName' | 'paidAt'>
      & { bookings?: Maybe<Array<(
        { __typename?: 'Booking' }
        & Pick<Booking, 'type' | 'date' | 'amount' | 'account'>
      )>> }
    )>, unprocessed: Array<(
      { __typename?: 'UnprocessedBooking' }
      & Pick<UnprocessedBooking, 'account' | 'name' | 'ref' | 'date' | 'amount' | 'journal' | 'sollstellungDate' | 'matchingNumber'>
    )>, vouchers: Array<(
      { __typename?: 'UnprocessedBooking' }
      & Pick<UnprocessedBooking, 'name' | 'date' | 'amount' | 'journal' | 'account' | 'ref'>
    )> }
  )> }
);

export type ReadContractAccountingHistoryQueryVariables = Exact<{
  contractId: Scalars['ID'];
}>;


export type ReadContractAccountingHistoryQuery = (
  { __typename?: 'Query' }
  & { readContractAccountingHistory: (
    { __typename?: 'ContractAccountingHistory' }
    & Pick<ContractAccountingHistory, 'lastAccountingEnd' | 'nextAccountingStart'>
  ) }
);

export type ReadContractByCustomerQueryVariables = Exact<{
  customerId: Scalars['ID'];
}>;


export type ReadContractByCustomerQuery = (
  { __typename?: 'Query' }
  & { readContractByCustomer?: Maybe<(
    { __typename?: 'Contract' }
    & ContractSimpleFragmentFragment
  )> }
);

export type ReadContractCancelationDateQueryVariables = Exact<{
  contractId: Scalars['ID'];
  tariffId: Scalars['ID'];
  noticeDate?: Maybe<Scalars['String']>;
}>;


export type ReadContractCancelationDateQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'readContractCancelationDate'>
);

export type ReadContractCommunicationsQueryVariables = Exact<{
  contractId: Scalars['ID'];
}>;


export type ReadContractCommunicationsQuery = (
  { __typename?: 'Query' }
  & { readContract?: Maybe<(
    { __typename?: 'Contract' }
    & Pick<Contract, 'id'>
    & { communications?: Maybe<Array<(
      { __typename?: 'CustomerCommunication' }
      & Pick<CustomerCommunication, 'id' | 'title' | 'message' | 'communicatedBy' | 'date'>
    )>> }
  )> }
);

export type ReadContractExcerptsQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type ReadContractExcerptsQuery = (
  { __typename?: 'Query' }
  & { readContractExcerpts: Array<(
    { __typename?: 'ContractExcerpt' }
    & Pick<ContractExcerpt, 'id' | 'label' | 'startDate' | 'endDate' | 'meterNumber' | 'name' | 'customerLabel' | 'isOperator' | 'meterType' | 'electricityGenerationOrGasType' | 'contractStatus' | 'contractType'>
  )> }
);

export type ReadContractFastQueryVariables = Exact<{
  contractId: Scalars['ID'];
  contractLabel: Scalars['String'];
}>;


export type ReadContractFastQuery = (
  { __typename?: 'Query' }
  & { readContract?: Maybe<(
    { __typename?: 'Contract' }
    & Pick<Contract, 'id' | 'plantId' | 'label' | 'status' | 'signDate' | 'startDate' | 'endDate' | 'contractType' | 'invoiceCycle' | 'cancelationReason' | 'cancelationDate' | 'earlyTerminationReason' | 'debtorLabel' | 'settlementDate' | 'settlementDay' | 'settlementMonth' | 'paymentDate' | 'downPayment' | 'downPaymentStartDate' | 'electricityGenerationOrGasType'>
    & { downPaymentHistory: Array<(
      { __typename?: 'DownPaymentHistory' }
      & Pick<DownPaymentHistory, 'downPayment' | 'downPaymentStartDate'>
    )>, contractMeter?: Maybe<(
      { __typename?: 'ContractMeter' }
      & Pick<ContractMeter, 'malo'>
      & { meters: Array<(
        { __typename?: 'Meter' }
        & Pick<Meter, 'id' | 'contractId' | 'melo' | 'meterType' | 'meterNumber' | 'meterPlace' | 'validityStart' | 'validityEnd' | 'metering'>
      )> }
    )>, loadProfile?: Maybe<(
      { __typename?: 'LoadProfile' }
      & Pick<LoadProfile, 'id' | 'name'>
    )>, tariffs?: Maybe<Array<(
      { __typename?: 'ContractTariff' }
      & Pick<ContractTariff, 'id' | 'tariffId' | 'nameExternal' | 'nameInternal' | 'validityStartDate' | 'validityEndDate' | 'assignedFrom' | 'assignedTo'>
    )>>, customer: (
      { __typename?: 'Customer' }
      & Pick<Customer, 'id' | 'label' | 'birthday' | 'advertisementAccepted' | 'documentDeliveryMethod' | 'hasSepa' | 'isOperator'>
      & { person: (
        { __typename?: 'Person' }
        & Pick<Person, 'name' | 'namePrefix' | 'contactMobile' | 'contactPhone' | 'contactEmail' | 'companyFlag' | 'co'>
      ), address: (
        { __typename?: 'Address' }
        & Pick<Address, 'streetName' | 'streetNumber' | 'zipCode' | 'city' | 'country'>
      ), addressBilling: (
        { __typename?: 'OdooAddress' }
        & Pick<OdooAddress, 'partnerId' | 'additionalPartnerId' | 'name' | 'namePrefix' | 'email' | 'additionalName' | 'additionalNamePrefix' | 'companySecondaryFlag' | 'streetName' | 'streetNumber' | 'zipCode' | 'city' | 'country' | 'companyFlag' | 'co'>
      ), addressShipping: (
        { __typename?: 'OdooAddress' }
        & Pick<OdooAddress, 'namePrefix' | 'name' | 'streetName' | 'streetNumber' | 'zipCode' | 'city' | 'country' | 'companyFlag' | 'co'>
      ), bankAccount?: Maybe<(
        { __typename?: 'BankAccount' }
        & Pick<BankAccount, 'name' | 'iban' | 'bic' | 'mandateReference' | 'signedAt'>
      )> }
    ), workspace: (
      { __typename?: 'Workspace' }
      & Pick<Workspace, 'id' | 'name'>
    ), documents?: Maybe<Array<(
      { __typename?: 'Document' }
      & Pick<Document, 'hash' | 'fileURL' | 'type' | 'contentType' | 'createdAt' | 'fileName' | 'userUpload' | 'parentGroup'>
    )>> }
  )> }
);

export type ReadContractSimpleQueryVariables = Exact<{
  contractId: Scalars['ID'];
}>;


export type ReadContractSimpleQuery = (
  { __typename?: 'Query' }
  & { readContract?: Maybe<(
    { __typename?: 'Contract' }
    & ContractSimpleFragmentFragment
  )> }
);

export type ReadContractsQueryVariables = Exact<{
  contractLabels: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type ReadContractsQuery = (
  { __typename?: 'Query' }
  & { readContracts: Array<(
    { __typename?: 'OdooContract' }
    & Pick<OdooContract, 'id' | 'label' | 'documentDeliveryMethod'>
  )> }
);

export type ReadContractsBatchQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
  byLabel?: Maybe<Scalars['Boolean']>;
}>;


export type ReadContractsBatchQuery = (
  { __typename?: 'Query' }
  & { readContractsBatch: Array<(
    { __typename?: 'Contract' }
    & Pick<Contract, 'id' | 'label' | 'startDate' | 'status' | 'plantId'>
    & { customer: (
      { __typename?: 'Customer' }
      & Pick<Customer, 'label'>
      & { person: (
        { __typename?: 'Person' }
        & Pick<Person, 'name'>
      ) }
    ), contractMeter?: Maybe<(
      { __typename?: 'ContractMeter' }
      & Pick<ContractMeter, 'malo'>
      & { meters: Array<(
        { __typename?: 'Meter' }
        & Pick<Meter, 'id' | 'meterType' | 'msoType'>
      )> }
    )> }
  )> }
);

export type ReadContractsBatchFullQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type ReadContractsBatchFullQuery = (
  { __typename?: 'Query' }
  & { readContractsBatch: Array<(
    { __typename?: 'Contract' }
    & Pick<Contract, 'id' | 'label' | 'signDate' | 'startDate' | 'status' | 'paymentDate' | 'contractType' | 'downPayment' | 'downPaymentStartDate'>
    & { customer: (
      { __typename?: 'Customer' }
      & Pick<Customer, 'id' | 'label' | 'advertisementAccepted' | 'documentDeliveryMethod' | 'hasSepa'>
      & { person: (
        { __typename?: 'Person' }
        & Pick<Person, 'name' | 'contactMobile' | 'contactPhone'>
      ), address: (
        { __typename?: 'Address' }
        & Pick<Address, 'streetName' | 'streetNumber' | 'zipCode' | 'city' | 'country'>
      ), addressBilling: (
        { __typename?: 'OdooAddress' }
        & Pick<OdooAddress, 'partnerId' | 'additionalPartnerId' | 'name' | 'namePrefix' | 'email' | 'additionalName' | 'additionalNamePrefix' | 'companySecondaryFlag' | 'streetName' | 'streetNumber' | 'zipCode' | 'city' | 'country' | 'companyFlag'>
      ), addressShipping: (
        { __typename?: 'OdooAddress' }
        & Pick<OdooAddress, 'namePrefix' | 'name' | 'streetName' | 'streetNumber' | 'zipCode' | 'city' | 'country' | 'companyFlag'>
      ), bankAccount?: Maybe<(
        { __typename?: 'BankAccount' }
        & Pick<BankAccount, 'name' | 'iban' | 'bic' | 'mandateReference' | 'signedAt'>
      )> }
    ) }
  )> }
);

export type ReadContractsForMeterQueryVariables = Exact<{
  meterId: Scalars['ID'];
}>;


export type ReadContractsForMeterQuery = (
  { __typename?: 'Query' }
  & { readContractsForMeter: Array<(
    { __typename?: 'ContractForMeter' }
    & Pick<ContractForMeter, 'id' | 'label' | 'customerNames'>
    & { meters: Array<(
      { __typename?: 'MeterInfo' }
      & Pick<MeterInfo, 'id' | 'validityStart' | 'validityEnd'>
    )> }
  )> }
);

export type ReadCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type ReadCurrentUserQuery = (
  { __typename?: 'Query' }
  & { readCurrentUser: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name' | 'logo'>
    ) }
  ) }
);

export type ReadCustomersSimpleQueryVariables = Exact<{
  isOperator?: Maybe<Scalars['Boolean']>;
}>;


export type ReadCustomersSimpleQuery = (
  { __typename?: 'Query' }
  & { readCustomers: Array<(
    { __typename?: 'CustomerLight' }
    & Pick<CustomerLight, 'id' | 'label'>
    & { person: (
      { __typename?: 'Person' }
      & Pick<Person, 'name'>
    ), address: (
      { __typename?: 'Address' }
      & Pick<Address, 'streetName' | 'streetNumber' | 'zipCode' | 'city' | 'country'>
    ) }
  )> }
);

export type ReadDashboardQueryVariables = Exact<{
  newsPostTypes: Array<NewsPostType> | NewsPostType;
  latestNewsLimit?: Maybe<Scalars['Int']>;
}>;


export type ReadDashboardQuery = (
  { __typename?: 'Query' }
  & { plantKpis: Array<(
    { __typename?: 'Project' }
    & { plants: Array<(
      { __typename?: 'PlantSimple' }
      & { kpis: (
        { __typename?: 'PlantKPI' }
        & Pick<PlantKpi, 'units' | 'unitsMembers'>
      ) }
    )> }
  )>, accountingKpis: (
    { __typename?: 'AccountingKpis' }
    & Pick<AccountingKpis, 'totalInvoicesMandate' | 'cancelledInvoicesMandate' | 'confirmedInvoicesMandate'>
  ), drafts: Array<(
    { __typename?: 'InvoiceDraftExcerpt' }
    & Pick<InvoiceDraftExcerpt, 'customerName'>
    & { workflow?: Maybe<(
      { __typename?: 'Workflow' }
      & Pick<Workflow, 'id' | 'alias'>
    )> }
  )>, latestNews?: Maybe<Array<Maybe<(
    { __typename?: 'NewsPost' }
    & Pick<NewsPost, 'id' | 'type' | 'title' | 'createdAt' | 'body'>
  )>>>, oldNews?: Maybe<Array<Maybe<(
    { __typename?: 'NewsPost' }
    & Pick<NewsPost, 'id' | 'type' | 'title' | 'forumId' | 'createdAt'>
  )>>> }
);

export type ReadDocumentsQueryVariables = Exact<{
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['String']>;
}>;


export type ReadDocumentsQuery = (
  { __typename?: 'Query' }
  & { readDocuments: Array<(
    { __typename: 'Document' }
    & Pick<Document, 'createdAt' | 'fileURL' | 'type' | 'fileName' | 'contentType' | 'hash' | 'parentGroup' | 'userUpload'>
  )> }
);

export type ReadInvoiceDraftsQueryQueryVariables = Exact<{
  filterByOPCName?: Maybe<Scalars['String']>;
  showDocuments?: Maybe<Scalars['Boolean']>;
}>;


export type ReadInvoiceDraftsQueryQuery = (
  { __typename?: 'Query' }
  & { drafts: Array<(
    { __typename?: 'InvoiceDraft' }
    & Pick<InvoiceDraft, 'id' | 'customerName' | 'amount' | 'dueDate' | 'contractId' | 'invoiceId' | 'confirmed' | 'invoiceDocumentUrl' | 'documentDeliveryMethod'>
  )> }
);

export type ReadLoadProfilesQueryVariables = Exact<{
  balancingAreaAccountId: Scalars['ID'];
}>;


export type ReadLoadProfilesQuery = (
  { __typename?: 'Query' }
  & { readLoadProfiles: Array<(
    { __typename?: 'LoadProfile' }
    & Pick<LoadProfile, 'id' | 'name'>
  )> }
);

export type ReadMaloBoundQueryVariables = Exact<{
  contractStartDate: Scalars['String'];
  contractEndDate?: Maybe<Scalars['String']>;
  malo: Scalars['String'];
}>;


export type ReadMaloBoundQuery = (
  { __typename?: 'Query' }
  & { readMaloBound: (
    { __typename?: 'MeloMaloBoundResponse' }
    & Pick<MeloMaloBoundResponse, 'bound'>
  ) }
);

export type ReadMeloBoundQueryVariables = Exact<{
  contractStartDate: Scalars['String'];
  contractEndDate?: Maybe<Scalars['String']>;
  melo: Scalars['String'];
}>;


export type ReadMeloBoundQuery = (
  { __typename?: 'Query' }
  & { readMeloBound: (
    { __typename?: 'MeloMaloBoundResponse' }
    & Pick<MeloMaloBoundResponse, 'bound'>
  ) }
);

export type ReadMetadataQueryVariables = Exact<{
  contractLabel: Scalars['ID'];
}>;


export type ReadMetadataQuery = (
  { __typename?: 'Query' }
  & { readMetadata: Array<(
    { __typename?: 'Metadata' }
    & Pick<Metadata, 'key' | 'value'>
  )> }
);

export type ReadMetadataKeysQueryVariables = Exact<{
  contractLabel: Scalars['ID'];
}>;


export type ReadMetadataKeysQuery = (
  { __typename?: 'Query' }
  & { readMetadataKeys: Array<(
    { __typename?: 'Metadata' }
    & Pick<Metadata, 'key'>
  )> }
);

export type ReadMeterQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ReadMeterQuery = (
  { __typename?: 'Query' }
  & { readMeter?: Maybe<(
    { __typename?: 'Meter' }
    & Pick<Meter, 'id' | 'meterNumber' | 'melo' | 'converterFactor' | 'validityEnd' | 'meterType' | 'msoType' | 'contractId' | 'metering' | 'wasEverBound' | 'inUse' | 'plantId' | 'meterPlace' | 'validityStart'>
    & { contract?: Maybe<(
      { __typename?: 'Contract' }
      & { customer: (
        { __typename?: 'Customer' }
        & Pick<Customer, 'id' | 'label'>
        & { person: (
          { __typename?: 'Person' }
          & Pick<Person, 'name' | 'contactMobile' | 'contactPhone'>
        ) }
      ) }
    )> }
  )> }
);

export type ReadMeterReadingsQueryVariables = Exact<{
  meterId: Scalars['ID'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  metering: Metering;
}>;


export type ReadMeterReadingsQuery = (
  { __typename?: 'Query' }
  & { readMeterReadings: Array<(
    { __typename?: 'MeterReading' }
    & Pick<MeterReading, 'from' | 'to' | 'obis' | 'value' | 'reason' | 'hint' | 'valueStatus' | 'usedForBilling' | 'melo'>
  )> }
);

export type ReadMeterSimpleQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ReadMeterSimpleQuery = (
  { __typename?: 'Query' }
  & { readMeter?: Maybe<(
    { __typename?: 'Meter' }
    & Pick<Meter, 'id' | 'meterNumber' | 'isBound' | 'wasEverBound' | 'contractLabel' | 'validityStart' | 'validityEnd'>
  )> }
);

export type ReadMeteringPointOperatorsQueryVariables = Exact<{ [key: string]: never; }>;


export type ReadMeteringPointOperatorsQuery = (
  { __typename?: 'Query' }
  & { readMeteringPointOperators: Array<(
    { __typename?: 'MeteringPointOperator' }
    & Pick<MeteringPointOperator, 'id' | 'code' | 'name'>
  )> }
);

export type ReadMetersByPlantQueryVariables = Exact<{
  plantId?: Maybe<Scalars['ID']>;
}>;


export type ReadMetersByPlantQuery = (
  { __typename?: 'Query' }
  & { readMeters: Array<(
    { __typename?: 'BoundMeter' }
    & Pick<BoundMeter, 'id' | 'meterNumber'>
  )> }
);

export type ReadMetersSimpleQueryVariables = Exact<{ [key: string]: never; }>;


export type ReadMetersSimpleQuery = (
  { __typename?: 'Query' }
  & { readMeters: Array<(
    { __typename?: 'BoundMeter' }
    & Pick<BoundMeter, 'id' | 'meterNumber'>
  )> }
);

export type NkvTemplateFragmentFragment = (
  { __typename?: 'MkvTemplateData' }
  & Pick<MkvTemplateData, 'contractType' | 'status' | 'loadProfile'>
  & { customer?: Maybe<(
    { __typename?: 'MkvTemplateCustomer' }
    & Pick<MkvTemplateCustomer, 'label' | 'birthday' | 'advertisementAccepted' | 'documentDeliveryMethod' | 'isOperator'>
    & { person?: Maybe<(
      { __typename?: 'Person' }
      & Pick<Person, 'namePrefix' | 'name' | 'contactMobile' | 'contactPhone'>
    )>, address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'streetName' | 'streetNumber' | 'zipCode' | 'city' | 'country'>
    )>, existingCustomer?: Maybe<(
      { __typename?: 'ObjectWithId' }
      & Pick<ObjectWithId, 'id'>
    )> }
  )>, tariff?: Maybe<(
    { __typename?: 'ObjectWithId' }
    & Pick<ObjectWithId, 'id'>
  )> }
);

export type ReadMkvTemplatesQueryVariables = Exact<{ [key: string]: never; }>;


export type ReadMkvTemplatesQuery = (
  { __typename?: 'Query' }
  & { readMkvTemplates?: Maybe<(
    { __typename?: 'MkvTemplates' }
    & { sumMeterFeedIn?: Maybe<(
      { __typename?: 'MkvTemplateData' }
      & NkvTemplateFragmentFragment
    )>, sumMeterConsumption?: Maybe<(
      { __typename?: 'MkvTemplateData' }
      & NkvTemplateFragmentFragment
    )>, prodMeter?: Maybe<(
      { __typename?: 'MkvTemplateData' }
      & NkvTemplateFragmentFragment
    )>, cascadeMeter?: Maybe<(
      { __typename?: 'MkvTemplateData' }
      & NkvTemplateFragmentFragment
    )> }
  )> }
);

export type ReadMkvTemplateQueryVariables = Exact<{
  template: Scalars['String'];
}>;


export type ReadMkvTemplateQuery = (
  { __typename?: 'Query' }
  & { readMkvTemplate?: Maybe<(
    { __typename?: 'MkvTemplateData' }
    & NkvTemplateFragmentFragment
  )> }
);

export type ReadNewsCommentQueryVariables = Exact<{
  topicId: Scalars['ID'];
  type: NewsPostType;
}>;


export type ReadNewsCommentQuery = (
  { __typename?: 'Query' }
  & { readNewsComment?: Maybe<(
    { __typename?: 'NewsPost' }
    & Pick<NewsPost, 'id' | 'type' | 'body'>
  )> }
);

export type ReadNextLabelQueryVariables = Exact<{
  type: Scalars['String'];
}>;


export type ReadNextLabelQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'readNextLabel'>
);

export type ReadOpsClientSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type ReadOpsClientSettingsQuery = (
  { __typename?: 'Query' }
  & { readOpsClientSettings?: Maybe<(
    { __typename?: 'OpsClientSettings' }
    & Pick<OpsClientSettings, 'debtorLabel' | 'clientStartDate' | 'bccReceiver'>
  )> }
);

export type PlantDetailQueryVariables = Exact<{
  plantId: Scalars['ID'];
}>;


export type PlantDetailQuery = (
  { __typename?: 'Query' }
  & { readPlant?: Maybe<(
    { __typename?: 'Plant' }
    & Pick<Plant, 'id' | 'projectId' | 'name' | 'measurementConcept' | 'meteringPointOperatorId' | 'balancingAreaAccountId' | 'supplierAccountId' | 'transmissionSystemOperatorId' | 'isInSetup'>
    & { mainAddress: (
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'streetName' | 'streetNumber' | 'zipCode' | 'city' | 'country'>
    ), addresses: Array<(
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'streetName' | 'streetNumber' | 'zipCode' | 'city' | 'country'>
    )>, kpis: (
      { __typename?: 'PlantKPI' }
      & Pick<PlantKpi, 'units' | 'unitsMembers'>
    ), tenantContracts: Array<(
      { __typename?: 'ContractExcerpt' }
      & Pick<ContractExcerpt, 'id' | 'label' | 'startDate' | 'endDate' | 'meterNumber' | 'name' | 'customerLabel' | 'isOperator' | 'meterType' | 'electricityGenerationOrGasType' | 'contractStatus'>
    )>, buildingContracts: Array<(
      { __typename?: 'ContractExcerpt' }
      & Pick<ContractExcerpt, 'id' | 'label' | 'startDate' | 'endDate' | 'meterNumber' | 'name' | 'customerLabel' | 'isOperator' | 'meterType' | 'electricityGenerationOrGasType' | 'contractStatus'>
    )>, reports: Array<(
      { __typename?: 'Report' }
      & Pick<Report, 'id' | 'type' | 'name' | 'url' | 'createdAt'>
    )>, project?: Maybe<(
      { __typename?: 'ProjectSimple' }
      & Pick<ProjectSimple, 'id' | 'name'>
    )>, meteringPointOperator: (
      { __typename?: 'MeteringPointOperator' }
      & Pick<MeteringPointOperator, 'name' | 'code'>
    ), balancingAreaAccount: (
      { __typename?: 'BalancingAreaAccount' }
      & Pick<BalancingAreaAccount, 'name' | 'eic'>
    ), balancingAreaGroupAccount?: Maybe<(
      { __typename?: 'BalancingAreaGroupAccount' }
      & Pick<BalancingAreaGroupAccount, 'name' | 'eic'>
    )>, supplierAccount: (
      { __typename?: 'Supplier' }
      & Pick<Supplier, 'name' | 'code'>
    ), transmissionSystemOperator: (
      { __typename?: 'TransmissionSystemOperator' }
      & Pick<TransmissionSystemOperator, 'name' | 'eic'>
    ) }
  )> }
);

export type PlantDetailExtendedQueryVariables = Exact<{
  plantId: Scalars['ID'];
}>;


export type PlantDetailExtendedQuery = (
  { __typename?: 'Query' }
  & { readPlant?: Maybe<(
    { __typename?: 'Plant' }
    & Pick<Plant, 'id' | 'projectId' | 'name' | 'measurementConcept' | 'meteringPointOperatorId' | 'balancingAreaAccountId' | 'supplierAccountId' | 'transmissionSystemOperatorId' | 'isInSetup'>
    & { mainAddress: (
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'streetName' | 'streetNumber' | 'zipCode' | 'city' | 'country'>
    ), addresses: Array<(
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'streetName' | 'streetNumber' | 'zipCode' | 'city' | 'country'>
    )>, kpis: (
      { __typename?: 'PlantKPI' }
      & Pick<PlantKpi, 'units' | 'unitsMembers'>
    ), tenantContracts: Array<(
      { __typename?: 'ContractExcerpt' }
      & Pick<ContractExcerpt, 'id' | 'label' | 'startDate' | 'endDate' | 'meterNumber' | 'name' | 'customerLabel' | 'isOperator' | 'meterType' | 'electricityGenerationOrGasType' | 'contractStatus'>
    )>, buildingContracts: Array<(
      { __typename?: 'ContractExcerpt' }
      & Pick<ContractExcerpt, 'id' | 'label' | 'startDate' | 'endDate' | 'meterNumber' | 'name' | 'customerLabel' | 'isOperator' | 'meterType' | 'electricityGenerationOrGasType' | 'contractStatus' | 'contractType'>
    )>, measurementConceptContractsInSetup: Array<(
      { __typename?: 'ContractMeterExcerpt' }
      & Pick<ContractMeterExcerpt, 'id' | 'meterNumber' | 'meterType' | 'msoType' | 'isOperator'>
    )>, reports: Array<(
      { __typename?: 'Report' }
      & Pick<Report, 'id' | 'plantId' | 'type' | 'name' | 'url' | 'createdAt'>
    )>, project?: Maybe<(
      { __typename?: 'ProjectSimple' }
      & Pick<ProjectSimple, 'id' | 'name'>
    )>, meteringPointOperator: (
      { __typename?: 'MeteringPointOperator' }
      & Pick<MeteringPointOperator, 'name' | 'code'>
    ), balancingAreaAccount: (
      { __typename?: 'BalancingAreaAccount' }
      & Pick<BalancingAreaAccount, 'name' | 'eic'>
    ), balancingAreaGroupAccount?: Maybe<(
      { __typename?: 'BalancingAreaGroupAccount' }
      & Pick<BalancingAreaGroupAccount, 'name' | 'eic'>
    )>, supplierAccount: (
      { __typename?: 'Supplier' }
      & Pick<Supplier, 'name' | 'code'>
    ), transmissionSystemOperator: (
      { __typename?: 'TransmissionSystemOperator' }
      & Pick<TransmissionSystemOperator, 'name' | 'eic'>
    ) }
  )> }
);

export type ReadPlantMetersQueryVariables = Exact<{
  plantId: Scalars['ID'];
}>;


export type ReadPlantMetersQuery = (
  { __typename?: 'Query' }
  & { readPlantMeters: (
    { __typename?: 'PlantMeters' }
    & { matchedTenantMeters: Array<(
      { __typename?: 'ContractMeterExcerpt' }
      & ContractMeterExcerptFragment
    )>, unmatchedMeters: Array<(
      { __typename?: 'ContractMeterExcerpt' }
      & ContractMeterExcerptFragment
    )>, matchedBuildingMeters: Array<(
      { __typename?: 'ContractMeterExcerpt' }
      & ContractMeterExcerptFragment
    )> }
  ) }
);

export type ReadPlantReportsQueryVariables = Exact<{
  plantId?: Maybe<Scalars['String']>;
}>;


export type ReadPlantReportsQuery = (
  { __typename?: 'Query' }
  & { readPlantReports: Array<(
    { __typename?: 'Report' }
    & Pick<Report, 'id' | 'type' | 'name' | 'url' | 'createdAt' | 'entityId'>
  )> }
);

export type PlantSimpleQueryVariables = Exact<{
  plantId: Scalars['ID'];
}>;


export type PlantSimpleQuery = (
  { __typename?: 'Query' }
  & { readPlant?: Maybe<(
    { __typename?: 'Plant' }
    & Pick<Plant, 'id' | 'name'>
  )> }
);

export type ReadPlantsAutarkiesQueryVariables = Exact<{
  ids?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type ReadPlantsAutarkiesQuery = (
  { __typename?: 'Query' }
  & { readPlantsAutarkies: Array<(
    { __typename?: 'PlantSimple' }
    & Pick<PlantSimple, 'id' | 'projectId' | 'name'>
    & { autarkies?: Maybe<Array<(
      { __typename?: 'Autarky' }
      & Pick<Autarky, 'startDate' | 'endDate' | 'calculatedValue' | 'userSuppliedValue'>
    )>> }
  )> }
);

export type ReadPlantsKpiQueryVariables = Exact<{ [key: string]: never; }>;


export type ReadPlantsKpiQuery = (
  { __typename?: 'Query' }
  & { readPlants: Array<(
    { __typename?: 'Plant' }
    & { kpis: (
      { __typename?: 'PlantKPI' }
      & Pick<PlantKpi, 'units' | 'unitsMembers'>
    ) }
  )> }
);

export type ReadPlantsSimpleQueryVariables = Exact<{ [key: string]: never; }>;


export type ReadPlantsSimpleQuery = (
  { __typename?: 'Query' }
  & { readPlants: Array<(
    { __typename?: 'Plant' }
    & Pick<Plant, 'id' | 'name' | 'projectId'>
    & { project?: Maybe<(
      { __typename?: 'ProjectSimple' }
      & Pick<ProjectSimple, 'id' | 'name'>
    )> }
  )> }
);

export type ReadPowerSuppliersQueryVariables = Exact<{
  type: Scalars['String'];
}>;


export type ReadPowerSuppliersQuery = (
  { __typename?: 'Query' }
  & { readPowerSuppliers: Array<(
    { __typename?: 'BdewCode' }
    & Pick<BdewCode, 'id' | 'BdewCode' | 'BdewCodeType' | 'BdewCodeFunction' | 'CompanyUID' | 'CompanyName' | 'PostCode' | 'City' | 'Street' | 'Country' | 'CodeContact' | 'CodeContactPhone' | 'CodeContactEmail'>
  )> }
);

export type ReadProjectQueryVariables = Exact<{
  projectId: Scalars['ID'];
}>;


export type ReadProjectQuery = (
  { __typename?: 'Query' }
  & { readProject?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name'>
    & { plants: Array<(
      { __typename?: 'PlantSimple' }
      & Pick<PlantSimple, 'id' | 'name' | 'projectId'>
      & { mainAddress: (
        { __typename?: 'Address' }
        & FullAddressFragment
      ), addresses: Array<(
        { __typename?: 'Address' }
        & FullAddressFragment
      )>, kpis: (
        { __typename?: 'PlantKPI' }
        & Pick<PlantKpi, 'units' | 'unitsMembers'>
      ) }
    )> }
  )> }
);

export type ReadProjectsQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type ReadProjectsQueryQuery = (
  { __typename?: 'Query' }
  & { readProjects: Array<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name'>
    & { plants: Array<(
      { __typename?: 'PlantSimple' }
      & Pick<PlantSimple, 'id' | 'name' | 'projectId'>
      & { mainAddress: (
        { __typename?: 'Address' }
        & FullAddressFragment
      ), addresses: Array<(
        { __typename?: 'Address' }
        & FullAddressFragment
      )>, kpis: (
        { __typename?: 'PlantKPI' }
        & Pick<PlantKpi, 'units' | 'unitsMembers'>
      ) }
    )> }
  )> }
);

export type ReadProjectsQueryAutoCompleteQueryVariables = Exact<{ [key: string]: never; }>;


export type ReadProjectsQueryAutoCompleteQuery = (
  { __typename?: 'Query' }
  & { readProjects: Array<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name'>
  )> }
);

export type ReadReportsQueryVariables = Exact<{
  types?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  plantId?: Maybe<Scalars['String']>;
}>;


export type ReadReportsQuery = (
  { __typename?: 'Query' }
  & { readReports: Array<(
    { __typename?: 'Report' }
    & Pick<Report, 'id' | 'type' | 'name' | 'url' | 'createdAt' | 'entityId'>
  )> }
);

export type ReadSuppliersQueryVariables = Exact<{ [key: string]: never; }>;


export type ReadSuppliersQuery = (
  { __typename?: 'Query' }
  & { readSuppliers: Array<(
    { __typename?: 'Supplier' }
    & Pick<Supplier, 'id' | 'name' | 'eic' | 'code'>
  )> }
);

export type ReadTariffQueryVariables = Exact<{
  tariffId: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type ReadTariffQuery = (
  { __typename?: 'Query' }
  & { readTariff: (
    { __typename?: 'Tariff' }
    & Pick<Tariff, 'nameInternal' | 'nameExternal' | 'powerTax' | 'tax' | 'priceGuaranteeReference' | 'priceGuarantee' | 'minimumDuration' | 'minimumDurationExtension' | 'validityEndDate' | 'validityStartDate' | 'kind'>
    & { noticePeriod: (
      { __typename?: 'NoticePeriod' }
      & Pick<NoticePeriod, 'to' | 'period' | 'count'>
    ), noticePeriodExtension?: Maybe<(
      { __typename?: 'NoticePeriod' }
      & Pick<NoticePeriod, 'to' | 'period' | 'count'>
    )>, priceSheets: Array<(
      { __typename?: 'PriceSheet' }
      & Pick<PriceSheet, 'id' | 'name' | 'startDate' | 'basicPrice' | 'energyPrice' | 'energyPriceLocal' | 'energyPriceResidual' | 'ignorePriceGuarantee'>
    )> }
  ) }
);

export type ReadTariffsQueryVariables = Exact<{ [key: string]: never; }>;


export type ReadTariffsQuery = (
  { __typename?: 'Query' }
  & { readTariffs: Array<(
    { __typename?: 'Tariff' }
    & Pick<Tariff, 'id' | 'nameInternal' | 'nameExternal' | 'powerTax' | 'tax' | 'priceGuarantee' | 'minimumDuration' | 'minimumDurationExtension' | 'priceGuaranteeReference' | 'validityEndDate' | 'validityStartDate' | 'kind'>
    & { noticePeriod: (
      { __typename?: 'NoticePeriod' }
      & Pick<NoticePeriod, 'to' | 'period' | 'count'>
    ), noticePeriodExtension?: Maybe<(
      { __typename?: 'NoticePeriod' }
      & Pick<NoticePeriod, 'to' | 'period' | 'count'>
    )>, priceSheets: Array<(
      { __typename?: 'PriceSheet' }
      & Pick<PriceSheet, 'id' | 'name' | 'startDate' | 'basicPrice' | 'energyPrice' | 'energyPriceLocal' | 'energyPriceResidual' | 'ignorePriceGuarantee' | 'createdAt'>
    )> }
  )> }
);

export type ReadTariffsSimpleQueryVariables = Exact<{ [key: string]: never; }>;


export type ReadTariffsSimpleQuery = (
  { __typename?: 'Query' }
  & { readTariffs: Array<(
    { __typename?: 'Tariff' }
    & Pick<Tariff, 'id' | 'nameInternal' | 'nameExternal' | 'kind' | 'validityStartDate' | 'validityEndDate'>
    & { priceSheets: Array<(
      { __typename?: 'PriceSheet' }
      & Pick<PriceSheet, 'name' | 'startDate'>
    )> }
  )> }
);

export type ReadUnboundMetersByPlantQueryVariables = Exact<{
  plantId?: Maybe<Scalars['ID']>;
  boundStartAt?: Maybe<Scalars['String']>;
  boundEndAt?: Maybe<Scalars['String']>;
}>;


export type ReadUnboundMetersByPlantQuery = (
  { __typename?: 'Query' }
  & { readMeters: Array<(
    { __typename?: 'BoundMeter' }
    & Pick<BoundMeter, 'id' | 'meterNumber' | 'wasEverBound' | 'isBoundInInterval'>
    & { binds?: Maybe<Array<Maybe<(
      { __typename?: 'MeterBind' }
      & Pick<MeterBind, 'validityStart' | 'validityEnd' | 'melo' | 'malo' | 'contractLabel' | 'contractEnd' | 'measuringPointId' | 'consumptionPointId'>
    )>>> }
  )> }
);

export type ReadWorkflowQueryVariables = Exact<{
  workflowId: Scalars['ID'];
}>;


export type ReadWorkflowQuery = (
  { __typename?: 'Query' }
  & { readWorkflow?: Maybe<(
    { __typename?: 'Workflow' }
    & Pick<Workflow, 'id' | 'alias' | 'type' | 'stateNames' | 'done' | 'meta' | 'createdAt' | 'doneAt' | 'updatedAt'>
    & { currentState: (
      { __typename?: 'State' }
      & Pick<State, 'name' | 'parentName' | 'context' | 'transitions'>
    ) }
  )> }
);

export type ReadWorkflowsQueryVariables = Exact<{ [key: string]: never; }>;


export type ReadWorkflowsQuery = (
  { __typename?: 'Query' }
  & { readWorkflows: Array<(
    { __typename?: 'Workflow' }
    & FullWorkflowFragment
  )> }
);

export type ReadWorkspacesQueryVariables = Exact<{ [key: string]: never; }>;


export type ReadWorkspacesQuery = (
  { __typename?: 'Query' }
  & { readWorkspaces: Array<(
    { __typename?: 'Workspace' }
    & Pick<Workspace, 'id' | 'name'>
  )> }
);

export type RejectInvoiceDraftMutationVariables = Exact<{
  invoiceDraftsTuple: Array<InvoiceDraftTupleInput> | InvoiceDraftTupleInput;
  uuid: Scalars['ID'];
}>;


export type RejectInvoiceDraftMutation = (
  { __typename?: 'Mutation' }
  & { rejectInvoiceDraft: (
    { __typename?: 'RejectInvoiceDraftResponse' }
    & { succeeded: Array<Maybe<(
      { __typename?: 'InvoiceDraftTuple' }
      & Pick<InvoiceDraftTuple, 'contractLabel' | 'invoiceDraftId'>
    )>>, failed: Array<Maybe<(
      { __typename?: 'InvoiceDraftTuple' }
      & Pick<InvoiceDraftTuple, 'contractLabel' | 'invoiceDraftId'>
    )>> }
  ) }
);

export type ResetPasswordMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  token: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & { resetPassword?: Maybe<(
    { __typename: 'ResetPasswordSuccess' }
    & Pick<ResetPasswordSuccess, 'success'>
  ) | (
    { __typename: 'WrongPasswordResetFlow' }
    & Pick<WrongPasswordResetFlow, 'message'>
  ) | (
    { __typename: 'NoPasswordResetSessionFound' }
    & Pick<NoPasswordResetSessionFound, 'message'>
  ) | { __typename: 'ServiceNotReachableError' } | (
    { __typename: 'UnknownError' }
    & Pick<UnknownError, 'message'>
  )> }
);

export type RetryInvoiceDocumentCreationMutationVariables = Exact<{
  invoiceDraftsTuple: Array<InvoiceDraftTupleInput> | InvoiceDraftTupleInput;
  uuid: Scalars['ID'];
  accountingType: Scalars['String'];
  accountingName: Scalars['String'];
  invoiceCycle: InvoiceCycle;
}>;


export type RetryInvoiceDocumentCreationMutation = (
  { __typename?: 'Mutation' }
  & { retryInvoiceDocumentCreation?: Maybe<(
    { __typename?: 'AcceptInvoiceDraftResponse' }
    & Pick<AcceptInvoiceDraftResponse, 'jobId' | 'jobName'>
  )> }
);

export type ScheduleRequestFragment = (
  { __typename?: 'ScheduleRequest' }
  & Pick<ScheduleRequest, 'jobId' | 'status' | 'progress' | 'jobName' | 'jobLabel' | 'total' | 'startedOn' | 'finishedOn'>
  & { result: Array<(
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'id' | 'jobId'>
    & { data: (
      { __typename?: 'JSONBox' }
      & Pick<JsonBox, 'id' | 'value'>
    ) | (
      { __typename?: 'OperationError' }
      & Pick<OperationError, 'id' | 'reason'>
    ) }
  )> }
);

export type GetMultipleScheduleRequestsResultsQueryVariables = Exact<{
  jobIds: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetMultipleScheduleRequestsResultsQuery = (
  { __typename?: 'Query' }
  & { getMultipleScheduleRequestsResults?: Maybe<(
    { __typename?: 'JobNotFound' }
    & Pick<JobNotFound, 'message'>
  ) | (
    { __typename?: 'MultipleScheduleRequestsSuccess' }
    & { results: Array<(
      { __typename?: 'ScheduleRequest' }
      & ScheduleRequestFragment
    )> }
  ) | (
    { __typename?: 'ScheduleRequestsError' }
    & Pick<ScheduleRequestsError, 'message'>
  )> }
);

export type GetAllScheduleRequestsResultsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllScheduleRequestsResultsQuery = (
  { __typename?: 'Query' }
  & { getAllScheduleRequestsResults?: Maybe<(
    { __typename?: 'JobNotFound' }
    & Pick<JobNotFound, 'message'>
  ) | (
    { __typename?: 'MultipleScheduleRequestsSuccess' }
    & { results: Array<(
      { __typename?: 'ScheduleRequest' }
      & ScheduleRequestFragment
    )> }
  ) | (
    { __typename?: 'ScheduleRequestsError' }
    & Pick<ScheduleRequestsError, 'message'>
  )> }
);

export type GetScheduleRequestsWorkflowQueryVariables = Exact<{
  workflowId: Scalars['String'];
}>;


export type GetScheduleRequestsWorkflowQuery = (
  { __typename?: 'Query' }
  & { getScheduleRequestsWorkflow: (
    { __typename?: 'JobNotFound' }
    & Pick<JobNotFound, 'message'>
  ) | (
    { __typename?: 'ScheduleRequestSuccess' }
    & { result: (
      { __typename?: 'ScheduleRequest' }
      & ScheduleRequestFragment
    ) }
  ) | (
    { __typename?: 'ScheduleRequestsError' }
    & Pick<ScheduleRequestsError, 'message'>
  ) }
);

export type SearchQueryVariables = Exact<{
  needle: Scalars['String'];
}>;


export type SearchQuery = (
  { __typename?: 'Query' }
  & { search: Array<(
    { __typename?: 'SearchResult' }
    & Pick<SearchResult, 'source' | 'designation' | 'object'>
    & { identifiers: (
      { __typename?: 'SearchIdentifiers' }
      & Pick<SearchIdentifiers, 'sourceId' | 'projectId' | 'plantId' | 'contractId'>
    ) }
  )> }
);

export type SendPasswordResetEmailMutationVariables = Exact<{
  email: Scalars['String'];
  resetPasswordCallbackUrl: Scalars['String'];
}>;


export type SendPasswordResetEmailMutation = (
  { __typename?: 'Mutation' }
  & { sendPasswordResetEmail: (
    { __typename: 'SendPasswordResetEmailSuccess' }
    & Pick<SendPasswordResetEmailSuccess, 'email'>
  ) | (
    { __typename: 'WrongPasswordResetFlow' }
    & Pick<WrongPasswordResetFlow, 'message'>
  ) | { __typename: 'ServiceNotReachableError' } | (
    { __typename: 'UnknownError' }
    & Pick<UnknownError, 'message'>
  ) }
);

export type SendTestMailMutationVariables = Exact<{ [key: string]: never; }>;


export type SendTestMailMutation = (
  { __typename?: 'Mutation' }
  & { sendTestMail: { __typename: 'SuccessResponse' } | (
    { __typename: 'UnknownError' }
    & Pick<UnknownError, 'message'>
  ) }
);

export type SetDownpaymentStartMutationVariables = Exact<{
  workflowId: Scalars['ID'];
  payload: SetDownpaymentStartPayload;
  transitionName: Scalars['String'];
}>;


export type SetDownpaymentStartMutation = (
  { __typename?: 'Mutation' }
  & { setDownpaymentStart?: Maybe<(
    { __typename?: 'Workflow' }
    & Pick<Workflow, 'id' | 'alias' | 'type' | 'stateNames' | 'done' | 'meta' | 'createdAt' | 'doneAt' | 'updatedAt'>
    & { currentState: (
      { __typename?: 'State' }
      & Pick<State, 'name' | 'parentName' | 'context' | 'transitions'>
    ) }
  )> }
);

export type SetPlantAutarkyMutationVariables = Exact<{
  workflowId: Scalars['ID'];
  payload: SetPlantAutarkyPayload;
  transitionName: Scalars['String'];
}>;


export type SetPlantAutarkyMutation = (
  { __typename?: 'Mutation' }
  & { setPlantAutarky?: Maybe<(
    { __typename?: 'Workflow' }
    & Pick<Workflow, 'id' | 'alias' | 'type' | 'stateNames' | 'done' | 'meta' | 'createdAt' | 'doneAt' | 'updatedAt'>
    & { currentState: (
      { __typename?: 'State' }
      & Pick<State, 'name' | 'parentName' | 'context' | 'transitions'>
    ) }
  )> }
);

export type SetCancellationSupplierMutationVariables = Exact<{
  supplier: NetworkOperatorInput;
  letterPayload: CancellationLetterPayloadInput;
  workflowId: Scalars['String'];
}>;


export type SetCancellationSupplierMutation = (
  { __typename?: 'Mutation' }
  & { setCancellationSupplier: (
    { __typename?: 'SupplierChangeWorkflowState' }
    & Pick<SupplierChangeWorkflowState, 'done' | 'type' | 'contractId'>
  ) }
);

export type SetContractSupplierChangeOperatorMutationVariables = Exact<{
  meteringPointOperator: SupplierNetworkOperatorInput;
  gridOperator: SupplierNetworkOperatorInput;
  workflowId: Scalars['String'];
  contractId: Scalars['String'];
  malo: Scalars['String'];
}>;


export type SetContractSupplierChangeOperatorMutation = (
  { __typename?: 'Mutation' }
  & { setContractSupplierChangeOperator: (
    { __typename?: 'SupplierChangeWorkflowState' }
    & Pick<SupplierChangeWorkflowState, 'done' | 'type' | 'contractId'>
  ) }
);

export type StartSupplierChangeTimerMutationVariables = Exact<{
  workflowId: Scalars['String'];
}>;


export type StartSupplierChangeTimerMutation = (
  { __typename?: 'Mutation' }
  & { startSupplierChangeTimer: (
    { __typename?: 'SupplierChangeWorkflowState' }
    & Pick<SupplierChangeWorkflowState, 'done' | 'type' | 'contractId' | 'startedAt'>
  ) }
);

export type UpdateSupplierChangeCancellationContractMutationVariables = Exact<{
  workflowId: Scalars['String'];
  payload: UpdateSupplierChangeContractInput;
}>;


export type UpdateSupplierChangeCancellationContractMutation = (
  { __typename?: 'Mutation' }
  & { updateSupplierChangeCancellationContract: (
    { __typename?: 'SupplierChangeWorkflowState' }
    & Pick<SupplierChangeWorkflowState, 'done' | 'startedAt' | 'contractId' | 'type'>
  ) }
);

export type UpdateSupplierChangeThirdPartyContractMutationVariables = Exact<{
  workflowId: Scalars['String'];
  payload: UpdateSupplierChangeContractInput;
}>;


export type UpdateSupplierChangeThirdPartyContractMutation = (
  { __typename?: 'Mutation' }
  & { updateSupplierChangeThirdPartyContract: (
    { __typename?: 'SupplierChangeWorkflowState' }
    & Pick<SupplierChangeWorkflowState, 'done' | 'startedAt' | 'contractId' | 'type'>
  ) }
);

export type UpdateSupplierChangeLocalContractMutationVariables = Exact<{
  workflowId: Scalars['String'];
  payload: UpdateSupplierChangeLocalContractInput;
}>;


export type UpdateSupplierChangeLocalContractMutation = (
  { __typename?: 'Mutation' }
  & { updateSupplierChangeLocalContract: (
    { __typename?: 'SupplierChangeWorkflowState' }
    & Pick<SupplierChangeWorkflowState, 'done' | 'startedAt' | 'contractId' | 'type'>
  ) }
);

export type UpdateSupplierChangeCancellationWorkflowMutationVariables = Exact<{
  workflowId: Scalars['String'];
  cancelled: Scalars['Boolean'];
  contractId: Scalars['String'];
}>;


export type UpdateSupplierChangeCancellationWorkflowMutation = (
  { __typename?: 'Mutation' }
  & { updateSupplierChangeCancellationWorkflow: (
    { __typename?: 'SupplierChangeWorkflowState' }
    & Pick<SupplierChangeWorkflowState, 'done' | 'startedAt' | 'contractId' | 'type'>
  ) }
);

export type RestartWorkflowMutationVariables = Exact<{
  workflowId: Scalars['String'];
}>;


export type RestartWorkflowMutation = (
  { __typename?: 'Mutation' }
  & { restartWorkflow: (
    { __typename?: 'SupplierChangeWorkflowState' }
    & Pick<SupplierChangeWorkflowState, 'done' | 'contractId' | 'startedAt' | 'type'>
  ) }
);

export type TerminateContractMutationVariables = Exact<{
  contractId: Scalars['ID'];
  contractLabel: Scalars['ID'];
  currentDate: Scalars['Date'];
  termination: ContractTerminationInput;
}>;


export type TerminateContractMutation = (
  { __typename?: 'Mutation' }
  & { terminateContract: (
    { __typename?: 'TerminateContractResult' }
    & Pick<TerminateContractResult, 'workflowId'>
  ) }
);

export type TransitionWorkflowMutationVariables = Exact<{
  workflowId: Scalars['ID'];
  transitionName: Scalars['String'];
}>;


export type TransitionWorkflowMutation = (
  { __typename?: 'Mutation' }
  & { transitionWorkflow?: Maybe<(
    { __typename?: 'Workflow' }
    & Pick<Workflow, 'id' | 'alias' | 'type' | 'stateNames' | 'done' | 'meta' | 'createdAt' | 'doneAt' | 'updatedAt'>
    & { currentState: (
      { __typename?: 'State' }
      & Pick<State, 'name' | 'parentName' | 'context' | 'transitions'>
    ) }
  )> }
);

export type UpdateCardMutationVariables = Exact<{
  id: Scalars['BigInt'];
  patch: CardPatch;
}>;


export type UpdateCardMutation = (
  { __typename?: 'Mutation' }
  & { updateCard?: Maybe<(
    { __typename?: 'UpdateCardPayload' }
    & { card?: Maybe<(
      { __typename?: 'Card' }
      & Pick<Card, 'id' | 'ajv' | 'reductionstartdate' | 'reduction' | 'city' | 'country' | 'createdat' | 'creator' | 'email' | 'isperson' | 'firstname' | 'lastname' | 'companyname' | 'co' | 'contractstartdate' | 'contractenddate' | 'phone' | 'state' | 'street' | 'streetnr' | 'leadorigin' | 'tariffid' | 'workspaceid'>
    )> }
  )> }
);

export type UpdateLaneForCardMutationVariables = Exact<{
  cardId: Scalars['BigInt'];
  patch: LaneCardRelPatch;
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type UpdateLaneForCardMutation = (
  { __typename?: 'Mutation' }
  & { updateLaneCardRel?: Maybe<(
    { __typename?: 'UpdateLaneCardRelPayload' }
    & Pick<UpdateLaneCardRelPayload, 'clientMutationId'>
  )> }
);

export type UpdateAnnualConsumptionMutationVariables = Exact<{
  contractId: Scalars['ID'];
  consumption: UpdateAnnualConsumptionInput;
}>;


export type UpdateAnnualConsumptionMutation = (
  { __typename?: 'Mutation' }
  & { updateAnnualConsumption: (
    { __typename?: 'Consumption' }
    & Pick<Consumption, 'startDate' | 'endDate' | 'value'>
  ) }
);

export type UpdatePasswordMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  currentPassword: Scalars['String'];
  updatePasswordCallbackUrl: Scalars['String'];
}>;


export type UpdatePasswordMutation = (
  { __typename?: 'Mutation' }
  & { updatePassword?: Maybe<(
    { __typename: 'UpdatePasswordSuccess' }
    & Pick<UpdatePasswordSuccess, 'success' | 'message'>
  ) | (
    { __typename: 'WrongCredentialsError' }
    & Pick<WrongCredentialsError, 'message'>
  ) | (
    { __typename: 'EmailNotFound' }
    & Pick<EmailNotFound, 'message'>
  ) | (
    { __typename: 'UpdatePasswordEmailFailed' }
    & Pick<UpdatePasswordEmailFailed, 'message'>
  ) | { __typename: 'ServiceNotReachableError' } | (
    { __typename: 'UnknownError' }
    & Pick<UnknownError, 'message'>
  )> }
);

export type UpdatePriceSheetMutationVariables = Exact<{
  tariffId: Scalars['ID'];
  priceSheetId: Scalars['ID'];
  priceSheet: PriceSheetEdit;
}>;


export type UpdatePriceSheetMutation = (
  { __typename?: 'Mutation' }
  & { updatePriceSheet: (
    { __typename?: 'PriceSheetSuccessResult' }
    & { tariff: (
      { __typename?: 'Tariff' }
      & Pick<Tariff, 'id'>
    ) }
  ) }
);

export const OverviewStepContractsFragmentDoc = gql`
    fragment overviewStepContracts on AccountingRunValidatedContractMeter {
  __typename
  name
  id
  customerLabel
  contractLabel
  type
  plantId
  projectId
  meters {
    meterId
    meterNumber
    errors {
      reason
      date
    }
  }
}
    `;
export const ValidatedConsumptionContractsFragmentDoc = gql`
    fragment validatedConsumptionContracts on AccountingRunValidatedConsumption {
  __typename
  name
  id
  customerLabel
  contractLabel
  plantId
  projectId
  ajv
  relativeAjv
  newConsumption
  relativeVariance
  error
}
    `;
export const ContractExcerptLabelFragmentDoc = gql`
    fragment contractExcerptLabel on ContractExcerpt {
  label
}
    `;
export const ContractMeterExcerptFragmentDoc = gql`
    fragment contractMeterExcerpt on ContractMeterExcerpt {
  id
  meterNumber
  meterPlace
  meterType
  msoType
  contractLabel
  customerLabel
  melo
  malo
  isOperator
  wasEverBound
  name
}
    `;
export const FullAddressFragmentDoc = gql`
    fragment fullAddress on Address {
  streetName
  streetNumber
  zipCode
  city
  country
}
    `;
export const FullPersonFragmentDoc = gql`
    fragment fullPerson on Person {
  name
}
    `;
export const FullTariffFragmentDoc = gql`
    fragment fullTariff on Tariff {
  nameInternal
  nameExternal
  tax
  priceGuarantee
  noticePeriod {
    to
    period
    count
  }
  noticePeriodExtension {
    to
    period
    count
  }
  validityEndDate
  validityStartDate
  priceSheets {
    basicPrice
    energyPrice
    energyPriceLocal
    energyPriceResidual
    startDate
  }
}
    `;
export const FullWorkflowFragmentDoc = gql`
    fragment fullWorkflow on Workflow {
  id
  alias
  type
  stateNames
  done
  meta
  createdAt
  doneAt
  updatedAt
  currentState {
    name
    parentName
    context
    transitions
  }
}
    `;
export const MeterInfoFragmentDoc = gql`
    fragment meterInfo on Meter {
  id
  meterNumber
  meterPlace
  meterType
  melo
  inUse
  msoType
}
    `;
export const TariffNameInternalFragmentDoc = gql`
    fragment tariffNameInternal on Tariff {
  nameInternal
}
    `;
export const NetworkOperatorDataFragmentDoc = gql`
    fragment NetworkOperatorData on NetworkOperator {
  bdewCode
  companyName
  postCode
  city
  street
  country
  email
  phone
}
    `;
export const SettlementDateFieldsFragmentDoc = gql`
    fragment SettlementDateFields on SettlementDate {
  day
  month
}
    `;
export const StandardAccountingFieldsFragmentDoc = gql`
    fragment StandardAccountingFields on StandardAccountingContractExcerpt {
  id
  plantId
  label
  customerLabel
  startDate
  endDate
  accFlowUUID
  settlement {
    ...SettlementDateFields
  }
}
    ${SettlementDateFieldsFragmentDoc}`;
export const ClosingAccountingFieldsFragmentDoc = gql`
    fragment ClosingAccountingFields on ClosingAccountingContractExcerpt {
  id
  label
  customerLabel
  plantId
  startDate
  endDate
  customerName
  invoiceCycle
  settlement {
    ...SettlementDateFields
  }
  accFlowUUID
}
    ${SettlementDateFieldsFragmentDoc}`;
export const ContractSimpleFragmentFragmentDoc = gql`
    fragment contractSimpleFragment on Contract {
  label
  customer {
    id
    label
    person {
      name
      namePrefix
      contactMobile
      contactPhone
      companyFlag
      co
    }
    address {
      streetName
      streetNumber
      zipCode
      city
      country
    }
    addressBilling {
      partnerId
      additionalPartnerId
      name
      namePrefix
      email
      additionalName
      additionalNamePrefix
      streetName
      streetNumber
      zipCode
      city
      country
      companyFlag
      co
    }
    addressShipping {
      name
      namePrefix
      streetName
      streetNumber
      zipCode
      city
      country
      companyFlag
      co
    }
    birthday
  }
}
    `;
export const NkvTemplateFragmentFragmentDoc = gql`
    fragment NkvTemplateFragment on MkvTemplateData {
  customer {
    label
    birthday
    advertisementAccepted
    documentDeliveryMethod
    person {
      namePrefix
      name
      contactMobile
      contactPhone
    }
    address {
      streetName
      streetNumber
      zipCode
      city
      country
    }
    existingCustomer {
      id
    }
    isOperator
  }
  contractType
  status
  tariff {
    id
  }
  loadProfile
}
    `;
export const ScheduleRequestFragmentDoc = gql`
    fragment scheduleRequest on ScheduleRequest {
  jobId
  status
  progress
  jobName
  jobLabel
  total
  startedOn
  finishedOn
  result {
    id
    jobId
    data {
      ... on JSONBox {
        id
        value
      }
      ... on OperationError {
        id
        reason
      }
    }
  }
}
    `;
export const GetAccountingRunWorkflowStateDocument = gql`
    query getAccountingRunWorkflowState($workflowId: String!) {
  getAccountingRunWorkflowState(workflowId: $workflowId) {
    done
    name
    type
    startedAt
    doneAt
    meta {
      accountingType
      paymentPeriodEndAt
      paymentPeriodStartAt
      settlementDay
      settlementMonth
      invoiceCycle
    }
    steps {
      index
      name
      action
      status
      error {
        __typename
        activityType
        message
      }
      active
      result {
        __typename
        ... on OverviewStepResult {
          __typename
          validContracts {
            ...overviewStepContracts
          }
          invalidContracts {
            ...overviewStepContracts
          }
          consumptionValuesWarnings {
            ...validatedConsumptionContracts
          }
          session
          additionalErrors {
            __typename
            type
            sourceId
            contractId
            errorString
          }
          hasSearchableLabels
        }
        ... on AutarkyStepResult {
          __typename
          plants {
            __typename
            plantId
            plantName
            projectId
            plantFullSupply
            autarky
            errorReason
            calculatedAt
            validityStartAt
            validityEndAt
            unroundedAutarky
            warning
            meterId
          }
          userInput {
            __typename
            autarky
            plantId
          }
          workflowType
        }
        ... on DownpaymentStartStepResult {
          __typename
          downpaymentStart
        }
        ... on ValidateContractConsumptionsStepResult {
          __typename
          contracts {
            __typename
            name
            id
            customerLabel
            contractLabel
            type
            plantId
            projectId
            errors
          }
        }
        ... on OpcStepResult {
          __typename
          name
          passed {
            id
          }
          errored {
            id
            error
          }
        }
      }
    }
  }
}
    ${OverviewStepContractsFragmentDoc}
${ValidatedConsumptionContractsFragmentDoc}`;

/**
 * __useGetAccountingRunWorkflowStateQuery__
 *
 * To run a query within a React component, call `useGetAccountingRunWorkflowStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountingRunWorkflowStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountingRunWorkflowStateQuery({
 *   variables: {
 *      workflowId: // value for 'workflowId'
 *   },
 * });
 */
export function useGetAccountingRunWorkflowStateQuery(baseOptions: Apollo.QueryHookOptions<GetAccountingRunWorkflowStateQuery, GetAccountingRunWorkflowStateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountingRunWorkflowStateQuery, GetAccountingRunWorkflowStateQueryVariables>(GetAccountingRunWorkflowStateDocument, options);
      }
export function useGetAccountingRunWorkflowStateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountingRunWorkflowStateQuery, GetAccountingRunWorkflowStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountingRunWorkflowStateQuery, GetAccountingRunWorkflowStateQueryVariables>(GetAccountingRunWorkflowStateDocument, options);
        }
export type GetAccountingRunWorkflowStateQueryHookResult = ReturnType<typeof useGetAccountingRunWorkflowStateQuery>;
export type GetAccountingRunWorkflowStateLazyQueryHookResult = ReturnType<typeof useGetAccountingRunWorkflowStateLazyQuery>;
export type GetAccountingRunWorkflowStateQueryResult = Apollo.QueryResult<GetAccountingRunWorkflowStateQuery, GetAccountingRunWorkflowStateQueryVariables>;
export const GetAccountingRunWorkflowsDocument = gql`
    query getAccountingRunWorkflows($nextPageToken: String, $searchAttributes: AccountingRunSearchAttributes, $sorting: AccountingRunSearchSorting, $pageSize: Int) {
  getAccountingRunWorkflows(nextPageToken: $nextPageToken, searchAttributes: $searchAttributes, sorting: $sorting, pageSize: $pageSize) {
    data {
      WorkflowId
      AccountingRunName
      AccountingRunStartTime
      AccountingRunPeriodStart
      AccountingRunPeriodEnd
      AccountingRunSettlementDay
      AccountingRunStep
      AccountingRunStepState
      AccountingRunState
      AccountingRunSessionId
      AccountingRunDownpaymentStartDate
      AccountingRunOpcName
      AccountingRunEndTime
    }
    totalCount
    nextPageToken
  }
}
    `;

/**
 * __useGetAccountingRunWorkflowsQuery__
 *
 * To run a query within a React component, call `useGetAccountingRunWorkflowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountingRunWorkflowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountingRunWorkflowsQuery({
 *   variables: {
 *      nextPageToken: // value for 'nextPageToken'
 *      searchAttributes: // value for 'searchAttributes'
 *      sorting: // value for 'sorting'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useGetAccountingRunWorkflowsQuery(baseOptions?: Apollo.QueryHookOptions<GetAccountingRunWorkflowsQuery, GetAccountingRunWorkflowsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountingRunWorkflowsQuery, GetAccountingRunWorkflowsQueryVariables>(GetAccountingRunWorkflowsDocument, options);
      }
export function useGetAccountingRunWorkflowsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountingRunWorkflowsQuery, GetAccountingRunWorkflowsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountingRunWorkflowsQuery, GetAccountingRunWorkflowsQueryVariables>(GetAccountingRunWorkflowsDocument, options);
        }
export type GetAccountingRunWorkflowsQueryHookResult = ReturnType<typeof useGetAccountingRunWorkflowsQuery>;
export type GetAccountingRunWorkflowsLazyQueryHookResult = ReturnType<typeof useGetAccountingRunWorkflowsLazyQuery>;
export type GetAccountingRunWorkflowsQueryResult = Apollo.QueryResult<GetAccountingRunWorkflowsQuery, GetAccountingRunWorkflowsQueryVariables>;
export const GetAccountingRunWorkflowsByUuidsDocument = gql`
    query getAccountingRunWorkflowsByUuids($uuids: [String!]!) {
  getAccountingRunWorkflowsByUuids(uuids: $uuids) {
    data {
      WorkflowId
      AccountingRunName
      AccountingRunStartTime
      AccountingRunPeriodStart
      AccountingRunPeriodEnd
      AccountingRunSettlementDay
      AccountingRunStep
      AccountingRunStepState
      AccountingRunState
      AccountingRunSessionId
      AccountingRunDownpaymentStartDate
      AccountingRunOpcName
      AccountingRunEndTime
    }
    totalCount
  }
}
    `;

/**
 * __useGetAccountingRunWorkflowsByUuidsQuery__
 *
 * To run a query within a React component, call `useGetAccountingRunWorkflowsByUuidsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountingRunWorkflowsByUuidsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountingRunWorkflowsByUuidsQuery({
 *   variables: {
 *      uuids: // value for 'uuids'
 *   },
 * });
 */
export function useGetAccountingRunWorkflowsByUuidsQuery(baseOptions: Apollo.QueryHookOptions<GetAccountingRunWorkflowsByUuidsQuery, GetAccountingRunWorkflowsByUuidsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountingRunWorkflowsByUuidsQuery, GetAccountingRunWorkflowsByUuidsQueryVariables>(GetAccountingRunWorkflowsByUuidsDocument, options);
      }
export function useGetAccountingRunWorkflowsByUuidsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountingRunWorkflowsByUuidsQuery, GetAccountingRunWorkflowsByUuidsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountingRunWorkflowsByUuidsQuery, GetAccountingRunWorkflowsByUuidsQueryVariables>(GetAccountingRunWorkflowsByUuidsDocument, options);
        }
export type GetAccountingRunWorkflowsByUuidsQueryHookResult = ReturnType<typeof useGetAccountingRunWorkflowsByUuidsQuery>;
export type GetAccountingRunWorkflowsByUuidsLazyQueryHookResult = ReturnType<typeof useGetAccountingRunWorkflowsByUuidsLazyQuery>;
export type GetAccountingRunWorkflowsByUuidsQueryResult = Apollo.QueryResult<GetAccountingRunWorkflowsByUuidsQuery, GetAccountingRunWorkflowsByUuidsQueryVariables>;
export const TransitionAccountingRunWorkflowDocument = gql`
    mutation transitionAccountingRunWorkflow($workflowId: String!, $action: TransitionAction!) {
  transitionAccountingRunWorkflow(workflowId: $workflowId, action: $action) {
    success
  }
}
    `;
export type TransitionAccountingRunWorkflowMutationFn = Apollo.MutationFunction<TransitionAccountingRunWorkflowMutation, TransitionAccountingRunWorkflowMutationVariables>;

/**
 * __useTransitionAccountingRunWorkflowMutation__
 *
 * To run a mutation, you first call `useTransitionAccountingRunWorkflowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransitionAccountingRunWorkflowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transitionAccountingRunWorkflowMutation, { data, loading, error }] = useTransitionAccountingRunWorkflowMutation({
 *   variables: {
 *      workflowId: // value for 'workflowId'
 *      action: // value for 'action'
 *   },
 * });
 */
export function useTransitionAccountingRunWorkflowMutation(baseOptions?: Apollo.MutationHookOptions<TransitionAccountingRunWorkflowMutation, TransitionAccountingRunWorkflowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TransitionAccountingRunWorkflowMutation, TransitionAccountingRunWorkflowMutationVariables>(TransitionAccountingRunWorkflowDocument, options);
      }
export type TransitionAccountingRunWorkflowMutationHookResult = ReturnType<typeof useTransitionAccountingRunWorkflowMutation>;
export type TransitionAccountingRunWorkflowMutationResult = Apollo.MutationResult<TransitionAccountingRunWorkflowMutation>;
export type TransitionAccountingRunWorkflowMutationOptions = Apollo.BaseMutationOptions<TransitionAccountingRunWorkflowMutation, TransitionAccountingRunWorkflowMutationVariables>;
export const SetAccountingRunPlantAutarkyDocument = gql`
    mutation setAccountingRunPlantAutarky($workflowId: String!, $payload: SetPlantAutarkyPayload!) {
  setAccountingRunPlantAutarky(workflowId: $workflowId, payload: $payload) {
    success
  }
}
    `;
export type SetAccountingRunPlantAutarkyMutationFn = Apollo.MutationFunction<SetAccountingRunPlantAutarkyMutation, SetAccountingRunPlantAutarkyMutationVariables>;

/**
 * __useSetAccountingRunPlantAutarkyMutation__
 *
 * To run a mutation, you first call `useSetAccountingRunPlantAutarkyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAccountingRunPlantAutarkyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAccountingRunPlantAutarkyMutation, { data, loading, error }] = useSetAccountingRunPlantAutarkyMutation({
 *   variables: {
 *      workflowId: // value for 'workflowId'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useSetAccountingRunPlantAutarkyMutation(baseOptions?: Apollo.MutationHookOptions<SetAccountingRunPlantAutarkyMutation, SetAccountingRunPlantAutarkyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetAccountingRunPlantAutarkyMutation, SetAccountingRunPlantAutarkyMutationVariables>(SetAccountingRunPlantAutarkyDocument, options);
      }
export type SetAccountingRunPlantAutarkyMutationHookResult = ReturnType<typeof useSetAccountingRunPlantAutarkyMutation>;
export type SetAccountingRunPlantAutarkyMutationResult = Apollo.MutationResult<SetAccountingRunPlantAutarkyMutation>;
export type SetAccountingRunPlantAutarkyMutationOptions = Apollo.BaseMutationOptions<SetAccountingRunPlantAutarkyMutation, SetAccountingRunPlantAutarkyMutationVariables>;
export const SetAccountingRunDownpaymentStartDocument = gql`
    mutation setAccountingRunDownpaymentStart($workflowId: String!, $downpaymentStartDate: Date!) {
  setAccountingRunDownpaymentStart(workflowId: $workflowId, downpaymentStartDate: $downpaymentStartDate) {
    success
  }
}
    `;
export type SetAccountingRunDownpaymentStartMutationFn = Apollo.MutationFunction<SetAccountingRunDownpaymentStartMutation, SetAccountingRunDownpaymentStartMutationVariables>;

/**
 * __useSetAccountingRunDownpaymentStartMutation__
 *
 * To run a mutation, you first call `useSetAccountingRunDownpaymentStartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAccountingRunDownpaymentStartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAccountingRunDownpaymentStartMutation, { data, loading, error }] = useSetAccountingRunDownpaymentStartMutation({
 *   variables: {
 *      workflowId: // value for 'workflowId'
 *      downpaymentStartDate: // value for 'downpaymentStartDate'
 *   },
 * });
 */
export function useSetAccountingRunDownpaymentStartMutation(baseOptions?: Apollo.MutationHookOptions<SetAccountingRunDownpaymentStartMutation, SetAccountingRunDownpaymentStartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetAccountingRunDownpaymentStartMutation, SetAccountingRunDownpaymentStartMutationVariables>(SetAccountingRunDownpaymentStartDocument, options);
      }
export type SetAccountingRunDownpaymentStartMutationHookResult = ReturnType<typeof useSetAccountingRunDownpaymentStartMutation>;
export type SetAccountingRunDownpaymentStartMutationResult = Apollo.MutationResult<SetAccountingRunDownpaymentStartMutation>;
export type SetAccountingRunDownpaymentStartMutationOptions = Apollo.BaseMutationOptions<SetAccountingRunDownpaymentStartMutation, SetAccountingRunDownpaymentStartMutationVariables>;
export const DeleteAccountingRunWorkflowDocument = gql`
    mutation deleteAccountingRunWorkflow($workflowId: String!) {
  deleteAccountingRunWorkflow(workflowId: $workflowId) {
    success
  }
}
    `;
export type DeleteAccountingRunWorkflowMutationFn = Apollo.MutationFunction<DeleteAccountingRunWorkflowMutation, DeleteAccountingRunWorkflowMutationVariables>;

/**
 * __useDeleteAccountingRunWorkflowMutation__
 *
 * To run a mutation, you first call `useDeleteAccountingRunWorkflowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountingRunWorkflowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountingRunWorkflowMutation, { data, loading, error }] = useDeleteAccountingRunWorkflowMutation({
 *   variables: {
 *      workflowId: // value for 'workflowId'
 *   },
 * });
 */
export function useDeleteAccountingRunWorkflowMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAccountingRunWorkflowMutation, DeleteAccountingRunWorkflowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAccountingRunWorkflowMutation, DeleteAccountingRunWorkflowMutationVariables>(DeleteAccountingRunWorkflowDocument, options);
      }
export type DeleteAccountingRunWorkflowMutationHookResult = ReturnType<typeof useDeleteAccountingRunWorkflowMutation>;
export type DeleteAccountingRunWorkflowMutationResult = Apollo.MutationResult<DeleteAccountingRunWorkflowMutation>;
export type DeleteAccountingRunWorkflowMutationOptions = Apollo.BaseMutationOptions<DeleteAccountingRunWorkflowMutation, DeleteAccountingRunWorkflowMutationVariables>;
export const RestartAccountingRunWorkflowDocument = gql`
    mutation restartAccountingRunWorkflow($workflowId: String!) {
  restartAccountingRunWorkflow(workflowId: $workflowId) {
    success
  }
}
    `;
export type RestartAccountingRunWorkflowMutationFn = Apollo.MutationFunction<RestartAccountingRunWorkflowMutation, RestartAccountingRunWorkflowMutationVariables>;

/**
 * __useRestartAccountingRunWorkflowMutation__
 *
 * To run a mutation, you first call `useRestartAccountingRunWorkflowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestartAccountingRunWorkflowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restartAccountingRunWorkflowMutation, { data, loading, error }] = useRestartAccountingRunWorkflowMutation({
 *   variables: {
 *      workflowId: // value for 'workflowId'
 *   },
 * });
 */
export function useRestartAccountingRunWorkflowMutation(baseOptions?: Apollo.MutationHookOptions<RestartAccountingRunWorkflowMutation, RestartAccountingRunWorkflowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestartAccountingRunWorkflowMutation, RestartAccountingRunWorkflowMutationVariables>(RestartAccountingRunWorkflowDocument, options);
      }
export type RestartAccountingRunWorkflowMutationHookResult = ReturnType<typeof useRestartAccountingRunWorkflowMutation>;
export type RestartAccountingRunWorkflowMutationResult = Apollo.MutationResult<RestartAccountingRunWorkflowMutation>;
export type RestartAccountingRunWorkflowMutationOptions = Apollo.BaseMutationOptions<RestartAccountingRunWorkflowMutation, RestartAccountingRunWorkflowMutationVariables>;
export const AcceptInvoiceDraftDocument = gql`
    mutation acceptInvoiceDraft($invoiceDraftsTuple: [InvoiceDraftTupleInput!]!, $uuid: ID!, $accountingType: String!, $accountingName: String!, $invoiceCycle: InvoiceCycle!) {
  acceptInvoiceDraft(accountingType: $accountingType, invoiceDraftsTuple: $invoiceDraftsTuple, uuid: $uuid, opcName: $accountingName, invoiceCycle: $invoiceCycle) {
    jobId
    jobName
  }
}
    `;
export type AcceptInvoiceDraftMutationFn = Apollo.MutationFunction<AcceptInvoiceDraftMutation, AcceptInvoiceDraftMutationVariables>;

/**
 * __useAcceptInvoiceDraftMutation__
 *
 * To run a mutation, you first call `useAcceptInvoiceDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptInvoiceDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptInvoiceDraftMutation, { data, loading, error }] = useAcceptInvoiceDraftMutation({
 *   variables: {
 *      invoiceDraftsTuple: // value for 'invoiceDraftsTuple'
 *      uuid: // value for 'uuid'
 *      accountingType: // value for 'accountingType'
 *      accountingName: // value for 'accountingName'
 *      invoiceCycle: // value for 'invoiceCycle'
 *   },
 * });
 */
export function useAcceptInvoiceDraftMutation(baseOptions?: Apollo.MutationHookOptions<AcceptInvoiceDraftMutation, AcceptInvoiceDraftMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptInvoiceDraftMutation, AcceptInvoiceDraftMutationVariables>(AcceptInvoiceDraftDocument, options);
      }
export type AcceptInvoiceDraftMutationHookResult = ReturnType<typeof useAcceptInvoiceDraftMutation>;
export type AcceptInvoiceDraftMutationResult = Apollo.MutationResult<AcceptInvoiceDraftMutation>;
export type AcceptInvoiceDraftMutationOptions = Apollo.BaseMutationOptions<AcceptInvoiceDraftMutation, AcceptInvoiceDraftMutationVariables>;
export const AccountingsInTimespanDocument = gql`
    query accountingsInTimespan($plantIds: [ID!], $contractIds: [ID!], $startDate: Date!, $endDate: Date!) {
  accountingsInTimespan(plantIds: $plantIds, contractIds: $contractIds, startDate: $startDate, endDate: $endDate) {
    contractId
    status
    accFlowUUID
  }
}
    `;

/**
 * __useAccountingsInTimespanQuery__
 *
 * To run a query within a React component, call `useAccountingsInTimespanQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountingsInTimespanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountingsInTimespanQuery({
 *   variables: {
 *      plantIds: // value for 'plantIds'
 *      contractIds: // value for 'contractIds'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useAccountingsInTimespanQuery(baseOptions: Apollo.QueryHookOptions<AccountingsInTimespanQuery, AccountingsInTimespanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountingsInTimespanQuery, AccountingsInTimespanQueryVariables>(AccountingsInTimespanDocument, options);
      }
export function useAccountingsInTimespanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountingsInTimespanQuery, AccountingsInTimespanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountingsInTimespanQuery, AccountingsInTimespanQueryVariables>(AccountingsInTimespanDocument, options);
        }
export type AccountingsInTimespanQueryHookResult = ReturnType<typeof useAccountingsInTimespanQuery>;
export type AccountingsInTimespanLazyQueryHookResult = ReturnType<typeof useAccountingsInTimespanLazyQuery>;
export type AccountingsInTimespanQueryResult = Apollo.QueryResult<AccountingsInTimespanQuery, AccountingsInTimespanQueryVariables>;
export const AssignCardToLaneDocument = gql`
    mutation assignCardToLane($input: CreateLaneCardRelInput!) {
  createLaneCardRel(input: $input) {
    clientMutationId
  }
}
    `;
export type AssignCardToLaneMutationFn = Apollo.MutationFunction<AssignCardToLaneMutation, AssignCardToLaneMutationVariables>;

/**
 * __useAssignCardToLaneMutation__
 *
 * To run a mutation, you first call `useAssignCardToLaneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignCardToLaneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignCardToLaneMutation, { data, loading, error }] = useAssignCardToLaneMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignCardToLaneMutation(baseOptions?: Apollo.MutationHookOptions<AssignCardToLaneMutation, AssignCardToLaneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignCardToLaneMutation, AssignCardToLaneMutationVariables>(AssignCardToLaneDocument, options);
      }
export type AssignCardToLaneMutationHookResult = ReturnType<typeof useAssignCardToLaneMutation>;
export type AssignCardToLaneMutationResult = Apollo.MutationResult<AssignCardToLaneMutation>;
export type AssignCardToLaneMutationOptions = Apollo.BaseMutationOptions<AssignCardToLaneMutation, AssignCardToLaneMutationVariables>;
export const CancelInvoiceDocument = gql`
    mutation CancelInvoice($contractNumber: String!, $contractId: String!, $bookingName: String!, $opcName: String!, $isPaid: Boolean!) {
  cancelInvoice(contractNumber: $contractNumber, contractId: $contractId, bookingName: $bookingName, opcName: $opcName, isPaid: $isPaid) {
    __typename
    contractNumber
    bookingName
    opcName
    reversalName
  }
}
    `;
export type CancelInvoiceMutationFn = Apollo.MutationFunction<CancelInvoiceMutation, CancelInvoiceMutationVariables>;

/**
 * __useCancelInvoiceMutation__
 *
 * To run a mutation, you first call `useCancelInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelInvoiceMutation, { data, loading, error }] = useCancelInvoiceMutation({
 *   variables: {
 *      contractNumber: // value for 'contractNumber'
 *      contractId: // value for 'contractId'
 *      bookingName: // value for 'bookingName'
 *      opcName: // value for 'opcName'
 *      isPaid: // value for 'isPaid'
 *   },
 * });
 */
export function useCancelInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<CancelInvoiceMutation, CancelInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelInvoiceMutation, CancelInvoiceMutationVariables>(CancelInvoiceDocument, options);
      }
export type CancelInvoiceMutationHookResult = ReturnType<typeof useCancelInvoiceMutation>;
export type CancelInvoiceMutationResult = Apollo.MutationResult<CancelInvoiceMutation>;
export type CancelInvoiceMutationOptions = Apollo.BaseMutationOptions<CancelInvoiceMutation, CancelInvoiceMutationVariables>;
export const Check2FAuthDocument = gql`
    mutation check2FAuth($id: String!, $token: String!) {
  check2FAuth(id: $id, token: $token) {
    __typename
    ... on User {
      id
      firstName
      lastName
      company {
        id
        secondaryColor
        primaryColor
        logo
      }
    }
    ... on TwoFactorSessionInvalid {
      message
    }
    ... on TwoFactorSessionExpired {
      message
    }
    ... on ServiceNotReachableError {
      message
    }
    ... on UnknownError {
      message
    }
  }
}
    `;
export type Check2FAuthMutationFn = Apollo.MutationFunction<Check2FAuthMutation, Check2FAuthMutationVariables>;

/**
 * __useCheck2FAuthMutation__
 *
 * To run a mutation, you first call `useCheck2FAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheck2FAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [check2FAuthMutation, { data, loading, error }] = useCheck2FAuthMutation({
 *   variables: {
 *      id: // value for 'id'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useCheck2FAuthMutation(baseOptions?: Apollo.MutationHookOptions<Check2FAuthMutation, Check2FAuthMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Check2FAuthMutation, Check2FAuthMutationVariables>(Check2FAuthDocument, options);
      }
export type Check2FAuthMutationHookResult = ReturnType<typeof useCheck2FAuthMutation>;
export type Check2FAuthMutationResult = Apollo.MutationResult<Check2FAuthMutation>;
export type Check2FAuthMutationOptions = Apollo.BaseMutationOptions<Check2FAuthMutation, Check2FAuthMutationVariables>;
export const CheckDeletePriceSheetDocument = gql`
    query checkDeletePriceSheet($id: ID!) {
  checkDeletePriceSheet(id: $id) {
    success
  }
}
    `;

/**
 * __useCheckDeletePriceSheetQuery__
 *
 * To run a query within a React component, call `useCheckDeletePriceSheetQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckDeletePriceSheetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckDeletePriceSheetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCheckDeletePriceSheetQuery(baseOptions: Apollo.QueryHookOptions<CheckDeletePriceSheetQuery, CheckDeletePriceSheetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckDeletePriceSheetQuery, CheckDeletePriceSheetQueryVariables>(CheckDeletePriceSheetDocument, options);
      }
export function useCheckDeletePriceSheetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckDeletePriceSheetQuery, CheckDeletePriceSheetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckDeletePriceSheetQuery, CheckDeletePriceSheetQueryVariables>(CheckDeletePriceSheetDocument, options);
        }
export type CheckDeletePriceSheetQueryHookResult = ReturnType<typeof useCheckDeletePriceSheetQuery>;
export type CheckDeletePriceSheetLazyQueryHookResult = ReturnType<typeof useCheckDeletePriceSheetLazyQuery>;
export type CheckDeletePriceSheetQueryResult = Apollo.QueryResult<CheckDeletePriceSheetQuery, CheckDeletePriceSheetQueryVariables>;
export const CheckResetPasswordTokenDocument = gql`
    mutation checkResetPasswordToken($token: String!) {
  checkResetPasswordToken(token: $token) {
    __typename
    ... on UnknownError {
      message
    }
    ... on NoPasswordResetSessionFound {
      message
    }
    ... on WrongPasswordResetFlow {
      message
    }
    ... on CheckResetPasswordTokenSuccess {
      success
      message
    }
  }
}
    `;
export type CheckResetPasswordTokenMutationFn = Apollo.MutationFunction<CheckResetPasswordTokenMutation, CheckResetPasswordTokenMutationVariables>;

/**
 * __useCheckResetPasswordTokenMutation__
 *
 * To run a mutation, you first call `useCheckResetPasswordTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckResetPasswordTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkResetPasswordTokenMutation, { data, loading, error }] = useCheckResetPasswordTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useCheckResetPasswordTokenMutation(baseOptions?: Apollo.MutationHookOptions<CheckResetPasswordTokenMutation, CheckResetPasswordTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckResetPasswordTokenMutation, CheckResetPasswordTokenMutationVariables>(CheckResetPasswordTokenDocument, options);
      }
export type CheckResetPasswordTokenMutationHookResult = ReturnType<typeof useCheckResetPasswordTokenMutation>;
export type CheckResetPasswordTokenMutationResult = Apollo.MutationResult<CheckResetPasswordTokenMutation>;
export type CheckResetPasswordTokenMutationOptions = Apollo.BaseMutationOptions<CheckResetPasswordTokenMutation, CheckResetPasswordTokenMutationVariables>;
export const CreateCardDocument = gql`
    mutation createCard($input: CreateCardInput!) {
  createCard(input: $input) {
    card {
      id
      ajv
      reductionstartdate
      reduction
      city
      country
      createdat
      creator
      email
      isperson
      prefix
      firstname
      lastname
      contractstartdate
      contractenddate
      companyname
      co
      phone
      state
      street
      streetnr
      plantid
      workspaceid
      tariffid
      meterid
      notes
      shippingaddressstreet
      shippingaddressstreetnr
      shippingaddresszipcode
      shippingaddresscity
      leadorigin
    }
  }
}
    `;
export type CreateCardMutationFn = Apollo.MutationFunction<CreateCardMutation, CreateCardMutationVariables>;

/**
 * __useCreateCardMutation__
 *
 * To run a mutation, you first call `useCreateCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCardMutation, { data, loading, error }] = useCreateCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCardMutation(baseOptions?: Apollo.MutationHookOptions<CreateCardMutation, CreateCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCardMutation, CreateCardMutationVariables>(CreateCardDocument, options);
      }
export type CreateCardMutationHookResult = ReturnType<typeof useCreateCardMutation>;
export type CreateCardMutationResult = Apollo.MutationResult<CreateCardMutation>;
export type CreateCardMutationOptions = Apollo.BaseMutationOptions<CreateCardMutation, CreateCardMutationVariables>;
export const CreateAccountingRunWorkflowDocument = gql`
    mutation createAccountingRunWorkflow($payload: AccountingWorkflowPayload!) {
  createAccountingRunWorkflow(payload: $payload) {
    id
  }
}
    `;
export type CreateAccountingRunWorkflowMutationFn = Apollo.MutationFunction<CreateAccountingRunWorkflowMutation, CreateAccountingRunWorkflowMutationVariables>;

/**
 * __useCreateAccountingRunWorkflowMutation__
 *
 * To run a mutation, you first call `useCreateAccountingRunWorkflowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountingRunWorkflowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountingRunWorkflowMutation, { data, loading, error }] = useCreateAccountingRunWorkflowMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useCreateAccountingRunWorkflowMutation(baseOptions?: Apollo.MutationHookOptions<CreateAccountingRunWorkflowMutation, CreateAccountingRunWorkflowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAccountingRunWorkflowMutation, CreateAccountingRunWorkflowMutationVariables>(CreateAccountingRunWorkflowDocument, options);
      }
export type CreateAccountingRunWorkflowMutationHookResult = ReturnType<typeof useCreateAccountingRunWorkflowMutation>;
export type CreateAccountingRunWorkflowMutationResult = Apollo.MutationResult<CreateAccountingRunWorkflowMutation>;
export type CreateAccountingRunWorkflowMutationOptions = Apollo.BaseMutationOptions<CreateAccountingRunWorkflowMutation, CreateAccountingRunWorkflowMutationVariables>;
export const CreateBookingsBatchedDocument = gql`
    mutation createBookingsBatched($bookings: [CreateBookingsBatchedInput!]!, $fileName: String) {
  createBookingsBatched(bookings: $bookings, fileName: $fileName) {
    __typename
    ... on CreateBookingsBatchedSuccess {
      succeeded {
        contractId
        date
        value
        type
      }
      info {
        contractLabel
        bookingDate
        contractEndDate
        contractStartDate
      }
    }
    ... on CreateBookingsBatchedError {
      failed {
        error
        message
        contractLabel
      }
      info {
        contractLabel
        bookingDate
        contractEndDate
        contractStartDate
      }
    }
  }
}
    `;
export type CreateBookingsBatchedMutationFn = Apollo.MutationFunction<CreateBookingsBatchedMutation, CreateBookingsBatchedMutationVariables>;

/**
 * __useCreateBookingsBatchedMutation__
 *
 * To run a mutation, you first call `useCreateBookingsBatchedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBookingsBatchedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBookingsBatchedMutation, { data, loading, error }] = useCreateBookingsBatchedMutation({
 *   variables: {
 *      bookings: // value for 'bookings'
 *      fileName: // value for 'fileName'
 *   },
 * });
 */
export function useCreateBookingsBatchedMutation(baseOptions?: Apollo.MutationHookOptions<CreateBookingsBatchedMutation, CreateBookingsBatchedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBookingsBatchedMutation, CreateBookingsBatchedMutationVariables>(CreateBookingsBatchedDocument, options);
      }
export type CreateBookingsBatchedMutationHookResult = ReturnType<typeof useCreateBookingsBatchedMutation>;
export type CreateBookingsBatchedMutationResult = Apollo.MutationResult<CreateBookingsBatchedMutation>;
export type CreateBookingsBatchedMutationOptions = Apollo.BaseMutationOptions<CreateBookingsBatchedMutation, CreateBookingsBatchedMutationVariables>;
export const CreateClientReportDocument = gql`
    mutation createClientReport($salesStartDate: DateTime!, $salesEndDate: DateTime!, $accountingPeriodStartDate: DateTime!, $accountingPeriodEndDate: DateTime!) {
  createClientReport(salesStartDate: $salesStartDate, salesEndDate: $salesEndDate, accountingPeriodStartDate: $accountingPeriodStartDate, accountingPeriodEndDate: $accountingPeriodEndDate) {
    ... on CreateClientReportSuccess {
      success
    }
    ... on UnknownError {
      message
    }
    ... on RateLimitReached {
      message
    }
  }
}
    `;
export type CreateClientReportMutationFn = Apollo.MutationFunction<CreateClientReportMutation, CreateClientReportMutationVariables>;

/**
 * __useCreateClientReportMutation__
 *
 * To run a mutation, you first call `useCreateClientReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientReportMutation, { data, loading, error }] = useCreateClientReportMutation({
 *   variables: {
 *      salesStartDate: // value for 'salesStartDate'
 *      salesEndDate: // value for 'salesEndDate'
 *      accountingPeriodStartDate: // value for 'accountingPeriodStartDate'
 *      accountingPeriodEndDate: // value for 'accountingPeriodEndDate'
 *   },
 * });
 */
export function useCreateClientReportMutation(baseOptions?: Apollo.MutationHookOptions<CreateClientReportMutation, CreateClientReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateClientReportMutation, CreateClientReportMutationVariables>(CreateClientReportDocument, options);
      }
export type CreateClientReportMutationHookResult = ReturnType<typeof useCreateClientReportMutation>;
export type CreateClientReportMutationResult = Apollo.MutationResult<CreateClientReportMutation>;
export type CreateClientReportMutationOptions = Apollo.BaseMutationOptions<CreateClientReportMutation, CreateClientReportMutationVariables>;
export const CreateCommunicationDocument = gql`
    mutation createCommunication($contractId: ID!, $communication: CustomerCommunicationInput!) {
  createCommunication(contractId: $contractId, communication: $communication) {
    id
    message
    date
    title
    communicatedBy
  }
}
    `;
export type CreateCommunicationMutationFn = Apollo.MutationFunction<CreateCommunicationMutation, CreateCommunicationMutationVariables>;

/**
 * __useCreateCommunicationMutation__
 *
 * To run a mutation, you first call `useCreateCommunicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommunicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommunicationMutation, { data, loading, error }] = useCreateCommunicationMutation({
 *   variables: {
 *      contractId: // value for 'contractId'
 *      communication: // value for 'communication'
 *   },
 * });
 */
export function useCreateCommunicationMutation(baseOptions?: Apollo.MutationHookOptions<CreateCommunicationMutation, CreateCommunicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCommunicationMutation, CreateCommunicationMutationVariables>(CreateCommunicationDocument, options);
      }
export type CreateCommunicationMutationHookResult = ReturnType<typeof useCreateCommunicationMutation>;
export type CreateCommunicationMutationResult = Apollo.MutationResult<CreateCommunicationMutation>;
export type CreateCommunicationMutationOptions = Apollo.BaseMutationOptions<CreateCommunicationMutation, CreateCommunicationMutationVariables>;
export const CreateContractsBatchedDocument = gql`
    mutation createContractsBatched($contracts: [CreateContractsBatchedInput!]!) {
  createContractsBatched(contracts: $contracts) {
    __typename
    ... on ContractsBatchedSuccess {
      jobId
      jobName
      status
      progress
      total
    }
    ... on CreateContractsBatchedError {
      failedContracts {
        customerLabel
        contractLabel
        message
        error
      }
      message
    }
  }
}
    `;
export type CreateContractsBatchedMutationFn = Apollo.MutationFunction<CreateContractsBatchedMutation, CreateContractsBatchedMutationVariables>;

/**
 * __useCreateContractsBatchedMutation__
 *
 * To run a mutation, you first call `useCreateContractsBatchedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractsBatchedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractsBatchedMutation, { data, loading, error }] = useCreateContractsBatchedMutation({
 *   variables: {
 *      contracts: // value for 'contracts'
 *   },
 * });
 */
export function useCreateContractsBatchedMutation(baseOptions?: Apollo.MutationHookOptions<CreateContractsBatchedMutation, CreateContractsBatchedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateContractsBatchedMutation, CreateContractsBatchedMutationVariables>(CreateContractsBatchedDocument, options);
      }
export type CreateContractsBatchedMutationHookResult = ReturnType<typeof useCreateContractsBatchedMutation>;
export type CreateContractsBatchedMutationResult = Apollo.MutationResult<CreateContractsBatchedMutation>;
export type CreateContractsBatchedMutationOptions = Apollo.BaseMutationOptions<CreateContractsBatchedMutation, CreateContractsBatchedMutationVariables>;
export const CreateETaxReportDocument = gql`
    mutation createETaxReport($year: Int!, $fallbackAutarky: Float!) {
  createETaxReport(year: $year, fallbackAutarky: $fallbackAutarky) {
    __typename
    ... on CreateETaxReportSuccess {
      success
    }
    ... on UnknownError {
      message
    }
    ... on RateLimitReached {
      message
    }
  }
}
    `;
export type CreateETaxReportMutationFn = Apollo.MutationFunction<CreateETaxReportMutation, CreateETaxReportMutationVariables>;

/**
 * __useCreateETaxReportMutation__
 *
 * To run a mutation, you first call `useCreateETaxReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateETaxReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createETaxReportMutation, { data, loading, error }] = useCreateETaxReportMutation({
 *   variables: {
 *      year: // value for 'year'
 *      fallbackAutarky: // value for 'fallbackAutarky'
 *   },
 * });
 */
export function useCreateETaxReportMutation(baseOptions?: Apollo.MutationHookOptions<CreateETaxReportMutation, CreateETaxReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateETaxReportMutation, CreateETaxReportMutationVariables>(CreateETaxReportDocument, options);
      }
export type CreateETaxReportMutationHookResult = ReturnType<typeof useCreateETaxReportMutation>;
export type CreateETaxReportMutationResult = Apollo.MutationResult<CreateETaxReportMutation>;
export type CreateETaxReportMutationOptions = Apollo.BaseMutationOptions<CreateETaxReportMutation, CreateETaxReportMutationVariables>;
export const CreateMeasurementContractSessionDocument = gql`
    mutation createMeasurementContractSession {
  session: createMeasurementContractSession
}
    `;
export type CreateMeasurementContractSessionMutationFn = Apollo.MutationFunction<CreateMeasurementContractSessionMutation, CreateMeasurementContractSessionMutationVariables>;

/**
 * __useCreateMeasurementContractSessionMutation__
 *
 * To run a mutation, you first call `useCreateMeasurementContractSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMeasurementContractSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMeasurementContractSessionMutation, { data, loading, error }] = useCreateMeasurementContractSessionMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateMeasurementContractSessionMutation(baseOptions?: Apollo.MutationHookOptions<CreateMeasurementContractSessionMutation, CreateMeasurementContractSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMeasurementContractSessionMutation, CreateMeasurementContractSessionMutationVariables>(CreateMeasurementContractSessionDocument, options);
      }
export type CreateMeasurementContractSessionMutationHookResult = ReturnType<typeof useCreateMeasurementContractSessionMutation>;
export type CreateMeasurementContractSessionMutationResult = Apollo.MutationResult<CreateMeasurementContractSessionMutation>;
export type CreateMeasurementContractSessionMutationOptions = Apollo.BaseMutationOptions<CreateMeasurementContractSessionMutation, CreateMeasurementContractSessionMutationVariables>;
export const CreateMeterReadingDocument = gql`
    mutation createMeterReading($meterId: ID!, $meterReading: CreateMeterReadingInput!) {
  createMeterReading(meterId: $meterId, meterReading: $meterReading) {
    date
    value
    reason
    hint
    valueStatus
    obis
  }
}
    `;
export type CreateMeterReadingMutationFn = Apollo.MutationFunction<CreateMeterReadingMutation, CreateMeterReadingMutationVariables>;

/**
 * __useCreateMeterReadingMutation__
 *
 * To run a mutation, you first call `useCreateMeterReadingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMeterReadingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMeterReadingMutation, { data, loading, error }] = useCreateMeterReadingMutation({
 *   variables: {
 *      meterId: // value for 'meterId'
 *      meterReading: // value for 'meterReading'
 *   },
 * });
 */
export function useCreateMeterReadingMutation(baseOptions?: Apollo.MutationHookOptions<CreateMeterReadingMutation, CreateMeterReadingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMeterReadingMutation, CreateMeterReadingMutationVariables>(CreateMeterReadingDocument, options);
      }
export type CreateMeterReadingMutationHookResult = ReturnType<typeof useCreateMeterReadingMutation>;
export type CreateMeterReadingMutationResult = Apollo.MutationResult<CreateMeterReadingMutation>;
export type CreateMeterReadingMutationOptions = Apollo.BaseMutationOptions<CreateMeterReadingMutation, CreateMeterReadingMutationVariables>;
export const CreateMeterReadingsBatchedDocument = gql`
    mutation createMeterReadingsBatched($meterReadings: [CreateMeterReadingsBatchedInput!]!) {
  createMeterReadingsBatched(meterReadings: $meterReadings) {
    __typename
    ... on CreateMeterReadingsBatchedMixedResult {
      succeeded {
        id
        meterNumber
        value
        date
      }
      failed {
        id
        meterNumber
        error
        value
        date
      }
    }
    ... on DuplicateMeterReadingsError {
      message
      pointer
      retryable
      duplicateReadings {
        id
        meterNumber
        date
        value
      }
      multipleReadings {
        id
        meterNumber
        date
        value
      }
    }
  }
}
    `;
export type CreateMeterReadingsBatchedMutationFn = Apollo.MutationFunction<CreateMeterReadingsBatchedMutation, CreateMeterReadingsBatchedMutationVariables>;

/**
 * __useCreateMeterReadingsBatchedMutation__
 *
 * To run a mutation, you first call `useCreateMeterReadingsBatchedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMeterReadingsBatchedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMeterReadingsBatchedMutation, { data, loading, error }] = useCreateMeterReadingsBatchedMutation({
 *   variables: {
 *      meterReadings: // value for 'meterReadings'
 *   },
 * });
 */
export function useCreateMeterReadingsBatchedMutation(baseOptions?: Apollo.MutationHookOptions<CreateMeterReadingsBatchedMutation, CreateMeterReadingsBatchedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMeterReadingsBatchedMutation, CreateMeterReadingsBatchedMutationVariables>(CreateMeterReadingsBatchedDocument, options);
      }
export type CreateMeterReadingsBatchedMutationHookResult = ReturnType<typeof useCreateMeterReadingsBatchedMutation>;
export type CreateMeterReadingsBatchedMutationResult = Apollo.MutationResult<CreateMeterReadingsBatchedMutation>;
export type CreateMeterReadingsBatchedMutationOptions = Apollo.BaseMutationOptions<CreateMeterReadingsBatchedMutation, CreateMeterReadingsBatchedMutationVariables>;
export const CreateMetersBatchedDocument = gql`
    mutation createMetersBatched($meters: [CreateMetersBatchedInput!]!) {
  createMetersBatched(meters: $meters) {
    __typename
    succeeded {
      meterNumber
      plantName
    }
    failed {
      error
      message
      meterNumber
      plantName
    }
  }
}
    `;
export type CreateMetersBatchedMutationFn = Apollo.MutationFunction<CreateMetersBatchedMutation, CreateMetersBatchedMutationVariables>;

/**
 * __useCreateMetersBatchedMutation__
 *
 * To run a mutation, you first call `useCreateMetersBatchedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMetersBatchedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMetersBatchedMutation, { data, loading, error }] = useCreateMetersBatchedMutation({
 *   variables: {
 *      meters: // value for 'meters'
 *   },
 * });
 */
export function useCreateMetersBatchedMutation(baseOptions?: Apollo.MutationHookOptions<CreateMetersBatchedMutation, CreateMetersBatchedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMetersBatchedMutation, CreateMetersBatchedMutationVariables>(CreateMetersBatchedDocument, options);
      }
export type CreateMetersBatchedMutationHookResult = ReturnType<typeof useCreateMetersBatchedMutation>;
export type CreateMetersBatchedMutationResult = Apollo.MutationResult<CreateMetersBatchedMutation>;
export type CreateMetersBatchedMutationOptions = Apollo.BaseMutationOptions<CreateMetersBatchedMutation, CreateMetersBatchedMutationVariables>;
export const CreatePlantReportDocument = gql`
    mutation createPlantReport($id: ID!, $salesStartDate: DateTime!, $salesEndDate: DateTime!, $accountingPeriodStartDate: DateTime!, $accountingPeriodEndDate: DateTime!) {
  createPlantReport(id: $id, salesStartDate: $salesStartDate, salesEndDate: $salesEndDate, accountingPeriodStartDate: $accountingPeriodStartDate, accountingPeriodEndDate: $accountingPeriodEndDate) {
    ... on CreatePlantReportSuccess {
      success
    }
    ... on UnknownError {
      message
    }
    ... on RateLimitReached {
      message
    }
  }
}
    `;
export type CreatePlantReportMutationFn = Apollo.MutationFunction<CreatePlantReportMutation, CreatePlantReportMutationVariables>;

/**
 * __useCreatePlantReportMutation__
 *
 * To run a mutation, you first call `useCreatePlantReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlantReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlantReportMutation, { data, loading, error }] = useCreatePlantReportMutation({
 *   variables: {
 *      id: // value for 'id'
 *      salesStartDate: // value for 'salesStartDate'
 *      salesEndDate: // value for 'salesEndDate'
 *      accountingPeriodStartDate: // value for 'accountingPeriodStartDate'
 *      accountingPeriodEndDate: // value for 'accountingPeriodEndDate'
 *   },
 * });
 */
export function useCreatePlantReportMutation(baseOptions?: Apollo.MutationHookOptions<CreatePlantReportMutation, CreatePlantReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePlantReportMutation, CreatePlantReportMutationVariables>(CreatePlantReportDocument, options);
      }
export type CreatePlantReportMutationHookResult = ReturnType<typeof useCreatePlantReportMutation>;
export type CreatePlantReportMutationResult = Apollo.MutationResult<CreatePlantReportMutation>;
export type CreatePlantReportMutationOptions = Apollo.BaseMutationOptions<CreatePlantReportMutation, CreatePlantReportMutationVariables>;
export const CreateReportDocument = gql`
    mutation createReport {
  createReport {
    ... on CreateReportSuccess {
      entityId
      documentName
      createdAt
    }
    ... on UnknownError {
      message
    }
    ... on WrongCredentialsError {
      message
    }
    ... on RateLimitReached {
      message
    }
  }
}
    `;
export type CreateReportMutationFn = Apollo.MutationFunction<CreateReportMutation, CreateReportMutationVariables>;

/**
 * __useCreateReportMutation__
 *
 * To run a mutation, you first call `useCreateReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReportMutation, { data, loading, error }] = useCreateReportMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateReportMutation(baseOptions?: Apollo.MutationHookOptions<CreateReportMutation, CreateReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReportMutation, CreateReportMutationVariables>(CreateReportDocument, options);
      }
export type CreateReportMutationHookResult = ReturnType<typeof useCreateReportMutation>;
export type CreateReportMutationResult = Apollo.MutationResult<CreateReportMutation>;
export type CreateReportMutationOptions = Apollo.BaseMutationOptions<CreateReportMutation, CreateReportMutationVariables>;
export const CreateTariffsBatchedDocument = gql`
    mutation createTariffsBatched($tariffs: [CreateTariffsBatchedInput!]!) {
  createTariffsBatched(tariffs: $tariffs) {
    __typename
    succeeded {
      nameInternal
      info
    }
    failed {
      nameInternal
      error
      message
    }
  }
}
    `;
export type CreateTariffsBatchedMutationFn = Apollo.MutationFunction<CreateTariffsBatchedMutation, CreateTariffsBatchedMutationVariables>;

/**
 * __useCreateTariffsBatchedMutation__
 *
 * To run a mutation, you first call `useCreateTariffsBatchedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTariffsBatchedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTariffsBatchedMutation, { data, loading, error }] = useCreateTariffsBatchedMutation({
 *   variables: {
 *      tariffs: // value for 'tariffs'
 *   },
 * });
 */
export function useCreateTariffsBatchedMutation(baseOptions?: Apollo.MutationHookOptions<CreateTariffsBatchedMutation, CreateTariffsBatchedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTariffsBatchedMutation, CreateTariffsBatchedMutationVariables>(CreateTariffsBatchedDocument, options);
      }
export type CreateTariffsBatchedMutationHookResult = ReturnType<typeof useCreateTariffsBatchedMutation>;
export type CreateTariffsBatchedMutationResult = Apollo.MutationResult<CreateTariffsBatchedMutation>;
export type CreateTariffsBatchedMutationOptions = Apollo.BaseMutationOptions<CreateTariffsBatchedMutation, CreateTariffsBatchedMutationVariables>;
export const DeleteAccountingDraftConsumptionValuesDocument = gql`
    mutation deleteAccountingDraftConsumptionValues($contractLabels: [String!]!, $validityStart: String!, $validityEnd: String!) {
  deleteAccountingDraftConsumptionValues(contractLabels: $contractLabels, validityStart: $validityStart, validityEnd: $validityEnd) {
    ... on DeleteAccountingDraftConsumptionValuesSuccess {
      __typename
      contractId
      deletionResult {
        success
      }
    }
    ... on DeleteAccountingDraftConsumptionValuesError {
      __typename
      contractId
      error {
        title
      }
    }
  }
}
    `;
export type DeleteAccountingDraftConsumptionValuesMutationFn = Apollo.MutationFunction<DeleteAccountingDraftConsumptionValuesMutation, DeleteAccountingDraftConsumptionValuesMutationVariables>;

/**
 * __useDeleteAccountingDraftConsumptionValuesMutation__
 *
 * To run a mutation, you first call `useDeleteAccountingDraftConsumptionValuesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountingDraftConsumptionValuesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountingDraftConsumptionValuesMutation, { data, loading, error }] = useDeleteAccountingDraftConsumptionValuesMutation({
 *   variables: {
 *      contractLabels: // value for 'contractLabels'
 *      validityStart: // value for 'validityStart'
 *      validityEnd: // value for 'validityEnd'
 *   },
 * });
 */
export function useDeleteAccountingDraftConsumptionValuesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAccountingDraftConsumptionValuesMutation, DeleteAccountingDraftConsumptionValuesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAccountingDraftConsumptionValuesMutation, DeleteAccountingDraftConsumptionValuesMutationVariables>(DeleteAccountingDraftConsumptionValuesDocument, options);
      }
export type DeleteAccountingDraftConsumptionValuesMutationHookResult = ReturnType<typeof useDeleteAccountingDraftConsumptionValuesMutation>;
export type DeleteAccountingDraftConsumptionValuesMutationResult = Apollo.MutationResult<DeleteAccountingDraftConsumptionValuesMutation>;
export type DeleteAccountingDraftConsumptionValuesMutationOptions = Apollo.BaseMutationOptions<DeleteAccountingDraftConsumptionValuesMutation, DeleteAccountingDraftConsumptionValuesMutationVariables>;
export const DeleteOldAccountingWorkflowDocument = gql`
    mutation deleteOldAccountingWorkflow($workflowId: ID!, $uuid: String) {
  deleteOldAccountingWorkflow(workflowId: $workflowId, uuid: $uuid) {
    ... on WorkflowResponse {
      success
      message
    }
    ... on UnknownError {
      message
    }
  }
}
    `;
export type DeleteOldAccountingWorkflowMutationFn = Apollo.MutationFunction<DeleteOldAccountingWorkflowMutation, DeleteOldAccountingWorkflowMutationVariables>;

/**
 * __useDeleteOldAccountingWorkflowMutation__
 *
 * To run a mutation, you first call `useDeleteOldAccountingWorkflowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOldAccountingWorkflowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOldAccountingWorkflowMutation, { data, loading, error }] = useDeleteOldAccountingWorkflowMutation({
 *   variables: {
 *      workflowId: // value for 'workflowId'
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDeleteOldAccountingWorkflowMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOldAccountingWorkflowMutation, DeleteOldAccountingWorkflowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOldAccountingWorkflowMutation, DeleteOldAccountingWorkflowMutationVariables>(DeleteOldAccountingWorkflowDocument, options);
      }
export type DeleteOldAccountingWorkflowMutationHookResult = ReturnType<typeof useDeleteOldAccountingWorkflowMutation>;
export type DeleteOldAccountingWorkflowMutationResult = Apollo.MutationResult<DeleteOldAccountingWorkflowMutation>;
export type DeleteOldAccountingWorkflowMutationOptions = Apollo.BaseMutationOptions<DeleteOldAccountingWorkflowMutation, DeleteOldAccountingWorkflowMutationVariables>;
export const DeleteCrmCardDocument = gql`
    mutation deleteCRMCard($cardId: BigInt!, $clientMutationId: String) {
  deleteCardCustom(input: {cardId: $cardId, clientMutationId: $clientMutationId}) {
    boolean
  }
}
    `;
export type DeleteCrmCardMutationFn = Apollo.MutationFunction<DeleteCrmCardMutation, DeleteCrmCardMutationVariables>;

/**
 * __useDeleteCrmCardMutation__
 *
 * To run a mutation, you first call `useDeleteCrmCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCrmCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCrmCardMutation, { data, loading, error }] = useDeleteCrmCardMutation({
 *   variables: {
 *      cardId: // value for 'cardId'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useDeleteCrmCardMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCrmCardMutation, DeleteCrmCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCrmCardMutation, DeleteCrmCardMutationVariables>(DeleteCrmCardDocument, options);
      }
export type DeleteCrmCardMutationHookResult = ReturnType<typeof useDeleteCrmCardMutation>;
export type DeleteCrmCardMutationResult = Apollo.MutationResult<DeleteCrmCardMutation>;
export type DeleteCrmCardMutationOptions = Apollo.BaseMutationOptions<DeleteCrmCardMutation, DeleteCrmCardMutationVariables>;
export const RemoveDocumentsDocument = gql`
    mutation removeDocuments($hash: String!) {
  removeDocuments(hash: $hash) {
    __typename
    ... on RemoveResponse {
      success
    }
  }
}
    `;
export type RemoveDocumentsMutationFn = Apollo.MutationFunction<RemoveDocumentsMutation, RemoveDocumentsMutationVariables>;

/**
 * __useRemoveDocumentsMutation__
 *
 * To run a mutation, you first call `useRemoveDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDocumentsMutation, { data, loading, error }] = useRemoveDocumentsMutation({
 *   variables: {
 *      hash: // value for 'hash'
 *   },
 * });
 */
export function useRemoveDocumentsMutation(baseOptions?: Apollo.MutationHookOptions<RemoveDocumentsMutation, RemoveDocumentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveDocumentsMutation, RemoveDocumentsMutationVariables>(RemoveDocumentsDocument, options);
      }
export type RemoveDocumentsMutationHookResult = ReturnType<typeof useRemoveDocumentsMutation>;
export type RemoveDocumentsMutationResult = Apollo.MutationResult<RemoveDocumentsMutation>;
export type RemoveDocumentsMutationOptions = Apollo.BaseMutationOptions<RemoveDocumentsMutation, RemoveDocumentsMutationVariables>;
export const DeleteMeterReadingDocument = gql`
    mutation deleteMeterReading($obisChannel: String!, $meloID: String!, $startDate: DateTime!, $endDate: DateTime!, $reason: String) {
  deleteMeterReading(obisChannel: $obisChannel, meloID: $meloID, startDate: $startDate, endDate: $endDate, reason: $reason) {
    success
  }
}
    `;
export type DeleteMeterReadingMutationFn = Apollo.MutationFunction<DeleteMeterReadingMutation, DeleteMeterReadingMutationVariables>;

/**
 * __useDeleteMeterReadingMutation__
 *
 * To run a mutation, you first call `useDeleteMeterReadingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMeterReadingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMeterReadingMutation, { data, loading, error }] = useDeleteMeterReadingMutation({
 *   variables: {
 *      obisChannel: // value for 'obisChannel'
 *      meloID: // value for 'meloID'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useDeleteMeterReadingMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMeterReadingMutation, DeleteMeterReadingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMeterReadingMutation, DeleteMeterReadingMutationVariables>(DeleteMeterReadingDocument, options);
      }
export type DeleteMeterReadingMutationHookResult = ReturnType<typeof useDeleteMeterReadingMutation>;
export type DeleteMeterReadingMutationResult = Apollo.MutationResult<DeleteMeterReadingMutation>;
export type DeleteMeterReadingMutationOptions = Apollo.BaseMutationOptions<DeleteMeterReadingMutation, DeleteMeterReadingMutationVariables>;
export const DeleteOdooContractDocument = gql`
    mutation deleteOdooContract($id: ID!) {
  deleteOdooContract(id: $id) {
    ... on SuccessResponse {
      success
    }
    ... on OdooContractNotFoundError {
      message
    }
    ... on OdooContractPartnerAssignedToOtherContractError {
      message
    }
    ... on Error {
      message
    }
  }
}
    `;
export type DeleteOdooContractMutationFn = Apollo.MutationFunction<DeleteOdooContractMutation, DeleteOdooContractMutationVariables>;

/**
 * __useDeleteOdooContractMutation__
 *
 * To run a mutation, you first call `useDeleteOdooContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOdooContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOdooContractMutation, { data, loading, error }] = useDeleteOdooContractMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOdooContractMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOdooContractMutation, DeleteOdooContractMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOdooContractMutation, DeleteOdooContractMutationVariables>(DeleteOdooContractDocument, options);
      }
export type DeleteOdooContractMutationHookResult = ReturnType<typeof useDeleteOdooContractMutation>;
export type DeleteOdooContractMutationResult = Apollo.MutationResult<DeleteOdooContractMutation>;
export type DeleteOdooContractMutationOptions = Apollo.BaseMutationOptions<DeleteOdooContractMutation, DeleteOdooContractMutationVariables>;
export const DeletePriceSheetDocument = gql`
    mutation deletePriceSheet($id: ID!) {
  deletePriceSheet(id: $id) {
    success
  }
}
    `;
export type DeletePriceSheetMutationFn = Apollo.MutationFunction<DeletePriceSheetMutation, DeletePriceSheetMutationVariables>;

/**
 * __useDeletePriceSheetMutation__
 *
 * To run a mutation, you first call `useDeletePriceSheetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePriceSheetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePriceSheetMutation, { data, loading, error }] = useDeletePriceSheetMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePriceSheetMutation(baseOptions?: Apollo.MutationHookOptions<DeletePriceSheetMutation, DeletePriceSheetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePriceSheetMutation, DeletePriceSheetMutationVariables>(DeletePriceSheetDocument, options);
      }
export type DeletePriceSheetMutationHookResult = ReturnType<typeof useDeletePriceSheetMutation>;
export type DeletePriceSheetMutationResult = Apollo.MutationResult<DeletePriceSheetMutation>;
export type DeletePriceSheetMutationOptions = Apollo.BaseMutationOptions<DeletePriceSheetMutation, DeletePriceSheetMutationVariables>;
export const GenerateContractDocumentDocument = gql`
    mutation generateContractDocument($contractId: String!, $type: String!, $deliveryPreference: DeliveryPreference!, $contractDocumentData: ContractDocumentData, $pricesheetChangeDocumentData: PricesheetChangeDocumentData) {
  generateContractDocument(contractId: $contractId, type: $type, deliveryPreference: $deliveryPreference, contractDocumentData: $contractDocumentData, pricesheetChangeDocumentData: $pricesheetChangeDocumentData) {
    ... on SuccessResponse {
      success
      fileURL
      hash
    }
    ... on MultipleFilesSuccessResponse {
      success
      results {
        url
        hash
      }
    }
    ... on UnknownError {
      message
    }
  }
}
    `;
export type GenerateContractDocumentMutationFn = Apollo.MutationFunction<GenerateContractDocumentMutation, GenerateContractDocumentMutationVariables>;

/**
 * __useGenerateContractDocumentMutation__
 *
 * To run a mutation, you first call `useGenerateContractDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateContractDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateContractDocumentMutation, { data, loading, error }] = useGenerateContractDocumentMutation({
 *   variables: {
 *      contractId: // value for 'contractId'
 *      type: // value for 'type'
 *      deliveryPreference: // value for 'deliveryPreference'
 *      contractDocumentData: // value for 'contractDocumentData'
 *      pricesheetChangeDocumentData: // value for 'pricesheetChangeDocumentData'
 *   },
 * });
 */
export function useGenerateContractDocumentMutation(baseOptions?: Apollo.MutationHookOptions<GenerateContractDocumentMutation, GenerateContractDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateContractDocumentMutation, GenerateContractDocumentMutationVariables>(GenerateContractDocumentDocument, options);
      }
export type GenerateContractDocumentMutationHookResult = ReturnType<typeof useGenerateContractDocumentMutation>;
export type GenerateContractDocumentMutationResult = Apollo.MutationResult<GenerateContractDocumentMutation>;
export type GenerateContractDocumentMutationOptions = Apollo.BaseMutationOptions<GenerateContractDocumentMutation, GenerateContractDocumentMutationVariables>;
export const GenerateContractDocumentPreviewDocument = gql`
    mutation generateContractDocumentPreview($contractId: String!, $type: String!, $contractDocumentData: ContractDocumentData, $pricesheetChangeDocumentData: PricesheetChangeDocumentData) {
  generateContractDocumentPreview(contractId: $contractId, type: $type, contractDocumentData: $contractDocumentData, pricesheetChangeDocumentData: $pricesheetChangeDocumentData) {
    ... on ContractDocumentPreview {
      fileURL
    }
    ... on MissingAttributesInContractDocumentError {
      attributes
    }
    ... on UnknownError {
      message
    }
  }
}
    `;
export type GenerateContractDocumentPreviewMutationFn = Apollo.MutationFunction<GenerateContractDocumentPreviewMutation, GenerateContractDocumentPreviewMutationVariables>;

/**
 * __useGenerateContractDocumentPreviewMutation__
 *
 * To run a mutation, you first call `useGenerateContractDocumentPreviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateContractDocumentPreviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateContractDocumentPreviewMutation, { data, loading, error }] = useGenerateContractDocumentPreviewMutation({
 *   variables: {
 *      contractId: // value for 'contractId'
 *      type: // value for 'type'
 *      contractDocumentData: // value for 'contractDocumentData'
 *      pricesheetChangeDocumentData: // value for 'pricesheetChangeDocumentData'
 *   },
 * });
 */
export function useGenerateContractDocumentPreviewMutation(baseOptions?: Apollo.MutationHookOptions<GenerateContractDocumentPreviewMutation, GenerateContractDocumentPreviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateContractDocumentPreviewMutation, GenerateContractDocumentPreviewMutationVariables>(GenerateContractDocumentPreviewDocument, options);
      }
export type GenerateContractDocumentPreviewMutationHookResult = ReturnType<typeof useGenerateContractDocumentPreviewMutation>;
export type GenerateContractDocumentPreviewMutationResult = Apollo.MutationResult<GenerateContractDocumentPreviewMutation>;
export type GenerateContractDocumentPreviewMutationOptions = Apollo.BaseMutationOptions<GenerateContractDocumentPreviewMutation, GenerateContractDocumentPreviewMutationVariables>;
export const GenerateContractInvoiceDocumentPreviewDocument = gql`
    mutation generateContractInvoiceDocumentPreview($contractLabel: String!, $opcName: String!, $workflowUuid: String!, $invoiceCycle: InvoiceCycle!, $invoiceId: String) {
  generateContractInvoiceDocumentPreview(contractLabel: $contractLabel, opcName: $opcName, workflowUuid: $workflowUuid, invoiceCycle: $invoiceCycle, invoiceId: $invoiceId) {
    ... on ContractDocumentPreview {
      fileURL
    }
    ... on UnknownError {
      message
    }
  }
}
    `;
export type GenerateContractInvoiceDocumentPreviewMutationFn = Apollo.MutationFunction<GenerateContractInvoiceDocumentPreviewMutation, GenerateContractInvoiceDocumentPreviewMutationVariables>;

/**
 * __useGenerateContractInvoiceDocumentPreviewMutation__
 *
 * To run a mutation, you first call `useGenerateContractInvoiceDocumentPreviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateContractInvoiceDocumentPreviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateContractInvoiceDocumentPreviewMutation, { data, loading, error }] = useGenerateContractInvoiceDocumentPreviewMutation({
 *   variables: {
 *      contractLabel: // value for 'contractLabel'
 *      opcName: // value for 'opcName'
 *      workflowUuid: // value for 'workflowUuid'
 *      invoiceCycle: // value for 'invoiceCycle'
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useGenerateContractInvoiceDocumentPreviewMutation(baseOptions?: Apollo.MutationHookOptions<GenerateContractInvoiceDocumentPreviewMutation, GenerateContractInvoiceDocumentPreviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateContractInvoiceDocumentPreviewMutation, GenerateContractInvoiceDocumentPreviewMutationVariables>(GenerateContractInvoiceDocumentPreviewDocument, options);
      }
export type GenerateContractInvoiceDocumentPreviewMutationHookResult = ReturnType<typeof useGenerateContractInvoiceDocumentPreviewMutation>;
export type GenerateContractInvoiceDocumentPreviewMutationResult = Apollo.MutationResult<GenerateContractInvoiceDocumentPreviewMutation>;
export type GenerateContractInvoiceDocumentPreviewMutationOptions = Apollo.BaseMutationOptions<GenerateContractInvoiceDocumentPreviewMutation, GenerateContractInvoiceDocumentPreviewMutationVariables>;
export const GenerateMultipleContractDocumentsDocument = gql`
    mutation generateMultipleContractDocuments($payloads: [GenerateContractDocumentInput!]!, $deliveryPreference: DeliveryPreference!) {
  generateMultipleContractDocuments(payloads: $payloads, deliveryPreference: $deliveryPreference) {
    jobId
    status
    progress
    documents {
      ... on DocumentInfo {
        contractId
        url
        hash
      }
      ... on GenerateDocumentError {
        contractId
        reason
      }
    }
  }
}
    `;
export type GenerateMultipleContractDocumentsMutationFn = Apollo.MutationFunction<GenerateMultipleContractDocumentsMutation, GenerateMultipleContractDocumentsMutationVariables>;

/**
 * __useGenerateMultipleContractDocumentsMutation__
 *
 * To run a mutation, you first call `useGenerateMultipleContractDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateMultipleContractDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateMultipleContractDocumentsMutation, { data, loading, error }] = useGenerateMultipleContractDocumentsMutation({
 *   variables: {
 *      payloads: // value for 'payloads'
 *      deliveryPreference: // value for 'deliveryPreference'
 *   },
 * });
 */
export function useGenerateMultipleContractDocumentsMutation(baseOptions?: Apollo.MutationHookOptions<GenerateMultipleContractDocumentsMutation, GenerateMultipleContractDocumentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateMultipleContractDocumentsMutation, GenerateMultipleContractDocumentsMutationVariables>(GenerateMultipleContractDocumentsDocument, options);
      }
export type GenerateMultipleContractDocumentsMutationHookResult = ReturnType<typeof useGenerateMultipleContractDocumentsMutation>;
export type GenerateMultipleContractDocumentsMutationResult = Apollo.MutationResult<GenerateMultipleContractDocumentsMutation>;
export type GenerateMultipleContractDocumentsMutationOptions = Apollo.BaseMutationOptions<GenerateMultipleContractDocumentsMutation, GenerateMultipleContractDocumentsMutationVariables>;
export const GetInitialBoardDocument = gql`
    query getInitialBoard($clientId: BigInt!) {
  crminstances(condition: {client: $clientId}) {
    nodes {
      id
      crminstanceLaneRelsByIdCrminstance {
        nodes {
          laneByIdLanes {
            laneCardRelsByIdLanes {
              nodes {
                cardByIdCard {
                  isperson
                  prefix
                  name
                  companyname
                  co
                  contractstartdate
                  contractenddate
                  street
                  streetnr
                  createdat
                  updatedat
                  ajv
                  reductionstartdate
                  reduction
                  city
                  id
                  zipcode
                  phone
                  email
                  state
                  notes
                  plantid
                  workspaceid
                  tariffid
                  meterid
                  shippingaddressstreet
                  shippingaddressstreetnr
                  shippingaddresszipcode
                  shippingaddresscity
                  leadorigin
                }
              }
            }
            name
            updatedat
            id
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetInitialBoardQuery__
 *
 * To run a query within a React component, call `useGetInitialBoardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInitialBoardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInitialBoardQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useGetInitialBoardQuery(baseOptions: Apollo.QueryHookOptions<GetInitialBoardQuery, GetInitialBoardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInitialBoardQuery, GetInitialBoardQueryVariables>(GetInitialBoardDocument, options);
      }
export function useGetInitialBoardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInitialBoardQuery, GetInitialBoardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInitialBoardQuery, GetInitialBoardQueryVariables>(GetInitialBoardDocument, options);
        }
export type GetInitialBoardQueryHookResult = ReturnType<typeof useGetInitialBoardQuery>;
export type GetInitialBoardLazyQueryHookResult = ReturnType<typeof useGetInitialBoardLazyQuery>;
export type GetInitialBoardQueryResult = Apollo.QueryResult<GetInitialBoardQuery, GetInitialBoardQueryVariables>;
export const GetBicForIbanDocument = gql`
    query getBicForIban($iban: String!) {
  getBicForIban(iban: $iban)
}
    `;

/**
 * __useGetBicForIbanQuery__
 *
 * To run a query within a React component, call `useGetBicForIbanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBicForIbanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBicForIbanQuery({
 *   variables: {
 *      iban: // value for 'iban'
 *   },
 * });
 */
export function useGetBicForIbanQuery(baseOptions: Apollo.QueryHookOptions<GetBicForIbanQuery, GetBicForIbanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBicForIbanQuery, GetBicForIbanQueryVariables>(GetBicForIbanDocument, options);
      }
export function useGetBicForIbanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBicForIbanQuery, GetBicForIbanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBicForIbanQuery, GetBicForIbanQueryVariables>(GetBicForIbanDocument, options);
        }
export type GetBicForIbanQueryHookResult = ReturnType<typeof useGetBicForIbanQuery>;
export type GetBicForIbanLazyQueryHookResult = ReturnType<typeof useGetBicForIbanLazyQuery>;
export type GetBicForIbanQueryResult = Apollo.QueryResult<GetBicForIbanQuery, GetBicForIbanQueryVariables>;
export const GetDocumentTypesDocument = gql`
    query getDocumentTypes {
  getDocumentTypes {
    name
    label
    group
  }
}
    `;

/**
 * __useGetDocumentTypesQuery__
 *
 * To run a query within a React component, call `useGetDocumentTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDocumentTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetDocumentTypesQuery, GetDocumentTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocumentTypesQuery, GetDocumentTypesQueryVariables>(GetDocumentTypesDocument, options);
      }
export function useGetDocumentTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocumentTypesQuery, GetDocumentTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocumentTypesQuery, GetDocumentTypesQueryVariables>(GetDocumentTypesDocument, options);
        }
export type GetDocumentTypesQueryHookResult = ReturnType<typeof useGetDocumentTypesQuery>;
export type GetDocumentTypesLazyQueryHookResult = ReturnType<typeof useGetDocumentTypesLazyQuery>;
export type GetDocumentTypesQueryResult = Apollo.QueryResult<GetDocumentTypesQuery, GetDocumentTypesQueryVariables>;
export const GetExtrapolatedMeterReadingDocument = gql`
    query getExtrapolatedMeterReading($meterId: ID!, $date: DateTime!) {
  getExtrapolatedMeterReading(meterId: $meterId, date: $date) {
    ... on ExtrapolatedReading {
      value
    }
    ... on ValidationError {
      message
    }
  }
}
    `;

/**
 * __useGetExtrapolatedMeterReadingQuery__
 *
 * To run a query within a React component, call `useGetExtrapolatedMeterReadingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExtrapolatedMeterReadingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExtrapolatedMeterReadingQuery({
 *   variables: {
 *      meterId: // value for 'meterId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetExtrapolatedMeterReadingQuery(baseOptions: Apollo.QueryHookOptions<GetExtrapolatedMeterReadingQuery, GetExtrapolatedMeterReadingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExtrapolatedMeterReadingQuery, GetExtrapolatedMeterReadingQueryVariables>(GetExtrapolatedMeterReadingDocument, options);
      }
export function useGetExtrapolatedMeterReadingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExtrapolatedMeterReadingQuery, GetExtrapolatedMeterReadingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExtrapolatedMeterReadingQuery, GetExtrapolatedMeterReadingQueryVariables>(GetExtrapolatedMeterReadingDocument, options);
        }
export type GetExtrapolatedMeterReadingQueryHookResult = ReturnType<typeof useGetExtrapolatedMeterReadingQuery>;
export type GetExtrapolatedMeterReadingLazyQueryHookResult = ReturnType<typeof useGetExtrapolatedMeterReadingLazyQuery>;
export type GetExtrapolatedMeterReadingQueryResult = Apollo.QueryResult<GetExtrapolatedMeterReadingQuery, GetExtrapolatedMeterReadingQueryVariables>;
export const GetSumMeterConsumptionDocument = gql`
    query GetSumMeterConsumption($contractId: String!) {
  getSumMeterConsumption(contractId: $contractId) {
    ... on SumMeterConsumptionSuccess {
      malo
    }
    ... on SumMeterConsumptionError {
      message
    }
  }
}
    `;

/**
 * __useGetSumMeterConsumptionQuery__
 *
 * To run a query within a React component, call `useGetSumMeterConsumptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSumMeterConsumptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSumMeterConsumptionQuery({
 *   variables: {
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useGetSumMeterConsumptionQuery(baseOptions: Apollo.QueryHookOptions<GetSumMeterConsumptionQuery, GetSumMeterConsumptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSumMeterConsumptionQuery, GetSumMeterConsumptionQueryVariables>(GetSumMeterConsumptionDocument, options);
      }
export function useGetSumMeterConsumptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSumMeterConsumptionQuery, GetSumMeterConsumptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSumMeterConsumptionQuery, GetSumMeterConsumptionQueryVariables>(GetSumMeterConsumptionDocument, options);
        }
export type GetSumMeterConsumptionQueryHookResult = ReturnType<typeof useGetSumMeterConsumptionQuery>;
export type GetSumMeterConsumptionLazyQueryHookResult = ReturnType<typeof useGetSumMeterConsumptionLazyQuery>;
export type GetSumMeterConsumptionQueryResult = Apollo.QueryResult<GetSumMeterConsumptionQuery, GetSumMeterConsumptionQueryVariables>;
export const GetSupplierChangeWorkflowStateDocument = gql`
    query getSupplierChangeWorkflowState($workflowId: String!) {
  getSupplierChangeWorkflowState(workflowId: $workflowId) {
    done
    type
    contractId
    startedAt
    steps {
      index
      status
      result {
        ... on DocumentResult {
          __typename
          document {
            url
          }
        }
        ... on NetworkOperatorResult {
          __typename
          meteringPointOperator {
            ...NetworkOperatorData
          }
          gridOperator {
            ...NetworkOperatorData
          }
          oldSupplier {
            ...NetworkOperatorData
          }
        }
        ... on ContractUpdateResult {
          __typename
          deadline
          contractDate
          newMalo
          parentWorkflowId
          reductionValue
          reductionStartDate
        }
        ... on ChildWorkflowResult {
          __typename
          workflowId
          done
        }
      }
    }
  }
}
    ${NetworkOperatorDataFragmentDoc}`;

/**
 * __useGetSupplierChangeWorkflowStateQuery__
 *
 * To run a query within a React component, call `useGetSupplierChangeWorkflowStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierChangeWorkflowStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierChangeWorkflowStateQuery({
 *   variables: {
 *      workflowId: // value for 'workflowId'
 *   },
 * });
 */
export function useGetSupplierChangeWorkflowStateQuery(baseOptions: Apollo.QueryHookOptions<GetSupplierChangeWorkflowStateQuery, GetSupplierChangeWorkflowStateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSupplierChangeWorkflowStateQuery, GetSupplierChangeWorkflowStateQueryVariables>(GetSupplierChangeWorkflowStateDocument, options);
      }
export function useGetSupplierChangeWorkflowStateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSupplierChangeWorkflowStateQuery, GetSupplierChangeWorkflowStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSupplierChangeWorkflowStateQuery, GetSupplierChangeWorkflowStateQueryVariables>(GetSupplierChangeWorkflowStateDocument, options);
        }
export type GetSupplierChangeWorkflowStateQueryHookResult = ReturnType<typeof useGetSupplierChangeWorkflowStateQuery>;
export type GetSupplierChangeWorkflowStateLazyQueryHookResult = ReturnType<typeof useGetSupplierChangeWorkflowStateLazyQuery>;
export type GetSupplierChangeWorkflowStateQueryResult = Apollo.QueryResult<GetSupplierChangeWorkflowStateQuery, GetSupplierChangeWorkflowStateQueryVariables>;
export const GetSupplierChangeWorkflowsDocument = gql`
    query GetSupplierChangeWorkflows($workflowType: String!, $nextPageToken: String) {
  getSupplierChangeWorkflows(workflowType: $workflowType, nextPageToken: $nextPageToken) {
    data {
      WorkflowId
      RunId
      ContractId
      ContractName
      ContractLabel
      Plant
      Meter
      Malo
      NetworkOperator
      WorkflowStatus
      StatusText
    }
    nextPageToken
    totalCount
  }
}
    `;

/**
 * __useGetSupplierChangeWorkflowsQuery__
 *
 * To run a query within a React component, call `useGetSupplierChangeWorkflowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierChangeWorkflowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierChangeWorkflowsQuery({
 *   variables: {
 *      workflowType: // value for 'workflowType'
 *      nextPageToken: // value for 'nextPageToken'
 *   },
 * });
 */
export function useGetSupplierChangeWorkflowsQuery(baseOptions: Apollo.QueryHookOptions<GetSupplierChangeWorkflowsQuery, GetSupplierChangeWorkflowsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSupplierChangeWorkflowsQuery, GetSupplierChangeWorkflowsQueryVariables>(GetSupplierChangeWorkflowsDocument, options);
      }
export function useGetSupplierChangeWorkflowsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSupplierChangeWorkflowsQuery, GetSupplierChangeWorkflowsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSupplierChangeWorkflowsQuery, GetSupplierChangeWorkflowsQueryVariables>(GetSupplierChangeWorkflowsDocument, options);
        }
export type GetSupplierChangeWorkflowsQueryHookResult = ReturnType<typeof useGetSupplierChangeWorkflowsQuery>;
export type GetSupplierChangeWorkflowsLazyQueryHookResult = ReturnType<typeof useGetSupplierChangeWorkflowsLazyQuery>;
export type GetSupplierChangeWorkflowsQueryResult = Apollo.QueryResult<GetSupplierChangeWorkflowsQuery, GetSupplierChangeWorkflowsQueryVariables>;
export const ImportDebitPositionsDocument = gql`
    mutation importDebitPositions($workflowId: ID!, $payload: ImportDebitPositionsPayload!) {
  importDebitPositions(workflowId: $workflowId, payload: $payload) {
    id
    alias
    type
    stateNames
    done
    meta
    createdAt
    doneAt
    updatedAt
    currentState {
      name
      parentName
      context
      transitions
    }
  }
}
    `;
export type ImportDebitPositionsMutationFn = Apollo.MutationFunction<ImportDebitPositionsMutation, ImportDebitPositionsMutationVariables>;

/**
 * __useImportDebitPositionsMutation__
 *
 * To run a mutation, you first call `useImportDebitPositionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportDebitPositionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importDebitPositionsMutation, { data, loading, error }] = useImportDebitPositionsMutation({
 *   variables: {
 *      workflowId: // value for 'workflowId'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useImportDebitPositionsMutation(baseOptions?: Apollo.MutationHookOptions<ImportDebitPositionsMutation, ImportDebitPositionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportDebitPositionsMutation, ImportDebitPositionsMutationVariables>(ImportDebitPositionsDocument, options);
      }
export type ImportDebitPositionsMutationHookResult = ReturnType<typeof useImportDebitPositionsMutation>;
export type ImportDebitPositionsMutationResult = Apollo.MutationResult<ImportDebitPositionsMutation>;
export type ImportDebitPositionsMutationOptions = Apollo.BaseMutationOptions<ImportDebitPositionsMutation, ImportDebitPositionsMutationVariables>;
export const ListPlantsDocument = gql`
    query listPlants($projectId: ID!) {
  readProject(id: $projectId) {
    plants {
      id
    }
  }
}
    `;

/**
 * __useListPlantsQuery__
 *
 * To run a query within a React component, call `useListPlantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPlantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPlantsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListPlantsQuery(baseOptions: Apollo.QueryHookOptions<ListPlantsQuery, ListPlantsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListPlantsQuery, ListPlantsQueryVariables>(ListPlantsDocument, options);
      }
export function useListPlantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListPlantsQuery, ListPlantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListPlantsQuery, ListPlantsQueryVariables>(ListPlantsDocument, options);
        }
export type ListPlantsQueryHookResult = ReturnType<typeof useListPlantsQuery>;
export type ListPlantsLazyQueryHookResult = ReturnType<typeof useListPlantsLazyQuery>;
export type ListPlantsQueryResult = Apollo.QueryResult<ListPlantsQuery, ListPlantsQueryVariables>;
export const LoginUserMutationDocument = gql`
    mutation loginUserMutation($email: String!, $password: String!, $twoFactorAuthCallbackURL: String!) {
  loginUser(email: $email, password: $password, twoFactorAuthCallbackURL: $twoFactorAuthCallbackURL) {
    __typename
    ... on User {
      id
      firstName
      lastName
      email
      company {
        id
        secondaryColor
        primaryColor
        logo
      }
    }
    ... on TwoFactorRequired {
      email
    }
    ... on TwoFactorEmailFailed {
      message
    }
    ... on WrongCredentialsError {
      message
    }
    ... on ServiceNotReachableError {
      message
    }
    ... on UnknownError {
      message
    }
  }
}
    `;
export type LoginUserMutationMutationFn = Apollo.MutationFunction<LoginUserMutationMutation, LoginUserMutationMutationVariables>;

/**
 * __useLoginUserMutationMutation__
 *
 * To run a mutation, you first call `useLoginUserMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginUserMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginUserMutationMutation, { data, loading, error }] = useLoginUserMutationMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      twoFactorAuthCallbackURL: // value for 'twoFactorAuthCallbackURL'
 *   },
 * });
 */
export function useLoginUserMutationMutation(baseOptions?: Apollo.MutationHookOptions<LoginUserMutationMutation, LoginUserMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginUserMutationMutation, LoginUserMutationMutationVariables>(LoginUserMutationDocument, options);
      }
export type LoginUserMutationMutationHookResult = ReturnType<typeof useLoginUserMutationMutation>;
export type LoginUserMutationMutationResult = Apollo.MutationResult<LoginUserMutationMutation>;
export type LoginUserMutationMutationOptions = Apollo.BaseMutationOptions<LoginUserMutationMutation, LoginUserMutationMutationVariables>;
export const LogoutUserDocument = gql`
    mutation logoutUser {
  logoutUser
}
    `;
export type LogoutUserMutationFn = Apollo.MutationFunction<LogoutUserMutation, LogoutUserMutationVariables>;

/**
 * __useLogoutUserMutation__
 *
 * To run a mutation, you first call `useLogoutUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutUserMutation, { data, loading, error }] = useLogoutUserMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutUserMutation(baseOptions?: Apollo.MutationHookOptions<LogoutUserMutation, LogoutUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutUserMutation, LogoutUserMutationVariables>(LogoutUserDocument, options);
      }
export type LogoutUserMutationHookResult = ReturnType<typeof useLogoutUserMutation>;
export type LogoutUserMutationResult = Apollo.MutationResult<LogoutUserMutation>;
export type LogoutUserMutationOptions = Apollo.BaseMutationOptions<LogoutUserMutation, LogoutUserMutationVariables>;
export const MergeInvoiceDraftsDocument = gql`
    mutation mergeInvoiceDrafts($invoiceDraftIds: [ID!]!, $opcName: String!) {
  mergeInvoiceDrafts(invoiceDraftIds: $invoiceDraftIds, opcName: $opcName)
}
    `;
export type MergeInvoiceDraftsMutationFn = Apollo.MutationFunction<MergeInvoiceDraftsMutation, MergeInvoiceDraftsMutationVariables>;

/**
 * __useMergeInvoiceDraftsMutation__
 *
 * To run a mutation, you first call `useMergeInvoiceDraftsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMergeInvoiceDraftsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mergeInvoiceDraftsMutation, { data, loading, error }] = useMergeInvoiceDraftsMutation({
 *   variables: {
 *      invoiceDraftIds: // value for 'invoiceDraftIds'
 *      opcName: // value for 'opcName'
 *   },
 * });
 */
export function useMergeInvoiceDraftsMutation(baseOptions?: Apollo.MutationHookOptions<MergeInvoiceDraftsMutation, MergeInvoiceDraftsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MergeInvoiceDraftsMutation, MergeInvoiceDraftsMutationVariables>(MergeInvoiceDraftsDocument, options);
      }
export type MergeInvoiceDraftsMutationHookResult = ReturnType<typeof useMergeInvoiceDraftsMutation>;
export type MergeInvoiceDraftsMutationResult = Apollo.MutationResult<MergeInvoiceDraftsMutation>;
export type MergeInvoiceDraftsMutationOptions = Apollo.BaseMutationOptions<MergeInvoiceDraftsMutation, MergeInvoiceDraftsMutationVariables>;
export const DeleteMetadataDocument = gql`
    mutation deleteMetadata($contractLabel: ID!, $customerLabel: ID!, $metadata: CreateUpdateMetadataInput!) {
  deleteMetadata(contractLabel: $contractLabel, customerLabel: $customerLabel, metadata: $metadata) {
    key
    value
  }
}
    `;
export type DeleteMetadataMutationFn = Apollo.MutationFunction<DeleteMetadataMutation, DeleteMetadataMutationVariables>;

/**
 * __useDeleteMetadataMutation__
 *
 * To run a mutation, you first call `useDeleteMetadataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMetadataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMetadataMutation, { data, loading, error }] = useDeleteMetadataMutation({
 *   variables: {
 *      contractLabel: // value for 'contractLabel'
 *      customerLabel: // value for 'customerLabel'
 *      metadata: // value for 'metadata'
 *   },
 * });
 */
export function useDeleteMetadataMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMetadataMutation, DeleteMetadataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMetadataMutation, DeleteMetadataMutationVariables>(DeleteMetadataDocument, options);
      }
export type DeleteMetadataMutationHookResult = ReturnType<typeof useDeleteMetadataMutation>;
export type DeleteMetadataMutationResult = Apollo.MutationResult<DeleteMetadataMutation>;
export type DeleteMetadataMutationOptions = Apollo.BaseMutationOptions<DeleteMetadataMutation, DeleteMetadataMutationVariables>;
export const OnWorkflowChangeDocument = gql`
    subscription onWorkflowChange {
  workflowChange {
    workflowId
  }
}
    `;

/**
 * __useOnWorkflowChangeSubscription__
 *
 * To run a query within a React component, call `useOnWorkflowChangeSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnWorkflowChangeSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnWorkflowChangeSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnWorkflowChangeSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnWorkflowChangeSubscription, OnWorkflowChangeSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnWorkflowChangeSubscription, OnWorkflowChangeSubscriptionVariables>(OnWorkflowChangeDocument, options);
      }
export type OnWorkflowChangeSubscriptionHookResult = ReturnType<typeof useOnWorkflowChangeSubscription>;
export type OnWorkflowChangeSubscriptionResult = Apollo.SubscriptionResult<OnWorkflowChangeSubscription>;
export const PrepareUpdateContractsBatchedDocument = gql`
    mutation prepareUpdateContractsBatched($contracts: [PrepareUpdateContractsBatchedInput!]!) {
  prepareUpdateContractsBatched(contracts: $contracts) {
    __typename
    valid {
      contractId
      contractLabel
      customerLabel
      contract {
        status
        startDate
        downPayment
        downPaymentStartDate
        customer {
          person {
            namePrefix
            name
            contactPhone
            contactMobile
            companyFlag
            co
          }
          address {
            streetName
            streetNumber
            zipCode
            city
            country
          }
          addressBilling {
            partnerId
            additionalPartnerId
            namePrefix
            name
            additionalName
            additionalNamePrefix
            streetName
            streetNumber
            zipCode
            city
            country
            email
            companyFlag
            co
          }
          addressShipping {
            namePrefix
            name
            streetName
            streetNumber
            zipCode
            city
            country
            companyFlag
            co
          }
          bankAccount {
            name
            iban
            bic
            mandateReference
            signedAt
          }
          hasSepa
          documentDeliveryMethod
          advertisementAccepted
        }
      }
    }
    failed {
      contractLabel
      message
      error
    }
  }
}
    `;
export type PrepareUpdateContractsBatchedMutationFn = Apollo.MutationFunction<PrepareUpdateContractsBatchedMutation, PrepareUpdateContractsBatchedMutationVariables>;

/**
 * __usePrepareUpdateContractsBatchedMutation__
 *
 * To run a mutation, you first call `usePrepareUpdateContractsBatchedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePrepareUpdateContractsBatchedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [prepareUpdateContractsBatchedMutation, { data, loading, error }] = usePrepareUpdateContractsBatchedMutation({
 *   variables: {
 *      contracts: // value for 'contracts'
 *   },
 * });
 */
export function usePrepareUpdateContractsBatchedMutation(baseOptions?: Apollo.MutationHookOptions<PrepareUpdateContractsBatchedMutation, PrepareUpdateContractsBatchedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PrepareUpdateContractsBatchedMutation, PrepareUpdateContractsBatchedMutationVariables>(PrepareUpdateContractsBatchedDocument, options);
      }
export type PrepareUpdateContractsBatchedMutationHookResult = ReturnType<typeof usePrepareUpdateContractsBatchedMutation>;
export type PrepareUpdateContractsBatchedMutationResult = Apollo.MutationResult<PrepareUpdateContractsBatchedMutation>;
export type PrepareUpdateContractsBatchedMutationOptions = Apollo.BaseMutationOptions<PrepareUpdateContractsBatchedMutation, PrepareUpdateContractsBatchedMutationVariables>;
export const ReadAccountingDetailsReportDocument = gql`
    query readAccountingDetailsReport($opcName: String!) {
  readAccountingDetailsReport(opcName: $opcName) {
    contractLabel
    accountingReceivableAccount
    accountingNumber
    accountingPeriodStartDate
    accountingPeriodEndDate
    accountingGenerationDate
    accountingNetValue
    accountingTaxValue
    accountingGrossValue
    accountingDiff
    consumption
    reduction
    reductionStartDate
  }
}
    `;

/**
 * __useReadAccountingDetailsReportQuery__
 *
 * To run a query within a React component, call `useReadAccountingDetailsReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadAccountingDetailsReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadAccountingDetailsReportQuery({
 *   variables: {
 *      opcName: // value for 'opcName'
 *   },
 * });
 */
export function useReadAccountingDetailsReportQuery(baseOptions: Apollo.QueryHookOptions<ReadAccountingDetailsReportQuery, ReadAccountingDetailsReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadAccountingDetailsReportQuery, ReadAccountingDetailsReportQueryVariables>(ReadAccountingDetailsReportDocument, options);
      }
export function useReadAccountingDetailsReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadAccountingDetailsReportQuery, ReadAccountingDetailsReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadAccountingDetailsReportQuery, ReadAccountingDetailsReportQueryVariables>(ReadAccountingDetailsReportDocument, options);
        }
export type ReadAccountingDetailsReportQueryHookResult = ReturnType<typeof useReadAccountingDetailsReportQuery>;
export type ReadAccountingDetailsReportLazyQueryHookResult = ReturnType<typeof useReadAccountingDetailsReportLazyQuery>;
export type ReadAccountingDetailsReportQueryResult = Apollo.QueryResult<ReadAccountingDetailsReportQuery, ReadAccountingDetailsReportQueryVariables>;
export const ReadAccountingKpisDocument = gql`
    query readAccountingKpis {
  readAccountingKpis {
    totalInvoicesMandate
    cancelledInvoicesMandate
  }
}
    `;

/**
 * __useReadAccountingKpisQuery__
 *
 * To run a query within a React component, call `useReadAccountingKpisQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadAccountingKpisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadAccountingKpisQuery({
 *   variables: {
 *   },
 * });
 */
export function useReadAccountingKpisQuery(baseOptions?: Apollo.QueryHookOptions<ReadAccountingKpisQuery, ReadAccountingKpisQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadAccountingKpisQuery, ReadAccountingKpisQueryVariables>(ReadAccountingKpisDocument, options);
      }
export function useReadAccountingKpisLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadAccountingKpisQuery, ReadAccountingKpisQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadAccountingKpisQuery, ReadAccountingKpisQueryVariables>(ReadAccountingKpisDocument, options);
        }
export type ReadAccountingKpisQueryHookResult = ReturnType<typeof useReadAccountingKpisQuery>;
export type ReadAccountingKpisLazyQueryHookResult = ReturnType<typeof useReadAccountingKpisLazyQuery>;
export type ReadAccountingKpisQueryResult = Apollo.QueryResult<ReadAccountingKpisQuery, ReadAccountingKpisQueryVariables>;
export const ReadAccountingOverviewDocument = gql`
    query readAccountingOverview {
  readAccountingOverview {
    periods {
      start
      end
      settlement {
        ...SettlementDateFields
      }
      standardAccounting {
        monthlySettlement {
          ...StandardAccountingFields
        }
        quarterlySettlement {
          ...StandardAccountingFields
        }
        halfYearlySettlement {
          ...StandardAccountingFields
        }
        yearlySettlement {
          ...StandardAccountingFields
        }
      }
      closingAccounting {
        ...ClosingAccountingFields
      }
      unfinishedStandardAccounting {
        monthlySettlement {
          ...StandardAccountingFields
        }
        quarterlySettlement {
          ...StandardAccountingFields
        }
        halfYearlySettlement {
          ...StandardAccountingFields
        }
        yearlySettlement {
          ...StandardAccountingFields
        }
      }
      unfinishedClosingAccounting {
        ...ClosingAccountingFields
      }
    }
    totalPendingInvoices
  }
}
    ${SettlementDateFieldsFragmentDoc}
${StandardAccountingFieldsFragmentDoc}
${ClosingAccountingFieldsFragmentDoc}`;

/**
 * __useReadAccountingOverviewQuery__
 *
 * To run a query within a React component, call `useReadAccountingOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadAccountingOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadAccountingOverviewQuery({
 *   variables: {
 *   },
 * });
 */
export function useReadAccountingOverviewQuery(baseOptions?: Apollo.QueryHookOptions<ReadAccountingOverviewQuery, ReadAccountingOverviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadAccountingOverviewQuery, ReadAccountingOverviewQueryVariables>(ReadAccountingOverviewDocument, options);
      }
export function useReadAccountingOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadAccountingOverviewQuery, ReadAccountingOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadAccountingOverviewQuery, ReadAccountingOverviewQueryVariables>(ReadAccountingOverviewDocument, options);
        }
export type ReadAccountingOverviewQueryHookResult = ReturnType<typeof useReadAccountingOverviewQuery>;
export type ReadAccountingOverviewLazyQueryHookResult = ReturnType<typeof useReadAccountingOverviewLazyQuery>;
export type ReadAccountingOverviewQueryResult = Apollo.QueryResult<ReadAccountingOverviewQuery, ReadAccountingOverviewQueryVariables>;
export const ReadBalancingAreaAccountsDocument = gql`
    query readBalancingAreaAccounts {
  readBalancingAreaAccounts {
    id
    name
    eic
    transmissionSystemOperator {
      id
      name
      code
    }
  }
}
    `;

/**
 * __useReadBalancingAreaAccountsQuery__
 *
 * To run a query within a React component, call `useReadBalancingAreaAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadBalancingAreaAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadBalancingAreaAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useReadBalancingAreaAccountsQuery(baseOptions?: Apollo.QueryHookOptions<ReadBalancingAreaAccountsQuery, ReadBalancingAreaAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadBalancingAreaAccountsQuery, ReadBalancingAreaAccountsQueryVariables>(ReadBalancingAreaAccountsDocument, options);
      }
export function useReadBalancingAreaAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadBalancingAreaAccountsQuery, ReadBalancingAreaAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadBalancingAreaAccountsQuery, ReadBalancingAreaAccountsQueryVariables>(ReadBalancingAreaAccountsDocument, options);
        }
export type ReadBalancingAreaAccountsQueryHookResult = ReturnType<typeof useReadBalancingAreaAccountsQuery>;
export type ReadBalancingAreaAccountsLazyQueryHookResult = ReturnType<typeof useReadBalancingAreaAccountsLazyQuery>;
export type ReadBalancingAreaAccountsQueryResult = Apollo.QueryResult<ReadBalancingAreaAccountsQuery, ReadBalancingAreaAccountsQueryVariables>;
export const ReadBalancingAreaGroupAccountsDocument = gql`
    query readBalancingAreaGroupAccounts {
  readBalancingAreaGroupAccounts {
    id
    name
    eic
    transmissionSystemOperatorId
    suppliers {
      id
    }
  }
}
    `;

/**
 * __useReadBalancingAreaGroupAccountsQuery__
 *
 * To run a query within a React component, call `useReadBalancingAreaGroupAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadBalancingAreaGroupAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadBalancingAreaGroupAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useReadBalancingAreaGroupAccountsQuery(baseOptions?: Apollo.QueryHookOptions<ReadBalancingAreaGroupAccountsQuery, ReadBalancingAreaGroupAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadBalancingAreaGroupAccountsQuery, ReadBalancingAreaGroupAccountsQueryVariables>(ReadBalancingAreaGroupAccountsDocument, options);
      }
export function useReadBalancingAreaGroupAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadBalancingAreaGroupAccountsQuery, ReadBalancingAreaGroupAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadBalancingAreaGroupAccountsQuery, ReadBalancingAreaGroupAccountsQueryVariables>(ReadBalancingAreaGroupAccountsDocument, options);
        }
export type ReadBalancingAreaGroupAccountsQueryHookResult = ReturnType<typeof useReadBalancingAreaGroupAccountsQuery>;
export type ReadBalancingAreaGroupAccountsLazyQueryHookResult = ReturnType<typeof useReadBalancingAreaGroupAccountsLazyQuery>;
export type ReadBalancingAreaGroupAccountsQueryResult = Apollo.QueryResult<ReadBalancingAreaGroupAccountsQuery, ReadBalancingAreaGroupAccountsQueryVariables>;
export const ReadBookingsReportsDocument = gql`
    query readBookingsReports {
  readBookingsReports {
    id
    name
    url
    createdAt
    status
  }
}
    `;

/**
 * __useReadBookingsReportsQuery__
 *
 * To run a query within a React component, call `useReadBookingsReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadBookingsReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadBookingsReportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useReadBookingsReportsQuery(baseOptions?: Apollo.QueryHookOptions<ReadBookingsReportsQuery, ReadBookingsReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadBookingsReportsQuery, ReadBookingsReportsQueryVariables>(ReadBookingsReportsDocument, options);
      }
export function useReadBookingsReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadBookingsReportsQuery, ReadBookingsReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadBookingsReportsQuery, ReadBookingsReportsQueryVariables>(ReadBookingsReportsDocument, options);
        }
export type ReadBookingsReportsQueryHookResult = ReturnType<typeof useReadBookingsReportsQuery>;
export type ReadBookingsReportsLazyQueryHookResult = ReturnType<typeof useReadBookingsReportsLazyQuery>;
export type ReadBookingsReportsQueryResult = Apollo.QueryResult<ReadBookingsReportsQuery, ReadBookingsReportsQueryVariables>;
export const ReadCheckClientSetupDocument = gql`
    query readCheckClientSetup {
  readCheckClientSetup {
    success
    error
  }
}
    `;

/**
 * __useReadCheckClientSetupQuery__
 *
 * To run a query within a React component, call `useReadCheckClientSetupQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadCheckClientSetupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadCheckClientSetupQuery({
 *   variables: {
 *   },
 * });
 */
export function useReadCheckClientSetupQuery(baseOptions?: Apollo.QueryHookOptions<ReadCheckClientSetupQuery, ReadCheckClientSetupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadCheckClientSetupQuery, ReadCheckClientSetupQueryVariables>(ReadCheckClientSetupDocument, options);
      }
export function useReadCheckClientSetupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadCheckClientSetupQuery, ReadCheckClientSetupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadCheckClientSetupQuery, ReadCheckClientSetupQueryVariables>(ReadCheckClientSetupDocument, options);
        }
export type ReadCheckClientSetupQueryHookResult = ReturnType<typeof useReadCheckClientSetupQuery>;
export type ReadCheckClientSetupLazyQueryHookResult = ReturnType<typeof useReadCheckClientSetupLazyQuery>;
export type ReadCheckClientSetupQueryResult = Apollo.QueryResult<ReadCheckClientSetupQuery, ReadCheckClientSetupQueryVariables>;
export const ReadClientDetailsDocument = gql`
    query readClientDetails {
  readClientDetails {
    companyName
    address {
      streetName
      streetNumber
      zipCode
      city
    }
    email
    contact {
      serviceHotline
      serviceEmail
      serviceHours
      footer1
      footer2
      footer3
      footer4
    }
    workspaces {
      name
      companyName
      address {
        streetName
        streetNumber
        zipCode
        city
      }
      email
      contact {
        serviceHotline
        serviceEmail
        serviceHours
        footer1
        footer2
        footer3
        footer4
        headerAddress
      }
      banking {
        iban
        bic
      }
      numberOfFooters
    }
    banking {
      iban
      bic
    }
    contractDraftTemplates {
      pricesheet_letter
      withdrawal_letter
      poa_cancellation_letter
      covering_letter
    }
    numberOfFooters
  }
}
    `;

/**
 * __useReadClientDetailsQuery__
 *
 * To run a query within a React component, call `useReadClientDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadClientDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadClientDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useReadClientDetailsQuery(baseOptions?: Apollo.QueryHookOptions<ReadClientDetailsQuery, ReadClientDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadClientDetailsQuery, ReadClientDetailsQueryVariables>(ReadClientDetailsDocument, options);
      }
export function useReadClientDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadClientDetailsQuery, ReadClientDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadClientDetailsQuery, ReadClientDetailsQueryVariables>(ReadClientDetailsDocument, options);
        }
export type ReadClientDetailsQueryHookResult = ReturnType<typeof useReadClientDetailsQuery>;
export type ReadClientDetailsLazyQueryHookResult = ReturnType<typeof useReadClientDetailsLazyQuery>;
export type ReadClientDetailsQueryResult = Apollo.QueryResult<ReadClientDetailsQuery, ReadClientDetailsQueryVariables>;
export const ReadClientSmtpConfigDocument = gql`
    query readClientSmtpConfig {
  readClientSmtpConfig {
    active
    host
    port
    mailFrom
    secure
    tls
    auth {
      user
    }
  }
}
    `;

/**
 * __useReadClientSmtpConfigQuery__
 *
 * To run a query within a React component, call `useReadClientSmtpConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadClientSmtpConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadClientSmtpConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useReadClientSmtpConfigQuery(baseOptions?: Apollo.QueryHookOptions<ReadClientSmtpConfigQuery, ReadClientSmtpConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadClientSmtpConfigQuery, ReadClientSmtpConfigQueryVariables>(ReadClientSmtpConfigDocument, options);
      }
export function useReadClientSmtpConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadClientSmtpConfigQuery, ReadClientSmtpConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadClientSmtpConfigQuery, ReadClientSmtpConfigQueryVariables>(ReadClientSmtpConfigDocument, options);
        }
export type ReadClientSmtpConfigQueryHookResult = ReturnType<typeof useReadClientSmtpConfigQuery>;
export type ReadClientSmtpConfigLazyQueryHookResult = ReturnType<typeof useReadClientSmtpConfigLazyQuery>;
export type ReadClientSmtpConfigQueryResult = Apollo.QueryResult<ReadClientSmtpConfigQuery, ReadClientSmtpConfigQueryVariables>;
export const ReadConsumptionsDocument = gql`
    query readConsumptions($contractID: ID!) {
  readConsumptions(contractID: $contractID) {
    ajvs {
      startDate
      endDate
      value
    }
    consumptions {
      startDate
      endDate
      value
    }
  }
}
    `;

/**
 * __useReadConsumptionsQuery__
 *
 * To run a query within a React component, call `useReadConsumptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadConsumptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadConsumptionsQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useReadConsumptionsQuery(baseOptions: Apollo.QueryHookOptions<ReadConsumptionsQuery, ReadConsumptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadConsumptionsQuery, ReadConsumptionsQueryVariables>(ReadConsumptionsDocument, options);
      }
export function useReadConsumptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadConsumptionsQuery, ReadConsumptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadConsumptionsQuery, ReadConsumptionsQueryVariables>(ReadConsumptionsDocument, options);
        }
export type ReadConsumptionsQueryHookResult = ReturnType<typeof useReadConsumptionsQuery>;
export type ReadConsumptionsLazyQueryHookResult = ReturnType<typeof useReadConsumptionsLazyQuery>;
export type ReadConsumptionsQueryResult = Apollo.QueryResult<ReadConsumptionsQuery, ReadConsumptionsQueryVariables>;
export const ReadContractDocument = gql`
    query readContract($contractId: ID!) {
  readContract(contractId: $contractId) {
    id
    plantId
    label
    status
    signDate
    startDate
    endDate
    contractType
    invoiceCycle
    cancelationReason
    cancelationDate
    earlyTerminationReason
    debtorLabel
    settlementDate
    settlementDay
    settlementMonth
    paymentDate
    downPayment
    downPaymentStartDate
    downPaymentHistory {
      downPayment
      downPaymentStartDate
    }
    contractMeter {
      malo
      meters {
        id
        contractId
        melo
        meterType
        meterNumber
        meterPlace
        validityStart
        validityEnd
        metering
      }
    }
    electricityGenerationOrGasType
    loadProfile {
      id
      name
    }
    tariffs {
      id
      tariffId
      nameExternal
      nameInternal
      validityStartDate
      validityEndDate
      assignedFrom
      assignedTo
    }
    customer {
      id
      label
      person {
        name
        namePrefix
        contactMobile
        contactPhone
        contactEmail
        companyFlag
        co
      }
      address {
        streetName
        streetNumber
        zipCode
        city
        country
      }
      addressBilling {
        partnerId
        additionalPartnerId
        name
        namePrefix
        email
        additionalName
        additionalNamePrefix
        companySecondaryFlag
        streetName
        streetNumber
        zipCode
        city
        country
        companyFlag
        co
      }
      addressShipping {
        namePrefix
        name
        streetName
        streetNumber
        zipCode
        city
        country
        companyFlag
        co
      }
      birthday
      advertisementAccepted
      documentDeliveryMethod
      bankAccount {
        name
        iban
        bic
        mandateReference
        signedAt
      }
      payer {
        accountHolderId
        name
        number
        iban
        bic
      }
      hasSepa
      isOperator
    }
    workspace {
      id
      name
    }
    documents {
      hash
      fileURL
      type
      contentType
      createdAt
      fileName
      userUpload
      parentGroup
    }
  }
}
    `;

/**
 * __useReadContractQuery__
 *
 * To run a query within a React component, call `useReadContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadContractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadContractQuery({
 *   variables: {
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useReadContractQuery(baseOptions: Apollo.QueryHookOptions<ReadContractQuery, ReadContractQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadContractQuery, ReadContractQueryVariables>(ReadContractDocument, options);
      }
export function useReadContractLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadContractQuery, ReadContractQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadContractQuery, ReadContractQueryVariables>(ReadContractDocument, options);
        }
export type ReadContractQueryHookResult = ReturnType<typeof useReadContractQuery>;
export type ReadContractLazyQueryHookResult = ReturnType<typeof useReadContractLazyQuery>;
export type ReadContractQueryResult = Apollo.QueryResult<ReadContractQuery, ReadContractQueryVariables>;
export const ReadContractAccountingDocument = gql`
    query readContractAccounting($contractId: ID!, $contractLabel: ID!, $startAt: DateTime!, $endAt: DateTime!) {
  readContract(contractId: $contractId) {
    id
    customer {
      id
      bankAccount {
        name
        iban
        bic
        mandateReference
        signedAt
      }
      hasSepa
      isOperator
    }
  }
  readAccountMoves(contractLabel: $contractLabel, startAt: $startAt, endAt: $endAt) {
    processed {
      type
      state
      amount
      bookedAt
      name
      opcName
      sepa
      sepaReturn
      partialAmount
      partialPaidAt
      canceledAt
      canceledName
      paidAt
      bookings {
        type
        date
        amount
        account
      }
    }
    unprocessed {
      account
      name
      ref
      date
      amount
      journal
      sollstellungDate
      matchingNumber
    }
    vouchers {
      name
      date
      amount
      journal
      account
      ref
    }
  }
}
    `;

/**
 * __useReadContractAccountingQuery__
 *
 * To run a query within a React component, call `useReadContractAccountingQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadContractAccountingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadContractAccountingQuery({
 *   variables: {
 *      contractId: // value for 'contractId'
 *      contractLabel: // value for 'contractLabel'
 *      startAt: // value for 'startAt'
 *      endAt: // value for 'endAt'
 *   },
 * });
 */
export function useReadContractAccountingQuery(baseOptions: Apollo.QueryHookOptions<ReadContractAccountingQuery, ReadContractAccountingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadContractAccountingQuery, ReadContractAccountingQueryVariables>(ReadContractAccountingDocument, options);
      }
export function useReadContractAccountingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadContractAccountingQuery, ReadContractAccountingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadContractAccountingQuery, ReadContractAccountingQueryVariables>(ReadContractAccountingDocument, options);
        }
export type ReadContractAccountingQueryHookResult = ReturnType<typeof useReadContractAccountingQuery>;
export type ReadContractAccountingLazyQueryHookResult = ReturnType<typeof useReadContractAccountingLazyQuery>;
export type ReadContractAccountingQueryResult = Apollo.QueryResult<ReadContractAccountingQuery, ReadContractAccountingQueryVariables>;
export const ReadContractAccountingHistoryDocument = gql`
    query readContractAccountingHistory($contractId: ID!) {
  readContractAccountingHistory(contractId: $contractId) {
    lastAccountingEnd
    nextAccountingStart
  }
}
    `;

/**
 * __useReadContractAccountingHistoryQuery__
 *
 * To run a query within a React component, call `useReadContractAccountingHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadContractAccountingHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadContractAccountingHistoryQuery({
 *   variables: {
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useReadContractAccountingHistoryQuery(baseOptions: Apollo.QueryHookOptions<ReadContractAccountingHistoryQuery, ReadContractAccountingHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadContractAccountingHistoryQuery, ReadContractAccountingHistoryQueryVariables>(ReadContractAccountingHistoryDocument, options);
      }
export function useReadContractAccountingHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadContractAccountingHistoryQuery, ReadContractAccountingHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadContractAccountingHistoryQuery, ReadContractAccountingHistoryQueryVariables>(ReadContractAccountingHistoryDocument, options);
        }
export type ReadContractAccountingHistoryQueryHookResult = ReturnType<typeof useReadContractAccountingHistoryQuery>;
export type ReadContractAccountingHistoryLazyQueryHookResult = ReturnType<typeof useReadContractAccountingHistoryLazyQuery>;
export type ReadContractAccountingHistoryQueryResult = Apollo.QueryResult<ReadContractAccountingHistoryQuery, ReadContractAccountingHistoryQueryVariables>;
export const ReadContractByCustomerDocument = gql`
    query readContractByCustomer($customerId: ID!) {
  readContractByCustomer(customerId: $customerId) {
    ...contractSimpleFragment
  }
}
    ${ContractSimpleFragmentFragmentDoc}`;

/**
 * __useReadContractByCustomerQuery__
 *
 * To run a query within a React component, call `useReadContractByCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadContractByCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadContractByCustomerQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useReadContractByCustomerQuery(baseOptions: Apollo.QueryHookOptions<ReadContractByCustomerQuery, ReadContractByCustomerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadContractByCustomerQuery, ReadContractByCustomerQueryVariables>(ReadContractByCustomerDocument, options);
      }
export function useReadContractByCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadContractByCustomerQuery, ReadContractByCustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadContractByCustomerQuery, ReadContractByCustomerQueryVariables>(ReadContractByCustomerDocument, options);
        }
export type ReadContractByCustomerQueryHookResult = ReturnType<typeof useReadContractByCustomerQuery>;
export type ReadContractByCustomerLazyQueryHookResult = ReturnType<typeof useReadContractByCustomerLazyQuery>;
export type ReadContractByCustomerQueryResult = Apollo.QueryResult<ReadContractByCustomerQuery, ReadContractByCustomerQueryVariables>;
export const ReadContractCancelationDateDocument = gql`
    query readContractCancelationDate($contractId: ID!, $tariffId: ID!, $noticeDate: String) {
  readContractCancelationDate(contractId: $contractId, tariffId: $tariffId, noticeDate: $noticeDate)
}
    `;

/**
 * __useReadContractCancelationDateQuery__
 *
 * To run a query within a React component, call `useReadContractCancelationDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadContractCancelationDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadContractCancelationDateQuery({
 *   variables: {
 *      contractId: // value for 'contractId'
 *      tariffId: // value for 'tariffId'
 *      noticeDate: // value for 'noticeDate'
 *   },
 * });
 */
export function useReadContractCancelationDateQuery(baseOptions: Apollo.QueryHookOptions<ReadContractCancelationDateQuery, ReadContractCancelationDateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadContractCancelationDateQuery, ReadContractCancelationDateQueryVariables>(ReadContractCancelationDateDocument, options);
      }
export function useReadContractCancelationDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadContractCancelationDateQuery, ReadContractCancelationDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadContractCancelationDateQuery, ReadContractCancelationDateQueryVariables>(ReadContractCancelationDateDocument, options);
        }
export type ReadContractCancelationDateQueryHookResult = ReturnType<typeof useReadContractCancelationDateQuery>;
export type ReadContractCancelationDateLazyQueryHookResult = ReturnType<typeof useReadContractCancelationDateLazyQuery>;
export type ReadContractCancelationDateQueryResult = Apollo.QueryResult<ReadContractCancelationDateQuery, ReadContractCancelationDateQueryVariables>;
export const ReadContractCommunicationsDocument = gql`
    query readContractCommunications($contractId: ID!) {
  readContract(contractId: $contractId) {
    id
    communications {
      id
      title
      message
      communicatedBy
      date
    }
  }
}
    `;

/**
 * __useReadContractCommunicationsQuery__
 *
 * To run a query within a React component, call `useReadContractCommunicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadContractCommunicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadContractCommunicationsQuery({
 *   variables: {
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useReadContractCommunicationsQuery(baseOptions: Apollo.QueryHookOptions<ReadContractCommunicationsQuery, ReadContractCommunicationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadContractCommunicationsQuery, ReadContractCommunicationsQueryVariables>(ReadContractCommunicationsDocument, options);
      }
export function useReadContractCommunicationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadContractCommunicationsQuery, ReadContractCommunicationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadContractCommunicationsQuery, ReadContractCommunicationsQueryVariables>(ReadContractCommunicationsDocument, options);
        }
export type ReadContractCommunicationsQueryHookResult = ReturnType<typeof useReadContractCommunicationsQuery>;
export type ReadContractCommunicationsLazyQueryHookResult = ReturnType<typeof useReadContractCommunicationsLazyQuery>;
export type ReadContractCommunicationsQueryResult = Apollo.QueryResult<ReadContractCommunicationsQuery, ReadContractCommunicationsQueryVariables>;
export const ReadContractExcerptsDocument = gql`
    query readContractExcerpts($ids: [ID!]!) {
  readContractExcerpts(ids: $ids) {
    id
    label
    startDate
    endDate
    meterNumber
    name
    customerLabel
    isOperator
    meterType
    electricityGenerationOrGasType
    contractStatus
    contractType
  }
}
    `;

/**
 * __useReadContractExcerptsQuery__
 *
 * To run a query within a React component, call `useReadContractExcerptsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadContractExcerptsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadContractExcerptsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useReadContractExcerptsQuery(baseOptions: Apollo.QueryHookOptions<ReadContractExcerptsQuery, ReadContractExcerptsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadContractExcerptsQuery, ReadContractExcerptsQueryVariables>(ReadContractExcerptsDocument, options);
      }
export function useReadContractExcerptsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadContractExcerptsQuery, ReadContractExcerptsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadContractExcerptsQuery, ReadContractExcerptsQueryVariables>(ReadContractExcerptsDocument, options);
        }
export type ReadContractExcerptsQueryHookResult = ReturnType<typeof useReadContractExcerptsQuery>;
export type ReadContractExcerptsLazyQueryHookResult = ReturnType<typeof useReadContractExcerptsLazyQuery>;
export type ReadContractExcerptsQueryResult = Apollo.QueryResult<ReadContractExcerptsQuery, ReadContractExcerptsQueryVariables>;
export const ReadContractFastDocument = gql`
    query readContractFast($contractId: ID!, $contractLabel: String!) {
  readContract: readContractFast(contractId: $contractId, contractLabel: $contractLabel) {
    id
    plantId
    label
    status
    signDate
    startDate
    endDate
    contractType
    invoiceCycle
    cancelationReason
    cancelationDate
    earlyTerminationReason
    debtorLabel
    settlementDate
    settlementDay
    settlementMonth
    paymentDate
    downPayment
    downPaymentStartDate
    downPaymentHistory {
      downPayment
      downPaymentStartDate
    }
    contractMeter {
      malo
      meters {
        id
        contractId
        melo
        meterType
        meterNumber
        meterPlace
        validityStart
        validityEnd
        metering
      }
    }
    electricityGenerationOrGasType
    loadProfile {
      id
      name
    }
    tariffs {
      id
      tariffId
      nameExternal
      nameInternal
      validityStartDate
      validityEndDate
      assignedFrom
      assignedTo
    }
    customer {
      id
      label
      person {
        name
        namePrefix
        contactMobile
        contactPhone
        contactEmail
        companyFlag
        co
      }
      address {
        streetName
        streetNumber
        zipCode
        city
        country
      }
      addressBilling {
        partnerId
        additionalPartnerId
        name
        namePrefix
        email
        additionalName
        additionalNamePrefix
        companySecondaryFlag
        streetName
        streetNumber
        zipCode
        city
        country
        companyFlag
        co
      }
      addressShipping {
        namePrefix
        name
        streetName
        streetNumber
        zipCode
        city
        country
        companyFlag
        co
      }
      birthday
      advertisementAccepted
      documentDeliveryMethod
      bankAccount {
        name
        iban
        bic
        mandateReference
        signedAt
      }
      hasSepa
      isOperator
    }
    workspace {
      id
      name
    }
    documents {
      hash
      fileURL
      type
      contentType
      createdAt
      fileName
      userUpload
      parentGroup
    }
  }
}
    `;

/**
 * __useReadContractFastQuery__
 *
 * To run a query within a React component, call `useReadContractFastQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadContractFastQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadContractFastQuery({
 *   variables: {
 *      contractId: // value for 'contractId'
 *      contractLabel: // value for 'contractLabel'
 *   },
 * });
 */
export function useReadContractFastQuery(baseOptions: Apollo.QueryHookOptions<ReadContractFastQuery, ReadContractFastQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadContractFastQuery, ReadContractFastQueryVariables>(ReadContractFastDocument, options);
      }
export function useReadContractFastLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadContractFastQuery, ReadContractFastQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadContractFastQuery, ReadContractFastQueryVariables>(ReadContractFastDocument, options);
        }
export type ReadContractFastQueryHookResult = ReturnType<typeof useReadContractFastQuery>;
export type ReadContractFastLazyQueryHookResult = ReturnType<typeof useReadContractFastLazyQuery>;
export type ReadContractFastQueryResult = Apollo.QueryResult<ReadContractFastQuery, ReadContractFastQueryVariables>;
export const ReadContractSimpleDocument = gql`
    query readContractSimple($contractId: ID!) {
  readContract(contractId: $contractId) {
    ...contractSimpleFragment
  }
}
    ${ContractSimpleFragmentFragmentDoc}`;

/**
 * __useReadContractSimpleQuery__
 *
 * To run a query within a React component, call `useReadContractSimpleQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadContractSimpleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadContractSimpleQuery({
 *   variables: {
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useReadContractSimpleQuery(baseOptions: Apollo.QueryHookOptions<ReadContractSimpleQuery, ReadContractSimpleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadContractSimpleQuery, ReadContractSimpleQueryVariables>(ReadContractSimpleDocument, options);
      }
export function useReadContractSimpleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadContractSimpleQuery, ReadContractSimpleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadContractSimpleQuery, ReadContractSimpleQueryVariables>(ReadContractSimpleDocument, options);
        }
export type ReadContractSimpleQueryHookResult = ReturnType<typeof useReadContractSimpleQuery>;
export type ReadContractSimpleLazyQueryHookResult = ReturnType<typeof useReadContractSimpleLazyQuery>;
export type ReadContractSimpleQueryResult = Apollo.QueryResult<ReadContractSimpleQuery, ReadContractSimpleQueryVariables>;
export const ReadContractsDocument = gql`
    query readContracts($contractLabels: [ID!]!) {
  readContracts(contractLabels: $contractLabels) {
    id
    label
    documentDeliveryMethod
  }
}
    `;

/**
 * __useReadContractsQuery__
 *
 * To run a query within a React component, call `useReadContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadContractsQuery({
 *   variables: {
 *      contractLabels: // value for 'contractLabels'
 *   },
 * });
 */
export function useReadContractsQuery(baseOptions: Apollo.QueryHookOptions<ReadContractsQuery, ReadContractsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadContractsQuery, ReadContractsQueryVariables>(ReadContractsDocument, options);
      }
export function useReadContractsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadContractsQuery, ReadContractsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadContractsQuery, ReadContractsQueryVariables>(ReadContractsDocument, options);
        }
export type ReadContractsQueryHookResult = ReturnType<typeof useReadContractsQuery>;
export type ReadContractsLazyQueryHookResult = ReturnType<typeof useReadContractsLazyQuery>;
export type ReadContractsQueryResult = Apollo.QueryResult<ReadContractsQuery, ReadContractsQueryVariables>;
export const ReadContractsBatchDocument = gql`
    query readContractsBatch($ids: [ID!]!, $byLabel: Boolean) {
  readContractsBatch(ids: $ids, byLabel: $byLabel) {
    id
    label
    startDate
    status
    plantId
    customer {
      label
      person {
        name
      }
    }
    contractMeter {
      malo
      meters {
        id
        meterType
        msoType
      }
    }
  }
}
    `;

/**
 * __useReadContractsBatchQuery__
 *
 * To run a query within a React component, call `useReadContractsBatchQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadContractsBatchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadContractsBatchQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      byLabel: // value for 'byLabel'
 *   },
 * });
 */
export function useReadContractsBatchQuery(baseOptions: Apollo.QueryHookOptions<ReadContractsBatchQuery, ReadContractsBatchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadContractsBatchQuery, ReadContractsBatchQueryVariables>(ReadContractsBatchDocument, options);
      }
export function useReadContractsBatchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadContractsBatchQuery, ReadContractsBatchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadContractsBatchQuery, ReadContractsBatchQueryVariables>(ReadContractsBatchDocument, options);
        }
export type ReadContractsBatchQueryHookResult = ReturnType<typeof useReadContractsBatchQuery>;
export type ReadContractsBatchLazyQueryHookResult = ReturnType<typeof useReadContractsBatchLazyQuery>;
export type ReadContractsBatchQueryResult = Apollo.QueryResult<ReadContractsBatchQuery, ReadContractsBatchQueryVariables>;
export const ReadContractsBatchFullDocument = gql`
    query readContractsBatchFull($ids: [ID!]!) {
  readContractsBatch(ids: $ids) {
    id
    label
    signDate
    startDate
    status
    paymentDate
    contractType
    downPayment
    downPaymentStartDate
    customer {
      id
      label
      person {
        name
        contactMobile
        contactPhone
      }
      address {
        streetName
        streetNumber
        zipCode
        city
        country
      }
      addressBilling {
        partnerId
        additionalPartnerId
        name
        namePrefix
        email
        additionalName
        additionalNamePrefix
        companySecondaryFlag
        streetName
        streetNumber
        zipCode
        city
        country
        companyFlag
      }
      addressShipping {
        namePrefix
        name
        streetName
        streetNumber
        zipCode
        city
        country
        companyFlag
      }
      advertisementAccepted
      documentDeliveryMethod
      bankAccount {
        name
        iban
        bic
        mandateReference
        signedAt
      }
      hasSepa
    }
  }
}
    `;

/**
 * __useReadContractsBatchFullQuery__
 *
 * To run a query within a React component, call `useReadContractsBatchFullQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadContractsBatchFullQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadContractsBatchFullQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useReadContractsBatchFullQuery(baseOptions: Apollo.QueryHookOptions<ReadContractsBatchFullQuery, ReadContractsBatchFullQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadContractsBatchFullQuery, ReadContractsBatchFullQueryVariables>(ReadContractsBatchFullDocument, options);
      }
export function useReadContractsBatchFullLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadContractsBatchFullQuery, ReadContractsBatchFullQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadContractsBatchFullQuery, ReadContractsBatchFullQueryVariables>(ReadContractsBatchFullDocument, options);
        }
export type ReadContractsBatchFullQueryHookResult = ReturnType<typeof useReadContractsBatchFullQuery>;
export type ReadContractsBatchFullLazyQueryHookResult = ReturnType<typeof useReadContractsBatchFullLazyQuery>;
export type ReadContractsBatchFullQueryResult = Apollo.QueryResult<ReadContractsBatchFullQuery, ReadContractsBatchFullQueryVariables>;
export const ReadContractsForMeterDocument = gql`
    query readContractsForMeter($meterId: ID!) {
  readContractsForMeter(meterId: $meterId) {
    id
    label
    customerNames
    meters {
      id
      validityStart
      validityEnd
    }
  }
}
    `;

/**
 * __useReadContractsForMeterQuery__
 *
 * To run a query within a React component, call `useReadContractsForMeterQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadContractsForMeterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadContractsForMeterQuery({
 *   variables: {
 *      meterId: // value for 'meterId'
 *   },
 * });
 */
export function useReadContractsForMeterQuery(baseOptions: Apollo.QueryHookOptions<ReadContractsForMeterQuery, ReadContractsForMeterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadContractsForMeterQuery, ReadContractsForMeterQueryVariables>(ReadContractsForMeterDocument, options);
      }
export function useReadContractsForMeterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadContractsForMeterQuery, ReadContractsForMeterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadContractsForMeterQuery, ReadContractsForMeterQueryVariables>(ReadContractsForMeterDocument, options);
        }
export type ReadContractsForMeterQueryHookResult = ReturnType<typeof useReadContractsForMeterQuery>;
export type ReadContractsForMeterLazyQueryHookResult = ReturnType<typeof useReadContractsForMeterLazyQuery>;
export type ReadContractsForMeterQueryResult = Apollo.QueryResult<ReadContractsForMeterQuery, ReadContractsForMeterQueryVariables>;
export const ReadCurrentUserDocument = gql`
    query readCurrentUser {
  readCurrentUser {
    id
    firstName
    lastName
    email
    company {
      id
      name
      logo
    }
  }
}
    `;

/**
 * __useReadCurrentUserQuery__
 *
 * To run a query within a React component, call `useReadCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useReadCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<ReadCurrentUserQuery, ReadCurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadCurrentUserQuery, ReadCurrentUserQueryVariables>(ReadCurrentUserDocument, options);
      }
export function useReadCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadCurrentUserQuery, ReadCurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadCurrentUserQuery, ReadCurrentUserQueryVariables>(ReadCurrentUserDocument, options);
        }
export type ReadCurrentUserQueryHookResult = ReturnType<typeof useReadCurrentUserQuery>;
export type ReadCurrentUserLazyQueryHookResult = ReturnType<typeof useReadCurrentUserLazyQuery>;
export type ReadCurrentUserQueryResult = Apollo.QueryResult<ReadCurrentUserQuery, ReadCurrentUserQueryVariables>;
export const ReadCustomersSimpleDocument = gql`
    query readCustomersSimple($isOperator: Boolean) {
  readCustomers(isOperator: $isOperator) {
    id
    label
    person {
      name
    }
    address {
      streetName
      streetNumber
      zipCode
      city
      country
    }
  }
}
    `;

/**
 * __useReadCustomersSimpleQuery__
 *
 * To run a query within a React component, call `useReadCustomersSimpleQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadCustomersSimpleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadCustomersSimpleQuery({
 *   variables: {
 *      isOperator: // value for 'isOperator'
 *   },
 * });
 */
export function useReadCustomersSimpleQuery(baseOptions?: Apollo.QueryHookOptions<ReadCustomersSimpleQuery, ReadCustomersSimpleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadCustomersSimpleQuery, ReadCustomersSimpleQueryVariables>(ReadCustomersSimpleDocument, options);
      }
export function useReadCustomersSimpleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadCustomersSimpleQuery, ReadCustomersSimpleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadCustomersSimpleQuery, ReadCustomersSimpleQueryVariables>(ReadCustomersSimpleDocument, options);
        }
export type ReadCustomersSimpleQueryHookResult = ReturnType<typeof useReadCustomersSimpleQuery>;
export type ReadCustomersSimpleLazyQueryHookResult = ReturnType<typeof useReadCustomersSimpleLazyQuery>;
export type ReadCustomersSimpleQueryResult = Apollo.QueryResult<ReadCustomersSimpleQuery, ReadCustomersSimpleQueryVariables>;
export const ReadDashboardDocument = gql`
    query readDashboard($newsPostTypes: [NewsPostType!]!, $latestNewsLimit: Int = 1) {
  plantKpis: readProjects {
    plants {
      kpis {
        units
        unitsMembers
      }
    }
  }
  accountingKpis: readAccountingKpis {
    totalInvoicesMandate
    cancelledInvoicesMandate
    confirmedInvoicesMandate
  }
  drafts: readInvoiceDraftExcerpts {
    customerName
    workflow {
      id
      alias
    }
  }
  latestNews: readNewPosts(types: $newsPostTypes, offset: 0, limit: $latestNewsLimit) {
    id
    type
    title
    createdAt
    body
  }
  oldNews: readNewPosts(types: $newsPostTypes, offset: $latestNewsLimit, limit: 10) {
    id
    type
    title
    forumId
    createdAt
  }
}
    `;

/**
 * __useReadDashboardQuery__
 *
 * To run a query within a React component, call `useReadDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadDashboardQuery({
 *   variables: {
 *      newsPostTypes: // value for 'newsPostTypes'
 *      latestNewsLimit: // value for 'latestNewsLimit'
 *   },
 * });
 */
export function useReadDashboardQuery(baseOptions: Apollo.QueryHookOptions<ReadDashboardQuery, ReadDashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadDashboardQuery, ReadDashboardQueryVariables>(ReadDashboardDocument, options);
      }
export function useReadDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadDashboardQuery, ReadDashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadDashboardQuery, ReadDashboardQueryVariables>(ReadDashboardDocument, options);
        }
export type ReadDashboardQueryHookResult = ReturnType<typeof useReadDashboardQuery>;
export type ReadDashboardLazyQueryHookResult = ReturnType<typeof useReadDashboardLazyQuery>;
export type ReadDashboardQueryResult = Apollo.QueryResult<ReadDashboardQuery, ReadDashboardQueryVariables>;
export const ReadDocumentsDocument = gql`
    query readDocuments($entityId: String, $entityType: String, $groupId: String) {
  readDocuments(entityId: $entityId, entityType: $entityType, groupId: $groupId) {
    createdAt
    fileURL
    type
    fileName
    contentType
    hash
    __typename
    parentGroup
    userUpload
  }
}
    `;

/**
 * __useReadDocumentsQuery__
 *
 * To run a query within a React component, call `useReadDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadDocumentsQuery({
 *   variables: {
 *      entityId: // value for 'entityId'
 *      entityType: // value for 'entityType'
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useReadDocumentsQuery(baseOptions?: Apollo.QueryHookOptions<ReadDocumentsQuery, ReadDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadDocumentsQuery, ReadDocumentsQueryVariables>(ReadDocumentsDocument, options);
      }
export function useReadDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadDocumentsQuery, ReadDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadDocumentsQuery, ReadDocumentsQueryVariables>(ReadDocumentsDocument, options);
        }
export type ReadDocumentsQueryHookResult = ReturnType<typeof useReadDocumentsQuery>;
export type ReadDocumentsLazyQueryHookResult = ReturnType<typeof useReadDocumentsLazyQuery>;
export type ReadDocumentsQueryResult = Apollo.QueryResult<ReadDocumentsQuery, ReadDocumentsQueryVariables>;
export const ReadInvoiceDraftsQueryDocument = gql`
    query readInvoiceDraftsQuery($filterByOPCName: String, $showDocuments: Boolean) {
  drafts: readInvoiceDrafts(filterByOPCName: $filterByOPCName, showDocuments: $showDocuments) {
    id
    customerName
    amount
    dueDate
    contractId
    invoiceId
    confirmed
    invoiceDocumentUrl
    documentDeliveryMethod
  }
}
    `;

/**
 * __useReadInvoiceDraftsQueryQuery__
 *
 * To run a query within a React component, call `useReadInvoiceDraftsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadInvoiceDraftsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadInvoiceDraftsQueryQuery({
 *   variables: {
 *      filterByOPCName: // value for 'filterByOPCName'
 *      showDocuments: // value for 'showDocuments'
 *   },
 * });
 */
export function useReadInvoiceDraftsQueryQuery(baseOptions?: Apollo.QueryHookOptions<ReadInvoiceDraftsQueryQuery, ReadInvoiceDraftsQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadInvoiceDraftsQueryQuery, ReadInvoiceDraftsQueryQueryVariables>(ReadInvoiceDraftsQueryDocument, options);
      }
export function useReadInvoiceDraftsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadInvoiceDraftsQueryQuery, ReadInvoiceDraftsQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadInvoiceDraftsQueryQuery, ReadInvoiceDraftsQueryQueryVariables>(ReadInvoiceDraftsQueryDocument, options);
        }
export type ReadInvoiceDraftsQueryQueryHookResult = ReturnType<typeof useReadInvoiceDraftsQueryQuery>;
export type ReadInvoiceDraftsQueryLazyQueryHookResult = ReturnType<typeof useReadInvoiceDraftsQueryLazyQuery>;
export type ReadInvoiceDraftsQueryQueryResult = Apollo.QueryResult<ReadInvoiceDraftsQueryQuery, ReadInvoiceDraftsQueryQueryVariables>;
export const ReadLoadProfilesDocument = gql`
    query readLoadProfiles($balancingAreaAccountId: ID!) {
  readLoadProfiles(balancingAreaAccountId: $balancingAreaAccountId) {
    id
    name
  }
}
    `;

/**
 * __useReadLoadProfilesQuery__
 *
 * To run a query within a React component, call `useReadLoadProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadLoadProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadLoadProfilesQuery({
 *   variables: {
 *      balancingAreaAccountId: // value for 'balancingAreaAccountId'
 *   },
 * });
 */
export function useReadLoadProfilesQuery(baseOptions: Apollo.QueryHookOptions<ReadLoadProfilesQuery, ReadLoadProfilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadLoadProfilesQuery, ReadLoadProfilesQueryVariables>(ReadLoadProfilesDocument, options);
      }
export function useReadLoadProfilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadLoadProfilesQuery, ReadLoadProfilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadLoadProfilesQuery, ReadLoadProfilesQueryVariables>(ReadLoadProfilesDocument, options);
        }
export type ReadLoadProfilesQueryHookResult = ReturnType<typeof useReadLoadProfilesQuery>;
export type ReadLoadProfilesLazyQueryHookResult = ReturnType<typeof useReadLoadProfilesLazyQuery>;
export type ReadLoadProfilesQueryResult = Apollo.QueryResult<ReadLoadProfilesQuery, ReadLoadProfilesQueryVariables>;
export const ReadMaloBoundDocument = gql`
    query readMaloBound($contractStartDate: String!, $contractEndDate: String, $malo: String!) {
  readMaloBound(contractStartDate: $contractStartDate, contractEndDate: $contractEndDate, malo: $malo) {
    bound
  }
}
    `;

/**
 * __useReadMaloBoundQuery__
 *
 * To run a query within a React component, call `useReadMaloBoundQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadMaloBoundQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadMaloBoundQuery({
 *   variables: {
 *      contractStartDate: // value for 'contractStartDate'
 *      contractEndDate: // value for 'contractEndDate'
 *      malo: // value for 'malo'
 *   },
 * });
 */
export function useReadMaloBoundQuery(baseOptions: Apollo.QueryHookOptions<ReadMaloBoundQuery, ReadMaloBoundQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadMaloBoundQuery, ReadMaloBoundQueryVariables>(ReadMaloBoundDocument, options);
      }
export function useReadMaloBoundLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadMaloBoundQuery, ReadMaloBoundQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadMaloBoundQuery, ReadMaloBoundQueryVariables>(ReadMaloBoundDocument, options);
        }
export type ReadMaloBoundQueryHookResult = ReturnType<typeof useReadMaloBoundQuery>;
export type ReadMaloBoundLazyQueryHookResult = ReturnType<typeof useReadMaloBoundLazyQuery>;
export type ReadMaloBoundQueryResult = Apollo.QueryResult<ReadMaloBoundQuery, ReadMaloBoundQueryVariables>;
export const ReadMeloBoundDocument = gql`
    query readMeloBound($contractStartDate: String!, $contractEndDate: String, $melo: String!) {
  readMeloBound(contractStartDate: $contractStartDate, contractEndDate: $contractEndDate, melo: $melo) {
    bound
  }
}
    `;

/**
 * __useReadMeloBoundQuery__
 *
 * To run a query within a React component, call `useReadMeloBoundQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadMeloBoundQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadMeloBoundQuery({
 *   variables: {
 *      contractStartDate: // value for 'contractStartDate'
 *      contractEndDate: // value for 'contractEndDate'
 *      melo: // value for 'melo'
 *   },
 * });
 */
export function useReadMeloBoundQuery(baseOptions: Apollo.QueryHookOptions<ReadMeloBoundQuery, ReadMeloBoundQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadMeloBoundQuery, ReadMeloBoundQueryVariables>(ReadMeloBoundDocument, options);
      }
export function useReadMeloBoundLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadMeloBoundQuery, ReadMeloBoundQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadMeloBoundQuery, ReadMeloBoundQueryVariables>(ReadMeloBoundDocument, options);
        }
export type ReadMeloBoundQueryHookResult = ReturnType<typeof useReadMeloBoundQuery>;
export type ReadMeloBoundLazyQueryHookResult = ReturnType<typeof useReadMeloBoundLazyQuery>;
export type ReadMeloBoundQueryResult = Apollo.QueryResult<ReadMeloBoundQuery, ReadMeloBoundQueryVariables>;
export const ReadMetadataDocument = gql`
    query readMetadata($contractLabel: ID!) {
  readMetadata(contractLabel: $contractLabel) {
    key
    value
  }
}
    `;

/**
 * __useReadMetadataQuery__
 *
 * To run a query within a React component, call `useReadMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadMetadataQuery({
 *   variables: {
 *      contractLabel: // value for 'contractLabel'
 *   },
 * });
 */
export function useReadMetadataQuery(baseOptions: Apollo.QueryHookOptions<ReadMetadataQuery, ReadMetadataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadMetadataQuery, ReadMetadataQueryVariables>(ReadMetadataDocument, options);
      }
export function useReadMetadataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadMetadataQuery, ReadMetadataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadMetadataQuery, ReadMetadataQueryVariables>(ReadMetadataDocument, options);
        }
export type ReadMetadataQueryHookResult = ReturnType<typeof useReadMetadataQuery>;
export type ReadMetadataLazyQueryHookResult = ReturnType<typeof useReadMetadataLazyQuery>;
export type ReadMetadataQueryResult = Apollo.QueryResult<ReadMetadataQuery, ReadMetadataQueryVariables>;
export const ReadMetadataKeysDocument = gql`
    query readMetadataKeys($contractLabel: ID!) {
  readMetadataKeys(contractLabel: $contractLabel) {
    key
  }
}
    `;

/**
 * __useReadMetadataKeysQuery__
 *
 * To run a query within a React component, call `useReadMetadataKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadMetadataKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadMetadataKeysQuery({
 *   variables: {
 *      contractLabel: // value for 'contractLabel'
 *   },
 * });
 */
export function useReadMetadataKeysQuery(baseOptions: Apollo.QueryHookOptions<ReadMetadataKeysQuery, ReadMetadataKeysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadMetadataKeysQuery, ReadMetadataKeysQueryVariables>(ReadMetadataKeysDocument, options);
      }
export function useReadMetadataKeysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadMetadataKeysQuery, ReadMetadataKeysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadMetadataKeysQuery, ReadMetadataKeysQueryVariables>(ReadMetadataKeysDocument, options);
        }
export type ReadMetadataKeysQueryHookResult = ReturnType<typeof useReadMetadataKeysQuery>;
export type ReadMetadataKeysLazyQueryHookResult = ReturnType<typeof useReadMetadataKeysLazyQuery>;
export type ReadMetadataKeysQueryResult = Apollo.QueryResult<ReadMetadataKeysQuery, ReadMetadataKeysQueryVariables>;
export const ReadMeterDocument = gql`
    query readMeter($id: ID!) {
  readMeter(id: $id) {
    id
    meterNumber
    melo
    converterFactor
    validityEnd
    meterType
    msoType
    contractId
    contract {
      customer {
        id
        label
        person {
          name
          contactMobile
          contactPhone
        }
      }
    }
    metering
    wasEverBound
    inUse
    plantId
    meterPlace
    validityStart
  }
}
    `;

/**
 * __useReadMeterQuery__
 *
 * To run a query within a React component, call `useReadMeterQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadMeterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadMeterQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReadMeterQuery(baseOptions: Apollo.QueryHookOptions<ReadMeterQuery, ReadMeterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadMeterQuery, ReadMeterQueryVariables>(ReadMeterDocument, options);
      }
export function useReadMeterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadMeterQuery, ReadMeterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadMeterQuery, ReadMeterQueryVariables>(ReadMeterDocument, options);
        }
export type ReadMeterQueryHookResult = ReturnType<typeof useReadMeterQuery>;
export type ReadMeterLazyQueryHookResult = ReturnType<typeof useReadMeterLazyQuery>;
export type ReadMeterQueryResult = Apollo.QueryResult<ReadMeterQuery, ReadMeterQueryVariables>;
export const ReadMeterReadingsDocument = gql`
    query readMeterReadings($meterId: ID!, $startDate: DateTime!, $endDate: DateTime!, $metering: Metering!) {
  readMeterReadings(meterId: $meterId, startDate: $startDate, endDate: $endDate, metering: $metering) {
    from
    to
    obis
    value
    reason
    hint
    valueStatus
    usedForBilling
    melo
  }
}
    `;

/**
 * __useReadMeterReadingsQuery__
 *
 * To run a query within a React component, call `useReadMeterReadingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadMeterReadingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadMeterReadingsQuery({
 *   variables: {
 *      meterId: // value for 'meterId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      metering: // value for 'metering'
 *   },
 * });
 */
export function useReadMeterReadingsQuery(baseOptions: Apollo.QueryHookOptions<ReadMeterReadingsQuery, ReadMeterReadingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadMeterReadingsQuery, ReadMeterReadingsQueryVariables>(ReadMeterReadingsDocument, options);
      }
export function useReadMeterReadingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadMeterReadingsQuery, ReadMeterReadingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadMeterReadingsQuery, ReadMeterReadingsQueryVariables>(ReadMeterReadingsDocument, options);
        }
export type ReadMeterReadingsQueryHookResult = ReturnType<typeof useReadMeterReadingsQuery>;
export type ReadMeterReadingsLazyQueryHookResult = ReturnType<typeof useReadMeterReadingsLazyQuery>;
export type ReadMeterReadingsQueryResult = Apollo.QueryResult<ReadMeterReadingsQuery, ReadMeterReadingsQueryVariables>;
export const ReadMeterSimpleDocument = gql`
    query readMeterSimple($id: ID!) {
  readMeter(id: $id) {
    id
    meterNumber
    isBound
    wasEverBound
    contractLabel
    validityStart
    validityEnd
  }
}
    `;

/**
 * __useReadMeterSimpleQuery__
 *
 * To run a query within a React component, call `useReadMeterSimpleQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadMeterSimpleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadMeterSimpleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReadMeterSimpleQuery(baseOptions: Apollo.QueryHookOptions<ReadMeterSimpleQuery, ReadMeterSimpleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadMeterSimpleQuery, ReadMeterSimpleQueryVariables>(ReadMeterSimpleDocument, options);
      }
export function useReadMeterSimpleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadMeterSimpleQuery, ReadMeterSimpleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadMeterSimpleQuery, ReadMeterSimpleQueryVariables>(ReadMeterSimpleDocument, options);
        }
export type ReadMeterSimpleQueryHookResult = ReturnType<typeof useReadMeterSimpleQuery>;
export type ReadMeterSimpleLazyQueryHookResult = ReturnType<typeof useReadMeterSimpleLazyQuery>;
export type ReadMeterSimpleQueryResult = Apollo.QueryResult<ReadMeterSimpleQuery, ReadMeterSimpleQueryVariables>;
export const ReadMeteringPointOperatorsDocument = gql`
    query readMeteringPointOperators {
  readMeteringPointOperators {
    id
    code
    name
  }
}
    `;

/**
 * __useReadMeteringPointOperatorsQuery__
 *
 * To run a query within a React component, call `useReadMeteringPointOperatorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadMeteringPointOperatorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadMeteringPointOperatorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useReadMeteringPointOperatorsQuery(baseOptions?: Apollo.QueryHookOptions<ReadMeteringPointOperatorsQuery, ReadMeteringPointOperatorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadMeteringPointOperatorsQuery, ReadMeteringPointOperatorsQueryVariables>(ReadMeteringPointOperatorsDocument, options);
      }
export function useReadMeteringPointOperatorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadMeteringPointOperatorsQuery, ReadMeteringPointOperatorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadMeteringPointOperatorsQuery, ReadMeteringPointOperatorsQueryVariables>(ReadMeteringPointOperatorsDocument, options);
        }
export type ReadMeteringPointOperatorsQueryHookResult = ReturnType<typeof useReadMeteringPointOperatorsQuery>;
export type ReadMeteringPointOperatorsLazyQueryHookResult = ReturnType<typeof useReadMeteringPointOperatorsLazyQuery>;
export type ReadMeteringPointOperatorsQueryResult = Apollo.QueryResult<ReadMeteringPointOperatorsQuery, ReadMeteringPointOperatorsQueryVariables>;
export const ReadMetersByPlantDocument = gql`
    query readMetersByPlant($plantId: ID) {
  readMeters(plantId: $plantId) {
    id
    meterNumber
  }
}
    `;

/**
 * __useReadMetersByPlantQuery__
 *
 * To run a query within a React component, call `useReadMetersByPlantQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadMetersByPlantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadMetersByPlantQuery({
 *   variables: {
 *      plantId: // value for 'plantId'
 *   },
 * });
 */
export function useReadMetersByPlantQuery(baseOptions?: Apollo.QueryHookOptions<ReadMetersByPlantQuery, ReadMetersByPlantQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadMetersByPlantQuery, ReadMetersByPlantQueryVariables>(ReadMetersByPlantDocument, options);
      }
export function useReadMetersByPlantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadMetersByPlantQuery, ReadMetersByPlantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadMetersByPlantQuery, ReadMetersByPlantQueryVariables>(ReadMetersByPlantDocument, options);
        }
export type ReadMetersByPlantQueryHookResult = ReturnType<typeof useReadMetersByPlantQuery>;
export type ReadMetersByPlantLazyQueryHookResult = ReturnType<typeof useReadMetersByPlantLazyQuery>;
export type ReadMetersByPlantQueryResult = Apollo.QueryResult<ReadMetersByPlantQuery, ReadMetersByPlantQueryVariables>;
export const ReadMetersSimpleDocument = gql`
    query readMetersSimple {
  readMeters {
    id
    meterNumber
  }
}
    `;

/**
 * __useReadMetersSimpleQuery__
 *
 * To run a query within a React component, call `useReadMetersSimpleQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadMetersSimpleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadMetersSimpleQuery({
 *   variables: {
 *   },
 * });
 */
export function useReadMetersSimpleQuery(baseOptions?: Apollo.QueryHookOptions<ReadMetersSimpleQuery, ReadMetersSimpleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadMetersSimpleQuery, ReadMetersSimpleQueryVariables>(ReadMetersSimpleDocument, options);
      }
export function useReadMetersSimpleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadMetersSimpleQuery, ReadMetersSimpleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadMetersSimpleQuery, ReadMetersSimpleQueryVariables>(ReadMetersSimpleDocument, options);
        }
export type ReadMetersSimpleQueryHookResult = ReturnType<typeof useReadMetersSimpleQuery>;
export type ReadMetersSimpleLazyQueryHookResult = ReturnType<typeof useReadMetersSimpleLazyQuery>;
export type ReadMetersSimpleQueryResult = Apollo.QueryResult<ReadMetersSimpleQuery, ReadMetersSimpleQueryVariables>;
export const ReadMkvTemplatesDocument = gql`
    query readMkvTemplates {
  readMkvTemplates {
    sumMeterFeedIn {
      ...NkvTemplateFragment
    }
    sumMeterConsumption {
      ...NkvTemplateFragment
    }
    prodMeter {
      ...NkvTemplateFragment
    }
    cascadeMeter {
      ...NkvTemplateFragment
    }
  }
}
    ${NkvTemplateFragmentFragmentDoc}`;

/**
 * __useReadMkvTemplatesQuery__
 *
 * To run a query within a React component, call `useReadMkvTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadMkvTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadMkvTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useReadMkvTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<ReadMkvTemplatesQuery, ReadMkvTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadMkvTemplatesQuery, ReadMkvTemplatesQueryVariables>(ReadMkvTemplatesDocument, options);
      }
export function useReadMkvTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadMkvTemplatesQuery, ReadMkvTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadMkvTemplatesQuery, ReadMkvTemplatesQueryVariables>(ReadMkvTemplatesDocument, options);
        }
export type ReadMkvTemplatesQueryHookResult = ReturnType<typeof useReadMkvTemplatesQuery>;
export type ReadMkvTemplatesLazyQueryHookResult = ReturnType<typeof useReadMkvTemplatesLazyQuery>;
export type ReadMkvTemplatesQueryResult = Apollo.QueryResult<ReadMkvTemplatesQuery, ReadMkvTemplatesQueryVariables>;
export const ReadMkvTemplateDocument = gql`
    query readMkvTemplate($template: String!) {
  readMkvTemplate(template: $template) {
    ...NkvTemplateFragment
  }
}
    ${NkvTemplateFragmentFragmentDoc}`;

/**
 * __useReadMkvTemplateQuery__
 *
 * To run a query within a React component, call `useReadMkvTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadMkvTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadMkvTemplateQuery({
 *   variables: {
 *      template: // value for 'template'
 *   },
 * });
 */
export function useReadMkvTemplateQuery(baseOptions: Apollo.QueryHookOptions<ReadMkvTemplateQuery, ReadMkvTemplateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadMkvTemplateQuery, ReadMkvTemplateQueryVariables>(ReadMkvTemplateDocument, options);
      }
export function useReadMkvTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadMkvTemplateQuery, ReadMkvTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadMkvTemplateQuery, ReadMkvTemplateQueryVariables>(ReadMkvTemplateDocument, options);
        }
export type ReadMkvTemplateQueryHookResult = ReturnType<typeof useReadMkvTemplateQuery>;
export type ReadMkvTemplateLazyQueryHookResult = ReturnType<typeof useReadMkvTemplateLazyQuery>;
export type ReadMkvTemplateQueryResult = Apollo.QueryResult<ReadMkvTemplateQuery, ReadMkvTemplateQueryVariables>;
export const ReadNewsCommentDocument = gql`
    query readNewsComment($topicId: ID!, $type: NewsPostType!) {
  readNewsComment(topicId: $topicId, type: $type) {
    id
    type
    body
  }
}
    `;

/**
 * __useReadNewsCommentQuery__
 *
 * To run a query within a React component, call `useReadNewsCommentQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadNewsCommentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadNewsCommentQuery({
 *   variables: {
 *      topicId: // value for 'topicId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useReadNewsCommentQuery(baseOptions: Apollo.QueryHookOptions<ReadNewsCommentQuery, ReadNewsCommentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadNewsCommentQuery, ReadNewsCommentQueryVariables>(ReadNewsCommentDocument, options);
      }
export function useReadNewsCommentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadNewsCommentQuery, ReadNewsCommentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadNewsCommentQuery, ReadNewsCommentQueryVariables>(ReadNewsCommentDocument, options);
        }
export type ReadNewsCommentQueryHookResult = ReturnType<typeof useReadNewsCommentQuery>;
export type ReadNewsCommentLazyQueryHookResult = ReturnType<typeof useReadNewsCommentLazyQuery>;
export type ReadNewsCommentQueryResult = Apollo.QueryResult<ReadNewsCommentQuery, ReadNewsCommentQueryVariables>;
export const ReadNextLabelDocument = gql`
    query readNextLabel($type: String!) {
  readNextLabel(type: $type)
}
    `;

/**
 * __useReadNextLabelQuery__
 *
 * To run a query within a React component, call `useReadNextLabelQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadNextLabelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadNextLabelQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useReadNextLabelQuery(baseOptions: Apollo.QueryHookOptions<ReadNextLabelQuery, ReadNextLabelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadNextLabelQuery, ReadNextLabelQueryVariables>(ReadNextLabelDocument, options);
      }
export function useReadNextLabelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadNextLabelQuery, ReadNextLabelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadNextLabelQuery, ReadNextLabelQueryVariables>(ReadNextLabelDocument, options);
        }
export type ReadNextLabelQueryHookResult = ReturnType<typeof useReadNextLabelQuery>;
export type ReadNextLabelLazyQueryHookResult = ReturnType<typeof useReadNextLabelLazyQuery>;
export type ReadNextLabelQueryResult = Apollo.QueryResult<ReadNextLabelQuery, ReadNextLabelQueryVariables>;
export const ReadOpsClientSettingsDocument = gql`
    query readOpsClientSettings {
  readOpsClientSettings {
    debtorLabel
    clientStartDate
    bccReceiver
  }
}
    `;

/**
 * __useReadOpsClientSettingsQuery__
 *
 * To run a query within a React component, call `useReadOpsClientSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadOpsClientSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadOpsClientSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useReadOpsClientSettingsQuery(baseOptions?: Apollo.QueryHookOptions<ReadOpsClientSettingsQuery, ReadOpsClientSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadOpsClientSettingsQuery, ReadOpsClientSettingsQueryVariables>(ReadOpsClientSettingsDocument, options);
      }
export function useReadOpsClientSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadOpsClientSettingsQuery, ReadOpsClientSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadOpsClientSettingsQuery, ReadOpsClientSettingsQueryVariables>(ReadOpsClientSettingsDocument, options);
        }
export type ReadOpsClientSettingsQueryHookResult = ReturnType<typeof useReadOpsClientSettingsQuery>;
export type ReadOpsClientSettingsLazyQueryHookResult = ReturnType<typeof useReadOpsClientSettingsLazyQuery>;
export type ReadOpsClientSettingsQueryResult = Apollo.QueryResult<ReadOpsClientSettingsQuery, ReadOpsClientSettingsQueryVariables>;
export const PlantDetailDocument = gql`
    query plantDetail($plantId: ID!) {
  readPlant(id: $plantId) {
    id
    projectId
    name
    mainAddress {
      id
      streetName
      streetNumber
      zipCode
      city
      country
    }
    addresses {
      id
      streetName
      streetNumber
      zipCode
      city
      country
    }
    kpis {
      units
      unitsMembers
    }
    tenantContracts {
      id
      label
      startDate
      endDate
      meterNumber
      name
      customerLabel
      isOperator
      meterType
      electricityGenerationOrGasType
      contractStatus
    }
    buildingContracts {
      id
      label
      startDate
      endDate
      meterNumber
      name
      customerLabel
      isOperator
      meterType
      electricityGenerationOrGasType
      contractStatus
    }
    reports {
      id
      type
      name
      url
      createdAt
    }
    project {
      id
      name
    }
    measurementConcept
    meteringPointOperator {
      name
      code
    }
    balancingAreaAccount {
      name
      eic
    }
    balancingAreaGroupAccount {
      name
      eic
    }
    supplierAccount {
      name
      code
    }
    transmissionSystemOperator {
      name
      eic
    }
    meteringPointOperatorId
    balancingAreaAccountId
    supplierAccountId
    transmissionSystemOperatorId
    isInSetup
  }
}
    `;

/**
 * __usePlantDetailQuery__
 *
 * To run a query within a React component, call `usePlantDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlantDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlantDetailQuery({
 *   variables: {
 *      plantId: // value for 'plantId'
 *   },
 * });
 */
export function usePlantDetailQuery(baseOptions: Apollo.QueryHookOptions<PlantDetailQuery, PlantDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlantDetailQuery, PlantDetailQueryVariables>(PlantDetailDocument, options);
      }
export function usePlantDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlantDetailQuery, PlantDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlantDetailQuery, PlantDetailQueryVariables>(PlantDetailDocument, options);
        }
export type PlantDetailQueryHookResult = ReturnType<typeof usePlantDetailQuery>;
export type PlantDetailLazyQueryHookResult = ReturnType<typeof usePlantDetailLazyQuery>;
export type PlantDetailQueryResult = Apollo.QueryResult<PlantDetailQuery, PlantDetailQueryVariables>;
export const PlantDetailExtendedDocument = gql`
    query plantDetailExtended($plantId: ID!) {
  readPlant(id: $plantId) {
    id
    projectId
    name
    mainAddress {
      id
      streetName
      streetNumber
      zipCode
      city
      country
    }
    addresses {
      id
      streetName
      streetNumber
      zipCode
      city
      country
    }
    kpis {
      units
      unitsMembers
    }
    tenantContracts {
      id
      label
      startDate
      endDate
      meterNumber
      name
      customerLabel
      isOperator
      meterType
      electricityGenerationOrGasType
      contractStatus
    }
    buildingContracts {
      id
      label
      startDate
      endDate
      meterNumber
      name
      customerLabel
      isOperator
      meterType
      electricityGenerationOrGasType
      contractStatus
      contractType
    }
    measurementConceptContractsInSetup {
      id
      meterNumber
      meterType
      msoType
      isOperator
    }
    reports {
      id
      plantId
      type
      name
      url
      createdAt
    }
    project {
      id
      name
    }
    measurementConcept
    meteringPointOperator {
      name
      code
    }
    balancingAreaAccount {
      name
      eic
    }
    balancingAreaGroupAccount {
      name
      eic
    }
    supplierAccount {
      name
      code
    }
    transmissionSystemOperator {
      name
      eic
    }
    meteringPointOperatorId
    balancingAreaAccountId
    supplierAccountId
    transmissionSystemOperatorId
    isInSetup
  }
}
    `;

/**
 * __usePlantDetailExtendedQuery__
 *
 * To run a query within a React component, call `usePlantDetailExtendedQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlantDetailExtendedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlantDetailExtendedQuery({
 *   variables: {
 *      plantId: // value for 'plantId'
 *   },
 * });
 */
export function usePlantDetailExtendedQuery(baseOptions: Apollo.QueryHookOptions<PlantDetailExtendedQuery, PlantDetailExtendedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlantDetailExtendedQuery, PlantDetailExtendedQueryVariables>(PlantDetailExtendedDocument, options);
      }
export function usePlantDetailExtendedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlantDetailExtendedQuery, PlantDetailExtendedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlantDetailExtendedQuery, PlantDetailExtendedQueryVariables>(PlantDetailExtendedDocument, options);
        }
export type PlantDetailExtendedQueryHookResult = ReturnType<typeof usePlantDetailExtendedQuery>;
export type PlantDetailExtendedLazyQueryHookResult = ReturnType<typeof usePlantDetailExtendedLazyQuery>;
export type PlantDetailExtendedQueryResult = Apollo.QueryResult<PlantDetailExtendedQuery, PlantDetailExtendedQueryVariables>;
export const ReadPlantMetersDocument = gql`
    query readPlantMeters($plantId: ID!) {
  readPlantMeters(id: $plantId) {
    matchedTenantMeters {
      ...contractMeterExcerpt
    }
    unmatchedMeters {
      ...contractMeterExcerpt
    }
    matchedBuildingMeters {
      ...contractMeterExcerpt
    }
  }
}
    ${ContractMeterExcerptFragmentDoc}`;

/**
 * __useReadPlantMetersQuery__
 *
 * To run a query within a React component, call `useReadPlantMetersQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadPlantMetersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadPlantMetersQuery({
 *   variables: {
 *      plantId: // value for 'plantId'
 *   },
 * });
 */
export function useReadPlantMetersQuery(baseOptions: Apollo.QueryHookOptions<ReadPlantMetersQuery, ReadPlantMetersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadPlantMetersQuery, ReadPlantMetersQueryVariables>(ReadPlantMetersDocument, options);
      }
export function useReadPlantMetersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadPlantMetersQuery, ReadPlantMetersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadPlantMetersQuery, ReadPlantMetersQueryVariables>(ReadPlantMetersDocument, options);
        }
export type ReadPlantMetersQueryHookResult = ReturnType<typeof useReadPlantMetersQuery>;
export type ReadPlantMetersLazyQueryHookResult = ReturnType<typeof useReadPlantMetersLazyQuery>;
export type ReadPlantMetersQueryResult = Apollo.QueryResult<ReadPlantMetersQuery, ReadPlantMetersQueryVariables>;
export const ReadPlantReportsDocument = gql`
    query readPlantReports($plantId: String) {
  readPlantReports(plantId: $plantId) {
    id
    type
    name
    url
    createdAt
    entityId
  }
}
    `;

/**
 * __useReadPlantReportsQuery__
 *
 * To run a query within a React component, call `useReadPlantReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadPlantReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadPlantReportsQuery({
 *   variables: {
 *      plantId: // value for 'plantId'
 *   },
 * });
 */
export function useReadPlantReportsQuery(baseOptions?: Apollo.QueryHookOptions<ReadPlantReportsQuery, ReadPlantReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadPlantReportsQuery, ReadPlantReportsQueryVariables>(ReadPlantReportsDocument, options);
      }
export function useReadPlantReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadPlantReportsQuery, ReadPlantReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadPlantReportsQuery, ReadPlantReportsQueryVariables>(ReadPlantReportsDocument, options);
        }
export type ReadPlantReportsQueryHookResult = ReturnType<typeof useReadPlantReportsQuery>;
export type ReadPlantReportsLazyQueryHookResult = ReturnType<typeof useReadPlantReportsLazyQuery>;
export type ReadPlantReportsQueryResult = Apollo.QueryResult<ReadPlantReportsQuery, ReadPlantReportsQueryVariables>;
export const PlantSimpleDocument = gql`
    query plantSimple($plantId: ID!) {
  readPlant(id: $plantId) {
    id
    name
  }
}
    `;

/**
 * __usePlantSimpleQuery__
 *
 * To run a query within a React component, call `usePlantSimpleQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlantSimpleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlantSimpleQuery({
 *   variables: {
 *      plantId: // value for 'plantId'
 *   },
 * });
 */
export function usePlantSimpleQuery(baseOptions: Apollo.QueryHookOptions<PlantSimpleQuery, PlantSimpleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlantSimpleQuery, PlantSimpleQueryVariables>(PlantSimpleDocument, options);
      }
export function usePlantSimpleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlantSimpleQuery, PlantSimpleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlantSimpleQuery, PlantSimpleQueryVariables>(PlantSimpleDocument, options);
        }
export type PlantSimpleQueryHookResult = ReturnType<typeof usePlantSimpleQuery>;
export type PlantSimpleLazyQueryHookResult = ReturnType<typeof usePlantSimpleLazyQuery>;
export type PlantSimpleQueryResult = Apollo.QueryResult<PlantSimpleQuery, PlantSimpleQueryVariables>;
export const ReadPlantsAutarkiesDocument = gql`
    query readPlantsAutarkies($ids: [ID!]) {
  readPlantsAutarkies(ids: $ids) {
    id
    projectId
    name
    autarkies {
      startDate
      endDate
      calculatedValue
      userSuppliedValue
    }
  }
}
    `;

/**
 * __useReadPlantsAutarkiesQuery__
 *
 * To run a query within a React component, call `useReadPlantsAutarkiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadPlantsAutarkiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadPlantsAutarkiesQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useReadPlantsAutarkiesQuery(baseOptions?: Apollo.QueryHookOptions<ReadPlantsAutarkiesQuery, ReadPlantsAutarkiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadPlantsAutarkiesQuery, ReadPlantsAutarkiesQueryVariables>(ReadPlantsAutarkiesDocument, options);
      }
export function useReadPlantsAutarkiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadPlantsAutarkiesQuery, ReadPlantsAutarkiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadPlantsAutarkiesQuery, ReadPlantsAutarkiesQueryVariables>(ReadPlantsAutarkiesDocument, options);
        }
export type ReadPlantsAutarkiesQueryHookResult = ReturnType<typeof useReadPlantsAutarkiesQuery>;
export type ReadPlantsAutarkiesLazyQueryHookResult = ReturnType<typeof useReadPlantsAutarkiesLazyQuery>;
export type ReadPlantsAutarkiesQueryResult = Apollo.QueryResult<ReadPlantsAutarkiesQuery, ReadPlantsAutarkiesQueryVariables>;
export const ReadPlantsKpiDocument = gql`
    query readPlantsKPI {
  readPlants {
    kpis {
      units
      unitsMembers
    }
  }
}
    `;

/**
 * __useReadPlantsKpiQuery__
 *
 * To run a query within a React component, call `useReadPlantsKpiQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadPlantsKpiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadPlantsKpiQuery({
 *   variables: {
 *   },
 * });
 */
export function useReadPlantsKpiQuery(baseOptions?: Apollo.QueryHookOptions<ReadPlantsKpiQuery, ReadPlantsKpiQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadPlantsKpiQuery, ReadPlantsKpiQueryVariables>(ReadPlantsKpiDocument, options);
      }
export function useReadPlantsKpiLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadPlantsKpiQuery, ReadPlantsKpiQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadPlantsKpiQuery, ReadPlantsKpiQueryVariables>(ReadPlantsKpiDocument, options);
        }
export type ReadPlantsKpiQueryHookResult = ReturnType<typeof useReadPlantsKpiQuery>;
export type ReadPlantsKpiLazyQueryHookResult = ReturnType<typeof useReadPlantsKpiLazyQuery>;
export type ReadPlantsKpiQueryResult = Apollo.QueryResult<ReadPlantsKpiQuery, ReadPlantsKpiQueryVariables>;
export const ReadPlantsSimpleDocument = gql`
    query readPlantsSimple {
  readPlants {
    id
    name
    projectId
    project {
      id
      name
    }
  }
}
    `;

/**
 * __useReadPlantsSimpleQuery__
 *
 * To run a query within a React component, call `useReadPlantsSimpleQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadPlantsSimpleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadPlantsSimpleQuery({
 *   variables: {
 *   },
 * });
 */
export function useReadPlantsSimpleQuery(baseOptions?: Apollo.QueryHookOptions<ReadPlantsSimpleQuery, ReadPlantsSimpleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadPlantsSimpleQuery, ReadPlantsSimpleQueryVariables>(ReadPlantsSimpleDocument, options);
      }
export function useReadPlantsSimpleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadPlantsSimpleQuery, ReadPlantsSimpleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadPlantsSimpleQuery, ReadPlantsSimpleQueryVariables>(ReadPlantsSimpleDocument, options);
        }
export type ReadPlantsSimpleQueryHookResult = ReturnType<typeof useReadPlantsSimpleQuery>;
export type ReadPlantsSimpleLazyQueryHookResult = ReturnType<typeof useReadPlantsSimpleLazyQuery>;
export type ReadPlantsSimpleQueryResult = Apollo.QueryResult<ReadPlantsSimpleQuery, ReadPlantsSimpleQueryVariables>;
export const ReadPowerSuppliersDocument = gql`
    query ReadPowerSuppliers($type: String!) {
  readPowerSuppliers(type: $type) {
    id
    BdewCode
    BdewCodeType
    BdewCodeFunction
    CompanyUID
    CompanyName
    PostCode
    City
    Street
    Country
    CodeContact
    CodeContactPhone
    CodeContactEmail
  }
}
    `;

/**
 * __useReadPowerSuppliersQuery__
 *
 * To run a query within a React component, call `useReadPowerSuppliersQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadPowerSuppliersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadPowerSuppliersQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useReadPowerSuppliersQuery(baseOptions: Apollo.QueryHookOptions<ReadPowerSuppliersQuery, ReadPowerSuppliersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadPowerSuppliersQuery, ReadPowerSuppliersQueryVariables>(ReadPowerSuppliersDocument, options);
      }
export function useReadPowerSuppliersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadPowerSuppliersQuery, ReadPowerSuppliersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadPowerSuppliersQuery, ReadPowerSuppliersQueryVariables>(ReadPowerSuppliersDocument, options);
        }
export type ReadPowerSuppliersQueryHookResult = ReturnType<typeof useReadPowerSuppliersQuery>;
export type ReadPowerSuppliersLazyQueryHookResult = ReturnType<typeof useReadPowerSuppliersLazyQuery>;
export type ReadPowerSuppliersQueryResult = Apollo.QueryResult<ReadPowerSuppliersQuery, ReadPowerSuppliersQueryVariables>;
export const ReadProjectDocument = gql`
    query readProject($projectId: ID!) {
  readProject(id: $projectId) {
    id
    name
    plants {
      id
      name
      mainAddress {
        ...fullAddress
      }
      addresses {
        ...fullAddress
      }
      kpis {
        units
        unitsMembers
      }
      projectId
    }
  }
}
    ${FullAddressFragmentDoc}`;

/**
 * __useReadProjectQuery__
 *
 * To run a query within a React component, call `useReadProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadProjectQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useReadProjectQuery(baseOptions: Apollo.QueryHookOptions<ReadProjectQuery, ReadProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadProjectQuery, ReadProjectQueryVariables>(ReadProjectDocument, options);
      }
export function useReadProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadProjectQuery, ReadProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadProjectQuery, ReadProjectQueryVariables>(ReadProjectDocument, options);
        }
export type ReadProjectQueryHookResult = ReturnType<typeof useReadProjectQuery>;
export type ReadProjectLazyQueryHookResult = ReturnType<typeof useReadProjectLazyQuery>;
export type ReadProjectQueryResult = Apollo.QueryResult<ReadProjectQuery, ReadProjectQueryVariables>;
export const ReadProjectsQueryDocument = gql`
    query readProjectsQuery {
  readProjects {
    id
    name
    plants {
      id
      name
      mainAddress {
        ...fullAddress
      }
      addresses {
        ...fullAddress
      }
      kpis {
        units
        unitsMembers
      }
      projectId
    }
  }
}
    ${FullAddressFragmentDoc}`;

/**
 * __useReadProjectsQueryQuery__
 *
 * To run a query within a React component, call `useReadProjectsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadProjectsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadProjectsQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useReadProjectsQueryQuery(baseOptions?: Apollo.QueryHookOptions<ReadProjectsQueryQuery, ReadProjectsQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadProjectsQueryQuery, ReadProjectsQueryQueryVariables>(ReadProjectsQueryDocument, options);
      }
export function useReadProjectsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadProjectsQueryQuery, ReadProjectsQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadProjectsQueryQuery, ReadProjectsQueryQueryVariables>(ReadProjectsQueryDocument, options);
        }
export type ReadProjectsQueryQueryHookResult = ReturnType<typeof useReadProjectsQueryQuery>;
export type ReadProjectsQueryLazyQueryHookResult = ReturnType<typeof useReadProjectsQueryLazyQuery>;
export type ReadProjectsQueryQueryResult = Apollo.QueryResult<ReadProjectsQueryQuery, ReadProjectsQueryQueryVariables>;
export const ReadProjectsQueryAutoCompleteDocument = gql`
    query readProjectsQueryAutoComplete {
  readProjects {
    id
    name
  }
}
    `;

/**
 * __useReadProjectsQueryAutoCompleteQuery__
 *
 * To run a query within a React component, call `useReadProjectsQueryAutoCompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadProjectsQueryAutoCompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadProjectsQueryAutoCompleteQuery({
 *   variables: {
 *   },
 * });
 */
export function useReadProjectsQueryAutoCompleteQuery(baseOptions?: Apollo.QueryHookOptions<ReadProjectsQueryAutoCompleteQuery, ReadProjectsQueryAutoCompleteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadProjectsQueryAutoCompleteQuery, ReadProjectsQueryAutoCompleteQueryVariables>(ReadProjectsQueryAutoCompleteDocument, options);
      }
export function useReadProjectsQueryAutoCompleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadProjectsQueryAutoCompleteQuery, ReadProjectsQueryAutoCompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadProjectsQueryAutoCompleteQuery, ReadProjectsQueryAutoCompleteQueryVariables>(ReadProjectsQueryAutoCompleteDocument, options);
        }
export type ReadProjectsQueryAutoCompleteQueryHookResult = ReturnType<typeof useReadProjectsQueryAutoCompleteQuery>;
export type ReadProjectsQueryAutoCompleteLazyQueryHookResult = ReturnType<typeof useReadProjectsQueryAutoCompleteLazyQuery>;
export type ReadProjectsQueryAutoCompleteQueryResult = Apollo.QueryResult<ReadProjectsQueryAutoCompleteQuery, ReadProjectsQueryAutoCompleteQueryVariables>;
export const ReadReportsDocument = gql`
    query readReports($types: [String!], $plantId: String) {
  readReports(types: $types, plantId: $plantId) {
    id
    type
    name
    url
    createdAt
    entityId
  }
}
    `;

/**
 * __useReadReportsQuery__
 *
 * To run a query within a React component, call `useReadReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadReportsQuery({
 *   variables: {
 *      types: // value for 'types'
 *      plantId: // value for 'plantId'
 *   },
 * });
 */
export function useReadReportsQuery(baseOptions?: Apollo.QueryHookOptions<ReadReportsQuery, ReadReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadReportsQuery, ReadReportsQueryVariables>(ReadReportsDocument, options);
      }
export function useReadReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadReportsQuery, ReadReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadReportsQuery, ReadReportsQueryVariables>(ReadReportsDocument, options);
        }
export type ReadReportsQueryHookResult = ReturnType<typeof useReadReportsQuery>;
export type ReadReportsLazyQueryHookResult = ReturnType<typeof useReadReportsLazyQuery>;
export type ReadReportsQueryResult = Apollo.QueryResult<ReadReportsQuery, ReadReportsQueryVariables>;
export const ReadSuppliersDocument = gql`
    query readSuppliers {
  readSuppliers {
    id
    name
    eic
    code
  }
}
    `;

/**
 * __useReadSuppliersQuery__
 *
 * To run a query within a React component, call `useReadSuppliersQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadSuppliersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadSuppliersQuery({
 *   variables: {
 *   },
 * });
 */
export function useReadSuppliersQuery(baseOptions?: Apollo.QueryHookOptions<ReadSuppliersQuery, ReadSuppliersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadSuppliersQuery, ReadSuppliersQueryVariables>(ReadSuppliersDocument, options);
      }
export function useReadSuppliersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadSuppliersQuery, ReadSuppliersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadSuppliersQuery, ReadSuppliersQueryVariables>(ReadSuppliersDocument, options);
        }
export type ReadSuppliersQueryHookResult = ReturnType<typeof useReadSuppliersQuery>;
export type ReadSuppliersLazyQueryHookResult = ReturnType<typeof useReadSuppliersLazyQuery>;
export type ReadSuppliersQueryResult = Apollo.QueryResult<ReadSuppliersQuery, ReadSuppliersQueryVariables>;
export const ReadTariffDocument = gql`
    query readTariff($tariffId: [ID!]!) {
  readTariff(id: $tariffId) {
    nameInternal
    nameExternal
    powerTax
    tax
    priceGuaranteeReference
    priceGuarantee
    minimumDuration
    minimumDurationExtension
    noticePeriod {
      to
      period
      count
    }
    noticePeriodExtension {
      to
      period
      count
    }
    validityEndDate
    validityStartDate
    kind
    priceSheets {
      id
      name
      startDate
      basicPrice
      energyPrice
      energyPriceLocal
      energyPriceResidual
      ignorePriceGuarantee
    }
  }
}
    `;

/**
 * __useReadTariffQuery__
 *
 * To run a query within a React component, call `useReadTariffQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadTariffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadTariffQuery({
 *   variables: {
 *      tariffId: // value for 'tariffId'
 *   },
 * });
 */
export function useReadTariffQuery(baseOptions: Apollo.QueryHookOptions<ReadTariffQuery, ReadTariffQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadTariffQuery, ReadTariffQueryVariables>(ReadTariffDocument, options);
      }
export function useReadTariffLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadTariffQuery, ReadTariffQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadTariffQuery, ReadTariffQueryVariables>(ReadTariffDocument, options);
        }
export type ReadTariffQueryHookResult = ReturnType<typeof useReadTariffQuery>;
export type ReadTariffLazyQueryHookResult = ReturnType<typeof useReadTariffLazyQuery>;
export type ReadTariffQueryResult = Apollo.QueryResult<ReadTariffQuery, ReadTariffQueryVariables>;
export const ReadTariffsDocument = gql`
    query readTariffs {
  readTariffs {
    id
    nameInternal
    nameExternal
    powerTax
    tax
    priceGuarantee
    minimumDuration
    minimumDurationExtension
    noticePeriod {
      to
      period
      count
    }
    noticePeriodExtension {
      to
      period
      count
    }
    priceGuaranteeReference
    validityEndDate
    validityStartDate
    kind
    priceSheets {
      id
      name
      startDate
      basicPrice
      energyPrice
      energyPriceLocal
      energyPriceResidual
      ignorePriceGuarantee
      createdAt
    }
  }
}
    `;

/**
 * __useReadTariffsQuery__
 *
 * To run a query within a React component, call `useReadTariffsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadTariffsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadTariffsQuery({
 *   variables: {
 *   },
 * });
 */
export function useReadTariffsQuery(baseOptions?: Apollo.QueryHookOptions<ReadTariffsQuery, ReadTariffsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadTariffsQuery, ReadTariffsQueryVariables>(ReadTariffsDocument, options);
      }
export function useReadTariffsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadTariffsQuery, ReadTariffsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadTariffsQuery, ReadTariffsQueryVariables>(ReadTariffsDocument, options);
        }
export type ReadTariffsQueryHookResult = ReturnType<typeof useReadTariffsQuery>;
export type ReadTariffsLazyQueryHookResult = ReturnType<typeof useReadTariffsLazyQuery>;
export type ReadTariffsQueryResult = Apollo.QueryResult<ReadTariffsQuery, ReadTariffsQueryVariables>;
export const ReadTariffsSimpleDocument = gql`
    query readTariffsSimple {
  readTariffs {
    id
    nameInternal
    nameExternal
    kind
    validityStartDate
    validityEndDate
    priceSheets {
      name
      startDate
    }
  }
}
    `;

/**
 * __useReadTariffsSimpleQuery__
 *
 * To run a query within a React component, call `useReadTariffsSimpleQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadTariffsSimpleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadTariffsSimpleQuery({
 *   variables: {
 *   },
 * });
 */
export function useReadTariffsSimpleQuery(baseOptions?: Apollo.QueryHookOptions<ReadTariffsSimpleQuery, ReadTariffsSimpleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadTariffsSimpleQuery, ReadTariffsSimpleQueryVariables>(ReadTariffsSimpleDocument, options);
      }
export function useReadTariffsSimpleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadTariffsSimpleQuery, ReadTariffsSimpleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadTariffsSimpleQuery, ReadTariffsSimpleQueryVariables>(ReadTariffsSimpleDocument, options);
        }
export type ReadTariffsSimpleQueryHookResult = ReturnType<typeof useReadTariffsSimpleQuery>;
export type ReadTariffsSimpleLazyQueryHookResult = ReturnType<typeof useReadTariffsSimpleLazyQuery>;
export type ReadTariffsSimpleQueryResult = Apollo.QueryResult<ReadTariffsSimpleQuery, ReadTariffsSimpleQueryVariables>;
export const ReadUnboundMetersByPlantDocument = gql`
    query readUnboundMetersByPlant($plantId: ID, $boundStartAt: String, $boundEndAt: String) {
  readMeters(plantId: $plantId, boundStartAt: $boundStartAt, boundEndAt: $boundEndAt) {
    id
    meterNumber
    wasEverBound
    isBoundInInterval
    binds {
      validityStart
      validityEnd
      melo
      malo
      contractLabel
      contractEnd
      measuringPointId
      consumptionPointId
    }
  }
}
    `;

/**
 * __useReadUnboundMetersByPlantQuery__
 *
 * To run a query within a React component, call `useReadUnboundMetersByPlantQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadUnboundMetersByPlantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadUnboundMetersByPlantQuery({
 *   variables: {
 *      plantId: // value for 'plantId'
 *      boundStartAt: // value for 'boundStartAt'
 *      boundEndAt: // value for 'boundEndAt'
 *   },
 * });
 */
export function useReadUnboundMetersByPlantQuery(baseOptions?: Apollo.QueryHookOptions<ReadUnboundMetersByPlantQuery, ReadUnboundMetersByPlantQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadUnboundMetersByPlantQuery, ReadUnboundMetersByPlantQueryVariables>(ReadUnboundMetersByPlantDocument, options);
      }
export function useReadUnboundMetersByPlantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadUnboundMetersByPlantQuery, ReadUnboundMetersByPlantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadUnboundMetersByPlantQuery, ReadUnboundMetersByPlantQueryVariables>(ReadUnboundMetersByPlantDocument, options);
        }
export type ReadUnboundMetersByPlantQueryHookResult = ReturnType<typeof useReadUnboundMetersByPlantQuery>;
export type ReadUnboundMetersByPlantLazyQueryHookResult = ReturnType<typeof useReadUnboundMetersByPlantLazyQuery>;
export type ReadUnboundMetersByPlantQueryResult = Apollo.QueryResult<ReadUnboundMetersByPlantQuery, ReadUnboundMetersByPlantQueryVariables>;
export const ReadWorkflowDocument = gql`
    query readWorkflow($workflowId: ID!) {
  readWorkflow(workflowId: $workflowId) {
    id
    alias
    type
    stateNames
    done
    meta
    createdAt
    doneAt
    updatedAt
    currentState {
      name
      parentName
      context
      transitions
    }
  }
}
    `;

/**
 * __useReadWorkflowQuery__
 *
 * To run a query within a React component, call `useReadWorkflowQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadWorkflowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadWorkflowQuery({
 *   variables: {
 *      workflowId: // value for 'workflowId'
 *   },
 * });
 */
export function useReadWorkflowQuery(baseOptions: Apollo.QueryHookOptions<ReadWorkflowQuery, ReadWorkflowQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadWorkflowQuery, ReadWorkflowQueryVariables>(ReadWorkflowDocument, options);
      }
export function useReadWorkflowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadWorkflowQuery, ReadWorkflowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadWorkflowQuery, ReadWorkflowQueryVariables>(ReadWorkflowDocument, options);
        }
export type ReadWorkflowQueryHookResult = ReturnType<typeof useReadWorkflowQuery>;
export type ReadWorkflowLazyQueryHookResult = ReturnType<typeof useReadWorkflowLazyQuery>;
export type ReadWorkflowQueryResult = Apollo.QueryResult<ReadWorkflowQuery, ReadWorkflowQueryVariables>;
export const ReadWorkflowsDocument = gql`
    query readWorkflows {
  readWorkflows {
    ...fullWorkflow
  }
}
    ${FullWorkflowFragmentDoc}`;

/**
 * __useReadWorkflowsQuery__
 *
 * To run a query within a React component, call `useReadWorkflowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadWorkflowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadWorkflowsQuery({
 *   variables: {
 *   },
 * });
 */
export function useReadWorkflowsQuery(baseOptions?: Apollo.QueryHookOptions<ReadWorkflowsQuery, ReadWorkflowsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadWorkflowsQuery, ReadWorkflowsQueryVariables>(ReadWorkflowsDocument, options);
      }
export function useReadWorkflowsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadWorkflowsQuery, ReadWorkflowsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadWorkflowsQuery, ReadWorkflowsQueryVariables>(ReadWorkflowsDocument, options);
        }
export type ReadWorkflowsQueryHookResult = ReturnType<typeof useReadWorkflowsQuery>;
export type ReadWorkflowsLazyQueryHookResult = ReturnType<typeof useReadWorkflowsLazyQuery>;
export type ReadWorkflowsQueryResult = Apollo.QueryResult<ReadWorkflowsQuery, ReadWorkflowsQueryVariables>;
export const ReadWorkspacesDocument = gql`
    query readWorkspaces {
  readWorkspaces {
    id
    name
  }
}
    `;

/**
 * __useReadWorkspacesQuery__
 *
 * To run a query within a React component, call `useReadWorkspacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadWorkspacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadWorkspacesQuery({
 *   variables: {
 *   },
 * });
 */
export function useReadWorkspacesQuery(baseOptions?: Apollo.QueryHookOptions<ReadWorkspacesQuery, ReadWorkspacesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadWorkspacesQuery, ReadWorkspacesQueryVariables>(ReadWorkspacesDocument, options);
      }
export function useReadWorkspacesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadWorkspacesQuery, ReadWorkspacesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadWorkspacesQuery, ReadWorkspacesQueryVariables>(ReadWorkspacesDocument, options);
        }
export type ReadWorkspacesQueryHookResult = ReturnType<typeof useReadWorkspacesQuery>;
export type ReadWorkspacesLazyQueryHookResult = ReturnType<typeof useReadWorkspacesLazyQuery>;
export type ReadWorkspacesQueryResult = Apollo.QueryResult<ReadWorkspacesQuery, ReadWorkspacesQueryVariables>;
export const RejectInvoiceDraftDocument = gql`
    mutation rejectInvoiceDraft($invoiceDraftsTuple: [InvoiceDraftTupleInput!]!, $uuid: ID!) {
  rejectInvoiceDraft(invoiceDraftsTuple: $invoiceDraftsTuple, uuid: $uuid) {
    succeeded {
      contractLabel
      invoiceDraftId
    }
    failed {
      contractLabel
      invoiceDraftId
    }
  }
}
    `;
export type RejectInvoiceDraftMutationFn = Apollo.MutationFunction<RejectInvoiceDraftMutation, RejectInvoiceDraftMutationVariables>;

/**
 * __useRejectInvoiceDraftMutation__
 *
 * To run a mutation, you first call `useRejectInvoiceDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectInvoiceDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectInvoiceDraftMutation, { data, loading, error }] = useRejectInvoiceDraftMutation({
 *   variables: {
 *      invoiceDraftsTuple: // value for 'invoiceDraftsTuple'
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useRejectInvoiceDraftMutation(baseOptions?: Apollo.MutationHookOptions<RejectInvoiceDraftMutation, RejectInvoiceDraftMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RejectInvoiceDraftMutation, RejectInvoiceDraftMutationVariables>(RejectInvoiceDraftDocument, options);
      }
export type RejectInvoiceDraftMutationHookResult = ReturnType<typeof useRejectInvoiceDraftMutation>;
export type RejectInvoiceDraftMutationResult = Apollo.MutationResult<RejectInvoiceDraftMutation>;
export type RejectInvoiceDraftMutationOptions = Apollo.BaseMutationOptions<RejectInvoiceDraftMutation, RejectInvoiceDraftMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($email: String!, $password: String!, $token: String!) {
  resetPassword(email: $email, password: $password, token: $token) {
    __typename
    ... on UnknownError {
      message
    }
    ... on NoPasswordResetSessionFound {
      message
    }
    ... on WrongPasswordResetFlow {
      message
    }
    ... on ResetPasswordSuccess {
      success
    }
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const RetryInvoiceDocumentCreationDocument = gql`
    mutation retryInvoiceDocumentCreation($invoiceDraftsTuple: [InvoiceDraftTupleInput!]!, $uuid: ID!, $accountingType: String!, $accountingName: String!, $invoiceCycle: InvoiceCycle!) {
  retryInvoiceDocumentCreation(invoiceDraftsTuple: $invoiceDraftsTuple, uuid: $uuid, accountingType: $accountingType, opcName: $accountingName, invoiceCycle: $invoiceCycle) {
    jobId
    jobName
  }
}
    `;
export type RetryInvoiceDocumentCreationMutationFn = Apollo.MutationFunction<RetryInvoiceDocumentCreationMutation, RetryInvoiceDocumentCreationMutationVariables>;

/**
 * __useRetryInvoiceDocumentCreationMutation__
 *
 * To run a mutation, you first call `useRetryInvoiceDocumentCreationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetryInvoiceDocumentCreationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retryInvoiceDocumentCreationMutation, { data, loading, error }] = useRetryInvoiceDocumentCreationMutation({
 *   variables: {
 *      invoiceDraftsTuple: // value for 'invoiceDraftsTuple'
 *      uuid: // value for 'uuid'
 *      accountingType: // value for 'accountingType'
 *      accountingName: // value for 'accountingName'
 *      invoiceCycle: // value for 'invoiceCycle'
 *   },
 * });
 */
export function useRetryInvoiceDocumentCreationMutation(baseOptions?: Apollo.MutationHookOptions<RetryInvoiceDocumentCreationMutation, RetryInvoiceDocumentCreationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RetryInvoiceDocumentCreationMutation, RetryInvoiceDocumentCreationMutationVariables>(RetryInvoiceDocumentCreationDocument, options);
      }
export type RetryInvoiceDocumentCreationMutationHookResult = ReturnType<typeof useRetryInvoiceDocumentCreationMutation>;
export type RetryInvoiceDocumentCreationMutationResult = Apollo.MutationResult<RetryInvoiceDocumentCreationMutation>;
export type RetryInvoiceDocumentCreationMutationOptions = Apollo.BaseMutationOptions<RetryInvoiceDocumentCreationMutation, RetryInvoiceDocumentCreationMutationVariables>;
export const GetMultipleScheduleRequestsResultsDocument = gql`
    query getMultipleScheduleRequestsResults($jobIds: [String!]!) {
  getMultipleScheduleRequestsResults(jobIds: $jobIds) {
    ... on MultipleScheduleRequestsSuccess {
      results {
        ...scheduleRequest
      }
    }
    ... on JobNotFound {
      message
    }
    ... on ScheduleRequestsError {
      message
    }
  }
}
    ${ScheduleRequestFragmentDoc}`;

/**
 * __useGetMultipleScheduleRequestsResultsQuery__
 *
 * To run a query within a React component, call `useGetMultipleScheduleRequestsResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMultipleScheduleRequestsResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMultipleScheduleRequestsResultsQuery({
 *   variables: {
 *      jobIds: // value for 'jobIds'
 *   },
 * });
 */
export function useGetMultipleScheduleRequestsResultsQuery(baseOptions: Apollo.QueryHookOptions<GetMultipleScheduleRequestsResultsQuery, GetMultipleScheduleRequestsResultsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMultipleScheduleRequestsResultsQuery, GetMultipleScheduleRequestsResultsQueryVariables>(GetMultipleScheduleRequestsResultsDocument, options);
      }
export function useGetMultipleScheduleRequestsResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMultipleScheduleRequestsResultsQuery, GetMultipleScheduleRequestsResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMultipleScheduleRequestsResultsQuery, GetMultipleScheduleRequestsResultsQueryVariables>(GetMultipleScheduleRequestsResultsDocument, options);
        }
export type GetMultipleScheduleRequestsResultsQueryHookResult = ReturnType<typeof useGetMultipleScheduleRequestsResultsQuery>;
export type GetMultipleScheduleRequestsResultsLazyQueryHookResult = ReturnType<typeof useGetMultipleScheduleRequestsResultsLazyQuery>;
export type GetMultipleScheduleRequestsResultsQueryResult = Apollo.QueryResult<GetMultipleScheduleRequestsResultsQuery, GetMultipleScheduleRequestsResultsQueryVariables>;
export const GetAllScheduleRequestsResultsDocument = gql`
    query getAllScheduleRequestsResults {
  getAllScheduleRequestsResults {
    ... on MultipleScheduleRequestsSuccess {
      results {
        ...scheduleRequest
      }
    }
    ... on JobNotFound {
      message
    }
    ... on ScheduleRequestsError {
      message
    }
  }
}
    ${ScheduleRequestFragmentDoc}`;

/**
 * __useGetAllScheduleRequestsResultsQuery__
 *
 * To run a query within a React component, call `useGetAllScheduleRequestsResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllScheduleRequestsResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllScheduleRequestsResultsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllScheduleRequestsResultsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllScheduleRequestsResultsQuery, GetAllScheduleRequestsResultsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllScheduleRequestsResultsQuery, GetAllScheduleRequestsResultsQueryVariables>(GetAllScheduleRequestsResultsDocument, options);
      }
export function useGetAllScheduleRequestsResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllScheduleRequestsResultsQuery, GetAllScheduleRequestsResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllScheduleRequestsResultsQuery, GetAllScheduleRequestsResultsQueryVariables>(GetAllScheduleRequestsResultsDocument, options);
        }
export type GetAllScheduleRequestsResultsQueryHookResult = ReturnType<typeof useGetAllScheduleRequestsResultsQuery>;
export type GetAllScheduleRequestsResultsLazyQueryHookResult = ReturnType<typeof useGetAllScheduleRequestsResultsLazyQuery>;
export type GetAllScheduleRequestsResultsQueryResult = Apollo.QueryResult<GetAllScheduleRequestsResultsQuery, GetAllScheduleRequestsResultsQueryVariables>;
export const GetScheduleRequestsWorkflowDocument = gql`
    query getScheduleRequestsWorkflow($workflowId: String!) {
  getScheduleRequestsWorkflow(workflowId: $workflowId) {
    ... on ScheduleRequestSuccess {
      result {
        ...scheduleRequest
      }
    }
    ... on JobNotFound {
      message
    }
    ... on ScheduleRequestsError {
      message
    }
  }
}
    ${ScheduleRequestFragmentDoc}`;

/**
 * __useGetScheduleRequestsWorkflowQuery__
 *
 * To run a query within a React component, call `useGetScheduleRequestsWorkflowQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScheduleRequestsWorkflowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScheduleRequestsWorkflowQuery({
 *   variables: {
 *      workflowId: // value for 'workflowId'
 *   },
 * });
 */
export function useGetScheduleRequestsWorkflowQuery(baseOptions: Apollo.QueryHookOptions<GetScheduleRequestsWorkflowQuery, GetScheduleRequestsWorkflowQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetScheduleRequestsWorkflowQuery, GetScheduleRequestsWorkflowQueryVariables>(GetScheduleRequestsWorkflowDocument, options);
      }
export function useGetScheduleRequestsWorkflowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetScheduleRequestsWorkflowQuery, GetScheduleRequestsWorkflowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetScheduleRequestsWorkflowQuery, GetScheduleRequestsWorkflowQueryVariables>(GetScheduleRequestsWorkflowDocument, options);
        }
export type GetScheduleRequestsWorkflowQueryHookResult = ReturnType<typeof useGetScheduleRequestsWorkflowQuery>;
export type GetScheduleRequestsWorkflowLazyQueryHookResult = ReturnType<typeof useGetScheduleRequestsWorkflowLazyQuery>;
export type GetScheduleRequestsWorkflowQueryResult = Apollo.QueryResult<GetScheduleRequestsWorkflowQuery, GetScheduleRequestsWorkflowQueryVariables>;
export const SearchDocument = gql`
    query search($needle: String!) {
  search(needle: $needle) {
    source
    designation
    identifiers {
      sourceId
      projectId
      plantId
      contractId
    }
    object
  }
}
    `;

/**
 * __useSearchQuery__
 *
 * To run a query within a React component, call `useSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchQuery({
 *   variables: {
 *      needle: // value for 'needle'
 *   },
 * });
 */
export function useSearchQuery(baseOptions: Apollo.QueryHookOptions<SearchQuery, SearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
      }
export function useSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchQuery, SearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
        }
export type SearchQueryHookResult = ReturnType<typeof useSearchQuery>;
export type SearchLazyQueryHookResult = ReturnType<typeof useSearchLazyQuery>;
export type SearchQueryResult = Apollo.QueryResult<SearchQuery, SearchQueryVariables>;
export const SendPasswordResetEmailDocument = gql`
    mutation sendPasswordResetEmail($email: String!, $resetPasswordCallbackUrl: String!) {
  sendPasswordResetEmail(email: $email, resetPasswordCallbackUrl: $resetPasswordCallbackUrl) {
    __typename
    ... on UnknownError {
      message
    }
    ... on WrongPasswordResetFlow {
      message
    }
    ... on SendPasswordResetEmailSuccess {
      email
    }
  }
}
    `;
export type SendPasswordResetEmailMutationFn = Apollo.MutationFunction<SendPasswordResetEmailMutation, SendPasswordResetEmailMutationVariables>;

/**
 * __useSendPasswordResetEmailMutation__
 *
 * To run a mutation, you first call `useSendPasswordResetEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPasswordResetEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPasswordResetEmailMutation, { data, loading, error }] = useSendPasswordResetEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *      resetPasswordCallbackUrl: // value for 'resetPasswordCallbackUrl'
 *   },
 * });
 */
export function useSendPasswordResetEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendPasswordResetEmailMutation, SendPasswordResetEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendPasswordResetEmailMutation, SendPasswordResetEmailMutationVariables>(SendPasswordResetEmailDocument, options);
      }
export type SendPasswordResetEmailMutationHookResult = ReturnType<typeof useSendPasswordResetEmailMutation>;
export type SendPasswordResetEmailMutationResult = Apollo.MutationResult<SendPasswordResetEmailMutation>;
export type SendPasswordResetEmailMutationOptions = Apollo.BaseMutationOptions<SendPasswordResetEmailMutation, SendPasswordResetEmailMutationVariables>;
export const SendTestMailDocument = gql`
    mutation sendTestMail {
  sendTestMail {
    __typename
    ... on UnknownError {
      message
    }
  }
}
    `;
export type SendTestMailMutationFn = Apollo.MutationFunction<SendTestMailMutation, SendTestMailMutationVariables>;

/**
 * __useSendTestMailMutation__
 *
 * To run a mutation, you first call `useSendTestMailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendTestMailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendTestMailMutation, { data, loading, error }] = useSendTestMailMutation({
 *   variables: {
 *   },
 * });
 */
export function useSendTestMailMutation(baseOptions?: Apollo.MutationHookOptions<SendTestMailMutation, SendTestMailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendTestMailMutation, SendTestMailMutationVariables>(SendTestMailDocument, options);
      }
export type SendTestMailMutationHookResult = ReturnType<typeof useSendTestMailMutation>;
export type SendTestMailMutationResult = Apollo.MutationResult<SendTestMailMutation>;
export type SendTestMailMutationOptions = Apollo.BaseMutationOptions<SendTestMailMutation, SendTestMailMutationVariables>;
export const SetDownpaymentStartDocument = gql`
    mutation setDownpaymentStart($workflowId: ID!, $payload: SetDownpaymentStartPayload!, $transitionName: String!) {
  setDownpaymentStart(workflowId: $workflowId, payload: $payload, transitionName: $transitionName) {
    id
    alias
    type
    stateNames
    done
    meta
    createdAt
    doneAt
    updatedAt
    currentState {
      name
      parentName
      context
      transitions
    }
  }
}
    `;
export type SetDownpaymentStartMutationFn = Apollo.MutationFunction<SetDownpaymentStartMutation, SetDownpaymentStartMutationVariables>;

/**
 * __useSetDownpaymentStartMutation__
 *
 * To run a mutation, you first call `useSetDownpaymentStartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDownpaymentStartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDownpaymentStartMutation, { data, loading, error }] = useSetDownpaymentStartMutation({
 *   variables: {
 *      workflowId: // value for 'workflowId'
 *      payload: // value for 'payload'
 *      transitionName: // value for 'transitionName'
 *   },
 * });
 */
export function useSetDownpaymentStartMutation(baseOptions?: Apollo.MutationHookOptions<SetDownpaymentStartMutation, SetDownpaymentStartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetDownpaymentStartMutation, SetDownpaymentStartMutationVariables>(SetDownpaymentStartDocument, options);
      }
export type SetDownpaymentStartMutationHookResult = ReturnType<typeof useSetDownpaymentStartMutation>;
export type SetDownpaymentStartMutationResult = Apollo.MutationResult<SetDownpaymentStartMutation>;
export type SetDownpaymentStartMutationOptions = Apollo.BaseMutationOptions<SetDownpaymentStartMutation, SetDownpaymentStartMutationVariables>;
export const SetPlantAutarkyDocument = gql`
    mutation setPlantAutarky($workflowId: ID!, $payload: SetPlantAutarkyPayload!, $transitionName: String!) {
  setPlantAutarky(workflowId: $workflowId, payload: $payload, transitionName: $transitionName) {
    id
    alias
    type
    stateNames
    done
    meta
    createdAt
    doneAt
    updatedAt
    currentState {
      name
      parentName
      context
      transitions
    }
  }
}
    `;
export type SetPlantAutarkyMutationFn = Apollo.MutationFunction<SetPlantAutarkyMutation, SetPlantAutarkyMutationVariables>;

/**
 * __useSetPlantAutarkyMutation__
 *
 * To run a mutation, you first call `useSetPlantAutarkyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPlantAutarkyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPlantAutarkyMutation, { data, loading, error }] = useSetPlantAutarkyMutation({
 *   variables: {
 *      workflowId: // value for 'workflowId'
 *      payload: // value for 'payload'
 *      transitionName: // value for 'transitionName'
 *   },
 * });
 */
export function useSetPlantAutarkyMutation(baseOptions?: Apollo.MutationHookOptions<SetPlantAutarkyMutation, SetPlantAutarkyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetPlantAutarkyMutation, SetPlantAutarkyMutationVariables>(SetPlantAutarkyDocument, options);
      }
export type SetPlantAutarkyMutationHookResult = ReturnType<typeof useSetPlantAutarkyMutation>;
export type SetPlantAutarkyMutationResult = Apollo.MutationResult<SetPlantAutarkyMutation>;
export type SetPlantAutarkyMutationOptions = Apollo.BaseMutationOptions<SetPlantAutarkyMutation, SetPlantAutarkyMutationVariables>;
export const SetCancellationSupplierDocument = gql`
    mutation SetCancellationSupplier($supplier: NetworkOperatorInput!, $letterPayload: CancellationLetterPayloadInput!, $workflowId: String!) {
  setCancellationSupplier(supplier: $supplier, letterPayload: $letterPayload, workflowId: $workflowId) {
    done
    type
    contractId
  }
}
    `;
export type SetCancellationSupplierMutationFn = Apollo.MutationFunction<SetCancellationSupplierMutation, SetCancellationSupplierMutationVariables>;

/**
 * __useSetCancellationSupplierMutation__
 *
 * To run a mutation, you first call `useSetCancellationSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCancellationSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCancellationSupplierMutation, { data, loading, error }] = useSetCancellationSupplierMutation({
 *   variables: {
 *      supplier: // value for 'supplier'
 *      letterPayload: // value for 'letterPayload'
 *      workflowId: // value for 'workflowId'
 *   },
 * });
 */
export function useSetCancellationSupplierMutation(baseOptions?: Apollo.MutationHookOptions<SetCancellationSupplierMutation, SetCancellationSupplierMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetCancellationSupplierMutation, SetCancellationSupplierMutationVariables>(SetCancellationSupplierDocument, options);
      }
export type SetCancellationSupplierMutationHookResult = ReturnType<typeof useSetCancellationSupplierMutation>;
export type SetCancellationSupplierMutationResult = Apollo.MutationResult<SetCancellationSupplierMutation>;
export type SetCancellationSupplierMutationOptions = Apollo.BaseMutationOptions<SetCancellationSupplierMutation, SetCancellationSupplierMutationVariables>;
export const SetContractSupplierChangeOperatorDocument = gql`
    mutation SetContractSupplierChangeOperator($meteringPointOperator: SupplierNetworkOperatorInput!, $gridOperator: SupplierNetworkOperatorInput!, $workflowId: String!, $contractId: String!, $malo: String!) {
  setContractSupplierChangeOperator(meteringPointOperator: $meteringPointOperator, gridOperator: $gridOperator, workflowId: $workflowId, contractId: $contractId, malo: $malo) {
    done
    type
    contractId
  }
}
    `;
export type SetContractSupplierChangeOperatorMutationFn = Apollo.MutationFunction<SetContractSupplierChangeOperatorMutation, SetContractSupplierChangeOperatorMutationVariables>;

/**
 * __useSetContractSupplierChangeOperatorMutation__
 *
 * To run a mutation, you first call `useSetContractSupplierChangeOperatorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetContractSupplierChangeOperatorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setContractSupplierChangeOperatorMutation, { data, loading, error }] = useSetContractSupplierChangeOperatorMutation({
 *   variables: {
 *      meteringPointOperator: // value for 'meteringPointOperator'
 *      gridOperator: // value for 'gridOperator'
 *      workflowId: // value for 'workflowId'
 *      contractId: // value for 'contractId'
 *      malo: // value for 'malo'
 *   },
 * });
 */
export function useSetContractSupplierChangeOperatorMutation(baseOptions?: Apollo.MutationHookOptions<SetContractSupplierChangeOperatorMutation, SetContractSupplierChangeOperatorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetContractSupplierChangeOperatorMutation, SetContractSupplierChangeOperatorMutationVariables>(SetContractSupplierChangeOperatorDocument, options);
      }
export type SetContractSupplierChangeOperatorMutationHookResult = ReturnType<typeof useSetContractSupplierChangeOperatorMutation>;
export type SetContractSupplierChangeOperatorMutationResult = Apollo.MutationResult<SetContractSupplierChangeOperatorMutation>;
export type SetContractSupplierChangeOperatorMutationOptions = Apollo.BaseMutationOptions<SetContractSupplierChangeOperatorMutation, SetContractSupplierChangeOperatorMutationVariables>;
export const StartSupplierChangeTimerDocument = gql`
    mutation StartSupplierChangeTimer($workflowId: String!) {
  startSupplierChangeTimer(workflowId: $workflowId) {
    done
    type
    contractId
    startedAt
  }
}
    `;
export type StartSupplierChangeTimerMutationFn = Apollo.MutationFunction<StartSupplierChangeTimerMutation, StartSupplierChangeTimerMutationVariables>;

/**
 * __useStartSupplierChangeTimerMutation__
 *
 * To run a mutation, you first call `useStartSupplierChangeTimerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartSupplierChangeTimerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startSupplierChangeTimerMutation, { data, loading, error }] = useStartSupplierChangeTimerMutation({
 *   variables: {
 *      workflowId: // value for 'workflowId'
 *   },
 * });
 */
export function useStartSupplierChangeTimerMutation(baseOptions?: Apollo.MutationHookOptions<StartSupplierChangeTimerMutation, StartSupplierChangeTimerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartSupplierChangeTimerMutation, StartSupplierChangeTimerMutationVariables>(StartSupplierChangeTimerDocument, options);
      }
export type StartSupplierChangeTimerMutationHookResult = ReturnType<typeof useStartSupplierChangeTimerMutation>;
export type StartSupplierChangeTimerMutationResult = Apollo.MutationResult<StartSupplierChangeTimerMutation>;
export type StartSupplierChangeTimerMutationOptions = Apollo.BaseMutationOptions<StartSupplierChangeTimerMutation, StartSupplierChangeTimerMutationVariables>;
export const UpdateSupplierChangeCancellationContractDocument = gql`
    mutation UpdateSupplierChangeCancellationContract($workflowId: String!, $payload: UpdateSupplierChangeContractInput!) {
  updateSupplierChangeCancellationContract(workflowId: $workflowId, payload: $payload) {
    done
    startedAt
    contractId
    type
  }
}
    `;
export type UpdateSupplierChangeCancellationContractMutationFn = Apollo.MutationFunction<UpdateSupplierChangeCancellationContractMutation, UpdateSupplierChangeCancellationContractMutationVariables>;

/**
 * __useUpdateSupplierChangeCancellationContractMutation__
 *
 * To run a mutation, you first call `useUpdateSupplierChangeCancellationContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSupplierChangeCancellationContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSupplierChangeCancellationContractMutation, { data, loading, error }] = useUpdateSupplierChangeCancellationContractMutation({
 *   variables: {
 *      workflowId: // value for 'workflowId'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpdateSupplierChangeCancellationContractMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSupplierChangeCancellationContractMutation, UpdateSupplierChangeCancellationContractMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSupplierChangeCancellationContractMutation, UpdateSupplierChangeCancellationContractMutationVariables>(UpdateSupplierChangeCancellationContractDocument, options);
      }
export type UpdateSupplierChangeCancellationContractMutationHookResult = ReturnType<typeof useUpdateSupplierChangeCancellationContractMutation>;
export type UpdateSupplierChangeCancellationContractMutationResult = Apollo.MutationResult<UpdateSupplierChangeCancellationContractMutation>;
export type UpdateSupplierChangeCancellationContractMutationOptions = Apollo.BaseMutationOptions<UpdateSupplierChangeCancellationContractMutation, UpdateSupplierChangeCancellationContractMutationVariables>;
export const UpdateSupplierChangeThirdPartyContractDocument = gql`
    mutation UpdateSupplierChangeThirdPartyContract($workflowId: String!, $payload: UpdateSupplierChangeContractInput!) {
  updateSupplierChangeThirdPartyContract(workflowId: $workflowId, payload: $payload) {
    done
    startedAt
    contractId
    type
  }
}
    `;
export type UpdateSupplierChangeThirdPartyContractMutationFn = Apollo.MutationFunction<UpdateSupplierChangeThirdPartyContractMutation, UpdateSupplierChangeThirdPartyContractMutationVariables>;

/**
 * __useUpdateSupplierChangeThirdPartyContractMutation__
 *
 * To run a mutation, you first call `useUpdateSupplierChangeThirdPartyContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSupplierChangeThirdPartyContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSupplierChangeThirdPartyContractMutation, { data, loading, error }] = useUpdateSupplierChangeThirdPartyContractMutation({
 *   variables: {
 *      workflowId: // value for 'workflowId'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpdateSupplierChangeThirdPartyContractMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSupplierChangeThirdPartyContractMutation, UpdateSupplierChangeThirdPartyContractMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSupplierChangeThirdPartyContractMutation, UpdateSupplierChangeThirdPartyContractMutationVariables>(UpdateSupplierChangeThirdPartyContractDocument, options);
      }
export type UpdateSupplierChangeThirdPartyContractMutationHookResult = ReturnType<typeof useUpdateSupplierChangeThirdPartyContractMutation>;
export type UpdateSupplierChangeThirdPartyContractMutationResult = Apollo.MutationResult<UpdateSupplierChangeThirdPartyContractMutation>;
export type UpdateSupplierChangeThirdPartyContractMutationOptions = Apollo.BaseMutationOptions<UpdateSupplierChangeThirdPartyContractMutation, UpdateSupplierChangeThirdPartyContractMutationVariables>;
export const UpdateSupplierChangeLocalContractDocument = gql`
    mutation UpdateSupplierChangeLocalContract($workflowId: String!, $payload: UpdateSupplierChangeLocalContractInput!) {
  updateSupplierChangeLocalContract(workflowId: $workflowId, payload: $payload) {
    done
    startedAt
    contractId
    type
  }
}
    `;
export type UpdateSupplierChangeLocalContractMutationFn = Apollo.MutationFunction<UpdateSupplierChangeLocalContractMutation, UpdateSupplierChangeLocalContractMutationVariables>;

/**
 * __useUpdateSupplierChangeLocalContractMutation__
 *
 * To run a mutation, you first call `useUpdateSupplierChangeLocalContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSupplierChangeLocalContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSupplierChangeLocalContractMutation, { data, loading, error }] = useUpdateSupplierChangeLocalContractMutation({
 *   variables: {
 *      workflowId: // value for 'workflowId'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpdateSupplierChangeLocalContractMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSupplierChangeLocalContractMutation, UpdateSupplierChangeLocalContractMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSupplierChangeLocalContractMutation, UpdateSupplierChangeLocalContractMutationVariables>(UpdateSupplierChangeLocalContractDocument, options);
      }
export type UpdateSupplierChangeLocalContractMutationHookResult = ReturnType<typeof useUpdateSupplierChangeLocalContractMutation>;
export type UpdateSupplierChangeLocalContractMutationResult = Apollo.MutationResult<UpdateSupplierChangeLocalContractMutation>;
export type UpdateSupplierChangeLocalContractMutationOptions = Apollo.BaseMutationOptions<UpdateSupplierChangeLocalContractMutation, UpdateSupplierChangeLocalContractMutationVariables>;
export const UpdateSupplierChangeCancellationWorkflowDocument = gql`
    mutation UpdateSupplierChangeCancellationWorkflow($workflowId: String!, $cancelled: Boolean!, $contractId: String!) {
  updateSupplierChangeCancellationWorkflow(workflowId: $workflowId, cancelled: $cancelled, contractId: $contractId) {
    done
    startedAt
    contractId
    type
  }
}
    `;
export type UpdateSupplierChangeCancellationWorkflowMutationFn = Apollo.MutationFunction<UpdateSupplierChangeCancellationWorkflowMutation, UpdateSupplierChangeCancellationWorkflowMutationVariables>;

/**
 * __useUpdateSupplierChangeCancellationWorkflowMutation__
 *
 * To run a mutation, you first call `useUpdateSupplierChangeCancellationWorkflowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSupplierChangeCancellationWorkflowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSupplierChangeCancellationWorkflowMutation, { data, loading, error }] = useUpdateSupplierChangeCancellationWorkflowMutation({
 *   variables: {
 *      workflowId: // value for 'workflowId'
 *      cancelled: // value for 'cancelled'
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useUpdateSupplierChangeCancellationWorkflowMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSupplierChangeCancellationWorkflowMutation, UpdateSupplierChangeCancellationWorkflowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSupplierChangeCancellationWorkflowMutation, UpdateSupplierChangeCancellationWorkflowMutationVariables>(UpdateSupplierChangeCancellationWorkflowDocument, options);
      }
export type UpdateSupplierChangeCancellationWorkflowMutationHookResult = ReturnType<typeof useUpdateSupplierChangeCancellationWorkflowMutation>;
export type UpdateSupplierChangeCancellationWorkflowMutationResult = Apollo.MutationResult<UpdateSupplierChangeCancellationWorkflowMutation>;
export type UpdateSupplierChangeCancellationWorkflowMutationOptions = Apollo.BaseMutationOptions<UpdateSupplierChangeCancellationWorkflowMutation, UpdateSupplierChangeCancellationWorkflowMutationVariables>;
export const RestartWorkflowDocument = gql`
    mutation restartWorkflow($workflowId: String!) {
  restartWorkflow(workflowId: $workflowId) {
    done
    contractId
    startedAt
    type
  }
}
    `;
export type RestartWorkflowMutationFn = Apollo.MutationFunction<RestartWorkflowMutation, RestartWorkflowMutationVariables>;

/**
 * __useRestartWorkflowMutation__
 *
 * To run a mutation, you first call `useRestartWorkflowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestartWorkflowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restartWorkflowMutation, { data, loading, error }] = useRestartWorkflowMutation({
 *   variables: {
 *      workflowId: // value for 'workflowId'
 *   },
 * });
 */
export function useRestartWorkflowMutation(baseOptions?: Apollo.MutationHookOptions<RestartWorkflowMutation, RestartWorkflowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestartWorkflowMutation, RestartWorkflowMutationVariables>(RestartWorkflowDocument, options);
      }
export type RestartWorkflowMutationHookResult = ReturnType<typeof useRestartWorkflowMutation>;
export type RestartWorkflowMutationResult = Apollo.MutationResult<RestartWorkflowMutation>;
export type RestartWorkflowMutationOptions = Apollo.BaseMutationOptions<RestartWorkflowMutation, RestartWorkflowMutationVariables>;
export const TerminateContractDocument = gql`
    mutation terminateContract($contractId: ID!, $contractLabel: ID!, $currentDate: Date!, $termination: ContractTerminationInput!) {
  terminateContract(contractId: $contractId, contractLabel: $contractLabel, currentDate: $currentDate, termination: $termination) {
    workflowId
  }
}
    `;
export type TerminateContractMutationFn = Apollo.MutationFunction<TerminateContractMutation, TerminateContractMutationVariables>;

/**
 * __useTerminateContractMutation__
 *
 * To run a mutation, you first call `useTerminateContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTerminateContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [terminateContractMutation, { data, loading, error }] = useTerminateContractMutation({
 *   variables: {
 *      contractId: // value for 'contractId'
 *      contractLabel: // value for 'contractLabel'
 *      currentDate: // value for 'currentDate'
 *      termination: // value for 'termination'
 *   },
 * });
 */
export function useTerminateContractMutation(baseOptions?: Apollo.MutationHookOptions<TerminateContractMutation, TerminateContractMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TerminateContractMutation, TerminateContractMutationVariables>(TerminateContractDocument, options);
      }
export type TerminateContractMutationHookResult = ReturnType<typeof useTerminateContractMutation>;
export type TerminateContractMutationResult = Apollo.MutationResult<TerminateContractMutation>;
export type TerminateContractMutationOptions = Apollo.BaseMutationOptions<TerminateContractMutation, TerminateContractMutationVariables>;
export const TransitionWorkflowDocument = gql`
    mutation transitionWorkflow($workflowId: ID!, $transitionName: String!) {
  transitionWorkflow(workflowId: $workflowId, transitionName: $transitionName) {
    id
    alias
    type
    stateNames
    done
    meta
    createdAt
    doneAt
    updatedAt
    currentState {
      name
      parentName
      context
      transitions
    }
  }
}
    `;
export type TransitionWorkflowMutationFn = Apollo.MutationFunction<TransitionWorkflowMutation, TransitionWorkflowMutationVariables>;

/**
 * __useTransitionWorkflowMutation__
 *
 * To run a mutation, you first call `useTransitionWorkflowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransitionWorkflowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transitionWorkflowMutation, { data, loading, error }] = useTransitionWorkflowMutation({
 *   variables: {
 *      workflowId: // value for 'workflowId'
 *      transitionName: // value for 'transitionName'
 *   },
 * });
 */
export function useTransitionWorkflowMutation(baseOptions?: Apollo.MutationHookOptions<TransitionWorkflowMutation, TransitionWorkflowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TransitionWorkflowMutation, TransitionWorkflowMutationVariables>(TransitionWorkflowDocument, options);
      }
export type TransitionWorkflowMutationHookResult = ReturnType<typeof useTransitionWorkflowMutation>;
export type TransitionWorkflowMutationResult = Apollo.MutationResult<TransitionWorkflowMutation>;
export type TransitionWorkflowMutationOptions = Apollo.BaseMutationOptions<TransitionWorkflowMutation, TransitionWorkflowMutationVariables>;
export const UpdateCardDocument = gql`
    mutation updateCard($id: BigInt!, $patch: CardPatch!) {
  updateCard(input: {patch: $patch, id: $id}) {
    card {
      id
      ajv
      reductionstartdate
      reduction
      city
      country
      createdat
      creator
      email
      isperson
      firstname
      lastname
      companyname
      co
      contractstartdate
      contractenddate
      phone
      state
      street
      streetnr
      leadorigin
      tariffid
      workspaceid
    }
  }
}
    `;
export type UpdateCardMutationFn = Apollo.MutationFunction<UpdateCardMutation, UpdateCardMutationVariables>;

/**
 * __useUpdateCardMutation__
 *
 * To run a mutation, you first call `useUpdateCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCardMutation, { data, loading, error }] = useUpdateCardMutation({
 *   variables: {
 *      id: // value for 'id'
 *      patch: // value for 'patch'
 *   },
 * });
 */
export function useUpdateCardMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCardMutation, UpdateCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCardMutation, UpdateCardMutationVariables>(UpdateCardDocument, options);
      }
export type UpdateCardMutationHookResult = ReturnType<typeof useUpdateCardMutation>;
export type UpdateCardMutationResult = Apollo.MutationResult<UpdateCardMutation>;
export type UpdateCardMutationOptions = Apollo.BaseMutationOptions<UpdateCardMutation, UpdateCardMutationVariables>;
export const UpdateLaneForCardDocument = gql`
    mutation updateLaneForCard($cardId: BigInt!, $patch: LaneCardRelPatch!, $clientMutationId: String) {
  updateLaneCardRel(input: {patch: $patch, idCard: $cardId, clientMutationId: $clientMutationId}) {
    clientMutationId
  }
}
    `;
export type UpdateLaneForCardMutationFn = Apollo.MutationFunction<UpdateLaneForCardMutation, UpdateLaneForCardMutationVariables>;

/**
 * __useUpdateLaneForCardMutation__
 *
 * To run a mutation, you first call `useUpdateLaneForCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLaneForCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLaneForCardMutation, { data, loading, error }] = useUpdateLaneForCardMutation({
 *   variables: {
 *      cardId: // value for 'cardId'
 *      patch: // value for 'patch'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useUpdateLaneForCardMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLaneForCardMutation, UpdateLaneForCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLaneForCardMutation, UpdateLaneForCardMutationVariables>(UpdateLaneForCardDocument, options);
      }
export type UpdateLaneForCardMutationHookResult = ReturnType<typeof useUpdateLaneForCardMutation>;
export type UpdateLaneForCardMutationResult = Apollo.MutationResult<UpdateLaneForCardMutation>;
export type UpdateLaneForCardMutationOptions = Apollo.BaseMutationOptions<UpdateLaneForCardMutation, UpdateLaneForCardMutationVariables>;
export const UpdateAnnualConsumptionDocument = gql`
    mutation updateAnnualConsumption($contractId: ID!, $consumption: UpdateAnnualConsumptionInput!) {
  updateAnnualConsumption(contractId: $contractId, consumption: $consumption) {
    startDate
    endDate
    value
  }
}
    `;
export type UpdateAnnualConsumptionMutationFn = Apollo.MutationFunction<UpdateAnnualConsumptionMutation, UpdateAnnualConsumptionMutationVariables>;

/**
 * __useUpdateAnnualConsumptionMutation__
 *
 * To run a mutation, you first call `useUpdateAnnualConsumptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAnnualConsumptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAnnualConsumptionMutation, { data, loading, error }] = useUpdateAnnualConsumptionMutation({
 *   variables: {
 *      contractId: // value for 'contractId'
 *      consumption: // value for 'consumption'
 *   },
 * });
 */
export function useUpdateAnnualConsumptionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAnnualConsumptionMutation, UpdateAnnualConsumptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAnnualConsumptionMutation, UpdateAnnualConsumptionMutationVariables>(UpdateAnnualConsumptionDocument, options);
      }
export type UpdateAnnualConsumptionMutationHookResult = ReturnType<typeof useUpdateAnnualConsumptionMutation>;
export type UpdateAnnualConsumptionMutationResult = Apollo.MutationResult<UpdateAnnualConsumptionMutation>;
export type UpdateAnnualConsumptionMutationOptions = Apollo.BaseMutationOptions<UpdateAnnualConsumptionMutation, UpdateAnnualConsumptionMutationVariables>;
export const UpdatePasswordDocument = gql`
    mutation updatePassword($email: String!, $password: String!, $currentPassword: String!, $updatePasswordCallbackUrl: String!) {
  updatePassword(email: $email, password: $password, currentPassword: $currentPassword, updatePasswordCallbackUrl: $updatePasswordCallbackUrl) {
    __typename
    ... on UnknownError {
      message
    }
    ... on UpdatePasswordEmailFailed {
      message
    }
    ... on WrongCredentialsError {
      message
    }
    ... on EmailNotFound {
      message
    }
    ... on UpdatePasswordSuccess {
      success
      message
    }
  }
}
    `;
export type UpdatePasswordMutationFn = Apollo.MutationFunction<UpdatePasswordMutation, UpdatePasswordMutationVariables>;

/**
 * __useUpdatePasswordMutation__
 *
 * To run a mutation, you first call `useUpdatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePasswordMutation, { data, loading, error }] = useUpdatePasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      currentPassword: // value for 'currentPassword'
 *      updatePasswordCallbackUrl: // value for 'updatePasswordCallbackUrl'
 *   },
 * });
 */
export function useUpdatePasswordMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePasswordMutation, UpdatePasswordMutationVariables>(UpdatePasswordDocument, options);
      }
export type UpdatePasswordMutationHookResult = ReturnType<typeof useUpdatePasswordMutation>;
export type UpdatePasswordMutationResult = Apollo.MutationResult<UpdatePasswordMutation>;
export type UpdatePasswordMutationOptions = Apollo.BaseMutationOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>;
export const UpdatePriceSheetDocument = gql`
    mutation updatePriceSheet($tariffId: ID!, $priceSheetId: ID!, $priceSheet: PriceSheetEdit!) {
  updatePriceSheet(tariffId: $tariffId, priceSheetId: $priceSheetId, priceSheet: $priceSheet) {
    tariff {
      id
    }
  }
}
    `;
export type UpdatePriceSheetMutationFn = Apollo.MutationFunction<UpdatePriceSheetMutation, UpdatePriceSheetMutationVariables>;

/**
 * __useUpdatePriceSheetMutation__
 *
 * To run a mutation, you first call `useUpdatePriceSheetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePriceSheetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePriceSheetMutation, { data, loading, error }] = useUpdatePriceSheetMutation({
 *   variables: {
 *      tariffId: // value for 'tariffId'
 *      priceSheetId: // value for 'priceSheetId'
 *      priceSheet: // value for 'priceSheet'
 *   },
 * });
 */
export function useUpdatePriceSheetMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePriceSheetMutation, UpdatePriceSheetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePriceSheetMutation, UpdatePriceSheetMutationVariables>(UpdatePriceSheetDocument, options);
      }
export type UpdatePriceSheetMutationHookResult = ReturnType<typeof useUpdatePriceSheetMutation>;
export type UpdatePriceSheetMutationResult = Apollo.MutationResult<UpdatePriceSheetMutation>;
export type UpdatePriceSheetMutationOptions = Apollo.BaseMutationOptions<UpdatePriceSheetMutation, UpdatePriceSheetMutationVariables>;