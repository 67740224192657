import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { ErrorMsg, PaddedShadowBox, SubTitle } from '../../components';
import { useReadTariffsQuery } from '../../graphql-types';

import EditPricesheetTable from './pricesheetTable';

const Box = styled(PaddedShadowBox)`
  padding-top: 1px;
  margin-bottom: 10px;
`;

function TariffPricesheet() {
  const { tariffId } = useParams<{ tariffId: string }>();

  const { data: dataTariffs, loading, error, refetch } = useReadTariffsQuery();
  const data = dataTariffs?.readTariffs.find(
    (tariff) => tariff.id === tariffId,
  );

  let inner = null;

  if (!tariffId) {
    inner = <ErrorMsg message="Id des Tarif fehlt." />;
  } else if (error) {
    inner = <ErrorMsg error={error} />;
  } else {
    inner = (
      <EditPricesheetTable
        tariff={data}
        isLoading={loading}
        refetch={refetch}
      />
    );
  }

  return (
    <Box>
      <SubTitle>Preisblätter</SubTitle>
      {inner}
    </Box>
  );
}

export default TariffPricesheet;
