import { ApolloClient } from '@apollo/client';
import gql from 'graphql-tag';

const readCustomerExistsQuery = gql`
  query readCustomerExists($customerLabel: String!) {
    readCustomerExists(customerLabel: $customerLabel) {
      exists
      customerId
    }
  }
`;

export const customerExists =
  (client: ApolloClient<any>) => async (customerLabel: string | undefined) => {
    if (!customerLabel || customerLabel.trim().length === 0) return false;

    const { data } = await client.query({
      query: readCustomerExistsQuery,
      variables: {
        customerLabel,
      },
      fetchPolicy: 'network-only',
    });
    return !data?.readCustomerExists?.exists;
  };

const readContractExistsQuery = gql`
  query readContractExists($contractLabel: String!) {
    readContractExists(contractLabel: $contractLabel) {
      exists
      contractId
    }
  }
`;

export const contractExists =
  (client: ApolloClient<any>) => async (contractLabel: string | undefined) => {
    if (!contractLabel || contractLabel.trim().length === 0) return false;

    const { data } = await client.query({
      query: readContractExistsQuery,
      variables: {
        contractLabel,
      },
      fetchPolicy: 'network-only',
    });
    return !data?.readContractExists?.exists;
  };
