import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import {
  applyPolyfills,
  defineCustomElements,
} from '@ampeersenergy/ampeers-ui-core/loader';
import { ApolloProvider } from '@apollo/client';
import { QueryParamProvider } from 'use-query-params';
import type {} from 'styled-components/cssprop';

import App from './App';
import * as serviceWorker from './serviceWorker';
import { history } from './browserHistory';
import rootStore from './redux/rootStore';
import { client } from './apollo-client';

// s. https://github.com/ionic-team/stencil/issues/1090#issuecomment-501124883
/* declare global {
  type StencilToReact<T> = {
    [P in keyof T]?: T[P] &
      Omit<React.HTMLAttributes<Element>, "className"> & { class?: string };
  };

  // eslint-disable-next-line @typescript-eslint/no-namespace
  export namespace JSX {
    interface IntrinsicElements
      extends StencilToReact<LocalJSX.IntrinsicElements> {}
  }
} */

ReactDOM.render(
  <Router history={history}>
    <QueryParamProvider ReactRouterRoute={Route}>
      <ApolloProvider client={client}>
        <Provider store={rootStore}>
          <App />
        </Provider>
      </ApolloProvider>
    </QueryParamProvider>
  </Router>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// register ui core components
// https://stenciljs.com/docs/react
applyPolyfills().then(async () => {
  await defineCustomElements();
});
