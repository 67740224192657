import * as React from 'react';
import { Button } from '@ampeersenergy/ampeers-ui-components';
import ContentLoader from 'react-content-loader';

import { ButtonWrap, Item, List } from '../style';

interface ContractPreviewStepProps<T> {
  contracts: T[];
  isLoading?: boolean;
  overviewDescription: React.ReactNode;
  getId: (contract: T) => string;
  renderItem: (contract: T) => React.ReactNode;
  onNext: () => void;
}

export function ContractPreviewStep<T>({
  contracts,
  overviewDescription,
  isLoading,
  getId,
  renderItem,
  onNext,
}: ContractPreviewStepProps<T>) {
  return (
    <>
      {overviewDescription}
      <ButtonWrap>
        <Button onClick={onNext} disabled={isLoading} isLoading={isLoading}>
          Weiter
        </Button>
      </ButtonWrap>
      <List>
        {!isLoading
          ? contracts.map((c) => {
              const id = getId(c);

              return (
                <Item as="li" key={id}>
                  {renderItem(c)}
                </Item>
              );
            })
          : ['1-loading', '2-loading', '3-loading'].map((c) => (
              <Item as="li" key={c}>
                <ContentLoader height={21} width={525} speed={2}>
                  <rect x="0" y="0" rx="0" ry="0" width="525" height="21" />
                </ContentLoader>
              </Item>
            ))}
      </List>
    </>
  );
}
