import { AnalyticsBrowser } from '@segment/analytics-next';

import { User } from '../graphql-types';

import { _ae_env_ } from './env';

export const loadMSClarityScript = (cookiePresent: boolean) => {
  if (cookiePresent) {
    const script = document.createElement('script');

    script.id = 'ms-clarity';
    script.type = 'text/javascript';
    script.innerHTML = `
    (function (c, l, a, r, i, t, y) {
      c[a] =
        c[a] ||
        function () {
          (c[a].q = c[a].q || []).push(arguments);
        };
      t = l.createElement(r);
      t.async = 1;
      t.src = "https://www.clarity.ms/tag/" + i;
      y = l.getElementsByTagName(r)[0];
      y.parentNode.insertBefore(t, y);
    })(
      window,
      document,
      "clarity",
      "script",
      "${_ae_env_.REACT_APP_MS_CLARITY_TRACKING}"
    );
  `;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }

  const sourceClarityScripts = document.querySelectorAll(
    `script[src*="${'clarity.ms'}"]`,
  );
  sourceClarityScripts.forEach((script) => {
    script.remove();
  });

  const script = document.getElementById('ms-clarity');

  if (script) {
    script.remove();
  }

  if (window.clarity) {
    window.clarity('stop');
  }
};

export const loadSegmentScript = (cookiePresent: boolean) => {
  if (cookiePresent) {
    const script = document.createElement('script');
    script.id = 'segment';
    script.type = 'text/javascript';
    script.innerHTML = `
      !function(){var i="analytics",analytics=window[i]=window[i]||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","screen","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware","register"];analytics.factory=function(e){return function(){if(window[i].initialized)return window[i][e].apply(window[i],arguments);var n=Array.prototype.slice.call(arguments);if(["track","screen","alias","group","page","identify"].indexOf(e)>-1){var c=document.querySelector("link[rel='canonical']");n.push({__t:"bpc",c:c&&c.getAttribute("href")||void 0,p:location.pathname,u:location.href,s:location.search,t:document.title,r:document.referrer})}n.unshift(e);analytics.push(n);return analytics}};for(var n=0;n<analytics.methods.length;n++){var key=analytics.methods[n];analytics[key]=analytics.factory(key)}analytics.load=function(key,n){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.setAttribute("data-global-segment-analytics-key",i);t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var r=document.getElementsByTagName("script")[0];r.parentNode.insertBefore(t,r);analytics._loadOptions=n};analytics._writeKey="ladiOyj0Duf96ALq8Q99oy9djEOIxCTi";;analytics.SNIPPET_VERSION="5.2.0";
      analytics.load("${_ae_env_.REACT_APP_SEGMENT_API_KEY}");
      analytics.page();
      }}();
  `;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }
};

export const identifySegmentUser = (
  user?: Pick<User, 'id' | 'email' | 'firstName' | 'lastName'>,
) => {
  if (user) {
    segmentAnalytics.identify(user.id, {
      name: `${user.firstName} ${user.lastName}`,
      email: user.email,
    });
  } else {
    segmentAnalytics.identify('.1', {
      name: 'Unidentified User',
      email: '',
    });
  }
};

export const segmentAnalytics = AnalyticsBrowser.load({
  writeKey: _ae_env_.REACT_APP_SEGMENT_API_KEY ?? '',
});

export const logErrorToClarity = (key?: string, value?: string | string[]) => {
  if (window.clarity) {
    window.clarity('set', 'error', `${key ?? ''}-${value ?? ''}`);
  }
};
