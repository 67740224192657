/* eslint-disable react/destructuring-assignment */
import React from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import { Icons } from '@ampeersenergy/ampeers-ui-components';

import { FlexRow, Flex } from './layout';
import PageTitle from './pageTitle';
import { CloseIconWrap } from './nestedPage';
import { __DEV__ } from './graphql-form/isDev';

if (process.env.NODE_ENV !== 'test') {
  ReactModal.setAppElement('#root');
}

type ModalBodyProps = {
  minWidth?: number;
  minHeight?: number;
};

const ModalBody = styled.div<ModalBodyProps>`
  background: #fff;
  border-radius: 4px;
  padding: 15px;
  outline: 0;

  min-width: ${(props) => props.minWidth}px;
  min-height: ${(props) => props.minHeight}px;
`;

interface ModalProps {
  isOpen: boolean;
  onRequestClose?: () => void;
  contentLabel: string;
  children: any;
  title: string;
  minWidth?: number;
  minHeight?: number;
}

const CloseWrapper = styled(CloseIconWrap)`
  display: inline-block;
  margin-top: inherit;
  align-self: center;
`;

export default function Modal(props: ModalProps) {
  const { children, title, minWidth, minHeight, onRequestClose, ...rest } =
    props;

  return (
    <ReactModal
      className="modal"
      overlayClassName="modal-overlay"
      closeTimeoutMS={300}
      onRequestClose={onRequestClose}
      appElement={document.getElementById('root') as HTMLElement}
      ariaHideApp={__DEV__}
      {...rest}
    >
      <ModalBody
        id={props.contentLabel}
        minWidth={minWidth}
        minHeight={minHeight}
      >
        <FlexRow>
          <Flex>
            <PageTitle>{title}</PageTitle>
          </Flex>
          <CloseWrapper onClick={() => onRequestClose && onRequestClose()}>
            <Icons.Close
              size={30}
              color="#909090"
              style={{ verticalAlign: 'bottom' }}
            />
          </CloseWrapper>
        </FlexRow>
        {children}
      </ModalBody>
    </ReactModal>
  );
}

const OverlayWrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  background: #fff;
  top: 0;
  bottom: 0;
  z-index: 2;
  overflow: scroll;
`;

const Center = styled.div`
  width: 1024px;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 10px;
`;

export function Overlay({
  children,
  title,
  onClose,
  isOpen,
}: {
  children: React.ReactNode;
  title: string;
  onClose: () => void;
  isOpen: boolean;
}) {
  if (!isOpen) {
    return null;
  }

  return (
    <OverlayWrapper>
      <Center>
        <FlexRow>
          <Flex>
            <PageTitle>{title}</PageTitle>
          </Flex>
          <CloseWrapper onClick={onClose}>
            <Icons.Close
              size={30}
              color="#909090"
              style={{ verticalAlign: 'bottom' }}
            />
          </CloseWrapper>
        </FlexRow>
        {children}
      </Center>
    </OverlayWrapper>
  );
}
