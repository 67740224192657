import React, { useState } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';

import { Bold, Flex, FlexRow, PaddedShadowBox } from '../../../../components';
import MultipleBankDetailsModal from '../../../../components/MultipleBankDetailsModal';
import { EditContainerProps } from '../../../../components/createFlow';
import GraphqlForm from '../../../../components/graphql-form';
import { GraphqlFormField } from '../../../../components/graphql-form/render';
import { IbanExistsErrorClass } from '../../../../components/graphql-form/types';
import {
  ReadContractDocument,
  ReadContractQuery,
  UpdateContractInput,
} from '../../../../graphql-types';
import { yupAddress } from '../../../../helpers/validators';
import {
  yupBankAccount,
  yupBankAccountNullable,
} from '../../../../helpers/validators/bankAccount';
import { AddressForm } from '../../create/AddressForm';
import { BirthdayForm, PersonContactForm } from '../../create/sharedFormParts';
import { EditBillingShippingAddressForm } from '../../edit/editBillingShippingAddressForm';
import { PaymentEditForm, PersonForm } from '../../edit/editFormParts';

const Spacer = styled.div`
  width: 25px;
`;

const FieldGroup = styled.div`
  margin-top: 25px;
  margin-bottom: 25px;
`;

const FlexRowStyled = styled(FlexRow)`
  margin-top: -25px;
  margin-bottom: -25px;
`;

const Headline = styled(Bold)`
  margin-bottom: 0px;
`;

interface CustomerPageProps extends EditContainerProps {
  contract?: ReadContractQuery['readContract'];
  isLoading?: boolean;
}

function CustomerPage({
  contract,
  isLoading,
  onSuccess,
  onAbort,
  formVariables,
}: CustomerPageProps) {
  const [ibanExistsError, setIbanExistsError] =
    useState<IbanExistsErrorClass | null>(null);

  return (
    <GraphqlForm
      mutation="updateContract"
      formVariables={{ ...formVariables, useCase: 'updateContract' }}
      startInEdit={false}
      readDocument={ReadContractDocument}
      variables={{
        contractId: contract?.id,
        contractLabel: contract?.label,
        customerId: contract?.customer?.id,
        customerLabel: contract?.customer?.label,
        contract: { status: contract?.status },
      }}
      isLoading={isLoading}
      // We only send values that have been changed to the backend
      // so we need to initially set the partnerIds to null so that
      // they are perceived as changed and sent to the backend
      values={{
        ...contract,
        customer: {
          ...contract?.customer,
          addressBilling: {
            ...contract?.customer.addressBilling,
            partnerId: null,
            additionalPartnerId: null,
          },
        },
      }}
      onSuccess={onSuccess}
      onAbort={onAbort}
      onError={(error) => {
        if (error instanceof IbanExistsErrorClass) {
          setIbanExistsError(error);
        }
      }}
      refetchQueries={[
        {
          query: ReadContractDocument,
          variables: {
            contractId: contract?.id,
            contractLabel: contract?.label,
            customerId: contract?.customer?.id,
            customerLabel: contract?.customer?.label,
          },
        },
      ]}
      formatBeforeSubmit={(values: UpdateContractInput) => ({
        ...values,
        customer: {
          ...values.customer,
          bankAccount: {
            ...values.customer?.bankAccount,
            iban: values.customer?.bankAccount?.iban?.replace(/ /g, ''),
            bic: values.customer?.bankAccount?.bic?.replace(/ /g, ''),
          },
          addressBilling: {
            ...values.customer?.addressBilling,
            partnerId: contract?.customer.addressBilling.partnerId,
            additionalPartnerId:
              contract?.customer.addressBilling.additionalPartnerId,
          },
        },
      })}
      validation={{
        customer: {
          addressShipping: yupAddress(true),
          birthday: yup.date().nullable(),
          address: yupAddress(true),
          addressBilling: yupAddress(true, {
            email: yup
              .string()
              .email()
              .nullable()
              .test(
                'email',
                'Pflichtfeld',
                (
                  value: string | undefined | null,
                  context: yup.TestContext,
                ) => {
                  const { documentDeliveryMethod } =
                    (context as any).from[1]?.value ?? {};

                  if (documentDeliveryMethod !== 'email' || value) {
                    return true;
                  }
                  return false;
                },
              ),
          }),
          bankAccount: yup.object().when('hasSepa', {
            is: true,
            then: yupBankAccount,
            otherwise: yupBankAccountNullable,
          }),
          payer: yup.object().nullable(),
        },
        downPayment: yup.number().nullable(),
        downPaymentStartDate: yup.date().nullable(),
      }}
    >
      <PaddedShadowBox>
        <FlexRowStyled>
          <Flex>
            <FieldGroup>
              <Headline>Kundennummer</Headline>
              <div>{contract?.customer?.label ?? 'Lade…'}</div>
            </FieldGroup>
            <FieldGroup>
              <Headline>Vertragspartner</Headline>
              <PersonForm />
              <AddressForm fieldNamePrefix="customer.address" />
            </FieldGroup>
          </Flex>
          <Spacer />
          <Flex>
            <FieldGroup>
              <Headline>Kontakt</Headline>
              <PersonContactForm fieldNamePrefix="" />
            </FieldGroup>
            <FieldGroup>
              <Headline>Geburtsdatum</Headline>
              <BirthdayForm fieldNamePrefix="" />
            </FieldGroup>
          </Flex>
        </FlexRowStyled>
        <FieldGroup>
          <EditBillingShippingAddressForm fieldNamePrefix="customer.addressBilling" />
        </FieldGroup>
        <FlexRowStyled>
          <Flex>
            <FieldGroup>
              <Headline>Rechnungszustellung</Headline>
              <GraphqlFormField
                name="customer.documentDeliveryMethod"
                label={null}
              />
            </FieldGroup>
            <FieldGroup>
              <Headline>Lieferadresse</Headline>
              <EditBillingShippingAddressForm fieldNamePrefix="customer.addressShipping" />
            </FieldGroup>
          </Flex>
          <Spacer />
          <Flex>
            <FieldGroup>
              <Headline>Zahlungsweise</Headline>
              <PaymentEditForm fieldNamePrefix="customer" />
            </FieldGroup>
            <FieldGroup>
              <GraphqlFormField name="customer.advertisementAccepted" />
            </FieldGroup>
          </Flex>
        </FlexRowStyled>
        {ibanExistsError && (
          <MultipleBankDetailsModal
            closeModal={() => setIbanExistsError(null)}
            error={ibanExistsError}
          />
        )}
      </PaddedShadowBox>
    </GraphqlForm>
  );
}

export default CustomerPage;
