import gql from 'graphql-tag';

export default gql`
  query readClientSmtpConfig {
    readClientSmtpConfig {
      active
      host
      port
      mailFrom
      secure
      tls
      auth {
        user
      }
    }
  }
`;
