import gql from 'graphql-tag';

export default gql`
  fragment meterInfo on Meter {
    id
    meterNumber
    meterPlace
    meterType
    melo
    inUse
    msoType
  }
`;
