import React from 'react';

import { InfoMsg } from '../../../../../components';

export default function TariffChangeWarningMessage() {
  return (
    <InfoMsg
      title="Hinweis"
      message="Bitte beachten Sie, dass der Tarif bei gestarteten Abrechnungen nicht angepasst wird. Um Anpassungen wirksam zu machen, starten Sie den Abrechnungsprozess anschließend neu."
    />
  );
}
