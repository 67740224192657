import { FlexRow, InvoiceState } from '@ampeersenergy/ampeers-ui-components';
import React from 'react';
import ContentLoader from 'react-content-loader';
import styled from 'styled-components';

import {
  AccountMove,
  InvoiceState as InvoiceStateType,
} from '../../../../../graphql-types';
import {
  formatCurrency,
  formatDate,
} from '../../../../../helpers/formatStrings';
import { assertNever } from '../../../../../helpers/type.helpers';

export const AccountingMoveList: React.FC<{
  entries: AccountMove[];
  loading: boolean;
  onClick?: (item: AccountMove) => void;
}> = ({ entries, loading, onClick }) => {
  return (
    <>
      {loading && (
        <List>
          <AccountMoveItemLoading />
          <AccountMoveItemLoading />
          <AccountMoveItemLoading />
        </List>
      )}
      {!loading && (
        <List>
          {entries.map((item) => (
            <AccountMoveItem
              item={item}
              key={`${item.bookedAt}-${item.amount}-${item.state}-${item.sepaReturn}`}
              onClick={onClick}
            />
          ))}{' '}
          {entries.length === 0 && <Empty>Keine Einträge</Empty>}
        </List>
      )}
    </>
  );
};

const formatSecondDate = (i: AccountMove) => {
  switch (i.state) {
    case InvoiceStateType.Canceled:
      return `storniert am ${formatDate(i.canceledAt)}`;
    case InvoiceStateType.Paid:
    case InvoiceStateType.OverPaid:
      return `bezahlt am ${formatDate(i.paidAt)}`;
    case InvoiceStateType.UnderPaid:
      return `Teilzahlung am ${formatDate(i.partialPaidAt)}`;
    case InvoiceStateType.Open:
      return 'offen';
    default:
      assertNever(i.state);
  }
};

const mapInvoiceState = (
  i: AccountMove,
): React.ComponentProps<typeof InvoiceState>['state'] => {
  switch (i.state) {
    case InvoiceStateType.UnderPaid:
      return 'partially-paid';

    case InvoiceStateType.OverPaid:
      return 'paid';

    default:
      return i.state.toLowerCase().replace('_', '-') as React.ComponentProps<
        typeof InvoiceState
      >['state'];
  }
};

const mapColor = (i: AccountMove) => {
  switch (i.state) {
    case InvoiceStateType.Paid:
      return 'green';
    case InvoiceStateType.Canceled:
      return 'red';
    default:
      return undefined;
  }
};

const formatAmount = (i: AccountMove): string => {
  if (
    i.state === InvoiceStateType.UnderPaid ||
    i.state === InvoiceStateType.OverPaid
  ) {
    return `${formatCurrency(i.partialAmount!)} von ${formatCurrency(
      i.amount,
    )}`;
  }

  return formatCurrency(i.amount);
};

function AccountMoveItem({
  item,
  onClick,
}: {
  item: AccountMove;
  onClick?: (curItem: AccountMove) => void;
}) {
  return (
    <Entry
      key={`${item.bookedAt}-${item.amount}-${item.state}-${item.sepaReturn}`}
      {...(onClick ? { onClick: () => onClick(item) } : {})}
    >
      <EntryInner>
        <InvoiceState
          state={mapInvoiceState(item)}
          color={mapColor(item)}
          id={`${item.bookedAt}-${item.amount}-${item.state}-${item.sepaReturn}`}
          isSepaReturn={!!item.sepaReturn}
        />
        <Name>{item.name}</Name>
        <BookingDate> {formatDate(item.bookedAt)}</BookingDate>
        <SecondDate> {formatSecondDate(item)}</SecondDate>
        <Amount>
          {item.sepa && <Sepa>sepa</Sepa>}
          {!item.sepa && <SepaSpacer />}
          {formatAmount(item)}
        </Amount>
      </EntryInner>
    </Entry>
  );
}

const contentLoaderProps = {
  speed: 2,
  backgroundColor: '#fafafa',
  foregroundColor: '#ecebeb',
  style: { width: '100%' },
  height: 14,
};

function AccountMoveItemLoading() {
  return (
    <Entry>
      <EntryInner>
        <ContentLoader
          {...contentLoaderProps}
          style={{ height: 26, width: 26 }}
        >
          <circle cx="12" cy="12" r="8" />
        </ContentLoader>
        <Name>
          <ContentLoader {...contentLoaderProps}>
            <rect x="0" y="0" rx="3" ry="3" width="100%" height="100%" />
          </ContentLoader>
        </Name>
        <BookingDate>
          <ContentLoader {...contentLoaderProps}>
            <rect x="0" y="0" rx="3" ry="3" width="100%" height="100%" />
          </ContentLoader>
        </BookingDate>
        <SecondDate>
          <ContentLoader {...contentLoaderProps}>
            <rect x="0" y="0" rx="3" ry="3" width="100%" height="100%" />
          </ContentLoader>
        </SecondDate>
        <Amount>
          <ContentLoader {...contentLoaderProps}>
            <rect x="0" y="0" rx="3" ry="3" width="100%" height="100%" />
          </ContentLoader>
        </Amount>
      </EntryInner>
    </Entry>
  );
}

export const List = styled.ul`
  margin: 0;
  padding: 5px 10px;
  font-size: 14px;
`;
export const Entry = styled.li`
  list-style: none;
  &:hover {
    ${(props) => props.onClick && 'background-color: #f3f3f3;'}
    ${(props) => props.onClick && 'cursor: pointer;'}
    border-radius: 4px;
  }
`;
export const EntryInner = styled(FlexRow)`
  justify-content: space-between;
  align-items: center;
  padding: 12px 7px;
`;

export const Name = styled.div`
  margin-left: 15px;
  width: 18%;
`;

export const Amount = styled.div`
  text-align: right;
  width: 140px;
`;

export const Sepa = styled.div`
  border: 1px solid #c6c6c6;
  border-radius: 4px;
  text-transform: uppercase;
  display: inline-block;
  color: #ababab;
  letter-spacing: 0.83px;
  font-weight: bold;
  font-size: 12px;
  margin-right: 15px;
  padding: 2px;
  width: 37px;
`;

export const SepaSpacer = styled.div`
  width: 37px;
`;

export const BookingDate = styled.div`
  width: 80px;
`;

const SecondDate = styled.div`
  width: 200px;
`;

export const Empty = styled.div`
  text-align: center;
  color: #616161;
  font-size: 15px;
  padding: 10px;
`;
