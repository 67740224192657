import gql from 'graphql-tag';

export default gql`
  mutation setDownpaymentStart(
    $workflowId: ID!
    $payload: SetDownpaymentStartPayload!
    $transitionName: String!
  ) {
    setDownpaymentStart(
      workflowId: $workflowId
      payload: $payload
      transitionName: $transitionName
    ) {
      id
      alias
      type
      stateNames
      done
      meta
      createdAt
      doneAt
      updatedAt
      currentState {
        name
        parentName
        context
        transitions
      }
    }
  }
`;
