import React from 'react';
import styled from 'styled-components';

import { useReadCurrentUserQuery } from '../../graphql-types';

const Inner = styled.div`
  width: 25px;
  height: 25px;
  background: ${(props) => props.color};
  border-radius: 50px;
  font-size: 13px;
  color: #fff;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  font-weight: bold;
`;

interface UserItemProps {
  color: string;
}

function UserItem({ color }: UserItemProps) {
  const { data } = useReadCurrentUserQuery();

  return (
    <Inner color={color}>
      {`${data?.readCurrentUser.firstName.charAt(
        0,
      )}${data?.readCurrentUser.lastName.charAt(0)}`}
    </Inner>
  );
}
export default UserItem;
