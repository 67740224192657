/* eslint-disable react/destructuring-assignment */
import React from 'react';
import styled from 'styled-components';

export interface IconProps extends React.PropsWithChildren<{}> {
  size: string | number;
  color?: string;
  hover?: string;
  title?: string;
}

const SVGThemed: any = styled.svg`
  vertical-align: sub;
  fill: ${(props) =>
    Object.prototype.hasOwnProperty.call(props, 'color') ? props.color : 'red'};

  &:hover {
    fill: ${(props: any) =>
      Object.prototype.hasOwnProperty.call(props, 'hover')
        ? props.hover
        : 'inherit'};
  }
`;

export function BaseIcon(props: IconProps) {
  return (
    <SVGThemed
      width={props.size}
      height={props.size}
      viewBox="0 0 30 30"
      color={props.color}
      hover={props.hover}
      title={props.title}
    >
      {props.children}
    </SVGThemed>
  );
}

export const StyledIcon = styled.div`
  border: 1px solid #979797;
  border-radius: 4px;
  display: inline-block;
  width: 35px;
  height: 35px;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;

  :hover {
    background: ${(props) => props.theme.primaryColor};
    border-color: ${(props) => props.theme.primaryColor};

    svg {
      fill: #fff;
    }
  }
`;
