import * as React from 'react';
import {
  AlertRetryable,
  Flex,
  Icons,
  Tab,
  Tabs,
  TooltipDark,
} from '@ampeersenergy/ampeers-ui-components';
import ContentLoader from 'react-content-loader';
import { err, ok, Result } from 'neverthrow';
import styled, { useTheme } from 'styled-components';
import { DeepExtractType } from 'ts-deep-extract-types';

import {
  OperationResult,
  ReadContractsBatchQuery,
  useReadContractsBatchQuery,
} from '../../../graphql-types';
import { formatCustomer } from '../../../helpers/formatStrings';
import {
  FileIcon,
  FileIconWrapper,
  Item,
  List,
} from '../../contract-documents/style';
import { CreationError, CreationSuccess } from '../../contract-documents/types';
import { buildDocumentUrl } from '../../../helpers/buildDocumentUrl';
import { ContractNameLabel } from '../../contractNameLabel';

const Wrapper = styled.div`
  padding: 16px 0 0 0;
`;

type ResultType = {
  contractId: string;
  result: Result<CreationSuccess, CreationError>;
};

type ContractBatchResult = DeepExtractType<
  ReadContractsBatchQuery,
  ['readContractsBatch']
>;

function buildResults(documentResults: OperationResult[]) {
  return documentResults?.reduce(
    (acc: { success: ResultType[]; failed: ResultType[] }, cur) => {
      if (cur.data.__typename === 'JSONBox') {
        return {
          ...acc,
          success: [
            ...acc.success,
            {
              contractId: cur.id,
              result: ok<CreationSuccess, CreationError>({
                hash: cur.data.value.hash,
                fileURL: buildDocumentUrl(`documents/${cur.data.value.hash}`),
                success: true,
              }),
            },
          ],
        };
      }
      if (cur.data.__typename === 'OperationError') {
        return {
          ...acc,
          failed: [
            ...acc.failed,
            {
              contractId: cur.id,
              result: err<CreationSuccess, CreationError>({
                reason: cur.data.reason,
              }),
            },
          ],
        };
      }
      return {
        ...acc,
        failed: [
          ...acc.failed,
          {
            contractId: cur.id,
            result: err<CreationSuccess, CreationError>({
              reason: 'Unknown error',
            }),
          },
        ],
      };
    },
    { success: [], failed: [] },
  );
}

interface ContractDocumentsProps {
  results: OperationResult[];
  byLabel?: boolean;
}

export function ContractDocuments({
  results,
  byLabel,
}: ContractDocumentsProps) {
  const ids = React.useMemo(
    () => results.map((result) => result.id),
    [results],
  );

  const { data, error, loading } = useReadContractsBatchQuery({
    variables: { ids, byLabel },
    skip: ids.length === 0,
  });

  const resultStates = React.useMemo(() => buildResults(results), [results]);

  if (loading) {
    return (
      <Wrapper>
        <ContentLoader width="100%" height="200">
          <circle cx="18" cy="34" r="8" />
          <rect x="35" y="24" width="160" height="20" />
          <circle cx="18" cy="78" r="8" />
          <rect x="35" y="68" width="180" height="20" />
          <circle cx="18" cy="122" r="8" />
          <rect x="35" y="112" width="140" height="20" />
          <circle cx="18" cy="166" r="8" />
          <rect x="35" y="156" width="180" height="20" />
        </ContentLoader>
      </Wrapper>
    );
  }

  if (error) {
    return <AlertRetryable error={error} />;
  }

  const successfulResults = resultStates.success.length;
  const failedResults = resultStates.failed.length;

  return (
    <Wrapper>
      <Tabs>
        <Tab title={`${successfulResults} erfolgreich`}>
          <ContractDocumentList
            contracts={data?.readContractsBatch}
            results={resultStates.success}
            byLabel={byLabel}
          />
        </Tab>
        <Tab title={`${failedResults} fehlgeschlagen`}>
          <ContractDocumentList
            contracts={data?.readContractsBatch}
            results={resultStates.failed}
            byLabel={byLabel}
          />
        </Tab>
      </Tabs>
    </Wrapper>
  );
}

interface ContractDocumentListProps {
  results: ResultType[];
  contracts?: ContractBatchResult;
  byLabel?: boolean;
}

function ContractDocumentList({
  results,
  contracts,
  byLabel,
}: ContractDocumentListProps) {
  const theme = useTheme();

  return (
    <List>
      {results.map((result) => {
        const contract = contracts?.find((c) => {
          if (byLabel) return c.label === result.contractId;
          return c.id === result.contractId;
        });

        if (!contract) return null;

        return (
          <Item as="li" key={contract.id}>
            <Flex>
              <ContractNameLabel
                contractName={formatCustomer(contract.customer)}
                contractLabel={contract.label}
              />
            </Flex>
            <aside>
              {result.result.isOk() ? (
                <a
                  aria-label="Datei herunterladen"
                  href={result.result.value.fileURL}
                  download
                >
                  <FileIcon />
                </a>
              ) : (
                <FileIconWrapper>
                  <TooltipDark
                    text={
                      result.result.isErr() ? result.result.error.reason : ''
                    }
                    id={`${contract.id}-error`}
                  >
                    <Icons.Warning
                      size={22}
                      color={theme.palette.warning.color}
                      data-tip
                      data-for={`${contract.id}-error`}
                    />
                  </TooltipDark>
                </FileIconWrapper>
              )}
            </aside>
          </Item>
        );
      })}
    </List>
  );
}
