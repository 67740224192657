import React from 'react';
import { DateTime } from 'luxon';
import * as yup from 'yup';
import { maxBy } from 'lodash';

import {
  // useReadConsumptionsQuery,
  ReadConsumptionsQuery,
  ReadContractQuery,
  ReadConsumptionsDocument,
  useReadConsumptionsQuery,
  UpdateAnnualConsumptionDocument,
} from '../../../../../graphql-types';
import { EditContainerProps } from '../../../../../components/createFlow';
import { yupUTCDate } from '../../../../../helpers/yupUTCDate';
import GraphqlForm from '../../../../../components/graphql-form';
import { PaddedShadowBox, FlexRow, Flex } from '../../../../../components';

import { ConsumptionAnnual } from './consumptionAnnual';
import { ConsumptionValue } from './consumptionValue';

interface ContractPageProps extends EditContainerProps {
  contract?: ReadContractQuery['readContract'];
}

function Consumptions({ contract, onSuccess }: ContractPageProps) {
  const deliveryStartDate = contract?.startDate
    ? DateTime.fromISO(contract?.startDate)
    : DateTime.local();
  const deliveryEndDate = contract?.endDate
    ? DateTime.fromISO(contract?.endDate)
    : undefined;
  const contractID = contract?.id ?? '';
  const skip = !contractID;

  const {
    data: dataConsumption,

    loading: loadingConsumption,
  } = useReadConsumptionsQuery({
    variables: {
      contractID,
    },
    skip,
  });

  const consumptionValues: ReadConsumptionsQuery['readConsumptions'] =
    dataConsumption
      ? dataConsumption.readConsumptions
      : {
          consumptions: [],
          ajvs: [],
        };
  const currentAnnualConsumption = maxBy(consumptionValues.ajvs, 'startDate');

  return (
    <GraphqlForm
      mutation="updateAnnualConsumption"
      readDocument={UpdateAnnualConsumptionDocument}
      variables={{
        contractId: contract?.id,
      }}
      values={{
        value: currentAnnualConsumption?.value,
        startDate: currentAnnualConsumption?.startDate,
        endDate: currentAnnualConsumption?.endDate,
      }}
      isLoading={loadingConsumption}
      disableEdit={false}
      submitAllValues
      onSuccess={onSuccess}
      refetchQueries={[
        {
          query: ReadConsumptionsDocument,
          variables: {
            contractID,
          },
        },
      ]}
      validation={{
        value: yup.number().required(),
        startDate: yupUTCDate
          .min(
            deliveryStartDate.startOf('day').toJSDate(),
            `Beginn der Gültigkeit darf nicht vor dem Lieferbeginn liegen`,
          )
          .test('endDate', 'Zeitbereich schon vorhanden', (value: any) => {
            if (
              currentAnnualConsumption?.endDate === undefined ||
              currentAnnualConsumption?.endDate === null
            ) {
              return true;
            }
            return (
              new Date(currentAnnualConsumption?.endDate).getTime() <
              new Date(value).getTime()
            );
          })
          .required(),
        endDate: yupUTCDate
          .test(
            'endDate',
            'Darf nicht nach dem Vertragsende liegen',
            (value) => {
              if (value && deliveryEndDate) {
                return value.getTime() <= deliveryEndDate.toJSDate().getTime();
              }

              return true;
            },
          )
          .when('startDate', (startDate: any, schema: any) => {
            if (!startDate || Number.isNaN(startDate)) {
              return schema;
            }

            return schema.min(
              startDate,
              `Ende der Gültigkeit darf nicht vor Beginn der Gültigkeit liegen`,
            );
          })
          .required(),
      }}
    >
      <PaddedShadowBox>
        <FlexRow>
          <Flex>
            <ConsumptionAnnual
              consumptionValues={consumptionValues.ajvs}
              loading={loadingConsumption}
            />
          </Flex>
          <Flex>
            <ConsumptionValue
              consumptionValues={consumptionValues.consumptions}
              loading={loadingConsumption}
            />
          </Flex>
        </FlexRow>
      </PaddedShadowBox>
    </GraphqlForm>
  );
}

export default Consumptions;
