import React from 'react';
import styled from 'styled-components';

import { SpinnerDark, FlexRow } from '../../../components';

const SpinnerWrap = styled.div`
  justify-self: center;
  align-self: center;
  margin-right: 5px;
`;

export function StepActive() {
  return (
    <FlexRow>
      <SpinnerWrap>
        <SpinnerDark size={20} />
      </SpinnerWrap>
      Schritt wird ausgeführt …
    </FlexRow>
  );
}
