import styled from 'styled-components';

export const InfoText = styled.div`
  font-size: 15px;
  color: #6d6d6d;
`;

export const Info = styled(InfoText)`
  margin: 15px 0;
`;

export const InfoWrapped = styled(Info)`
  margin-bottom: 30px;
  margin-top: -8px;
`;
