import React from 'react';
import { FlexRow, Icons } from '@ampeersenergy/ampeers-ui-components';
import styled, { useTheme } from 'styled-components';

export const Center = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;
  min-height: 200px;
  justify-content: center;

  h3 {
    margin-top: 3px;
    margin-bottom: 3px;
  }

  button {
    margin-top: 10px;
  }
`;

export const Iframe = styled.iframe`
  &.is-loading {
    display: none;
  }

  border: none;
  outline: 0;
`;

export const ButtonWrap = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
`;

export const Item = styled(FlexRow)`
  list-style: none;
  border: 1px solid #e5e5e5;
  padding: 9px;
  justify-content: space-between;
  border-bottom: none;
  align-items: center;
  height: 45px;

  &:last-child {
    border-bottom: 1px solid #e5e5e5;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:first-child {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }
`;

export const List = styled.ul`
  margin: 0;
  padding: 0;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const Wrapper = styled.div`
  padding: 0px 15px;
`;

export const FixedHeight = styled.div`
  height: 25px;
`;

export const InlineBlock = styled.div`
  display: inline-block;
`;

export const FileIconWrapper = styled.div`
  height: 35px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export function FileIcon() {
  const theme = useTheme();
  return (
    <FileIconWrapper>
      <Icons.File size={20} color={theme.primaryColor} />
    </FileIconWrapper>
  );
}
