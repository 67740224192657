import { DialogProvider } from '@ampeersenergy/ampeers-ui-components';
import * as React from 'react';
import * as yup from 'yup';
import 'styled-components/macro';

import GraphQlForm from '../../../components/graphql-form';
import { useReadClientSmtpConfigQuery } from '../../../graphql-types';
import readClientSmtpConfig from '../../../queries/readClientSmtpConfig';
import { SettingsTitle } from '../style';
import { transformNullToUndefined } from '../../../helpers/utils';

import { EmailSettingsForm } from './EmailSettingsForm';

export function EmailSettings() {
  const { data, loading } = useReadClientSmtpConfigQuery();

  const initialValues = React.useMemo(() => {
    if (
      data?.readClientSmtpConfig &&
      Object.keys(data?.readClientSmtpConfig).length > 0 &&
      data?.readClientSmtpConfig?.active
    ) {
      return transformNullToUndefined(data.readClientSmtpConfig);
    }
  }, [data]);

  const hasValues = !!initialValues?.auth?.user;

  return (
    <DialogProvider>
      <div css="width: 640px;">
        <SettingsTitle>E-Mail Versand</SettingsTitle>
        <GraphQlForm
          submitAllValues
          startInEdit
          isLoading={loading}
          mutation="updateClientSmtpConfig"
          readDocumentFields={['success']}
          refetchQueries={[
            {
              query: readClientSmtpConfig,
            },
          ]}
          values={initialValues}
          validation={{
            active: yup.boolean().required(),
            host: yup.string().when('active', {
              is: true,
              then: yup.string().required(),
            }),
            port: yup.number().when('active', {
              is: true,
              then: yup.number().required(),
            }),
            mailFrom: yup.string().email().when('active', {
              is: true,
              then: yup.string().email().required(),
            }),
            auth: {
              user: yup.string().when('active', {
                is: true,
                then: yup.string().required(),
              }),
              pass: yup.string().when('active', {
                is: true && !hasValues,
                then: yup.string().required(),
              }),
            },
          }}
        >
          <EmailSettingsForm />
        </GraphQlForm>
      </div>
    </DialogProvider>
  );
}
