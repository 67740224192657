import { IbanExistsError, OdooBankAccountPayer } from '../../graphql-types';

export class IbanExistsErrorClass extends Error implements IbanExistsError {
  constructor(
    public message: string,
    public accountHolderId: string,
    public accountNumber: string,
    public accountHolder: string,
    public payers: [OdooBankAccountPayer],
    public hasNewMeterBeenAdded?: boolean,
  ) {
    super(message);
    this.name = 'IbanExistsErrorClass';
  }
}
