import {
  ButtonIcon,
  defaultTheme,
  Icons,
  TooltipDark,
} from '@ampeersenergy/ampeers-ui-components';
import { Result } from 'neverthrow';
import React from 'react';
import 'styled-components/macro';

import { InvoiceDraft } from '../../../../graphql-types';

import { InvoiceDraftPreviewButton } from './InvoiceDraftPreview';

interface InvoicePreviewProps {
  invoiceDraft: InvoiceDraft;
  previewInvoice?: (contractLabel: string) => Promise<Result<string, string>>;
  handleViewInvoiceDocument: (fileUrl: string) => void;
  hasOperationRole?: boolean | null;
}

export function InvoicePreview({
  invoiceDraft,
  previewInvoice,
  handleViewInvoiceDocument,
  hasOperationRole,
}: InvoicePreviewProps) {
  const {
    customerName,
    contractId,
    invoiceDocumentUrl,
    confirmed: confirmedInvoice,
  } = invoiceDraft;

  if (!previewInvoice) {
    return null;
  }

  if (!confirmedInvoice) {
    return (
      <InvoiceDraftPreviewButton
        previewInvoice={previewInvoice}
        contractLabel={contractId}
        customerName={customerName}
      />
    );
  }

  if (invoiceDocumentUrl) {
    return (
      <ButtonIcon
        secondary
        icon={Icons.File}
        onClick={() => handleViewInvoiceDocument(invoiceDocumentUrl)}
      />
    );
  }

  const tooltipText = hasOperationRole
    ? 'Bei der Erstellung des Rechnungsdokuments ein Fehler aufgetreten.'
    : `Leider ist bei der Erstellung des Rechnungsdokuments ein Fehler aufgetreten. Bitte wende Dich an unseren Helpdesk.`;

  return (
    <div css="display: flex; align-items: center;">
      <TooltipDark text={tooltipText} id={`${contractId}-invoice-error`}>
        <Icons.Warning
          size={22}
          color={defaultTheme.palette.warning.color}
          data-tip
          data-for={`${contractId}-invoice-error`}
        />
      </TooltipDark>
      {hasOperationRole && (
        <InvoiceDraftPreviewButton
          previewInvoice={previewInvoice}
          contractLabel={contractId}
          customerName={customerName}
        />
      )}
    </div>
  );
}
