import React from 'react';
import styled from 'styled-components';
import Dropzone from 'react-dropzone';
import { Button } from '@ampeersenergy/ampeers-ui-components';

const Container = styled.section`
  border-radius: 6px;
  border: 2px dashed #e1e1e1;

  min-height: 400px;
  min-width: 600px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .inputWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    align-self: stretch;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  p {
    color: #1d1d1d;
    margin-top: 0;
    max-width: 400px;
    text-align: center;
  }
`;

function FileUpload({
  message,
  fileParser = () => {},
}: {
  message: string;
  fileParser?: (files: File[]) => any;
}) {
  return (
    <Dropzone onDrop={(acceptedFiles) => fileParser(acceptedFiles)}>
      {({ getRootProps, getInputProps }) => (
        <Container>
          <div className="inputWrapper" {...(getRootProps() as any)}>
            <input {...(getInputProps() as any)} />
            <p>{message}</p>
            <Button>Datei auswählen</Button>
          </div>
        </Container>
      )}
    </Dropzone>
  );
}

export default FileUpload;
