import React from 'react';
import styled from 'styled-components';

export interface InputFieldProp {
  message: React.ReactNode;
  value: React.ReactNode;
  className?: string;
  onAccept: () => void;
}

const Wrapper = styled.span`
  font-size: 14px;
`;

const Value = styled.span`
  font-weight: bold;
`;

export const ClickableText = styled.button`
  color: ${(props) => props.theme.primaryColor};
  border: none;
  background: none;
  cursor: pointer;
  font-weight: 600;
  margin-left: 5px;
`;

function InputSuggestion({
  onAccept,
  message,
  value,
  className,
}: InputFieldProp) {
  return (
    <Wrapper className={className}>
      {message} <Value>{value}</Value>
      <ClickableText
        data-testid="input-suggestion-apply-action"
        onClick={onAccept}
        type="button"
      >
        Übernehmen
      </ClickableText>
    </Wrapper>
  );
}

export default InputSuggestion;
