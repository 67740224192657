/* eslint-disable import/no-named-as-default */
import React, { useState } from 'react';
import { Modal } from '@ampeersenergy/ampeers-ui-components';

import { CreateFlow } from '../../../../components';
import { PlantDetailExtendedQuery } from '../../../../graphql-types';
import { EditContainerProps } from '../../../../components/createFlow';

import NewAddress from './create/newAddress';
import EditMasterData from './edit';

export interface MasterDataProps extends EditContainerProps {
  data: { readPlant?: PlantDetailExtendedQuery['readPlant'] } | undefined;
  isLoading: boolean;
}

function MasterData({ data, isLoading }: MasterDataProps) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const plantId = data?.readPlant?.id;

  return (
    <>
      <EditMasterData
        setModalIsOpen={setModalIsOpen}
        plant={data?.readPlant}
        isLoading={isLoading}
        variables={{ plantId }}
      />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Create-Address-Modal"
        title="Zusätzliche Adresse hinzufügen"
      >
        <CreateFlow
          editContainer={NewAddress}
          kind="Addresse"
          onDone={() => setModalIsOpen(false)}
          variables={{ plantId }}
        />
      </Modal>
    </>
  );
}

export default MasterData;
