import React, { useMemo } from 'react';
import { ColumnDefinition, Table } from '@ampeersenergy/ampeers-ui-components';
import { DateTime } from 'luxon';

import {
  formatDate,
  formatMeterReadingValue,
} from '../../../../../helpers/formatStrings';
import { ReadMeterReadingsQuery } from '../../../../../graphql-types';

const columns: ColumnDefinition<any>[] = [
  {
    Header: 'Zeitraum',
    accessor: 'dateRange',
    width: '20%',
    Cell: ({ value }) =>
      `${formatDate(DateTime.fromISO(value.from).toUTC().toISO())} -
        ${formatDate(DateTime.fromISO(value.to).toUTC().toISO())}`,
    sortType: (rowA, rowB, columnId, desc) => {
      if (desc) {
        return (
          Date.parse(rowA.values[columnId].to) -
          Date.parse(rowB.values[columnId].to)
        );
      }
      return (
        Date.parse(rowA.values[columnId].from) -
        Date.parse(rowB.values[columnId].from)
      );
    },
  },
  {
    Header: 'Wert',
    accessor: 'value',
    Cell: ({ value }) => formatMeterReadingValue(value),
  },
];

export function RlmReadingsTable({
  readings,
  loading,
}: {
  readings: ReadMeterReadingsQuery['readMeterReadings'];
  loading: boolean;
}) {
  const formattedReadings = useMemo(() => {
    return readings.map(({ from, to, ...reading }) => {
      return {
        ...reading,
        dateRange: { from, to },
      };
    });
  }, [readings]);

  return (
    <Table
      data={formattedReadings ?? []}
      isLoading={loading}
      columns={columns}
      filterKind="Verbrauchswerte"
      compact
      withAlternatingRows
      withPagination
    />
  );
}
