import React from 'react';
import styled from 'styled-components';
import {
  FlexRow,
  defaultTheme,
  Icons,
  PaddedShadowBox,
  SpinnerDark,
} from '@ampeersenergy/ampeers-ui-components';

import { StepStatus } from '../graphql-types';

const CardHeader = styled(FlexRow)`
  align-items: center;
  margin-bottom: 8px;
`;

const TimeIcon = styled(Icons.Time)`
  display: inline-block;
  margin-right: 3px;
  margin-left: 4px;
`;

const SpinnerIcon = styled(SpinnerDark)`
  margin-right: 5px;
  margin-left: 5px;
`;

export const Card = styled(PaddedShadowBox)`
  width: 500px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 17px;
`;

const IconPlaceholder = styled.div`
  width: 16px;
  height: 16px;
  color: ${(props) => props.theme.palette.background};
  border: 2px solid ${(props) => props.theme.palette.borderEmphasis};
  text-align: center;
  font-size: 13px;
  border-radius: 50px;
  margin: 6px;
`;

const CardContent = styled.div`
  flex-grow: 1;
`;

const StepName = styled.h5`
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: -2px;
`;

const CardIcon = styled.div`
  position: relative;
  left: -5px;
  top: 1px;
`;

export interface WorkflowCardProps {
  state: StepStatus;
  title: string;
}

export const WorkflowCard: React.FC<WorkflowCardProps> = ({
  children,
  state,
  title,
}) => {
  return (
    <Card>
      <CardHeader>
        <CardIcon>
          <WorkflowIcon state={state} />
        </CardIcon>
        <StepName>{title}</StepName>
      </CardHeader>
      <CardContent>{children}</CardContent>
    </Card>
  );
};

const WorkflowIcon = ({ state }: { state: StepStatus }) => {
  switch (state) {
    case StepStatus.Active:
      return <SpinnerIcon size={15} />;
    case StepStatus.Succeeded:
      return (
        <Icons.Checkmark size={25} color={defaultTheme.palette.success.color} />
      );
    case StepStatus.Timeout:
      return (
        <Icons.Warning size={25} color={defaultTheme.palette.warning.color} />
      );
    case StepStatus.WaitingForUser:
      return <IconPlaceholder />;
    case StepStatus.Failed:
      return <Icons.Wrong size={25} color={defaultTheme.palette.error.color} />;
    case StepStatus.WaitingForExternal:
      return <TimeIcon size={18} color={defaultTheme.palette.textMuted} />;
    default:
      return null;
  }
};
