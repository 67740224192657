import React, { useCallback, useMemo, useState } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { err, ok, Result } from 'neverthrow';
import { DateTime } from 'luxon';
import styled from 'styled-components';
import {
  Overlay,
  Table,
  Main,
  Header,
  Button,
  ColumnDefinition,
} from '@ampeersenergy/ampeers-ui-components';
import { DeepExtractType } from 'ts-deep-extract-types';
import { NumberParam, useQueryParam } from 'use-query-params';

import { Icons, CreateFlow } from '../../components';
import {
  Row,
  ImportFlow,
  ImportError,
  ImportHandler,
  ImportResult,
  FailedRow,
} from '../../components/importer/import-flow';
import {
  CreateTariffsBatchedError,
  CreateTariffsBatchedSuccess,
  Tariff,
  useReadTariffsQuery,
  ReadTariffQuery,
} from '../../graphql-types';
import { formatDecimal, formatEmgEndDates } from '../../helpers/formatStrings';
import CSVService, { tariffsCSVConfig } from '../../services/csv';
import { EnergyLabel } from '../../components/energyLabel';
import { DocTitle } from '../../components/docTitle';

import CreateTariffContainer from './create/tariff/createTariffContainer';

const AddTariffButton = styled(Button)`
  margin-left: 6px;
`;
const csvService: CSVService = new CSVService(tariffsCSVConfig());

type ContractType = DeepExtractType<ReadTariffQuery, ['readTariff']>;
type PickedContract = Pick<
  ContractType,
  'nameInternal' | 'validityStartDate' | 'validityEndDate'
> & {
  basicPrice: number;
  energyPrice: {
    energyPrice?: number;
    energyPriceLocal?: number;
    energyPriceResidual?: number;
  };
};

const columns: ColumnDefinition<PickedContract>[] = [
  {
    Header: 'Tarif-ID',
    accessor: 'nameInternal',
  },
  {
    Header: 'Grundpreis',
    accessor: 'basicPrice',
    Cell: ({ value }: { value?: number }) =>
      `${formatDecimal(value)} ${value ? '€/Monat' : ''}`,
  },
  {
    Header: 'Arbeitspreis',
    accessor: 'energyPrice',
    Cell: ({
      value: { energyPrice, energyPriceLocal, energyPriceResidual },
    }: {
      value: {
        energyPrice: number | undefined;
        energyPriceLocal: number | undefined;
        energyPriceResidual: number | undefined;
      };
    }) => {
      if (energyPrice === undefined) {
        return (
          <>
            <EnergyLabel type="local">{`${formatDecimal(
              energyPriceLocal,
            )} Cent/kWh`}</EnergyLabel>
            <EnergyLabel type="residual">{`${formatDecimal(
              energyPriceResidual,
            )} Cent/kWh`}</EnergyLabel>
          </>
        );
      }
      return (
        <EnergyLabel type="mixed">{`${formatDecimal(
          energyPrice,
        )} Cent/kWh`}</EnergyLabel>
      );
    },
    sortType: (rowA, rowB, columnId) => {
      if (
        rowA.values[columnId].energyPrice === undefined &&
        rowB.values[columnId].energyPrice !== undefined
      ) {
        return (
          rowA.values[columnId].energyPriceLocal -
          rowB.values[columnId].energyPrice
        );
      }
      if (
        rowA.values[columnId].energyPrice !== undefined &&
        rowB.values[columnId].energyPrice === undefined
      ) {
        return (
          rowA.values[columnId].energyPrice -
          rowB.values[columnId].energyPriceLocal
        );
      }
      if (
        rowA.values[columnId].energyPrice === undefined &&
        rowB.values[columnId].energyPrice === undefined
      ) {
        return (
          rowA.values[columnId].energyPriceLocal -
          rowB.values[columnId].energyPriceLocal
        );
      }

      return (
        rowA.values[columnId].energyPrice - rowB.values[columnId].energyPrice
      );
    },
  },
  {
    Header: 'Gültigkeit von',
    accessor: 'validityStartDate',
    type: 'date',
  },
  {
    Header: 'Gültigkeit bis',
    accessor: 'validityEndDate',
    type: 'date',
    Cell: ({ value }) => formatEmgEndDates(value),
  },
];

const tariffListTitle = 'Tarife';

function TariffListPage() {
  const match = useRouteMatch();
  const location = useLocation();
  const [pageQueryParam] = useQueryParam('page', NumberParam);
  const rowLink = useCallback(
    ({ row }: { row: any }) => {
      const entry = row.original as Tariff;

      return `${match.url}/${entry.id}${location.search}`;
    },
    [match.url, location.search],
  );

  const { data, loading, refetch } = useReadTariffsQuery();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [importModalOpen, setImportModalOpen] = useState(false);

  const tableData = useMemo(() => {
    if (data?.readTariffs) {
      return data.readTariffs
        .slice(0)
        .map(
          ({
            id,
            nameInternal,
            priceSheets,
            validityStartDate,
            validityEndDate,
          }) => ({
            id,
            nameInternal,
            basicPrice: priceSheets[0].basicPrice,
            energyPrice: {
              energyPrice: priceSheets[0].energyPrice ?? undefined,
              energyPriceLocal: priceSheets[0].energyPriceLocal ?? undefined,
              energyPriceResidual:
                priceSheets[0].energyPriceResidual ?? undefined,
            },
            validityStartDate,
            validityEndDate,
          }),
        );
    }

    return [];
  }, [data]);

  const onImport: ImportHandler = async ({ type, file, error }) => {
    if (!file || error) {
      const errorMsg: string = error instanceof Error ? error.message : error!;
      return err({
        title: 'CSV konnte nicht importiert werden',
        message: errorMsg,
      });
    }

    if (type.id === 'tariffs') {
      try {
        const uploadResult = await csvService.uploadFiles([file!]);

        const failed = uploadResult.failed.map(
          (failedResult: CreateTariffsBatchedError) => {
            return {
              error: failedResult.error,
              values: [failedResult.nameInternal, failedResult.message],
            };
          },
        );

        const succeeded = uploadResult.succeeded.map(
          (succeededResult: CreateTariffsBatchedSuccess) => {
            return {
              values: [succeededResult.nameInternal, succeededResult.info],
            };
          },
        );

        refetch();

        return ok({
          failed,
          succeeded,
          headers: ['Tarif ID', 'Status'],
        });
      } catch (e) {
        return err({
          message: Array.isArray(e)
            ? e.map((_error) => _error.message).join(',')
            : e instanceof Error
            ? e.message.toString()
            : String(e),
          title: 'CSV konnte nicht importiert werden',
        });
      }
    }

    return ok({ failed: [], succeeded: [], headers: [] });
  };

  const onImportOverviewDownload = useCallback(
    (result: Result<ImportResult, ImportError>) => {
      if (result.isOk()) {
        const fields = ['tariffName', 'status', 'date'];
        const headers = ['Tarif Name', 'Status', 'Datum'];
        const date = DateTime.utc().setLocale('de').toLocal();
        const fileNameDate = date.toFormat('ddMMyyyyHHmm');
        const fileName = `${fileNameDate}_Importlog_Tarife.csv`;
        const config = { fields, headers, fileName };

        const csvDate = date.toFormat('dd.MM.yyyy');
        const succeeded = generateCsvObject(
          result.value.succeeded ?? [],
          csvDate,
        );
        const failed = generateCsvObject(result.value.failed ?? [], csvDate);

        return CSVService.downloadCSV([...succeeded, ...failed], config);
      }
      console.error(
        'Something went wrong while trying to generate import overview',
        { error: result.error },
      );
    },
    [],
  );

  const arrayOfIds = useMemo(() => {
    if (data?.readTariffs) {
      return data.readTariffs.map((tariff: any) => tariff.nameInternal);
    }
    return [];
  }, [data]);

  return (
    <>
      <DocTitle titleParts={[tariffListTitle]} />
      <Main>
        <Header
          Icon={Icons.TariffIcon}
          actions={() => (
            <>
              <Button
                id="upload-tariffs-btn"
                onClick={() => setImportModalOpen(true)}
                secondary
              >
                Tarif Import
              </Button>
              <AddTariffButton
                id="create-tariff-btn"
                onClick={() => setModalIsOpen(true)}
              >
                Tarif anlegen
              </AddTariffButton>
            </>
          )}
        >
          {tariffListTitle}
        </Header>

        <Table
          data={tableData}
          columns={columns}
          rowLink={rowLink}
          filterKind={tariffListTitle}
          isLoading={loading}
          compact
          withFilter
          withRouting
          withAlternatingRows
          withPagination
          initialState={{
            sortBy: [
              {
                id: 'validityEndDate',
                desc: true,
              },
            ],
            pageIndex: pageQueryParam || 0,
          }}
        />
        <Overlay
          isOpen={modalIsOpen}
          onRequestClose={() => setModalIsOpen(false)}
          title="Tarif hinzufügen"
        >
          <CreateFlow
            editContainer={CreateTariffContainer}
            kind="Tarif"
            onDone={() => setModalIsOpen(false)}
            variables={{
              arrayOfIds,
            }}
          />
        </Overlay>

        {importModalOpen && (
          <>
            <DocTitle titleParts={['Tarif Import']} />
            <ImportFlow
              types={[
                {
                  label: 'Tarif',
                  id: 'tariffs',
                  templateURL: '/tariffs_upload_template.csv',
                  mimeTypes: ['text/csv', 'application/vnd.ms-excel'],
                  acceptedExtensions: ['csv'],
                  indeterminateProgress: true,
                  importTypeInstructions: 'csv',
                },
              ]}
              onClose={() => setImportModalOpen(false)}
              onImport={onImport}
              onImportOverviewDownload={onImportOverviewDownload}
              defaultType="tariffs"
            />
          </>
        )}
      </Main>
    </>
  );
}

export default TariffListPage;

function generateCsvObject(rows: Array<Row | FailedRow>, date: string) {
  return rows.map((result) => ({
    tariffName: result.values[0],
    status: result.values[1],
    date,
  }));
}
