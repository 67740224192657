import React, { useImperativeHandle, forwardRef, useCallback } from 'react';
import * as yup from 'yup';
import { Form, useFormik, FormikContext } from 'formik';

import {
  SetPlantAutarky,
  PlantAutarkySuggestion,
  suggestionHasError,
} from '../types';
import setPlantAutarkyMutation from '../../../../queries/setPlantAutarkyMutation';
import { InfoWrapped } from '../style';

import { AutarkyList } from './list';

interface PlantAutarkyFormProps {
  suggestions: PlantAutarkySuggestion[];
  isEditable: boolean;
}

const parseValues = (values: any) =>
  Object.keys(values)
    .filter((key: string) => key.split('-')[1] !== 'selected')
    .reduce(
      (acc, key: string) => [
        ...acc,
        {
          plantId: parseInt(key, 10),
          autarky: values[key] ? parseFloat(`${values[key]}`) : 0,
        },
      ],
      [] as SetPlantAutarky[],
    )
    .filter(
      ({ plantId, autarky }) =>
        values[`${plantId}-selected`] && autarky !== null,
    );

export const PlantAutarky: any = forwardRef<any, PlantAutarkyFormProps>(
  ({ suggestions, isEditable }, ref) => {
    const validationSchema = React.useMemo(
      () =>
        yup.object().shape(
          suggestions.reduce(
            (values, { plantId, plantFullSupply }) => ({
              ...values,
              [`${plantId}`]: yup
                .number()
                .when(
                  `${plantId}-selected`,
                  (
                    selected: boolean,
                    schema: yup.NumberSchema<number | undefined>,
                  ) =>
                    selected && !plantFullSupply
                      ? schema.min(0).max(100).required()
                      : schema.nullable(),
                ),
              [`${plantId}-selected`]: yup.boolean(),
            }),
            {},
          ),
        ),
      [suggestions],
    );

    const initialFormValues = suggestions.reduce(
      (values, s) => ({
        ...values,
        [`${s.plantId}`]: suggestionHasError(s) ? '' : s.autarky,
        [`${s.plantId}-selected`]: isEditable || !suggestionHasError(s),
      }),
      {},
    );

    const ctx = useFormik({
      initialValues: initialFormValues,
      validationSchema,
      validateOnMount: true,
      onSubmit: () => {},
    });

    const selectAll = useCallback(() => {
      ctx.setValues(
        suggestions.reduce(
          (values, { plantId }) => ({
            ...values,
            [`${plantId}-selected`]: true,
          }),
          {},
        ),
      );
    }, [ctx, suggestions]);

    useImperativeHandle(ref, () => ({
      allowTransition(transitionName: string) {
        if (transitionName.startsWith('toSetPlantAutarky')) {
          ctx.setTouched(
            suggestions.reduce(
              (values, { plantId }) => ({
                ...values,
                [plantId]: true,
              }),
              {},
            ),
            true,
          );

          return ctx.isValid;
        }
        return true;
      },

      getTransitionConfig(transitionName: string) {
        if (transitionName.startsWith('toSetPlantAutarky')) {
          return {
            mutation: setPlantAutarkyMutation,
            variables: {
              transitionName,
              payload: {
                autarkies: parseValues(ctx.values),
              },
            },
          };
        }
      },
    }));

    const data = suggestions.map((suggestion) => ({
      ...suggestion,
      isSelected: (ctx.values as any)[`${suggestion.plantId}-selected`],
      value: (ctx.values as any)[String(suggestion.plantId)],
    }));

    return (
      <>
        <InfoWrapped>
          Verhältnis des Eigenverbrauches zum Gesamtverbrauch aller am
          Mieterstrom teilnehmenden Verträge einer Kundenanlage.
        </InfoWrapped>
        <FormikContext.Provider value={ctx}>
          <Form>
            <AutarkyList
              suggestions={data}
              isEditable={isEditable}
              selectAll={selectAll}
            />
          </Form>
        </FormikContext.Provider>
      </>
    );
  },
);
