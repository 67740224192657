import React, { useState } from 'react';
import {
  Button,
  CreateFlow,
  Modal,
} from '@ampeersenergy/ampeers-ui-components';

import VoucherBookingContainer from './VoucherBookingContainer';

type VoucherBookingModalProps = {
  contractId?: string;
  contractLabel?: string;
  contractStart?: string;
  startAt?: string;
  endAt?: string;
};

export default function VoucherBookingModal({
  contractLabel,
  contractStart,
  ...rest
}: VoucherBookingModalProps) {
  const [modalVisible, setModalVisible] = useState(false);

  if (!contractLabel || !contractStart) {
    return <span />;
  }

  return (
    <>
      <Modal
        isOpen={modalVisible}
        onRequestClose={() => setModalVisible(false)}
        contentLabel="Contract-Voucher-Booking-Modal"
        title="Bonus buchen"
      >
        <CreateFlow
          editContainer={VoucherBookingContainer}
          kind="Bonus"
          onDone={() => setModalVisible(false)}
          variables={{
            contractLabel,
            contractStart,
            ...rest,
          }}
        />
      </Modal>
      <div>
        <Button onClick={() => setModalVisible(true)}>Bonus buchen</Button>
      </div>
    </>
  );
}
