import gql from 'graphql-tag';

export default gql`
  mutation removeDocuments($hash: String!) {
    removeDocuments(hash: $hash) {
      __typename
      ... on RemoveResponse {
        success
      }
    }
  }
`;
