import React, { useState, useMemo } from 'react';
import { Button } from '@ampeersenergy/ampeers-ui-components';
import styled from 'styled-components';
import * as yup from 'yup';
import { DateTime } from 'luxon';

import { Flex, FlexRow, Entry, PaddedShadowBox } from '../../../../components';
import { formatCurrency } from '../../../../helpers/formatStrings';
import GraphqlForm from '../../../../components/graphql-form';
import {
  AccountingType,
  InvoiceCycle,
  ReadContractDocument,
  ReadContractQuery,
  useReadContractAccountingHistoryQuery,
  WorkflowType,
} from '../../../../graphql-types';
import { EditContainerProps } from '../../../../components/createFlow';
import { yupUTCDate } from '../../../../helpers/yupUTCDate';
import { useHasRole } from '../../../../components/useHasRole';
import { CreateAccountingWorkflowModal } from '../../../accounting/create/createAccountingWorkflow.modal';

import { DownPayment } from './downpayment';
import { AccountingMoves } from './accountingMoves';

const PaddedShadowBoxStyled = styled(PaddedShadowBox)`
  margin-bottom: 1px;
`;

const SecondBox = styled(PaddedShadowBox)`
  margin-top: 10px;
  padding-top: 1px;
`;

interface AccountingPageProps extends EditContainerProps {
  contract?: ReadContractQuery['readContract'];
  isLoading?: boolean;
}

function AccountingPage({
  contract,
  isLoading,
  onSuccess,
  onAbort,
}: AccountingPageProps) {
  const { hasRole: accountingEnabled } = useHasRole('feature_accounting');
  const { hasRole: accountMovesEnabled } = useHasRole('feature_account_moves');

  const [modalVisible, setModalVisible] = useState(false);
  const saldo = 0;

  // TODO: Needs to be done when handling accounting history
  const { data } = useReadContractAccountingHistoryQuery({
    variables: {
      contractId: contract?.id ?? '',
    },
    skip: !contract?.id,
  });

  const paymentPeriodStartAt =
    data?.readContractAccountingHistory?.nextAccountingStart;
  const lastAccountingPeriodEnd =
    data?.readContractAccountingHistory?.lastAccountingEnd;

  const contractHasEnded = ['FS_ENDED', 'LS_ENDED'].includes(
    contract?.status?.toUpperCase() ?? '',
  );
  const accountingTypes = contractHasEnded
    ? [AccountingType.ClosingAccountingMove, AccountingType.StandardAccounting]
    : [AccountingType.StandardAccounting];

  const invoiceCycle = useMemo(() => {
    switch (contract?.invoiceCycle) {
      case InvoiceCycle.Monthly:
        return 'monatlich';
      case InvoiceCycle.Quarterly:
        return 'vierteljährlich';
      case InvoiceCycle.HalfYearly:
        return 'halbjährlich';
      case InvoiceCycle.Yearly:
      default:
        return 'jährlich';
    }
  }, [contract?.invoiceCycle]);

  return (
    <>
      {modalVisible && (
        <CreateAccountingWorkflowModal
          onClose={() => setModalVisible(false)}
          formVariables={{
            workflowType: WorkflowType.Single,
            accountingTypes,
            name: `Abrechnung ${contract?.customer.person.name}`,
            contractToAccount: Number(contract?.id),
            ...(paymentPeriodStartAt && {
              paymentPeriodStartAt: new Date(paymentPeriodStartAt),
            }),
            ...(contract?.endDate &&
              contractHasEnded && {
                paymentPeriodEndAt: new Date(contract?.endDate),
              }),
            settlementDay: Number(contract?.settlementDay),
            settlementMonth: Number(contract?.settlementMonth),
            ...(lastAccountingPeriodEnd && {
              lastAccountingPeriodEnd: new Date(lastAccountingPeriodEnd),
            }),
            invoiceCycle: contract?.invoiceCycle,
            contractStartDate: new Date(contract?.startDate),
          }}
        />
      )}
      <GraphqlForm
        mutation="updateAccounting"
        startInEdit={false}
        readDocument={ReadContractDocument}
        variables={{
          contractId: contract?.id,
          contractLabel: contract?.label,
          customerId: contract?.customer?.id,
          customerLabel: contract?.customer?.label,
        }}
        isLoading={isLoading}
        values={{
          downPayment: contract?.downPayment,
          downPaymentStartDate: contract?.downPaymentStartDate,
        }}
        onSuccess={onSuccess}
        onAbort={onAbort}
        refetchQueries={[
          {
            query: ReadContractDocument,
            variables: {
              contractId: contract?.id,
              contractLabel: contract?.label,
              customerId: contract?.customer?.id,
              customerLabel: contract?.customer?.label,
            },
          },
        ]}
        validation={{
          downPayment: yup.number().required(),
          downPaymentStartDate: yupUTCDate
            .min(
              DateTime.fromJSDate(new Date())
                .plus({ months: 1 })
                .startOf('month')
                .toISODate(),
              `Die Gültigkeit des neuen Abschlags kann frühestens zum Ersten des nächsten Monats beginnen`,
            )
            .test(
              'reduction',
              'Die Gültigkeit des neuen Abschlags kann nur zum Ersten des Monats beginnen',
              (value: any) => {
                return (
                  DateTime.fromJSDate(value).startOf('month').toISODate() ===
                  DateTime.fromJSDate(value).toISODate()
                );
              },
            )
            .required(),
        }}
      >
        <>
          <PaddedShadowBoxStyled>
            <FlexRow>
              <Flex>
                <FlexRow>
                  <Flex>
                    <Entry title="Status">Ok</Entry>
                  </Flex>
                  <Flex>
                    <Entry title="Offener Saldo">{formatCurrency(saldo)}</Entry>
                  </Flex>
                </FlexRow>
                <FlexRow>
                  <Flex>
                    <Entry title="Abrechnungsturnus" isLoading={isLoading}>
                      {invoiceCycle}
                    </Entry>
                  </Flex>
                  {contract?.invoiceCycle === InvoiceCycle.Yearly ? (
                    <Flex>
                      <Entry title="Abrechnungsstichtag">
                        {contract?.settlementDate}
                      </Entry>
                    </Flex>
                  ) : null}
                </FlexRow>
              </Flex>
              <div>
                <Entry title="Nächste Schritte">
                  <Button
                    onClick={() => setModalVisible(true)}
                    disabled={!accountingEnabled}
                  >
                    Abrechnung
                  </Button>
                </Entry>
              </div>
            </FlexRow>
          </PaddedShadowBoxStyled>
          {contract?.invoiceCycle !== InvoiceCycle.Monthly ? (
            <PaddedShadowBoxStyled>
              <FlexRow>
                <Flex>
                  <DownPayment contract={contract} />
                </Flex>
                <Flex />
                <Flex />
              </FlexRow>
            </PaddedShadowBoxStyled>
          ) : null}
        </>
      </GraphqlForm>
      {accountingEnabled && accountMovesEnabled && (
        <SecondBox>
          <AccountingMoves
            isLoading={!!isLoading}
            contractId={contract?.id}
            contractLabel={contract?.label}
            contractStartDate={contract?.startDate}
          />
        </SecondBox>
      )}
    </>
  );
}

export default AccountingPage;
