import React from 'react';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import {
  Icons as UiIcons,
  Relation,
} from '@ampeersenergy/ampeers-ui-components';
import { Link } from 'react-router-dom';

import { Bold, Icons, FlexRow, Hint } from '../../../../../components';
import {
  ReadContractQuery,
  TerminateContractMutation,
} from '../../../../../graphql-types';
import { EditContainerProps } from '../../../../../components/createFlow';

const Headline = styled(Bold)`
  padding-top: 20px;
  font-size: 15px;
`;
const Wrap = styled.div`
  width: 400px;
  font-size: 14px;
  p {
    padding-left: 12px;
    margin-top: 0px;
  }
`;

const WorkflowLink = styled(Link)`
  text-decoration: none;
  margin-left: 6px;
  margin-top: 10px;
`;

interface EndMessageContainerProps extends EditContainerProps {
  contract?: ReadContractQuery['readContract'];
  result: TerminateContractMutation;
}

const EndMessageContainer = ({
  variables,
  result,
}: EndMessageContainerProps) => {
  const today = DateTime.fromJSDate(new Date())
    .setZone('utc', { keepLocalTime: true })
    .endOf('day');
  const contractEndDate = DateTime.fromISO(variables?.contract?.endDate);
  const isContractEndDateInFuture = contractEndDate > today;

  const { workflowId } = result.terminateContract;

  return (
    <Wrap>
      {workflowId ? (
        <CancellationSupplierChangeMessage workflowId={workflowId} />
      ) : (
        <CancellationDefaultMessage
          isContractEndDateInFuture={isContractEndDateInFuture}
        />
      )}
    </Wrap>
  );
};

function CancellationSupplierChangeMessage({
  workflowId,
}: {
  workflowId: string;
}) {
  return (
    <>
      <FlexRow>
        <Icons.Checkmark size={60} color="#A8E015" />
        <Headline>Kündigungsprozess gestartet</Headline>
      </FlexRow>
      <FlexRow>
        <Hint>
          Du hast den Kündigungsprozess erfolgreich gestartet. Bis der
          Verteilnetzbetreiber eine Malo zugewiesen und das gewünschte
          Kündigungsdatum bestätigt hat, musst Du noch mit der Schlussrechnung
          warten.
        </Hint>
      </FlexRow>
      <FlexRow>
        <WorkflowLink to={`/supplier/workflow/${workflowId}`}>
          <Relation icon={UiIcons.SupplierChange} title="Zum Wechselprozess" />
        </WorkflowLink>
      </FlexRow>
    </>
  );
}

function CancellationDefaultMessage({
  isContractEndDateInFuture,
}: {
  isContractEndDateInFuture: boolean;
}) {
  return (
    <>
      <FlexRow>
        <Icons.Checkmark size={60} color="#A8E015" />
        <Headline>Erfolgreiche Kündigung</Headline>
      </FlexRow>
      {isContractEndDateInFuture ? (
        <FlexRow>
          <Hint>
            Du hast den Vertrag erfolgreich gekündigt. Da das Lieferende in der
            Zukunft liegt, musst Du noch mit der Schlussrechnung warten.
          </Hint>
        </FlexRow>
      ) : (
        <>
          <FlexRow>
            <Hint>
              Du hast den Vertrag erfolgreich gekündigt. Anschließend kannst Du
              die Schlussrechnung unter Abrechnung erstellen
            </Hint>
          </FlexRow>
        </>
      )}
    </>
  );
}

export default EndMessageContainer;
