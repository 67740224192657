import { EditContainerProps } from '@ampeersenergy/ampeers-ui-components/dist/create-flow/create-flow';
import { useFormikContext } from 'formik';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';

import GraphQlForm from '../../../../../components/graphql-form';
import { GraphqlFormField } from '../../../../../components/graphql-form/render';
import { yupUTCDate } from '../../../../../helpers/yupUTCDate';
import readContractAccounting from '../../../../../queries/readContractAccounting';

const FormStyled = styled.div`
  max-width: 480px;
`;

const StyledRadioField = styled(GraphqlFormField)`
  margin-top: 10px;
  label {
    margin-left: 0;
  }
  div > label {
    margin-left: 0;
  }
  div > div > div {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

const outOfPeriodLabelNo =
  'Bonus der aktuellen Periode (Kalenderjahr) zuordnen';
const outOfPeriodLabelYes =
  'Bonus einer bereits abgeschlossenen Periode (Kalenderjahr) zuordnen';

function VoucherBookingForm() {
  const { setFieldValue, getFieldProps } = useFormikContext<{
    bonus: string;
    value: number;
  }>();
  const [bonusValue, setBonusValue] = useState<'VW' | 'VK' | undefined>();

  useEffect(() => {
    if (getFieldProps('bonus').value === bonusValue) return;

    if (getFieldProps('bonus').value === 'VW') {
      setFieldValue('value', 50);
    } else if (getFieldProps('bonus').value === 'VK') {
      setFieldValue('value', 25);
    }
    setBonusValue(getFieldProps('bonus').value);
  }, [bonusValue, getFieldProps, setFieldValue]);

  return (
    <FormStyled>
      <GraphqlFormField name="bonus" />
      <GraphqlFormField name="value" />
      <GraphqlFormField name="date" disabled />
      <StyledRadioField
        labels={[outOfPeriodLabelNo, outOfPeriodLabelYes]}
        name="outOfPeriod"
        asType="Radio"
      />
    </FormStyled>
  );
}

export default function VoucherBookingContainer({
  onSuccess,
  onAbort,
  variables: { contractId, contractLabel, contractStart, startAt, endAt },
}: EditContainerProps) {
  return (
    <GraphQlForm
      mutation="createVoucherBooking"
      startInEdit
      onSuccess={onSuccess}
      onAbort={onAbort}
      readDocumentFields={['contractId', 'date', 'value', 'productId']}
      variables={{
        contractLabel,
        contractStart,
      }}
      refetchQueries={[
        {
          query: readContractAccounting,
          variables: {
            contractId: contractId!,
            contractLabel: contractLabel!,
            startAt,
            endAt,
          },
        },
      ]}
      defaultValues={{
        outOfPeriod: 'false',
        date: DateTime.now().toFormat('yyyy-MM-dd'),
      }}
      validation={{
        bonus: yup.mixed().oneOf(['VW', 'VK']).required(),
        value: yup.number().moreThan(0).required(),
        date: yupUTCDate.required(),
        outOfPeriod: yup.mixed().oneOf(['true', 'false']).required(),
      }}
    >
      <VoucherBookingForm />
    </GraphQlForm>
  );
}
