import * as React from 'react';
import * as yup from 'yup';
import { DateTime } from 'luxon';
import { Form, useFormik, FormikContext } from 'formik';
import {
  FormikInput,
  SpinnerDark,
  WarningAlert,
} from '@ampeersenergy/ampeers-ui-components';

import { AccountingRunStepRef } from '../types';
import { yupUTCDate } from '../../../../helpers/yupUTCDate';
import {
  DownpaymentStartStepResult,
  OverviewStepResult,
  SetAccountingRunDownpaymentStartDocument,
  useReadContractsBatchQuery,
} from '../../../../graphql-types';

const validationSchema = (contractStartDate?: Date, singleContract = false) =>
  yup.object().shape({
    downpaymentStartDate: yupUTCDate
      .test(
        'downpaymentStartDate',
        singleContract
          ? 'Das Datum darf nicht vor dem Vertragsbeginn liegen.'
          : 'Das Datum darf nicht vor dem Vertragsbeginn eines der Verträge liegen.',
        (value) => {
          if (!value) {
            return true;
          }

          return !contractStartDate || new Date(value) >= contractStartDate;
        },
      )
      .required(),
  });

interface DownpaymentFormProps {
  isEditable?: boolean;
  result: DownpaymentStartStepResult;
  overviewStep?: OverviewStepResult | null;
}

export const DownpaymentStartStep = React.forwardRef<
  AccountingRunStepRef,
  DownpaymentFormProps
>(({ isEditable = true, result, overviewStep }, ref) => {
  const { downpaymentStart } = result ?? {};

  const [showHint, setShowHint] = React.useState(false);

  const contractLabels = React.useMemo(
    () =>
      overviewStep?.validContracts?.map((contract) => contract.contractLabel) ??
      [],
    [overviewStep],
  );

  const { data: contractsBatched, loading: contractLoading } =
    useReadContractsBatchQuery({
      variables: { ids: contractLabels, byLabel: true },
      skip: !isEditable || contractLabels.length === 0,
    });

  const closestContractStartDate = React.useMemo(
    () =>
      contractsBatched?.readContractsBatch
        .map((contract) => new Date(contract.startDate))
        .reduce((maxDate, startDate) =>
          maxDate >= startDate ? maxDate : startDate,
        ),
    [contractsBatched],
  );

  const initialFormValues = React.useMemo(() => {
    return {
      downpaymentStartDate:
        downpaymentStart ?? DateTime.now().toFormat('yyyy-MM-dd'),
    };
  }, [downpaymentStart]);

  const formikContext = useFormik({
    initialValues: initialFormValues,
    validationSchema: validationSchema(
      closestContractStartDate,
      contractLabels.length === 1,
    ),
    validateOnMount: true,
    validateOnBlur: true,
    onSubmit: () => {},
  });

  React.useEffect(() => {
    const contextStartDate = formikContext.values.downpaymentStartDate
      ? new Date(formikContext.values.downpaymentStartDate)
      : null;

    setShowHint(
      !downpaymentStart &&
        !!contextStartDate &&
        contextStartDate < DateTime.local().startOf('day').toJSDate(),
    );
  }, [formikContext.values.downpaymentStartDate, downpaymentStart]);

  React.useImperativeHandle(ref, () =>
    isEditable
      ? {
          async isValid() {
            await formikContext.validateForm();
            return formikContext.isValid;
          },

          getTransitionConfig() {
            return {
              mutation: SetAccountingRunDownpaymentStartDocument,
              variables: {
                downpaymentStartDate: formikContext.values.downpaymentStartDate,
              },
            };
          },
        }
      : undefined,
  );

  return (
    <>
      <p>Bitte gib hier den Gültigkeitsbeginn der neuen Abschläge an.</p>
      <FormikContext.Provider value={formikContext}>
        <Form>
          <FormikInput
            id="downpaymentStartDate"
            name="downpaymentStartDate"
            data-testid="downpaymentStartDate"
            label="Datum"
            type="date"
            appendix={contractLoading ? <SpinnerDark size={20} /> : null}
            disabled={!isEditable || contractLoading}
          />
        </Form>
        {showHint && (
          <WarningAlert>
            Dein gewähltes Datum für den Gültigkeitsbeginn der neuen Abschläge
            liegt in der Vergangenheit. Möchtest Du trotzdem fortfahren?
          </WarningAlert>
        )}
      </FormikContext.Provider>
    </>
  );
});
