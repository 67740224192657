/* eslint-disable no-nested-ternary */
import {
  LinkedTab,
  LinkedTabs,
  PageTitleLayout,
} from '@ampeersenergy/ampeers-ui-components';
import React from 'react';
import { Route, useParams, useRouteMatch } from 'react-router-dom';

import { Icons, SubTitle } from '../../components';
import { DocTitle } from '../../components/docTitle';
import ErrorMsg from '../../components/errorMsg';
import { NestedPageParent } from '../../components/nestedPage';
import { SuccessMsg } from '../../components/successMessage';
import {
  useListPlantsQuery,
  usePlantDetailExtendedQuery,
} from '../../graphql-types';
import ContractIndexPage from '../contract/pages/index';

import GenerationPlants from './generationPlants';
import { MeterDetailPage } from './meter';
import Meters from './meters';
import MasterData from './pages/masterData/masterData';
import Reports from './reports';
import Tenants from './tenants';

const projectDetailTitle = 'Kundenanlage';

function ProjectDetail() {
  const match = useRouteMatch();
  const { plantId, projectId } = useParams<{
    plantId: string;
    projectId: string;
  }>();

  const { data, error, loading } = usePlantDetailExtendedQuery({
    variables: {
      plantId,
    },
  });

  const { data: projectResult } = useListPlantsQuery({
    variables: {
      projectId,
    },
    skip: data === undefined,
  });

  const isSinglePlantInProject =
    projectResult?.readProject?.plants.length === 1 ?? false;

  let inner;

  if (plantId === null) {
    inner = <ErrorMsg message="Id der Kundenanlage fehlt." />;
  } else if (!plantId) {
    inner = <ErrorMsg message="Id der Kundenanlage ist invalide." />;
  } else if (error) {
    inner = <ErrorMsg error={error} />;
  } else {
    inner = (
      <LinkedTabs basePath={match.url}>
        <LinkedTab title="Mieter" path="/tenant">
          {data?.readPlant?.isInSetup && (
            <SuccessMsg>
              <SubTitle>
                <Icons.Checkmark size={25} color="#A8E015" />
                Erfolgreich angelegt
              </SubTitle>
              Um die Einrichtung abzuschließen, als nächstes Verträge für die
              Gebäudezähler anlegen.
            </SuccessMsg>
          )}
          <Tenants data={data} isLoading={loading} />
        </LinkedTab>
        <LinkedTab
          title="Gebäude"
          hasNotification={data?.readPlant?.isInSetup ?? false}
          path="/building"
        >
          <GenerationPlants data={data} isLoading={loading} />
        </LinkedTab>
        <LinkedTab title="Zähler" path="/meter">
          <Meters plantId={plantId} />
        </LinkedTab>
        <LinkedTab title="Stammdaten" path="/masterdata">
          <MasterData data={data} isLoading={loading} />
        </LinkedTab>
        <LinkedTab title="Berichte" path="/reports">
          <Reports data={data} isLoading={loading} />
        </LinkedTab>
      </LinkedTabs>
    );
  }

  const titleParts = loading
    ? [projectDetailTitle]
    : data?.readPlant?.name
    ? [data?.readPlant?.name, projectDetailTitle]
    : [projectDetailTitle];

  return (
    <>
      <DocTitle titleParts={titleParts} />
      <NestedPageParent>
        <PageTitleLayout
          levelsUpToParent={isSinglePlantInProject ? 4 : 3}
          isLoading={loading}
          title={data?.readPlant?.name || ''}
        >
          {inner}
          <Route
            path={[
              // Since labels or names can include slashes "/", we don't want to add them to the URL.
              // But we also don't want to break existing URLs (for the time being).
              // That's why we match the URLs with labels or names here, but mark them as deprecated.
              // Note: In react-router v6 these partial dynamic segments are disallowed.
              // See https://reactrouter.com/en/main/route/route#dynamic-segments
              `${match.path}/tenant/contract/:contractId-:contractLabelDeprecated`,
              `${match.path}/building/contract/:contractId-:contractLabelDeprecated`,
              `${match.path}/tenant/contract/:contractId`,
              `${match.path}/building/contract/:contractId`,
            ]}
          >
            <ContractIndexPage />
          </Route>
          <Route path={`${match.path}/meter/:meterId`}>
            <MeterDetailPage />
          </Route>
        </PageTitleLayout>
      </NestedPageParent>
    </>
  );
}

export default ProjectDetail;
