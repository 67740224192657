import * as React from 'react';
import { DateTime } from 'luxon';
import { useApolloClient } from '@apollo/client';
import 'styled-components/macro';
import {
  Icons,
  useDialog,
  useToasts,
} from '@ampeersenergy/ampeers-ui-components';

import CSVService from '../../../../services/csv';
import {
  useMergeInvoiceDraftsMutation,
  ReadAccountingDetailsReportDocument,
  ReadAccountingDetailsReportQuery,
} from '../../../../graphql-types';
import { ResultWrapper } from '../../../../components/export-flow/result-step';

export function useInvoiceDrafts(
  accountingName: string,
  workflowName?: string,
) {
  const client = useApolloClient();
  const toast = useToasts();
  const { openDialog } = useDialog();

  const [downloadIsLoading, setDownloadIsLoading] = React.useState(false);
  const [reportIsLoading, setReportIsLoading] = React.useState(false);

  const [mergeAndSendInvoices] = useMergeInvoiceDraftsMutation();

  const downloadInvoices = React.useCallback(
    async (ids: string[]) => {
      const error =
        'Leider ist beim Export ein Fehler aufgetreten. Der Helpdesk ist informiert und meldet sich in kürze bei dir!';
      setDownloadIsLoading(true);
      try {
        const { data } = await mergeAndSendInvoices({
          variables: {
            invoiceDraftIds: ids,
            opcName: accountingName,
          },
        });
        if (data?.mergeInvoiceDrafts) {
          openDialog({
            title: 'Download wird vorbereitet',
            content: (
              <ResultWrapper>
                <Icons.Checkmark size={80} color="#A8E015" />
                <p css="margin: 0; padding: 0; text-align: center;">
                  Die Abrechnungen werden erstellt, dies kann einige Minuten
                  dauern.
                  <br />
                  <br />
                  Die fertigen Abrechnungen werden dir per Downloadlink zur
                  Verfügung gestellt, den Du per E-Mail erhälst.
                </p>
              </ResultWrapper>
            ),
          });
        } else {
          toast.error(error);
        }
      } catch (err) {
        toast.error(error);
      }
      setDownloadIsLoading(false);
    },
    [accountingName, mergeAndSendInvoices, openDialog, toast],
  );

  const onCompleteAccountingDetailsReport = React.useCallback(
    (data: ReadAccountingDetailsReportQuery) => {
      const fields = [
        'contractLabel',
        'accountingReceivableAccount',
        'accountingNumber',
        'accountingPeriodStartDate',
        'accountingPeriodEndDate',
        'accountingGenerationDate',
        'accountingNetValue',
        'accountingTaxValue',
        'accountingGrossValue',
        'accountingDiff',
        'consumption',
        'reduction',
        'reductionStartDate',
      ];
      const headers = [
        'Vertragsnummer',
        'Forderungskonto',
        'Rechnungsnummer',
        'Rechnungsperiode_Beginn',
        'Rechnungsperiode_Ende',
        'Rechnungsdatum',
        'Rechnungsbetrag_Netto',
        'Rechnungsbetrag_Steuer',
        'Rechnungsbetrag_Brutto',
        'OPOS',
        'Verbrauchswert',
        'Abschlag_neu',
        'Abschlagsgueltigkeit',
      ];
      const fileName = `${DateTime.utc()
        .setLocale('de')
        .toLocal()
        .toFormat('yyyyMMdd')}_Rechnungsdetails_${
        workflowName ?? accountingName
      }.csv`;
      const csvData = data.readAccountingDetailsReport.map((row) => ({
        ...row,
        accountingNetValue: CSVService.formatNumber(row.accountingNetValue),
        accountingTaxValue: CSVService.formatNumber(row.accountingTaxValue),
        accountingGrossValue: CSVService.formatNumber(row.accountingGrossValue),
        accountingDiff: CSVService.formatNumber(row.accountingDiff),
        consumption: CSVService.formatNumber(row.consumption),
        reduction: CSVService.formatNumber(row.reduction),
        accountingPeriodStartDate: CSVService.formatDate(
          row.accountingPeriodStartDate,
        ),
        accountingPeriodEndDate: CSVService.formatDate(
          row.accountingPeriodEndDate,
        ),
        accountingGenerationDate: CSVService.formatDate(
          row.accountingGenerationDate,
        ),
        reductionStartDate: CSVService.formatDate(row.reductionStartDate),
      }));

      CSVService.downloadCSV(
        csvData,
        {
          fields,
          headers,
          fileName,
        },
        { delimiter: ';' },
      );
    },
    [accountingName, workflowName],
  );

  const downloadAccountingDetailReport = React.useCallback(async () => {
    setReportIsLoading(true);
    return client
      .query({
        query: ReadAccountingDetailsReportDocument,
        fetchPolicy: 'network-only',
        variables: { opcName: accountingName },
      })
      .then(({ data }) => {
        onCompleteAccountingDetailsReport(data);
      })
      .catch((error) => {
        console.error(error);
        toast.error(
          'Es gab einen Fehler beim Erstellen des Rechnungsdetails-Export',
        );
      })
      .finally(() => setReportIsLoading(false));
  }, [accountingName, client, onCompleteAccountingDetailsReport, toast]);

  return {
    downloadInvoices,
    downloadAccountingDetailReport,
    downloadIsLoading,
    reportIsLoading,
  };
}
