import * as yup from 'yup';

export const validateMaloCheckNumber = (calcDigits: number[]) => {
  const sumOdd = calcDigits
    .filter((_, index) => (index + 1) % 2 !== 0)
    .reduce((sum, digit) => sum + digit, 0);
  const sumEvenMultipliedBy2 = calcDigits
    .filter((_, index) => (index + 1) % 2 === 0)
    .reduce((sum, digit) => sum + digit * 2, 0);

  const totalSum = sumOdd + sumEvenMultipliedBy2;

  const checkNumber = (10 - (totalSum % 10)) % 10;
  return checkNumber;
};

const yupMalo = yup
  .string()
  .length(11, 'Die Malo-Id muss genau 11 Ziffern haben.')
  .matches(/^\d+$/, 'Die Malo-Id darf nur numerische Ziffern (0-9) enthalten.')
  .matches(
    /^[1-9]/,
    'Die erste Ziffer muss eine positive ganze Zahl von 1 bis 9 sein.',
  )
  .test(
    'isValidCheckNumber',
    'Ungültige Prüfziffer: Informationen zur Berechnung der Prüfziffer findest Du auf den Seiten 6 und 7 in diesem Dokument: https://www.bdew.de/media/documents/Awh_20170428_MaLo-ID_NyDa4vU.pdf .',
    (value?: string) => {
      if (value) {
        // Extract digits (includes check number)
        const digits = value.split('').map(Number);

        // Digits used for calculation of check number (Doesn't include check number)
        const calcDigits = digits.slice(0, 10);

        const checkNumber = validateMaloCheckNumber(calcDigits);

        // Compares calculated check number with the input check number
        return checkNumber === digits[10];
      }
      return false;
    },
  );

export default yupMalo;
