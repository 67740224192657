import { useFormikContext } from 'formik';
import React from 'react';
import styled from 'styled-components';

import { TooltipInfo } from '../../../components/TooltipInfo';

import { FormVariablesAccountingWorkflow } from './types';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.palette.backgroundEmphasis};
  border-radius: 5px;

  & input {
    display: none;
  }
`;

const InputLabel = styled.label`
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 4px 10px;
  font-weight: 700;
  border-radius: 5px;
  cursor: pointer;

  background: ${({ theme }) => theme.palette.backgroundEmphasis};
  color: ${({ theme }) => theme.palette.textMuted};
  border: 1px solid transparent;
  box-shadow: 0px 0px;
`;

const MonthlyInput = styled.input`
  &:checked ~ label[for='monthly'] {
    color: ${({ theme }) => theme.primaryColor};
    background: ${({ theme }) => theme.palette.background};
    border-color: ${({ theme }) => theme.palette.border};
    box-shadow: 0px 1px 2px ${({ theme }) => theme.palette.borderEmphasis};
  }
`;

const QuarterlyInput = styled.input`
  &:checked ~ label[for='quarterly'] {
    background: ${({ theme }) => theme.palette.background};
    color: ${({ theme }) => theme.primaryColor};
    border-color: ${({ theme }) => theme.palette.border};
    box-shadow: 0px 1px 2px ${({ theme }) => theme.palette.borderEmphasis};
  }
`;

const DepositLabel = styled.div`
  font-weight: 700;
`;

export default function QuarterlyDeduction() {
  const { setFieldValue, values } =
    useFormikContext<FormVariablesAccountingWorkflow>();
  const { quarterlyDeduction } = values;

  const handleCheckInput = () => {
    setFieldValue('quarterlyDeduction', !quarterlyDeduction);
  };

  return (
    <Container>
      <DepositLabel>
        Abschlagturnus
        <TooltipInfo
          id="depositTurnInfo"
          text="Neu berechnete Abschläge sind entweder monatlich oder quartalsweise fällig"
        />
      </DepositLabel>
      <InputContainer>
        <MonthlyInput
          type="checkbox"
          name="monthly"
          id="monthly"
          checked={!quarterlyDeduction}
          onChange={handleCheckInput}
        />

        <QuarterlyInput
          type="checkbox"
          name="quarterly"
          id="quarterly"
          checked={!!quarterlyDeduction}
          onChange={handleCheckInput}
        />
        <InputLabel htmlFor="monthly"> Monatlich </InputLabel>

        <InputLabel htmlFor="quarterly"> Vierteljährlich </InputLabel>
      </InputContainer>
    </Container>
  );
}
