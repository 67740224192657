import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import * as yup from 'yup';
import gql from 'graphql-tag';

import {
  CreatePlantFlowInput,
  ReadProjectsQueryDocument,
} from '../../../graphql-types';
import readPlantsSimple from '../../../queries/readPlantsSimple';
import GraphqlMultiStepForm from '../../../components/graphql-form/multistepForm';
import { EditContainerProps } from '../../../components/createFlow';
import { isValidMeterNumber } from '../../../helpers/validateStrings';
import { DocTitle } from '../../../components/docTitle';

import {
  AddressForm,
  MeasurementConceptForm,
  ProjectForm,
  GridForm,
  BaseDataForm,
} from './formParts';

const createPlantTitle = 'Kundenanlage anlegen';

export default function CreatePlantFlow({
  onAbort,
  onSuccess,
}: EditContainerProps) {
  const newProjectState = useState(false);
  const history = useHistory();
  const client = useApolloClient();

  const steps = [
    {
      title: 'Stammdaten',
      content: <BaseDataForm />,
    },
    {
      title: 'Adresse',
      content: (
        <AddressForm hintLimitReached="Es können max. vier Zusatzadressen hinterlegt werden." />
      ),
    },
    {
      title: 'Messkonzept',
      content: <MeasurementConceptForm />,
    },
    {
      title: 'Netz',
      content: <GridForm />,
    },
    {
      title: 'Projekt',
      content: <ProjectForm newProjectState={newProjectState} />,
    },
  ];

  return (
    <>
      <DocTitle titleParts={[createPlantTitle]} />
      <GraphqlMultiStepForm
        mutation="createPlant"
        steps={steps}
        onAbort={onAbort}
        readDocument={readPlantsSimple}
        onSuccess={(result, add) => {
          /**
           * Refetch the project the new plant is in.
           * This can happen in the background,
           * we don't need to await the result
           */
          client.query({
            query: ReadProjectsQueryDocument,
            variables: { projectId: result.projectId },
          });

          if (onSuccess) {
            onSuccess(result, add);
          }

          history.push(`project/${result.project.id}/plant/${result.id}`);
        }}
        refetchQueries={[{ query: ReadProjectsQueryDocument }]}
        validation={{
          name: yup
            .string()
            .required()
            .test(
              'no-parentheses',
              'Die folgenden Zeichen sind nicht erlaubt: ( ) %',
              (value: string | undefined) => {
                if (value) {
                  return !/[()%]/.test(value);
                }
                return true;
              },
            )
            .test(
              'valid-plantName',
              'Name existiert bereits',
              async (plantName) => {
                const data = await client
                  .query({
                    query: gql`
                      query readPlantExists($plantName: String!) {
                        readPlantExists(plantName: $plantName) {
                          exists
                          plantId
                        }
                      }
                    `,
                    variables: {
                      plantName,
                    },
                  })
                  .then((result) => result.data);
                return !data?.readPlantExists?.exists;
              },
            ),
          units: yup.number().min(1).integer(),
          measurementConcept: yup.string().required(),
          meters: [
            {
              meterPlace: yup.string().required(),
              meterNumber: yup
                .string()
                .required()
                .test(
                  'meterNumber name validity',
                  'Ungültiges Format',
                  isValidMeterNumber,
                )
                .test(
                  'meterNumber',
                  'Es gibt bereits einen Zähler mit dieser Zählernummer',
                  isMeterNumberUniqueInArrayOfMeters,
                ),
              converterFactor: yup.number().min(1).required(),
              metering: yup.string().required(),
            },
          ],
        }}
      />
    </>
  );
}

export const isMeterNumberUniqueInArrayOfMeters = function isMeterUnique(
  this: yup.TestContext,
  value: string | undefined,
) {
  if (!value) return true;
  const { meters } = this.options.context as CreatePlantFlowInput;
  return meters.every((m, index) => {
    if (!m.meterNumber) return true;
    const currentValueTrimmed = m.meterNumber.trimEnd();
    const mValueTrimmed = value.trimEnd();
    const isThisCurrentMeter = this.path.includes(index.toString());
    if (isThisCurrentMeter) {
      return true;
    }
    return currentValueTrimmed !== mValueTrimmed;
  });
};
