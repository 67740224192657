import styled from 'styled-components';
import React, { useState, useEffect, useCallback } from 'react';
import { useFormikContext } from 'formik';
import { get } from 'lodash';

import { StyledLabel } from '../pages/contract/sharedForms';

import { TooltipInfo } from './TooltipInfo';

import { Label } from '.';

export const OptionalFieldStyling = styled.div`
  padding-left: 10px;
`;

interface BooleanState {
  value: boolean;
  setValue: (value: boolean) => void;
}

const OptionalFieldWrap = styled.div``;

const Item = styled.div``;

interface OptionalFieldProps {
  label: string;
  options: string[];
  children: React.ReactNode;
  managedState?: [BooleanState['value'], BooleanState['setValue']];
  'test-id'?: string;
  resetFields?: string[];
  tooltipText?: string;
}

function OptionalField({
  label,
  managedState,
  'test-id': testId,
  options,
  children,
  resetFields,
  tooltipText,
}: OptionalFieldProps) {
  const id = `option-${label}`;
  const localState = useState(true);
  const [state, setState] = managedState ?? localState;
  const { setFieldValue, initialValues, getFieldProps } = useFormikContext();

  const resetFieldsFunc = useCallback(
    (pResetFields: string[]) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const path of pResetFields) {
        if (getFieldProps(path).value !== get(initialValues, path)) {
          setFieldValue(path, get(initialValues, path), true);
        }
      }
    },
    [initialValues, setFieldValue, getFieldProps],
  );

  useEffect(() => {
    if (managedState && managedState[0] !== state) {
      setState(state);
    }
    if (state && resetFields) {
      resetFieldsFunc(resetFields);
    }
  }, [managedState, state, setState, resetFields, resetFieldsFunc]);

  return (
    <OptionalFieldWrap>
      <Label>
        {label}
        {tooltipText && (
          <TooltipInfo id={`${label}-ToolTip`} text={tooltipText} />
        )}
      </Label>
      <Item>
        <input
          type="radio"
          data-testid={`${testId ?? id}-false`}
          id={`${id}-false`}
          checked={state}
          onClick={() => setState(true)}
          readOnly
        />
        <StyledLabel htmlFor={`${id}-false`}>{options[0]}</StyledLabel>
      </Item>
      <Item>
        <input
          type="radio"
          data-testid={`${testId ?? id}-true`}
          id={`${id}-true`}
          checked={!state}
          onClick={() => setState(false)}
          readOnly
        />
        <StyledLabel htmlFor={`${id}-true`}>{options[1]}</StyledLabel>
        {state ? null : children}
      </Item>
    </OptionalFieldWrap>
  );
}

export default OptionalField;
