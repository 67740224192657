import * as React from 'react';
import { useFormikContext } from 'formik';
import { isValidIBAN } from 'ibantools';
import styled from 'styled-components';
import { get } from 'lodash';

import InputSuggestion from '../../../components/inputSuggestion';
import { useGraphqlForm } from '../../../components/graphql-form/hooks/useGraphqlForm';
import { useGetBicForIbanQuery } from '../../../graphql-types';
import { GraphqlFormField } from '../../../components/graphql-form/render';
import { Hidden } from '../sharedForms';

import type { FormPartProps } from './sharedFormParts';

function OptionallyHidden({
  shouldHide,
  children,
}: React.PropsWithChildren<{ shouldHide: boolean }>) {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return shouldHide ? <Hidden>{children}</Hidden> : <>{children}</>;
}

const BankingDetailsContainer = styled.div<{ $isEditing?: boolean }>`
  display: grid;
  grid-gap: ${({ $isEditing }) => ($isEditing ? '0.5rem' : '0rem')};
  grid-auto-flow: row;
`;

export function BankingDetailsForm({
  fieldNamePrefix,
  isSelfPayer,
}: FormPartProps & { isSelfPayer?: boolean }) {
  const [showBicSuggestion, setShowBicSuggestion] = React.useState(true);
  const [previousIban, setPreviousIban] = React.useState(null);
  const { values, setFieldValue } = useFormikContext();
  const { isEditing } = useGraphqlForm();

  const isNonEditingSelfPayer = !isEditing && !!isSelfPayer;

  const name = get(values, `${fieldNamePrefix}.bankAccount.name`);
  const iban = get(values, `${fieldNamePrefix}.bankAccount.iban`);
  const currentBic = get(values, `${fieldNamePrefix}.bankAccount.bic`);

  const { data } = useGetBicForIbanQuery({
    variables: { iban },
    skip: !isValidIBAN(iban) || !isEditing || !showBicSuggestion,
  });

  const bic = data?.getBicForIban;

  React.useEffect(() => {
    if (iban !== previousIban) {
      setShowBicSuggestion(true);
      setPreviousIban(iban);
    }
  }, [iban, previousIban]);

  React.useEffect(() => {
    if (bic && bic === currentBic) {
      setShowBicSuggestion(false);
    }
  }, [bic, currentBic]);

  return (
    <BankingDetailsContainer $isEditing={isEditing}>
      <Hidden>
        <GraphqlFormField name={`${fieldNamePrefix}.hasSepa`} label={null} />
      </Hidden>
      <OptionallyHidden shouldHide={isNonEditingSelfPayer && !name}>
        <GraphqlFormField
          name={`${fieldNamePrefix}.bankAccount.name`}
          label={null}
        />
      </OptionallyHidden>
      <OptionallyHidden shouldHide={isNonEditingSelfPayer && !iban}>
        <GraphqlFormField
          name={`${fieldNamePrefix}.bankAccount.iban`}
          prependix="IBAN"
          label={null}
        />
      </OptionallyHidden>
      <OptionallyHidden shouldHide={isNonEditingSelfPayer && !currentBic}>
        <GraphqlFormField
          name={`${fieldNamePrefix}.bankAccount.bic`}
          prependix="BIC"
          label={null}
        />
      </OptionallyHidden>
      {isEditing && bic && showBicSuggestion && (
        <InputSuggestion
          message="Vorgeschlagene BIC:"
          value={bic}
          onAccept={() => {
            setFieldValue(`${fieldNamePrefix}.bankAccount.bic`, bic);
            setShowBicSuggestion(false);
          }}
        />
      )}
      {!isSelfPayer && (
        <>
          <GraphqlFormField
            name={`${fieldNamePrefix}.bankAccount.mandateReference`}
            prependix="REF"
            label={null}
          />
          <GraphqlFormField name={`${fieldNamePrefix}.bankAccount.signedAt`} />
        </>
      )}
    </BankingDetailsContainer>
  );
}
