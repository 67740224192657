import {
  Button,
  DialogProvider,
  Flex,
  FlexRow,
  Input,
  Modal,
  PaddedShadowBox,
} from '@ampeersenergy/ampeers-ui-components';
import { DateTime } from 'luxon';
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { CreateFlow, ErrorMsg, InfoMsg } from '../../../../../components';
import { Select } from '../../../../../components/input';
import {
  Meter,
  Metering,
  ReadMeterQuery,
  useReadMeterReadingsQuery,
} from '../../../../../graphql-types';
import {
  METER_OBIS_CHANNEL_RLM_BEZUG,
  METER_OBIS_CHANNEL_SLP_BEZUG,
  METER_READING_END_DATE,
  METER_READING_START_DATE,
  METER_TYPES_WITH_MULTIPLE_OBIS_CHANNELS,
  METERING_OBIS_MAP,
  OBIS_CHANNEL_TYPE_TEXT_MAP,
} from '../../../../../helpers/constants';

import AddMeterReadingContainer from './addMeterReading/addMeterReadingContainer';
import { RlmReadingsTable } from './rlm';
import { SlpReadingsTable } from './slp';

export function MeterReadings({
  meter,
  showObis,
  loading,
}: {
  showObis: boolean;
  meter: ReadMeterQuery['readMeter'];
  loading: boolean;
}) {
  if (loading) {
    return (
      <PaddedShadowBox>
        <Actions />
        <SlpReadingsTable readings={[]} loading meter={meter!} />
      </PaddedShadowBox>
    );
  }

  return <MeterReadingsLoaded meter={meter!} showObis={showObis} />;
}

function MeterReadingsLoaded({
  meter,
  showObis,
}: {
  showObis: boolean;
  meter: Pick<Meter, 'metering' | 'id' | 'meterType' | 'melo'>;
}) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [obisChannel, setObisChannel] = useState(
    meter.metering !== Metering.Rlm
      ? METER_OBIS_CHANNEL_SLP_BEZUG
      : METER_OBIS_CHANNEL_RLM_BEZUG,
  );

  const [timeRange, setTimeRange] = useState({
    startAt:
      meter.metering !== Metering.Rlm
        ? new Date(METER_READING_START_DATE).toISOString()
        : DateTime.now().minus({ year: 1 }).toISO(),

    endAt:
      meter.metering !== Metering.Rlm
        ? new Date(METER_READING_END_DATE).toISOString()
        : DateTime.now().toISO(),
  });

  const {
    data: readings,
    loading,
    error,
  } = useReadMeterReadingsQuery({
    variables: {
      meterId: meter.id!,
      startDate: timeRange.startAt,
      endDate: timeRange.endAt,
      metering: meter.metering === Metering.Slp ? Metering.Slp : Metering.Rlm,
    },
    skip: meter === undefined,
    fetchPolicy: 'cache-and-network',
  });

  const readingsForSelectedChannel = useMemo(
    () =>
      readings
        ? readings.readMeterReadings.filter(
            (reading) => reading.obis === obisChannel,
          )
        : [],
    [readings, obisChannel],
  );

  const disableAddMeterReading = !meter.melo;

  const showObisDropdown =
    showObis &&
    METER_TYPES_WITH_MULTIPLE_OBIS_CHANNELS.includes(meter.meterType);

  const onDateChange = useCallback(
    (event) => {
      setTimeRange({
        startAt: timeRange.startAt,
        endAt: timeRange.endAt,
        [event.target.name]: DateTime.fromSQL(event.target.value).toISO(),
      });
    },
    [setTimeRange, timeRange],
  );

  return (
    <DialogProvider>
      <PaddedShadowBox>
        <Actions>
          {showObisDropdown && (
            <ObisDropdown>
              <FlexRow>
                <Prefix>OBIS-Kanal</Prefix>
                <Select
                  id="obisSelection"
                  onChange={(e: any) => setObisChannel(e?.target?.value)}
                >
                  {METERING_OBIS_MAP[meter?.metering as Metering]?.map(
                    (channel) => (
                      <option key={channel} value={channel}>
                        {OBIS_CHANNEL_TYPE_TEXT_MAP[channel]}
                        {` (${channel})`}
                      </option>
                    ),
                  )}
                </Select>
              </FlexRow>
            </ObisDropdown>
          )}
          {meter.metering === Metering.Rlm && (
            <Inputs>
              <Prefix>Zeitraum</Prefix>
              <Input
                id="startAt"
                type="date"
                name="startAt"
                value={DateTime.fromISO(timeRange.startAt).toSQLDate()}
                onChange={onDateChange}
              />
              <Input
                id="endAt"
                type="date"
                name="endAt"
                value={DateTime.fromISO(timeRange.endAt).toSQLDate()}
                onChange={onDateChange}
              />
            </Inputs>
          )}
          {meter.metering === Metering.Slp && !disableAddMeterReading && (
            <div>
              <Button
                id="add-meter-reading-btn"
                onClick={() => setModalIsOpen(true)}
              >
                Zählerstand hinzufügen
              </Button>
            </div>
          )}
        </Actions>
        {error && <ErrorMsg error={error} />}
        {disableAddMeterReading && (
          <InfoMsg
            title="Hinweis"
            message="Der Zähler ist bisher keinem Vertrag zugeordnet.
                Zählerstände können deshalb nicht erfasst werden."
          />
        )}
        {!disableAddMeterReading && meter?.metering === Metering.Slp && (
          <SlpReadingsTable
            readings={readingsForSelectedChannel}
            loading={loading}
            meter={meter}
            startDate={timeRange.startAt}
            endDate={timeRange.endAt}
          />
        )}
        {!disableAddMeterReading && meter.metering === Metering.Rlm && (
          <RlmReadingsTable
            readings={readingsForSelectedChannel}
            loading={loading}
          />
        )}
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={() => setModalIsOpen(false)}
          contentLabel="Create-MeterReading-Modal"
          title="Zählerstand hinzufügen"
        >
          <CreateFlow
            editContainer={AddMeterReadingContainer}
            kind="Zählerstand"
            onDone={() => setModalIsOpen(false)}
            variables={{
              existingReadings: readings?.readMeterReadings,
              meterId: meter?.id!,
              meterType: meter?.meterType,
              startDate: METER_READING_START_DATE,
              endDate: METER_READING_END_DATE,
              showObisDropdown: !!showObisDropdown,
              selectedObis: obisChannel,
              metering:
                meter?.metering === Metering.Rlm ? Metering.Rlm : Metering.Slp,
            }}
          />
        </Modal>
      </PaddedShadowBox>
    </DialogProvider>
  );
}

const ObisDropdown = styled(Flex)`
  align-items: center;
`;

const Prefix = styled.div`
  margin-right: 10px;
  font-size: 15px;
  font-weight: 600;
  align-self: center;
`;

const Inputs = styled(FlexRow)`
  justify-content: flex-end;
  align-items: center;

  > div:not(:first-child) {
    margin-right: 5px;
  }
`;

const Actions = styled(FlexRow)`
  margin-top: 15px;
  margin-bottom: 10px;
  justify-content: flex-end;
  align-items: center;
`;
