import * as React from 'react';
import { Flex } from '@ampeersenergy/ampeers-ui-components';

import { GraphqlFormField } from '../../../components/graphql-form/render';
import { sortTariffsSuggestions } from '../../../helpers/utils';
import { ContractStatus } from '../../contract/create/formParts/contractStatus/contractStatus';

interface ContractTemplateFormProps {
  fieldNamePrefix?: string;
}

export function ContractTemplateForm({
  fieldNamePrefix = '',
}: ContractTemplateFormProps) {
  return (
    <Flex>
      <ContractStatus fieldNamePrefix={fieldNamePrefix} />
      <GraphqlFormField
        name={`${fieldNamePrefix}tariff.id`}
        sort={sortTariffsSuggestions}
        placeholder="Tarif - Preisblatt"
      />
      <GraphqlFormField name={`${fieldNamePrefix}loadProfile`} />
    </Flex>
  );
}
