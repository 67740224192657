import Maybe from 'graphql/tsutils/Maybe';
import { DateTime } from 'luxon';

import { InvoiceCycle } from '../../../graphql-types';

export function yearsUntilContractStart(contractStartYear: number) {
  const currentYear = new Date().getFullYear();

  return Array.from(
    { length: currentYear - contractStartYear + 1 },
    (_, i) => currentYear - i,
  ).reverse();
}

export const getSubstractPeriod = (invoiceCycle: Maybe<InvoiceCycle>) => {
  switch (invoiceCycle) {
    case InvoiceCycle.Monthly:
      return { month: 1 };
    case InvoiceCycle.Quarterly:
      return { month: 3 };
    case InvoiceCycle.HalfYearly:
      return { month: 6 };
    case InvoiceCycle.Yearly:
    default:
      return { year: 1 };
  }
};

export const quarterlyPeriods = [
  { 'Erstes Quartal (Januar-März)': 3 },
  { 'Zweites Quartal (April-Juni)': 6 },
  { 'Drittes Quartal (Juli-September)': 9 },
  { 'Viertes Quartal (Oktober-Dezember)': 12 },
] as const;

export const halfYearlyPeriods = [
  { 'Erste Hälfte (Januar-Juni)': 6 },
  { 'Zweite Hälfte (Juli-Dezember)': 12 },
] as const;

/**
 * Returns Last Settlement Date based on a date and a invoiceCycle.
 * Search which is the last month where a settlement occurred based on how often do these occur (1 month, 3 months.)
 * In case the month is december, the settlement date is checked on last year, as it's not possible that there's already been a settlement on december of the given date
 * @param endDate
 * @param invoiceCycle
 *
 * @example
 * Here's an example with getLastSettlementDate(DateTime.fromISO('2023-01-25'), InvoiceCycle.Quarterly)):
 * ```
 * returns Datetime.fromISO(2022-12-31)
 * ```
 * (Because the last settlement for 2023-01-25 is 2022-12-31)
 *
 * @example
 * Here's another example with getLastSettlementDate(DateTime.fromISO('2023-06-25'),InvoiceCycle.Monthly)):
 * ```
 * returns Datetime.fromISO(2023-05-31)
 * ```
 *(Because the last settlement for 2023-06-25 is 2023-05-31 for a monthly cycle)
 * */
export const getLastSettlementDate = (
  endDate: DateTime,
  invoiceCycle?: InvoiceCycle | null,
  settlementMonth?: number,
  settlementDay?: number,
) => {
  const { year: endDateYear } = endDate;
  let settlementMonths: number[] | undefined;
  switch (invoiceCycle) {
    case InvoiceCycle.Monthly:
      settlementMonths = new Array(12).fill(null).map((_, i) => i + 1);
      break;
    case InvoiceCycle.Quarterly:
      settlementMonths = quarterlyPeriods.map(
        (period) => Object.values(period)[0],
      );
      break;
    case InvoiceCycle.HalfYearly:
      settlementMonths = halfYearlyPeriods.map(
        (period) => Object.values(period)[0],
      );
      break;
    case InvoiceCycle.Yearly:
    default:
      return DateTime.fromObject({
        day: settlementDay ?? 31,
        month: settlementMonth ?? 12,
        year: endDateYear - 1,
      });
  }

  if (!settlementMonths) {
    return null;
  }

  // Creates from the months an array with the possible startPeriodDates (the ones that have already happened) and the difference of time respect the endDate
  const startPeriodDates = settlementMonths
    .reduce((acc, currentSettlementMonth) => {
      let settlementMonthYear;
      if (currentSettlementMonth === 12) {
        settlementMonthYear = endDateYear - 1;
      } else {
        settlementMonthYear = endDateYear;
      }
      const monthDateTime = DateTime.fromObject({
        month: currentSettlementMonth,
        year: settlementMonthYear,
      });
      const periodStartDate = DateTime.fromObject({
        day: monthDateTime.daysInMonth,
        month: currentSettlementMonth,
        year: settlementMonthYear,
      }).plus({ day: 1 }); // We have to add 1 day as it's when the period date starts

      // We add just the period dates that fall under the endDate for the curren tyear
      if (periodStartDate <= endDate) {
        return [...acc, periodStartDate];
      }
      return acc;
    }, [] as DateTime[])
    .sort()
    .reverse();

  return startPeriodDates.length > 0 ? startPeriodDates[0] : null;
};

export const getClosestDatetoEndDate = (
  endDate: DateTime,
  dates: (DateTime | undefined)[],
) => {
  const nearestDates: DateTime[] = dates
    .reduce((acc, date) => {
      if (date && date <= endDate) {
        return [...acc, date];
      }
      return acc;
    }, [] as DateTime[])
    .sort()
    .reverse();

  return nearestDates.length > 0 ? nearestDates[0] : undefined;
};

export const transformInvoiceCycle = (
  invoiceCycle?: string | null,
): InvoiceCycle => {
  switch (invoiceCycle) {
    case 'yearly':
      return InvoiceCycle.Yearly;
    case 'half-yearly':
      return InvoiceCycle.HalfYearly;
    case 'quarterly':
      return InvoiceCycle.Quarterly;
    case 'monthly':
      return InvoiceCycle.Monthly;
    default:
      return InvoiceCycle.Yearly;
  }
};
export function getAccountableYears({
  firstYearAvailable,
  settlementDay,
  settlementMonth,
  contractStartDate,
}: {
  firstYearAvailable: number;
  settlementDay: number | undefined;
  settlementMonth: number | undefined;
  contractStartDate: Date | undefined;
}) {
  if (settlementDay && settlementMonth && contractStartDate) {
    const contractStartYear = contractStartDate.getFullYear();
    const earliestSettlementDate = DateTime.fromObject({
      year: contractStartYear,
      month: settlementMonth,
      day: settlementDay,
    });

    if (DateTime.fromJSDate(contractStartDate) > earliestSettlementDate) {
      return yearsUntilContractStart(firstYearAvailable + 1);
    }
  }
  /*
  In case settlementDay, settlementMonth or contractStartDate do not exist
  fall back to list of years beginning with year in which the contract starts
  */
  return yearsUntilContractStart(firstYearAvailable);
}
