/* eslint-disable no-console */
/* eslint-disable react/jsx-no-bind */
import {
  AlertRetryable,
  Button,
  DialogProvider,
  DocumentType,
  Modal,
  Module,
} from '@ampeersenergy/ampeers-ui-components';
import { useApolloClient } from '@apollo/client';
import Board, {
  CardDragDestination,
  CardDragSource,
} from '@lourenci/react-kanban';
import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { DeepExtractType } from 'ts-deep-extract-types';

import { FlexRow } from '../../../../components';
import { DocTitle } from '../../../../components/docTitle';
import { DocumentsSidebar } from '../../../../components/documentsSidebar';
import { CrmIcon } from '../../../../components/icons';
import {
  Plant,
  PlantDetailDocument,
  ReadDocumentsDocument,
  ReadDocumentsQuery,
  useReadDocumentsQuery,
} from '../../../../graphql-types';
import { _ae_env_ } from '../../../../helpers/env';
import { isTrueStringBool } from '../../../../helpers/utils';
import { SessionState } from '../../../../redux/session';
import { getSession } from '../../../../services/session';
import CreateOverlay from '../../../contract/create/createOverlay';
import CrmBoardCardEdit, {
  CrmBoardCardEditType,
} from '../crm-board-card-edit/crm-board-card-edit';
import CrmBoardCard from '../crm-board-card/crm-board-card';
import { CrmBoardCardContent } from '../crm-board-card/crm-board-card.types';
import CrmBoardColumnHeader from '../crm-board-column-header/crm-board-column-header';

import '../crm-board-column/crm-board-column.scss';
import { boardStore } from './crm-board.redux';
import './crm-board.scss';
import { CrmBoardCardType, CrmBoardType } from './crm-board.types';

const Spacer = styled.div`
  width: 10px;
`;

const Wrapper = styled.div`
  position: relative;
`;

export type CrmBoardPropType = {
  data: CrmBoardType;
};

const SidebarWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

const DocumentsSidebarStyled = styled(DocumentsSidebar)`
  padding-top: 10px;
`;

const crmTitle = 'Akquise-Tool';

const labels = {
  isperson: 'isPerson',
  prefix: 'Anrede',
  name: 'Name',
  companyname: 'Firmenname',
  contractStartDate: 'Geplanter Lieferbeginn',
  contractEndDate: 'Geplantes Lieferende',
  street: 'Straße',
  streetNo: 'Hausnummer',
  zipCode: 'Postleitzahl',
  city: 'Stadt',
  expectedConsumption: 'Avisierter Jahresverbrauch',
  reductionStartDate: 'Beginn Abschlagszahlung',
  reduction: 'Abschlagszahlung (Brutto)',
  mail: 'E-Mail-Adresse',
  phone: 'Telefon',
  plantid: 'Kundenanlage',
  tariffid: 'Tarif',
  workspaceid: 'Vertragstyp',
  meterid: 'Zähler',
  notes: 'Notiz',
  shippingaddressstreet: 'Straße',
  shippingaddressstreetnr: 'Hausnummer',
  shippingaddresszipcode: 'Postleitzahl',
  shippingaddresscity: 'Stadt',
  leadorigin: 'Lead-Quelle',
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function CrmBoard({ data: _data }: any) {
  const [createOverlayVisible, setCreateOverlayVisible] = useState(false);
  const [documentsSidebarVisible, setDocumentsSidebarVisible] = useState(false);
  const [valuesForCreateContract, setValuesForCreateContract] = useState({
    values: {},
    variables: {},
  });
  const [plant, setPlant]: any = useState();

  const [isModalOpen, toggleModal] = useState(false);
  // const [isInitial, toggleInitial] = useState(true);
  const [cardEditing, setEditingCard] = useState<
    CrmBoardCardType | undefined
  >();

  // @ts-ignore
  const isInitialized = useSelector((state) => state.isInitialized);
  // @ts-ignore
  const board = useSelector((state) => state.board);

  const session = getSession();

  const { data: documentsData } = useReadDocumentsQuery({
    variables: { entityType: 'crmBoard', entityId: board.id },
  });

  const crmBoardDocuments = documentsData?.readDocuments;

  const documentTypes = useMemo(() => {
    if (!crmBoardDocuments) {
      return [];
    }
    return getDocumentTypes(crmBoardDocuments, session);
  }, [crmBoardDocuments, session]);

  const client = useApolloClient();

  /**
   * tracks wether we have changes in the edit form or not
   */
  const [isDirty, setDirty] = useState(false);

  console.log('Got board', board);

  function onCardDragEnd(
    card: CrmBoardCardType,
    source: CardDragSource,
    destination: CardDragDestination,
  ) {
    const { fromColumnId, fromPosition } = source;
    const { toColumnId, toPosition } = destination;

    // change board state
    // either a card has been moved from one column to another
    if (fromColumnId === toColumnId && fromPosition !== toPosition) {
      boardStore.dispatch({
        type: 'MOVE_CARD_IN_COLUMN',
        payload: {
          ...source,
          ...destination,
          card,
        },
      });
    }
    if (fromColumnId !== toColumnId) {
      boardStore.dispatch({
        type: 'MOVE_CARD_TO_COLUMN',
        payload: {
          ...source,
          ...destination,
          card,
        },
      });
    }
  }

  function prepareModalTitle(): string {
    if (cardEditing === undefined) {
      return 'Neuen Kunden anlegen';
    }
    if (isTrueStringBool(cardEditing.isperson)) {
      return `${cardEditing.name} bearbeiten`;
    }
    return `${cardEditing.companyname} bearbeiten`;
  }

  function showCardModal(card?: CrmBoardCardType) {
    setEditingCard(card);
    toggleModal(true);
  }

  function deleteCard(card?: CrmBoardCardType) {
    console.log(card);
    boardStore.dispatch({
      type: 'DELETE_CARD',
      payload: card,
    });
  }

  function hideCardModal() {
    toggleModal(false);
  }

  const handleCardUpdate = useCallback(
    function handleCardUpdate(updatedCard: CrmBoardCardEditType) {
      hideCardModal();
      console.log('Should update card', updatedCard);
      if (cardEditing === undefined) {
        boardStore.dispatch({
          type: 'ADD_CARD',
          payload: { card: updatedCard, laneId: board.columns[0].id },
        });
      } else {
        boardStore.dispatch({
          type: 'EDIT_CARD',
          payload: { card: { ...cardEditing, ...updatedCard } },
        });
      }
    },
    [board.columns, cardEditing],
  );

  async function goToCreateContract(card: CrmBoardCardType) {
    const { data } = await client.query({
      query: PlantDetailDocument,
      variables: {
        plantId: card.plantid,
      },
    });

    const { data: _documentsData } = await client.query({
      query: ReadDocumentsDocument,
      variables: {
        entityId: card.id,
        entityType: 'crmLead',
        groupId: 'contract_draft_letter',
      },
    });

    const shouldMoveDocuments = Boolean(_documentsData?.readDocuments?.length);

    setPlant(data?.readPlant);
    const plantData: Plant = data?.readPlant;

    setValuesForCreateContract({
      values: {
        ...(card?.contractStartDate && { startDate: card.contractStartDate }),
        ...(card?.contractEndDate && { endDate: card.contractEndDate }),
        customer: {
          person: {
            ...(isTrueStringBool(card.isperson)
              ? {
                  namePrefix: card.prefix,
                  name: card.name,
                }
              : {
                  companyFlag: !card.isperson,
                  name: card.companyname,
                  co: card.co,
                }),
            ...(card.phone && { contactPhone: card.phone }),
          },
          address: {
            streetName: card.shippingaddressstreet,
            streetNumber: card.shippingaddressstreetnr,
            zipCode: card.shippingaddresszipcode,
            city: card.shippingaddresscity,
          },
          addressBilling: {
            ...(card.mail && { email: card.mail }),
          },
        },
        contractMeter: {
          meter: {
            id: card.meterid,
          },
        },
        documentIdToMove: shouldMoveDocuments ? card.id : undefined,
        workspace: {
          id: card.workspaceid,
        },
        tariff: {
          id: card.tariffid,
        },
        downPaymentStartDate: card.reductionStartDate,
        downPayment: card.reduction,
        consumptions: {
          initial: {
            value: card.expectedConsumption,
          },
        },
      },
      variables: {
        customerPersonInitialState: isTrueStringBool(card.isperson),
        createContractURL: `/project/${plantData.projectId}/plant/${plantData.id}`,
        fixedInitialState: !!card?.contractEndDate,
        startDate: card.contractStartDate,
        endDate: card.contractEndDate,
      },
    });
    setCreateOverlayVisible(true);
  }

  const metadata = useMemo(
    () => ({
      created: cardEditing?.created,
      updated: cardEditing?.updated,
    }),
    [cardEditing?.created, cardEditing?.updated],
  );

  // if we are not yet initialized, dispatch initialize and show init status
  if (!isInitialized) {
    // # TODO: this should be dispatched earlier in the (component) tree...
    boardStore.dispatch({ type: 'GET_BOARD' });
    return <p>Initialize...</p>;
  }

  return (
    <>
      <DocTitle titleParts={[crmTitle]} />
      {createOverlayVisible && (
        <CreateOverlay
          plant={{
            balancingAreaAccountId: plant?.balancingAreaAccountId || '',
            id: plant?.id || '',
            addresses: plant?.addresses || [],
            name: plant?.name,
          }}
          onClose={() => {
            setCreateOverlayVisible(false);
          }}
          kind="tenant"
          isVisible={createOverlayVisible}
          defaultValues={valuesForCreateContract.values}
          variables={{
            existingCustomerInitialState: false,
            ...valuesForCreateContract.variables,
          }}
        />
      )}
      <Module
        icon={CrmIcon}
        title={crmTitle}
        actions={() => (
          <FlexRow>
            {crmBoardDocuments && !(crmBoardDocuments.length === 0) && (
              <>
                <Button
                  tertiary
                  active={documentsSidebarVisible}
                  onClick={() =>
                    setDocumentsSidebarVisible(!documentsSidebarVisible)
                  }
                >
                  Vorlagen
                </Button>
                <Spacer />
              </>
            )}
            <Button onClick={() => showCardModal()}>Hinzufügen</Button>
          </FlexRow>
        )}
      >
        {/* <CrmBoardActions onAdd={() => showCardModal()} /> */}
        {board === null && (
          <AlertRetryable
            title="Client has no board"
            message="Please configure a board in the crm database for this client or remove crm permission."
            retryable={false}
          />
        )}
        {board && board.columns.length === 0 && (
          <AlertRetryable
            title="Board has no columns"
            message="Please configure columns for this board in the crm database."
            retryable={false}
          />
        )}
        {board && board.columns.length !== 0 && (
          <Wrapper>
            <Board<CrmBoardCardContent, {}>
              onCardDragEnd={onCardDragEnd}
              disableColumnDrag
              renderColumnHeader={({ title, cards: { length: cardCount } }) => (
                <CrmBoardColumnHeader title={title} cardCount={cardCount} />
              )}
              renderCard={(card, { dragging }: { dragging: boolean }) => {
                const { length, [length - 2]: lastColumn } = board.columns;
                const isInLastColumn =
                  lastColumn.id === card.columnId && !!card.plantid;
                return (
                  <CrmBoardCard
                    card={card}
                    dragging={dragging}
                    onEdit={(_card: any) => showCardModal(_card)}
                    onDelete={(_card: any) => deleteCard(_card)}
                    isInLastColumn={isInLastColumn}
                    goToCreateContract={goToCreateContract}
                  />
                );
              }}
            >
              {board}
            </Board>
            {documentsSidebarVisible && (
              <SidebarWrapper>
                <DocumentsSidebarStyled documentTypes={documentTypes} />
              </SidebarWrapper>
            )}
          </Wrapper>
        )}

        <DialogProvider>
          <Modal
            contentLabel=""
            isOpen={isModalOpen}
            onRequestClose={hideCardModal}
            title={prepareModalTitle()}
            warnWhenMovingAway={{
              enabled: isDirty,
              title: 'Ansicht verlassen',
              body: 'Möchtest Du die Ansicht wirklich verlassen? Deine Änderungen werden dann verworfen.',
            }}
          >
            <CrmBoardCardEdit
              submitLabel="Speichern"
              labels={labels}
              onSubmit={handleCardUpdate}
              initialValues={cardEditing}
              id={cardEditing?.id}
              metadata={metadata}
              setDirty={setDirty}
            />
          </Modal>
        </DialogProvider>
      </Module>
    </>
  );
}
export default CrmBoard;

export function getDocumentTypes(
  documents: DeepExtractType<ReadDocumentsQuery, ['readDocuments']>,
  session: SessionState,
): DocumentType[] {
  return documents.reduce<DocumentType[]>((acc, cur) => {
    const type = mapDocTypeToTemplateName(cur.type);
    const existingType = acc.find((el) => el.type === type);

    const fileURL = new URL(cur.fileURL, _ae_env_.REACT_APP_BACKEND_BASE_URI);
    fileURL.searchParams.set('token', session.token!);
    fileURL.searchParams.set('client_id', session.clientId!);
    fileURL.searchParams.set('attachment', 'true');

    const document = {
      createdAt: cur.createdAt,
      fileURL: fileURL.toString(),
      contentType: cur.contentType,
      fileName: cur.fileName ?? undefined,
      id: cur.hash ?? undefined,
      type: cur.type,
    };

    if (existingType) {
      const existingTypeWithDocument = {
        ...existingType,
        documents: [...(existingType.documents ?? []), document],
      };
      const accWithoutExisting = acc.filter((el) => el.type !== type);
      return [...accWithoutExisting, existingTypeWithDocument];
    }
    const newType = {
      type,
      documents: [document],
      creation: {
        disabled: true,
        hint: 'disabled',
      },
      deletion: {
        enabled: false,
      },
      previewEnabled: false,
    };
    return [...acc, newType];
  }, []);
}

function mapDocTypeToTemplateName(docType: string) {
  switch (docType) {
    case 'contract_energy_delivery':
      return 'Stromliefervertrag';
    case 'tariff_price_regulation':
      return 'Tarif- und Preisregelung';
    case 'dismissal_notice':
      return 'Kündigungsschreiben';
    case 'cover_letter_contract_new_build':
      return 'Anschreiben Vertrag Neubau';
    case 'cover_letter_contract':
      return 'Anschreiben Vertrag';
    case 'business_terms_energy_delivery':
      return 'AGB Stromlieferung';
    case 'dismissal_warrant':
      return 'Vollmacht Kündigung';
    default:
      return 'Unbekanntes Dokument';
  }
}
