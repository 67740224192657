import React from 'react';
import styled from 'styled-components';
import { Icons } from '@ampeersenergy/ampeers-ui-components';

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;
  flex: 1;
`;

const SuccessResult = ({
  type = 'Schritt',
  className = '',
}: {
  type?: string;
  className?: string;
}) => {
  return (
    <Center className={className}>
      <div>
        <Icons.Checkmark size={60} color="#119142" />
      </div>
      {type} erfolgreich abgeschlossen.
    </Center>
  );
};

export default SuccessResult;
