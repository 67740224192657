import React from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

import { EditContainerProps } from '../../../../../components/createFlow';
import { Entry, FlexRow, Flex, Relation } from '../../../../../components';
import {
  formatMeter,
  formatString,
  formatMeterType,
} from '../../../../../helpers/formatStrings';
import { StyledLink } from '../../../../../components/graphql-form/style';
import { MeterIcon } from '../../../../../components/icons';

const LinkWrap = styled.div`
  margin-right: 5px;
  display: inline-block;
`;

interface MainPanelProps extends EditContainerProps {
  data: any;
  malo: string;
}

function MainPanel({ data: meter, malo }: MainPanelProps) {
  const location = useLocation();

  return (
    <>
      <FlexRow>
        <Flex>
          <Entry title="Beginn Zuordnungsgültigkeit">
            {meter?.startDateFormatted}
          </Entry>
        </Flex>
        <Flex>
          <Entry title="Ende Zuordnungsgültigkeit">
            {meter?.endDateFormatted}
          </Entry>
        </Flex>
      </FlexRow>
      <FlexRow>
        <Flex>
          <Entry title="Zähler">
            <LinkWrap>
              <StyledLink
                to={`${location.pathname}/meter/${meter?.id}?showObis=false`}
              >
                <Relation icon={MeterIcon} title={formatMeter(meter)} />
              </StyledLink>
            </LinkWrap>
          </Entry>
        </Flex>
        <Flex>
          <Entry title="Messart">{formatString(meter?.metering)}</Entry>
        </Flex>
      </FlexRow>
      <FlexRow>
        <Flex>
          <Entry title="Mieterstrom-Zählertyp">{formatMeterType(meter)}</Entry>
        </Flex>
        <Flex>
          <Entry title="Lagezusatz">{formatString(meter?.meterPlace)}</Entry>
        </Flex>
      </FlexRow>
      <FlexRow>
        <Flex>
          <Entry title="Melo-ID">{formatString(meter?.melo)}</Entry>
        </Flex>
      </FlexRow>
      <FlexRow>
        <Flex>
          <Entry title="Malo-ID">{formatString(malo)}</Entry>
        </Flex>
      </FlexRow>
    </>
  );
}

export default MainPanel;
