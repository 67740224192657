import React from 'react';

// eslint-disable-next-line import/no-cycle
import { GraphqlFormContextType, GraphqlFormContext } from './useGraphqlForm';

export interface GraphqlFormFieldIndentifier {
  name: string;
}

export interface GraphqlFormFieldInternalIdentifier {
  id: string;
}

export interface GraphqlFormFieldProps {
  dataType?: string;
  // input type
  type?: string;
  asType?: string;

  label?: string;
  value?: any;

  relation?: string;
  // for relations
  multiselect?: boolean;

  unit?: string;
  appendix?: React.ReactNode;
  prependix?: React.ReactNode;
  placeholder?: string;

  // used together
  values?: string[];
  labels?: string[];

  renderEmptyString?: boolean;

  formatValue?: (value: string) => string;
  // wraps the value in a <Link> Component
  linkTo?: string;

  'data-testid'?: string;

  required?: boolean;
  'aria-required'?: boolean;
}

export const useGraphqlFormField = ({
  name,
  ...rest
}: GraphqlFormFieldIndentifier & GraphqlFormFieldProps): [
  GraphqlFormFieldProps &
    GraphqlFormFieldIndentifier &
    GraphqlFormFieldInternalIdentifier,
  GraphqlFormContextType,
] => {
  const context = React.useContext(GraphqlFormContext);

  if (context === undefined) {
    throw new Error(
      'useGraphqlFormField must be used within a GraphlFormContext',
    );
  }

  React.useEffect(() => {
    if (name) {
      context.registerField(name);
    }
    return () => {
      if (name) {
        context.unregisterField(name);
      }
    };
  }, [context, name]);

  if (context.isLoading) {
    return [
      {
        name,
        id: name,
        ...rest,
      },
      context,
    ];
  }

  const props = context.getFieldProps(name);

  if (props === undefined) {
    throw new Error(`useGraphqlFormField: Unknown Fieldname: '${name}'`);
  }

  return [{ ...props, ...rest }, context];
};
