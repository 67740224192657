import * as React from 'react';
import {
  Label,
  Selectable,
  SelectableOption,
} from '@ampeersenergy/ampeers-ui-components';
import 'styled-components/macro';
import { useFormikContext } from 'formik';

import { Entry, ErrorMsg } from '../../../components';
import { useGraphqlForm } from '../../../components/graphql-form/hooks/useGraphqlForm';
import { useReadCustomersSimpleQuery } from '../../../graphql-types';
import { AddressSelection } from '../../contract/create/AddressesForm';

interface AddressTemplateFormProps {
  isExistingCustomer: boolean;
  fieldNamePrefix?: string;
}

export function AddressTemplateForm({
  isExistingCustomer,
  fieldNamePrefix = '',
}: AddressTemplateFormProps) {
  const { getFieldProps } = useFormikContext();
  const { formVariables } = useGraphqlForm();

  const existingCustomerId = getFieldProps(
    'customer.existingCustomer.id',
  ).value;
  const isExistingCustomerIDSelected = !!existingCustomerId;

  const { data, error, loading } = useReadCustomersSimpleQuery({
    variables: {
      isOperator:
        formVariables.isOperator === undefined
          ? false
          : formVariables.isOperator,
    },
    skip: !isExistingCustomerIDSelected,
  });

  const selectedCustomer = data?.readCustomers.find(
    (customer) => customer.id === existingCustomerId,
  );

  if (error) {
    return <ErrorMsg error={error} />;
  }

  return (
    <div css={{ maxWidth: '640px' }}>
      {isExistingCustomer && selectedCustomer ? (
        <>
          <Label>Adresse</Label>
          <Selectable value="1" onChange={() => {}}>
            <SelectableOption value="1">
              <Entry title={null} isLoading={loading}>
                {selectedCustomer?.address.streetName}{' '}
                {selectedCustomer?.address.streetNumber},{' '}
                {selectedCustomer?.address.zipCode}{' '}
                {selectedCustomer?.address.city}
              </Entry>
            </SelectableOption>
          </Selectable>
        </>
      ) : (
        <>
          <Label>Gebäude</Label>
          <AddressSelection
            fieldNamePrefix={`${fieldNamePrefix}customer.address`}
            skipReset
          />
        </>
      )}
    </div>
  );
}
