import gql from 'graphql-tag';

export default gql`
  # Write your query or mutation here
  fragment FullType on __Type {
    kind
    name
    description
    fields(includeDeprecated: false) {
      name
      description
      args {
        ...InputValue
      }

      type {
        ...TypeRef
      }
    }
    inputFields {
      ...InputValue
    }
    interfaces {
      ...TypeRef
    }
    enumValues(includeDeprecated: false) {
      name
      description
    }
    possibleTypes {
      ...TypeRef
    }
  }
  fragment InputValue on __InputValue {
    name
    description
    type {
      ...TypeRef
    }
    defaultValue
  }
  fragment TypeRef on __Type {
    kind
    name
    ofType {
      kind
      name
      ofType {
        kind
        name
        ofType {
          kind
          name
          ofType {
            kind
            name
            ofType {
              kind
              name
              ofType {
                kind
                name
                ofType {
                  kind
                  name
                }
              }
            }
          }
        }
      }
    }
  }

  query IntrospectionQuery {
    __schema {
      # queryType {name}

      # mutationType {
      # ...FullType
      #}

      types {
        ...FullType
      }

      directives {
        name
        description
        locations
        args {
          ...InputValue
        }
      }
    }
  }
`;
