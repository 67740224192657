import React, { useCallback, useState } from 'react';
import {
  Icons,
  Main,
  Header,
  Button,
  LinkedTab,
  LinkedTabs,
} from '@ampeersenergy/ampeers-ui-components';
import styled from 'styled-components';
import { useRouteMatch } from 'react-router-dom';

import { DocTitle } from '../../components/docTitle';
import { useReadWorkflowsQuery, WorkflowType } from '../../graphql-types';
import { useHasRole } from '../../components/useHasRole';

import WorkflowList from './workflowTable';
import { CreateAccountingWorkflowModal } from './create/createAccountingWorkflow.modal';
import { AccountingDashboard } from './dashboard/AccountingDashboard';
import { AccountingRunOverview } from './AccountingRunOverview';
import ImportBookings from './import/ImportBookings';

export const accountingTitle = 'Abrechnung';

const Content = styled.div`
  position: relative;
`;

function AccountingPage() {
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const match = useRouteMatch();
  const { hasRole: hasBookingsImportEnabled } = useHasRole(
    'feature_booking_import',
  );

  // Already pre-fetch and cache workflow table data
  useReadWorkflowsQuery();

  const renderTableActions = useCallback(() => {
    return (
      <Button onClick={() => setModalVisible(true)}>
        Abrechnung erstellen
      </Button>
    );
  }, [setModalVisible]);

  return (
    <>
      <DocTitle titleParts={[accountingTitle]} />
      <Main>
        <Header
          Icon={Icons.Accounting}
          actions={renderTableActions}
          hideBackground
        >
          {accountingTitle}
        </Header>
        {modalVisible && (
          <CreateAccountingWorkflowModal
            formVariables={{
              workflowType: WorkflowType.Multiple,
            }}
            onClose={() => setModalVisible(false)}
          />
        )}
        <Content>
          <LinkedTabs basePath={match.url}>
            <LinkedTab title="Übersicht" path="/dashboard">
              <AccountingDashboard />
            </LinkedTab>
            <LinkedTab title="Abrechnungen" path="/runs">
              <AccountingRunOverview />
            </LinkedTab>
            <LinkedTab title="Abrechnungen vor 2023" path="/workflows">
              <WorkflowList />
            </LinkedTab>
            {hasBookingsImportEnabled ? (
              <LinkedTab title="Import Buchungsstapel" path="/import">
                <ImportBookings />
              </LinkedTab>
            ) : null}
          </LinkedTabs>
        </Content>
      </Main>
    </>
  );
}

export default AccountingPage;
