import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: flex-start;
  height: 100%;

  a {
    text-decoration: none;
    color: #000;

    &:hover {
      color: ${(props) => props.theme.primaryColor};
    }
  }
`;

export const Sidebar = styled.div`
  order: 0;
  flex: 0 1 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  order: 0;
  flex: 1 1 auto;
  align-self: stretch;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: 73px;
`;
