import * as React from 'react';
import { useFormikContext } from 'formik';
import { merge } from 'lodash';
import styled from 'styled-components/macro';
import {
  Selectable,
  SelectableOption,
} from '@ampeersenergy/ampeers-ui-components';

import { Label, Entry, ErrorMsg } from '../../../components';
import { Address, useReadCustomersSimpleQuery } from '../../../graphql-types';
import { useGraphqlForm } from '../../../components/graphql-form/hooks/useGraphqlForm';
import { GraphqlFormField } from '../../../components/graphql-form/render';
import { SetStateAction } from '../../../helpers/stateHelpers';
import { TooltipInfo } from '../../../components/TooltipInfo';

import type { FormPartProps } from './sharedFormParts';
import { CreateBillingShippingAddressForm } from './formParts/createBillingShippingAddressForm';
import { AddressForm } from './AddressForm';

const StyledLabel = styled(Label)`
  padding: 2rem 0 0.5rem;
`;

export default function AddressesForm({
  addresses = [],
  fieldNamePrefix,
  billingState,
  shippingState,
  billingCustomerPersonState,
  billingCustomerAdditionalEntityFieldsState,
  billingCustomerAdditionalPersonState,
  shippingCustomerPersonState,
  existingCustomerState,
  customerPersonState,
}: {
  addresses?: Address[];
  billingState: SetStateAction<boolean>;
  shippingState: SetStateAction<boolean>;
  billingCustomerPersonState: SetStateAction<boolean>;
  billingCustomerAdditionalEntityFieldsState: SetStateAction<boolean>;
  billingCustomerAdditionalPersonState: SetStateAction<boolean>;
  shippingCustomerPersonState: SetStateAction<boolean>;
  existingCustomerState: SetStateAction<any>;
  customerPersonState: SetStateAction<boolean>;
} & FormPartProps) {
  const { values, setValues, getFieldProps, setFieldValue } =
    useFormikContext();
  const { formVariables } = useGraphqlForm();

  const existingCustomerId = getFieldProps(
    'customer.existingCustomer.id',
  ).value;
  const isExistingCustomerIDSelected = !!existingCustomerId;

  const { data, error, loading } = useReadCustomersSimpleQuery({
    variables: {
      isOperator:
        formVariables.isOperator === undefined
          ? false
          : formVariables.isOperator,
    },
    skip: !isExistingCustomerIDSelected,
  });

  const selectedCustomer = data?.readCustomers.find(
    (customer) => customer.id === existingCustomerId,
  );

  const [reuseBillingAddress, setReuseBillingAddress] = billingState;
  const [reuseShippingAddress, setReuseShippingAddress] = shippingState;
  const [isExistingCustomer] = existingCustomerState;

  React.useEffect(() => {
    if (reuseBillingAddress) {
      billingCustomerPersonState[1](customerPersonState[0]);
      setFieldValue(
        `${fieldNamePrefix}customer.addressBilling.companyFlag`,
        !customerPersonState[0],
        false,
      );
    }
  }, [
    billingCustomerPersonState,
    customerPersonState,
    fieldNamePrefix,
    reuseBillingAddress,
    setFieldValue,
  ]);

  React.useEffect(() => {
    if (reuseShippingAddress) {
      shippingCustomerPersonState[1](customerPersonState[0]);
      setFieldValue(
        `${fieldNamePrefix}customer.addressShipping.companyFlag`,
        !customerPersonState[0],
        false,
      );
      setFieldValue(
        `${fieldNamePrefix}customer.addressShipping.companyFlag`,
        !customerPersonState[0],
        false,
      );
    }
  }, [
    customerPersonState,
    fieldNamePrefix,
    reuseBillingAddress,
    reuseShippingAddress,
    setFieldValue,
    shippingCustomerPersonState,
  ]);

  const resetBilling = React.useCallback(() => {
    billingCustomerPersonState[1](true);
    billingCustomerAdditionalPersonState[1](true);
    billingCustomerAdditionalEntityFieldsState[1](false);

    setValues(
      merge({}, values, {
        customer: {
          addressBilling: {
            namePrefix: '',
            companyFlag: false,
            name: '',
            streetName: '',
            streetNumber: '',
            zipCode: '',
            city: '',
            country: 'DE',
            additionalName: '',
            additionalNamePrefix: '',
            companySecondaryFlag: false,
            co: '',
          },
        },
      }),
      false,
    );
  }, [
    billingCustomerPersonState,
    billingCustomerAdditionalPersonState,
    billingCustomerAdditionalEntityFieldsState,
    setValues,
    values,
  ]);

  const resetShipping = () => {
    shippingCustomerPersonState[1](true);
    setValues(
      merge({}, values, {
        customer: {
          addressShipping: {
            namePrefix: '',
            companyFlag: false,
            name: '',
            streetName: '',
            streetNumber: '',
            zipCode: '',
            city: '',
            country: 'DE',
          },
        },
      }),
      false,
    );
  };

  const handleBillingAddressSelection = (value: string) => {
    if (value === 'true') {
      resetBilling();
    }
    setReuseBillingAddress(value === 'true');
  };

  const handleShippingAddressSelection = (value: string) => {
    if (value === 'true') {
      resetShipping();
    }
    setReuseShippingAddress(value === 'true');
  };

  if (error) {
    return <ErrorMsg error={error} />;
  }

  return (
    <div
      css={`
        max-width: 640px;
      `}
    >
      {isExistingCustomer && selectedCustomer ? (
        <>
          <StyledLabel>Adresse</StyledLabel>
          <Selectable value="1" onChange={() => {}}>
            <SelectableOption value="1">
              <Entry title={null} isLoading={loading}>
                {selectedCustomer.address.streetName}{' '}
                {selectedCustomer.address.streetNumber},{' '}
                {selectedCustomer.address.zipCode}{' '}
                {selectedCustomer.address.city}
              </Entry>
            </SelectableOption>
          </Selectable>
        </>
      ) : (
        <>
          <StyledLabel>
            Adresse Vertragspartner
            <TooltipInfo
              id="contractAddressTooltip"
              text="Änderungen an dieser Adresse wirken sich auf den Vertragspartner und alle zugehörigen Verträge aus."
            />
          </StyledLabel>
          <AddressSelection
            addresses={addresses ?? []}
            fieldNamePrefix={`${fieldNamePrefix}customer.address`}
          />
          <StyledLabel>
            Rechnungsadresse
            <TooltipInfo
              id="invoiceAddressTooltip"
              text="Diese Adresse wird im Briefkopf der Kundendokumente angezeigt. Änderungen wirken sich auf den Vertragspartner und alle zugeordneten Verträge aus."
            />
          </StyledLabel>
          <Selectable
            value={reuseBillingAddress.toString()}
            onChange={handleBillingAddressSelection}
            id="reuse-billing-address"
          >
            <SelectableOption value="true">Adresse übernehmen</SelectableOption>
            <SelectableOption
              value="false"
              content={
                <CreateBillingShippingAddressForm
                  fieldNamePrefix={`${fieldNamePrefix}customer.addressBilling`}
                  billingCustomerAdditionalEntityFieldsState={
                    billingCustomerAdditionalEntityFieldsState
                  }
                  billingCustomerAdditionalPersonState={
                    billingCustomerAdditionalPersonState
                  }
                />
              }
            >
              Abweichende Adresse
            </SelectableOption>
          </Selectable>
        </>
      )}

      <StyledLabel>
        Lieferadresse
        <TooltipInfo
          id="shippingAddressTooltip"
          text="Die Lieferadresse ist die Adresse, an die der Strom geliefert wird. Sie kann von den anderen Adressen abweichen und wird für jeden Vertrag separat definiert."
        />
      </StyledLabel>
      <Selectable
        value={reuseShippingAddress.toString()}
        onChange={handleShippingAddressSelection}
        id="reuse-shipping-address"
      >
        <SelectableOption value="true">Adresse übernehmen</SelectableOption>
        <SelectableOption
          value="false"
          content={
            <CreateBillingShippingAddressForm
              fieldNamePrefix={`${fieldNamePrefix}customer.addressShipping`}
            />
          }
        >
          Abweichende Lieferadresse
        </SelectableOption>
      </Selectable>
    </div>
  );
}

type AddressSelectionProps = {
  addresses?: Address[];
  skipReset?: boolean;
} & FormPartProps;

export function AddressSelection({
  addresses,
  fieldNamePrefix,
  skipReset = false,
}: AddressSelectionProps) {
  const prefix = React.useMemo(
    () => (fieldNamePrefix ? `${fieldNamePrefix}.` : ''),
    [fieldNamePrefix],
  );

  const { setFieldValue } = useFormikContext();

  const uniqueAddresses = React.useMemo(
    () =>
      addresses && addresses.length > 0
        ? addresses.filter(
            (address, index) =>
              addresses.findIndex(
                (a) =>
                  a.streetName === address.streetName &&
                  a.zipCode === address.zipCode &&
                  a.city === address.city &&
                  a.country === address.country,
              ) === index,
          )
        : [],
    [addresses],
  );

  const [selectedAddress, setSelectedAddress] = React.useState<number>(
    uniqueAddresses.length > 0 ? 0 : -1,
  );

  React.useEffect(() => {
    if (selectedAddress === -1 && !skipReset) {
      setFieldValue(`${prefix}streetName`, '', false);
      setFieldValue(`${prefix}streetNumber`, '', false);
      setFieldValue(`${prefix}zipCode`, '', false);
      setFieldValue(`${prefix}city`, '', false);
      setFieldValue(`${prefix}country`, '', false);
    } else if (
      selectedAddress >= 0 &&
      selectedAddress < uniqueAddresses.length
    ) {
      setFieldValue(
        `${prefix}streetName`,
        uniqueAddresses[selectedAddress].streetName,
        false,
      );
      setFieldValue(
        `${prefix}streetNumber`,
        uniqueAddresses[selectedAddress].streetNumber,
        false,
      );
      setFieldValue(
        `${prefix}zipCode`,
        uniqueAddresses[selectedAddress].zipCode,
        false,
      );
      setFieldValue(
        `${prefix}city`,
        uniqueAddresses[selectedAddress].city,
        false,
      );
      setFieldValue(
        `${prefix}country`,
        uniqueAddresses[selectedAddress].country,
        false,
      );
    }
  }, [selectedAddress, setFieldValue, prefix, uniqueAddresses, skipReset]);

  return (
    <>
      <Selectable
        value={selectedAddress}
        onChange={setSelectedAddress}
        id="address-selection"
      >
        {uniqueAddresses.map((address, index) => (
          <SelectableOption
            key={[
              address.streetName,
              address.zipCode,
              address.city,
              address.streetNumber,
              index,
            ].join('-')}
            value={index}
          >
            {address.streetName}, {address.zipCode} {address.city}
          </SelectableOption>
        ))}
        <SelectableOption
          value={-1}
          content={<AddressForm fieldNamePrefix={fieldNamePrefix} />}
        >
          Neue Adresse
        </SelectableOption>
      </Selectable>

      {selectedAddress >= 0 ? (
        <GraphqlFormField name={`${prefix}streetNumber`} label="Hausnummer" />
      ) : null}
    </>
  );
}
