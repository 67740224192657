import * as React from 'react';
import {
  defaultTheme,
  Icons,
  TooltipLight,
  TooltipDark,
} from '@ampeersenergy/ampeers-ui-components';
import styled from 'styled-components';

interface TooltipInfoProps {
  id: string | number;
  text: string;
  dark?: boolean;
  maxWidth?: string;
}

const InfoIcon = styled(Icons.Info)`
  margin-left: 0.25rem;
`;

export function TooltipInfo({
  text,
  id,
  dark,
  maxWidth = '700px',
}: TooltipInfoProps) {
  const tooltipId = `tooltip-info-${id}`;

  const Tooltip = dark ? TooltipDark : TooltipLight;

  return (
    <Tooltip id={tooltipId} place="right" text={text} maxWidth={maxWidth}>
      <InfoIcon
        data-tip
        data-for={tooltipId}
        size={20}
        color={defaultTheme.palette.textMuted}
        data-testid={tooltipId}
      />
    </Tooltip>
  );
}
