import {
  Bold,
  Button,
  Selectable,
  SelectableOption,
  SubTitle,
} from '@ampeersenergy/ampeers-ui-components';
import React, { useState } from 'react';

import { ButtonWrap } from '../style';

export function SendStep({
  onNext,
  customerEmail,
  deliveryPreference: initialDeliveryPreference,
  disableB2CDelivery,
  userEmail,
}: {
  onNext: (deliveryPreference: string) => void;
  customerEmail?: string | null;
  userEmail: string;
  disableB2CDelivery?: boolean;
  deliveryPreference?: string;
}) {
  const [deliveryPreference, setDeliveryPreference] = useState(
    initialDeliveryPreference === 'email' || customerEmail
      ? 'customer'
      : 'user',
  );

  return (
    <>
      <SubTitle>Versandart</SubTitle>
      <Selectable value={deliveryPreference} onChange={setDeliveryPreference}>
        <SelectableOption
          value="CONTRACT_EMAIL"
          disabled={Boolean(disableB2CDelivery || !customerEmail)}
        >
          Direkt an Kunden E-Mail{' '}
          {customerEmail ? <Bold>&quot;{customerEmail}&quot;</Bold> : null}
        </SelectableOption>
        <SelectableOption value="USER_EMAIL">
          Erstellte Dokument per E-Mail an <Bold>&quot;{userEmail}&quot;</Bold>
        </SelectableOption>
        <SelectableOption value="BROWSER">
          Nur im Browser anzeigen
        </SelectableOption>
      </Selectable>
      <ButtonWrap>
        <Button onClick={() => onNext(deliveryPreference)}>Erstellen</Button>
      </ButtonWrap>
    </>
  );
}
