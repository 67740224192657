import React from 'react';
import styled from 'styled-components';
import {
  ColumnDefinition,
  FlexRow,
  SubTitle,
  Table as TableComponent,
} from '@ampeersenergy/ampeers-ui-components';

import { LegendItem, ProgressRingWithLabels } from '../../../components';
import {
  formatDateTime,
  formatSettlementDay,
  formatDate,
} from '../../../helpers/formatStrings';
import { InvoiceCycle, Workflow } from '../../../graphql-types';

import Bar from './bar';
import { InvoiceDraftsContainer } from './invoiceDrafts/invoiceDraftsContainer';
import {
  CalculatedContractConsumption,
  ValidatedContractMeter,
  PlantAutarkySuggestion,
} from './types';

interface WorkflowSummaryProps {
  steps: string[];
  context: Workflow['currentState']['context'];
  meta: Workflow['meta'];
  createdAt: Workflow['createdAt'];
  doneAt: Workflow['doneAt'];
}
const FixedLabel = styled.div`
  width: 200px;
`;

const Row = styled(FlexRow)`
  align-items: baseline;
  margin-top: 19px;
  font-size: 14px;
`;

const Margin = styled.div`
  margin-top: 100px;
  margin-bottom: 40px;
`;

const LegendWrap = styled(FlexRow)`
  justify-content: flex-end;
`;

const SummaryDetails = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
  margin-bottom: 70px;
  margin-top: 70px;
  text-align: center;
`;

const SubsectionTitle = styled(SubTitle)`
  padding-bottom: 12px;
  border-bottom: 1px solid #e8e8e8;
`;

const Table = styled(TableComponent)`
  margin-bottom: 20px;
`;

const BarsWrapper = styled.div`
  padding: 10px;
`;

const primaryColor = '#138E89';
const secondaryColor = '#77F4C0';
const tertiaryColor = '#70DDDA';
const textColor = '#000000';

const StepSum = styled.div`
  color: #848484;
  font-size: 14px;
  padding-left: 10px;
  width: 80px;
  text-align: right;
`;

function WorkflowSummary({
  steps,
  context,
  meta,
  doneAt,
  createdAt,
}: WorkflowSummaryProps) {
  const rows: React.ReactNode[] = [];

  const allContracts = context.validateContractMeters.contracts.length;
  const successfulContracts =
    context?.createAccounting?.opcPayload?.passed?.length ?? 0;
  const failedContracts = allContracts - successfulContracts;

  steps.forEach((step, index) => {
    let succeeded;
    let failed;

    const { contracts, opcPayload, userInput } = context[step] ?? {};

    switch (step) {
      case 'validateContractMeters':
        succeeded = contracts.filter(
          ({ meters }: ValidatedContractMeter) =>
            meters.filter(({ errors }: any) => errors.length !== 0).length ===
            0,
        ).length;
        failed = contracts.filter(
          ({ meters }: ValidatedContractMeter) =>
            meters.filter(({ errors }: any) => errors.length !== 0).length !==
            0,
        ).length;
        break;
      case 'validateContractConsumptions':
        succeeded = contracts.filter(
          ({ errors }: CalculatedContractConsumption) => errors.length === 0,
        ).length;
        failed = contracts.filter(
          ({ errors }: CalculatedContractConsumption) => errors.length !== 0,
        ).length;
        break;
      case 'createAccounting':
        succeeded = opcPayload?.passed?.length ?? 0;
        failed = opcPayload?.errored?.length ?? 0;
        break;
      case 'calculateAutarky': {
        const contractsGroupedByPlants: { [key: string]: number[] } = (
          context.validateContractMeters.contracts as ValidatedContractMeter[]
        )
          .filter(
            ({ meters }) =>
              meters.filter(({ errors }) => errors.length === 0).length !== 0,
          )
          .reduce(
            (acc: any, cur: ValidatedContractMeter) => ({
              ...acc,
              [cur.plantId]: [
                ...(acc[String(cur.plantId)] ?? []),
                cur.contractLabel,
              ],
            }),
            {},
          );

        succeeded = userInput
          .map(
            (p: PlantAutarkySuggestion) =>
              new Set(contractsGroupedByPlants[String(p.plantId)]).size,
          )
          .reduce((acc: number, cur: number) => acc + cur, 0);

        failed = 0;
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const plantId in contractsGroupedByPlants) {
          const selected = (userInput as PlantAutarkySuggestion[]).find(
            (p: PlantAutarkySuggestion) =>
              String(p.plantId) === String(plantId),
          );

          if (!selected) {
            failed += new Set(contractsGroupedByPlants[plantId]).size;
          }
        }
        break;
      }
      default:
        return;
    }

    const stepTotal = failed + succeeded;

    rows.push(
      <Row key={step}>
        <FixedLabel>{meta[step].name ?? step}</FixedLabel>
        <Bar
          total={allContracts}
          stepTotal={stepTotal}
          stepSuccess={succeeded}
          stepFailures={failed}
          index={index}
        />
        <StepSum>{stepTotal} (100%)</StepSum>
      </Row>,
    );
  });

  const columns: ColumnDefinition<any>[] = [
    {
      Header: 'Beginn Rechnungslauf',
      accessor: 'createdAt',
      type: 'date',
      Cell: ({ value }) => formatDateTime(value),
    },
    {
      Header: 'Abschluss Rechnungslauf',
      accessor: 'doneAt',
      type: 'date',
      Cell: ({ value }) => formatDateTime(value),
    },
    {
      Header: 'Rechnungsperiode',
      accessor: 'paymentPeriod',
      Cell: (props) =>
        `${formatDate(props.value?.startAt)} - ${formatDate(
          props.value?.endAt,
        )}`,
      sortType: (rowA, rowB, columnId, desc) => {
        if (desc) {
          return (
            Date.parse(rowA.values[columnId].endAt) -
            Date.parse(rowB.values[columnId].endAt)
          );
        }
        return (
          Date.parse(rowA.values[columnId].startAt) -
          Date.parse(rowB.values[columnId].startAt)
        );
      },
    },
    {
      Header: 'Abrechnungsstichtag',
      accessor: 'settlementDate',
      Cell: (props) => {
        return formatSettlementDay(
          props.value?.day,
          props.value?.month,
        ).replace('/', '.');
      },
      sortType: (rowA, rowB, columnId) =>
        Date.parse(
          `${rowA.values[columnId].month}/${
            rowA.values[columnId].day
          }/${new Date().getFullYear()}`,
        ) -
        Date.parse(
          `${rowB.values[columnId].month}/${
            rowB.values[columnId].day
          }/${new Date().getFullYear()}`,
        ),
    },
  ];

  const tableData = [
    {
      createdAt,
      doneAt,
      paymentPeriod: {
        startAt: context.payload?.paymentPeriodStartAt,
        endAt: context.payload?.paymentPeriodEndAt,
      },
      settlementDate: {
        day: context.payload?.settlementDay,
        month: context.payload?.settlementMonth,
      },
    },
  ];

  return (
    <>
      <Table isLoading={false} columns={columns} data={tableData} />
      <SummaryDetails>
        <ProgressRingWithLabels
          isLoading={false}
          radius={42}
          stroke={6}
          progress={[100]}
          colors={[primaryColor]}
          textColor={textColor}
          value={allContracts}
          labels={['Alle Verträge im Rechnungslauf']}
          withProgress={false}
        />
        <ProgressRingWithLabels
          isLoading={false}
          radius={42}
          stroke={6}
          progress={[100]}
          colors={[secondaryColor]}
          textColor={textColor}
          value={successfulContracts}
          labels={['Anzahl abgerechnete Verträge']}
          withProgress={false}
        />
        <ProgressRingWithLabels
          isLoading={false}
          radius={42}
          stroke={6}
          progress={[100]}
          colors={[tertiaryColor]}
          textColor={textColor}
          value={failedContracts}
          labels={['Gesamtfehlerzahl']}
          withProgress={false}
        />
      </SummaryDetails>
      <BarsWrapper>
        <SubsectionTitle>Abrechnungsphasen</SubsectionTitle>
        <LegendWrap>
          <LegendItem color="#7795F4">Korrekt</LegendItem>
          <LegendItem color="#13238E">Fehlerhaft</LegendItem>
          <LegendItem color="#ACACAC">Eingangsrechnungen</LegendItem>
        </LegendWrap>
        {rows}
      </BarsWrapper>
      <Margin>
        <InvoiceDraftsContainer
          accountingType={context.payload.accountingType}
          accountingName={context.createAccounting.name}
          uuid={context.executionContext.session}
          paymentPeriodStart={context.payload?.paymentPeriodStartAt}
          paymentPeriodEnd={context.payload?.paymentPeriodEndAt}
          invoiceCycle={InvoiceCycle.Yearly}
        />
      </Margin>
    </>
  );
}

export default WorkflowSummary;
