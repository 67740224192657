import {
  Button,
  Flex,
  FlexRow,
  IFrameModal,
  Link,
  SingleDocument,
} from '@ampeersenergy/ampeers-ui-components';
import React, { useCallback, useEffect } from 'react';
import ContentLoader from 'react-content-loader';
import styled from 'styled-components';

import {
  GetSupplierChangeWorkflowStateDocument,
  ReadContractQuery,
  StepStatus,
  SupplierChangeWorkflowType,
  useReadContractQuery,
  useStartSupplierChangeTimerMutation,
} from '../../../graphql-types';
import { buildDocumentUrl } from '../../../helpers/buildDocumentUrl';

const CenteredRow = styled(FlexRow)`
  align-items: center;
`;

const DocumentWrapper = styled.div`
  border: 1px solid ${(props) => props.theme.palette.border};
  border-radius: 4px;
  padding: 0px 17px;
  border-radius: 4px;
  margin-top: 15px;
  margin-bottom: 15px;
`;

const LinkStyled = styled(Link)`
  padding: 6px 20px 0px 0px;
  font-size: 12pt;
`;

const DocumentLoadingStyle = styled.div`
  margin-top: 10px;
`;

const contentLoaderProps = {
  speed: 2,
  backgroundColor: '#fafafa',
  foregroundColor: '#ecebeb',
  style: { width: '100%', marginTop: '6px' },
  height: 14,
};

const pollInterval = 15000;

type ContractDocument = NonNullable<
  NonNullable<ReadContractQuery['readContract']>['documents']
>[0];

interface SendDocumentFormProps {
  state: StepStatus;
  workflowId: string;
  document: ContractDocument;
  type?: SupplierChangeWorkflowType;
  email?: string | null;
}

type ViewDocument = { fileURL: string; type: string } | null;

export enum DocumentTypes {
  Cancellation = 'previous_provider_termination',
  SupplierChangeLocalSupply = 'registration_ultimate_consumer',
  SupplierChangeThirdParty = 'registration_malo',
}

type DocumentAttributes = {
  type:
    | DocumentTypes.Cancellation
    | DocumentTypes.SupplierChangeLocalSupply
    | DocumentTypes.SupplierChangeThirdParty;
  contentType: string;
  label: string;
  message: string;
};

function getDocumentAttributesByType(
  type?: SupplierChangeWorkflowType,
): DocumentAttributes | null {
  switch (type) {
    case SupplierChangeWorkflowType.Cancellation: {
      return {
        type: DocumentTypes.Cancellation,
        contentType: 'application/pdf',
        label: 'Kündigungsschreiben beim alten Lieferanten',
        message:
          'Der alte Lieferant hat dann 7 Werktage Zeit um die Kündigung vorzunehmen.',
      };
    }
    case SupplierChangeWorkflowType.SupplierChangeThirdParty: {
      return {
        type: DocumentTypes.SupplierChangeThirdParty,
        contentType:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        label: 'Anmeldung Marktlokation',
        message:
          'Der neue Lieferant hat dann 11 Werktage Zeit um die Anmeldung vorzunehmen.',
      };
    }
    case SupplierChangeWorkflowType.SupplierChangeLocalSupply:
    default: {
      return {
        type: DocumentTypes.SupplierChangeLocalSupply,
        contentType:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        label: 'Abmeldung Marktlokation',
        message:
          'Der Netzbetreiber hat dann 10 Werktage Zeit um die Abmeldung vorzunehmen.',
      };
    }
  }
}

export function SendDocument({
  workflowId,
  contractId,
  state,
  type,
  email,
}: {
  workflowId: string;
  contractId: string;
  state: StepStatus;
  type?: SupplierChangeWorkflowType;
  email?: string | null;
}) {
  const {
    data: contractData,
    startPolling,
    stopPolling,
  } = useReadContractQuery({
    variables: { contractId },
  });

  const documentAttributes = getDocumentAttributesByType(type);

  startPolling(pollInterval);

  const document: ContractDocument | undefined =
    contractData?.readContract?.documents?.find(
      (_document) => _document.type === documentAttributes?.type,
    );

  useEffect(() => {
    if (document) {
      stopPolling();
    }

    return () => {
      stopPolling();
    };
  }, [document, stopPolling]);

  return state === StepStatus.Active || !document ? (
    <DocumentLoading />
  ) : (
    <SendDocumentForm
      state={state}
      workflowId={workflowId}
      document={document}
      email={email}
      type={type}
    />
  );
}

function SendDocumentForm({
  state,
  workflowId,
  document,
  type,
  email,
}: SendDocumentFormProps) {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [viewDocument, setViewDocument] = React.useState<ViewDocument>(null);

  const documentAttributes = getDocumentAttributesByType(type);

  const [startWorkflowTimer] = useStartSupplierChangeTimerMutation();

  const onClick = useCallback(
    async (status: StepStatus) => {
      if (status === StepStatus.WaitingForUser) {
        setIsSubmitting(true);
        await startWorkflowTimer({
          variables: {
            workflowId,
          },
          refetchQueries: [
            {
              query: GetSupplierChangeWorkflowStateDocument,
              variables: { workflowId },
            },
          ],
        });
      }
    },
    [workflowId, startWorkflowTimer],
  );

  useEffect(() => {
    if (state === StepStatus.Failed || state === StepStatus.Succeeded) {
      setIsSubmitting(false);
    }
  }, [state]);

  const onOpen = useCallback(
    (fileURL: string, _type: string) => {
      const url = new URL(fileURL);
      url.searchParams.set('attachment', 'false');
      setViewDocument({ fileURL: url.toString(), type: _type });
    },
    [setViewDocument],
  );

  return (
    <>
      {state !== StepStatus.Failed && !!document && (
        <DocumentInfo onOpen={onOpen} document={document} type={type} />
      )}
      {email && (
        <>
          Die Adresse lautet:
          <CenteredRow>
            <Flex>
              <LinkStyled
                onClick={() => {
                  window.open(`mailto:${email}`);
                }}
              >
                {email}
              </LinkStyled>
            </Flex>
            <div>
              <Button
                small
                secondary
                onClick={() => navigator.clipboard.writeText(email)}
              >
                Kopieren
              </Button>
            </div>
          </CenteredRow>
        </>
      )}
      <p>
        Markiere den Schritt als Erledigt sobald Du das Dokument versendet hast.{' '}
        {documentAttributes?.message}
      </p>
      {(state === StepStatus.WaitingForUser || state === StepStatus.Failed) && (
        <Button isLoading={isSubmitting} onClick={() => onClick(state)}>
          {state === StepStatus.Failed ? 'Wiederholen' : 'Erledigt'}
        </Button>
      )}
      {viewDocument !== null && (
        <IFrameModal
          src={viewDocument.fileURL}
          contentLabel={`Show ${viewDocument.type}`}
          closeModal={() => setViewDocument(null)}
          title={viewDocument.type}
        />
      )}
    </>
  );
}

function DocumentInfo({
  onOpen,
  document,
  type,
}: {
  onOpen: (fileURL: string, type: string) => void;
  document: ContractDocument;
  type?: SupplierChangeWorkflowType;
}) {
  const documentAttributes = getDocumentAttributesByType(type);

  const contentType =
    document.contentType ??
    documentAttributes?.contentType ??
    'application/pdf';
  const previewEnabled = contentType === 'application/pdf';
  return (
    <>
      <div>
        Verschicke das folgende Dokument per E-Mail an den aufgeführten
        Adressaten:
      </div>
      <DocumentWrapper>
        <SingleDocument
          onOpen={onOpen}
          type={documentAttributes?.label ?? 'Dokument'}
          document={{
            contentType,
            createdAt: document.createdAt,
            fileURL: buildDocumentUrl(document.fileURL),
            fileName: document.fileName ?? undefined,
            deletable: false,
          }}
          previewEnabled={previewEnabled}
        />
      </DocumentWrapper>
    </>
  );
}

function DocumentLoading() {
  return (
    <DocumentLoadingStyle>
      <ContentLoader {...contentLoaderProps}>
        <rect x="0" y="0" rx="3" ry="3" width="100%" height="100%" />
      </ContentLoader>
      <ContentLoader {...contentLoaderProps}>
        <rect x="0" y="0" rx="3" ry="3" width="30%" height="100%" />
      </ContentLoader>
      <ContentLoader
        {...contentLoaderProps}
        height={100}
        style={{
          ...contentLoaderProps.style,
          marginTop: '20px',
          marginBottom: '20px',
        }}
      >
        <rect x="0" y="0" rx="3" ry="3" width="100%" height="100%" />
      </ContentLoader>
      <ContentLoader {...contentLoaderProps}>
        <rect x="0" y="0" rx="3" ry="3" width="100%" height="100%" />
      </ContentLoader>
      <ContentLoader {...contentLoaderProps}>
        <rect x="0" y="0" rx="3" ry="3" width="10%" height="100%" />
      </ContentLoader>
    </DocumentLoadingStyle>
  );
}
