import React from 'react';
import styled from 'styled-components';
import { Icons } from '@ampeersenergy/ampeers-ui-components';

export const ResultWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  margin-top: -10px;

  p {
    width: 60%;
    margin-bottom: 0;
    margin-top: 10px;
    font-size: 15px;
    line-height: 15px;
    text-align: center;
  }
`;

export interface ResultStepProps {
  label: string;
}

export const ResultStep: React.FC<ResultStepProps> = ({ label }) => {
  return (
    <ResultWrapper>
      <Icons.Checkmark size={80} color="#A8E015" />
      <p>
        {label} erfolgreich erstellt und kann im Bereich {label} heruntergeladen
        werden.
      </p>
    </ResultWrapper>
  );
};
