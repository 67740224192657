import * as React from 'react';
import styled from 'styled-components/macro';
import { useFormikContext } from 'formik';
import get from 'lodash/get';
import { DateTime } from 'luxon';
import { Label as DefaultLabel } from '@ampeersenergy/ampeers-ui-components';

import InputSuggestion from '../../../components/inputSuggestion';
import { InvoiceCycle, useReadTariffQuery } from '../../../graphql-types';
import { deductionCalculation } from '../../../helpers/calculations';
import { formatCurrency } from '../../../helpers/formatStrings';
import {
  GraphqlFormField,
  GraphqlFormInputGroup,
  GraphqlFormSelect,
} from '../../../components/graphql-form/render';
import OptionalField, {
  OptionalFieldStyling,
} from '../../../components/optionalField';
import { useHasRole } from '../../../components/useHasRole';
import { StyledLabel } from '../sharedForms';
import { SetStateAction } from '../../../helpers/stateHelpers';
import { determineInitialConsumptionEndDate } from '../../../helpers/dateHelpers';
import { TooltipInfo } from '../../../components/TooltipInfo';
import { addLeadingZero } from '../../../helpers/numberHelper';

import type { FormPartProps } from './sharedFormParts';
import { BankingDetailsForm } from './BankingDetailsForm';

const Label = styled(DefaultLabel)`
  margin-top: 2rem;
`;

const IndentedSection = styled.div`
  margin-top: 10px;
  padding-left: 10px;
`;

const SepaFormStyling = styled.div`
  margin-top: 10px;
  padding-left: 10px;
`;

export function PaymentForm({
  fieldNamePrefix,
  selfPayerState,
  consumptionFirstOptionSelectionState,
  shouldResetFieldState = [false, () => {}],
}: {
  selfPayerState: SetStateAction<boolean>;
  consumptionFirstOptionSelectionState?: SetStateAction<boolean>;
  shouldResetFieldState?: SetStateAction<boolean>;
} & FormPartProps) {
  const [isSelfPayer, setIsSelfPayer] = selfPayerState;
  const [shouldResetField] = shouldResetFieldState;
  const previousDisabledAnnualConsumption = React.useRef(true);
  const previousInvoiceCycle = React.useRef<string | undefined>();
  const { initialValues, getFieldProps, setFieldValue } = useFormikContext();

  const [acceptedSuggestion, setAcceptedSuggestion] = React.useState(false);

  const formTariffId = getFieldProps('tariff.id').value;
  const { data: dataTariff } = useReadTariffQuery({
    variables: {
      tariffId: formTariffId,
    },
    skip: !formTariffId,
  });

  const { hasRole: invoiceCycleEnabled } = useHasRole('feature_invoice_cycle');

  React.useEffect(() => {
    if (
      isSelfPayer === false &&
      getFieldProps('customer.hasSepa').value !== true
    ) {
      setFieldValue('customer.hasSepa', true);
    } else if (
      isSelfPayer === true &&
      getFieldProps('customer.hasSepa').value !== false
    ) {
      setFieldValue('customer.hasSepa', false, true);
      setFieldValue('customer.bankAccount.bic', '', true);
      setFieldValue('customer.bankAccount.iban', '', true);
      setFieldValue('customer.bankAccount.mandateReference', '', true);
      setFieldValue('customer.bankAccount.name', '', true);
      setFieldValue('customer.bankAccount.signedAt', '', true);
    }
  }, [isSelfPayer, setFieldValue, getFieldProps]);

  const contractStartDate = React.useMemo(() => {
    if (getFieldProps('startDate').value) {
      return getFieldProps('startDate').value;
    }
    return undefined;
  }, [getFieldProps]);

  React.useEffect(() => {
    const downPaymentStartDate = getFieldProps('downPaymentStartDate').value;
    if (contractStartDate && !downPaymentStartDate) {
      setFieldValue('downPaymentStartDate', contractStartDate, true);
    }
  }, [getFieldProps, setFieldValue, contractStartDate]);

  const resetSettlementAndDownpaymentFields = React.useCallback(
    ({
      resetInitialValues = true,
      revalidateFields = true,
      skipDownpayment = false,
    }: {
      resetInitialValues?: boolean;
      revalidateFields?: boolean;
      skipDownpayment?: boolean;
    }) => {
      const fieldValues = ['settlementDay', 'settlementMonth', 'paymentDate'];

      if (skipDownpayment) {
        fieldValues.concat(['downPayment', 'downPaymentStartDate']);
      }

      fieldValues.forEach((property) => {
        setFieldValue(
          property,
          resetInitialValues ? get(initialValues, property) : '',
          revalidateFields,
        );
      });
    },
    [initialValues, setFieldValue],
  );

  React.useEffect(() => {
    // Check if the following properties are equal to the initial values
    const properties = [
      'downPayment',
      'downPaymentStartDate',
      'settlementDay',
      'settlementMonth',
      'customer.bankAccount.bic',
      'customer.bankAccount.iban',
      'customer.bankAccount.mandateReference',
      'customer.bankAccount.name',
      'customer.bankAccount.signedAt',
      'customer.hasSepa',
      'paymentDate',
    ];
    const isResetted = properties.every(
      (property) =>
        getFieldProps(property).value === get(initialValues, property),
    );

    if (!isResetted && shouldResetField) {
      setIsSelfPayer(true);

      // Reset the following properties
      resetSettlementAndDownpaymentFields({
        resetInitialValues: true,
        revalidateFields: true,
      });
    }
  }, [
    shouldResetField,
    setIsSelfPayer,
    isSelfPayer,
    initialValues,
    getFieldProps,
    resetSettlementAndDownpaymentFields,
  ]);

  const isDeductionCalculatable = React.useMemo(() => {
    const initialValue = getFieldProps('consumptions.initial.value').value;
    const downPaymentStartDate = getFieldProps('downPaymentStartDate').value;
    const tariffId = getFieldProps('tariff.id').value;
    const basicPrice = dataTariff?.readTariff.priceSheets[0].basicPrice;
    const energyPrice = dataTariff?.readTariff.priceSheets[0].energyPrice;

    return (
      initialValue > 0 &&
      contractStartDate &&
      downPaymentStartDate &&
      tariffId &&
      basicPrice &&
      energyPrice &&
      DateTime.fromISO(downPaymentStartDate).year ===
        DateTime.fromISO(contractStartDate).year
    );
  }, [dataTariff?.readTariff.priceSheets, getFieldProps, contractStartDate]);

  const deduction = React.useMemo(() => {
    if (
      !isDeductionCalculatable ||
      !dataTariff?.readTariff ||
      dataTariff?.readTariff.priceSheets[0].energyPrice === null ||
      dataTariff?.readTariff.priceSheets[0].energyPrice === undefined
    ) {
      return undefined;
    }
    const expectedConsumption = getFieldProps(
      'consumptions.initial.value',
    ).value;
    const reductionStartDate = getFieldProps('downPaymentStartDate').value;
    const basicPrice = dataTariff?.readTariff.priceSheets[0].basicPrice;
    const energyPrice = dataTariff?.readTariff.priceSheets[0].energyPrice;

    return deductionCalculation({
      expectedConsumption,
      contractStartDate,
      reductionStartDate,
      basicPrice,
      energyPrice,
    });
  }, [isDeductionCalculatable, dataTariff, getFieldProps, contractStartDate]);

  const onAcceptDeduction = React.useCallback(() => {
    setFieldValue('downPayment', deduction, true);
    setAcceptedSuggestion(true);
  }, [setFieldValue, deduction]);

  const invoiceCycle: InvoiceCycle = React.useMemo(
    () => getFieldProps(`${fieldNamePrefix}invoiceCycle`).value,
    [getFieldProps, fieldNamePrefix],
  );

  React.useEffect(() => {
    if (consumptionFirstOptionSelectionState) {
      const [isDisabled] = consumptionFirstOptionSelectionState;

      if (
        previousDisabledAnnualConsumption.current !== isDisabled ||
        previousInvoiceCycle.current !== invoiceCycle
      ) {
        if (!isDisabled) {
          previousDisabledAnnualConsumption.current = false;
          const startDate = getFieldProps(`${fieldNamePrefix}startDate`).value;

          const endDate = determineInitialConsumptionEndDate(
            DateTime.fromISO(startDate),
            invoiceCycle,
          );

          if (endDate) {
            setFieldValue(
              `${fieldNamePrefix}consumptions.initial.startDate`,
              startDate,
            );
            setFieldValue(
              `${fieldNamePrefix}consumptions.initial.endDate`,
              endDate?.toISODate(),
            );
          }
          previousInvoiceCycle.current = invoiceCycle;
        } else {
          previousDisabledAnnualConsumption.current = true;
          setFieldValue(`${fieldNamePrefix}consumptions.initial.startDate`, '');
          setFieldValue(`${fieldNamePrefix}consumptions.initial.endDate`, '');
          setFieldValue(
            `${fieldNamePrefix}consumptions.initial.value`,
            undefined,
          );
        }
      }
    }
  }, [
    consumptionFirstOptionSelectionState,
    fieldNamePrefix,
    setFieldValue,
    getFieldProps,
    previousDisabledAnnualConsumption,
    invoiceCycle,
  ]);

  React.useEffect(() => {
    if (invoiceCycle === InvoiceCycle.Monthly) {
      resetSettlementAndDownpaymentFields({
        resetInitialValues: false,
        skipDownpayment: true,
        revalidateFields: false,
      });
    } else if (
      [InvoiceCycle.Quarterly, InvoiceCycle.HalfYearly].includes(invoiceCycle)
    ) {
      resetSettlementAndDownpaymentFields({
        resetInitialValues: false,
        revalidateFields: false,
      });
    } else if (invoiceCycle === InvoiceCycle.Yearly) {
      resetSettlementAndDownpaymentFields({
        revalidateFields: false,
      });
      setFieldValue(`${fieldNamePrefix}settlementDay`, '31');
      setFieldValue(`${fieldNamePrefix}settlementMonth`, '12');
    }
  }, [
    invoiceCycle,
    resetSettlementAndDownpaymentFields,
    setFieldValue,
    fieldNamePrefix,
  ]);

  return (
    <>
      {/* 1. Zahlungsweise  */}
      <Label>Zahlungsweise</Label>
      <input
        type="radio"
        data-testid="self-payer"
        id="self-payer"
        checked={isSelfPayer}
        onClick={() => {
          setIsSelfPayer(true);
        }}
        readOnly
      />
      <StyledLabel htmlFor="self-payer">
        Selbstzahler
        <TooltipInfo
          id="selfPayerToolTip"
          text="Für Selbstzahler können die Zahlungsinformationen (Konto-Inhaber, IBAN, BIC) als Information festgehalten werden. Bei der Vertragsanlage wird die BIC automatisch als Vorschlag generiert."
        />
      </StyledLabel>
      <br />
      <input
        type="radio"
        data-testid="sepa-payment"
        id="sepa-payment"
        checked={!isSelfPayer}
        onClick={() => {
          setIsSelfPayer(false);
        }}
        readOnly
      />
      <StyledLabel htmlFor="sepa-payment">
        SEPA-Bankeinzug
        <TooltipInfo
          id="sepaPaymentToolTip"
          text="Für SEPA-Kunden müssen Konto-Inhaber, IBAN, BIC, Mandatsreferenznummer und Unterschriftsdatum hinterlegt werden. Bei der Vertragsanlage wird die BIC automatisch als Vorschlag generiert."
        />
      </StyledLabel>
      <br />
      <SepaFormStyling>
        <BankingDetailsForm
          fieldNamePrefix={`${fieldNamePrefix}customer`}
          isSelfPayer={isSelfPayer}
        />
      </SepaFormStyling>
      {/* 2. Abrechnungsturnus */}
      {invoiceCycleEnabled ? (
        <>
          <br />
          <GraphqlFormField name={`${fieldNamePrefix}invoiceCycle`} />
        </>
      ) : null}
      {/* 3. Angenommener Jahresverbrauch */}
      {consumptionFirstOptionSelectionState && (
        <YearConsumptionForm
          fieldNamePrefix={fieldNamePrefix}
          consumptionFirstOptionSelectionState={
            consumptionFirstOptionSelectionState
          }
        />
      )}
      {/* 4. Abschlagszahlung */}
      {invoiceCycle !== InvoiceCycle.Monthly && (
        <>
          <Label>
            Abschlagszahlung
            <TooltipInfo
              id="downPaymentToolTip"
              text="Es können sowohl die Höhe als auch der Abschlagsbeginn gepflegt werden. Der Abschlagsbeginn definiert, wann der Abschlag erstmalig eingezogen wird. Das Datum darf nicht vor Lieferbeginn liegen und ist nur anzugeben, wenn es von diesem abweicht. Bei einem untermonatlichen Startdatum wir der Abschlag nur anteilig gebucht. Wenn der angenommene Jahresverbrauch und der Tarif angegeben sind, wird automatisch ein Vorschlag für die Abschlagshöhe generiert."
            />
          </Label>
          <IndentedSection>
            <GraphqlFormField
              name={`${fieldNamePrefix}downPaymentStartDate`}
              label="Beginn Abschlagszahlung"
              warning="Hinweis: Definiert, wann der Abschlag erstmalig eingezogen wird. Datum darf nicht vor Vertragsbeginn liegen und ist nur anzugeben, wenn abweichend von diesem. Bei einem untermonatlichen Startdatum wird der Abschlag nur anteilig gebucht."
            />
            <GraphqlFormField
              name={`${fieldNamePrefix}downPayment`}
              label="Abschlagshöhe (Brutto)"
            />
            {isDeductionCalculatable && !acceptedSuggestion && (
              <div>
                <InputSuggestion
                  message="Vorgeschlagener Abschlag:"
                  value={formatCurrency(deduction)}
                  onAccept={onAcceptDeduction}
                />
              </div>
            )}
          </IndentedSection>
        </>
      )}
      {/* 5. Abrechnungsstichtag */}
      {invoiceCycle === InvoiceCycle.Yearly ? (
        <>
          <Label>
            Abrechnungsstichtag
            <TooltipInfo
              id="settlementToolTip"
              text="Zum Abrechnungsstichtag werden Verträge turnusmäßig abgerechnet."
            />
          </Label>
          <GraphqlFormInputGroup>
            <GraphqlFormSelect
              name={`${fieldNamePrefix}settlementDay`}
              data-testid={`${fieldNamePrefix}settlementDay`}
              hint="Tag"
            >
              <option disabled hidden value="" key="">
                Bitte wählen
              </option>
              {new Array(31)
                .fill(null)
                .map((_, index) => (
                  <option value={addLeadingZero(index + 1)} key={index}>
                    {index + 1}
                  </option>
                ))
                .reverse()}
            </GraphqlFormSelect>
            <GraphqlFormSelect
              name={`${fieldNamePrefix}settlementMonth`}
              data-testid={`${fieldNamePrefix}settlementMonth`}
              hint="Monat"
            >
              <option disabled hidden value="" key="">
                Bitte wählen
              </option>
              {new Array(12)
                .fill(null)
                .map((_, index) => (
                  <option value={addLeadingZero(index + 1)} key={index}>
                    {index + 1}
                  </option>
                ))
                .reverse()}
            </GraphqlFormSelect>
          </GraphqlFormInputGroup>

          <DefaultLabel>
            Zahlungstermin
            <TooltipInfo
              id="paymentDateToolTip"
              text="Der Zahlungstermin ist standardmäßig der erste des Monats und kann abweichend definiert werden, wenn die Sollstellung durch AE LOCAL SUPPLY erfolgt."
            />
          </DefaultLabel>
          <GraphqlFormInputGroup>
            <GraphqlFormSelect
              name={`${fieldNamePrefix}paymentDate`}
              data-testid={`${fieldNamePrefix}paymentDate`}
              hint="Tag"
            >
              <option disabled hidden value="" key="">
                Bitte wählen
              </option>
              {new Array(31).fill(null).map((_, index) => (
                <option value={index + 1} key={index}>
                  {index + 1}
                </option>
              ))}
            </GraphqlFormSelect>
          </GraphqlFormInputGroup>
        </>
      ) : null}
    </>
  );
}

export function YearConsumptionForm({
  fieldNamePrefix,
  consumptionFirstOptionSelectionState,
}: FormPartProps & { consumptionFirstOptionSelectionState: any }) {
  const [isDisabled] = consumptionFirstOptionSelectionState;

  // TODO: Handle consumptions when we know where to store AJV
  return (
    <OptionalField
      label="Angenommener Jahresverbrauch"
      options={['Nein', 'Ja']}
      test-id="consumption"
      managedState={consumptionFirstOptionSelectionState}
      tooltipText="Der angenommene Jahresverbrauch und die Gültigkeit ermöglichen eine Validierung des Verbrauchs für die Abrechnung. Außerdem ist dieser Berechnungsgrundlage für die anfängliche Abschlagszahlung."
    >
      <OptionalFieldStyling>
        {!isDisabled && (
          <>
            <GraphqlFormField
              name={`${fieldNamePrefix}consumptions.initial.startDate`}
            />
            <GraphqlFormField
              name={`${fieldNamePrefix}consumptions.initial.endDate`}
            />
            <GraphqlFormField
              name={`${fieldNamePrefix}consumptions.initial.value`}
            />
          </>
        )}
      </OptionalFieldStyling>
    </OptionalField>
  );
}
