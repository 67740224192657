/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/default-param-last */
import { combineEpics, createEpicMiddleware, ofType } from 'redux-observable';
import { map, switchMap, mergeMap, withLatestFrom } from 'rxjs/operators';
import { from, Observable } from 'rxjs';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import { CRM_DATA_MOCK } from '../../mock';
import { client as APOLLO_CLIENT } from '../../../../apollo-client';
import { transformService } from '../../services/transform.service';
import {
  UpdateLaneForCardDocument,
  GetInitialBoardDocument,
  UpdateCardDocument,
  CreateCardDocument,
  AssignCardToLaneDocument,
  DeleteCrmCardDocument,
  RemoveDocumentsDocument,
  ReadDocumentsQuery,
  ReadDocumentsDocument,
} from '../../../../graphql-types';
import { getSession } from '../../../../services/session';

import { CrmBoardCardType } from './crm-board.types';

// import { UpdateCardDocument } from "../../../api/mutations/update-card.mutation";
// import { CreateCardDocument } from "../../../api/mutations/create-card.mutation";
// import { AssignCardToLaneDocument } from "../../../api/mutations/assign-card-to-lane.mutation";
// import { UpdateLaneForCardDocument } from "../../../api/mutations/update-lane-for-card.mutation";
// import { GetInitialBoardDocument } from "../../../api/queries/get-initial-board/get-initial-board.query";

type Action<TPayload = any> = {
  payload?: TPayload;
  type:
    | 'ADD_CARD'
    | 'EDIT_CARD'
    | 'MOVE_CARD_TO_COLUMN'
    | 'DELETE_CARD'
    | 'MOVE_CARD_IN_COLUMN'
    | 'GET_BOARD'
    | 'BOARD_DOCUMENT';
};

const changeCardPositionEpic = (action$: Observable<Action>) =>
  action$.pipe(
    ofType('MOVE_CARD_TO_COLUMN', 'MOVE_CARD_IN_COLUMN'),
    switchMap(({ payload }) => {
      const { card, toColumnId, toPosition } = payload;
      return from(
        APOLLO_CLIENT.mutate({
          mutation: UpdateLaneForCardDocument,
          variables: {
            cardId: card.id,
            patch: {
              idCard: card.id,
              idLanes: toColumnId,
              // DB has no nullPosition...
              position: toPosition + 1,
            },
          },
        }),
      );
    }),
    // @ts-ignore that we are doing a lazy update
    map(() => ({ type: 'NOOP' })),
  );

const initBoardEpic = (action$: Observable<Action>) =>
  action$.pipe(
    ofType('GET_BOARD'),
    switchMap(() => {
      return from(
        APOLLO_CLIENT.query({
          query: GetInitialBoardDocument,
          fetchPolicy: 'no-cache',
          variables: {
            clientId: getSession().clientId,
          },
        }),
      );
    }),
    map((queryResponse) => {
      console.log('Got response', queryResponse);
      // we need to transform it and issue a new board
      const board = transformService.transformInitialBoardData(
        queryResponse.data,
      );
      return {
        type: 'BOARD_DOCUMENT',
        payload: {
          board,
        },
      };
    }),
  );

const updateCardEpic = (action$: Observable<Action>) =>
  action$.pipe(
    ofType('EDIT_CARD'),
    switchMap(({ payload }) => {
      const { id, ...patch } = transformService.convertBoardCardToCard(
        payload.card,
      );
      console.log('Patch', patch);
      return from(
        APOLLO_CLIENT.mutate({
          mutation: UpdateCardDocument,
          variables: {
            id,
            patch,
          },
        }),
      );
    }),
    // @ts-ignore
    map(() => ({ type: 'NOOP' })),
  );

const deleteCardEpic = (action$: Observable<Action>) =>
  action$.pipe(
    ofType('DELETE_CARD'),
    switchMap(async ({ payload }) => {
      const { data: documentData } =
        await APOLLO_CLIENT.query<ReadDocumentsQuery>({
          query: ReadDocumentsDocument,
          variables: {
            entityId: payload.id,
            entityType: 'crmLead',
            groupId: 'contract_draft_letter',
          },
        });
      if (documentData?.readDocuments?.length === 1) {
        APOLLO_CLIENT.mutate({
          mutation: RemoveDocumentsDocument,
          variables: {
            hash: documentData?.readDocuments[0].hash,
          },
        });
      }
      return from(
        APOLLO_CLIENT.mutate({
          mutation: DeleteCrmCardDocument,
          variables: {
            cardId: payload.id,
          },
        }),
      );
    }),
    // @ts-ignore
    map(() => ({ type: 'NOOP' })),
  );

const addCardEpic = (action$: Observable<Action>) =>
  action$.pipe(
    ofType('ADD_CARD'),
    mergeMap(({ payload }) => {
      const { id, ...card } = transformService.convertBoardCardToCard(
        payload.card,
      );
      console.log('Card?', card);
      return from(
        APOLLO_CLIENT.mutate({
          mutation: CreateCardDocument,
          variables: {
            input: {
              card,
            },
          },
        }),
      );
    }),
    withLatestFrom(action$),
    map(([createdCard, { payload }]) => {
      const { laneId } = payload;

      if (laneId === null || laneId === undefined) {
        throw new Error('laneId is required');
      }
      // @ts-ignore extract card if from createdCard
      const { id } = createdCard.data.createCard.card;
      return from(
        APOLLO_CLIENT.mutate({
          mutation: AssignCardToLaneDocument,
          variables: {
            input: {
              laneCardRel: {
                idCard: id,
                idLanes: laneId,
                position: 1, // new cards are always assigned to the first lane
              },
            },
          },
        }),
      );
    }),
    // we are lazy and simply get the whole new board...
    map(() => ({ type: 'GET_BOARD' })),
  );

const allMyEpics = combineEpics(
  changeCardPositionEpic,
  initBoardEpic,
  updateCardEpic,
  addCardEpic,
  deleteCardEpic,
);

const observableMiddleware = createEpicMiddleware();

const INITIAL_BOARD = CRM_DATA_MOCK;

export const boardStore = createStore(
  // @ts-ignore
  boardReducer,
  { board: INITIAL_BOARD, isInitialized: false },
  composeWithDevTools(applyMiddleware(observableMiddleware)),
);

// @ts-ignore
observableMiddleware.run(allMyEpics);

// Sample Redux reducer
function boardReducer(
  state = { board: INITIAL_BOARD, isInitialized: false },
  action: Action,
) {
  switch (action.type) {
    case 'MOVE_CARD_IN_COLUMN': {
      const { toColumnId, fromPosition, toPosition } = action.payload;
      const board = { ...state.board };
      // @ts-ignore
      const columnToBeChanged = board.columns.find(
        ({ id }) => id === toColumnId,
      );
      // -> https://stackoverflow.com/questions/5306680/move-an-array-element-from-one-array-position-to-another
      // @ts-ignore
      columnToBeChanged.cards.splice(
        toPosition,
        0,
        columnToBeChanged.cards.splice(fromPosition, 1)[0],
      );

      return { ...state, board };
    }

    case 'MOVE_CARD_TO_COLUMN': {
      const { fromColumnId, toColumnId, toPosition, card } = action.payload;
      const newBoard = { ...state.board };

      // update the column id in the card
      card.columnId = toColumnId;
      // delete card
      // @ts-ignore
      const columnCardNeedsToBeRemoved = newBoard.columns.find(
        ({ id }) => id === fromColumnId,
      );
      // @ts-ignore
      columnCardNeedsToBeRemoved!.cards =
        columnCardNeedsToBeRemoved!.cards.filter(
          ({ id: cardId }: { id: string }) => cardId !== card.id,
        );
      // add it
      // @ts-ignore
      const columnCardNeedsToBeAdded = newBoard.columns.find(
        ({ id }) => id === toColumnId,
      );
      // in the new column, simply insert it at the position
      columnCardNeedsToBeAdded!.cards = [
        ...columnCardNeedsToBeAdded!.cards.slice(0, toPosition),
        card,
        ...columnCardNeedsToBeAdded!.cards.slice(toPosition),
      ];
      return { ...state, board: newBoard };
    }

    case 'DELETE_CARD': {
      const card = action.payload as CrmBoardCardType;
      const board = { ...state.board };
      // find affected column
      // @ts-ignore
      const columnIndex = board.columns.findIndex(
        ({ id }) => id === card.columnId,
      );
      // find affected card
      // @ts-ignore
      const cardIndex = board.columns[columnIndex].cards.findIndex(
        ({ id }: { id: string }) => id === card.id,
      );
      // delete the affected card
      board.columns[columnIndex].cards.splice(cardIndex, 1);
      // @ts-ignore
      return { ...state, board };
    }

    case 'EDIT_CARD': {
      const { card } = action.payload as { card: CrmBoardCardType };
      const board = { ...state.board };
      // find affected column
      // @ts-ignore
      const columnIndex = board.columns.findIndex(
        ({ id }) => id === card.columnId,
      );
      // find affected card
      // @ts-ignore
      const cardIndex = board.columns[columnIndex].cards.findIndex(
        ({ id }: any) => id === card.id,
      );
      console.log(columnIndex, cardIndex);
      // update the affected card
      board.columns[columnIndex].cards[cardIndex] = card;

      // @ts-ignore
      return { ...state, board };
    }

    case 'BOARD_DOCUMENT': {
      const { board } = action.payload;
      return { ...state, board, isInitialized: true };
    }

    // ADD_CARD is taken care of in an epic and is NOT optimistic
    case 'ADD_CARD':
    default:
      return state;
  }
}
