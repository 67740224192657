import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Button } from '@ampeersenergy/ampeers-ui-components';

import Stepper from './stepper/stepper';
import { FlexRow } from './layout';
import { FormikSubmit } from './input';

interface FormFlowProps {
  steps: FormFlowStep[];
  onPrevStep: () => void;
  stepIndex: number;
}

export interface FormFlowStep {
  title: string;
  content: React.ReactNode;
}

const Wrapper = styled.div``;

const Status = styled(FlexRow)`
  align-items: center;
`;

const Content = styled.div``;

function FormFlow({ steps, onPrevStep, stepIndex }: FormFlowProps) {
  const stepperSteps: any = useMemo(
    () =>
      steps.map((s: FormFlowStep) => ({
        label: s.title,
      })),
    [steps],
  );

  return (
    <Wrapper>
      <Status>
        <Button
          onClick={() => onPrevStep()}
          disabled={stepIndex === 0}
          secondary
        >
          {stepIndex === 0 ? 'Abbrechen' : 'Zurück'}
        </Button>
        <Stepper steps={stepperSteps} index={stepIndex} />
        <FormikSubmit
        // disabled={stepIndex === steps.length - 1}
        >
          {stepIndex === steps.length - 1 ? 'Speichern' : 'Weiter'}
        </FormikSubmit>
      </Status>
      <Content>{steps[stepIndex].content}</Content>
    </Wrapper>
  );
}

export default FormFlow;
