import {
  AlertInfo,
  Selectable,
  SelectableOption,
  Label,
} from '@ampeersenergy/ampeers-ui-components';
import 'styled-components/macro';
import { get } from 'lodash';
import { useFormikContext } from 'formik';
import * as React from 'react';

import { useGraphqlForm } from '../../../components/graphql-form/hooks/useGraphqlForm';
import { GraphqlFormField } from '../../../components/graphql-form/render';
import OptionalField, {
  OptionalFieldStyling,
} from '../../../components/optionalField';
import { CreateMeterForm } from '../../plant/meter/CreateMeterForm';
import { MeterItem } from '../../../components/form/relationSelects/meterSelect';
import { Hidden, StyledLabel } from '../sharedForms';
import { METER_OBIS_CHANNEL_SLP_BEZUG } from '../../../helpers/constants';
import { TooltipInfo } from '../../../components/TooltipInfo';

import { FormPartProps } from './sharedFormParts';

export enum SourceMeloMaloEnum {
  Autogenerated,
  Suggested,
  New,
}

type UseStateDispatch<T> = [T, React.Dispatch<React.SetStateAction<T>>];

const MeterForm = React.forwardRef(
  (
    {
      fieldNamePrefix,
      meloState,
      maloState,
      meterState,
      selectedMeterId,
      isNewMeterState,
      fixedOpenEndDateState,
    }: {
      meloState: UseStateDispatch<SourceMeloMaloEnum>;
      maloState: UseStateDispatch<SourceMeloMaloEnum>;
      meterState: UseStateDispatch<boolean>;
      selectedMeterId: UseStateDispatch<string | undefined>;
      isNewMeterState: UseStateDispatch<boolean>;
      fixedOpenEndDateState: any;
    } & FormPartProps,
    ref?: any,
  ) => {
    const [maloType, setMaloType] = maloState;
    const [meloType, setMeloType] = meloState;
    const [, setSelecetedMeterId] = selectedMeterId;
    const { formVariables } = useGraphqlForm();
    const { getFieldProps, setFieldValue, initialValues, status } =
      useFormikContext();

    const startDate = getFieldProps(`${fieldNamePrefix}startDate`).value;
    const endDate = getFieldProps(`${fieldNamePrefix}endDate`).value;
    const disableMeterField =
      !startDate ||
      (!endDate && fixedOpenEndDateState[0]) ||
      new Date(startDate).getTime() - new Date(endDate).getTime() >= 0;
    const [isNewMeter, setIsNewMeter] = isNewMeterState;

    const [suggestedMaloMelo, setSuggestedMaloMelo] = React.useState<
      { malo?: string; melo?: string } | undefined
    >();

    const [showMeterWarning, setShowMeterWarning] = React.useState(false);

    React.useEffect(() => {
      if (status) {
        setShowMeterWarning(
          Object.keys(status).every((key) => !key.includes('meter.')),
        );
      }
    }, [status]);

    React.useImperativeHandle(ref, () => ({
      getValue(path: string) {
        return getFieldProps(path).value;
      },
    }));

    React.useEffect(() => {
      if (!isNewMeter) {
        setFieldValue(
          `${fieldNamePrefix}contractMeter.meter.new.meterNumber`,
          '',
        );
        setFieldValue(
          `${fieldNamePrefix}contractMeter.meter.new.meterPlace`,
          '',
        );
        setFieldValue(
          `${fieldNamePrefix}contractMeter.meter.new.meterType`,
          '',
        );
        setFieldValue(
          `${fieldNamePrefix}contractMeter.meter.new.isInSecondaryCascade`,
          undefined,
        );
        setFieldValue(`${fieldNamePrefix}contractMeter.meter.new.metering`, '');
        setFieldValue(
          `${fieldNamePrefix}contractMeter.meter.new.converterFactor`,
          '',
        );
      } else {
        setFieldValue(`${fieldNamePrefix}contractMeter.meter.id`, '');
        setShowMeterWarning(false);
      }
    }, [fieldNamePrefix, isNewMeter, setFieldValue]);

    const changeNewMeter = React.useCallback(
      (value: any) => {
        const isNew = value === 'new';
        if (isNewMeter === isNew) {
          return;
        }

        if (isNew) {
          setFieldValue(
            `${fieldNamePrefix}contractMeter.meter.new.converterFactor`,
            get(
              initialValues,
              `${fieldNamePrefix}contractMeter.meter.new.converterFactor`,
              1,
            ),
            false,
          );
          setFieldValue(`${fieldNamePrefix}contractMeter.meter.id`, '', false);

          setSuggestedMaloMelo(undefined);
          setFieldValue(`${fieldNamePrefix}contractMeter.melo`, '', false);
          setFieldValue(`${fieldNamePrefix}contractMeter.malo`, '', false);
          setSelecetedMeterId(undefined);
        }

        setIsNewMeter(isNew);
      },
      [
        fieldNamePrefix,
        initialValues,
        isNewMeter,
        setFieldValue,
        setIsNewMeter,
        setSelecetedMeterId,
      ],
    );

    React.useEffect(() => {
      if (
        maloType === SourceMeloMaloEnum.Autogenerated &&
        getFieldProps(`${fieldNamePrefix}contractMeter.malo`).value !== ''
      ) {
        setFieldValue(`${fieldNamePrefix}contractMeter.malo`, '', true);
      }
      if (
        meloType === SourceMeloMaloEnum.Autogenerated &&
        getFieldProps(`${fieldNamePrefix}contractMeter.melo`).value !== ''
      ) {
        setFieldValue(`${fieldNamePrefix}contractMeter.melo`, '', true);
      }
      if (!meterState[0]) {
        setFieldValue(
          `${fieldNamePrefix}contractMeter.meterReading.reason`,
          'COS',
          false,
        );
        setFieldValue(
          `${fieldNamePrefix}contractMeter.meterReading.obis`,
          METER_OBIS_CHANNEL_SLP_BEZUG,
          false,
        );
        if (
          getFieldProps(`${fieldNamePrefix}contractMeter.meterReading.date`)
            .value === ''
        ) {
          const contractStartDate = getFieldProps(
            `${fieldNamePrefix}startDate`,
          ).value;
          setFieldValue(
            `${fieldNamePrefix}contractMeter.meterReading.date`,
            contractStartDate,
            false,
          );
        }
      } else {
        setFieldValue(
          `${fieldNamePrefix}contractMeter.meterReading.ignoreWarning`,
          '',
          false,
        );
      }
    }, [
      maloType,
      getFieldProps,
      meloType,
      setFieldValue,
      fieldNamePrefix,
      meterState,
    ]);

    React.useEffect(() => {
      if (
        suggestedMaloMelo?.malo &&
        maloType === SourceMeloMaloEnum.Suggested
      ) {
        setFieldValue(
          `${fieldNamePrefix}contractMeter.malo`,
          suggestedMaloMelo?.malo,
          false,
        );
      } else {
        setFieldValue(`${fieldNamePrefix}contractMeter.malo`, '');
      }
    }, [maloType, suggestedMaloMelo, fieldNamePrefix, setFieldValue]);

    React.useEffect(() => {
      if (
        suggestedMaloMelo?.melo &&
        meloType === SourceMeloMaloEnum.Suggested
      ) {
        setFieldValue(
          `${fieldNamePrefix}contractMeter.melo`,
          suggestedMaloMelo?.melo,
          false,
        );
      } else {
        setFieldValue(`${fieldNamePrefix}contractMeter.melo`, '');
      }
    }, [meloType, suggestedMaloMelo, fieldNamePrefix, setFieldValue]);

    const handleSelectMeter = (meter: MeterItem) => {
      setSelecetedMeterId(meter.id);
      const lastBind = meter.binds?.slice(-1)[0];
      if (lastBind && lastBind.malo && lastBind.melo) {
        setSuggestedMaloMelo({ malo: lastBind.malo, melo: lastBind.melo });
        setMaloType(SourceMeloMaloEnum.Suggested);
        setMeloType(SourceMeloMaloEnum.Suggested);
        setFieldValue(
          `${fieldNamePrefix}contractMeter.consumptionPointId`,
          lastBind.consumptionPointId,
        );
        setFieldValue(
          `${fieldNamePrefix}contractMeter.measuringPointId`,
          lastBind.measuringPointId,
        );
      } else {
        setSuggestedMaloMelo({ malo: '', melo: '' });
        setFieldValue(`${fieldNamePrefix}contractMeter.consumptionPointId`, '');
        setFieldValue(`${fieldNamePrefix}contractMeter.measuringPointId`, '');
        setMaloType(SourceMeloMaloEnum.Autogenerated);
        setMeloType(SourceMeloMaloEnum.Autogenerated);
      }
    };

    const contractStart = getFieldProps(`${fieldNamePrefix}startDate`).value;
    const contractEnd = getFieldProps(`${fieldNamePrefix}endDate`).value;

    return (
      <>
        <Selectable
          value={isNewMeter ? 'new' : 'existing'}
          onChange={changeNewMeter}
          css={`
            margin: 4rem 0 2rem 0;
          `}
        >
          <SelectableOption
            value="existing"
            content={
              <GraphqlFormField
                name={`${fieldNamePrefix}contractMeter.meter.id`}
                plantId={formVariables.plantId}
                boundStartAt={contractStart}
                boundEndAt={contractEnd || undefined}
                disabled={disableMeterField}
                type="Meter"
                data-testid={`${fieldNamePrefix}contractMeter.meter.id`}
                id={`${fieldNamePrefix}contractMeter.meter.id`}
                label=""
                placeholder="Zähler"
                {...(disableMeterField && {
                  warning: `Bitte gib zunächst den Lieferbeginn im Schritt “Vertrag” an, bevor Du einen Zähler auswählen kannst.`,
                })}
                onSelect={handleSelectMeter}
              />
            }
            css={`
              border-bottom: 0;
              margin-bottom: 0;
            `}
            allowOverflow
          >
            Bestehenden Zähler auswählen
            <TooltipInfo
              id="selectMeterToolTip"
              text="Hier kann ein bereits angelegter, freier Zähler aus der Kundenanlage ausgewählt werden."
            />
          </SelectableOption>
          <SelectableOption
            value="new"
            content={
              <>
                {showMeterWarning && isNewMeter ? (
                  <AlertInfo title="Achtung">
                    Beim Erstellen des Vertrags ist ein Fehler aufgetreten, der
                    neue Zähler wurde aber gegebenenfalls schon erstellt. Bitte
                    prüfe daher ob der Zähler nicht schon in der Liste der
                    bestehenden Zähler auftaucht.
                  </AlertInfo>
                ) : null}
                <CreateMeterForm fieldNamePrefix="contractMeter.meter.new." />
              </>
            }
            css={`
              margin-top: 0;
            `}
          >
            Neuen Zähler anlegen
          </SelectableOption>
        </Selectable>
        <OptionalField
          label="Anfangszählerstand"
          options={['Nein', 'Ja']}
          test-id="meterReading"
          managedState={meterState}
          resetFields={[
            `${fieldNamePrefix}contractMeter.meterReading.date`,
            `${fieldNamePrefix}contractMeter.meterReading.value`,
            `${fieldNamePrefix}contractMeter.meterReading.valueStatus`,
            `${fieldNamePrefix}contractMeter.meterReading.obis`,
            `${fieldNamePrefix}contractMeter.meterReading.reason`,
          ]}
        >
          <OptionalFieldStyling>
            <GraphqlFormField
              name={`${fieldNamePrefix}contractMeter.meterReading.date`}
            />
            <GraphqlFormField
              name={`${fieldNamePrefix}contractMeter.meterReading.value`}
              asType="string"
            />
            <GraphqlFormField
              name={`${fieldNamePrefix}contractMeter.meterReading.valueStatus`}
            />
          </OptionalFieldStyling>
        </OptionalField>
        <Label>
          Malo
          <TooltipInfo
            id="maloToolTip"
            text="Die offizielle Bezeichnung für den Ort, an dem Energie erzeugt, eingespeist, verbraucht oder entnommen wird. Für die Zähler innerhalb einer Kundenanlage wird die MaLo in der Regel fiktiv generiert."
          />
        </Label>
        <input
          type="radio"
          data-testid="maloType-autogenerated"
          id="maloType-autogenerated"
          checked={maloType === SourceMeloMaloEnum.Autogenerated}
          onClick={() => setMaloType(SourceMeloMaloEnum.Autogenerated)}
          readOnly
        />
        <StyledLabel htmlFor="maloType-autogenerated">
          Fiktive Malo generieren
        </StyledLabel>
        <br />
        <input
          type="radio"
          data-testid="maloType-new"
          id="maloType-new"
          checked={maloType === SourceMeloMaloEnum.New}
          onClick={() => setMaloType(SourceMeloMaloEnum.New)}
          readOnly
        />
        <StyledLabel htmlFor="maloType-new">Eigene Malo</StyledLabel>
        <br />
        {suggestedMaloMelo?.malo ? (
          <>
            <input
              type="radio"
              data-testid="maloType-suggested"
              id="maloType-suggested"
              checked={maloType === SourceMeloMaloEnum.Suggested}
              onClick={() => setMaloType(SourceMeloMaloEnum.Suggested)}
              readOnly
            />
            <StyledLabel htmlFor="maloType-suggested">
              Malo vom Zähler übernehmen
            </StyledLabel>
            <br />
            {maloType === SourceMeloMaloEnum.Suggested && (
              <GraphqlFormField
                name={`${fieldNamePrefix}contractMeter.malo`}
                label={false}
                disabled
              />
            )}
          </>
        ) : null}
        {maloType === SourceMeloMaloEnum.New && (
          <GraphqlFormField
            name={`${fieldNamePrefix}contractMeter.malo`}
            label={false}
          />
        )}
        {maloType === SourceMeloMaloEnum.Autogenerated && (
          <Hidden>
            <GraphqlFormField
              name={`${fieldNamePrefix}contractMeter.malo`}
              label={false}
            />
          </Hidden>
        )}
        <Label>
          Melo
          <TooltipInfo
            id="meloToolTip"
            text="Offizielle Bezeichnung für den Ort, an dem physikalische Energie tatsächlich gemessen wird. Für die Zähler innerhalb einer Kundenanlage wird die Melo in der Regel vom Messstellenbetreiber bereitgestellt."
          />
        </Label>
        <input
          type="radio"
          data-testid="meloType-autogenerated"
          id="meloType-autogenerated"
          checked={meloType === SourceMeloMaloEnum.Autogenerated}
          onClick={() => setMeloType(SourceMeloMaloEnum.Autogenerated)}
          readOnly
        />
        <StyledLabel htmlFor="meloType-autogenerated">
          Fiktive Melo generieren
        </StyledLabel>{' '}
        <br />
        <input
          type="radio"
          data-testid="meloType-new"
          id="meloType-new"
          checked={meloType === SourceMeloMaloEnum.New}
          onClick={() => setMeloType(SourceMeloMaloEnum.New)}
          readOnly
        />
        <StyledLabel htmlFor="meloType-new">Eigene Melo</StyledLabel>
        <br />
        {suggestedMaloMelo?.melo ? (
          <>
            <input
              type="radio"
              data-testid="meloType-suggested"
              id="meloType-suggested"
              checked={meloType === SourceMeloMaloEnum.Suggested}
              onClick={() => setMeloType(SourceMeloMaloEnum.Suggested)}
              readOnly
            />
            <StyledLabel htmlFor="meloType-suggested">
              Melo vom Zähler übernehmen
            </StyledLabel>
            <br />
          </>
        ) : null}
        {meloType === SourceMeloMaloEnum.New && (
          <GraphqlFormField
            name={`${fieldNamePrefix}contractMeter.melo`}
            label={false}
          />
        )}
        {meloType === SourceMeloMaloEnum.Suggested && (
          <GraphqlFormField
            name={`${fieldNamePrefix}contractMeter.melo`}
            label={false}
            disabled
          />
        )}
        {meloType === SourceMeloMaloEnum.Autogenerated && (
          <Hidden>
            <GraphqlFormField
              name={`${fieldNamePrefix}contractMeter.melo`}
              label={false}
            />
          </Hidden>
        )}
      </>
    );
  },
);

export default MeterForm;
