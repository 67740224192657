export const formatType = (type: string, count: number) => {
  switch (type) {
    case 'plant':
      return count === 1 ? 'Kundenanlage' : 'Kundenanlagen';
    case 'contractcomponent':
      return count === 1 ? 'Vertragskomponente' : 'Vertragskomponenten';
    case 'commonContract':
    case 'contractMeter':
    case 'contract':
      return count === 1 ? 'Vertrag' : 'Verträge';
    default:
      return type;
  }
};
