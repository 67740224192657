import React from 'react';
import styled from 'styled-components';

import { ReadContractQuery } from '../../../../graphql-types';
import { useGraphqlForm } from '../../../../components/graphql-form/hooks/useGraphqlForm';
import { HistoricEntry, Bold } from '../../../../components';
import { formatCurrency } from '../../../../helpers/formatStrings';
import { GraphqlFormField } from '../../../../components/graphql-form/render';

const Headline = styled(Bold)`
  margin-top: 10px;
  font-size: 15px;
`;

export function DownPayment({
  contract,
}: {
  contract?: ReadContractQuery['readContract'];
}) {
  const { isEditing, isLoading } = useGraphqlForm();

  const { downPaymentHistory } = contract ?? {};

  const historyPayment: Array<{
    value: number;
    date: { startDate: string };
  }> = downPaymentHistory
    ? downPaymentHistory.map((_historyPayment: any) => ({
        value: _historyPayment.downPayment,
        date: {
          startDate: _historyPayment.downPaymentStartDate,
        },
      }))
    : [];

  return (
    <>
      {!isEditing && (
        <HistoricEntry
          title="Abschlagszahlung (Brutto)"
          values={historyPayment}
          isLoading={isLoading}
          formatValue={formatCurrency}
        />
      )}
      {isEditing && (
        <>
          <Headline>Abschlagszahlung (Brutto)</Headline>
          <GraphqlFormField name="downPayment" label={null} />
          <GraphqlFormField
            prependix="vom"
            name="downPaymentStartDate"
            label={null}
          />
        </>
      )}
    </>
  );
}
