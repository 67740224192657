import React from 'react';

import { useGraphqlForm } from '../../../../components/graphql-form/hooks/useGraphqlForm';
import { Grid } from '../../../../components/layout';
import { CreateAdditionalPersonForm } from '../../edit/AdditionalPersonForm';
import { PersonForm } from '../../edit/editFormParts';
import { SetStateAction } from '../../../../helpers/stateHelpers';
import { AddressForm } from '../AddressForm';

type CreateBillingShippingAddressFormProps = {
  fieldNamePrefix: string;
  billingCustomerAdditionalEntityFieldsState?: SetStateAction<boolean>;
  billingCustomerAdditionalPersonState?: SetStateAction<boolean>;
};

export function CreateBillingShippingAddressForm({
  fieldNamePrefix,
  billingCustomerAdditionalEntityFieldsState,
  billingCustomerAdditionalPersonState,
}: CreateBillingShippingAddressFormProps) {
  const { isEditing } = useGraphqlForm();

  return (
    <Grid $flow="row">
      <div
        css={`
          display: grid;
          grid-auto-flow: row;
          gap: ${isEditing ? `0.5rem` : `0`};
        `}
      >
        <PersonForm fieldNamePrefix={fieldNamePrefix} />
        <AddressForm fieldNamePrefix={fieldNamePrefix} />
      </div>
      <AddressBilling
        fieldNamePrefix={fieldNamePrefix}
        billingCustomerAdditionalEntityFieldsState={
          billingCustomerAdditionalEntityFieldsState
        }
        billingCustomerAdditionalPersonState={
          billingCustomerAdditionalPersonState
        }
      />
    </Grid>
  );
}

function AddressBilling({
  fieldNamePrefix,
  billingCustomerAdditionalEntityFieldsState,
  billingCustomerAdditionalPersonState,
}: {
  fieldNamePrefix: string;
  billingCustomerAdditionalEntityFieldsState?: SetStateAction<boolean>;
  billingCustomerAdditionalPersonState?: SetStateAction<boolean>;
}) {
  if (
    !isAddressBilling(fieldNamePrefix) ||
    !billingCustomerAdditionalEntityFieldsState ||
    !billingCustomerAdditionalPersonState
  ) {
    return null;
  }

  return (
    <CreateAdditionalPersonForm
      fieldNamePrefix={fieldNamePrefix}
      billingCustomerAdditionalEntityFieldsState={
        billingCustomerAdditionalEntityFieldsState
      }
      billingCustomerAdditionalPersonState={
        billingCustomerAdditionalPersonState
      }
    />
  );
}

function isAddressBilling(fieldNamePrefix: string) {
  return fieldNamePrefix.includes('addressBilling');
}
