import React, { useCallback } from 'react';
import styled from 'styled-components';
import { get } from 'lodash';
import { useFormikContext } from 'formik';
import { Icons, Button } from '@ampeersenergy/ampeers-ui-components';

import {
  GraphqlFormField,
  Hint,
} from '../../../../../components/graphql-form/render';
import {
  Flex,
  FlexRow,
  PaddedShadowBox,
  Entry,
  Relation,
  Label,
} from '../../../../../components';
import { PlantDetailExtendedQuery } from '../../../../../graphql-types';
import { AddressForm } from '../../../create/formParts';
import {
  formateMeasurementConceptText,
  formatString,
} from '../../../../../helpers/formatStrings';
import { useGraphqlForm } from '../../../../../components/graphql-form/hooks/useGraphqlForm';

const PaddedShadowBoxStyled = styled(PaddedShadowBox)`
  height: 100%;
`;
const FullHeight = styled.div`
  margin-bottom: 1px;
`;
const PaddedShadowBoxMarginStyled = styled(PaddedShadowBox)`
  min-width: 250px;
  margin-right: 1px;
`;
const ActionButton = styled.div`
  min-width: 200px;
`;

function MainPanel({
  plant,
  isLoading,
  setModalIsOpen,
}: {
  setModalIsOpen: any;
  plant: PlantDetailExtendedQuery['readPlant'] | undefined;
  isLoading: boolean;
}) {
  const { isEditing } = useGraphqlForm();
  const { values } = useFormikContext();
  const sidebarTitle = useCallback(
    (): string =>
      get(values, 'addresses')?.length > 1 ? 'Adressen' : 'Adresse',
    [values],
  );
  const subTitle = useCallback((selected: number) => {
    if (selected === 0) {
      return 'Hauptadresse';
    }
    return `Zusatzadresse ${selected}`;
  }, []);
  return (
    <>
      <FullHeight>
        <FlexRow>
          <PaddedShadowBoxMarginStyled>
            <FlexRow>
              <Flex>
                <GraphqlFormField name="name" />
              </Flex>
            </FlexRow>
            <FlexRow>
              <Flex>
                <Label>Projekt</Label>
                <Relation
                  title={formatString(plant?.project?.name)}
                  icon={Icons.Project}
                />
              </Flex>
            </FlexRow>
            <FlexRow>
              <Flex>
                <GraphqlFormField name="kpis.units" />
              </Flex>
            </FlexRow>
          </PaddedShadowBoxMarginStyled>
          <Flex>
            <PaddedShadowBoxStyled>
              <FlexRow>
                <Flex>
                  <AddressForm
                    sidebarTitle={sidebarTitle}
                    subTitle={subTitle}
                    showActionButtons={false}
                    isLoading={isLoading}
                    hintLimitReached={
                      'Es können max. vier Zusatzadressen hinterlegt \n werden.'
                    }
                  />
                </Flex>
                {!isEditing && (
                  <div>
                    <ActionButton>
                      <Button
                        onClick={() => setModalIsOpen(true)}
                        isLoading={isLoading}
                        disabled={
                          isLoading ||
                          (plant?.addresses && plant?.addresses?.length > 4)
                        }
                      >
                        Adresse hinzufügen
                      </Button>
                    </ActionButton>
                    {!isLoading &&
                      plant?.addresses &&
                      plant?.addresses?.length > 4 && (
                        <Hint>
                          Es können max. vier Zusatzadressen
                          <br /> hinterlegt werden.
                        </Hint>
                      )}
                  </div>
                )}
              </FlexRow>
            </PaddedShadowBoxStyled>
          </Flex>
        </FlexRow>
      </FullHeight>
      <PaddedShadowBoxStyled>
        <FlexRow>
          <Flex>
            <Entry title="Bilanzkreis" isLoading={isLoading}>
              {formatString(plant?.balancingAreaGroupAccount?.name)}
              <br />
              EIC: {formatString(plant?.balancingAreaGroupAccount?.eic)}
            </Entry>
          </Flex>
          <Flex>
            <Entry title="Regelzone" isLoading={isLoading}>
              {formatString(plant?.transmissionSystemOperator?.name)}
              <br />
              EIC: {formatString(plant?.transmissionSystemOperator?.eic)}
            </Entry>
          </Flex>
          <Flex>
            <Entry title="Messkonzept" isLoading={isLoading}>
              {formateMeasurementConceptText(plant?.measurementConcept)}
            </Entry>
          </Flex>
        </FlexRow>
        <FlexRow>
          <Flex>
            <Entry title="Bilanzierungsgebiet" isLoading={isLoading}>
              {formatString(plant?.balancingAreaAccount?.name)}
              <br />
              EIC: {formatString(plant?.balancingAreaAccount?.eic)}
            </Entry>
          </Flex>
          <Flex>
            <Entry title="Messstellenbetreiber" isLoading={isLoading}>
              {formatString(plant?.meteringPointOperator?.name)} <br />
              Codenummer: {formatString(plant?.meteringPointOperator?.code)}
            </Entry>
          </Flex>
          <Flex>
            <Entry title="Lieferant" isLoading={isLoading}>
              {formatString(plant?.supplierAccount?.name)}
              <br />
              Codenummer: {formatString(plant?.supplierAccount?.code)}
            </Entry>
          </Flex>
        </FlexRow>
      </PaddedShadowBoxStyled>
    </>
  );
}

export default MainPanel;
