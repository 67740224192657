import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import styled from 'styled-components/macro';

import { Entry } from '../../../components';
import { useGraphqlForm } from '../../../components/graphql-form/hooks/useGraphqlForm';
import { GraphqlFormField } from '../../../components/graphql-form/render';

const Markdown = styled(ReactMarkdown)`
  margin: 0.5rem 0 1rem;
  padding: 0.5rem 0.5rem;

  background-color: ${(props) => props.theme.palette.backgroundMuted};

  p {
    margin: 0;
  }
`;

interface MarkdownTextareaProps {
  name: string;
  label?: string | null;
  rows?: number;
  maxRows?: number;
}

export function MarkdownTextarea({
  name,
  label,
  rows = 4,
  maxRows = 5,
}: MarkdownTextareaProps) {
  const { getFieldProps, isEditing, isLoading } = useGraphqlForm();

  const limitTextaraRows = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const Inputrows = (e.target as HTMLInputElement)?.value?.split('\n').length;
    if (e.key === 'Enter' && Inputrows >= maxRows) {
      e.preventDefault();
    }
  };

  const preventMaxRows = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const inputRows = e.target.value.split('\n');
    if (inputRows.length > maxRows) {
      e.target.value = inputRows.slice(0, maxRows).join('\n');
    }
  };

  const props = getFieldProps(name);

  if (isEditing) {
    return (
      <GraphqlFormField
        name={name}
        type="textarea"
        rows={rows}
        style={{ minHeight: 'auto' }}
        onKeyPress={limitTextaraRows}
        onChange={preventMaxRows}
        label={label ?? props?.label}
      />
    );
  }

  if (label === null) {
    return (
      <Markdown remarkPlugins={[remarkBreaks]}>{props?.value ?? '--'}</Markdown>
    );
  }

  return (
    <Entry title={props?.label} isLoading={isLoading}>
      <Markdown remarkPlugins={[remarkBreaks]}>{props?.value ?? '--'}</Markdown>
    </Entry>
  );
}
