export const LOCAL_STORAGE_IDENTIFIER = 'ae.localsupply.auth';

export const loadJSONFromStorage = <T = any>(key: string) => {
  try {
    const data = localStorage.getItem(key);
    if (!data) {
      return undefined;
    }
    return JSON.parse(data) as T;
  } catch (err) {
    return undefined;
  }
};

export const saveJSONToStorage = <T = any>(key: string, data: T) => {
  try {
    const stringifiedData = JSON.stringify(data);
    localStorage.setItem(key, stringifiedData);
  } catch {
    // ignore write errors
  }
};

export const clearDataInStorage = (key: string) => {
  try {
    localStorage.removeItem(key);
  } catch {
    // ignore write errors
  }
};

export const getOptionalCookiesAccepted = () => {
  const localStorageItem = localStorage.getItem(
    `${LOCAL_STORAGE_IDENTIFIER}.cookies.optional`,
  );
  return localStorageItem === 'true';
};
