import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

export const Link = styled.span`
  color: ${(props) => props.theme.primaryColor};
  cursor: pointer;
  font-weight: 600;

  &:hover {
    text-decoration: underline;
  }
`;

export const StyledRouterLink = styled(RouterLink)`
  color: ${(props) => props.theme.primaryColor};
  cursor: pointer;
  font-weight: 600;

  &:hover {
    text-decoration: underline;
  }
`;
