import React from 'react';
import styled from 'styled-components';

import { buildDocumentUrl } from '../helpers/buildDocumentUrl';

import { FileIcon } from './fileLink';

const FadedFileIcon = styled(FileIcon)`
  opacity: 0.3;
`;

function DownloadFileLink({
  url,
  disabled = false,
}: {
  url: string;
  disabled?: boolean;
}) {
  if (disabled) {
    return <FadedFileIcon />;
  }

  const fileUrl = buildDocumentUrl(url);

  return (
    <a aria-label="Datei herunterladen" id="download-file-link" href={fileUrl}>
      <FileIcon />
    </a>
  );
}

export default DownloadFileLink;
