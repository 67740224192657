import { maxBy } from 'lodash';
import { DateTime, Interval } from 'luxon';

import {
  ReadContractQuery,
  ReadCustomersSimpleQuery,
  ReadTariffsSimpleQuery,
} from '../graphql-types';

export function isFinished(date: any): boolean {
  if (!date) {
    return false;
  }
  return new Date(date).getTime() <= Date.now();
}

export function sortTariffsSuggestions(
  tariffs: ReadTariffsSimpleQuery['readTariffs'],
): ReadTariffsSimpleQuery['readTariffs'] {
  // sort 1,2,3,4,5,6,0
  return [...tariffs].sort((a: any, b: any) => {
    if (parseInt(a.kind, 10) === parseInt(b.kind, 10)) {
      if (a.nameInternal < b.nameInternal) {
        return -1;
      }
      if (a.nameInternal > b.nameInternal) {
        return 1;
      }
      return 0;
    }
    if (parseInt(a.kind, 10) === 0) {
      return 1;
    }
    if (parseInt(b.kind, 10) === 0) {
      return -1;
    }
    return parseInt(a.kind, 10) < parseInt(b.kind, 10) ? -1 : 1;
  });
}

export const filterTariffSuggestions =
  (startDate: DateTime, contract?: ReadContractQuery['readContract']) =>
  (tariffs: ReadTariffsSimpleQuery['readTariffs']) => {
    const lastUsedTariff = maxBy(contract?.tariffs, 'assignedTo');

    let filteredTariffs = tariffs.filter(
      (tariff) => tariff.id !== lastUsedTariff?.tariffId,
    );
    if (!startDate.isValid) {
      return filteredTariffs;
    }
    filteredTariffs = filteredTariffs.filter((tariff) => {
      const tariffValidityStartDate = DateTime.fromISO(
        tariff.validityStartDate,
        {
          zone: 'utc',
        },
      );
      const tariffValidityEndDate = DateTime.fromISO(tariff.validityEndDate, {
        zone: 'utc',
      });
      const hasValidPricesheets = tariff.priceSheets.some(
        (pricesheet) => startDate >= DateTime.fromISO(pricesheet.startDate),
      );
      return (
        startDate >= tariffValidityStartDate &&
        startDate <= tariffValidityEndDate &&
        hasValidPricesheets
      );
    });
    return filteredTariffs.map((tariff) => ({
      ...tariff,
      priceSheets:
        tariff.priceSheets.length === 1
          ? tariff.priceSheets
          : tariff.priceSheets.filter(
              (pricesheet) =>
                startDate >= DateTime.fromISO(pricesheet.startDate),
            ),
    }));
  };

export function sortExistingCustomersSuggestions(
  customers: ReadCustomersSimpleQuery['readCustomers'],
): ReadCustomersSimpleQuery['readCustomers'] {
  return [...customers].sort((a: any, b: any) => {
    return a.person.name.localeCompare(b.person.name);
  });
}

export function isTrueStringBool(value: string | boolean): boolean {
  if (typeof value !== 'string' && typeof value !== 'boolean') {
    throw new Error(
      `isTrueStringBool got: ${typeof value}, but should have been string or boolean`,
    );
  }

  if (value === 'true' || value === true) {
    return true;
  }
  if (value === 'false' || value === false) {
    return false;
  }
  throw new Error(
    `isTrueStringBool got: ${value}, but should have been true or false`,
  );
}

export const isSameDay = (date1: Date, date2: Date) => {
  const _date1 = DateTime.fromJSDate(date1);
  const _date2 = DateTime.fromJSDate(date2);
  return (
    _date1.hasSame(_date2, 'year') &&
    _date1.hasSame(_date2, 'month') &&
    _date1.hasSame(_date2, 'day')
  );
};

export function removeTypename<T>(obj: T): T {
  if (Array.isArray(obj)) {
    return obj.map((o) => transformNullToUndefined(o)) as any;
  }
  if (isObject(obj)) {
    return Object.keys(obj).reduce((acc, key) => {
      if (key === '__typename') {
        return acc;
      }
      if (typeof (obj as any)[key] === 'object') {
        return { ...acc, [key]: removeTypename((obj as any)[key]) };
      }
      return { ...acc, [key]: (obj as any)[key] };
    }, {} as T);
  }
  return obj;
}

export function transformNullToUndefined<T>(
  obj?: T,
): Omit<T, '__typename'> | undefined {
  if (Array.isArray(obj)) {
    return obj.map((o) => transformNullToUndefined(o)) as any;
  }
  if (isObject(obj)) {
    return Object.keys(obj).reduce((acc, key) => {
      if (key === '__typename') {
        return acc;
      }
      const value = transformNullToUndefined(obj[key]);
      if (value !== undefined && value !== null) {
        return { ...acc, [key]: value };
      }
      return acc;
    }, {} as T);
  }
  return obj;
}

type TypeSafeTrimFilterOptions = {
  filterEmpty?: boolean;
  filterNull?: boolean;
};

export function typesafeTrimFilterUndefined<T>(
  obj: T,
  options?: TypeSafeTrimFilterOptions,
): T {
  if (Array.isArray(obj)) {
    return obj;
  }
  if (isObject(obj)) {
    return Object.keys(obj).reduce((acc, cur) => {
      if (obj[cur] === undefined) {
        return acc;
      }
      const val = typesafeTrimFilterUndefined(obj[cur], options);
      const keepEmpty = !options?.filterEmpty || val !== '';
      const keepNull = !options?.filterNull || val !== null;
      if (val !== undefined && !isEmptyObject(val) && keepEmpty && keepNull) {
        const value = typeof val === 'string' ? val.trim() : val;
        return {
          ...acc,
          [cur]: value,
        };
      }
      return acc;
    }, {} as T);
  }

  return obj;
}

export function isObject(val: unknown): val is Record<string, unknown> {
  return toString.call(val) === '[object Object]';
}

export function isEmptyObject<T>(obj: T): boolean {
  return isObject(obj) && Object.keys(obj).length === 0;
}

export function isSameTimePeriod(
  refStartDate: string,
  refEndDate: string,
  startDate: string,
  endDate: string,
) {
  const refInterval = Interval.fromDateTimes(
    DateTime.fromISO(refStartDate),
    DateTime.fromISO(refEndDate),
  );

  const currentInterval = Interval.fromDateTimes(
    DateTime.fromISO(startDate),
    DateTime.fromISO(endDate),
  );

  const refIntervalLengthInDays = Math.trunc(refInterval.length('days'));
  const currentIntervalLengthInDays = Math.trunc(
    currentInterval.length('days'),
  );

  // year
  // when the interval is {year}-01-01 to {year}-12-31, we get length 364 instead of 365
  if ([364, 365, 366].includes(currentIntervalLengthInDays)) {
    return [364, 365, 366].includes(refIntervalLengthInDays);
  }

  // month
  // when the interval is {year}-{month}-01 to {year}-{month}-{28|30|31}, we get length of 27|29|30 instead of 28|30|31
  if ([27, 28, 29, 30, 31].includes(currentIntervalLengthInDays)) {
    return [27, 28, 29, 30, 31].includes(refIntervalLengthInDays);
  }

  return refIntervalLengthInDays === currentIntervalLengthInDays;
}
