import {
  ColumnDefinition,
  FlexRow,
  Icons,
} from '@ampeersenergy/ampeers-ui-components';
import * as React from 'react';
import styled, { useTheme } from 'styled-components';

import { Relation } from '../../../../components';
import { TooltipInfo } from '../../../../components/TooltipInfo';
import { AccountingRunValidatedConsumption } from '../../../../graphql-types';
import {
  formatMeter,
  formatMeterReadingValue,
} from '../../../../helpers/formatStrings';
import { IconWrap } from '../../oldWorkflow/validate/style';

export const columns: ColumnDefinition<any>[] = [
  {
    Header: 'Mieter',
    accessor: 'name',
    type: 'string',
  },
  {
    Header: 'KundenNr.',
    accessor: 'customerLabel',
    type: 'string',
  },
  {
    Header: 'VertragsNr.',
    accessor: 'contractLabel',
    type: 'string',
  },
  {
    Header: 'ZählerNr.',
    accessor: 'meters',
    Cell: ({ cell }) => {
      const meters = cell.value as Array<{
        meterNumber: string;
        meterId: number;
      }>;

      if (!meters) return null;
      return [...meters]
        .sort((a, b) => a.meterId - b.meterId)
        .map(({ meterNumber }) => (
          <Relation icon={Icons.Meter} title={formatMeter({ meterNumber })} />
        ));
    },
  },
];

export const consumptionErrorColumns: ColumnDefinition<AccountingRunValidatedConsumption>[] =
  [
    {
      Header: 'Mieter',
      accessor: 'name',
      type: 'string',
    },
    {
      Header: 'KundenNr.',
      accessor: 'customerLabel',
      type: 'string',
    },
    {
      Header: 'VertragsNr.',
      accessor: 'contractLabel',
      type: 'string',
    },
    {
      Header: 'Angenommener Verbrauch',
      accessor: 'relativeAjv',
      Cell: ({ row }) => {
        const { ajv, relativeAjv, id } = row.original;

        const showTooltip = ajv !== relativeAjv;
        return (
          <>
            {formatMeterReadingValue(relativeAjv)}
            {showTooltip ? (
              <TooltipInfo
                id={`ajv-${id}`}
                text={`Als Vergleichsgrundlage dient der angenommene Jahresverbrauch von ${formatMeterReadingValue(
                  ajv,
                )}`}
                dark
              />
            ) : null}
          </>
        );
      },
    },
    {
      Header: 'Tat. Verbrauch',
      accessor: 'newConsumption',
      Cell: ({ row }) => {
        const { newConsumption, relativeVariance, id } = row.original;
        const formattedVariance = relativeVariance
          ? Intl.NumberFormat('de-DE', {
              style: 'percent',
              maximumFractionDigits: 2,
              minimumFractionDigits: 0,
            }).format(relativeVariance / 100)
          : undefined;
        return (
          <>
            {formatMeterReadingValue(newConsumption)}
            <TooltipInfo
              id={`newConsumption-${id}`}
              text={`Der tatsächliche Verbrauch weicht mit einer Abweichung von ${formattedVariance} vom angenommenen Verbrauch ab`}
              dark
            />
          </>
        );
      },
    },
    {
      Header: 'Fehler',
      accessor: 'error',
      Cell: ({ row }) => {
        const lowOrHigh =
          row.original.error === 'TOO_HIGH' ? 'hoch' : 'niedrig';
        return `Der Verbrauch ist ungewöhnlich ${lowOrHigh}. Er weicht stark vom angenommenen Jahresverbrauch ab.`;
      },
    },
  ];

export function mergeErrorReasons(reason: string) {
  switch (reason) {
    case 'NON_INTERPOLATABLE_METER_CHANGE_AT_ACC_PERIOD_END':
      return 'NON_INTERPOLATABLE_METER_CHANGE_AT_ACC_PERIOD_END';
    case 'NON_INTERPOLATABLE_ACCOBJ_TYPE':
    case 'NO_METERREADING_FOR_END_OF_ACC_PERIOD':
    case 'NO_METERREADINGS_FOR_METER':
      return 'missingMeterReading';
    case 'BEGINREADING_GREATER_THAN_END_READING':
      return 'BEGINREADING_GREATER_THAN_END_READING';
    default:
      return reason;
  }
}

export const formatPluralType = (type: string, count: number) => {
  switch (type) {
    case 'plant':
      return count === 1 ? 'Kundenanlage' : 'Kundenanlagen';
    case 'contractcomponent':
      return count === 1 ? 'Vertragskomponente' : 'Vertragskomponenten';
    case 'commonContract':
    case 'contractMeter':
    case 'contract':
      return count === 1 ? 'Vertrag' : 'Verträge';
    default:
      return type;
  }
};

export const FlexRowWithCenteredItems = styled(FlexRow)`
  align-items: center;
`;

export function ErrorTitle({
  type,
  error,
  count,
  isError = true,
}: {
  type: string;
  error: string;
  count: number;
  isError?: boolean;
}) {
  const theme = useTheme();

  return (
    <FlexRowWithCenteredItems>
      <IconWrap>
        {isError ? (
          <Icons.Wrong size={30} color={theme.palette.error.color} />
        ) : (
          <Icons.Warning size={30} color={theme.palette.warning.color} />
        )}
      </IconWrap>
      {count} {formatPluralType(type, count)} mit {error}
    </FlexRowWithCenteredItems>
  );
}
