import * as yup from 'yup';
import { DateTime } from 'luxon';

const invalidDate = new Date('');

/**
 * This changes local time zone to utc zone
 * in the way that only the timezone/offset is
 * changed. So for example
 * 12:00:00 +2 will be changed to 12:00:00 +0
 */
export const yupUTCDate = yup.date().transform(function transform(value) {
  if (!this.isType(value)) {
    return invalidDate;
  }

  if (value === undefined) {
    return invalidDate;
  }

  return DateTime.fromJSDate(value)
    .setZone('utc', { keepLocalTime: true })
    .toJSDate();
});
