import React, { useCallback, useMemo, useState } from 'react';
import * as yup from 'yup';
import { DateTime } from 'luxon';
import styled from 'styled-components';

import GraphqlForm from '../../../../../../components/graphql-form';
import { EditContainerProps } from '../../../../../../components/createFlow';
import {
  Metering,
  MeterReading,
  ReadMeterReadingsDocument,
} from '../../../../../../graphql-types';
import { yupUTCDate } from '../../../../../../helpers/yupUTCDate';
import { yupNumberAsString } from '../../../../../../helpers/yupNumberAsString';
import numeral from '../../../../../../helpers/numeral';
import { findMeterReadingMinMaxLimits } from '../../../../../../helpers/validators/meterReading';
import { METERING_OBIS_MAP } from '../../../../../../helpers/constants';
import { formatNumberToEuropeanStyle } from '../../../../../../helpers/numberHelper';

import ConfirmationDialog from './addMeterReadingDialog';
import { AddMeterReadingForm } from './addMeterReadingForm';

const Hint = styled.div`
  color: #a9a9a9;
  font-size: 12px;
  margin-bottom: 5px;
  display: block;
  width: 270px;
`;

interface CreateMeterReadingContainerProps
  extends Omit<EditContainerProps, 'variables'> {
  variables?: {
    existingReadings: Pick<
      MeterReading,
      'from' | 'value' | 'obis' | 'reason'
    >[];
    meterId: string;
    meterType: string;
    selectedObis: string;
    startDate: string;
    endDate: string;
    metering: Metering;
    showObisDropdown: boolean;
  };
}

export const buildValidation = (
  existingReadings: {
    date: string;
    value: string;
    obis: string;
    reason: string;
  }[],
) => {
  return {
    date: yupUTCDate
      .when(['obis'], (obis: string, schema: any) =>
        schema?.test(
          'endDate',
          'Es existiert bereits ein Zählerstand an diesem Datum.',
          (value?: Date) => {
            if (!value) {
              return true;
            }
            return !existingReadings
              .filter((reading) => reading.obis === obis)
              .find(
                (data) => new Date(data.date).getTime() === value.getTime(),
              );
          },
        ),
      )
      .required(),
    value: yupNumberAsString
      .when(
        ['obis', 'date'],
        // typings of yup changed and we couldn't get it to work
        // @ts-ignore
        (
          obis: string,
          date: Date | undefined,
          schema: yup.NumberSchema<number>,
        ) => {
          if (!date) {
            return schema;
          }
          const readingsForSelectedObis = existingReadings?.filter(
            (reading) => reading.obis === obis,
          );
          const { min, max } = findMeterReadingMinMaxLimits(
            readingsForSelectedObis,
            date,
          );
          const firstDate = DateTime.fromISO(
            readingsForSelectedObis[0]?.date,
          ).toISODate();
          if (max === 0) {
            return schema.test(
              'maxZero',
              `Muss größer 0 sein und nach ${firstDate} liegen`,
              () => false,
            );
          }
          if (min === max) {
            return schema.test(
              'minEqualMax',
              `Muss gleich ${formatNumberToEuropeanStyle(min)} sein`,
              (value: number) => numeral(value).value() === min,
            );
          }
          if (max) {
            return schema.test(
              'minMax',
              `Muss zwischen ${formatNumberToEuropeanStyle(
                min,
              )} und ${formatNumberToEuropeanStyle(max)} liegen`,
              (value) =>
                numeral(value).value() >= min && numeral(value).value() <= max,
            );
          }
          return schema.test(
            'min',
            `Muss größer oder gleich ${formatNumberToEuropeanStyle(min)} sein`,
            (value) => numeral(value).value() >= min,
          );
        },
      )
      .required(),
  };
};

export default function AddMeterReadingContainer({
  onSuccess,
  onAbort,
  variables,
}: CreateMeterReadingContainerProps) {
  const [mResult, setMResult]: any = useState();
  const [showConfirmationDialog, setShowConfirmationDialog]: any =
    useState(false);

  const existingReadings = useMemo(() => {
    return (
      variables?.existingReadings
        .map((c) => ({
          date: String(c.from),
          value: String(c.value),
          reason: String(c.reason),
          obis: String(c.obis),
        }))
        .sort(
          (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
        ) ?? []
    );
  }, [variables]);

  const onSuccessInterception = useCallback(
    (result: any, add: any) => {
      setMResult(result);
      if (result?.createMeterReading?.warning) {
        setShowConfirmationDialog(true);
      } else if (onSuccess) {
        onSuccess(result, add);
      }
    },
    [onSuccess],
  );

  const validation = useMemo(
    () => buildValidation(existingReadings),
    [existingReadings],
  );

  if (!variables) return null;

  if (showConfirmationDialog) {
    return (
      <ConfirmationDialog
        onDone={onAbort!}
        variables={variables}
        result={mResult}
        onSuccess={onSuccess}
      />
    );
  }

  let refetchQueries = [
    {
      query: ReadMeterReadingsDocument,
      variables: {
        meterId: String(variables.meterId),
        startDate: variables.startDate,
        endDate: variables.endDate,
        metering:
          variables.metering === Metering.Slp ? Metering.Slp : Metering.Rlm,
        obisChannel: variables.selectedObis,
      },
    },
  ];

  if (variables.showObisDropdown) {
    refetchQueries = METERING_OBIS_MAP[variables.metering].map(
      (obisChannel) => ({
        query: ReadMeterReadingsDocument,
        variables: {
          meterId: String(variables.meterId),
          startDate: variables.startDate,
          endDate: variables.endDate,
          metering:
            variables.metering === Metering.Slp ? Metering.Slp : Metering.Rlm,
          obisChannel,
        },
      }),
    );
  }

  return (
    <GraphqlForm
      mutation="createMeterReading"
      startInEdit
      onSuccess={onSuccessInterception}
      onAbort={onAbort}
      defaultValues={{
        obis: variables.selectedObis,
        ignoreWarning: false,
      }}
      formatBeforeSubmit={(v) => {
        return { ...v, value: numeral(v.value).value() };
      }}
      refetchQueries={refetchQueries}
      readDocumentFields={[
        'warning',
        'date',
        'value',
        'reason',
        'hint',
        'valueStatus',
        'obis',
      ]}
      variables={{
        meterId: variables.meterId,
      }}
      formVariables={{
        showObisDropdown: variables.showObisDropdown,
      }}
      css="white-space: pre"
      validation={validation}
    >
      <Hint>
        Du kannst Zählerstände im Zeitbereich hinzufügen, für den der Zähler
        einem Vertrag zugeordnet ist.
      </Hint>
      <AddMeterReadingForm
        fieldNamePrefix=""
        meterId={variables.meterId}
        meterType={variables.meterType}
        existingReadings={existingReadings}
      />
    </GraphqlForm>
  );
}
