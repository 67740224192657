import * as React from 'react';
import {
  VerticalTabsContainer,
  VerticalTabs,
  TabNavigation,
  Main,
  Header,
  PageGrid,
  Icons,
  Section,
} from '@ampeersenergy/ampeers-ui-components';
import styled from 'styled-components';
import { Link, Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';

import { DocTitle } from '../../components/docTitle';
import { useHasRole } from '../../components/useHasRole';

import { UserSettings } from './userSettings/userSettings';
import { ContractSettings } from './ContractSettings';
import { EmailSettings } from './EmailSettings';
import { DocumentsSettings } from './DocumentsSettings';
import TestSettings from './TestSettings';
import CookieSettings from './CookieSettings/cookieSettings';
import OpsClientSettings from './OpsClientSettings';

const AlignedSection = styled(Section)`
  padding: 32px 34px 32px 0;
`;

function Settings() {
  const { hasRole: isOperationsUser } = useHasRole('ae-operations');
  const match = useRouteMatch();

  const routeMatch = useRouteMatch([
    `/settings/account`,
    `/settings/cookies`,
    `/settings/contracts`,
    `/settings/email`,
    `/settings/documents`,
    `/settings/test-requests`,
    `/settings/ops-client-settings`,
  ]);
  const activeTab = routeMatch !== null ? routeMatch.path : '';

  const basePath = match.url;

  return (
    <>
      <DocTitle titleParts={['Einstellungen']} />
      <Main>
        <Header Icon={Icons.Settings}>Einstellungen</Header>
        <PageGrid>
          <AlignedSection expand>
            <VerticalTabsContainer>
              <TabNavigation>
                <VerticalTabs title="Persönlich" value={activeTab}>
                  <VerticalTabs.Tab
                    label="Account"
                    to={`${basePath}/account`}
                    value={`${basePath}/account`}
                    component={Link}
                  />
                  <VerticalTabs.Tab
                    label="Cookies"
                    to={`${basePath}/cookies`}
                    value={`${basePath}/cookies`}
                    component={Link}
                  />
                </VerticalTabs>
                <VerticalTabs title="Workspace" value={activeTab}>
                  <VerticalTabs.Tab
                    label="Verträge"
                    to={`${basePath}/contracts`}
                    value={`${basePath}/contracts`}
                    component={Link}
                  />
                  <VerticalTabs.Tab
                    label="Dokumente"
                    to={`${basePath}/documents`}
                    value={`${basePath}/documents`}
                    component={Link}
                  />
                </VerticalTabs>
                {isOperationsUser ? (
                  <VerticalTabs title="Operations" value={activeTab}>
                    <VerticalTabs.Tab
                      label="E-Mail Versand"
                      to={`${basePath}/email`}
                      value={`${basePath}/email`}
                      component={Link}
                    />
                    <VerticalTabs.Tab
                      label="Test Settings"
                      to={`${basePath}/test-requests`}
                      value={`${basePath}/test-requests`}
                      component={Link}
                    />
                    <VerticalTabs.Tab
                      label="Ops Client Settings"
                      to={`${basePath}/ops-client-settings`}
                      value={`${basePath}/ops-client-settings`}
                      component={Link}
                    />
                  </VerticalTabs>
                ) : null}
              </TabNavigation>

              <Switch>
                <Route
                  exact
                  path={basePath}
                  render={() => <Redirect to={`${basePath}/account`} />}
                />
                <Route path={`${basePath}/account`}>
                  <UserSettings />
                </Route>
                <Route path={`${basePath}/cookies`}>
                  <CookieSettings />
                </Route>
                <Route path={`${basePath}/contracts`}>
                  <ContractSettings />
                </Route>
                <Route path={`${basePath}/documents`}>
                  <DocumentsSettings />
                </Route>
                {isOperationsUser ? (
                  <>
                    <Route path={`${basePath}/email`}>
                      <EmailSettings />
                    </Route>
                    <Route path={`${basePath}/test-requests`}>
                      <TestSettings />
                    </Route>
                    <Route path={`${basePath}/ops-client-settings`}>
                      <OpsClientSettings />
                    </Route>
                  </>
                ) : null}
              </Switch>
            </VerticalTabsContainer>
          </AlignedSection>
        </PageGrid>
      </Main>
    </>
  );
}

export default Settings;
