import { Modal } from '@ampeersenergy/ampeers-ui-components';
import React, { useMemo, useState } from 'react';

import CreateFlow from '../../../../components/createFlow';
import { Contract, PriceSheet, Tariff } from '../../../../graphql-types';

import CreatePricesheetChangeDocuments from './CreatePricesheetChangeDocuments';
import CreatePricesheetContainer from './createPricesheetContainer';
import { PriceSheetInput } from './createPricesheetForm';

type CreatePricesheetFlowProps = {
  tariff: Tariff;
  isOpen: boolean;
  onClose: () => void;
};

export default function CreatePricesheetFlow({
  tariff,
  isOpen,
  onClose,
}: CreatePricesheetFlowProps) {
  const [newPriceSheet, setNewPriceSheet] = useState<PriceSheetInput>({});
  const [affectedContracts, setAffectedContracts] = useState<
    Contract[] | undefined
  >();

  const oldPricesheet: PriceSheetInput = useMemo(() => {
    if (!tariff?.priceSheets) return {};
    const sortedPriceSheets = tariff.priceSheets
      .slice(0)
      .sort(
        (sheetA: PriceSheet, sheetB: PriceSheet) =>
          new Date(sheetB.startDate).getTime() -
          new Date(sheetA.startDate).getTime(),
      );
    if (tariff.priceSheets[0].name === newPriceSheet.name) {
      return sortedPriceSheets[1];
    }
    return sortedPriceSheets[0];
  }, [tariff, newPriceSheet.name]);

  // We can currently only create Pricesheet Change Documents for
  // Pricesheets with one energy price. Once we support documents with
  // local and residual prices this condition can be removed.
  const priceSheetsHaveSingleEnergyPrice =
    oldPricesheet?.energyPrice && newPriceSheet.energyPrice;

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        contentLabel="Create-PriceSheet-Modal"
        title="Preisblatt hinzufügen"
        maxWidth="sm"
        minWidth={400}
      >
        <CreateFlow
          editContainer={CreatePricesheetContainer}
          kind="Preisblatt"
          onDone={onClose}
          onAbort={onClose}
          variables={{
            tariff,
            newPriceSheet,
            setNewPriceSheet,
            setAffectedContracts,
          }}
        />
      </Modal>
      {affectedContracts && priceSheetsHaveSingleEnergyPrice && (
        <CreatePricesheetChangeDocuments
          oldPricesheet={oldPricesheet}
          newPriceSheet={newPriceSheet}
          contracts={affectedContracts}
          tariff={tariff}
          onClose={() => setAffectedContracts(undefined)}
        />
      )}
    </>
  );
}
