import React from 'react';
import styled from 'styled-components';

import { ProgressLoader } from '../progressLoader';

const SpinnerWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
`;

const ProcessMsg = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;

  .label {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 12px;
  }

  p {
    font-size: 15px;
    line-height: 15px;
    margin: 0;
    color: ${(props) => props.theme.palette.textMuted};
    text-align: center;
  }
`;

export interface ProcessingStepProps {
  expectedApiDurationInSeconds?: number;
  finished?: boolean;
}

export const ProcessingStep: React.FC<ProcessingStepProps> = ({
  expectedApiDurationInSeconds,
  finished,
}) => {
  return (
    <SpinnerWrap>
      <ProgressLoader
        finished={finished}
        durationInSeconds={expectedApiDurationInSeconds}
      />
      <ProcessMsg>
        <span className="label">Fast fertig!</span>
        <p>
          Ihr Bericht wird jetzt erstellt. Bitte haben Sie einen Moment Geduld,
          der Vorgang kann bis zu 1 Minute dauern.
        </p>
      </ProcessMsg>
    </SpinnerWrap>
  );
};
