import React, { useState } from 'react';
import styled from 'styled-components';

import * as Icons from './icons';

export interface EditContainerProps {
  onSuccess?: (result: any, additional: { values: any }) => void;
  onAbort?: () => void;
  variables?: any;
  formVariables?: any;
  values?: any;
  defaultValues?: any;
  refetch?: () => void;
}

export interface EndMessageContainerProps {
  variables: EditContainerProps['variables'];
  result: any;
  onDone: () => void;
  kind: CreateFlowBaseProps['kind'];
}

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;
`;

interface CreateFlowBaseProps {
  kind: string;
  editContainer: React.ComponentType<EditContainerProps>;
  endMessageContainer?: React.ComponentType<EndMessageContainerProps>;
  variables?: any;
  formVariables?: any;
  values?: any;
  defaultValues?: any;
  onDone: (result?: any, additional?: { values: any }) => void;
  onSuccess?: (result: any, additional: { values: any }) => void;
  onAbort?: () => void;
}

function CreateFlow({
  kind,
  editContainer: EditContainer,
  endMessageContainer: EndMessageContainer,
  variables,
  formVariables,
  values,
  defaultValues,
  onAbort,
  onDone,
  onSuccess,
}: CreateFlowBaseProps) {
  const [result, setResult] = useState<null | [any, any]>(null);
  const [isComplete, setIsComplete] = useState(false);

  if (isComplete) {
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {EndMessageContainer ? (
          <EndMessageContainer
            variables={variables}
            result={result![0]}
            onDone={() => onDone(...result!)}
            kind={kind}
          />
        ) : (
          <Center data-testid="create-flow-success">
            <div>
              <Icons.Checkmark size={60} color="#A8E015" />
            </div>
            {kind} erfolgreich hinzugefügt.
          </Center>
        )}
      </>
    );
  }

  return (
    <EditContainer
      onSuccess={(res: any, additional: { values: any }) => {
        setResult([res, additional]);
        setIsComplete(true);
        if (onSuccess) {
          onSuccess(res, additional);
        }
      }}
      onAbort={() => {
        if (onAbort) {
          onAbort();
        } else {
          onDone();
        }
      }}
      variables={variables}
      formVariables={formVariables}
      values={values}
      defaultValues={defaultValues}
    />
  );
}

export default CreateFlow;
