import React from 'react';
import { Icons, Label } from '@ampeersenergy/ampeers-ui-components';
import { useFormikContext } from 'formik';
import styled from 'styled-components';

import { GraphqlFormField } from '../../../components/graphql-form/render';
import { FormVariablesAccountingWorkflow } from '../create/types';
import { InvoiceCycle, ReadPlantsSimpleQuery } from '../../../graphql-types';
import { TooltipInfo } from '../../../components/TooltipInfo';

import {
  PeriodEnum,
  StandardAccountingInvoice,
  YearlyAccountingInvoice,
} from './dashboardAccountingTypes';

export const periodTexts = {
  [InvoiceCycle.Monthly]: 'monatliche Abrechnungen',
  [InvoiceCycle.Quarterly]: 'quartalweise Abrechnungen',
  [InvoiceCycle.HalfYearly]: 'halbjährliche Abrechnungen',
  [InvoiceCycle.Yearly]: 'jährliche Abrechnungen',
  [PeriodEnum.Final]: 'Schlussrechnungen',
};

const MutedText = styled.span`
  color: ${({ theme }) => theme.palette.textMuted};
  font-weight: 400;
`;

export default function AccountingWorkflowFormInnerDashboard({
  invoicesIncluded,
}: {
  invoicesIncluded?: (
    | YearlyAccountingInvoice[0]
    | StandardAccountingInvoice[0]
  )[];
}) {
  const { values, touched, setTouched, setFieldValue, initialValues } =
    useFormikContext<FormVariablesAccountingWorkflow>();
  const { invoiceCycle, plantsToAccount } = values;
  const { plantsToAccount: initialPlantsToAccount } = initialValues;
  const text = periodTexts[invoiceCycle || InvoiceCycle.Yearly];

  const resetPlantsToAccount = (plantId: string) => {
    if (!touched.plantsToAccount) {
      setFieldValue('plantsToAccount', [plantId]);
      setTouched({ ...touched, plantsToAccount: true });
    }
  };

  React.useEffect(() => {
    if (plantsToAccount?.length === 0) {
      setFieldValue('plantsToAccount', initialValues.plantsToAccount);
      setTouched({ ...touched, plantsToAccount: false });
    }
  }, [
    plantsToAccount,
    setFieldValue,
    initialValues.plantsToAccount,
    setTouched,
    touched,
  ]);

  const invoicesSelectedForPlant = invoicesIncluded?.filter(
    (invoiceToAccount) =>
      plantsToAccount
        ?.map((plantId) => plantId.toString())
        .includes(invoiceToAccount?.plantId!),
  );
  return (
    <>
      {/* Kundenanlagen */}
      <Label>
        Abrechnung auf Kundenanlagen beschränken{' '}
        <MutedText>(optional)</MutedText>
        <TooltipInfo
          id="plantid"
          text="Wähle eine oder mehrere Kundenanlagen aus um die Abrechnung auf diese Kundenanlagen zu beschränken."
        />
      </Label>
      <GraphqlFormField
        name="plantsToAccount"
        placeholder=""
        label=""
        relation="Plant"
        onChange={(props: any) => {
          resetPlantsToAccount(props.id);
        }}
        filter={(plantsResponse: ReadPlantsSimpleQuery['readPlants']) =>
          plantsResponse.filter((plant) =>
            initialPlantsToAccount?.includes(+plant.id),
          )
        }
        multiselect
      />
      {/* Abrechnungsanzahl/-typ */}
      <Label>Abrechnungsanzahl/-typ </Label>
      <>
        <Icons.AccountingRecuring size={20} />{' '}
        {invoicesSelectedForPlant?.length} {text}{' '}
      </>

      {/* Name */}
      <GraphqlFormField name="name" label="Name" placeholder="Name" />
      {/* Start AbrechnungsPeriode */}
      <GraphqlFormField
        name="paymentPeriodStartAt"
        label="Start Abrechnungsperiode"
      />
      {/* Ende AbrechnungsPeriod */}
      <GraphqlFormField
        name="paymentPeriodEndAt"
        label="Ende Abrechnungsperiode"
        disabled
      />
    </>
  );
}
