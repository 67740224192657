import React from 'react';
import { Hint, SubTitle } from '@ampeersenergy/ampeers-ui-components';

import { SettingsTitle } from '../style';
import GraphQlForm from '../../../components/graphql-form';
import { useReadOpsClientSettingsQuery } from '../../../graphql-types';
import { GraphqlFormField } from '../../../components/graphql-form/render';
import readOpsClientSettings from '../../../queries/readOpsClientSettings';
import { transformNullToUndefined } from '../../../helpers/utils';
import { formatDate } from '../../../helpers/formatStrings';

export default function OpsClientSettings() {
  const { data: opsClientSettings, loading } = useReadOpsClientSettingsQuery();

  const initialValues = React.useMemo(() => {
    if (
      opsClientSettings?.readOpsClientSettings &&
      Object.keys(opsClientSettings?.readOpsClientSettings).length > 0
    ) {
      return transformNullToUndefined(opsClientSettings.readOpsClientSettings);
    }
  }, [opsClientSettings]);

  return (
    <div>
      <SettingsTitle> OPS Client Settings </SettingsTitle>
      <GraphQlForm
        submitAllValues
        startInEdit
        hideCancel
        isLoading={loading}
        mutation="updateOpsClientSettings"
        readDocumentFields={['success']}
        refetchQueries={[
          {
            query: readOpsClientSettings,
          },
        ]}
        values={initialValues}
      >
        <SubTitle>Sollstellung</SubTitle>
        <GraphqlFormField name="debtorLabel" label="Sollstellung aktiviert" />
        <Hint>
          Das Setzen der Sollstellung erfordert die Angabe einer Debitorenummer
          beim Erstellen eines Vertrages.
        </Hint>
        <GraphqlFormField
          name="clientStartDate"
          label="Allgemeines Startdatum für Abrechnungen"
          type="Date"
          formatValue={(inputValue: string) => formatDate(inputValue)}
        />
        <SubTitle>BCC Rechnungsversand</SubTitle>
        <Hint>
          Wenn eine e-Mailaddresse hinterlegt ist, wird beim B2C
          Rechnungsversand eine Blind-Copy and die hinterlegte Adresse
          versendet.
        </Hint>
        <GraphqlFormField name="bccReceiver" />
      </GraphQlForm>
    </div>
  );
}
