import gql from 'graphql-tag';

export default gql`
  mutation deleteMetadata(
    $contractLabel: ID!
    $customerLabel: ID!
    $metadata: CreateUpdateMetadataInput!
  ) {
    deleteMetadata(
      contractLabel: $contractLabel
      customerLabel: $customerLabel
      metadata: $metadata
    ) {
      key
      value
    }
  }
`;
