import React from 'react';
import styled from 'styled-components';
import { Relation, FlexRow, Icons } from '@ampeersenergy/ampeers-ui-components';
import ContentLoader from 'react-content-loader';

import { InlineErrorMessage } from '../../../../components/errorMsg';
import { useResolveById } from '../../../../components/relation/useResolveById';
import { getRelationIcon } from '../../../../components/relation/getRelationIcon';
import { RelationFormattersMap } from '../../../../helpers/formatStrings';
import { InfoText } from '../style';
import { ValidationError } from '../types';

import { formatType } from './formatters';

export const TitleWrap = styled(FlexRow)`
  align-items: center;
`;

export const IconWrap = styled.div`
  margin-right: 5px;
`;

export function Title({
  type,
  error,
  count,
}: {
  type: string;
  error: string;
  count: number;
}) {
  return (
    <TitleWrap>
      <IconWrap>
        <Icons.Wrong size={30} color="#FF1F00" />
      </IconWrap>
      {count} {formatType(type, count)} mit {error}
    </TitleWrap>
  );
}

export const InfoWrapper = styled(InfoText)`
  padding: 0px 0 20px 0;
`;

export const InfoTitle = styled.div`
  font-weight: bold;
  margin-top: 10px;
  color: #383737;

  &:first-child {
    margin-top: 0;
  }
`;

export const Spacer = styled.div`
  padding-bottom: 30px;
`;

export function TypeIcon({ type, sourceId, contractId }: ValidationError) {
  const { data, loading, error } = useResolveById({
    type: type === 'contractcomponent' ? 'contract' : type,
    id: type === 'contractcomponent' ? String(contractId) : String(sourceId),
  });

  if (loading) {
    return (
      <ContentLoader id="content-loader-modal" speed={2} height={15}>
        <rect x="0" y="0" rx="1" ry="1" width="60px" height="12" />
      </ContentLoader>
    );
  }

  if (error) {
    return <InlineErrorMessage error={error} />;
  }

  const icon = getRelationIcon(
    type === 'contractcomponent' ? 'contract' : type,
  );

  const title =
    RelationFormattersMap[type === 'contractcomponent' ? 'contract' : type](
      data,
    );

  return <Relation icon={icon} title={title} />;
}

export const Errors = styled.div`
  > div {
    margin-bottom: 10px;
  }
`;
