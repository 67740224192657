import React, { useEffect, useState } from 'react';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { useApolloClient } from '@apollo/client';
import { ErrorAlert, useDialog } from '@ampeersenergy/ampeers-ui-components';

import CrmBoardCardEditForm, {
  CrmBardCardEditFormFieldLabels,
  CrmBoardCardEditFormPropType,
} from '../crm-board-card-edit-form/crm-board-card-edit-form';
import { CrmBoardCardContent } from '../crm-board-card/crm-board-card.types';
import { isTrueStringBool } from '../../../../helpers/utils';
import { yupUTCDate } from '../../../../helpers/yupUTCDate';
import { FlexRow } from '../../../../components';
import {
  ContractAddress,
  ContractDocumentData,
  ReadDocumentsDocument,
  usePlantSimpleQuery,
  useReadDocumentsQuery,
  useReadMeterSimpleQuery,
} from '../../../../graphql-types';
import deleteDocumentsMutation from '../../../../queries/deleteDocumentsMutation';

// eslint-disable-next-line import/no-cycle
import { CrmDocumentsSidebar } from './crm-board-card-sidebar';

// use this convenient type to eventually modify the data
// properties which will be exposed to the edit form
export type CrmBoardCardEditType = Omit<
  CrmBoardCardContent,
  'status' | 'created' | 'updated'
>;

export type CrmBoardCardEditPropType = {
  initialValues?: Partial<CrmBoardCardEditType>;
  id?: string;
  labels?: Partial<CrmBardCardEditFormFieldLabels>;
  onSubmit: (values: CrmBoardCardEditType) => void;
  submitLabel?: string;
  metadata: { created: Date | undefined; updated: Date | undefined };
  setDirty: (isDirty: boolean) => void;
};

const defaultValues: CrmBoardCardEditType = {
  isperson: true,
  prefix: '',
  name: '',
  companyname: '',
  co: '',
  contractStartDate: undefined,
  contractEndDate: undefined,
  street: '',
  streetNo: '',
  zipCode: '',
  city: '',
  expectedConsumption: undefined,
  reductionStartDate: undefined,
  reduction: undefined,
  mail: '',
  phone: '',
  plantid: undefined,
  tariffid: undefined,
  meterid: undefined,
  notes: '',
  shippingaddressstreet: '',
  shippingaddressstreetnr: '',
  shippingaddresszipcode: '',
  shippingaddresscity: '',
  leadorigin: 'other',
};

const defaultLabels: CrmBardCardEditFormFieldLabels = {
  isperson: 'isperson',
  prefix: 'prefix',
  name: 'Name',
  companyname: 'Firmenname',
  co: 'c/o',
  contractStartDate: 'Geplanter Lieferbeginn',
  contractEndDate: 'Geplantes Lieferende',
  street: 'street',
  streetNo: 'street no',
  zipCode: 'zip code',
  city: 'city',
  expectedConsumption: 'expected consumption',
  reductionStartDate: 'Beginn Abschlagszahlung',
  reduction: 'reduction',
  mail: 'mail',
  phone: 'phone',
  plantid: 'plantid',
  workspaceid: 'workspaceid',
  tariffid: 'tariffid',
  meterid: 'meterid',
  notes: 'notes',
  shippingaddressstreet: 'street',
  shippingaddressstreetnr: 'street no',
  shippingaddresszipcode: 'zip code',
  shippingaddresscity: 'city',
  leadorigin: 'Lead-Quelle',
};

// prepare form
const CrmBoardCardEditFormWrapper = withFormik<
  CrmBoardCardEditFormPropType,
  CrmBoardCardEditType
>({
  mapPropsToValues: ({ initialValues }) => {
    return {
      isperson:
        initialValues?.isperson !== undefined
          ? initialValues?.isperson
          : defaultValues.isperson,
      prefix: isTrueStringBool(initialValues?.isperson ?? true)
        ? initialValues?.prefix || defaultValues.prefix
        : '',
      name: isTrueStringBool(initialValues?.isperson ?? true)
        ? initialValues?.name || defaultValues.name
        : '',
      companyname: isTrueStringBool(initialValues?.isperson ?? true)
        ? ''
        : initialValues?.companyname || defaultValues.companyname,
      co: isTrueStringBool(initialValues?.isperson ?? true)
        ? ''
        : initialValues?.co || defaultValues.co,
      contractStartDate:
        initialValues?.contractStartDate || defaultValues?.contractStartDate,
      contractEndDate:
        initialValues?.contractEndDate || defaultValues?.contractEndDate,
      street: initialValues?.street || defaultValues.street,
      streetNo: initialValues?.streetNo || defaultValues.streetNo,
      zipCode: initialValues?.zipCode || defaultValues.zipCode,
      city: initialValues?.city || defaultValues.city,
      expectedConsumption:
        initialValues?.expectedConsumption === null
          ? undefined
          : initialValues?.expectedConsumption ??
            defaultValues.expectedConsumption,
      reductionStartDate:
        initialValues?.reductionStartDate || defaultValues.reductionStartDate,
      reduction: initialValues?.reduction || defaultValues.reduction,
      mail: initialValues?.mail || defaultValues.mail,
      phone: initialValues?.phone || defaultValues.phone,
      plantid: initialValues?.plantid || defaultValues.plantid,
      tariffid: initialValues?.tariffid || defaultValues.tariffid,
      workspaceid: initialValues?.workspaceid || defaultValues.workspaceid,
      meterid: initialValues?.meterid || defaultValues.meterid,
      notes: initialValues?.notes || defaultValues.notes,
      shippingaddressstreet:
        initialValues?.shippingaddressstreet ||
        defaultValues.shippingaddressstreet,
      shippingaddressstreetnr:
        initialValues?.shippingaddressstreetnr ||
        defaultValues.shippingaddressstreetnr,
      shippingaddresszipcode:
        initialValues?.shippingaddresszipcode ||
        defaultValues.shippingaddresszipcode,
      shippingaddresscity:
        initialValues?.shippingaddresscity || defaultValues.shippingaddresscity,
      leadorigin: initialValues?.leadorigin || defaultValues.leadorigin,
    };
  },
  validateOnMount: true,
  handleSubmit: (values, { props: { onSubmit } }) => {
    if (onSubmit !== undefined) {
      onSubmit(values);
    }
  },
})(CrmBoardCardEditForm);

export default function CrmBoardCardEdit(props: CrmBoardCardEditPropType) {
  const {
    initialValues,
    labels: givenLabels,
    onSubmit = () => undefined,
    submitLabel,
    metadata,
    id,
    setDirty,
  } = props;

  const client = useApolloClient();
  const { openDialog } = useDialog();

  // prepare labels
  const labels: CrmBardCardEditFormFieldLabels = {
    ...defaultLabels,
    ...givenLabels,
  };

  const { data: documentsData } = useReadDocumentsQuery({
    variables: {
      entityId: id,
      entityType: 'crmLead',
    },
    skip: !id,
  });

  // prepare schema
  const validationSchema: yup.SchemaOf<CrmBoardCardEditType> = yup
    .object()
    .shape({
      isperson: yup.boolean().required(),
      prefix: yup
        .string()
        .when('isperson', (isperson: boolean, schema: any) => {
          if (isperson) {
            return schema.label(labels.prefix).ensure().required();
          }
          return schema.label(labels.prefix).ensure().notRequired();
        }),
      name: yup
        .string()
        .label(labels.name)
        .ensure()
        .when('isperson', (isperson: boolean, schema: any) => {
          if (isperson) {
            return schema.required();
          }
          return schema.notRequired();
        }),
      companyname: yup
        .string()
        .label(labels.companyname)
        .ensure()
        .when('isperson', (isperson: boolean, schema: any) => {
          if (isperson) {
            return schema.notRequired();
          }
          return schema.required();
        }),
      co: yup.string().notRequired().label(labels.co),
      contractStartDate: yupUTCDate.notRequired(),
      contractEndDate: yupUTCDate
        .when('contractStartDate', (contractStartDate: any, schema: any) => {
          return (
            contractStartDate &&
            schema.min(
              contractStartDate,
              `Das Vertragsende kann nicht vor dem Startdatum des Vertrags liegen.`,
            )
          );
        })
        .notRequired(),
      street: yup.string().label(labels.street).ensure().notRequired(),
      streetNo: yup.string().label(labels.streetNo).ensure().notRequired(),
      zipCode: yup.string().label(labels.zipCode).ensure().notRequired(),
      city: yup.string().label(labels.city).ensure().notRequired(),
      expectedConsumption: yup
        .number()
        .label(labels.expectedConsumption)
        .min(0),
      reductionStartDate: yupUTCDate.notRequired(),
      reduction: yup.number().label(labels.reduction).min(0).notRequired(),
      mail: yup.string().label(labels.mail).email().ensure().notRequired(),
      phone: yup.string().label(labels.phone).ensure().notRequired(),
      plantid: yup.number().label(labels.plantid).notRequired(),
      tariffid: yup.number().label(labels.tariffid).notRequired(),
      workspaceid: yup.string().label(labels.workspaceid).notRequired(),
      meterid: yup.number().label(labels.meterid).notRequired(),
      notes: yup.string().label(labels.notes).ensure().notRequired(),
      shippingaddressstreet: yup
        .string()
        .label(labels.street)
        .ensure()
        .required(),
      shippingaddressstreetnr: yup
        .string()
        .label(labels.streetNo)
        .ensure()
        .required(),
      shippingaddresszipcode: yup
        .string()
        .label(labels.zipCode)
        .ensure()
        .required(),
      shippingaddresscity: yup.string().label(labels.city).ensure().required(),
      leadorigin: yup.string().label(labels.leadorigin).required(),
    });
  // .defined();

  const contractDocumentData = useContractDocumentData(id, initialValues);

  const onDelete = async (hash: string) => {
    try {
      await client.mutate({
        mutation: deleteDocumentsMutation,
        variables: { hash },
        refetchQueries: [
          {
            query: ReadDocumentsDocument,
            variables: {
              entityId: id,
              entityType: 'crmLead',
            },
          },
        ],
      });
    } catch (error) {
      openDialog({
        title: 'Fehler',
        content: (
          <ErrorAlert>
            Beim Löschen des Dokuments ist leider ein Fehler aufgetreten. Bitte
            versuche es erneut oder wende Dich an den Service Desk.
          </ErrorAlert>
        ),
      });
    }
  };

  return (
    <FlexRow>
      <CrmBoardCardEditFormWrapper
        initialValues={initialValues}
        labels={labels}
        onSubmit={onSubmit}
        submitLabel={submitLabel || 'submit'}
        metadata={metadata}
        isEditing={initialValues !== undefined}
        setDirty={setDirty}
        validationSchema={validationSchema}
      />
      {id && (
        <CrmDocumentsSidebar
          documents={documentsData?.readDocuments}
          customerEmail={initialValues?.mail}
          contractId={id}
          documentDeliveryMethod="USER_EMAIL"
          contractDocumentData={contractDocumentData}
          onDelete={onDelete}
        />
      )}
    </FlexRow>
  );
}

function useContractDocumentData(
  cardId?: string,
  initialValues?: Partial<CrmBoardCardEditType>,
): PartialContractDocumentData | undefined {
  const [contractDocumentData, setContractDocumentData] = useState<
    PartialContractDocumentData | undefined
  >(undefined);

  const meterId = initialValues?.meterid?.toString() ?? '';

  const { data: readMeterData } = useReadMeterSimpleQuery({
    variables: { id: meterId },
    skip: meterId === '',
  });

  const plantId = initialValues?.plantid?.toString() ?? '';

  const { data: plantData } = usePlantSimpleQuery({
    variables: { plantId },
    skip: plantId === '',
  });

  useEffect(() => {
    if (cardId && initialValues) {
      const _contractDocumentData = generateContractDocumentData(
        initialValues,
        cardId,
        readMeterData?.readMeter?.meterNumber,
      );

      if (_contractDocumentData) setContractDocumentData(_contractDocumentData);
    }
  }, [cardId, initialValues, readMeterData, plantData]);

  return contractDocumentData;
}

export type PartialContractDocumentData = Omit<
  Partial<ContractDocumentData>,
  'currentAddress' | 'shippingAddress'
> & {
  currentAddress: Partial<ContractAddress>;
} & { shippingAddress: Partial<ContractAddress> };

function generateContractDocumentData(
  initialValues: Partial<CrmBoardCardEditType>,
  cardId: string,
  meterNumber?: string,
): PartialContractDocumentData | undefined {
  const contractDocumentData = {
    prefix: initialValues.prefix,
    name: initialValues.isperson
      ? initialValues.name
      : initialValues.companyname,
    mail: initialValues.mail,
    currentAddress: {
      streetName: initialValues.street,
      streetNumber: initialValues.streetNo,
      zipCode: initialValues.zipCode,
      city: initialValues.city,
    },
    date: new Date().toISOString(),
    shippingAddress: {
      streetName: initialValues.shippingaddressstreet,
      streetNumber: initialValues.shippingaddressstreetnr,
      zipCode: initialValues.shippingaddresszipcode,
      city: initialValues.shippingaddresscity,
    },
    aJV: initialValues.expectedConsumption
      ? Number.parseFloat(String(initialValues.expectedConsumption))
      : undefined,
    meterNumber,
    reduction: initialValues.reduction
      ? Number.parseFloat(String(initialValues.reduction))
      : undefined,
    startOfDelivery: initialValues.contractStartDate
      ? new Date(initialValues.contractStartDate).toISOString()
      : undefined,
    cardId,
    tariffId: initialValues.tariffid?.toString(),
    workspaceId: initialValues.workspaceid?.toString(),
    plantId: initialValues.plantid?.toString(),
  };

  return contractDocumentData;
}
