import gql from 'graphql-tag';

export default gql`
  mutation createBookingsBatched(
    $bookings: [CreateBookingsBatchedInput!]!
    $fileName: String
  ) {
    createBookingsBatched(bookings: $bookings, fileName: $fileName) {
      __typename

      ... on CreateBookingsBatchedSuccess {
        succeeded {
          contractId
          date
          value
          type
        }
        info {
          contractLabel
          bookingDate
          contractEndDate
          contractStartDate
        }
      }
      ... on CreateBookingsBatchedError {
        failed {
          error
          message
          contractLabel
        }
        info {
          contractLabel
          bookingDate
          contractEndDate
          contractStartDate
        }
      }
    }
  }
`;
