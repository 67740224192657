import React from 'react';
import styled from 'styled-components';
import {
  Table,
  Tabs,
  Tab,
  ColumnDefinition,
} from '@ampeersenergy/ampeers-ui-components';

import {
  OpcStepResult,
  OpcStepResultInfo,
  useReadContractExcerptsQuery,
} from '../../../../graphql-types';

type ResultData = {
  name: string;
  customerLabel: string;
  label: string;
  error?: any;
};

export const columns: ColumnDefinition<ResultData>[] = [
  {
    Header: 'Mieter',
    accessor: 'name',
    type: 'string',
  },
  {
    Header: 'KundenNr.',
    accessor: 'customerLabel',
    type: 'string',
  },
  {
    Header: 'VertragsNr.',
    accessor: 'label',
    type: 'string',
  },
];

const columnsWithError: ColumnDefinition<ResultData>[] = [
  ...columns,
  {
    Header: 'Fehler',
    accessor: 'error',
    type: 'string',
  },
];

const Spacer = styled.div`
  margin-top: 10px;
`;

interface OpcResultStepProps {
  result: OpcStepResult;
}

export function OpcResultStep({ result }: OpcResultStepProps) {
  const { passed, errored } = result;

  const ids = React.useMemo(
    () => [
      ...(passed?.map(({ id }) => id.toString()) ?? []),
      ...(errored?.map(({ id }) => id.toString()) ?? []),
    ],
    [errored, passed],
  );

  const { data, loading } = useReadContractExcerptsQuery({
    variables: {
      ids,
    },
    skip: !ids || ids.length === 0,
  });

  const mapper = React.useCallback(
    ({ id, error }: OpcStepResultInfo) => {
      if (data?.readContractExcerpts) {
        const contract = data.readContractExcerpts.find(
          (c) => parseInt(c.id, 10) === id,
        );

        if (contract) {
          return {
            ...contract,
            error,
          };
        }
      }

      return {
        label: id.toString(),
        name: '',
        customerLabel: '',
        meterNumber: '',
        startDate: '',
        endDate: '',
        contractStatus: '',
        error: '',
        projectId: null,
        plantId: null,
      };
    },
    [data?.readContractExcerpts],
  );

  const succeeded = passed?.map(mapper) ?? [];
  const failed = errored?.map(mapper) ?? [];

  return (
    <Tabs>
      <Tab title={`${succeeded?.length ?? 0} Erfolgreich`}>
        <Spacer>
          <Table
            columns={columns}
            data={succeeded}
            filterKind="Erfolgreiche Verträge"
            isLoading={loading}
            openInNewTab
            compact
            withAlternatingRows
            withBoxShadow
            withPagination
            withFilter
          />
        </Spacer>
      </Tab>
      <Tab
        title={`${failed?.length ?? 0} Fehlerhaft`}
        hasNotification={failed?.length !== 0}
      >
        <Spacer>
          <Table
            columns={columnsWithError}
            data={failed}
            filterKind="Fehlerhafte Verträge"
            isLoading={loading}
            openInNewTab
            compact
            withAlternatingRows
            withBoxShadow
            withPagination
            withFilter
          />
        </Spacer>
      </Tab>
    </Tabs>
  );
}
