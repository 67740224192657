import * as React from 'react';
import styled from 'styled-components';
import {
  Table,
  Relation,
  PaddedShadowBox,
  FlexRow,
  ColumnDefinition,
} from '@ampeersenergy/ampeers-ui-components';

import {
  formatDateWithMonthAndYear,
  formatContract,
  formatDate,
  formatEmgEndDates,
} from '../../../helpers/formatStrings';
import { InfoAlert } from '../../../components/alert';
import { useResolveById } from '../../../components/relation/useResolveById';
import { getRelationIcon } from '../../../components/relation/getRelationIcon';

import { Invoice } from './types';

const HintWrapper = styled.div`
  margin-bottom: 16px;
  color: rgb(93, 93, 93);
  white-space: pre-line;
`;

const HINTS = {
  overview: (
    <>
      In diesem Schritt kannst Du alle in der Abrechnungsperiode gebuchten
      Abschlagszahlungen zum Vertrag einsehen. Der Gesamtbetrag der
      Vorauszahlungen wird mit dem ermittelten Rechnungsbetrag verrechnet.
      Außerdem werden alle geleisteten Zahlungen in der Tabelle
      “Buchungsübersicht” auf der Rechnung dargestellt.
    </>
  ),
  summary: `Bitte prüfe nochmals alle erstellten Buchungen. Gibt es keine Fehler kannst Du jetzt auf "Nächster Schritt" klicken. Wende Dich bitte an den AE Service Desk, wenn etwas nicht wie erwartet ist.`,
};

interface ImportDebitPositionsOverviewProps {
  invoices: Invoice[];
  hint?: keyof typeof HINTS;
  contractId: string;
  paymentPeriodStartAt: string;
  paymentPeriodEndAt: string;
}

const RelationExcerptW = styled(PaddedShadowBox)`
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 15px;
`;
const RelationExcerptT = styled.div`
  margin-left: 5px;
  letter-spacing: 0.2px;
  color: #4c4c4c;
  font-weight: bold;
`;
const RelationExcerptC = styled.div`
  background: #fff;
`;

const RelationHeadline = styled(FlexRow)`
  font-size: 15px;
  border-bottom: 1px solid whitesmoke;
  padding-bottom: 10px;
  align-items: center;
`;

const RRW = styled.div`
  margin-right: 5px;

  > div {
    > div {
      font-size: 16px;
    }

    svg {
      width: 18px;
      height: 18px;
    }
  }
`;

function RelationExcerpt({ for: relationFor, title, children }: any) {
  return (
    <RelationExcerptW>
      <RelationHeadline>
        <RRW>{relationFor}</RRW>•<RelationExcerptT>{title}</RelationExcerptT>
      </RelationHeadline>
      <RelationExcerptC>{children}</RelationExcerptC>
    </RelationExcerptW>
  );
}

export interface InvoiceData {
  amount: number;
  date: string;
  name: string;
  ref: string;
  type?: string;
}

export const ImportDebitPositionsOverview: React.FC<
  ImportDebitPositionsOverviewProps
> = ({
  invoices = [],
  hint,
  contractId,
  paymentPeriodStartAt,
  paymentPeriodEndAt,
}) => {
  const columns: ColumnDefinition<Invoice>[] = React.useMemo(
    () => [
      {
        Header: 'Buchungsnummer',
        accessor: 'name',
      },
      {
        Header: 'Buchungstext',
        accessor: 'ref',
      },
      {
        Header: 'Datum',
        accessor: 'date',
        type: 'date',
        Cell: ({ value }) => formatDateWithMonthAndYear(value),
      },
      {
        Header: 'Betrag',
        accessor: 'amount',
        type: 'currency',
      },
    ],
    [],
  );

  const contract = useResolveById({
    type: 'contract',
    id: contractId,
  });

  return (
    <>
      {hint && <HintWrapper>{HINTS[hint]}</HintWrapper>}
      <RelationExcerpt
        for={
          <Relation
            icon={getRelationIcon('contract')}
            title={formatContract(contract.data)}
          />
        }
        title={`Abschläge (${formatDate(paymentPeriodStartAt)} - 
        ${formatEmgEndDates(paymentPeriodEndAt)})`}
      >
        <Table
          columns={columns}
          data={invoices}
          isLoading={contract.loading}
          withPagination
          compact
          initialState={{
            sortBy: [
              {
                id: 'date',
                desc: true,
              },
            ],
          }}
          filterKind="Abschläge"
        />
      </RelationExcerpt>
      {hint && hint === 'overview' && (
        <InfoAlert>
          Abschlagszahlungen werden standardmäßig mit 19 % Umsatzsteuer gebucht.
          Sind davon abweichende Buchungen notwendig, musst Du Dich bitte an den
          AE Service Desk wenden.
        </InfoAlert>
      )}
    </>
  );
};
