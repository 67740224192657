import {
  FlexRow,
  FlexColumn,
  Icons,
  defaultTheme,
  TooltipDark,
} from '@ampeersenergy/ampeers-ui-components';
import * as React from 'react';
import styled from 'styled-components/macro';

import {
  useReadAccountingKpisQuery,
  useReadAccountingOverviewQuery,
} from '../../../graphql-types';
import { ErrorBox } from '../../../components/input';

import { AccountingKpiCard } from './AccountingKpiCard';
import InvoiceMonthCard, { InvoicePeriodLoader } from './InvoiceMonthCard';

const FlexColumnStyled = styled(FlexColumn)`
  gap: 8px;
`;

const AccountingDashboardGrid = styled(FlexRow)`
  gap: 24px;
  align-items: flex-start;
  margin-top: 24px;
`;

const AccountingPeriods = styled(FlexColumnStyled)`
  flex: 1;
  max-width: 680px;
`;

export function AccountingDashboard() {
  const {
    data: accountingOverview,
    loading: overviewLoading,
    error: errorMsg,
  } = useReadAccountingOverviewQuery({
    fetchPolicy: 'cache-and-network',
  });
  const { data: kpiData, loading: kpiLoading } = useReadAccountingKpisQuery();
  const { periods } = accountingOverview?.readAccountingOverview || {};

  const hasContracts = periods && periods?.length > 0;

  return (
    <>
      <AccountingDashboardGrid>
        <AccountingPeriods>
          <>
            {errorMsg && (
              <ErrorBox>
                Es gab ein Problem mit der Anzeige der Übersicht:{' '}
                {errorMsg.message}
              </ErrorBox>
            )}
            {overviewLoading && !accountingOverview && <InvoicePeriodLoader />}

            {(!overviewLoading || accountingOverview) && !errorMsg ? (
              <>
                {hasContracts ? (
                  periods
                    ?.map((period) => (
                      <InvoiceMonthCard period={period} key={period.start} />
                    ))
                    .reverse()
                ) : (
                  <p
                    css={`
                      display: flex;
                      align-items: center;
                    `}
                  >
                    <Icons.Checkmark size={30} />
                    Keine Verträge zum Abrechnen.
                  </p>
                )}
              </>
            ) : null}
          </>
        </AccountingPeriods>

        <FlexColumnStyled>
          <AccountingKpiCard
            icon={
              <Icons.Contract
                size={30}
                color={defaultTheme.palette.info.color}
              />
            }
            title="Ausstehende Verträge"
            amount={
              accountingOverview?.readAccountingOverview.totalPendingInvoices
            }
            isLoading={overviewLoading}
          >
            Verträge, die noch nicht abgerechnet wurden.
          </AccountingKpiCard>
          <AccountingKpiCard
            icon={
              <Icons.Accounting
                size={30}
                color={defaultTheme.palette.info.color}
              />
            }
            title="Erstellte Abrechnungen"
            amount={kpiData?.readAccountingKpis.totalInvoicesMandate}
            isLoading={kpiLoading}
          />
          <AccountingKpiCard
            icon={
              <Icons.InvoiceCancelled
                size={30}
                color={defaultTheme.palette.info.color}
              />
            }
            title="Stornierte Abrechnungen"
            amount={kpiData?.readAccountingKpis.cancelledInvoicesMandate}
            isLoading={kpiLoading}
          >
            Jede Stornorechnung kostet im Schnitt{' '}
            <TooltipDark
              text="Berechnung beruht auf Erfahrungswerten von AMPEERS ENERGY"
              id="storno-tooltip"
            >
              <em data-tip data-for="storno-tooltip">
                53,50 €
              </em>
            </TooltipDark>
            . Wir können dir helfen deine Prozesskosten zu optimieren.
          </AccountingKpiCard>
        </FlexColumnStyled>
      </AccountingDashboardGrid>
    </>
  );
}
