import { IFrameModal } from '@ampeersenergy/ampeers-ui-components';
import React, { useState } from 'react';

import { buildDocumentUrl } from '../helpers/buildDocumentUrl';

export interface ModalLinkProps {
  children: React.ReactNode;
  url: string;
  modalTitle: string;
  modalAccessibilityContentLabel: string;
  modalIncludeTokeninURL?: boolean;
  filename?: string;
}

function ModalLink({
  url,
  modalTitle,
  modalAccessibilityContentLabel,
  modalIncludeTokeninURL,
  filename,
  children,
}: ModalLinkProps) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const closeModal = () => setModalIsOpen(false);
  const documentUrl = buildDocumentUrl(url, false, modalIncludeTokeninURL);

  const handeClick = () => {
    setModalIsOpen(true);
  };

  return (
    <>
      <IFrameModal
        contentLabel={modalAccessibilityContentLabel}
        closeModal={closeModal}
        title={modalTitle}
        src={`${documentUrl}${filename ? `&filename=${filename}` : ''}`}
        isOpen={modalIsOpen}
      />
      <div
        role="button"
        tabIndex={0}
        onKeyPress={handeClick}
        onClick={handeClick}
      >
        {children}
      </div>
    </>
  );
}

export default ModalLink;
