import React from 'react';

import { HistoricEntry } from '../../../../../components';
import { formatMeterReadingValue } from '../../../../../helpers/formatStrings';
import { Consumption } from '../../../../../graphql-types';
import { isDefined } from '../../../../../helpers/typeUtils';

export function ConsumptionValue({
  consumptionValues,
  loading,
}: {
  consumptionValues: Consumption[];
  loading: boolean;
}) {
  const historicConsumption: Array<{
    value: number;
    date: {
      startDate: string;
      endDate?: string;
    };
  }> = consumptionValues
    ? consumptionValues
        .map((consumption: Consumption) => ({
          date: {
            startDate: consumption.startDate,
            endDate: consumption.endDate,
          },
          value: consumption.value,
        }))
        .filter(isDefined)
        .sort(
          (a, b) =>
            new Date(b.date.startDate).getTime() -
            new Date(a.date.startDate).getTime(),
        )
    : [];

  return (
    <HistoricEntry
      title="Verbrauchswert"
      values={historicConsumption}
      isLoading={loading}
      formatValue={formatMeterReadingValue}
    />
  );
}
