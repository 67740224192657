import styled from 'styled-components';

export const StyledLabel = styled.label`
  margin-left: 5px;
  font-size: 14px;
`;

export const Hidden = styled.div`
  display: none;
`;
