import * as yup from 'yup';

/**
 * we want to validate if the string adheres
 * to the german number notation which uses:
 * `.` as a thousand separator
 * `,` as a decimal separator
 * the decimal places are optional
 * up to 2 decimal places are allowed
 *
 * so the following is allowed:
 * 1,98
 * 1
 * 1.987,6
 * 1.987,65
 * 1987,65
 * 1987
 *
 * the following is not allowed:
 * 1,987
 * 1,987.65 (english version is  not supported)
 * 1987.65 (english version is  not supported)
 */
const validate = (number: string) =>
  /^(\d*|(\d{1,3}(\.\d{3})*))?(,\d{1,2})?$/.test(number);

export const yupNumberAsString = yup
  .string()
  .test(
    'number-as-string',
    'Muss eine Zahl sein',
    (value: string | undefined) => {
      if (!value) return true;
      return validate(value);
    },
  );
