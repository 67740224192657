import React, {
  ChangeEvent,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import styled from 'styled-components';
import { useFormikContext } from 'formik';

import { GraphqlFormField } from '../../../../components/graphql-form/render';
import { FlexRow, Flex, Label } from '../../../../components';

const Hidden = styled.div`
  display: none;
`;

const StyledLabel = styled.label`
  margin-left: 5px;
  font-size: 14px;
`;

const Wrap = styled.div`
  min-width: 690px;
`;

interface MasterDataFormProps {
  setFixed: (fixed: boolean) => void;
  isFixed: boolean;
}

const MasterDataForm = forwardRef(
  ({ setFixed, isFixed }: MasterDataFormProps, ref?: any) => {
    const { getFieldProps, setFieldValue } = useFormikContext();
    const [nameExternal, setNameExternal] = useState('');
    const [validityStartDate, setValidityStartDate] = useState('');

    useImperativeHandle(ref, () => ({
      getValue(path: string) {
        return getFieldProps(path).value;
      },
    }));

    useEffect(() => {
      if (
        !isFixed &&
        getFieldProps('validityEndDate').value !== '' &&
        getFieldProps('validityEndDate').value !== undefined
      ) {
        setFieldValue('validityEndDate', '', true);
      }
    }, [getFieldProps, isFixed, setFieldValue]);

    useEffect(() => {
      const name = nameExternal || getFieldProps('nameExternal').value;
      const date =
        validityStartDate || getFieldProps('validityStartDate').value;
      const yearStart = new Date(date).getFullYear();
      if (name && yearStart) {
        setFieldValue('priceSheet.name', `${name} ${yearStart}`);
      }
    }, [nameExternal, validityStartDate, getFieldProps, setFieldValue]);

    return (
      <Wrap>
        <FlexRow>
          <Flex>
            <GraphqlFormField
              name="nameExternal"
              onInput={(e: ChangeEvent<HTMLInputElement>) => {
                setNameExternal(e.target.value);
              }}
            />
            <GraphqlFormField name="nameInternal" />
            <GraphqlFormField
              name="validityStartDate"
              onInput={(e: ChangeEvent<HTMLInputElement>) => {
                setFieldValue('priceSheet.startDate', e.target.value);
                setValidityStartDate(e.target.value);
              }}
            />
            <Label>Ende der Gültigkeit</Label>
            <input
              type="radio"
              id="validityEndDate-open"
              data-testid="validityEndDate-open"
              checked={!isFixed}
              onClick={() => setFixed(false)}
              readOnly
            />
            <StyledLabel htmlFor="validityEndDate-open">
              Offenes Ende
            </StyledLabel>
            <br />
            <input
              type="radio"
              id="validityEndDate-fixed"
              data-testid="validityEndDate-fixed"
              checked={isFixed}
              onClick={() => setFixed(true)}
              readOnly
            />
            <StyledLabel htmlFor="validityEndDate-fixed">
              Festes Ende
            </StyledLabel>
            <br />
            {isFixed && (
              <GraphqlFormField name="validityEndDate" label={false} />
            )}
            {!isFixed && (
              <Hidden>
                <GraphqlFormField name="validityEndDate" label={false} />
              </Hidden>
            )}
          </Flex>
        </FlexRow>
      </Wrap>
    );
  },
);

export default MasterDataForm;
