import * as React from 'react';
import {
  AlertRetryable,
  Button,
  CheckboxSimple,
  Circles,
  Hint,
  Label,
  useDialog,
} from '@ampeersenergy/ampeers-ui-components';
import { useFormikContext } from 'formik';

import 'styled-components/macro';
import { GraphqlFormField } from '../../../components/graphql-form/render';
import { Grid } from '../../../components/layout';
import {
  ClientSmtpConfig,
  useReadCurrentUserQuery,
  useSendTestMailMutation,
} from '../../../graphql-types';
import { useGraphqlForm } from '../../../components/graphql-form/hooks/useGraphqlForm';
import { useHasRole } from '../../../components/useHasRole';

const OPERATIONS_EMAIL = 'servicedesk@ampeersenergy.de';

export function EmailSettingsForm() {
  const { getFieldProps, setFieldValue, initialValues, submitCount } =
    useFormikContext<ClientSmtpConfig>();
  const { isEditing } = useGraphqlForm();
  const { openDialog } = useDialog();
  const { data: user } = useReadCurrentUserQuery();
  const { hasRole: isOperationsUser } = useHasRole('ae-operations');

  const [updatePassword, setUpdatePassword] = React.useState(false);

  React.useEffect(() => {
    setUpdatePassword(!initialValues?.active);
  }, [initialValues?.active]);

  const [sendTestMail, { loading: testMailLoading }] =
    useSendTestMailMutation();

  const [isActive, setActive] = React.useState<boolean>(false);

  React.useEffect(() => {
    setActive(getFieldProps('active').value ?? false);
  }, [getFieldProps]);

  React.useEffect(() => {
    if (!isEditing && updatePassword) {
      setUpdatePassword(false);
    }
  }, [isEditing, updatePassword]);

  const handleSetActive = (_isActive: boolean) => {
    setActive(_isActive);
    setFieldValue('active', _isActive);
  };

  const receiver = isOperationsUser
    ? OPERATIONS_EMAIL
    : user?.readCurrentUser?.email;

  const handleTestMail = async () => {
    try {
      await sendTestMail();

      openDialog({
        title: 'Test E-Mail gesendet',
        content: (
          <>
            <p>
              Die Test E-Mail wurde an die Adresse <em>{receiver}</em> gesendet.
              Bitte prüfe deinen Posteingang, ob die E-Mail zugestellt wurde.
            </p>
            <p>Andernfalls prüfe bitte deine SMTP-Konfiguration.</p>
          </>
        ),
      });
    } catch (error) {
      openDialog({
        title: 'Fehler',
        content: <AlertRetryable error={error} />,
      });
    }
  };

  const disablePreviewButton =
    testMailLoading || !isActive || (submitCount === 0 && !initialValues.host);

  return (
    <div>
      <CheckboxSimple
        id="active"
        label="Eigenen E-Mail Server für Versand von Dokumenten nutzen"
        checked={isActive}
        onChange={handleSetActive}
        disabled={!isEditing}
      />
      <GraphqlFormField name="mailFrom" disabled={!isActive} />
      <Grid css="grid-auto-columns: 1fr 1fr;">
        <GraphqlFormField name="host" disabled={!isActive} />
        <GraphqlFormField name="port" disabled={!isActive} />
      </Grid>
      <Hint>Der Mailserver muss mindestens TLS 1.2 unterstützen.</Hint>

      <Grid css="grid-auto-columns: 1fr 1fr;">
        <GraphqlFormField name="auth.user" disabled={!isActive} />
        {isEditing && updatePassword ? (
          <GraphqlFormField
            name="auth.pass"
            type="password"
            disabled={!isActive}
          />
        ) : (
          <div>
            <Label>Passwort</Label>
            <div
              css={{
                height: isEditing ? '48px' : 'auto',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Circles />
            </div>
            {isEditing ? (
              <Button secondary bold onClick={() => setUpdatePassword(true)}>
                Passwort ändern
              </Button>
            ) : null}
          </div>
        )}
      </Grid>
      <Grid>
        <div>
          <GraphqlFormField name="secure" disabled={!isActive} default />
          <GraphqlFormField name="tls" disabled={!isActive} default />
        </div>
      </Grid>

      <div
        css={`
          margin-top: 10px;
        `}
      >
        <Button
          secondary
          disabled={disablePreviewButton}
          onClick={handleTestMail}
        >
          Test E-Mail verschicken
        </Button>
      </div>
    </div>
  );
}
