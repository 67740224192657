import * as yup from 'yup';

const yupMelo = yup
  .string()
  .length(33, 'Die Melo-Id muss genau 33 Zeichen haben.')
  .matches(
    /^[A-Za-z]{2}\d{11}[A-Za-z0-9]{20}$/,
    'Die Melo-Id muss mit 2 Buchstaben beginnen, gefolgt von 11 Ziffern, und mit 20 alphanumerischen Zeichen enden.',
  );

export default yupMelo;
