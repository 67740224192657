import {
  AlertRetryable,
  PaddedShadowBox,
  PageTitle,
} from '@ampeersenergy/ampeers-ui-components';
import { useApolloClient } from '@apollo/client';
import * as React from 'react';
import styled from 'styled-components';

import {
  AccountingRunWorkflowState,
  AccountingRunWorkflowStep,
  AccountingRunWorkflowStepName,
  AccountingRunWorkflowStepResult,
  ActionOption,
  OverviewStepResult,
  StepStatus,
  TransitionAccountingRunWorkflowDocument,
  TransitionAction,
} from '../../../graphql-types';

import {
  getLabelForStepName,
  translateActivityErrorTitle,
  translateErrorMessage,
} from './accountingRunHelper';
import { StepAction } from './StepAction';
import { StepActive } from './StepActive';
import { AutarkyStep } from './autarkyStep/AutarkyStep';
import { DownpaymentStartStep } from './downPaymentStartStep';
import { OpcResultStep } from './opcResultStep';
import { OverviewStepResults } from './overviewStep/OverviewStep';
import { ValidateContractConsumptionStep } from './validateContractConsumptionStep';
import type {
  AccountingRunStepRef,
  AccountingRunTransitionConfig,
} from './types';
import { FinalStep } from './finalStep/FinalStep';
import OverviewStepNoContractsFound from './overviewStep/OverviewStepNoContractsFound';
import WorkflowStepsProvider from './WorkflowStepsProvider';

export const Wrapper = styled(PaddedShadowBox)`
  padding: 16px 16px 32px;
  flex: 1;
`;

const StepTitle = styled(PageTitle)`
  margin-top: 0;
`;

interface StepsDetailsContentProps {
  step: AccountingRunWorkflowStep;
  workflow?: AccountingRunWorkflowState;
}

const StepsDetailsContent = React.forwardRef<
  AccountingRunStepRef,
  StepsDetailsContentProps
>(({ step, workflow }, ref) => {
  switch (step?.result?.__typename) {
    case 'OverviewStepResult':
      return <OverviewStepResults result={step.result} />;
    case 'AutarkyStepResult':
      return (
        <AutarkyStep
          status={step.status}
          result={step.result}
          isEditable={step.active}
          ref={ref}
          stepAction={step.action}
          paymentPeriodStartAt={workflow?.meta.paymentPeriodStartAt}
          paymentPeriodEndAt={workflow?.meta.paymentPeriodEndAt}
        />
      );
    case 'ValidateContractConsumptionsStepResult':
      return <ValidateContractConsumptionStep result={step.result} />;
    case 'DownpaymentStartStepResult':
      return (
        <DownpaymentStartStep
          result={step.result}
          isEditable={step.active}
          overviewStep={
            workflow?.steps.find((s) => isOverviewStepResult(s.result))
              ?.result as OverviewStepResult | undefined
          }
          ref={ref}
        />
      );
    case 'OpcStepResult':
      return <OpcResultStep result={step.result} />;
    default:
  }

  if (step.name === AccountingRunWorkflowStepName.FinalStep && workflow) {
    return (
      <FinalStep
        steps={workflow.steps}
        doneAt={workflow.doneAt}
        meta={workflow.meta}
        startedAt={workflow.startedAt}
      />
    );
  }

  return null;
});

interface StepsDetailsProps {
  workflowId: string;
  step?: AccountingRunWorkflowStep;
  workflow?: AccountingRunWorkflowState;
  onStepAction: () => Promise<void>;
}

/**
 * TODO: Think about replacing `useRef` by rendering the buttons
 * in the step-specific component and passing the `handleProceed`
 * and `handleRetry` functions directly.
 */
export function StepsDetails({
  workflowId,
  step,
  workflow,
  onStepAction,
}: StepsDetailsProps) {
  const stepRef = React.useRef<AccountingRunStepRef>();
  const client = useApolloClient();
  const [isLoading, setIsLoading] = React.useState(false);
  const [mutationError, setMutationError] = React.useState<Error | undefined>();

  const handleAction = React.useCallback(
    async (action: TransitionAction) => {
      setMutationError(undefined);

      if (!step?.active && action === TransitionAction.Proceed) {
        await onStepAction();
      } else {
        const stepTransitionConfig: AccountingRunTransitionConfig | undefined =
          stepRef?.current?.getTransitionConfig?.();

        const isValid = (await stepRef?.current?.isValid?.()) ?? true;
        if (action === TransitionAction.Proceed && !isValid) {
          return;
        }

        setIsLoading(true);

        try {
          const variables =
            action === TransitionAction.Retry
              ? { action }
              : stepTransitionConfig?.variables;

          await client.mutate({
            mutation:
              action === TransitionAction.Proceed &&
              stepTransitionConfig?.mutation
                ? stepTransitionConfig?.mutation
                : TransitionAccountingRunWorkflowDocument,
            variables: {
              workflowId,
              ...(variables ?? { action }),
            },
          });

          setIsLoading(false);

          await onStepAction();
        } catch (error) {
          if (error instanceof Error) {
            setMutationError(error);
          }
          setMutationError(new Error(JSON.stringify(error)));
          setIsLoading(false);
        }
      }
    },
    [client, step?.active, onStepAction, workflowId],
  );

  if (!step) {
    return (
      <Wrapper>
        <p>Kein Schritt ausgewählt.</p>
      </Wrapper>
    );
  }

  const name = getLabelForStepName(step?.name);

  if (step.error?.message === 'NOTHING_TO_ACCOUNT') {
    return (
      <Wrapper>
        <StepTitle>{name}</StepTitle>
        <OverviewStepNoContractsFound />
      </Wrapper>
    );
  }

  if (step.error?.message) {
    return (
      <Wrapper>
        <StepTitle>{name}</StepTitle>
        <AlertRetryable
          title={translateActivityErrorTitle(step.error?.activityType)}
          message={translateErrorMessage(step.error.message)}
          retryable={false}
        />
        {step.active && step.action !== ActionOption.None ? (
          <StepAction
            step={step}
            isLoading={isLoading}
            onAction={handleAction}
          />
        ) : null}
      </Wrapper>
    );
  }

  if (step.status === StepStatus.Active) {
    return (
      <Wrapper>
        <StepTitle>{name}</StepTitle>
        <StepActive />
      </Wrapper>
    );
  }

  return (
    <WorkflowStepsProvider>
      <Wrapper>
        <StepTitle>{name}</StepTitle>
        {mutationError && <AlertRetryable error={mutationError} />}
        <StepsDetailsContent step={step} workflow={workflow} ref={stepRef} />
        {step.active ? (
          <StepAction
            step={step}
            isLoading={isLoading}
            onAction={handleAction}
          />
        ) : null}
      </Wrapper>
    </WorkflowStepsProvider>
  );
}

function isOverviewStepResult(
  result?: AccountingRunWorkflowStepResult | null,
): result is OverviewStepResult {
  return result?.__typename === 'OverviewStepResult';
}
