/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
import React, { CSSProperties } from 'react';

export interface RipplesProps {
  style: CSSProperties;
  nextStyle: CSSProperties;
  persist?: boolean;
}

type State = Readonly<{
  rippleStyle: CSSProperties;
  boxStyle: CSSProperties;
}>;

const boxStyle: CSSProperties = {
  position: 'relative',
  display: 'inline-flex',
  overflow: 'hidden',
};

const rippleStyle: CSSProperties = {
  position: 'absolute',
  borderRadius: '50%',
  width: 35,
  height: 35,
  pointerEvents: 'none',
  transform: 'translate(-50%, -50%)',
  opacity: 0,
};

export default class Ripple extends React.PureComponent<RipplesProps, State> {
  timer: number = 0;

  during: number = 600;

  constructor(props: RipplesProps) {
    super(props);

    this.state = {
      rippleStyle: {},
      boxStyle: {},
    };
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  static getDerivedStateFromProps(props: RipplesProps, state: State) {
    if (props.style.backgroundColor === state.boxStyle.backgroundColor) {
      return null;
    }

    return {
      boxStyle: {},
    };
  }

  onClick = (ev: React.MouseEvent<HTMLDivElement>) => {
    if (process.env.NODE_ENV === 'test') return;

    const {
      pageX,
      pageY,
      currentTarget: { offsetLeft, offsetTop, offsetWidth, offsetHeight },
    } = ev;
    const { persist, nextStyle } = this.props;

    const left = pageX - offsetLeft;
    const top = pageY - offsetTop;
    const size = Math.max(offsetWidth, offsetHeight);

    this.setState(
      (state) => {
        return {
          rippleStyle: {
            ...state.rippleStyle,
            left,
            top,
            opacity: 1,
            transform: 'translate(-50%, -50%)',
            transition: 'initial',
          },
          boxStyle: persist
            ? {
                ...nextStyle,
                transition: 'background, color 0s linear',
                transitionDelay: `150ms`,
              }
            : {},
        };
      },
      () => {
        this.timer = setTimeout(() => {
          this.setState((state) => ({
            rippleStyle: {
              ...state.rippleStyle,
              opacity: 0,
              transform: `scale(${size / 9})`,
              transition: `all ${this.during}ms`,
            },
          }));
        }, 50) as any;
      },
    );
  };

  render() {
    const { children, style, nextStyle } = this.props;

    return (
      <div
        style={{
          ...style,
          ...boxStyle,
          ...this.state.boxStyle,
        }}
        onClick={this.onClick}
        role="button"
        tabIndex={0}
      >
        {children}
        <s
          style={{
            ...rippleStyle,
            ...this.state.rippleStyle,
            ...nextStyle,
          }}
        />
      </div>
    );
  }
}
