import React from 'react';
import { useFormikContext } from 'formik';
import { DateTime } from 'luxon';
import * as yup from 'yup';
import 'styled-components/macro';

import { GraphqlFormField } from '../../../../../components/graphql-form/render';
import { FormPartProps } from '../../../edit/editFormParts';
import {
  contractStatusMap,
  ContractStatus as ContractStatusType,
  formatContractStatus,
} from '../../../../../helpers/formatStrings';
import { useHasRole } from '../../../../../components/useHasRole';

/**
 * creating a new contract with fixed end date, we need to validate allowed contract states.
 * two possible cases with different selections in dropdown:
 * end date is in the future → only show LS_/FS_ “ENDED” or “INCANCELATION”
 * end date is in the past or today → only show LS_/FS_ “ENDED”
 * in case user selects contract state before end date, all contract states are shown
 * but selection need to be validated after user defines a fixed end date.
 */

const startOfToday = DateTime.local().startOf('day');
export function ContractStatus({ fieldNamePrefix = '' }: FormPartProps) {
  const { values } = useFormikContext();
  const endDate = (values as any).endDate as string;

  const { hasRole: hasFullStatusSupply } = useHasRole(
    'feature_status_full_supply',
  );

  const hasEndDate = endDate !== '';
  const endDateIsTodayOrFuture = DateTime.fromISO(endDate) >= startOfToday;

  const statusContractEnabled = Object.keys(contractStatusMap).reduce(
    (acc, key) => {
      const statusKey = key as ContractStatusType;
      let isStatusEnabled;

      switch (statusKey) {
        case 'LS_INTRANSFER':
        case 'LS_ACTIVE':
        case 'FS_INTRANSFER':
        case 'FS_ACTIVE':
          isStatusEnabled = !hasEndDate;
          break;
        case 'LS_INCANCELATION':
        case 'FS_INCANCELATION':
          isStatusEnabled = hasEndDate && endDateIsTodayOrFuture;
          break;
        case 'LS_ENDED':
        case 'FS_ENDED':
          isStatusEnabled = hasEndDate && !endDateIsTodayOrFuture;

          break;
        case 'LS_REVERSAL':
        case 'FS_REVERSAL':
          isStatusEnabled = false;
          break;
        default:
          isStatusEnabled = true;
      }

      const itemDoesNotHaveFullSupplyOrUserRoleAllowsIt =
        !statusKey.includes('FS') || hasFullStatusSupply;

      if (isStatusEnabled && itemDoesNotHaveFullSupplyOrUserRoleAllowsIt) {
        acc[statusKey] = formatContractStatus(key, hasFullStatusSupply);
      }
      return acc;
    },
    {} as typeof contractStatusMap,
  );

  return (
    <div
      css={`
        width: 400px;
      `}
    >
      <GraphqlFormField
        name={`${fieldNamePrefix}status`}
        data-testid={`${fieldNamePrefix}status`}
        labels={Object.values(statusContractEnabled)}
        values={Object.keys(statusContractEnabled)}
        type="dropdown"
      />
    </div>
  );
}

/**
 * If a not allowed combination happens, user need to update state selection.
 * Show error below contract state field “Nicht erlaubter Vertragsstatus”.
 * not allowed cases would be the opposite from above:
 * for example: fixed end date and state is “ACTIVE”, “INTRANSFER”
 */
export const invalidStatusMessage = 'Nicht erlaubter Vertragsstatus';

export const contractStatusValidator = yup
  .string()
  .required()
  .when('endDate', (endDate: string, schema: any) => {
    if (endDate === '') return schema;
    if (DateTime.fromISO(endDate) > startOfToday) {
      return schema.oneOf(
        ['LS_INCANCELATION', 'LS_ENDED', 'FS_INCANCELATION', 'FS_ENDED'],
        invalidStatusMessage,
      );
    }
    if (DateTime.fromISO(endDate) <= startOfToday) {
      return schema.oneOf(['LS_ENDED', 'FS_ENDED'], invalidStatusMessage);
    }
  });
