import { WorkflowStep } from '@ampeersenergy/ampeers-ui-components';
import * as React from 'react';

import {
  AccountingRunWorkflowStep,
  AccountingRunWorkflowStepName,
} from '../../../graphql-types';

import { getLabelForStepName, mapStepStatus } from './accountingRunHelper';

interface StepsSidebarProps {
  steps: AccountingRunWorkflowStep[];
  selectedStep: AccountingRunWorkflowStepName | undefined;
  setActive: (step: AccountingRunWorkflowStepName) => void;
}

export function StepsSidebar({
  steps,
  selectedStep,
  setActive,
}: StepsSidebarProps) {
  return (
    <>
      {steps.map((step, index) => {
        const state = mapStepStatus(step.status, step.action);
        const label = getLabelForStepName(step.name);

        return (
          <WorkflowStep
            label={label}
            state={state}
            isSelected={selectedStep === step.name}
            key={`${step.name}-${index}`}
            onClick={() => setActive(step.name)}
          />
        );
      })}
    </>
  );
}
