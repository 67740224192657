import gql from 'graphql-tag';

export default gql`
  query readPlantsSimple {
    readPlants {
      id
      name
      projectId
      project {
        id
        name
      }
    }
  }
`;
