import {
  Login,
  LoginLayout,
  ResetPasswordRequest,
} from '@ampeersenergy/ampeers-ui-components';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import ProductLogo from '../../assets/local-supply.png';
import { DocTitle } from '../../components/docTitle';
import {
  useLoginUserMutationMutation,
  useSendPasswordResetEmailMutation,
} from '../../graphql-types';
import { isDevelopment, password, username } from '../../helpers/env';
import { identifySegmentUser } from '../../helpers/analyticsHelpers';

const initialValues = isDevelopment
  ? { email: username, password }
  : { email: '', password: '' };

function LoginPage() {
  const history = useHistory();
  const [loginUserMutation] = useLoginUserMutationMutation();
  const [sendPasswordResetEmail, { loading }] =
    useSendPasswordResetEmailMutation();
  const [loginState, setLoginState] = useState<'login' | 'passwordReset'>(
    'login',
  );

  return (
    <>
      <DocTitle titleParts={['Login']} />
      <LoginLayout ProductLogo={ProductLogo}>
        {loginState === 'passwordReset' && (
          <ResetPasswordRequest
            setLoginState={setLoginState}
            loading={loading}
            sendPasswordResetEmail={({ email }) =>
              sendPasswordResetEmail({
                variables: {
                  email,
                  resetPasswordCallbackUrl: `${window.location.origin}/reset-password`,
                },
              })
            }
          />
        )}
        {loginState === 'login' && (
          <Login
            setLoginState={setLoginState}
            initialValues={initialValues}
            onLoginSuccess={() => history.replace({ pathname: '/' })}
            login={async (values) => {
              const loginRes = await loginUserMutation({
                variables: {
                  ...values,
                  twoFactorAuthCallbackURL: `${window.location.origin}/login/2fa`,
                },
              });

              if (loginRes.data?.loginUser.__typename === 'User') {
                identifySegmentUser(loginRes.data?.loginUser);
              }

              return loginRes;
            }}
          />
        )}
      </LoginLayout>
    </>
  );
}

export default LoginPage;
