import React, { useState, useMemo } from 'react';
import {
  HistoricTabs,
  HistoricItem,
  Button,
  PaddedShadowBox,
  Relation,
  Modal,
} from '@ampeersenergy/ampeers-ui-components';
import { DateTime } from 'luxon';

import { CreateFlow } from '../../../../../components';
import {
  formatDate,
  formatEmgEndDates,
  formatMeter,
} from '../../../../../helpers/formatStrings';
import { ReadContractQuery } from '../../../../../graphql-types';
import { MeterIcon } from '../../../../../components/icons';
import { isFinished } from '../../../../../helpers/utils';

import MainPanel from './mainPanel';
import EditMeter from './edit';

interface MeterComponentProps {
  contract?: ReadContractQuery['readContract'];
  isLoading: boolean;
}

function MeterComponent({ contract, isLoading }: MeterComponentProps) {
  const plantId = contract?.plantId;
  const contractId = contract?.id;
  const contractStartDate = contract?.startDate;
  const contractEndDate = contract?.endDate;
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const sortedMeters = useMemo(() => {
    if (!contract?.contractMeter?.meters) return [];

    return contract?.contractMeter?.meters
      .slice(0)
      .sort(
        (meterA, meterB) =>
          new Date(meterB.validityStart!).getTime() -
          new Date(meterA.validityStart!).getTime(),
      )
      .map((meter) => {
        return {
          ...meter,
          endDateFormatted: `${formatEmgEndDates(
            meter.validityEnd ? meter.validityEnd : '',
            true,
            false,
          )} ${isFinished(meter.validityEnd) ? '(beendet)' : ''}`,
          startDateFormatted: formatDate(meter.validityStart!),
        };
      });
  }, [contract]);

  const items: HistoricItem[] = sortedMeters.map((sortedMeter) => ({
    label: formatMeter(sortedMeter),
    date: DateTime.fromISO(sortedMeter?.validityStart ?? '').toISODate() ?? '',
    id: sortedMeter.id,
  }));

  return (
    <PaddedShadowBox>
      <HistoricTabs
        items={items}
        isLoading={isLoading}
        title="Zähler"
        renderValue={(item: any) =>
          item.label ? <Relation icon={MeterIcon} title={item.label} /> : null
        }
        renderActions={() => (
          <Button disabled={modalIsOpen} onClick={() => setModalIsOpen(true)}>
            Zähler wechseln
          </Button>
        )}
      >
        {(item: any) => {
          const meter = sortedMeters.find(
            (sortedMeter) => sortedMeter.id === item.id.toString(),
          );

          return (
            <>
              <MainPanel
                data={meter}
                malo={contract?.contractMeter?.malo ?? ''}
              />
              <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                contentLabel="Create-Tariff-Modal"
                title="Zähler wechseln"
              >
                <CreateFlow
                  editContainer={EditMeter}
                  kind="Zähler"
                  onDone={() => setModalIsOpen(false)}
                  variables={{
                    contractId,
                    contractStartDate,
                    contractEndDate,
                    plantId,
                    oldMeter: sortedMeters[0],
                    melo: contract?.contractMeter?.meters[0].melo ?? '',
                  }}
                />
              </Modal>
            </>
          );
        }}
      </HistoricTabs>
    </PaddedShadowBox>
  );
}

export default MeterComponent;
