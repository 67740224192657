import React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import { ErrorMsg, SubTitle, PaddedShadowBox } from '../../components';
import { useReadTariffsQuery } from '../../graphql-types';

import EditTariffContainer from './editTariffContainer';

const Box = styled(PaddedShadowBox)`
  padding-top: 1px;
  margin-bottom: 10px;
`;

function TariffDetail() {
  const { tariffId } = useParams<{ tariffId: string }>();

  const {
    data: dataTariffs,
    loading: loadingTariffs,
    error: errorTariffs,
  } = useReadTariffsQuery();
  const dataTariff = dataTariffs?.readTariffs.find(
    (tariff) => tariff.id === tariffId,
  );

  const otherTariffNames = (dataTariffs?.readTariffs ?? [])
    .map((tariff) => tariff.nameInternal)
    .filter((name) => name !== dataTariff?.nameInternal);

  let inner = null;

  if (!tariffId) {
    inner = <ErrorMsg message="Die ID des Tarifs fehlt." />;
  } else if (errorTariffs) {
    inner = <ErrorMsg error={errorTariffs} />;
  } else {
    inner = (
      <EditTariffContainer
        tariffId={tariffId}
        dataTariff={dataTariff}
        isLoading={loadingTariffs}
        otherTariffNames={otherTariffNames}
      />
    );
  }

  return (
    <Box>
      <SubTitle>Tarifinformationen</SubTitle>
      {inner}
    </Box>
  );
}

export default TariffDetail;
