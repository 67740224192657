import React from 'react';
import styled from 'styled-components';
import {
  PageTitleLayout,
  PageTitle,
} from '@ampeersenergy/ampeers-ui-components';
import { useFragment } from '@apollo/client';
import { useParams } from 'react-router';

import { DocTitle } from '../../components/docTitle';
import { TariffNameInternalFragment } from '../../graphql-types';
import { tariffNameInternalFragment } from '../../queries/fragments/tariffNameInternal';
import { LOADING_STR } from '../../helpers/formatStrings';

import TariffDetail from './tariffDetail';
import TariffPricesheet from './tariffPricesheet';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 60px;
`;

interface TariffDetailPageProps {
  showPageTitleLayout?: boolean;
}

function TariffDetailPage({ showPageTitleLayout }: TariffDetailPageProps) {
  const { tariffId } = useParams<{ tariffId: string }>();
  const { tariffNameInternal, loading } = useTariffNameInternal(tariffId);
  const pageTitle = loading ? LOADING_STR : tariffNameInternal;

  return (
    <>
      <DocTitle titleParts={[tariffNameInternal, 'Tarif']} />
      {showPageTitleLayout ? (
        <>
          <PageTitle>{pageTitle}</PageTitle>
          <Wrapper>
            <TariffDetail />
            <TariffPricesheet />
          </Wrapper>
        </>
      ) : (
        <PageTitleLayout levelsUpToParent={1} title={pageTitle}>
          <Wrapper>
            <TariffDetail />
            <TariffPricesheet />
          </Wrapper>
        </PageTitleLayout>
      )}
    </>
  );
}

/**
 * Reads the internal name of tariff from the cache.
 * Updates when the value in the cache changes.
 */
function useTariffNameInternal(tariffId: string) {
  const { data, complete } = useFragment<TariffNameInternalFragment>({
    fragment: tariffNameInternalFragment,
    from: {
      __typename: 'Tariff',
      id: tariffId,
    },
  });

  return {
    tariffNameInternal: data.nameInternal ?? '',
    loading: !complete,
  };
}

export default TariffDetailPage;
