import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useFormikContext } from 'formik';

import { GraphqlFormField } from '../../../../../components/graphql-form/render';
import { Flex, FlexRow, Bold } from '../../../../../components';

export const GraphqlFormVerticalInputGroup = styled(FlexRow)`
  > div {
    margin-left: 3px;
    padding-left: 3px;
    padding-right: 3px;

    :first-child {
      padding-left: 0;
    }

    :last-child {
      padding-right: 0;
    }
  }

  .is-editing & > div {
    flex: 1;
  }
`;

const Headline = styled(Bold)`
  margin-top: 10px;
  font-size: 20px;
`;

interface EditFromProps {
  plantId?: number;
  contractStartDate?: string;
  contractEndDate?: string;
}

function EditForm({
  plantId,
  contractStartDate,
  contractEndDate,
}: EditFromProps) {
  const { setFieldValue, getFieldProps } = useFormikContext();
  useEffect(() => {
    if (
      getFieldProps('validityEndDate').value !==
      getFieldProps('validityStartDate').value
    ) {
      setFieldValue(
        'validityStartDate',
        getFieldProps('validityEndDate').value,
        true,
      );
    }
  }, [setFieldValue, getFieldProps]);

  return (
    <>
      <div>
        <GraphqlFormField
          name="meter.id"
          label="Neuer Zähler"
          placeholder="Neuen Zähler wählen"
          plantId={plantId}
          boundStartAt={contractStartDate}
          {...(contractEndDate && {
            boundEndAt: contractEndDate,
          })}
          type="Meter"
          data-testid="meter.id"
          id="meter.id"
          required
        />
      </div>
      <Headline>Ausbau</Headline>
      <GraphqlFormVerticalInputGroup>
        <div>
          <GraphqlFormField name="validityEndDate" label="Datum" />
        </div>
        <div>
          <GraphqlFormField name="oldMeterReading.value" asType="string" />
        </div>
        <div>
          <GraphqlFormField name="oldMeterReading.valueStatus" />
        </div>
      </GraphqlFormVerticalInputGroup>
      <Headline>Einbau</Headline>
      <GraphqlFormVerticalInputGroup>
        <div>
          <GraphqlFormField name="validityStartDate" label="Datum" disabled />
        </div>
        <Flex>
          <GraphqlFormField name="meterReading.value" asType="string" />
        </Flex>
        <Flex>
          <GraphqlFormField name="meterReading.valueStatus" />
        </Flex>
      </GraphqlFormVerticalInputGroup>
    </>
  );
}
export default EditForm;
