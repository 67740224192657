import React from 'react';
import styled from 'styled-components';

import { CloseIcon } from './icons';
import useToParentCallback from './useToParentCallback';

export const NestedPageParent = styled.div`
  position: relative;
  height: 100%;
`;

const Backdrop = styled.div`
  background: rgba(0, 0, 0, 0.32);
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
`;

export const NestedPageContainer = styled.div`
  position: fixed;
  background: #f8f8fb;
  box-shadow: -7px 5px 4px 0 rgba(0, 0, 0, 0.08);
  top: 0;
  right: 0;
  height: 100%;
  width: 86.5%;
  margin-left: 100px;
  border-radius: 5px;
  padding-left: 12px;
`;

export const NestedPageInner = styled.div`
  height: 100%;
  overflow: scroll;
  padding-bottom: 20px;
`;

export const CloseIconWrap = styled.div`
  background: #fff;
  border-radius: 50px;
  border: 1px solid #e8e8e8;
  display: inline-block;
  cursor: pointer;
  flex: none;
  margin-top: 10px;

  svg {
    fill: ${(props) => props.theme.primaryColor};
  }

  &:hover {
    border: 1px solid ${(props) => props.theme.primaryColor};
  }
`;

const FlexItem = styled.div`
  flex: 1;
  height: 100%;
  padding-left: 20px;
`;

const FlexContainer = styled.div`
  display: flex;
  height: 100%;
`;

export interface NestedpageProps {
  children: React.ReactNode;
  className?: string;
  levelsUpToParent: number;
}

export function NestedPage({
  children,
  className,
  levelsUpToParent,
}: NestedpageProps) {
  const toParent = useToParentCallback(levelsUpToParent);

  return (
    <>
      <Backdrop onClick={toParent} />
      <NestedPageContainer className={className}>
        <FlexContainer>
          <div>
            <CloseIconWrap onClick={toParent}>
              <CloseIcon size={30} color="#909090" />
            </CloseIconWrap>
          </div>
          <FlexItem>
            <NestedPageInner>{children}</NestedPageInner>
          </FlexItem>
        </FlexContainer>
      </NestedPageContainer>
    </>
  );
}
