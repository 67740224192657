import {
  ColumnDefinition,
  Filter,
  Table,
  TableCells,
} from '@ampeersenergy/ampeers-ui-components';
import React, { useCallback, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { useGetSupplierChangeWorkflowsQuery } from '../../graphql-types';

import type { SupplierBaseColumns } from './index';

const POLL_INTERVAL = 15 * 1000;

type WorkflowTypes =
  | 'supplierChangeLocal'
  | 'supplierChangeThirdParty'
  | 'cancellation';

interface SupplierChangeTableProps {
  columns: ColumnDefinition<SupplierBaseColumns>[];
  filters: Filter[];
  workflowType?: WorkflowTypes;
}

export function SupplierChangeTable({
  columns,
  filters,
  workflowType = 'supplierChangeLocal',
}: SupplierChangeTableProps) {
  const pageSize = 10;
  const match = useRouteMatch();
  const [currentPage, setCurrentPage] = useState(0);
  const [nextPageToken, setNextPageToken] = useState('');
  const [pageCount, setPageCount] = useState(0);
  const [pageTokens, setPageTokens] = useState<Array<string | undefined>>(
    Array(pageCount),
  );

  const rowLink = useCallback(
    ({ row }: TableCells<SupplierBaseColumns>) => {
      const entry = row.original;
      return `${match.url}/workflow/${entry.id}`;
    },
    [match.url],
  );

  const {
    data: supplierChangeWorkflowsResponse,
    loading: supplierChangeLoading,
    refetch,
    startPolling,
    stopPolling,
  } = useGetSupplierChangeWorkflowsQuery({
    variables: { workflowType, nextPageToken },
    skip: !workflowType,
    fetchPolicy: 'network-only',
  });

  const getWorkflowsResponse =
    supplierChangeWorkflowsResponse?.getSupplierChangeWorkflows;

  const supplierChangeWorkflowsData: SupplierBaseColumns[] | undefined =
    getWorkflowsResponse?.data.map((workflow) => {
      return {
        id: workflow.WorkflowId,
        contractLabel: workflow.ContractLabel,
        status: workflow.WorkflowStatus,
        statusText: workflow.StatusText ?? undefined,
        contractName: `${workflow.ContractName} - ${workflow.ContractLabel}`,
        meterId: workflow.Meter,
        malo: workflow.Malo,
        plantName: workflow.Plant,
        marketPartnerLabel: workflow.NetworkOperator,
      };
    });

  useEffect(() => {
    const totalCount = getWorkflowsResponse?.totalCount;
    const pages = Math.ceil(totalCount ? totalCount / pageSize : 0);
    setPageCount(pages);
  }, [getWorkflowsResponse?.totalCount]);

  useEffect(() => {
    setPageTokens((tokens) => {
      // eslint-disable-next-line no-param-reassign
      tokens[currentPage + 1] =
        getWorkflowsResponse?.nextPageToken ?? undefined;
      return tokens;
    });
  }, [currentPage, getWorkflowsResponse?.nextPageToken]);

  useEffect(() => {
    refetch();
    startPolling(POLL_INTERVAL);

    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling, refetch]);

  const onPageChange = React.useCallback(
    async (pageIndex: number) => {
      setNextPageToken(pageTokens[pageIndex] ?? '');
      setCurrentPage(pageIndex);
    },
    [pageTokens],
  );

  const filterKind = getFilterKind(workflowType);

  return (
    <Table
      columns={columns}
      data={supplierChangeWorkflowsData ?? []}
      filterKind={filterKind}
      isLoading={supplierChangeLoading}
      rowLink={rowLink}
      compact
      withAlternatingRows
      withPagination
      withBoxShadow
      pageSize={pageSize}
      total={getWorkflowsResponse?.totalCount}
      filterState={filters}
      onPageChange={onPageChange}
    />
  );
}

function getFilterKind(workflowType: WorkflowTypes) {
  switch (workflowType) {
    case 'supplierChangeLocal':
      return 'Lieferantenwechsel';
    case 'supplierChangeThirdParty':
      return 'Lieferantenwechsel in Drittbelieferung (beginne Lieferantenwechsel)';
    case 'cancellation':
      return 'Vertragskündigung';
    default:
      return 'Lieferantenwechsel';
  }
}
