import React, { useState } from 'react';
import { Button, Modal } from '@ampeersenergy/ampeers-ui-components';
import styled from 'styled-components';

import { CreateFlow } from '../../../../components';
import { PaddedShadowBox } from '../../../../components/layout';
import { ReadContractQuery } from '../../../../graphql-types';

import SingleCustomerTermination from './cancellation';
import Consumptions from './consumption';
import ContractTariffsComponent from './contractComponent';
import EndMessageContainer from './cancellation/endMessageContainer';
import { ContractEdit } from './edit';

const PaddedShadowBoxStyled = styled(PaddedShadowBox)`
  margin-bottom: 1px;
`;

const SpacerY = styled.div`
  height: 10px;
`;

interface ContractPageProps {
  contract?: ReadContractQuery['readContract'];
  isLoading?: boolean;
}

function ContractPage({ contract, isLoading }: ContractPageProps) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const customerNameFirstLast = contract?.customer?.person?.name;

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Create-Termination-Modal"
        title={`${customerNameFirstLast} kündigen`}
      >
        <CreateFlow
          editContainer={SingleCustomerTermination}
          kind="Vertrag"
          onDone={() => setModalIsOpen(false)}
          variables={{
            contract,
            isLoading,
          }}
          endMessageContainer={EndMessageContainer}
        />
      </Modal>
      <PaddedShadowBoxStyled>
        <ContractEdit contract={contract} isLoading={isLoading} />
      </PaddedShadowBoxStyled>
      <Consumptions contract={contract} />
      <SpacerY />
      <ContractTariffsComponent contract={contract} isLoading={isLoading!} />
      <SpacerY />
      {contract?.status &&
        !(
          contract?.status.includes('_ENDED') ||
          contract?.status.includes('_INCANCELATION')
        ) && (
          <Button secondary onClick={() => setModalIsOpen(true)}>
            Kündigen
          </Button>
        )}
    </>
  );
}

export default ContractPage;
