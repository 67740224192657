import React from 'react';

// eslint-disable-next-line import/no-cycle
import {
  GraphqlFormFieldProps,
  GraphqlFormFieldInternalIdentifier,
  GraphqlFormFieldIndentifier,
} from './useGraphqlFormField';

export interface GraphqlFormContextType {
  isEditing: boolean;
  isLoading: boolean;
  formVariables: { [key: string]: any };
  getFieldProps: (
    name: string,
  ) =>
    | (GraphqlFormFieldProps &
        GraphqlFormFieldInternalIdentifier &
        GraphqlFormFieldIndentifier)
    | undefined;
  registerField: (name: string) => void;
  unregisterField: (name: string) => void;
}

export const GraphqlFormContext = React.createContext<
  GraphqlFormContextType | undefined
>(undefined);

export const useGraphqlForm = () => {
  const context = React.useContext(GraphqlFormContext);
  if (context === undefined) {
    throw new Error('useGraphqlForm must be used within a GraphlFormContext');
  }
  return context;
};
