import gql from 'graphql-tag';

export default gql`
  mutation prepareUpdateContractsBatched(
    $contracts: [PrepareUpdateContractsBatchedInput!]!
  ) {
    prepareUpdateContractsBatched(contracts: $contracts) {
      __typename
      valid {
        contractId
        contractLabel
        customerLabel
        contract {
          status
          startDate
          downPayment
          downPaymentStartDate
          customer {
            person {
              namePrefix
              name
              contactPhone
              contactMobile
              companyFlag
              co
            }
            address {
              streetName
              streetNumber
              zipCode
              city
              country
            }
            addressBilling {
              partnerId
              additionalPartnerId
              namePrefix
              name
              additionalName
              additionalNamePrefix
              streetName
              streetNumber
              zipCode
              city
              country
              email
              companyFlag
              co
            }
            addressShipping {
              namePrefix
              name
              streetName
              streetNumber
              zipCode
              city
              country
              companyFlag
              co
            }
            bankAccount {
              name
              iban
              bic
              mandateReference
              signedAt
            }
            hasSepa
            documentDeliveryMethod
            advertisementAccepted
          }
        }
      }
      failed {
        contractLabel
        message
        error
      }
    }
  }
`;
