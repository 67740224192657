import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import AELogo from '../../assets/aelogo.png';

const Wrapper = styled.div`
  margin-bottom: 20px;
  padding-top: 10px;
  display: flex;
  justify-content: center;
  height: 40px;
`;

const StyledImg = styled.img`
  border-radius: 4px;
`;

function Logo() {
  return (
    <Wrapper>
      <Link to="/">
        <StyledImg width="40" height="40" src={AELogo} />
      </Link>
    </Wrapper>
  );
}

export default Logo;
