import { connect, useSelector } from 'react-redux';

import { LOGIN, LOGOUT, SessionState, sessionState } from '../redux/session';
import store, { RootState } from '../redux/rootStore';

export const withSession = (Component: any): any => {
  const wrapped = connect(
    (s: RootState) => ({
      hasSession: !!s.session.token,
      token: s.session.token,
      clientId: s.session.clientId,
    }),
    (dispatch) => ({
      setSession: (hasSession: boolean) => {
        dispatch(hasSession ? { type: LOGIN } : { type: LOGOUT });
      },
    }),
  )(Component);

  return wrapped;
};

export const useSession = () => {
  const session = useSelector(
    (state: RootState) => state.session,
  ) as SessionState;

  return {
    hasSession: !!session.token,
    token: session.token,
    clientId: session.clientId,
  };
};

export const hasSession = () => !!store.getState().session.token;

let resolveWaitForSession: any;
const waitForSessionPromise = new Promise<SessionState>((_resolve) => {
  if (sessionState.token) {
    _resolve(sessionState);
  }

  resolveWaitForSession = _resolve;
});
export const waitForSession = () => waitForSessionPromise;

export const setSession = (nextState: SessionState) => {
  store.dispatch({ type: LOGIN, nextState });

  if (waitForSessionPromise) {
    resolveWaitForSession(nextState);
  }
};

export const getSession = () => store.getState().session;

export const clearSession = () => store.dispatch({ type: LOGOUT });
