import { ApolloError } from '@apollo/client';

/**
 * returns parsed error messages for a given Apollo Error
 * @param error
 *
 */
export const extractErrorMessage = (
  error?: ApolloError,
): string | undefined => {
  if (!error) return;

  if (error.graphQLErrors.length) {
    return error.graphQLErrors.reduce(
      (errMsg: string, { message }: { message: string }) =>
        errMsg.length ? `${errMsg} \n ${message}` : message,
      '',
    );
  }

  if (error.networkError) {
    return error.networkError.message;
  }
};
