/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import { Icons, Modal } from '@ampeersenergy/ampeers-ui-components';
import styled, { useTheme } from 'styled-components';
import ContentLoader from 'react-content-loader';

import { HRStyle } from '../dashboardStyles';
import { NewsPostType, useReadNewsCommentQuery } from '../../../graphql-types';
import { formatDateWithMonthStringAndYear } from '../../../helpers/formatStrings';
import {
  loadJSONFromStorage,
  saveJSONToStorage,
} from '../../../helpers/storageUtils';

export const LIST_PAGE_SIZE = 3;

const NewsWrapper = styled.div`
  cursor: pointer;
  &:hover p {
    color: ${(props) => props.theme.primaryColor};
  }
  display: grid;
  grid-template-columns: 5fr 1fr;
`;
const ReleaseDate = styled.div`
  color: ${(props) => props.theme.palette.textMuted};
  font-size: 14px;
`;

const RibonIcon = styled(Icons.Ribon)`
  margin-left: 6px;
`;

const NewsInfo = styled.p<{ variant: 'large' | 'small' }>`
  font-size: ${(props) => (props.variant === 'large' ? '24px' : '16px')};
  font-weight: bold;
  margin-top: 8px;
`;

const NewLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: right;
  width: 85px;
  gap: 4px;
  background-color: ${(props) => props.theme.primaryColor};
  color: white;
  font-weight: bold;
  height: fit-content;
  border-radius: 15px;
  padding: 4px 10px;
`;

const ModalWrap = styled.div`
  padding: 0 25px;

  a {
    color: ${(props) => props.theme.primaryColor};
    text-decoration: none;
    font-weight: bold;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const LoadingWrap = styled.div`
  display: flex;
  width: 770px;
  height: 500px;
`;

const ModalTitleWrap = styled.div`
  margin-top: 20px;
  margin-bottom: -30px;
  padding: 10px 25px 0px 25px;
`;

type NewsItemProps = {
  index: number;
  currNewsItem: DashboardNewsFeedContentType;
  topicsLength: number;
};

export type DashboardNewsFeedContentType = {
  id?: string;
  type?: NewsPostType;
  title?: string;
  createdAt?: string;
  body?: string;
};

const addItemToLocalStorageArray = (
  id: string,
  localStorageIdentifier: string,
) => {
  const storedArray = loadJSONFromStorage(localStorageIdentifier);
  const releaseNotes = new Set(storedArray);

  if (!releaseNotes.has(id)) {
    releaseNotes.add(id);
  }

  saveJSONToStorage(localStorageIdentifier, Array.from(releaseNotes));
};

const newsPostTypeToString = (newsPostType?: NewsPostType) => {
  if (newsPostType === NewsPostType.ProductNews) {
    return 'Release';
  }
  if (newsPostType === NewsPostType.MarketNews) {
    return 'Markt';
  }
};

const addNoTabToLinks = (body: string) => {
  return body.replace(/href=/g, 'target="_blank" href=');
};

export default function NewsItem({ content }: { content: NewsItemProps }) {
  const theme = useTheme();
  const { index, currNewsItem, topicsLength } = content;
  const { id: topicId, type, title, createdAt, body } = currNewsItem;

  const [modalVisible, setModalVisible] = useState(false);
  const [newLabelVisible, setNewLabelVisible] = useState(false);
  const [loadBody, setLoadBody] = useState(false);

  const { data: newsBody, loading } = useReadNewsCommentQuery({
    variables: {
      topicId: topicId || '',
      type: type || NewsPostType.ProductNews,
    },
    skip: !loadBody,
  });

  React.useEffect(() => {
    const viewedReleaseNotes = loadJSONFromStorage('viewed_release_notes');
    if (
      !viewedReleaseNotes?.includes(currNewsItem.id) &&
      currNewsItem.id &&
      index < 3 // Just display new label on first 3 releases
    ) {
      setNewLabelVisible(true);
    }
  }, [currNewsItem.id, index]);

  const handleModalOpen = () => {
    setLoadBody(true);
    setModalVisible(true);

    if (currNewsItem.id) {
      addItemToLocalStorageArray(currNewsItem.id, 'viewed_release_notes');
      setNewLabelVisible(false);
    }
  };

  return (
    <>
      <div
        key={`${title?.substring(0, 10)}-${index}`}
        data-testid={`news-feed-${index}`}
      >
        <NewsWrapper
          onClick={handleModalOpen}
          tabIndex={0}
          role="button"
          onKeyDown={({ key }) => {
            if (key === 'Enter') {
              handleModalOpen();
            }
          }}
        >
          <div>
            <ReleaseDate>
              {formatDateWithMonthStringAndYear(createdAt || '')}
              <RibonIcon size={16} color={theme.palette.textMuted} />
              {newsPostTypeToString(type)}
            </ReleaseDate>
            <NewsInfo variant="small"> {title} </NewsInfo>
          </div>
          {newLabelVisible && (
            <NewLabel>
              Neu <Icons.Sparks size={20} color="white" />
            </NewLabel>
          )}
        </NewsWrapper>
        {(index + 1) % LIST_PAGE_SIZE !== 0 && index + 1 !== topicsLength ? (
          <HRStyle />
        ) : null}
      </div>

      <Modal
        isOpen={modalVisible}
        onRequestClose={() => setModalVisible(false)}
        contentLabel="Create-NewsFeed-Modal"
        maxWidth="lg"
        title={
          <ModalTitleWrap>
            <ReleaseDate>
              {formatDateWithMonthStringAndYear(createdAt || '')}
              <RibonIcon size={16} color={theme.palette.textMuted} />
              {newsPostTypeToString(currNewsItem?.type)}
            </ReleaseDate>
            <NewsInfo variant="large"> {currNewsItem.title} </NewsInfo>
          </ModalTitleWrap>
        }
      >
        {loading ? (
          <LoadingWrap>
            <ContentLoader speed={2} height="500px" width="700px">
              <rect x="25px" y="25px" width="600px" height="12px" />
              <rect x="25px" y="45px" width="500px" height="12px" />
              <rect x="25px" y="65px" width="550px" height="12px" />
              <rect x="25px" y="85px" width="450px" height="12px" />
              <rect x="25px" y="105px" width="600px" height="12px" />
              <rect x="25px" y="125px" width="500px" height="12px" />
              <rect x="25px" y="155px" width="550px" height="12px" />
              <rect x="25px" y="175px" width="450px" height="12px" />
              <rect x="25px" y="195px" width="500px" height="12px" />
              <rect x="25px" y="215px" width="550px" height="12px" />
              <rect x="25px" y="235px" width="450px" height="12px" />
              <rect x="25px" y="265px" width="450px" height="12px" />
              <rect x="25px" y="285px" width="500px" height="12px" />
              <rect x="25px" y="305px" width="550px" height="12px" />
              <rect x="25px" y="325px" width="450px" height="12px" />
            </ContentLoader>
          </LoadingWrap>
        ) : (
          <ModalWrap>
            <div
              dangerouslySetInnerHTML={{
                __html: addNoTabToLinks(
                  body || newsBody?.readNewsComment?.body || '',
                ),
              }}
            />
          </ModalWrap>
        )}
      </Modal>
    </>
  );
}
