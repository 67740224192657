import gql from 'graphql-tag';

export default gql`
  query readOpsClientSettings {
    readOpsClientSettings {
      debtorLabel
      clientStartDate
      bccReceiver
    }
  }
`;
