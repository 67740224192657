import * as React from 'react';
import {
  AlertRetryable,
  Expandable,
  Expandables,
  SubTitle,
  useToasts,
} from '@ampeersenergy/ampeers-ui-components';
import 'styled-components/macro';

import { SettingsTitle } from '../style';
import { useReadMkvTemplatesQuery } from '../../../graphql-types';

import { MeterTemplates } from './MeterTemplates';

export function ContractSettings() {
  const { data, loading, error } = useReadMkvTemplatesQuery();
  const toast = useToasts();

  const [activeItem, setActiveItem] = React.useState<number | null>(null);

  const handleClose = (success = true) => {
    if (success) {
      toast.success(`Die Vorlage wurde erfolgreich gespeichert`, {
        autoClose: 2500,
      });
    }
    setActiveItem(null);
  };

  if (error) {
    return <AlertRetryable error={error} />;
  }

  return (
    <div css={{ width: '100%' }}>
      <SettingsTitle>Verträge</SettingsTitle>
      <SubTitle>Vorlage für Gebäudeverträge</SubTitle>
      <p css={{ maxWidth: '740px' }}>
        Um beim Erstellen von Gebäude Verträgen Zeit zu sparen, kannst Du hier
        Vorlagen für alle Gebäudeverträge verwalten. Beim Anlegen der Verträge
        wird dann das Formular mit diesen Werten vorausgefüllt.
      </p>
      <Expandables expandedIndex={activeItem}>
        <Expandable title="Erzeugungszähler" onSelect={setActiveItem}>
          <MeterTemplates
            data={data?.readMkvTemplates?.prodMeter}
            key="prodMeter"
            meterType="prodMeter"
            loading={loading}
            onClose={handleClose}
          />
        </Expandable>
        <Expandable title="Summenzähler Bezug" onSelect={setActiveItem}>
          <MeterTemplates
            data={data?.readMkvTemplates?.sumMeterConsumption}
            key="sumMeterConsumption"
            meterType="sumMeterConsumption"
            loading={loading}
            onClose={handleClose}
          />
        </Expandable>
        <Expandable title="Summenzähler Einspeisung" onSelect={setActiveItem}>
          <MeterTemplates
            data={data?.readMkvTemplates?.sumMeterFeedIn}
            key="sumMeterFeedIn"
            meterType="sumMeterFeedIn"
            loading={loading}
            onClose={handleClose}
          />
        </Expandable>
        <Expandable title="Kaskadenzähler" onSelect={setActiveItem}>
          <MeterTemplates
            data={data?.readMkvTemplates?.cascadeMeter}
            key="cascadeMeter"
            meterType="cascadeMeter"
            loading={loading}
            onClose={handleClose}
          />
        </Expandable>
      </Expandables>
    </div>
  );
}
