import * as React from 'react';
import { useFormikContext } from 'formik';
import { Icons, Label, Relation } from '@ampeersenergy/ampeers-ui-components';
import 'styled-components/macro';
import ContentLoader from 'react-content-loader';
import { DateTime } from 'luxon';

import {
  GraphqlFormField,
  GraphqlFormSelect,
} from '../../../components/graphql-form/render';
import {
  AccountingType,
  useReadContractAccountingHistoryQuery,
  useReadContractQuery,
} from '../../../graphql-types';
import { formatAccountingType } from '../../../helpers/formatStrings';

import InvoiceCycleForm from './InvoiceCycleForm';
import ClosingAccountingForm from './ClosingAccountingForm';
import { FormVariablesAccountingWorkflow } from './types';

interface CreateAccountingWorkflowFormInnerProps {
  accountingTypes: string[];
}

export function CreateAccountingWorkflowFormInner({
  accountingTypes,
}: CreateAccountingWorkflowFormInnerProps) {
  const { setFieldValue, values, resetForm } =
    useFormikContext<FormVariablesAccountingWorkflow>();
  const { accountingType, contractToAccount, paymentPeriodStartAt } = values;
  const { data: contractData, loading } = useReadContractQuery({
    variables: {
      contractId: String(contractToAccount),
    },
    skip: !contractToAccount,
  });

  // TODO: Needs to be done when handling accounting history
  const { data: accountingHistory } = useReadContractAccountingHistoryQuery({
    variables: {
      contractId: contractData?.readContract?.id || '',
    },
    skip: !!paymentPeriodStartAt || !contractData?.readContract?.id,
  });

  const contractName = React.useMemo(
    () =>
      contractData?.readContract
        ? contractData.readContract.customer.person.name
        : null,
    [contractData?.readContract],
  );

  const renderContractName = () => {
    if (loading) {
      return (
        <ContentLoader height={24} width={160}>
          <rect x="0" y="0" width="100%" height="100%" />
        </ContentLoader>
      );
    }

    return (
      <Relation
        icon={Icons.Customer}
        title={`${contractName}`}
        css={{ marginTop: '4px' }}
      />
    );
  };

  // TODO: Needs to be done when handling accounting history
  React.useEffect(() => {
    const { nextAccountingStart } =
      accountingHistory?.readContractAccountingHistory ?? {};

    if (nextAccountingStart) {
      setFieldValue(
        'paymentPeriodStartAt',
        DateTime.fromJSDate(new Date(nextAccountingStart)).toFormat(
          'yyyy-MM-dd',
        ),
      );
    }
  }, [accountingHistory?.readContractAccountingHistory, setFieldValue]);

  return (
    <>
      <GraphqlFormField name="name" label="Name" placeholder="Name" />
      {contractToAccount ? renderContractName() : null}
      {accountingTypes.length === 1 ? (
        <>
          <Label>Art der Abrechnung</Label>
          {formatAccountingType(accountingTypes[0])}
        </>
      ) : (
        <GraphqlFormSelect
          name="accountingType"
          label="Art der Abrechnung"
          onChange={() => resetForm()}
        >
          <option disabled hidden value="">
            Bitte wählen
          </option>
          {accountingTypes.map((type) => (
            <option value={type} key={type}>
              {formatAccountingType(type)}
            </option>
          ))}
        </GraphqlFormSelect>
      )}
      {accountingType && (
        <>
          {accountingType === AccountingType.ClosingAccountingMove ||
          accountingType === AccountingType.ClosingAccountingThird ? (
            <ClosingAccountingForm />
          ) : (
            <InvoiceCycleForm />
          )}
        </>
      )}
    </>
  );
}
