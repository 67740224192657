import React, { useMemo } from 'react';
import {
  Main,
  Header,
  PageGrid,
  PageColumn,
  Icons,
} from '@ampeersenergy/ampeers-ui-components';

import ErrorMsg from '../../components/errorMsg';
import {
  NewsPostType,
  useReadCurrentUserQuery,
  useReadDashboardQuery,
} from '../../graphql-types';
import { DocTitle } from '../../components/docTitle';
import { useHasRole } from '../../components/useHasRole';

import DashboardKPI from './dashboardKPI';
import DashboardWorklist from './dashboardWorklist';
import DashboardNewsFeed from './dashboardNews/dashboardNewsFeed';
import { DashboardNewsFeedContentType } from './dashboardNews/newsItem';

const dashboardTitle = 'Dashboard';

const Dashboard: React.FC = () => {
  const { error, data, loading } = useReadDashboardQuery({
    variables: {
      newsPostTypes: [NewsPostType.ProductNews, NewsPostType.MarketNews],
    },
  });

  const { data: currentUserQuery } = useReadCurrentUserQuery({
    fetchPolicy: 'cache-only',
  });

  const { hasRole: disableDashboardFeed } = useHasRole('disable_dashboardfeed');

  const { sumOfUnits, sumOfThirdSupplied, sumOfPlants } = useMemo(
    () =>
      (data?.plantKpis || []).reduce(
        (acc, cur) => {
          // Calculate the sums for the current project
          const projectSums = cur.plants.reduce(
            (plantAcc, plant) => ({
              sumOfUnits: plantAcc.sumOfUnits + plant.kpis.units,
              sumOfThirdSupplied:
                plantAcc.sumOfThirdSupplied +
                plant.kpis.units -
                plant.kpis.unitsMembers,
            }),
            { sumOfUnits: 0, sumOfThirdSupplied: 0 },
          );

          // Add the current project's sums to the overall sums
          return {
            sumOfUnits: acc.sumOfUnits + projectSums.sumOfUnits,
            sumOfThirdSupplied:
              acc.sumOfThirdSupplied + projectSums.sumOfThirdSupplied,
            sumOfPlants: acc.sumOfPlants + cur.plants.length,
          };
        },
        { sumOfUnits: 0, sumOfThirdSupplied: 0, sumOfPlants: 0 },
      ),
    [data],
  );

  return (
    <>
      <DocTitle titleParts={[dashboardTitle]} />
      <div data-testid="page-dashboard">
        <Main>
          <Header Icon={Icons.Dashboard}>{dashboardTitle}</Header>
          {error && <ErrorMsg error={error} />}
          {!error && (
            <PageGrid>
              <PageColumn>
                <DashboardWorklist
                  firstName={currentUserQuery?.readCurrentUser.firstName}
                  drafts={data?.drafts}
                  isLoading={loading}
                />
                {!disableDashboardFeed && (
                  <DashboardNewsFeed
                    latestNews={
                      data?.latestNews as DashboardNewsFeedContentType[]
                    }
                    oldNews={data?.oldNews as DashboardNewsFeedContentType[]}
                    isLoading={loading}
                  />
                )}
              </PageColumn>
              <PageColumn>
                <DashboardKPI
                  data={{
                    numberOfPlants: data ? sumOfPlants : null,
                    numberOfTenants: data
                      ? sumOfUnits - sumOfThirdSupplied
                      : null,
                    sumOfUnits,
                    sumOfThirdSupplied,
                    amountOfUncheckedInvoice: data
                      ? data.accountingKpis.totalInvoicesMandate -
                        data.accountingKpis.confirmedInvoicesMandate -
                        data.accountingKpis.cancelledInvoicesMandate
                      : null,
                    amountOfConfirmedInvoice:
                      data?.accountingKpis.confirmedInvoicesMandate,
                    amountOfRejectedInvoice:
                      data?.accountingKpis.cancelledInvoicesMandate,
                    isLoading: loading,
                  }}
                />
              </PageColumn>
            </PageGrid>
          )}
        </Main>
      </div>
    </>
  );
};

export default Dashboard;
