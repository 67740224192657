import React from 'react';
import styled, { withTheme, ThemeProps } from 'styled-components';
import KiwiDropdown from 'react-kiwi-dropdown';
import type { Theme } from '@ampeersenergy/ampeers-ui-components';

import { hexToRgbaString } from '../../helpers/hexToRgb';

const ThemedDropdown = styled(KiwiDropdown as any)`
  z-index: 2;
  position: relative;

  .KIWI-button {
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 4px;
    padding: 8px 18px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 1.15px;
    min-height: unset;
    cursor: pointer;

    color: ${(props) => props.theme.primaryColor};
    background-color: ${(props) => props.theme.secondaryColor};
  }

  .KIWI-single-arrow {
    border-top-color: ${(props) => props.theme.primaryColor};
  }

  .KIWI-option-list {
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    background-color: white;
    margin-top: 6px;
    width: 100%;
  }

  .KIWI-option {
    padding: 8px 18px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 1.15px;
    cursor: pointer;
    display: flex;
    justify-content: center;

    color: ${(props) => props.theme.primaryColor};
    background-color: white;

    :hover {
      background-color: ${(props) =>
        hexToRgbaString(props.theme.secondaryColor, 0.6)};
    }

    :first-child {
      border-radius: 4px 4px 0 0;
    }

    :last-child {
      border-radius: 0 0 4px 4px;
    }

    :focus {
      outline: none;
    }
  }
`;

interface DropdownProps {
  id?: string;
  value?: any;
  label: string;
  options: { value: any; content: string }[];
  onChange?: (item: { value: any; content: string }) => void;
}

const Dropdown: React.FC<DropdownProps & ThemeProps<Theme>> = ({
  id,
  value,
  label,
  options,
  onChange = () => {},
}) => {
  return (
    <ThemedDropdown
      id={id}
      options={options}
      buttonLabel={label}
      onChange={onChange}
      resetValue=""
      selectedOption={value}
    />
  );
};

export default withTheme(Dropdown);
