import React, { useState } from 'react';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import styled from 'styled-components';

import {
  Relation,
  FormikInput,
  FormikSubmit,
  Icons,
} from '../../../../components';
import OptionalField from '../../../../components/optionalField';
import { formatMeter } from '../../../../helpers/formatStrings';
import { Meter } from '../../../../graphql-types';
import yupMalo from '../../../../helpers/validators/malo';
import yupMelo from '../../../../helpers/validators/melo';
import { InfoAlert } from '../../../../components/alert';

import type { MeloMaloValueBag } from '.';

const SubmitWrap = styled.div`
  margin-top: 10px;
`;

const Head = styled.div`
  font-weight: bold;
  font-size: 15px;
`;

export function MaloMeloMeterForm({
  meter,
  setValues,
  isSumMeter,
}: {
  meter: Meter;
  setValues: (value: MeloMaloValueBag) => void;
  isSumMeter: boolean;
}) {
  const managedStateMelo = useState(true);
  const [shouldGenerateMelo] = managedStateMelo;

  // other Meters
  const managedStateMalo = useState(true);
  const [shouldGenerateMalo] = managedStateMalo;

  // sum Meter
  const managedStateMaloConsumption = useState(true);
  const managedStateMaloProduction = useState(true);
  const [shouldGenerateMaloConsumption] = managedStateMaloConsumption;
  const [shouldGenerateMaloProduction] = managedStateMaloProduction;

  let maloValidation;

  if (isSumMeter) {
    maloValidation = {
      ...(!shouldGenerateMaloConsumption && { maloConsumption: yupMalo }),
      ...(!shouldGenerateMaloProduction && { maloFeedIn: yupMalo }),
    };
  } else {
    maloValidation = {
      ...(!shouldGenerateMalo && { malo: yupMalo }),
    };
  }

  const validationSchema = yup.object({
    ...(!shouldGenerateMelo && { melo: yupMelo }),
    ...maloValidation,
  });

  return (
    <Formik
      initialValues={{
        maloFeedIn: '',
        maloConsumption: '',
        melo: '',
        malo: '',
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        setValues(values);
      }}
    >
      {() => (
        <Form>
          {isSumMeter && (
            <InfoAlert>
              Für den Summenzähler ist je Lieferrichtung des
              Zweirichtungszählers ein Vertrag mit getrennten Vertragspartnern
              und Marktlokationen zu generieren. Die nachfolgenden Schritte
              unterstützen Dich bei der korrekten Anlage.
            </InfoAlert>
          )}
          <Head>Zähler</Head>
          <Relation icon={Icons.MeterIcon} title={formatMeter(meter)} />
          <OptionalField
            label="Melo"
            options={['Fiktive Melo generieren', 'Eigene Melo']}
            managedState={managedStateMelo}
            resetFields={['melo']}
          >
            <FormikInput
              id="melo"
              name="melo"
              label={null}
              data-testid="melo"
            />
          </OptionalField>
          {isSumMeter && (
            <>
              <OptionalField
                label="Malo Bezug"
                options={['Fiktive Malo generieren', 'Eigene Malo']}
                managedState={managedStateMaloConsumption}
                resetFields={['maloConsumption']}
              >
                <FormikInput
                  id="maloConsumption"
                  name="maloConsumption"
                  label={null}
                  data-testid="malo-consumption"
                />
              </OptionalField>
              <OptionalField
                label="Malo Einspeisung"
                options={['Fiktive Malo generieren', 'Eigene Malo']}
                managedState={managedStateMaloProduction}
                resetFields={['maloFeedIn']}
              >
                <FormikInput
                  id="maloFeedIn"
                  name="maloFeedIn"
                  label={null}
                  data-testid="malo-feedIn"
                />
              </OptionalField>
            </>
          )}
          {!isSumMeter && (
            <OptionalField
              label="Malo"
              options={['Fiktive Malo generieren', 'Eigene Malo']}
              managedState={managedStateMalo}
              resetFields={['malo']}
            >
              <FormikInput
                id="malo"
                name="malo"
                label={null}
                data-testid="malo"
              />
            </OptionalField>
          )}
          <SubmitWrap>
            <FormikSubmit data-testid="melo-malo-submit">Weiter</FormikSubmit>
          </SubmitWrap>
        </Form>
      )}
    </Formik>
  );
}
